import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Windows1 from '../../images/inner/acoustical-studio-window1-200x200.webp';
import Windows2 from '../../images/inner/acoustical_window-22-200x200.webp';
import Windows3 from '../../images/inner/front1.webp';
import './windows.css';
import ScrollToTopButton from '../ScrollButton';
function windows() {
    return (
      
    <>
    <Helmet>
    <title>Soundproof Windows are an affordable high performance noise control solution</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Soundproof Window Products" />
    <meta property="og:description" content="we offer windows with Sound Transmission Class ratings that will meet the needs of any application."/>
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/acoustical_window-22-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/windows" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/windows" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/windows" />
    </Helmet>
    <Header />
    <section className="content" id="windows">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Soundproof Window Products</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>Windows are all too often a source of noise transmission. Single pane glass offers little as a barrier against sound passing through it. To eliminate sound problems, installing a soundproof window panel with higher sound ratings is the ideal solution.</p>
                                    <p>Our soundproof interior and exterior Windows are an affordable high performance noise control solution. Custom engineered to any shape or size, we offer windows with Sound Transmission Class ratings that will meet the needs of any application. For maximum acoustical performance, windows feature angled and flat glass and sealed air spaces to eliminate noise transmission.</p>
                                    <p>For indoor recording studios, windows are available in numerous custom glass options and Sound Silencer™ Acoustical Frame Inserts. Exterior applications benefit from windows with noise inserts, reducing up to 80% of outside noise while maintaining aesthetic qualities. We also offer a line of interior or exterior noise barrier curtains that absorb sound in quilted or decorative styles.</p>
                                    <p>To learn more about our soundproof window panels, window inserts for noise, and our other products, <Link to="/contact-us">contact us</Link> today!</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>View Our Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Windows1} alt="Studio Window" />
                                </Link>
                                <h5>Studio Windows</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Windows2} alt="Climate Seal Window" />
                                </Link>
                                <h5>Climate Seal Window Inserts</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Windows3} alt="Cinema Port" />
                                </Link>
                                <h5>Cinema Port</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default windows;