import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Curtains1 from '../../images/inner/quilted12-200x200.webp';
import Curtains2 from '../../images/inner/bbc_ext_n2-200x182.webp';
import Curtains3 from '../../images/inner/exterior-quilted-curtain-absorber2-200x200.webp';
import './soundproofing-curtains.css';
import ScrollToTopButton from '../ScrollButton';
function soundproofcurtain() {
    return (
      
    <>
    <Helmet>
    <title>Soundproofing Curtains and Sound Absorbing Blankets</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Soundproofing Curtains & Sound Blankets" />
    <meta property="og:description" content="Made from sound absorbing materials, our soundproof blankets and curtains offer an alternative to more costly solutions, are portable, and are easy to install." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/quilted12-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/soundproofing-curtains" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/curtains-sound-blankets" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/soundproofing-curtains" />
    </Helmet>
    <Header />
    <section className="content" id="curtains">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Soundproofing Curtains & Sound Blankets</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>Made from sound absorbing materials, our soundproof blankets and curtains offer an alternative to more costly solutions, are portable, and are easy to install.</p>
                                    <p>Sound proof curtains are available in a variety of forms. We supply portable acoustical enclosures and screens with a core of foam or fiberglass faced with aluminized or polyester fabric that are ideal for machinery and work area enclosures indoors or out. Easy set-up around noisy areas make these ideal solutions for unusual conditions where other types of sound barriers wouldn’t work as well. For indoor applications, custom made decorative curtains, constructed with a core material of naturally fire resistant wool fabric, offer a higher degree of sound absorption than traditional curtains.</p>
                                    <p>Sound blankets are most commonly used to absorb and block sound from outdoor equipment or machinery but are also highly effective as a wrapping around structural elements in buildings. Constructed with a fiberglass core and a facing made from vinyl or flame-proof foil, sound blankets keep the noise level down for a more peaceful and efficient environment. Sound blankets are used throughout commercial and industrial applications.</p>
                                    <p>The above products are just a sampling of our many varieties of Curtains Sound Blankets. <Link to="/contact-us">Contact us</Link> today to learn more.</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>View Our Products</h4>
                            </div>
                            <div className="col-sm-4 dbox">
                                <Link to="/#">
                                    <img className="img" src={Curtains1} alt="Absorptive Quilted Curtains" />
                                </Link>
                                <h5>Absorptive Quilted Curtains</h5>
                            </div>
                            <div className="col-sm-4 dbox">
                                <Link to="/#">
                                    <img className="img" src={Curtains2} alt="Exterior Quilted Barrier" />
                                </Link>
                                <h5>Exterior Quilted Barrier/Absorbers</h5>
                            </div>
                            <div className="col-sm-4 dbox">
                                <Link to="/#">
                                    <img className="img" src={Curtains3} alt="Quilted Exterior Absorbers" />
                                </Link>
                                <h5>Quilted Exterior Absorbers</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default soundproofcurtain; 