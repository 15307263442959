import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Hb1 from '../../images/acfabric/hb1.jpg';
import Hb2 from '../../images/acfabric/hb2.jpg';
import Hb3 from '../../images/acfabric/hb3.jpg';
import Hb4 from '../../images/acfabric/hb4.jpg';
import Hb5 from '../../images/acfabric/hb5.jpg';
import Hb6 from '../../images/acfabric/hb6.jpg';
import Hb7 from '../../images/acfabric/hb7.jpg';
import Hb8 from '../../images/acfabric/hb8.jpg';
import Hb9 from '../../images/acfabric/hb9.jpg';
import Hb10 from '../../images/acfabric/hb10.jpg';
import Hb11 from '../../images/acfabric/hb11.jpg';
import Hb12 from '../../images/acfabric/hb12.jpg';
import Hb13 from '../../images/acfabric/hb13.jpg';
import Hb14 from '../../images/acfabric/hb14.jpg';
import Hb15 from '../../images/acfabric/hb15.jpg';
import Hb16 from '../../images/acfabric/hb16.jpg';
import './acfabric-highbeams.css';
import ScrollToTopButton from '../ScrollButton';
function acfabrichigh() {
    return (
      
    <>
    <Helmet>
    <title>Acoustic of Maine Highbeams Acoustic Fabric - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="AcoustyFABRIC Highbeams" />
    <meta property="og:description" content="Guilford of Maine Highbeams 9834 Fabric Facings" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/acfabric/hb1.jpg" />
    <meta property="og:locale" content="en-in" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/acfabric-highbeams" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/acfabric-highbeams" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acfabric-highbeams" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="acfabrichighbeams">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
            	<div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
	                <div className="col-sm-12 heading-fsorb">				
		                <h1>Acousty<span className="fabriwall">FABRIC</span> Highbeams</h1>
                		<h4 className="mb-0"> <b>Guilford of Maine Highbeams 9834 Fabric Facings</b></h4>
		                <p><span className="color:red;">(Colors Shown are Approximate Match to Material)</span></p>
	                    <br />        
		                <div className="col-sm-3 cloths p-0"><button>Product Specifications</button></div>
	                </div>
					<br /><br />
		            <div className="col-sm-12 cloths">
		                <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Hb1} alt="Barley" />
                                <p className="text-center">Barley 2637</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Hb2} alt="Bayberry" />
                                <p className="text-center">Bayberry 2632</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Hb3} alt="Black" />
                                <p className="text-center">Black 2644</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Hb4} alt="Bronze" />
                                <p className="text-center">Bronze 2639</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Hb5} alt="Buttercup" />
                                <p className="text-center">Buttercup 2640</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Hb6} alt="Expresso" />
                                <p className="text-center">Expresso 2638</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Hb7} alt="Gold Rush" />
                                <p className="text-center">Gold Rush 2633</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Hb8} alt="Grape" />
                                <p className="text-center">Grape 2641</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Hb9} alt="Kiwi" />
                                <p className="text-center">Kiwi 2643</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Hb10} alt="Magenta" />
                                <p className="text-center">Magenta 2692</p>
                            </div>
                        </div>	
                        <div className="row d-flex justify-content-center mb-2">
                           	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Hb11} alt="Maroon" />
                                <p className="text-center">Maroon 2634</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Hb12} alt="Navy" />
                                <p className="text-center">Navy 2631</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Hb13} alt="Ocean" />
                                <p className="text-center">Ocean 2629</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Hb14} alt="Rust" />
                                <p className="text-center">Rust 2636</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Hb15} alt="Slate" />
                                <p className="text-center">Slate 2645</p>
                            </div>
                        </div>	
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0">
                                <img className="w-100" src={Hb16} alt="Tangerine" />
                                <p className="text-center">Tangerine 2647</p>
                            </div>
                            <div className="col-sm-8 p-0 mx-4"></div>
                        </div>	
                    </div>
		            <br />
		            <hr />
                    <div className="col-12 d-flex justify-content-center backbtn">
                	    <Link to="/acoustyfabric"><button>Back to Acousty<span style={{color: "red"}}>FABRIC</span></button></Link>
                    </div>
                    <hr />
                    <div className="col-sm-12 application">
			            <h1>APPLICATION</h1>
	                    <div className="hover14 text-center justify-content-center">
		                <br />
  		                    <div className="col-sm-12">
			                    <figure className="m-0"><div className="tinted-image">CINEMA HALL</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image2">OFFICE ROOMS & HALLS</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image3">AUDITORIUM & EDUCATION</div></figure>
 		                    </div>
                        <br />
	                    </div>
                    </div>
		            <br />
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acfabrichigh;