import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Rsicclips1 from '../../images/inner/rsic-1-clip-furring-channel.webp';
import Rsicclips2 from '../../images/inner/rsiclips02.webp';
import Rsicclips3 from '../../images/inner/rsiclips14.webp';
import './rsic-1-clips.css';
import ScrollToTopButton from '../ScrollButton';
function rsic1clips() {
    return (

        <>
            <Helmet>
                <title>Resilient Sound Isolation Clips For Wall & Floor Ceiling</title>
                <meta name="description" content="" />
                <meta name="robots" content="index, follow" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="in" />
                <meta name="geo.region" content="india" />
                <meta property="og:title" content="Resilient Sound Isolation Clips SC101" />
                <meta property="og:description" content="SC101 Resilient Sound Isolation Clips provide 2 unique approaches to sound privacy that will prevent sound from noisy neighbors transmitting through your walls or floor/ceiling assemblies." />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/rsic-1-clip-furring-channel.webp" />
                <meta property="og:locale" content="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.ae/rsic-1-clips" hreflang="en-ae" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/rsic-1-clips" hreflang="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/rsic-1-clips" />
            </Helmet>
            <Header />
            <section className="content" id="rsicclips">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 sidemenu">
                            <Sidebar />
                        </div>
                        <div className="col-sm-9 product-page nopaddding">
                            <div className="container thanks">
                                <div className="megamenu">
                                    <Megamenu />
                                </div>
                                <h1>Resilient Sound Isolation Clips SC101</h1>
                                <div className="row pad">
                                    <div className="col-xl-4 col-sm-12">
                                        <Link className="example-image-link" to="../../images/inner/rsic-1-clip-furring-channel.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Rsicclips1} alt="Rsic Clips1" />
                                        </Link>
                                    </div>
                                    <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                        <h2>For Wall & Floor Ceiling Assemblies</h2>
                                        <img className="img-responsive" src="images/inner/Soundproofing.gif" alt="" />
                                        <h2>The SC101 Resilient Sound Isolation Clip</h2>
                                        <p>SC101 Resilient Sound Isolation Clips provide 2 unique approaches to sound privacy that will prevent sound from noisy neighbors transmitting through your walls or floor/ceiling assemblies.</p>
                                        <p>Experience with the SC101 Clips has recorded remarkable improvements of up to an additional 20 STC points on walls.</p>
                                        <p>The SC101-R designed to retrofit and improve existing walls and ceilings has shown to improve existing walls by as much as 8-10 dB by actual field tests.</p>
                                        <ul style={{ marginleft: "1em", fontsize: ".9em" }}>
                                            <li>
                                                <a style={{ color: "#0059a5" }} href="images/inner/clips-1.webp">View Estimated SC101 Clip Usage For Walls & Ceilings, Wood or Steel Framed (Spacing at 24″×48″ O.C.)</a>
                                            </li>
                                            <li>
                                                <a style={{ color: "#0059a5" }} href="images/inner/clips-2.webp">View Estimated SC101 Clip Usage for Walls & Ceilings, Wood or Steel Framed (Spacing at 16″×48″ O.C.)</a>
                                            </li>
                                            <li>
                                                <a style={{ color: "#0059a5" }} href="images/inner/wall-1.webp">Wall Detail – SC101 Clips at 24″ O.C.</a>
                                            </li>
                                            <li>
                                                <a style={{ color: "#0059a5" }} href="images/inner/wall-2.webp">Wall Detail – SC101 Clips at 16″ O.C.</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                        <div className="testing-info">
                                            <h5>Product Testing & Information</h5>
                                            <ul className="">
                                                <li>
                                                    <Link to="https://acousticalsurfaces.in/pdfs/RSIC-1-Product-Guide-Specification.doc" target="_blank">CSI Spec Sheet</Link>
                                                </li>
                                                <li>
                                                    <Link to="https://acousticalsurfaces.in/pdfs/rsiclips.pdf" target="_blank">Product Specs</Link>
                                                </li>
                                                <li>
                                                    <Link to="https://acousticalsurfaces.in/pdfs/RSIC_Retro.pdf" target="_blank">Technical Field Information</Link>
                                                </li>
                                                <li>
                                                    <Link to="https://acousticalsurfaces.in/pdfs/RSIC_Concrete.pdf" target="_blank">Retrofitting Walls & Floors From Noisy Neighbors</Link>
                                                </li>
                                                <li>
                                                    <Link to="https://acousticalsurfaces.in/pdfs/WhitePaper_SoundIsolationClips.pdf" target="_blank">White Paper</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="line"></div>
                                </div>
                                <div className="row pad">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        <Link className="example-image-link" to="../../images/inner/rsiclips02.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Rsicclips2} alt="Rsic Clips2" />
                                        </Link>
                                        <Link className="example-image-link" to="../../images/inner/rsiclips14.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Rsicclips3} alt="Rsic Clips3" />
                                        </Link>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 text">
                                        <h3>Walls – One and Two layers of 5/8″ Gypsum Board</h3>
                                        <ul style={{ marginleft: "1em", fontsize: ".9em" }}>
                                            <li>Resilient Sound Isolation Clip (SC101) shall be 48 inches maximum on center (horizontal)</li>
                                            <li>Fasten the Resilient Sound Isolation Clip (SC101) to the substrate with a fastener approved for a minimum pull-out and sheer of 120 lbs</li>
                                            <li>Ensure the internal metal ferrell is tight to the substrate</li>
                                            <li>Locate the first row of SC101 clips within 3 inches from the floor and within 6 inches from the ceiling</li>
                                            <li>Snap in the drywall furring channel (hat track) into the SC101 clips (horizontal for walls)</li>
                                            <li>Place 1/4″ (minimum) shim on floor to fully support the gypsum board</li>
                                            <li>Install the gypsum board from the bottom up leaving a 1/4″ min. gap around perimeter.</li>
                                            <li>ONLY remove the shims after ALL the gypsum board is completely screwed to ALL the drywall furring channels. Make sure every screw (floor to ceiling and wall to wall) is installed as required by the assembly design, in every layer of gypsum board before removing the shims at the floor. The shims are critical to ensure best results</li>
                                            <li>Caulk around the entire perimeter of the gypsum board. Use fire and smoke rated acoustical sealant where required</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mobsidebar'>
                        <div className="col-sm-12">
                            <div className="container">
                                <div className="row">
                                    <Mobsidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default rsic1clips; 