import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Floor1 from '../../images/inner/acoustik4-200x200.webp';
import Floor2 from '../../images/inner/nutra-phone1-200x162.webp';
import Floor3 from '../../images/inner/duracousticnew1-200x200.webp';
import Floor4 from '../../images/inner/quflu71-200x200.webp';
import Floor5 from '../../images/inner/green-glue-group4-200x200.webp';
import './floor-soundproofing.css';
import ScrollToTopButton from '../ScrollButton';
function floor() {
    return (
      
    <>
    <Helmet>
    <title>Floor Soundproofing - reduce Floor noise in residential or commercial applications</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Soundproof Flooring" />
    <meta property="og:description" content="To reduce floor noise in residential or commercial applications, application of soundproof subfloors, underlayments, and pads." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/nutra-phone1-200x162.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/floor-soundproofing" hreflang="en-ae" />    
    <link rel="alternate" href="https://www.acousticalsurfaces.in/floor-soundproofing" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/floor-soundproofing" />
    </Helmet>
    <Header />
    <section className="content" id="Floorsoundproofing">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Soundproof Flooring</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>To reduce floor noise in residential or commercial applications, application of soundproof subfloors, underlayments, and pads when installing flooring significantly decreases sound transmission from one space to another. The ability to provide a quiet working or living environment with proper floor sound insulation is a major benefit for all.</p>
                                    <p>As leaders in noise control products, our floor soundproofing products are available in many different styles and materials. Constructed from recycled rubber or synthetic fibers, these easy to install products are suitable for use under ceramic tile, hardwood, laminate and engineered wood floors, or carpet. By using floor sound insulation products specifically designed to reduce sound transmission, you will be preventing a leading cause of tenant dissatisfaction. Quiet spaces are valued in residential and commercial environments. To reduce structure-borne sound even further, our specialized glues reduce impact noise and improve resistance to airborne sound.</p>
                                    <p>Let us help solve your noise problems. <Link to="/contact">Contact us</Link> today!</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12"><h4>View Our Products</h4></div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#"><img className="img" src={Floor1} alt="Acoustik 3/8" /></Link>
                                <h5>Acoustik 3/8"</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#"><img className="img" src={Floor2} alt="Quiet Floor NP" /></Link>
                                <h5>Quiet Floor NP</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#"><img className="img" src={Floor3} alt="Duracoustic" /></Link>
                                <h5>Duracoustic</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#"><img className="img" src={Floor4} alt="Quiet Floor Walk" /></Link>
                                <h5>Quiet Floor Walk</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#"><img className="img" src={Floor5} alt="Green Glue" /></Link>
                                <h5>Green Glue</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default floor; 