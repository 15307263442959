import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import Flocert from '../../images/projects-slider/flocert-1.webp';
import Amaryllis from '../../images/projects-slider/amaryllis-1.webp';
import ScrollToTopButton from '../ScrollButton';
function projects5() {
    return (

        <>
            <Helmet>
                <title>Turnkey Acoustic Projects - Acoustical Surfaces </title>
                <meta name="description" content="" />
                <meta property="og:title" content="Projects Gallery" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.png" />
                <meta property="og:locale" content="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.ae/projects-5" hreflang="en-ae" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/projects-5" hreflang="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/projects-5" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="IN" />
                <meta name="geo.region" content="india" />
            </Helmet>
            <Header />
            <section className="content projects">
                <div className="container">
                    <hr />
                    <h2 style={{ textalign: "center" }}>Projects Gallery</h2>
                    <div style={{ width: "18%", bordertop: "1px solid #e0dede", margin: "auto", padding: "10px" }}></div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>Flocert India,  Bangalore</h4>
                            <img className="img-fluid" src={Flocert} alt="Flocert Bangalore" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{ textalign: "justify" }}>
                            <h5>About Flocert India,  Whitefield, Bangalore</h5>
                            <p style={{textAlign:"justify"}}>Flocert is a leading certifier offering verification services to Fair-trade organizations globally. Flocert has supported the phenomenal growth of Fair-trade, a movement that has positively impacted millions of people across the world for over a decade. </p>
                            <h5>Our Scope</h5>
                            <p>Supply & Installation of acoustical Fabric panels with echo eliminator as backing material for the conference room to reduce the reverberation time to provide better speech intelligibility.</p>
                            <h5>Location</h5><p>Bangalore</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>Amaryllis Healthcare Pvt Ltd,  Bangalore</h4>
                            <img className="img-fluid" src={Amaryllis} alt="Amaryllis Healthcare Pvt Ltd Bangalore" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{ textalign: "justify" }}>
                            <h5>About Amaryllis Healthcare Pvt Ltd, Bangalore</h5>
                            <p style={{textAlign:"justify"}}>Amaryllis Healthcare Private Limited, an ISO Certified Company, has emerged as a key partner of the healthcare sector in India over the last few years. Their product range extends from Disposable Surgical Drapes & Gowns to various Hygiene & Protective products.</p>
                            <h5>Our Scope</h5>
                            <p>Supply and installation of Noise S.T.O.P Vinyl Barrier and stretch fabric system with Glass wool to increase sound isolation property of wall and to reduce reverberation time in the conference room which provides better speech intelligibility.</p>
                            <h5>Location</h5><p>Bangalore</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="paginate">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a class="page-link" href="/projects-4" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item"><a class="page-link" href="/projects">1</a></li>
                                        <li class="page-item"><a class="page-link" href="/projects-2">2</a></li>
                                        <li class="page-item"><a class="page-link" href="/projects-3">3</a></li>
                                        <li class="page-item"><a class="page-link" href="/projects-4">4</a></li>
                                        <li class="page-item"><a class="page-link" href="/projects-5">5</a></li>
                                        <li class="page-item">
                                            <a class="page-link" href="/projects" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default projects5; 