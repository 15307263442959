import React from "react";
import './carousel-kuwait.css';
import { Link } from 'react-router-dom';
import ProjectASI1 from '../../images/Teleperformance.webp';
import ProjectASI2 from '../../images/projects-slider/bosch.webp';
import ProjectASI3 from '../../images/project4.webp';
import ProjectASI4 from '../../images/project5.webp';
import ProjectASI5 from '../../images/project1.webp';
import ProjectASI6 from '../../images/projects-slider/wipro-bangalore.webp';
import ProjectASI7 from '../../images/project2.webp';
import ProjectASI8 from '../../images/project3.webp';
import ProjectASI9 from '../../images/projects-slider/naval-vizag.webp';
import ProjectASI10 from '../../images/projects-slider/proj-kodai-3.webp';
import ProjectASI11 from '../../images/projects-slider/cmc-image-1.webp';
import ProjectASI12 from '../../images/projects-slider/hospira-chennai.webp';
import ProjectASI13 from '../../images/projects-slider/ht-mangalore-1.webp';
import ProjectASI14 from '../../images/projects-slider/flocert-1.webp';
import ProjectASI15 from '../../images/projects-slider/amaryllis-1.webp';
import ProjectASI16 from '../../images/projects-slider/p1ferrari.webp';
import ProjectASI17 from '../../images/projects-slider/p1museum.webp';
import ProjectASI18 from '../../images/projects-slider/p1palace.webp';
import ProjectASI19 from '../../images/projects-slider/p1louvre.webp';
import ProjectASI20 from '../../images/projects-slider/p1eibfs.webp';
import ProjectASI21 from '../../images/projects-slider/p1kapsarc.webp';
import ProjectASI22 from '../../images/projects-slider/acousticproject-acoustical-surfaces.webp';
function carousel() {
    return (
    <>      
        <div id="carousel-home">
            <div className="row">
                <div className="col-xl-12">
                    <div className="items">
                        <div className="item">
                            <div className="img-holder">
                                <Link to="/project-teleperformance">
                                    <img src={ProjectASI1} alt="FabriwallAS1" className="project" />
                                    <div className="title-box">
                                        <h3><span>Project: </span>Teleperformance SE</h3>
                                        <h4><span>Scope: </span> Supply & Installation of D'ecopanel</h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <Link to="/project-bosch">
                                    <img src={ProjectASI2} alt="FabriwallAS1" className="project" />
                                    <div className="title-box">
                                        <h3><span>Project: </span>ROBERT BOSCH ENGINEERING</h3>
                                        <h4><span>Scope: </span> Supply & Installation of Fabriwall</h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <Link to="/project-twitter">
                                    <img src={ProjectASI3} alt="FabriwallAS1" className="project" />
                                    <div className="title-box">
                                        <h3><span>Project: </span>TWITTER INDIA</h3>
                                        <h4><span>Scope: </span> <b>Acoustical Wall</b> Treatment</h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <Link to="/project-zoho">
                                    <img src={ProjectASI4} alt="FabriwallAS1" className="project" />
                                    <div className="title-box">
                                        <h3><span>Project: </span>ZOHO CORPORATION</h3>
                                        <h4><span>Scope: </span> <b>Acoustic Panel</b> System and Acrylic Sheet</h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <Link to="/project-general-motors">
                                    <img src={ProjectASI5} alt="FabriwallAS1" className="project" />
                                    <div className="title-box">
                                        <h3><span>Project: </span>GENERAL MOTORS</h3>
                                        <h4><span>Scope: </span><b>Acoustic Anaechoic Chamber</b></h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <Link to="/project-wipro">
                                    <img src={ProjectASI6} alt="FabriwallAS1" className="project" />
                                    <div className="title-box">
                                        <h3><span>Project: </span>Wipro Technologies, Bangalore, Karnataka</h3>
                                        <h4><span>Scope: </span> Supply & installation of <b>Acoustic Wall Treatment </b>Acoustic</h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <Link to="/project-goldman-sachs">
                                    <img src={ProjectASI7} alt="FabriwallAS1" className="project" />
                                    <div className="title-box">
                                        <h3><span>Project: </span>GOLDMAN SACHS</h3>
                                        <h4><span>Scope: </span><b>Acoustic Wall Treatment</b></h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <Link to="/project-drc-cinemas">
                                    <img src={ProjectASI8} alt="FabriwallAS1" className="project" />
                                    <div className="title-box">
                                        <h3><span>Project: </span>DRC CINEMAS</h3>
                                        <h4><span>Scope: </span><b>Acoustic Treatment</b></h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <Link to="/project-navel-base-academy">
                                    <img src={ProjectASI9} alt="FabriwallAS1" className="project" />
                                    <div className="title-box">
                                        <h3><span>Project: </span>Navel Base Academy</h3>
                                        <h4><span>Scope: </span>Supply & Installation of <b>Acoustic Panel</b></h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <Link to="/project-kodaikanal-international-school">
                                    <img src={ProjectASI10} alt="FabriwallAS1" className="project" />
                                    <div className="title-box">
                                        <h3><span>Project: </span>Kodaikanal International School</h3>
                                        <h4><span>Scope: </span>Supply & Installation of <b>Acoustic Wall Panels</b></h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <Link to="/project-christian-medical-college">
                                    <img src={ProjectASI11} alt="FabriwallAS1" className="project" />
                                    <div className="title-box">
                                        <h3><span>Project: </span>Christian Medical College</h3>
                                        <h4><span>Scope: </span>Supply & Installation of <b>Sound Absorption Wall Paneling of NRC 0.9 and Suspended Ceilings</b></h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <Link to="/project-hospira-healthcare">
                                    <img src={ProjectASI12} alt="FabriwallAS1" className="project" />
                                    <div className="title-box">
                                        <h3><span>Project: </span>Hospira Healthcare</h3>
                                        <h4><span>Scope: </span>Supply & Installation of <b>Acoustic Wall Panels</b></h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <Link to="/home-theater-project">
                                    <img src={ProjectASI13} alt="FabriwallAS1" className="project" />
                                    <div className="title-box">
                                        <h3><span>Project: </span>Home Theater, Mangalore</h3>
                                        <h4><span>Scope: </span>Supply & Installation of <b>Acoustic Walls and Flooring</b></h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <Link to="/project-flocert">
                                    <img src={ProjectASI14} alt="FabriwallAS1" className="project" />
                                    <div className="title-box">
                                        <h3><span>Project: </span>Flocert India, Bangalore</h3>
                                        <h4><span>Scope: </span>Supply & Installation of <b>Acoustical Fabric panels</b> with Echo Eliminator</h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <Link to="/project-amaryllis-healthcare">
                                    <img src={ProjectASI15} alt="FabriwallAS1" className="project" />
                                    <div className="title-box">
                                        <h3><span>Project: </span>Amaryllis Healthcare Pvt Ltd, Bangalore</h3>
                                        <h4><span>Scope: </span>Supply & Installation of <b>Soundproof Vinyl Barrier and Stretch Fabric</b> System</h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <Link to="/project-ferrari-world">
                                    <img src={ProjectASI16} alt="FabriwallAS1" className="project" />
                                    <div className="title-box">
                                        <h3><span>Project: </span>Ferrari World, Abu dhabi</h3>
                                        <h4><span>Scope: </span>Installed <b>Fabric Wrapped Acoustic Wall Panel</b> System on the Wall</h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <Link to="/project-the-museum-of-future-dubai">
                                    <img src={ProjectASI17} alt="FabriwallAS1" className="project" />
                                    <div className="title-box">
                                        <h3><span>Project: </span>The Museum of Future, Dubai</h3>
                                        <h4><span>Scope: </span><b>Acoustical Plaster Ceiling</b> in Auditorium below the Walkway & Hall Area</h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <Link to="/project-presidential-palace">
                                    <img src={ProjectASI18} alt="FabriwallAS1" className="project" />
                                    <div className="title-box">
                                        <h3><span>Project: </span>Presidential Palace, Abu dhabi</h3>
                                        <h4><span>Scope: </span>Supply & Installation of <b>Acoustic Wall Panels</b></h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <Link to="/project-louvre">
                                    <img src={ProjectASI19} alt="FabriwallAS1" className="project" />
                                    <div className="title-box">
                                        <h3><span>Project: </span>Louvre, Abu dhabi</h3>
                                        <h4><span>Scope: </span>Supply & Installation of <b>Acoustic Wood Wool</b></h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <Link to="/project-EIBFS">
                                    <img src={ProjectASI20} alt="FabriwallAS1" className="project" />
                                    <div className="title-box">
                                        <h3><span>Project: </span>EIBFS, Dubai, UAE</h3>
                                        <h4><span>Scope: </span><b>Acoustical Wall Treatment & Ceiling</b></h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <Link to="/project-kapsarc">
                                    <img src={ProjectASI21} alt="FabriwallAS1" className="project" />
                                    <div className="title-box">
                                        <h3><span>Project: </span>KAPSARC, Riyadh, Saudi Arabia</h3>
                                        <h4><span>Scope: </span>Supply & installation of <b>Acoustic Panels, Echo Panels</b> and Acoustic Treatment</h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <Link to="/project-delhi-public-school">
                                    <img src={ProjectASI22} alt="FabriwallAS1" className="project" />
                                    <div className="title-box">
                                        <h3><span>Project: </span>Delhi Public School, Bangalore, Karnataka</h3>
                                        <h4><span>Scope: </span>Supply & installation of <b>Soundproof Fabrisorb Baffles</b></h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>                       
        </div>
    </>
    );
  }
  
  export default carousel;