import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Greenbldg1 from '../../images/inner/recycled_logo_jeans-200x200.webp';
import Greenbldg2 from '../../images/inner/cotton_stack-200x200.webp';
import Greenbldg3 from '../../images/inner/cellulose-panels1-200x200.webp';
import Greenbldg4 from '../../images/inner/acoustik1-200x200.webp';
import Greenbldg5 from '../../images/inner/quiet_acoustical_liner-200x200.webp';
import Greenbldg6 from '../../images/inner/acousti-board-ultra-200x200.webp';
import Mobsidebar from '../mobile-sidebar';
import './green-bldg.css';
import ScrollToTopButton from '../ScrollButton';
function green() {
    return (
      
    <>
    <Helmet>
    <title>Green Acoustic Foam - Soundproofing for Green Building Applications</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Green Soundproofing Products" />
    <meta property="og:description" content="All of our green soundproofing products like green acoustic foam and recycled insulation are made to the highest standards." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/cellulose-panels1-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/green-bldg" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/green-bldg" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/green-bldg" />
    </Helmet>
    <Header />
    <section className="content" id="greenbldg">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Soundproofing for Green Building Applications</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>Green building and LEED certification is a goal for many construction products. Utilizing the correct green acoustic foam, recycled insulation, and other products is extremely important to acquiring this certification. To meet these needs, Acoustic Surfaces provides a large selection of products that are eco-friendly and meet green building certifications.</p>
                                    <p>Using a variety of new and recycled materials, we offer the highest quality products that meet building code requirements for both safety and environmental friendliness. Our green product line features insulation made from recycled natural fibers; wall panels, baffles, and HVAC acoustic liners made from recycled cotton; and soundproof backing made from recycled synthetic fiber.</p>
                                    <p>All of our green soundproofing products like green acoustic foam and recycled insulation are made to the highest standards. When building green, the best choice is to work with the experts at Acoustic Surfaces. For a look at our eco-friendly green building options, please see the above below.</p>
                                    <p><Link to="/contact-us">Contact us</Link> today for help solving your noise problem!</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>Green Soundproofing Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Greenbldg1} alt="Ultra Touch Insulation" />
                                </Link>
                                <h5>Ultra Touch Insulation</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Greenbldg2} alt="Echo Eliminator" />
                                </Link>
                                <h5>Echo Eliminator</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Greenbldg3} alt="CFAB Cellulose Panels" />
                                </Link>
                                <h5>CFAB Cellulose Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Greenbldg4} alt="Acoustik Underlament" />
                                </Link>
                                <h5>Acoustik Underlament</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Greenbldg5} alt="Quiet Liner" />
                                </Link>
                                <h5>Quiet Liner</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Greenbldg6} alt="AcoustiBoard Ultra" />
                                </Link>
                                <h5>AcoustiBoard Ultra</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default green;