import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Pyramiddiffuser from '../../images/inner/pyramid-diffuser.webp';
import './pyramiddiffuser.css';
import ScrollToTopButton from '../ScrollButton';
function pyramiddiffuser() {
    return (
      
    <>
    <Helmet>
    <title>Pyramid Diffuser Panel break up flutter echo from flat surfaces</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Pyramid Diffuser" />
    <meta property="og:description" content="Pyramid Diffuser Panel are made of high density glass fibre designed to provide scattered reflections for a diffused sound field." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.svg" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/pyramiddiffuser" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/pyramiddiffuser" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/pyramiddiffuser" />
    </Helmet>
    <Header />
    <section className="content" id="pyramiddiffuser">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Pyramid Diffuser</h1>
                        <div className="row pad">
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/pyramid-diffuser.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Pyramiddiffuser} alt="Pyramid Diffuser" />
                                </Link>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Pyramid Diffuser</h2>
                                <p>Pyramid Diffuser Panel are made of high density glass fibre designed to provide scattered reflections for a diffused sound field. Pyramid panels break up flutter echo from flat surfaces to enhance music tone quality and speech intelligibility.</p>
                                <h2>Application</h2>
                                <p>Pyramid Diffuser Panel is designed to fit into standard suspended ceiling grid or can be fixed to walls with brackets. Ideal for music rooms, performance halls, theatres where speech intelligibility and music tone quality is desirable.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 text">
                                <h2>Composition</h2>
                                <p>Manufactured from high density alpha plaster reinforced with glass fibres..</p>
                                <h2>Features</h2>
                                <p>Deeply profiled asymmetric reflective shape.</p>
                                <p>Helps create diffused sound field for music tone and speech clarity.</p>
                                <p>Designed as a lay in ceiling tile or can be fixed to walls.</p>
                                <div className="line"></div>
                                <h2>Technical Specifications</h2>
                                <table className="table table-bordered-2" style={{background: "#f5f5f5"}}>
                                    <tr>
                                        <td>Size (nominal)*</td>
                                        <td>Edge</td>
                                    </tr>
                                    <tr>
                                        <td>100 x 600 x 600 mm</td>
                                        <td>E24</td>
                                    </tr>
                                </table>
                                <table className="table table-bordered-2" style={{background: "#f5f5f5"}}>
                                    <h2>Sound Absorption Rating: per ASTM C423, E-200 *est.</h2>
                                    <tr>
                                        <td>Hz</td>
                                        <td>125</td>
                                        <td>250</td>
                                        <td>500</td>
                                        <td>1000</td>
                                        <td>2000</td>
                                        <td>4000</td>
                                    </tr>
                                    <tr>
                                        <td>Diffusion</td>
                                        <td>∂s</td>
                                        <td>0.35</td>
                                        <td>0.15</td>
                                        <td>0.10</td>
                                        <td>0.10</td>
                                        <td>0.10</td>
                                    </tr>
                                </table>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>Accessibility:</th>
                                            <td>All panels are demountable and accessible.</td>
                                        </tr>
                                        <tr>
                                            <th>Colour:</th>
                                            <td>Any colour fabric finishes available</td>
                                        </tr>
                                        <tr>
                                            <th>Environmental impact:</th>
                                            <td>Manufactured locally, product and packaging can be recycled.</td>
                                        </tr>
                                        <tr>
                                            <th>Fire Reaction:</th>
                                            <td>AS1530 pt 3. Smoke developed 1, spread of flame 0.</td>
                                        </tr>
                                        <tr>
                                            <th>Humidity:</th>
                                            <td>Max 95% R/H at 30°C.</td>
                                        </tr>
                                        <tr>
                                            <th>Light reflectance:</th>
                                            <td>ASTM C1477 LR-1, 75%</td>
                                        </tr>
                                        <tr>
                                            <th>Maintenance:</th>
                                            <td>Clean with vacuum, soft brush or damp cloth.</td>
                                        </tr>
                                        <tr>
                                            <th>Thermal Resistance:</th>
                                            <td>R 0.1 m²°C/W</td>
                                        </tr>
                                        <tr>
                                            <th>Weight:</th>
                                            <td>8 kg/m²</td>
                                        </tr>
                                        <tr>
                                            <th>Installation</th>
                                            <td>Shall not commence until the building is water tight and dry. Lay
                                                panels into standard T24 two way exposed grid system with
                                                minimum of 200 mm plenum to allow for placement of panels, use
                                                hold down clips in seismic areas. Panels may be fixed to walls
                                                using brackets. Store panels on pallets in a clean dry place.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default pyramiddiffuser; 