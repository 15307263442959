import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Sonexclass1 from '../../images/inner/prod_sonexclassicpanels.webp';
import Sonexclass2 from '../../images/inner/sonex-white-200x155.webp';
import Sonexclass3 from '../../images/inner/sonex-grey1-200x155.webp';
import Sonexclass4 from '../../images/inner/sonex-charcoal1-200x155.webp';
import Sonexclass5 from '../../images/inner/sonex-hpc-black-200x155.webp';
import Sonexclass6 from '../../images/inner/sonex-hpc-light-grey-200x155.webp';
import Sonexclass7 from '../../images/inner/sonex-hpc-white-200x155.webp';
import Sonexclass8 from '../../images/inner/sonex-hpc-almond-200x155.webp';
import Sonexclass9 from '../../images/inner/sonex-hpc-medium-grey-200x155.webp';
import Sonexclass10 from '../../images/inner/sonex-hpc-light-blue-200x155.webp';
import Sonexclass11 from '../../images/inner/sonex-hpc-ivory-200x155.webp';
import './acoustic-foam.css';
import ScrollToTopButton from '../ScrollButton';
function acousticfoam() {
    return (
      
    <>
    <Helmet>
    <title>Acoustic Foam Supplier - Acoustic Foam provide excellent sound and noise control</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="SONEX classNameic Melamine Foam Acoustical Panels" />
    <meta property="og:description" content="Melamine Foam Acoustical Panels" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/prod_sonexclassNameicpanels.webp" />
    <meta property="og:locale" content="en-in" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/acoustic-foam" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/acoustic-foam" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acoustic-foam" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="acousticfoam">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
            	<div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                        <div className="col-sm-12 heading-fsorb">	
                            <h1>SONEX classNameic Melamine Foam Acoustical Panels</h1>
                        </div>
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/prod_sonexclassNameicpanels.webp" data-lightbox="example-1">
                                <img className="example-image img-fluid" src={Sonexclass1} alt="SONEX classNameic1" /></Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Acoustic Foam Panels</h2>
                                <ul style={{paddingleft: "1em"}}>
                                    <li>Fiber Free</li>
                                    <li>Original Sculptured Foam Absorber</li>
                                    <li>High Acoustical Performance</li>
                                    <li>className A Fire Retardant</li>
                                </ul>
                                <p>SONEX classNameic™ acoustical foam panels that provide excellent sound and noise control where it’s needed the most. These sound dampening foam panels are trusted by professional sound studios everywhere.</p>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li><Link target="_blank" to="https://acousticalsurfaces.in/pdfs/sonex-classNameic-acoustical-panels.pdf" className="ico-misc">Product Specs</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Sonex classNameic Acoustical Foam Panel Product Specs</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                <tbody>
                                    <tr>
                                        <th>Material</th>
                                        <td>Melamine Acoustical Foam.</td>
                                    </tr>
                                    <tr>
                                        <th>Pattern</th>
                                        <td>Original Sculptured Surface. Modified Anechoic design.</td>
                                    </tr>
                                    <tr>
                                        <th>Features</th>
                                        <td>Increased absorption surface area.</td>
                                    </tr>
                                    <tr>
                                        <th>Applications</th>
                                        <td>This sound dampening foam panel is great for industrial facilities, churches, multi-purpose rooms, recording studios or other spaces that require acoustical control.</td>
                                    </tr>
                                    <tr>
                                        <th>Thickness</th>
                                        <td>2″</td>
                                    </tr>
                                    <tr>
                                        <th>Sizes</th>
                                        <td>2′×4′</td>
                                    </tr>
                                    <tr>
                                        <th>Density</th>
                                        <td>0.7lbs/cub. ft.</td>
                                    </tr>
                                    <tr>
                                        <th>Standard Colors</th>
                                        <td>White, Grey, Charcoal</td>
                                    </tr>
                                    <tr>
                                        <th>Optional Hpc-Coated Co, Lighlors</th>
                                        <td>Black, Light Grey, White, Almond, Medium Grey, Light Blue, Ivory.</td>
                                    </tr>
                                    <tr>
                                        <th>Flame Spread per ASTM E84</th>
                                        <td>className A. Natural: 5; Painted: 10; HPC-Coated: 5; Colortec: 10</td>
                                    </tr>
                                    <tr>
                                        <th>Smoke Density per ASTM E84</th>
                                        <td>className A. Natural: 50; Painted: 10; HPC-Coated: 90; Colortec: 10</td>
                                    </tr>
                                    <tr>
                                        <th>Installation</th>
                                        <td>ASI S.T.O.P. Noise Acoustical Adhesive</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <h3 style={{paddingleft: "1em"}}>Standard Colors</h3>
                            <div className="col-md-12 col-sm-12 fleximages">
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/sonex-white-200x155.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Sonexclass2} alt="SONEX classNameic2" /></Link>
                                    <span>White</span>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/sonex-grey1-200x155.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Sonexclass3} alt="SONEX classNameic3" /></Link>
                                    <span>Grey</span>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/sonex-charcoal1-200x155.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Sonexclass4} alt="SONEX classNameic4" /></Link>
                                    <span>Charcoal</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <h3 style={{marginleft: "1em", margintop: "1em"}}>Optional HPC-coated Colors</h3>
                            <div className="col-md-12 col-sm-12 fleximages">
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/sonex-hpc-black-200x155.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Sonexclass5} alt="SONEX classNameic5" /></Link>
                                    <span>Black</span>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/sonex-hpc-light-grey-200x155.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Sonexclass6} alt="SONEX classNameic6" /></Link>
                                    <span>Light Grey</span>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/sonex-hpc-white-200x155.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Sonexclass7} alt="SONEX classNameic7" /></Link>
                                    <span>White</span>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/sonex-hpc-almond-200x155.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Sonexclass8} alt="SONEX classNameic8" /></Link>
                                    <span>Almond</span>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/sonex-hpc-medium-grey-200x155.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Sonexclass9} alt="SONEX classNameic9" /></Link>
                                    <span>Medium Grey</span>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/sonex-hpc-light-blue-200x155.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Sonexclass10} alt="SONEX classNameic10" /></Link>
                                    <span>Light Blue</span>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" href="../../images/inner/sonex-hpc-ivory-200x155.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Sonexclass11} alt="SONEX classNameic11" /></Link>
                                    <span>Ivory</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <p></p>
                                <p><Link className="btn btn-asi" to="reverb-demo">Reverberation Demonstration</Link></p>
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="9">
                                            <h4>SONEX classNameic™ – Absorption Coefficients</h4></th>
                                        </tr>
                                    </thead>
                                    <tbody>     
                                        <tr className="colheader">
                                            <td>Thickness</td>
                                            <td>Finish</td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>NRC</td>
                                        </tr>
                                        <tr>
                                            <td>2″</td>
                                            <td>Natural</td>
                                            <td>0.05</td>
                                            <td>0.31</td>
                                            <td>0.81</td>
                                            <td>1.01</td>
                                            <td>0.99</td>
                                            <td>0.95</td>
                                            <td className="emphasis">0.80</td>
                                        </tr>
                                        <tr>
                                            <td>2″</td>
                                            <td>Painted</td>
                                            <td>0.17</td>
                                            <td>0.33</td>
                                            <td>0.85</td>
                                            <td>1.03</td>
                                            <td>1.08</td>
                                            <td>1.06</td>
                                            <td className="emphasis">0.80</td>
                                        </tr>
                                        <tr>
                                            <td>2″</td>
                                            <td>Colortec</td>
                                            <td>0.05</td>
                                            <td>0.31</td>
                                            <td>0.81</td>
                                            <td>0.96</td>
                                            <td>0.97</td>
                                            <td>0.97</td>
                                            <td className="emphasis">0.75</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acousticfoam;