import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Fabriwallpanel from '../../images/fabwall/panel1.webp';
import Fabriwallpanel1 from '../../images/fabwall/panel3.webp';
import Fabriwallpanel2 from '../../images/fabwall/panel4.webp';
import Fabriwallpanel3 from '../../images/fabwall/panel5.webp';
import Fabriwallpanel4 from '../../images/fabwall/panel6.webp';
import Card1 from '../../images/fabwall/card1.webp';
import Card2 from '../../images/fabwall/card2.webp';
import Card3 from '../../images/fabwall/card3.webp';
import Mobsidebar from '../mobile-sidebar';
import './fabriwallpanel.css';
import ScrollToTopButton from '../ScrollButton';
function fabriwallPanel() {
    return (
      
    <>
    <Helmet>
    <title>Fabric Wall Panels - interior acoustic solution</title>
    <meta name="description" content="" />
    <meta property="og:title" content="Absorption Panel" />
    <meta property="og:description" content="PANEL is the unseen element of the system installed inside Track's frame and fabric." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/fabwall/panel1.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/fabriwallpanel" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/fabriwallpanel" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/fabriwallpanel" hreflang="en-ae" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="IN" />
    <meta name="geo.region" content="india" />
    </Helmet>
    <Header />
    <section className="content" id="Fabriwallpanel">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
	                    <div className="col-sm-12 heading-fsorb">				
	                        <img src={Fabriwallpanel} alt="Fabriwall Panel" />
		                    <h1>Absorption <span className="fabriwall">PANEL</span></h1>
		                    <br />
		                    <p>PANEL is the unseen element of the system installed inside Track's frame and fabric. They remain hidden behind fabric and are having high sound absorption properties. Increase in hard surfaces in the modern buildings contribute largely to the increased sound propagation and excess noise levels these days.
                                The panels in Fabriwall helps in efficient sound absorption without compromising the aesthetics of the space.</p>
	                    </div>
                        <div className="col-sm-12 product1">
    	                    <div className="row no-gutters">
                                <div className="col-sm-12 col-md-6 pro1">
                                    <div className="probg"><img src={Fabriwallpanel1} alt="Fabriwall Panel1" /></div>
                                </div>
                                <div className="col-sm-12 col-md-6 pro2">
            	                    <h1>Echo Eliminator</h1>
                                    <p>Echo Eliminator is a high-performance acoustical material made from recycled cotton.
                                    Echo Eliminator cotton fabric panels are eligible for LEED™ credits, className A fire rated</p>
                                    <br />
                                    <ul>
                                        <li>Recycled Cotton</li>
		    		                    <li>className A – Non-Flammable</li>
				                        <li>Lightweight</li>
				                        <li>Easy to Install</li>
				                        <li>Low Cost</li>
				                        <li>High Light Reflectance</li>
				                        <li>High Acoustical Absorption</li>
				                        <li>Getting Upto 1 NRC by related thickness</li>
                                    </ul>
                                    <br />
                                    <div className="col text-center">
                                        <Link to="/echo-eliminator"><button type="button" className="brochure">For More</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
	                    <div className="col-sm-12 product1">
    	                    <div className="row no-gutters">
                                <div className="col-sm-12 col-md-6 order-2 order-md-1 pro2">
            	                    <h1>Polymax</h1>
                                    <p>Poly Max polyester tackable wall panels offer an acoustically absorbent panel that is non-allergenic,
                                    non-toxic, and contains no chemical irritants or formaldehyde</p>
                                    <br />
                                    <ul>
                                        <li>Formaldehyde Free</li>
				                        <li>No Binding Agents</li>
				                        <li>No Chemical Irritants</li>
				                        <li>Architecturally Decorative</li>
				                        <li>Tackable Surface</li>
				                        <li>Impact Resistant</li>
				                        <li>className A Fire Rated</li>
				                        <li>60% Polyethylene Terephthalate – Recycled Content</li>
                                    </ul>
                                    <br />
                                    <div className="col text-center">
                                        <Link to="/polymax-polyester"><button type="button" className="brochure">For More</button></Link>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 order-1 order-md-2  pro1">
                                    <div className="probg">
                                        <img src={Fabriwallpanel2} alt="Fabriwall Panel2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 product1">
    	                    <div className="row no-gutters">
                                <div className="col-sm-12 col-md-6 pro1">
                                    <div className="probg">
                                        <img src={Fabriwallpanel3} alt="Fabriwall Panel3" />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 pro2">
            	                    <h1>FOAM STOP</h1>
                                    <p>Echo Eliminator is a high-performance acoustical material made from recycled cotton. Echo Eliminator
                                    cotton fabric panels are eligible for LEED™ credits, className A fire rated</p>
                                    <br />
                                    <ul>
                                        <li>Recycled Cotton</li>
		    		                    <li>className A – Non-Flammable</li>
				                        <li>Lightweight</li>
				                        <li>Easy to Install</li>
				                        <li>Low Cost</li>
				                        <li>High Light Reflectance</li>
				                        <li>High Acoustical Absorption</li>
				                        <li>Getting Upto 1 NRC by related thickness</li>
                                    </ul>
                                    <br />
                                    <div className="col text-center">
                                        <Link to="/foamstop-pu"><button type="button" className="brochure">For More</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
	                    <div className="col-sm-12 mb-5 product1">
    	                    <div className="row no-gutters">
                                <div className="col-sm-12 col-md-6 order-2 order-md-1 pro2">
            	                    <h1>Fiber Board</h1>
                                    <p>Acoustic fiberglass as a form of audio insulation consists of rather small particles of compressed glass or plastic.</p>
                                    <br />
                                    <ul>
                                        <li>Recyclable Glass</li>
				                        <li>Fiberglass conducts thermal isolation</li>
				                        <li>Lightweight</li>
				                        <li>Easy to Install</li>
				                        <li>Low Cost</li>
				                        <li>High Light Reflectance</li>
				                        <li>High Acoustical Absorption</li>
				                        <li>Getting Upto 1 NRC by related thickness</li>
                                    </ul>
                                    <br />
                                    <div className="col text-center">
                                        <button type="button" className="brochure">For More</button>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 order-1 order-md-2  pro1">
                                    <div className="probg">
                                        <img src={Fabriwallpanel4} alt="Fabriwall Panel4" />
                                    </div>
                                </div>
                            </div>
                        </div>	
	                    <div className="col-sm-12 featured-products">
		                    <p>Fabri-Wall is the industry-leading acoustical stretched fabric mounting system for
			                walls, ceilings and other interior surfaces.</p>
		                    <br /><br />
		                    <h4>FEATURED PRODUCTS</h4>
		                    <br /><br />
		                    <div className="row pb-5">
	    	                    <div className="col-md-4 col-sm-12 featured-card">
		                            <div className="card text-center">
			                            <img className="card-img-top" src={Card1} alt="Interior Works" />
  			                            <div className="card-block">
    			                            <h4 className="card-title">Fabri<span>Wall</span> TRACK</h4>
    			                            <p className="card-text">Tracks are on-site fabricated acoustical systems that enables fabric be stretched for interior works.</p>
    			                            <Link to="/fabriwallTrack" className="btn btn-danger">View Track</Link>
  			                            </div>
		                            </div>
		                        </div>
			                    <div className="col-md-4 col-sm-12 featured-card">
			                        <div className="card text-center">
			                            <img className="card-img-top" src={Card2} alt="Polyster" />
  			                            <div className="card-block">
    			                            <h4 className="card-title">Fabri<span>Wall</span> FABRIC</h4>
    			                            <p className="card-text">We manufacture all kind of interior panel and upholstery fabrics using finest quality of Polyster</p>
    			                            <Link to="/fabriwallfabric" className="btn btn-danger">View Fabric</Link>
  			                            </div>
		                            </div>
		                        </div>
			                    <div className="col-md-4 col-sm-12 featured-card">
		    	                    <div className="card text-center">
			                            <img className="card-img-top" src={Card3} alt="Acoustic Solution" />
  			                            <div className="card-block">
    			                            <h4 className="card-title">Fabri<span>Wall</span> PANEL</h4>
    			                            <p className="card-text">Fabriwall Panel is an interior acoustic solution that absorbs noise and mitigates sound reverberation,</p>
    			                            <Link to="/fabriwallpanel" className="btn btn-danger">View Panel</Link>
  			                            </div>
		                            </div>
		                        </div>
		                    </div>
	                    </div>			
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default fabriwallPanel; 