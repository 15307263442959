import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Mfg1 from '../../images/inner/portscre1-200x200.webp';
import Mfg2 from '../../images/inner/cellulose-panels3-200x200.webp';
import Mfg3 from '../../images/inner/Soundsilencer-group3-200x200.webp';
import Mfg4 from '../../images/inner/ceiling_metal1-200x200.webp';
import Mfg5 from '../../images/inner/cotton-composite-small-200x200.webp';
import Mfg6 from '../../images/inner/mlvb3-200x200.webp';
import './mfg-industrial.css';
import ScrollToTopButton from '../ScrollButton';
function mfg() {
    return (
      
    <>
    <Helmet>
    <title>Manufacturing and Industrial Sound Control Products</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Industrial Soundproofing Products" />
    <meta property="og:description" content="We provide many industrial acoustics products that have been engineered to reduce sound transmission and contain noise."/>
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/portscre1-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/mfg-industrial" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/mfg-industrial" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/mfg-industrial" />
    </Helmet>
    <Header />
    <section className="content" id="mfg">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Manufacturing and Industrial Sound Control Products</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>Maintaining suitable noise levels in a manufacturing or industrial environment is often a very challenging problem. The output from machinery can be deafening and keeping the noise within acceptable OSHA levels is important for the health and well-being of employees. We provide many industrial acoustics products that have been engineered to reduce sound transmission and contain noise.</p>
                                    <p>By offering a large variety of noise control products, we have the capabilities to reduce noise levels within any size or shape manufacturing or industrial facility. Available as wall panels, ceiling tiles, enclosures, barriers, or architectural elements, noise control products are constructed from polypropylene, cotton fiber composite, vinyl, or cellulose. With such wide-ranging options, it is easy to create an effective industrial acoustics solution for the most difficult noise problem.</p>
                                    <p>Whether the noise problems are inside the plant or outside, we will have an ideal resolution to improve working conditions. Above are some of our outstanding products often used for sound control in Manufacturing/Industrial facilities. <Link to="/contact-us">Contact us</Link> today to learn more.</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>Industrial Soundproofing Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Mfg1} alt="BSC-25 Quilted Walls" />
                                </Link>
                                <h5>BSC-25 Quilted Walls Enclosures</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Mfg2} alt="CFAB Cellulose Panels" />
                                </Link>
                                <h5>CFAB Cellulose Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Mfg3} alt="Sound Silencer Panels" />
                                </Link>
                                <h5>Sound Silencer panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Mfg4} alt="Perforated Metal Panels" />
                                </Link>
                                <h5>Perforated Metal Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Mfg5} alt="Echo Eliminator" />
                                </Link>
                                <h5>Echo Eliminator 8# composite</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Mfg6} alt="Clear Mass Loaded Vinyl" />
                                </Link>
                                <h5>Clear Mass Loaded Vinyl</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default mfg; 