import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundsilencer1 from '../../images/inner/Charcoal_up_lg1-200x200.webp';
import Soundsilencer2 from '../../images/inner/ssabaffle12.webp';
import Soundsilencer3 from '../../images/inner/white_up_lg3-200x200.webp';
import Soundsilencer4 from '../../images/inner/ss_acoust1.webp';
import Soundsilencer5 from '../../images/inner/Acoustical-Baffles-1-200x200.webp';
import Soundsilencer6 from '../../images/inner/Acoustical-Baffles-2-200x200.webp';
import './sound-silencer-baffles.css';
import ScrollToTopButton from '../ScrollButton';
function soundsilencerbaffles() {
    return (
      
    <>
    <Helmet>
    <title>Acoustic Decorative Sound Baffles for walls and ceilings</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="SOUND SILENCER BAFFLES" />
    <meta property="og:description" content="ACOUSTICAL BAFFLES" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/ssabaffle12.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/sound-silencer-baffles" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/sound-silencer-baffles" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/sound-silencer-baffles" />
    </Helmet>
    <Header />
    <section className="content" id="silencer">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>SOUND SILENCER™</h1> 
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/ssabaffle12.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Soundsilencer1} alt="Sound Silencer Baffles1" />
                                </Link>
                                <div className="row col-md-12 col-lg-12 minigallery">
                                    <div className='col-lg-4 col-md-2'>
                                        <Link className="example-image-link" to="../../images/inner/Charcoal_up_lg1-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" style={{maxwidth: "5em", marginright: ".5em"}} src={Soundsilencer2} alt="Sound Silencer Baffles2" />
                                        </Link>
                                    </div>
                                    <div className='col-lg-4 col-md-2'>
                                        <Link className="example-image-link" to="../../images/inner/white_up_lg3-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" style={{maxwidth: "5em", marginright: ".5em"}} src={Soundsilencer3} alt="Sound Silencer Baffles3" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>ACOUSTICAL BAFFLES</h2>
                                <ul style={{marginleft: "1em"}}>                                                   
                                    <li>No Fiberglass Lightweight</li>
                                    <li>Moisture Resistant</li>
                                    <li>Economical</li>
                                    <li>Impact Resistant Washable</li>
                                </ul>  
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Product Specs</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>MATERIAL</th>
                                            <td>Porous Expanded Polypropylene (P.E.P.P.), Fiberglass Free. </td>
                                        </tr>
                                        <tr>
                                            <th>PATTERN</th>
                                            <td>Slightly Textured Beaded Surface.</td>
                                        </tr>
                                        <tr>
                                            <th>FEATURES</th>
                                            <td>Lightweight, Multi-Purpose, Moisture Resistant, Durable, Cleanable.</td>
                                        </tr>
                                        <tr>
                                            <th>APPLICATIONS</th>
                                            <td>Gymnasiums, Auditoriums, Classrooms, Music Rooms, Arenas & Stadiums, Churches, Offices, Computer Rooms, Restaurants/Cafeterias, Concert & Theaters, Pro Audio, Gun Ranges, Manufacturing Plants, Machine Enclosures, Water Treatment Plants, Swimming Pools, Food Processing Plants, Animal Hospitals.</td>
                                        </tr>
                                        <tr>
                                            <th>THICKNESS</th>
                                            <td>1″, 2″</td>
                                        </tr>
                                        <tr>
                                            <th>SIZES</th>
                                            <td>2′ × 4′</td>
                                        </tr>
                                        <tr>
                                            <th>COLOR</th>
                                            <td>2′ × 4′</td>
                                        </tr>
                                        <tr>
                                            <th>FLAMMABILITY</th>
                                            <td>ASTM E84, Class A.<br />
                                                Flame Spread: 1″ – 3″, 2″ – 5″<br />
                                                Smoke Developed: 1″ – 84″, 2″ – 113″
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="8">Sound Silencer Baffle Sabins/Baffle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td></td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>Ave</td>
                                        </tr>
                                        <tr>
                                            <td>1″ 2′ × 4′</td>
                                            <td>0.82</td>
                                            <td>2.18</td>
                                            <td>2.79</td>
                                            <td>3.03</td>
                                            <td>6.29</td>
                                            <td>8.36</td>
                                            <td className="emphasis">3.55</td>
                                        </tr>
                                        <tr>
                                            <td>2″ 2′ × 4′</td>
                                            <td>1.17</td>
                                            <td>2.53</td>
                                            <td>3.67</td>
                                            <td>6.61</td>
                                            <td>9.39</td>
                                            <td>10.64</td>
                                            <td className="emphasis">5.55</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="images/inner/ss_acoust1.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Soundsilencer4} alt="Sound Silencer Baffles4" />
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h2 style={{marginleft: "1em"}}>Product Photos</h2>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="images/inner/Acoustical-Baffles-1-200x200.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Soundsilencer5} alt="Sound Silencer Baffles5" />
                                </Link>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="images/inner/Acoustical-Baffles-2-200x200.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Soundsilencer6} alt="Sound Silencer Baffles6" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default soundsilencerbaffles; 