import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Cotton1 from '../../images/inner/echo-eggcrate-graphite.webp';
import Cotton2 from '../../images/inner/echo-eggcrate-graphite-2.webp';
import './cotton-soundwave-eggcrate-panels.css';
import ScrollToTopButton from '../ScrollButton';
function cotton() {
    return (
      
    <>
    <Helmet>
    <title>Cotton Soundwave Eggcrate Echo Eliminator Acoustic Panels</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Echo Eliminator Cotton Soundwave Eggcrate Acoustical Panel" />
    <meta property="og:description" content="ASI is proud to introduce the market’s first recycled cotton, high acoustical performance studio wall panel." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/echo-eggcrate-graphite.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/cotton-soundwave-eggcrate-panels" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/cotton-soundwave-eggcrate-panels" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/cotton-soundwave-eggcrate-panels" />
    </Helmet>
    <Header />
    <section className="content" id="cottonsoundwave">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
                    <h1 className='cottonsoundwave-content'>Echo Eliminator™ Cotton Soundwave Eggcrate Acoustical Panel</h1>
                    <div className="row pad cottonsoundwave-content">
                        <div className="col-xl-4 col-sm-12">
                            <Link className="example-image-link" to="../../images/inner/echo-eggcrate-graphite.webp" data-lightbox="example-1">
                                <img className="example-image img-fluid" src={Cotton1} alt="Soundwave Eggcrate1" />
                            </Link>
                            <Link className="example-image-link" to="../../images/inner/echo-eggcrate-graphite-2.webp" data-lightbox="example-1">
                                <img className="example-image img-fluid" src={Cotton2} alt="Soundwave Eggcrate2" />
                            </Link>
                        </div>
                        <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                            <p>ASI is proud to introduce the market’s first recycled cotton,  high acoustical performance studio wall panel. The Soundwave panel is made from 85% recycled cotton and is 100% recyclable.</p>
                            <ul style={{marginleft: "1em"}}>
                                <li>Recycled Cotton (Green Acoustical Material)</li>
                                <li>className A – Non-Flammable (Per ASTM E-84)</li>
                                <li>Lightweight</li>
                                <li>Easy to Install (adhesively applied)</li>
                                <li>Impact Resistant</li>
                                <li>Durable</li>
                                <li>Low Cost</li>
                                <li>Fiberglass Free</li>
                                <li>No Formaldehyde – No VOC’S</li>
                                <li>Hi-Performance Acoustical Absorption</li>
                                <li>Proudly Made in the USA</li>
                                <li>LEED™ Eligible 
                                    <Link to="http://www.usgbc.org/" target="_blank" style={{color: "#0059a5"}}>(more information)</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                            <div className="testing-info">
                                <h3>Product Testing &amp; Information</h3>
                                <ul className="">
                                    <li><Link to="https://acousticalsurfaces.in/pdfs/Echo_Eggcrate.pdf" target="_blank" className="ico-misc">Product Specs</Link></li>
                                    <li><Link to="https://acousticalsurfaces.in/pdfs/MSDS_BAP.pdf" target="_blank" className="ico-misc">MSDS</Link></li>
                                    <li><Link to="https://acousticalsurfaces.in/pdfs/Install.pdf" target="_blank" className="ico-misc">Installation</Link></li>
                                    <li><Link to="https://acousticalsurfaces.in/pdfs/09844_Acoustical_Cotton_Soundwave_Eggcrate_Panels.doc" target="_blank" className="ico-misc">CSI Specifications</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="line"></div>
                    </div>
                    <div className="row cottonsoundwave-content">
                        <div className="col-sm-12">
                            <h3>Product Specs</h3>
                            <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                <tbody>
                                    <tr>
                                        <th>Applications</th>
                                        <td>Recycled Cotton Soundwave panels are ideally suited for studios, school gymnasiums, cafeterias, classNamerooms, churches, multi-purpose rooms, community centers and more. </td>
                                    </tr>
                                    <tr>
                                        <th>Size </th>
                                        <td>2′ X 4′</td>
                                    </tr>
                                    <tr>
                                        <th>Thickness </th>
                                        <td>2″</td>
                                    </tr>
                                    <tr>
                                        <th>Color</th>
                                        <td>Graphite</td>
                                    </tr>               
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row cottonsoundwave-content">
                        <div className="col-sm-12 text">
                            <p><strong>Please note:</strong> Since these panels have a male/female pattern (convolute), each panel is slightly different on it’s edge pattern. Panels will not line up to make one large uniform pattern.</p>
                            <p>Although we take extensive precautions and purge the lines between colors, due to the manufacturing process color inconsistencies – including dye lot variations – flecks, specks, and other slight surface blemishes are unavoidable. Please call for details.</p>
                            <p><i>All sizes are nominal and are subject to manufacturing tolerances that may vary ± 1/8″. Exact fitting may require on site adjustment by the purchaser. Exact size and other unavoidable variables such as dye lot color variances, lighting conditions, pattern matching,directional materials, expansion and contraction due to environmental conditions. Project with exacted visual requirements and expectation must be identified for our special attention prior to ordering.</i></p>
                            <p><Link className="btn btn-asi" to="/reverb-demo">Reverberation Demonstration</Link></p>
                        </div>
                    </div>
                    <div className="row cottonsoundwave-content">
                        <div className="col-sm-12">
                            <table className="table table-bordered-2">
                                <thead>
                                    <tr>
                                        <th colSpan="9">Echo Eliminator&trade; Soundwave Panel&nbsp;&ndash; Sound Absorption Coefficients</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="colheader">
                                        <td></td>
                                        <td>Mount</td>
                                        <td>125Hz</td>
                                        <td>250Hz</td>
                                        <td>500Hz</td>  
                                        <td>1KHz</td>
                                        <td>2KHz</td>
                                        <td>4KHz</td>
                                        <td>NRC</td>
                                    </tr>
                                    <tr>
                                        <td className="colheader">2″&nbsp;&ndash; 3lb./cf.</td>
                                        <td>A</td>
                                        <td>.35</td>
                                        <td>.94</td>
                                        <td>1.32</td>
                                        <td>1.22</td>
                                        <td>1.06</td>
                                        <td>1.03</td>
                                        <td>1.15</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default cotton;