import React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Petpanel from '../../images/petpanel.webp';
import Mobsidebar from '../mobile-sidebar';
import Sliderpet from './slider-pet';
import ScrollToTopButton from '../ScrollButton';
function petpanel() {
    return (
      
    <>
    <Helmet>
    <title>PET Panels are designed to control reverberated noise</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="PET Panel" />
    <meta property="og:description" content="PET Acoustic Panels are designed to control reverberated noise." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/flet-sm-1.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/pet-panel" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/pet-panel" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/pet-panel" />
    </Helmet>
    <Header />
    <section className="content">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
	                    <div className="col-sm-12 heading-fsorb">				
	                        <h1>PET Acoustic Panel</h1>
	                        <p>PET Panels are highly absorptive acoustic panels with non-woven polyester fiber as its core. They form an effective remedy for reverberation related issues in interior
                            spaces and are customizable to any design depending upon the customer demands. These Pet panels are available in 21 different color options.</p>
	                        <Sliderpet />
	                    </div>
                        <div className="col-sm-12 features">
	                        <h1>Applications</h1>
	                        <div className="line"></div>
	                        <p>These Pet acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                        	<div className="row pad ">	
		                        <div className="col-md-4 col-sm-12 d-none d-md-block">
	                                <ul>
		                                <li>Home</li>
		                                <li>Theatres</li>
		                                <li>Conference Rooms</li>
		                                <li>Hospitals</li>
                                    </ul>
		                        </div>
		                        <div className="col-md-4 col-sm-12 d-none d-md-block">
	                                <ul>
		                                <li>Convention Centres</li>
		                                <li>Banquet Halls</li>
		                                <li>Cafeterias</li>
		                                <li>Multiplexes</li>
                                    </ul>
		                        </div>
		                        <div className="col-md-4 col-sm-12 d-none d-md-block">
	                                <ul>
		                                <li>Colleges</li>
		                                <li>Factories</li>
		                                <li>Open Work Spaces</li>
		                                <li>Auditoriums</li>
                                    </ul>
		                        </div>
	                        </div>
                        </div>
                        <div className="col-sm-12 features">
                            <img src={Petpanel} width="100%" alt="PET Panel" />
	                        <h1>Specifications</h1>
	                        <div className="line"></div>
	                        <div className="row pad ">	
		                        <div className="col-lg-9 col-xl-12 col-md-4 col-sm-12 d-none d-md-block">
	                                <ul>
		                                <li>COMPOSITION: 100% PET</li>
		                                <li>WEIGHT: Varies</li>
		                                <li>THICKNESS: 10 MM</li>
		                                <li>PANEL DIMENSIONS: Varies based on the design. Custom dimensions are possible</li>
                                    </ul>
		                        </div>
	                        </div>
                        </div>
                        <div className="col-sm-12 features">
                            <h1>Performance</h1>
	                        <div className="line"></div>
	                        <div className="row pad ">	
		                        <div className="col-lg-9 col-xl-12 col-md-4 col-sm-12 d-none d-md-block">
	                                <ul>
		                                <li>High Acoustic Performance – NRC up to 0.9, className A as per ASTM C-423</li>
		                                <li>Fire Resistance - B – s1 d0 classNameified as per EN 13501-1:2007+A1:2009</li>
		                                <li>LEED eligible</li>
		                                <li>High colorfastness</li>
		                                <li>High resistance to humidity, moisture, bacteria & fungi</li>
		                                <li>Low VOC & No formaldehyde</li>
		                                <li>High impact resistance</li>
                                    </ul>
		                        </div>
	                        </div>
                        </div>
                    	<div className="line"></div>			
	                </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
		<ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default petpanel; 