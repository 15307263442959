import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Doorpiano from '../../images/inner/soundproof-door-piano-room-st-olaf-350x585.webp';
import Doorstudio from '../../images/inner/studio-door-lite-kit-200x200.webp';
import Door2 from '../../images/inner/double-200x200.webp';
import Door3 from '../../images/inner/Sound-Proof-Door-3-200x200.webp';
import Handle1 from '../../images/inner/door_handle_passage_only-150x200.webp';
import Handle2 from '../../images/inner/door_handle_keyed_deadbolt-150x200.webp';
import Handle3 from '../../images/inner/door_handle_deadbolt-150x200.webp';
import Innerdoor1 from '../../images/inner/door-thickness-both-lg-200x200.webp';
import Innerdoor2 from '../../images/inner/drop_seal-200x200.webp';
import Innerdoor3 from '../../images/inner/door_with_kit_ex-200x200.webp';
import Innerdoor4 from '../../images/inner/with_cap_kit-200x200.webp';
import Window1 from '../../images/inner/window_lite_kit-200x200.webp';
import Window2 from '../../images/inner/soundproof-door-with-window-200x200.webp';
import Window3 from '../../images/inner/double-200x200.webp';
import Window4 from '../../images/inner/acoustical-double-door-200x200.webp';
import Swing1 from '../../images/inner/Left_Hand_Swing_sm.webp';
import Swing2 from '../../images/inner/Right-Hand-Swing_sm.webp';
import Activedoor1 from '../../images/inner/left_hand_active_double_door_sm.webp';
import Activedoor2 from '../../images/inner/right_hand_active_double_door_sm1.webp';
import Mobsidebar from '../mobile-sidebar';
import './soundproof-interior-doors.css';
import ScrollToTopButton from '../ScrollButton';
function soundproofint() {
    return (
      
    <>
    <Helmet>
    <title>Acoustic Doors - Studio 3D Soundproof Interior Doors</title>
    <meta name="description" content="We are the leading acoustic products manufacturer in India providing a wide range of acoustic doors, acoustic walls, absorbers, diffusers and other customized acoustical solutions." />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Studio 3D™ Soundproof Interior Doors" />
    <meta property="og:description" content="The Studio 3D soundproof doors are ideal for recording studios, offices, band rooms, hotels, dorm rooms, conference rooms or any application where a soundproof interior door is required." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/soundproof-door-piano-room-st-olaf-350x585.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acoustydoor" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/soundproof-interior-doors" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/soundproof-interior-doors" />
    </Helmet>
    <Header />
    <section className="content" id="interiordoor">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Studio 3D™ Soundproof Interior Doors </h1>
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/soundproof-door-piano-room-st-olaf-350x585.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Doorpiano} alt="Soundprrof Door Piano" />
                                </Link>
                                <div className="row d-none d-lg-block d-xl-block">
                                    <div className="col-xl-4" style={{float: "left"}}>
                                        <Link className="example-image-link" to="../../images/inner/studio-door-lite-kit-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Doorstudio} alt="Soundproof Studio Door" />
                                        </Link>
                                    </div>
                                    <div className="col-xl-4" style={{float: "left"}}>
                                        <Link className="example-image-link" to="../../images/inner/double-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Door2} alt="Soundproof Double Door" />
                                        </Link>
                                    </div>
                                    <div className="col-xl-4" style={{float: "left"}}>
                                        <Link className="example-image-link" to="../../images/inner/Sound-Proof-Door-3-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Door3} alt="Soundproof Type3 Door" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 text">
                                <h2>Studio 3D™ Soundproof Interior Doors </h2>
                                <p>ASI is proud to offer the markets first affordable soundproof interior door. Our recording studio doors have an STC value up to 56. The Studio 3D soundproof doors are ideal for recording studios, offices, band rooms, hotels, dorm rooms, conference rooms or any application where a soundproof interior door is required. The soundproofing doors come standard in oak, ready to stain with silver hardware. Custom finishes and hardware options are available for this door sound insulation product.</p>
                                <ul style={{marginleft: ".9em", fontsize: ".9em"}}>
                                    <li>Low Cost – High Performance</li>
                                    <li>STC Ratings Up To 56</li>
                                    <li>Products Tested per ASTM E-90</li>
                                    <li>Custom Manufactured Acoustic Door to Any Size Specification</li>
                                    <li>Appx. 300 lb Door Assembly</li>
                                    <li>2¾″ 13 Layer Door Assembly</li>
                                    <li>Comes Pre-Hung and Assembled</li>
                                    <li>Adjustable Soundproof Jambs Seals</li>
                                    <li>Ready To Stain/Paint to Match Your Decor</li>
                                    <li>Best Performance Wood Finished Door Assembly Available</li>
                                    <li>Automatic Sill</li>
                                    <li>Heavy Duty Hinges (4)</li>
                                    <li>3-Point Latching System</li>
                                </ul>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Soundproofing Door & Frame Features</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>STC Ratings</th>
                                            <td>Soundproof interior doors are available with ratings up to STC 56</td>
                                        </tr>
                                        <tr>
                                            <th>Testing</th>
                                            <td>Door products are tested in accordance with the most recent ASTM E-90 standards at accredited Acoustical Laboratories.</td>
                                        </tr>
                                        <tr>
                                            <th>Frame Configuration</th>
                                            <td>In addition to our standard frame designs, we can engineer special frame profiles to complement wall construction and architectural style.</td>
                                        </tr>
                                        <tr>
                                            <th>Services</th>
                                            <td>ASI will provide the necessary shop drawings, certification of test results and assistance with compliance for all industry standards.</td>
                                        </tr>
                                        <tr>
                                            <th>Guarantee</th>
                                            <td>Studio 3D recording studio doors are guaranteed to be free of defects in workmanship and quality for a period of two years.</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h3>Soundproofing Door Specific Features</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>Configuration</th>
                                            <td>Recording studio doors are available in a flush design or with vision elite and as single or pairs of hinged doors.</td>
                                        </tr>
                                        <tr>
                                            <th>Sizes</th>
                                            <td>Select from standard or custom sizes. We also offer double door pairs as large as 8′ W × 12′ H.</td>
                                        </tr>
                                        <tr>
                                            <th>Hardware</th>
                                            <td>Door, frame, trim, handle, hinges, perimeter sound seals and door bottom are supplied as part of the tested assembly. Dead bolt mortise locks also available.</td>
                                        </tr>
                                        <tr>
                                            <th>Finishes</th>
                                            <td>Oak, ready to stain. Optional maple, cherry, birch and any other wood species are available.</td>
                                        </tr>
                                        <tr>
                                            <th>Hardware Color</th>
                                            <td>Satin Chrome standard. 7 additional custom order finishes available.</td>
                                        </tr>
                                        <tr>
                                            <th>Required Installation Kit Includes</th>
                                            <td> 
                                                <p>2 Packs of Shims<br />
                                                    4 Heavy Duty Jamb Screws<br />
                                                    Backer Rod<br />   
                                                    1 Tube Acoustical Sealant
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Lead Time</th>
                                            <td>Typically 3-4 weeks. Please check with our professional sales associate to verify actual lead time.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-12 col-sm-12 fleximages">
                                <h3>Standard Hardware</h3>
                                <div className="imagebox">
                                    <img src={Handle1} className="img-fluid" alt="Door Handle1" />
                                    <span>Venice – Passage Only</span>
                                </div>
                                <div className="imagebox">
                                    <img src={Handle2} className="img-fluid" alt="Door Handle2" />
                                    <span>Venice – Keyed Deadbolt</span>
                                </div>
                                <div className="imagebox">
                                    <img src={Handle3} className="img-fluid" alt="Door Handle3" />
                                    <span>Venice – Thumb Turn Deadbolt</span>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <Link to="https://acousticalsurfaces.in/pdfs/ASI-Door-Hardware.pdf" target="_blank" rel="noopener" style={{textdecoration: "underline", color: "#0059a5"}}>Download Hardware Options</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 fleximages">
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/door-thickness-both-lg-200x200.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Innerdoor1} alt="Inner Door1" />
                                        <span>2¾″ Door Thickness</span>
                                    </Link>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/drop_seal-200x200.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Innerdoor2} alt="Inner Door2" />
                                        <span>Mortise Drop Seal & Strike Plate</span>
                                    </Link>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/door_with_kit_ex-200x200.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Innerdoor3} alt="Inner Door3" />
                                        <span>Exposed Jamb Kit</span>
                                    </Link>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/with_cap_kit-200x200.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Innerdoor4} alt="Inner Door4" />
                                        <span>Wood Caps Over Jamb Kit</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 fleximages">
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/window_lite_kit-200x200.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Window1} alt="Window lite1" />
                                        <span>Window Lite Kit</span>
                                    </Link>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/soundproof-door-with-window-200x200.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Window2} alt="Soundproof with Window" />
                                        <span>Smaller Window Kit</span>
                                    </Link>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/double-200x200.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Window3} alt="Window Double" />
                                        <span>Double Door</span>
                                    </Link>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/acoustical-double-door-200x200.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Window4} alt="Acoustic Window" />
                                        <span>Double Door</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 fleximages">
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/Left_Hand_Swing_sm.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Swing1} alt="Left Hand Swing" />
                                        <span>Left Hand Swing</span>
                                    </Link>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/Right-Hand-Swing_sm.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Swing2} alt="Right Hand Swing" />
                                        <span>Right Hand Swing</span>
                                    </Link>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/left_hand_active_double_door_sm.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Activedoor1} alt="Left Hand Active Door" />
                                        <span>Left Hand Active – Double Door</span>
                                    </Link>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/right_hand_active_double_door_sm1.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Activedoor2} alt="Right Hand Active Door" />
                                        <span>Right Hand Active – Double Door</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="line" style={{margintop: "2em"}}></div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-sm-12 ytvid-2">
                                <h4 style={{padding: "1em 0"}}>Watch our soundproof door installation video below. Click the video to play/pause.</h4>
                                <iframe allowfullscreen="allowfullscreen"  src="https://www.youtube.com/embed/uRStMyJL9Hw" title="soundproof interior"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default soundproofint;