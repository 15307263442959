import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Download1 from '../../images/wudwool/dwnld.png';
import Download2 from '../../images/btn-download-brochure.png';
import Download3 from '../../images/btn-download-color.png';
import Mobsidebar from '../mobile-sidebar';
import './fab-downloads.css';
import ScrollToTopButton from '../ScrollButton';
function fab() {
    return (
      
    <>
    <Helmet>
    <title>NOISE S.T.O.P Fabrisorb - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="Soundproof Fabrisorb Product Specifications" />
    <meta property="og:description" content="" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.png" />
    <meta property="og:locale" content="en-in" />
    </Helmet>
    <Header />
    <section className="content" id="fabdownloads">
      <div className="container">
        <div className="row">
          <div className="col-sm-3 sidemenu">
            <Sidebar />
          </div>
          <div className="col-sm-9 product-page nopaddding">
            <div className="container thanks">
              <div className="megamenu">
                <Megamenu />
              </div>
					    <div className="col-sm-12 heading-fsorb">				
		            <h1>NOISE S.T.O.P Fabrisorb<sup>TM</sup> Downloads</h1>
              </div>
	            <br />
	            <div className="accordion-started accordion-bral panels row">
			          <div className="col-sm-12 col-md-12 col-lg-12 ">
			            <input className="ac-input" id="ac-1"  name="accordion-1" type="radio" />
				          <label className="ac-label" for="ac-1">
                    <b>Product Specifications</b><i></i>
                  </label>
			            <div className="article ac-content">
			              <table className="table">
                      <tbody>
	                      <tr> 
		                      <td width="90%"><heading>Download</heading></td> 
		                      <td width="10%">
                            <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/Fabric-Wrapped-Acoustical-Wall-Panels.pdf" target='_blank'>
                              <img id="change" className="w-auto" src={Download1} alt="Download1" />View
                            </Link>
                          </td>      
                        </tr> 
                      </tbody>
                    </table>
                  </div>
			          </div>
			          <div className="col-sm-12 col-md-12 col-lg-12">
			            <input className="ac-input" id="ac-3" name="accordion-1" type="radio" />
				          <label className="ac-label" for="ac-3">
                    <b>Acoustical Test</b><i></i>
                  </label>
							    <div className="article ac-content">
                    <table className="table">
                      <tbody>
	                      <tr> 
		                      <td width="90%"><heading>ASTM C423-90a 4′ × 8′ × 1-1/8″</heading></td> 
                          <td width="10%">
                            <Link to="pdfs/FABRISORB/1-18_IH.PDF">
                              <img id="change" className="w-auto" src={Download1} alt="Download2" /> View
                            </Link>
                          </td>      
                        </tr>
                        <tr> 
		                      <td width="90%"><heading>ASTM C423-90a 4′ × 8′ × 1″</heading></td> 
                          <td width="10%">
                            <Link to="pdfs/FABRISORB/1_301C9.PDF">
                              <img id="change" className="w-auto" src={Download1} alt="Download3" />View
                            </Link>
                          </td>      
                        </tr>
                        <tr> 
		                      <td width="90%"><heading>ASTM C423-90a 4′ × 8′ × 2-1/8″</heading></td> 
                          <td width="10%">
                            <Link to="pdfs/FABRISORB/2-18_IK.PDF">
                              <img id="change" className="w-auto" src={Download1} alt="Download4" />View
                            </Link>
                          </td>      
                        </tr>
	                      <tr> 
		                      <td width="90%"><heading>ASTM C423-90a 4′ × 8′ × 2″</heading></td> 
                          <td width="10%">
                            <Link to="pdfs/FABRISORB/2_301B.PDF">
                              <img id="change" className="w-auto" src={Download1} alt="Download5" />View
                            </Link>
                          </td>      
                        </tr>
	                      <tr> 
		                      <td width="90%"><heading>Decorative Fabric Wrapped</heading></td> 
                          <td width="10%">
                            <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/Acous_Tests.pdf">
                              <img id="change" className="w-auto" src={Download1} alt="Download6" />View
                            </Link>
                          </td>      
                        </tr>
                        <tr> 
		                      <td width="90%"><heading>ASTM C423-90a Type A – Diffuser</heading></td> 
                          <td width="10%">
                            <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/Diffusers.pdf">
                              <img id="change" className="w-auto" src={Download1} alt="Download7" />View
                            </Link>
                          </td>      
                        </tr>
	                      <tr> 
		                      <td width="90%"><heading>1-1/4″ Tuned Panel 5% Open Area Perforated Hardboard</heading></td> 
                          <td width="10%">
                            <Link to="pdfs/FABRISORB/1-14_F.PDF">
                              <img id="change" className="w-auto" src={Download1} alt="Download8" />View
                            </Link>
                          </td>      
                        </tr> 
	                    </tbody>
                    </table>
		              </div>
		            </div>
		            <div className="col-sm-12 col-md-12 col-lg-12">
			            <input className="ac-input" id="ac-3" name="accordion-1" type="radio" />
				          <label className="ac-label" for="ac-3">
                    <b>MSDS</b><i></i>
                  </label>
							    <div className="article ac-content">
                    <table className="table">
                      <tbody>
	                      <tr> 
		                      <td width="90%"><heading>EFI Edge Hardener MSDS</heading></td> 
                          <td width="10%">
                            <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/Edge-Hardener-MSDS-Fabric-Wrapped-Fiberglass-Panels.pdf">
                              <img id="change" className="w-auto" src={Download1} alt="Download9" /> View
                            </Link>
                          </td>      
                        </tr>
                        <tr> 
		                      <td width="90%"><heading>Fiber Glass Specialty Insulation</heading></td> 
                          <td width="10%">
                            <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/Fiberglass_1001.pdf">
                              <img id="change" className="w-auto" src={Download1} alt="Download10" />View
                            </Link>
                          </td>      
                        </tr>
                        <tr> 
		                      <td width="90%"><heading>Fiber Glass Residential Insulation Material Safety Data Sheet</heading></td> 
                          <td width="10%">
                            <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/Fiberglass_1010.pdf">
                              <img id="change" className="w-auto" src={Download1} alt="Download11" />View
                            </Link>
                          </td>      
                        </tr>
                        <tr> 
		                      <td width="90%"><heading>Specialty Fiber Glass Insulation Sheet</heading></td> 
                          <td width="10%">
                            <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/Fiberglass_1041.pdf">
                              <img id="change" className="w-auto" src={Download1} alt="Download12" />View
                            </Link>
                          </td>      
                        </tr>
	                      <tr> 
		                      <td width="90%"><heading>FR 701® Style 2100 100% Terratex Polyester Panel Fabric</heading></td> 
                          <td width="10%">
                            <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/Gilford_MSDS.pdf">
                              <img id="change" className="w-auto" src={Download1} alt="Download13" />View
                            </Link>
                          </td>      
                        </tr>
                        <tr> 
		                      <td width="90%"><heading>Cured Fibrous Glass Wool Insulation, Faced or Unfaced</heading></td> 
                          <td width="10%">
                            <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/MAP_Fiberglass.pdf">
                              <img id="change" className="w-auto" src={Download1} alt="Download14" />View
                            </Link>
                          </td>      
                        </tr>
                        <tr> 
		                      <td width="90%"><heading>Fiberglass Core MSDS</heading></td> 
                          <td width="10%">
                            <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/Fiberglass-Core-MSDS.pdf">
                              <img id="change" className="w-auto" src={Download1} alt="Download15" />View
                            </Link>
                          </td>      
                        </tr> 
                        <tr> 
		                      <td width="90%"><heading>Face Adhesive MSDS</heading></td> 
                          <td width="10%">
                            <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/Face-Adhesive-MSDS-Fabric-Wrapped-Fiberglass-Panels.pdf">
                              <img id="change" className="w-auto" src={Download1} alt="Download16" />View
                            </Link>
                          </td>      
                        </tr> 
                      </tbody>
                    </table>
		              </div>
		            </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
			            <input className="ac-input" id="ac-4" name="accordion-1" type="radio" />
				          <label className="ac-label mb-0" for="ac-4">
                    <b>Flammability</b><i></i>
                  </label>
						      <div className="article ac-content">
		                <table className="table">
                      <tbody>
	                      <tr> 
		                      <td width="90%"><heading>Download</heading></td> 
		                      <td width="10%">
                            <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/Fabric_Flame.pdf">
                              <img id="change" className="w-auto" src={Download1} alt="Download17" />View
                            </Link>
                          </td>      
                        </tr> 
                      </tbody>
                    </table>
			            </div>
			          </div>
	              <div className="col-sm-12 col-md-12 col-lg-12">
			            <input className="ac-input" id="ac-4" name="accordion-1" type="radio" />
				          <label className="ac-label mb-0" for="ac-4">
                    <b>Installation</b><i></i>
                  </label>
						      <div className="article ac-content">
			              <table className="table">
                      <tbody>
	                      <tr> 
		                      <td width="90%"><heading>Installation Instructions – Z-Clip</heading></td>
		                      <td width="10%">
                            <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/AS_NoiseSTOPFabrisorb_Install_Wall_ZClip.pdf">
                              <img id="change" className="w-auto" src={Download1} alt="Download18" />View
                            </Link>
                          </td>      
                        </tr> 
                        <tr> 
		                      <td width="90%"><heading>Installation Instructions – Impaling Clips</heading></td>
		                      <td width="10%">
                            <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/Impaling_Clips_Installation_Instructions.pdf">
                              <img id="change" className="w-auto" src={Download1} alt="Download19" />View
                            </Link>
                          </td>      
                        </tr> 
                        <tr> 
		                      <td width="90%"><heading>Installation Instructions – Rotofast</heading></td>
		                      <td width="10%">
                            <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/Rotofast_Installation_Instructions.pdf">
                              <img id="change" className="w-auto" src={Download1} alt="Download20" />View
                            </Link>
                          </td>      
                        </tr> 
                      </tbody>
                    </table>
			            </div>
			          </div>
			          <div className="col-sm-12 col-md-12 col-lg-12">
			            <input className="ac-input" id="ac-4" name="accordion-1" type="radio" />
				          <label className="ac-label mb-0" for="ac-4">
                    <b>Miscellaneous Data</b><i></i>
                  </label>
					        <div className="article ac-content">
			              <table className="table">
                      <tbody>
	                      <tr> 
		                      <td width="90%"><heading>Cleaning Instructions – FABRICS</heading></td>
		                      <td width="10%">
                            <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/Cleaning_Instu.pdf">
                              <img id="change" className="w-auto" src={Download1} alt="Download21" />View
                            </Link>
                          </td>       
                        </tr> 
                        <tr> 
		                      <td width="90%"><heading>Whispertone® Tackboard Office Partition Insulation</heading></td>
		                      <td width="10%">
                            <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/Tackboard_Info.pdf">
                              <img id="change" className="w-auto" src={Download1} alt="Download22" />View
                            </Link>
                          </td>      
                        </tr> 
                      </tbody>
                    </table>
			            </div>		
			          </div>
	              <div className="col-sm-12 col-md-12 col-lg-12">
			            <input className="ac-input" id="ac-4" name="accordion-1" type="radio" />
				          <label className="ac-label mb-0" for="ac-4">
                    <b>CSI Specification</b><i></i>
                  </label>
					        <div className="article ac-content">
                    <table className="table">
				              <tbody>
	                      <tr> 
		                      <td width="90%"><heading>Download</heading></td>
		                      <td width="10%">
                            <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/AS_NoiseSTOPFabrisorb_CSISpec_Wall.docx">
                              <img id="change" className="w-auto" src={Download1} alt="Download23" />View
                            </Link>
                          </td>      
                        </tr> 
                      </tbody>
				            </table>
			            </div>	
			          </div>
	              <div className="col-sm-12 col-md-12 col-lg-12">
			            <input className="ac-input" id="ac-4" name="accordion-1" type="radio" />
				          <label className="ac-label mb-0" for="ac-4">
                    <b>Cleaning Instructions</b><i></i>
                  </label>
							    <div className="article ac-content">
                    <table className="table">
				              <tbody>
	                      <tr>
                          <td width="90%"><heading>Download</heading></td>
		                      <td width="10%">
                            <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/AS_NoiseSTOPFabrisorb_CleaningInstructions.pdf">
                              <img id="change" className="w-auto" src={Download1} alt="Download24" />View
                            </Link>
                          </td>      
                        </tr> 
                      </tbody>
				            </table>
                  </div>
			          </div>
	              <div className="line"></div>			
		            <div className="col-sm-12 download-button">
		              <div className="row">
			              <div className="col-sm-6">
                      <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/Fabrisorb-LAST.pdf">
                        <img className="mx-auto d-block" src={Download2} alt="Download2" />
                      </Link>
                    </div>
			              <div className="col-sm-6">
                      <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/color-chart.pdf">
                        <img className="mx-auto d-block" src={Download3} alt="Download3" />
                      </Link>
                    </div>
		              </div>	
		            </div>
              </div>
            </div>
          </div>
        </div>
        <div className='mobsidebar'>
          <div className="col-sm-12">
            <div className="container">
              <div className="row">
                <Mobsidebar />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default fab; 