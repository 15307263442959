import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Springhangers1 from '../../images/inner/IM.webp';
import Springhangers2 from '../../images/inner/im-spring-pipe-trapeze-200x189.webp';
import Springhangers3 from '../../images/inner/im-spring-large-angle-clips-200x189.webp';
import Springhangers4 from '../../images/inner/im-spring-large-suspended-unit-200x200.webp';
import Springhangers5 from '../../images/inner/im-spring-large-cradled-unit-200x200.webp';
import './spring-isolation-hangers.css';
import ScrollToTopButton from '../ScrollButton';
function springhangers() {
    return (
      
    <>
    <Helmet>
    <title>Spring Isolation Hangers provide far greater vibration control  </title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="IM Simple Spring Hangers" />
    <meta property="og:description" content="The spring serves to isolate a broad range of disturbing vibration frequencies, while the rubber cup is a high frequency or noise break." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/IM.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/spring-isolation-hangers" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/spring-isolation-hangers" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/spring-isolation-hangers" />
    </Helmet>
    <Header />
    <section className="content" id="isolation">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>IM Simple Spring Hangers</h1> 
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/IM.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Springhangers1} alt="Spring Isolation Hangers1" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <p></p>
                                <p>High Deflection Spring Hangers provide far greater vibration control than the lower deflection neoprene hangers. Each powder coated steel spring is in series with a neoprene cup, providing excellent isolation efficiency. The spring serves to isolate a broad range of disturbing vibration frequencies, while the rubber cup is a high frequency or noise break.</p>
                                <p>All box type spring hangers are available pre compressed from the factory with a scale for adjusting spring tension. They are similar to SLR restrained mounts in that they can be adjusted to limit vertical movement caused by weight changes during system start up, maintenance and seismic events. Spring Hangers are also available with eyebolts top and bottom.
                                </p>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/15._IM_Spring_Hangers.pdf" target="_blank">Product Specs</Link>
                                        </li>  
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/im-spring-pipe-trapeze-200x189.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Springhangers2} alt="Spring Isolation Hangers2" />
                                </Link>
                                <p style={{margin: "0", textalign: "center", width: "100%"}}>Pipe Trapeze</p>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/im-spring-large-angle-clips-200x189.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Springhangers3} alt="Spring Isolation Hangers3" />
                                </Link>
                                <p style={{margin: "0", textalign: "center", width: "100%"}}>Unit with Large Angle Clips</p>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/im-spring-large-suspended-unit-200x200.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Springhangers4} alt="Spring Isolation Hangers4" />
                                </Link>
                                <p style={{margin: "0", textalign: "center", width: "100%"}}>Unit Suspended From Above</p>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/im-spring-large-cradled-unit-200x200.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Springhangers5} alt="Spring Isolation Hangers5" />
                                </Link>
                                <p style={{margin: "0", textalign: "center", width: "100%"}}>Cradled Unit</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="9">IM 1″ Deflection Spring Hangers</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td rowspan="2">Type Size</td>
                                            <td rowspan="2">Rated Capacity</td>
                                            <td rowspan="2">Rated Deflection</td>
                                            <td rowspan="2">Mount Constant</td>
                                            <td rowspan="2">Spring Color/Stripe</td>
                                            <td colSpan="4">Dimensions</td>
                                        </tr>
                                        <tr className="colheader">
                                            <td>BOD</td>
                                            <td>COD</td>
                                            <td>MRD</td>
                                            <td>RP</td>
                                        </tr>
                                        <tr>
                                            <td>IM-X-12</td>
                                            <td>12 lbs</td>
                                            <td>1.25″</td>
                                            <td>10 lbs/in</td>
                                            <td>Orange</td>
                                            <td rowspan="9" className="nospace">1-1/8″</td>
                                            <td rowspan="9">2″</td>
                                            <td rowspan="9">3/8″</td>
                                            <td rowspan="9">2-3/4″</td>
                                        </tr>
                                        <tr>
                                            <td>IM-X-23</td>
                                            <td>23 lbs</td>
                                            <td>1.30″</td>
                                            <td>18 lbs/in</td>
                                            <td>Brown</td>
                                        </tr>
                                        <tr>
                                            <td>IM-X-33</td>
                                            <td>33 lbs</td>
                                            <td>1.10″</td>
                                            <td>30 lbs/in</td>
                                            <td>Red</td>
                                        </tr>
                                        <tr>
                                            <td>IM-X-54</td>
                                            <td>54 lbs</td>
                                            <td>1.20″</td>
                                            <td>45 lbs/in</td>
                                            <td>White</td>
                                        </tr>
                                        <tr>
                                            <td>IM-X-76</td>
                                            <td>76 lbs</td>
                                            <td>1.02″</td>
                                            <td>73 lbs/in</td>
                                            <td>Black</td>
                                        </tr>
                                        <tr>
                                            <td>IM-X-113</td>
                                            <td>113 lbs</td>
                                            <td>1.00″</td>
                                            <td>113 lbs/in</td>
                                            <td>Yellow</td>
                                        </tr>
                                        <tr>
                                            <td>IM-X-130</td>
                                            <td>130 lbs</td>
                                            <td>1.00″</td>
                                            <td>130 lbs/in</td>
                                            <td>Purple</td>
                                        </tr>
                                        <tr>
                                            <td>IM-X-175</td>
                                            <td>175 lbs</td>
                                            <td>1.00″</td>
                                            <td>175 lbs/in</td>
                                            <td>Silver</td>
                                        </tr>
                                        <tr>
                                            <td>IM-X-210</td>
                                            <td>210 lbs</td>
                                            <td>1.00″</td>
                                            <td>210 lbs/in</td>
                                            <td>Blue</td>
                                        </tr>
                                        <tr>
                                            <td>IM-A-12</td>
                                            <td>12 lbs</td>
                                            <td>1.00″</td>
                                            <td>12 lbs/in</td>
                                            <td>Red</td>
                                            <td rowspan="3">1-1/4″</td>
                                            <td rowspan="3" className="nospace">2-1/2″</td>
                                            <td rowspan="3">3/8″</td>
                                            <td rowspan="3">2-1/4″</td>
                                        </tr>
                                        <tr>
                                            <td>IM-A-18</td>
                                            <td>18 lbs</td>
                                            <td>1.00″</td>
                                            <td>18 lbs/in</td>
                                            <td>Gray</td>
                                        </tr>
                                        <tr>
                                            <td>IM-A-25</td>
                                            <td>25 lbs</td>
                                            <td>1.00″</td>
                                            <td>25 lbs/in</td>
                                            <td>Orange</td>
                                        </tr>
                                        <tr>
                                            <td>IM-A-45</td>
                                            <td>45 lbs</td>
                                            <td>1.60″</td>
                                            <td>28 lbs/in</td>
                                            <td>Blue</td>
                                            <td rowspan="6">1-1/4″</td>
                                            <td rowspan="6">2-1/2″</td>
                                            <td rowspan="6">1/2″</td>
                                            <td rowspan="6" className="nospace">3-3/8″</td>
                                        </tr>
                                        <tr>
                                            <td>IM-A-75</td>
                                            <td>75 lbs</td>
                                            <td>1.50″</td>
                                            <td>50 lbs/in</td>
                                            <td>Orange</td>
                                        </tr>
                                        <tr>
                                            <td>IM-A-125</td>
                                            <td>125 lbs</td>
                                            <td>1.33″</td>
                                            <td>94 lbs/in</td>
                                            <td>Brown</td>
                                        </tr>
                                        <tr>
                                            <td>IM-A-200</td>
                                            <td>200 lbs</td>
                                            <td>1.15″</td>
                                            <td>174 lbs/in</td>
                                            <td>Black</td>
                                        </tr>
                                        <tr>
                                            <td>IM-A-310</td>
                                            <td>310 lbs</td>
                                            <td>1.00″</td>
                                            <td>310 lbs/in</td>
                                            <td>Yellow</td>
                                        </tr>
                                        <tr>
                                            <td>IM-A-400</td>
                                            <td>400 lbs</td>
                                            <td>1.00″</td>
                                            <td>300 lbs/in</td>
                                            <td>Green</td>
                                        </tr>
                                        <tr>
                                            <td className="nospace">IM-A-510</td>
                                            <td>510 lbs</td>
                                            <td>1.00″</td>
                                            <td>510 lbs/in</td>
                                            <td>Red</td>
                                            <td>1-1/4″</td>
                                            <td>2-1/2″</td>
                                            <td>1/2″</td>
                                            <td>3-1/2″</td>
                                        </tr>
                                        <tr>
                                            <td style={{textalign: "left"}} colSpan="9" className="colheader"><strong>3/4″ Minimum Deflection</strong></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="9">IM 2″ Deflection Spring Hangers</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td rowspan="2">Type  Size</td>
                                            <td rowspan="2">Rated Capacity</td>
                                            <td rowspan="2">Rated Deflection</td>
                                            <td rowspan="2">Mount Constant</td>
                                            <td rowspan="2">Spring Color/Stripe</td>
                                            <td colSpan="4">Dimensions</td>
                                        </tr>
                                        <tr className="colheader">
                                            <td>BOD</td>
                                            <td>COD</td>
                                            <td>MRD</td>
                                            <td>RP</td>
                                        </tr>
                                        <tr>
                                            <td>IM-B-20</td>
                                            <td>20 lbs</td>
                                            <td>2.40″</td>
                                            <td>8 lbs/in</td>
                                            <td>Tan</td>
                                            <td rowspan="8" className="nospace">1-7/8″</td>
                                            <td rowspan="8" className="nospace">3-1/8″</td>
                                            <td rowspan="8" className="nospace">3/4″</td>
                                            <td rowspan="8" className="nospace">4-7/8″</td>
                                        </tr>
                                        <tr>
                                            <td>IM-B-26</td>
                                            <td>26 lbs</td>
                                            <td>2.18″</td>
                                            <td>12 lbs/in</td>
                                            <td>White/Blue</td>
                                        </tr>
                                        <tr>
                                            <td>IM-B-35</td>
                                            <td>35 lbs</td>
                                            <td>2.20″</td>
                                            <td>16 lbs/in</td>
                                            <td>Purple</td>
                                        </tr>
                                        <tr>
                                            <td>IM-B-50</td>
                                            <td>50 lbs</td>
                                            <td>2.20″</td>
                                            <td>24 lbs/in</td>
                                            <td>White/Red</td>
                                        </tr>
                                        <tr>
                                            <td>IM-B-65</td>
                                            <td>65 lbs</td>
                                            <td>2.10″</td>
                                            <td>31 lbs/in</td>
                                            <td>Brown</td>
                                        </tr>
                                        <tr>
                                            <td>IM-B-85</td>
                                            <td>85 lbs</td>
                                            <td>2.10″</td>
                                            <td>40 lbs/in</td>
                                            <td>White/Black</td>
                                        </tr>
                                        <tr>
                                            <td>IM-B-115</td>
                                            <td>115 lbs</td>
                                            <td>2.00″</td>
                                            <td>57 lbs/in</td>
                                            <td>Silver</td>
                                        </tr>
                                        <tr>
                                            <td>IM-B-150</td>
                                            <td>150 lbs</td>
                                            <td>2.00″</td>
                                            <td>75 lbs/in</td>
                                            <td>Orange</td>
                                        </tr>
                                        <tr>
                                            <td>IM-B2-210</td>
                                            <td>210 lbs</td>
                                            <td>2.12″</td>
                                            <td>99 lbs/in</td>
                                            <td>Silver</td>
                                            <td rowspan="3">1-7/8″</td>
                                            <td rowspan="3">3-1/8″</td>
                                            <td rowspan="3">3/4″</td>
                                            <td rowspan="3">5-3/8″</td>
                                        </tr>
                                        <tr>
                                            <td>IM-B2-290</td>
                                            <td>290 lbs</td>
                                            <td>2.00″</td>
                                            <td>144 lbs/in</td>
                                            <td>Blue</td>
                                        </tr>
                                        <tr>
                                            <td className="nospace">IM-B2-450</td>
                                            <td>450 lbs</td>
                                            <td>2.00″</td>
                                            <td>224 lbs/in</td>
                                            <td>Tan</td>
                                        </tr>
                                        <tr>
                                            <td style={{textalign: "left"}} colSpan="9" className="colheader"><strong>1-1/2″ Minimum Deflection</strong></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default springhangers; 