import React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import CMC from '../../images/projects-slider/cmc-image-1.webp';
import Hospira from '../../images/projects-slider/hospira-chennai.webp';
import DRC from '../../images/projects-slider/drc-mysore.webp';
import Sabic from '../../images/projects-slider/sabic-bangalore.webp';
import Hometheater from '../../images/projects-slider/ht-mangalore-1.webp';
import ScrollToTopButton from '../ScrollButton';
function projects4() {
    return (
      
    <>
    <Helmet>
    <title>Turnkey Acoustic Projects - Acoustical Surfaces </title>
    <meta name="description" content="" />
    <meta property="og:title" content="Projects Gallery" />
    <meta property="og:description" content="" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.png" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/projects-4" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/projects-4" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/projects-4" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="IN" />
    <meta name="geo.region" content="india" />
    </Helmet>
    <Header />
    <section className="content projects">
        <div className="container">
        <hr />
        <h2 style={{textalign: "center"}}>Projects Gallery</h2>
            <div style={{width: "18%", bordertop: "1px solid #e0dede", margin: "auto", padding: "10px"}}></div>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6"> 
                    <h4>Christian Medical College</h4>
                    <img  className="img-fluid" src={CMC} alt="Christian Medical College" />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{textalign: "justify"}}>
                    <p></p>
                    <h5>About Christian Medical College.</h5>
                    <p style={{textAlign:"justify"}}>The Christian Medical College and Hospital (CMC) is a private, minority-run educational and research institute that includes a network of primary, secondary and tertiary care hospitals in and around Vellore, Tamil Nadu, India. Founded in 1900 by an American missionary, Dr Ida S. Scudder, CMC has brought many significant achievements to India.</p>
                    <h5>Our Scope</h5>
                    <p>To treat the Wall Partition Acoustic Isolation to achieve 39dB addition to that absorption wall paneling of NRC 0.9   and suspended ceilings with Acoustic isolation and renovating the existing rooms by retrofitting with current technology. Construction area : 2361 sq.ft</p>
                    <h5>Location</h5><p>Vellore, Tamil Nadu</p>
                </div>
                <div className="line" style={{margin: "1em 0"}}></div>
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6"> 
                    <h4>Hospira Healthcare</h4>
                    <img className="img-fluid" src={Hospira} alt="Hospira Chennai" />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{textalign: "justify"}}>
                    <p></p>
                    <h5>About Hospira Healthcare</h5>
                    <p style={{textAlign:"justify"}}>Hospira Healthcare India Private Limited manufactures injectable formulations and active pharmaceutical ingredients (APIs). The company was formerly known as Ojas Pharmaceuticals India Private Limited Hospira Healthcare India Private Limited operates as a subsidiary of Pfizer Inc.</p>
                    <h5>Our Scope</h5>
                    <p>Instalation of Acoustic Wall Panels.</p>
                    <h5>Location</h5><p>Chennai</p>
                </div>
                <div className="line" style={{margin: "1em 0"}}></div>
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6"> 
                    <h4>DRC Cinemas</h4>
                    <img className="img-fluid" src={DRC} alt="DRC Mysore" />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{textalign: "justify"}}>
                    <p></p>
                    <h5>About DRC Cinemas</h5>
                    <p style={{textAlign:"justify"}}>Mysore's first Multiplex is here fulfilling the long cherished dream of many a Mysorean! Top of the line equipment for acoustics and projection giving beautiful ambience topped off with stylish lobby and concession counters screens films of all popular languages.</p>
                    <h5>Our Scope</h5>
                    <p>Acoustic Treatment</p>
                    <h5>Location</h5><p>Mysore</p>
                </div>
                <div className="line" style={{margin: "1em 0"}}></div>
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6"> 
                    <h4>Sabic Research & Technology Pvt. Ltd</h4>
                    <img className="img-fluid" src={Sabic} alt="Sabic Bangalore" />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{textalign: "justify"}}>
                    <p></p>
                    <h5>About Sabic Research & Technology</h5>
                    <p style={{textAlign:"justify"}}>Sabic Research And Technology Private Limited is a Private incorporated on 19 February 1998. It is classNameified as Non-govt company and is registered at Registrar of Companies, Bangalore.</p>
                    <h5>Our Scope</h5>
                    <p>Supply & Installation of Acoustical Door and Partition</p>
                    <h5>Location</h5><p>Bangalore</p>
                </div>
                <div className="line" style={{margin: "1em 0"}}></div>
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6"> 
                    <h4>Home Theater, Mangalore</h4>
                    <img className="img-fluid" src={Hometheater} alt="Home Theater Mangalore" />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{textalign: "justify"}}>
                    <p></p>
                    <h5>Home Theater, Mangalore</h5>
                    <p></p>
                    <h5>Our Scope</h5>
                    <p>Home Theater Installation, Supply & Installation of Acoustic Walls and Flooring</p>
                    <h5>Location</h5><p>Mangalore</p>
                </div>
                <div className="line" style={{margin: "1em 0"}}></div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="paginate">
                         <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a class="page-link" href="/projects-3" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item"><a class="page-link" href="/projects">1</a></li>
                                        <li class="page-item"><a class="page-link" href="/projects-2">2</a></li>
                                        <li class="page-item"><a class="page-link" href="/projects-3">3</a></li>
                                        <li class="page-item"><a class="page-link" href="/projects-4">4</a></li>
                                        <li class="page-item"><a class="page-link" href="/projects-5">5</a></li>
                                        <li class="page-item">
                                            <a class="page-link" href="/projects-5" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default projects4; 