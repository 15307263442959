import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Dbapanels2 from '../../images/inner/dBA-panel.webp';
import Dbapanels3 from '../../images/inner/Charcoal_up_lg-200x200.webp';
import Dbapanels4 from '../../images/inner/white_up_lg1-200x200.webp';
import Dbapanels5 from '../../images/inner/Sound-Silencer-13-web.webp';
import './dba-panels.css';
import ScrollToTopButton from '../ScrollButton';
function dba() {
    return (
      
    <>
    <Helmet>
    <meta name="description" content="" />
    <title>dBA Panels - Acoustical Wall Panels and Ceiling Tiles</title>
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Introducing the First Tri-Functional Acoustical Panel" />
    <meta property="og:description" content="The new dBA panels offer sound blocking, sound absorption and sound diffusion all in one unit." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/dBA-panel.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/dba-panels" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/dba-ceiling-tiles" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/dba-panels" />
    </Helmet>
    <Header />
    <section className="content" id="dbapanels">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
                    <h1 className='dbapanels-content'>Introducing the First Tri-Functional Acoustical Panel</h1>
                    <div className="row pad dbapanels-content">
                        <div className="col-xl-4 col-sm-12">
                            <Link className="example-image-link" to="../../images/inner/dBA-panel.webp" data-lightbox="example-1">
                                <img className="example-image img-fluid" src={Dbapanels2} alt="DBA Panels2" />
                            </Link>
                        </div>
                        <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                            <h2>dBA Panels – Sound Silencer™ (Diffuser Blocking Absorber)</h2>
                            <strong>Acoustical Wall Panels and Ceiling Tiles</strong>
                            <br />
                            <p>The new <strong>dBA</strong> panels offer sound blocking, <strong>sound absorption and sound diffusion</strong> all in one unit.</p>
                            <strong>Product Features:</strong>
                            <ul style={{marginLeft: "1em", fontSize: ".9em"}}>
                                <li>1 installation required. Unlike traditional products which require 3 separate installations to meet the performance of the dBA panel.</li>
                                <li>className A Fire Rated</li>
                                <li>No Fiberglass</li>
                                <li>Moisture Resistant Indoor</li>
                                <li>Non-Fibrous</li>
                                <li>Impact Resistant</li>
                                <li>Water Resistant</li>
                                <li>Non-Abrasive Surface</li>
                                <li>Both STC and NRC Ratings and Diffuser</li>
                                <li>Tackable Surface</li>
                                <li>Bacteria and Fungi Resistant</li>
                                <li>Lightweight</li>
                            </ul>
                        </div>
                        <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                            <div className="testing-info">
                                <h5>Product Testing & Information</h5>
                                <ul>
                                    <li><Link to="https://acousticalsurfaces.in/pdfs/dBA1.pdf" target="_blank">Product Specs</Link></li>
                                    <li><Link to="0">Testimonials</Link></li>
                                    <li><Link to="0">Acoustical Tests</Link></li>
                                    <li><Link to="0">MSDS</Link></li>
                                    <li><Link to="0">Flammability</Link></li>
                                    <li><Link to="0">Installation</Link></li>
                                    <li><Link to="0">Product Photos</Link></li>
                                    <li><Link to="0">Misc. Data</Link></li>
                                    <li><Link to="https://acousticalsurfaces.in/pdfs/30160-08-92064-4.pdf" target="_blank">1" dBA Test Data</Link></li>
                                    <li><Link to="https://acousticalsurfaces.in/pdfs/30160-08-92064-3.pdf" target="_blank">2" dBA Test Data</Link></li>
                                    <li><Link to="cad-library-guide">CSI, CAD, and Revit Files</Link></li>                                       
                                </ul>
                            </div>
                        </div>
                        <div className="line"></div>
                    </div>
                    <div className="row dbapanels-content">
                        <div className="col-sm-12 text">
                            <p><strong>dBA</strong> stands for Diffuser Blocking Absorber. Our <strong>dBA</strong> panels are available in 1″ and 2″ thickness. Panel size is 2′ × 2′. The <strong>dBA</strong> panel is intended for wall or ceiling applications. The dBA panel is an innovative sound treatment panel for both home theaters and professional grade commercial studios. No longer will you have to struggle to figure out noise reduction coefficients or calculate the amount of sabins for a given space. </p>
                            <p>All the guesswork will be eliminated. The new <strong>dBA</strong> panels offer sound blocking, sound absorption and sound diffusion all in one unit. This will all be accomplished with our new tri-functional wall and ceiling panel. These panels have a unique design that not only provides a wide array of acoustical characteristics, but it is aesthetically pleasing and will give any studio or home theater that polished, professional look. As it stands, the new <strong>dBA</strong> panels will come in white or charcoal only, but as the line expands so will the color choices. Keep in mind these panels can be painted to match any decor. <strong>(ask for acoustical painting instructions)</strong></p>
                        </div>
                    </div> 
                    <div className="row">
                        <div className="col-md-12 col-sm-12 fleximages">
                            <div className="imagebox">
                                <Link className="example-image-link" to="../../images/inner/Charcoal_up_lg-200x200.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Dbapanels3} alt="DBA Panels3" />
                                </Link>
                            </div>
                            <div className="imagebox">
                                <Link className="example-image-link" to="../../images/inner/white_up_lg1-200x200.webp" data-lightbox="Pattern">
                                    <img className="example-image img-fluid" src={Dbapanels4} alt="DBA Panels4" />
                                </Link>
                            </div>
                            <div className="imagebox">
                                <Link className="example-image-link" to="../../images/inner/Sound-Silencer-13-web.webp" data-lightbox="Features">
                                    <img className="example-image img-fluid" style={{minheight: "10.5em"}} src={Dbapanels5} alt="DBA Panels5" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row dbapanels-content">
                        <div className="col-sm-12">
                            <h3>Product Specs</h3>
                            <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                <tbody>
                                    <tr>
                                        <th>Material</th>
                                        <td>Semi-Rigid, Non-Abrasive, Porous Expanded Polypropylene (P.E.P.P.). Acoustical Bead Board </td>
                                    </tr>
                                    <tr>
                                        <th>Pattern</th>
                                        <td>dBA-1 and dBA-2 Custom Patterns Available For Truck Load Quantities </td>
                                    </tr>
                                    <tr>
                                        <th>Features</th>
                                        <td>Lightweight, Impact Resistant, Moisture, Bacteria & Fungi Resistant, Tackable Surface </td>
                                    </tr>
                                    <tr>
                                        <th>Applications</th>
                                        <td>Home Theaters, Recording Studios, Commercial Sound, Pro Audio Application, Ceiling Tiles Or Any Acoustical Venue. </td>
                                    </tr>
                                    <tr>
                                        <th>Thickness</th>
                                        <td>1″ & 2″</td>
                                    </tr>
                                    <tr>
                                        <th>Sizes</th>
                                        <td>Nominal 2′ × 2′ & 2′ × 4′ </td>
                                    </tr>
                                    <tr>
                                        <th>Colors</th>
                                        <td>White, Charcoal</td>
                                    </tr>
                                    <tr>
                                        <th>Flammability</th>
                                        <td>ASTM E84, className A
                                        <br /> 1″ – Flame Spread: 3, Smoke Developed: 84
                                        <br /> 2″ – Flame Spread: 5, Smoke Developed: 113
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Installation</th>
                                        <td>Noise S.T.O.P. Acoustical Adhesive, Standard T-bar grid, Mechanical Fasteners </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p><Link className="btn btn-asi" to="reverb-demo">Reverberation Demonstration</Link></p>
                        </div>
                    </div>
                    <br />
                    <div className="row dbapanels-content">
                        <div className="col-sm-12">
                            <table className="table table-bordered-2">
                                <thead>
                                    <tr>
                                        <th colSpan="8">dBA Panel Sound Absorption/Noise Reduction (NRC)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="colheader">
                                        <td>Mount</td>
                                        <td>125Hz</td>
                                        <td>250Hz</td>
                                        <td>500Hz</td>
                                        <td>1KHz</td>
                                        <td>2KHz</td>
                                        <td>4KHz</td>
                                        <td>NRC</td>
                                    </tr>
                                    <tr>
                                        <td>1″ Ceiling E400</td>
                                        <td>0.38</td>
                                        <td>0.60</td>
                                        <td>0.36</td>
                                        <td>0.47</td>
                                        <td>0.62</td>
                                        <td>0.82</td>
                                        <td className="color-red">0.50</td>
                                    </tr>
                                    <tr>
                                        <td>2″ Ceiling E400</td>
                                        <td>0.35</td>
                                        <td>0.59</td>
                                        <td>0.40</td>
                                        <td>0.64</td>
                                        <td>0.97</td>
                                        <td>1.10</td>
                                        <td className="color-red">0.65</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p></p>
                            <table className="table table-bordered-2">
                                <thead>
                                    <tr>
                                        <th colSpan="8">dBA Panel Sound Transmission Loss (STC)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="colheader">
                                        <td></td>
                                        <td>125Hz</td>
                                        <td>250Hz</td>
                                        <td>500Hz</td>
                                        <td>1KHz</td>
                                        <td>2.5KHz</td>
                                        <td>4KHz</td>
                                        <td>STC</td>
                                    </tr>
                                    <tr>
                                        <td>1″</td>
                                        <td>5</td>
                                        <td>3</td>
                                        <td>4</td>
                                        <td>6</td>
                                        <td>7</td>
                                        <td>8</td>
                                        <td className="color-red">6</td>
                                    </tr>
                                    <tr>
                                        <td>2″</td>
                                        <td>7</td>
                                        <td>4</td>
                                        <td>5</td>
                                        <td>7</td>
                                        <td>8</td>
                                        <td>11</td>
                                        <td className="color-red">7</td>
                                    </tr>
                                    <tr>
                                        <td>1″ w/ 5/8″ Gypsum both sides</td>
                                        <td>27</td>
                                        <td>27</td>
                                        <td>29</td>
                                        <td>31</td>
                                        <td>32</td>
                                        <td>45</td>
                                        <td className="color-red">32</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default dba;