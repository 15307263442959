import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Card1 from '../../images/wudwool/card1.webp';
import Card2 from '../../images/wudwool/card2.webp';
import Card3 from '../../images/wudwool/card3.webp';
import Mobsidebar from '../mobile-sidebar';
import Sliderwoodwool from './slider-woodwool';
import Features from '../../images/wudwool/ww6.webp';
import Natural from '../../images/wudwool/ww5.webp';
import Structures from '../../images/wudwool/ww7.webp';
import './style-woodwool.css';
import ScrollToTopButton from '../ScrollButton';
function woodwool() {
    return (
      
    <>
    <Helmet>
    <title>Wood Wool panels creates unique sound absorbing properties</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Wood Wool panels" />
    <meta property="og:description" content=" The combination of wood and cement creates unique sound absorbing properties, ensuring good acoustics in any room." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/wudwool/card2.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/woodwool" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/woodwool" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/woodwool" />
    </Helmet>
    <Header />
    <section className="content" id="woodwool">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
	                    <div className="col-sm-12 heading-fsorb">				
	                        <Sliderwoodwool />
		                    <h1>Envirocoustic<sup>TM</sup> Wood Wool</h1>
                    		<p>Envirocoustic<sup>TM</sup> Wood Wool panels  are made from 100% natural materials; wood and cement. The combination of wood and cement creates unique sound absorbing properties, ensuring good acoustics
                                in any room. The material has a natural resilience and can handle moist environments, while also providing effective fire protection.</p>
                    		<div className="flex-container">
	                            <Link to="ww-downloads" className="btn btn-danger justify-content-center">Downloads</Link>
                    		</div>	
	                    </div>
                        <div className="col-sm-12 features">
	                        <div className="row pad">	
    	                        <div className="col-md-7 col-sm-12 bgcolor">
		                            <h1>Features</h1>
		                            <ul>
		                                <li>Aesthetically pleasing</li>
		                                <li>High sound absorption performance</li>
		                                <li>Available with various edges and colours</li>
		                                <li>Fire resistant</li>
		                                <li>Environmentally friendly</li>
		                                <li>Paintable, with wide range of colors</li>
		                                <li>Durable</li>
		                            </ul>
		                        </div>
		                        <div className="col-md-5 col-sm-12 tiles bgimage p-0">
                                    <img src={Features} alt="Features" />
                                </div>
		                    </div>
		                </div>
                        <div className="col-sm-12 features">
	                        <div className="row pad">	
    	                        <div className="col-md-5 col-sm-12 order-2 order-sm-2  order-md-1 tiles bgimage1 p-0">
                                    <img src={Natural} alt="Natural" />
                                </div>
                                <div className="col-md-7 col-sm-12 order-1 order-sm-1  order-md-2 bgcolor">
                                    <br />
		                            <h1>100% Natural Raw Material</h1>
		                            <p>Envirocoustic™ Wood-wool Acoustic Panel is made of wood fiber,cement and minerals under high temperature and high pressure. There are countless pores inside of the panel, which can absorb noise. 
                                        It not only has great acoustic and decoration effect, but also environmental friendly. Different paint colors are available by request .</p>
                        		</div>
	                        </div>
		                </div>
                        <div className="col-sm-12 features">
	                        <div className="row pad">	
    	                        <div className="col-md-7 col-sm-12 bgcolor">
                                    <br />
		                            <h1>Structures and Colours</h1>
		                            <p>The surface structure of Envirocoustic™ <Link to="/acoustic-panel">acoustic panels</Link> is determined by the width of the wood wool. Our products are available in two different structures; 
                                    <strong>ultrafine (1 mm) and fine (2 mm)</strong>. Envirocoustic™ acoustic panels we produce in two basis variants: natural wood and natural grey.
                                    Water based colour painted Envirocoustic™ wood wool panels are available in any RAL/NCS colour code.</p>
		                        </div>
		                        <div className="col-md-5 col-sm-12 tiles bgimage2 p-0">
                                    <img src={Structures} alt="Structures" />
                                </div>
		                    </div>
		                </div>
                        <br />	
		                <div className="col-sm-12 more">
                            <h1> More Products </h1>
		                    <div className="line"></div>
		                </div>
	                    <div className="col-sm-12 featured-products">
	                        <div className="row pb-5">
		                        <div className="col-md-4 col-sm-12 featured-card">
		                            <div className="card text-center">
			                            <img className="card-img-top" src={Card1} alt="Card cap1" />
  			                            <div className="card-block">
    			                            <h4 className="card-title">Woodwool<br /><span>Wall</span> Panels</h4>
    			                            <p className="card-text">We are the leader in eco-friendly, high-performance, cost-effective acoustical-panel products category</p>
    			                            <Link to="/woodwool-wall"className="btn btn-danger">For More</Link>
  			                            </div>
                                    </div>
		                        </div>
		                        <div className="col-md-4 col-sm-12 featured-card">
		                            <div className="card text-center">
			                            <img className="card-img-top" src={Card2} alt="Card cap2" />
  			                            <div className="card-block">
    			                            <h4 className="card-title">Woodwool<br /><span>Ceiling</span> Panels</h4>
    			                            <p className="card-text">Envirocoustic Wood wool ceiling panels can transform the aesthetics and acoustics of any interior space.</p>
    			                            <Link to="/woodwool-ceiling" className="btn btn-danger">For More</Link>
  			                            </div>
                                    </div>
                        		</div>
		                        <div className="col-md-4 col-sm-12 featured-card">
		                            <div className="card text-center">
			                            <img className="card-img-top" src={Card3} alt="Card cap3" />
  			                            <div className="card-block">
    			                            <h4 className="card-title">Woodwool<br /><span>Cloud / Baffles</span></h4>
    			                            <p className="card-text">Cementitious Wood-Fiber Acoustic Ceiling Clouds / Baffles provide a high level of acoustical absorption</p>
    			                            <Link to="/woodwool-cloud" className="btn btn-danger">For More</Link>
  			                            </div>
		                            </div>
		                        </div>
		                    </div>	
                        </div>
                        <hr />
                    </div>
                </div>
            </div>
			<div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
		<ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default woodwool;