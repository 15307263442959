import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Soundproofingclassrooms1 from '../../images/inner/cotton_stack-200x200.webp';
import Soundproofingclassrooms2 from '../../images/inner/sound-silencer-panels-200x200.webp';
import Soundproofingclassrooms3 from '../../images/inner/pic13-200x185.webp';
import Soundproofingclassrooms4 from '../../images/inner/fabric-wrapped-panel-edge-details-lg-200x200.webp';
import Soundproofingclassrooms5 from '../../images/inner/curve-low-profile-diffusor1-200x200.webp';
import Soundproofingclassrooms6 from '../../images/inner/silk-metal-tiles-sm1-200x200.webp';
import Mobsidebar from '../mobile-sidebar';
import './soundproofing-classrooms.css';
import ScrollToTopButton from '../ScrollButton';
function soundproofclass() {
    return (
      
    <>
    <Helmet>
    <title>Soundproofing Classrooms & Educational Institutions Acoustic Solutions</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Soundproofing Classrooms and Educational Institutions" />
    <meta property="og:description" content="Studies have found that up to one-third of all students in traditional educational institutions are missing out on instruction due to poor speech intelligibility. " />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/sound-silencer-panels-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/edu-institutions" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/soundproofing-classrooms" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/soundproofing-classrooms" />
    </Helmet>
    <Header />
    <section className="content" id="classrooms">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Soundproofing Classrooms and Educational Institutions</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>Studies have found that up to one-third of all students in traditional educational institutions are missing out on instruction due to poor speech intelligibility. Speech intelligibility is the degree to which speech is understood. The cement block walls commonly found in classrooms are not acoustically conducive to learning for many children. As a result, these children potentially miss between 30-40% of all verbal instruction. Improving classroom acoustics greatly improves the learning environment for all students, including special needs students.</p>
                                    <p>As specialists in classroom acoustics, we understand and consider national ANSI standards when recommending solutions for classrooms. Our classroom noise reduction products offer the best in sound absorption qualities to create an environment for optimal speech intelligibility. We offer a number of choices for wall and ceiling panels, replacement drop-in ceiling tiles as well as hanging baffles and clouds. All of our classroom soundproofing products meet fire rating standards for schools. Designed especially for students, the Quiet Light and Talk Light stoplight device are a fun way to signal when a classroom noise reaches an unacceptable level.</p>
                                    <p>With over 35 years of experience working with school systems to improve their classrooms, we are industry leaders in noise control products and know what classrooms need. The classroom noise reduction products above are just some examples of the high-quality acoustical components we use to improve communication within educational institutions. <Link to="/contact-us">Contact us</Link> to learn more.</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>Educational Institutions Soundproofing Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Soundproofingclassrooms1} alt="Echo Eliminator" />
                                </Link>
                                <h5>Echo Eliminator</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Soundproofingclassrooms2} alt="Sound Silencer Panels" />
                                </Link>
                                <h5>Sound Silencer Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Soundproofingclassrooms3} alt="PVC Barriers" />
                                </Link>
                                <h5>PVC Barriers</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Soundproofingclassrooms4} alt="Fabric Wrapped Fiberglass Panels" />
                                </Link>
                                <h5>Fabric Wrapped Fiberglass Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Soundproofingclassrooms5} alt="Curve Panels" />
                                </Link>
                                <h5>Curve Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Soundproofingclassrooms6} alt="Silk Metal" />
                                </Link>
                                <h5>Silk Metal</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default soundproofclass; 