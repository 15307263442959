import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import Museum from '../../images/projects-slider/p1museum.webp';
import Emiratesbank from '../../images/projects-slider/p1eibfs.webp';
import Goldman from '../../images/projects-slider/goldman-bangalore.webp';
import Wipro from '../../images/projects-slider/wipro-bangalore.webp';
import Twitter from '../../images/projects-slider/twitter-india.webp';
import ScrollToTopButton from '../ScrollButton';
function projects2() {
    return (

        <>
            <Helmet>
                <title>Turnkey Acoustic Projects - Acoustical Surfaces</title>
                <meta name="description" content="" />
                <meta property="og:title" content="Projects Gallery" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.png" />
                <meta property="og:locale" content="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.ae/projects-2" hreflang="en-ae" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/projects-2" hreflang="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/projects-2" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="IN" />
                <meta name="geo.region" content="india" />
            </Helmet>
            <Header />
            <section className="content projects">
                <div className="container">
                    <hr />
                    <h2 style={{ textalign: "center" }}>Projects Gallery</h2>
                    <div style={{ width: "18%", bordertop: "1px solid #e0dede", margin: "auto", padding: "10px" }}></div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>The Museum of Future</h4>
                            <img className="img-fluid" src={Museum} alt="The Museum of Future" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{ textalign: "justify" }}>
                            <p></p>
                            <h5>About The Museum of Future</h5>
                            <p style={{textAlign:"justify"}}>The Museum of the Future is a visionary cultural institution currently under construction in Dubai, a showplace for a new era - a center of creativity and hope where you can see, touch and shape our shared future.</p>
                            <h5>Our Scope</h5>
                            <p>Acoustical Plaster ceiling in Auditorium below the walkway & Hall area</p>
                            <h5>Location</h5><p>Dubai, UAE</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>Emirates Institute of Banking & Financial Studies</h4>
                            <img className="img-fluid" src={Emiratesbank} alt="Emirates Institute of Banking & Financial Studies" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{ textalign: "justify" }}>
                            <p></p>
                            <h5>About EIBFS</h5>
                            <p style={{textAlign:"justify"}}>Emirates Institute For Banking and Financial Studies (EIBFS) has made quantum leaps by delivering world className education, training and allied services in the area of Banking and Finance at its three campuses strategically located at Sharjah, Abu Dhabi and Dubai.</p>
                            <h5>Our Scope</h5>
                            <p>Acoustical Interior fit out which include In-house acoustic consultancy & Design, Acoustical wall treatment & ceiling.</p>
                            <h5>Location</h5><p>Dubai, UAE</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>Goldman Sachs </h4>
                            <img className="img-fluid" src={Goldman} alt="Goldman Sachs Bangalore" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{ textalign: "justify" }}>
                            <p></p>
                            <h5>About Goldman Sachs</h5>
                            <p style={{textAlign:"justify"}}>The Goldman Sachs Group, Inc. is a leading global investment banking, securities and investment management firm that provides a wide range of financial services to a substantial and diversified client base that includes corporations, financial institutions, governments and individuals. Founded in 1869, the firm is headquartered in New York and maintains offices in all major financial centers around the world.</p>
                            <h5>Our Scope</h5>
                            <p>Acoustic Wall Treatment.</p>
                            <h5>Location</h5><p>Bangalore</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>Wipro Technologies</h4>
                            <img className="img-fluid" src={Wipro} alt="Wipro Technologies Bangalore" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{ textalign: "justify" }}>
                            <p></p>
                            <h5>About Wipro Technologies</h5>
                            <p style={{textAlign:"justify"}}>Wipro Technologies Ltd. a division of Wipro Limited (NYSE:WIT), is amongst the largest global IT services, BPO and Product Engineering companies. In addition to the IT business, Wipro also has leadership position in niche market segments of consumer products and lighting solutions.</p>
                            <h5>Our Scope</h5>
                            <p>Acoustic Wall Treatment.</p>
                            <h5>Location</h5><p>Bangalore</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>Twitter India</h4>
                            <img className="img-fluid" src={Twitter} alt="Twitter Bangalore India" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{ textalign: "justify" }}>
                            <p></p>
                            <h5>About Twitter</h5>
                            <p style={{textAlign:"justify"}}>Twitter is an online news and social networking service on which users post and interact with messages known as tweets. Twitter, Inc. is based in San Francisco, California, and has more than 25 offices around the world.</p>
                            <h5>Our Scope</h5>
                            <p>Acoustical Wall Treatment</p>
                            <h5>Location</h5><p>Bangalore</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="paginate">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a class="page-link" href="/projects" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item"><a class="page-link" href="/projects">1</a></li>
                                        <li class="page-item"><a class="page-link" href="/projects-2">2</a></li>
                                        <li class="page-item"><a class="page-link" href="/projects-3">3</a></li>
                                        <li class="page-item"><a class="page-link" href="/projects-4">4</a></li>
                                        <li class="page-item"><a class="page-link" href="/projects-5">5</a></li>
                                        <li class="page-item">
                                            <a class="page-link" href="/projects-3" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default projects2; 