import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import './wallmate.css';
import Wallmate1 from '../../images/inner/wallmate-sports-arena.jpg';
import Wallmate2 from '../../images/inner/Wall-Mate-2-web-200x200.jpg';
import Wallmate3 from '../../images/inner/Wall-Mate-4-web-200x200.jpg';
import Wallmate4 from '../../images/inner/Wallmate-1-web-200x200.jpg';
import Wallmate5 from '../../images/inner/1_1-16_bevelled_hor.jpg';
import Wallmate6 from '../../images/inner/1_1-16_rounded_hor.jpg';
import Wallmate7 from '../../images/inner/1_1-16_square_hor.jpg';
import Wallmate8 from '../../images/inner/9-16_bevelled_hor.jpg';
import Wallmate9 from '../../images/inner/9-16_rounded_hor.jpg';
import Wallmate10 from '../../images/inner/9-16_square_hor.jpg';
import Mobsidebar from '../mobile-sidebar';
import ScrollToTopButton from '../ScrollButton';
function wallmate() {
    return (
      
    <>
    <Helmet>
    <title>Wallmate stretch fabric wall system</title>
    <meta name="description" content="" />
    <meta property="og:title" content="WALLMATE" />
    <meta property="og:description" content="The most user friendly, do it yourself, high tension stretch fabric wall system in the industry." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/Wallmate-1-web-200x200.jpg" />
    <meta property="og:locale" content="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/wallmate" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/wallmate" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/wallmate" hreflang="en-ae" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="IN" />
    <meta name="geo.region" content="india" />
    </Helmet>
    <Header />
    <section className="content" id="wallmate">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>WALLMATE® </h1>
                        <div className="row">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/wallmate-sports-arena.jpg" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Wallmate1} alt="Wallmate 1" />
                                </Link>
                            </div>
                            <div className="col-xl-5 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>The most user friendly, do it yourself, high tension stretch fabric wall system in the industry.</h2>
                                <p><strong>Product Features:</strong></p>
                                <ul style={{marginleft: "1.2em", fontsize: ".9em"}}>
                                    <li>Covers Any Size Wall</li>
                                    <li>No Wall Preparation</li>
                                    <li>No Lath</li>
                                    <li>No Staples</li>
                                    <li>No Welting/Gimp</li>
                                    <li>Excellent Acoustical Control Finish</li>
                                    <li>Flexible Choice of Fabrics</li>
                                    <li>Choice of Installer or Do It Yourself</li>
                                    <li>Removable Replaceable Fabric</li>
                                    <li>System is Reusable</li>
                                </ul>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link target="_blank" to="https://acousticalsurfaces.in/pdfs/wallmate.pdf" className="ico-misc">Product Specs</Link>
                                        </li>
                                        <li>
                                            <Link target="_blank" to="https://acousticalsurfaces.in/pdfs//097710-Fabric-Wrapped-Panels-Wallmate.doc" className="ico-misc">CSI Specification [DOC]</Link>
                                        </li>
                                        <li>
                                            <Link target="_blank" to="https://acousticalsurfaces.in/pdfs/Guilford_701_Fabric_Facings.pdf" className="ico-misc">Standard Fabric Color Chart</Link>
                                        </li>
                                        <li>
                                            <Link target="_blank" to="https://acousticalsurfaces.in/pdfs/Walmate-PA-765A.pdf" className="ico-misc">Material Safety Data Sheet</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 fleximages">
                                <h3 style={{marginleft: ".5em"}}>Product Photos</h3>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/Wall-Mate-2-web.jpg" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Wallmate2} alt="Wallmate 2" />
                                    </Link>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/Wall-Mate-4-web.jpg" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Wallmate3} alt="Wallmate 3" />
                                    </Link>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/Wallmate-1-web.jpg" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Wallmate4} alt="Wallmate 4" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12col-md-12 col-sm-12 text">
                                <p>Why Wallmate? It’s the only stretch fabric wall system you don’t have to hire an exclusive contractor. Wallmate acoustic wall fabric is great for sound control applications. You can create almost any panel size and there are hundreds of fabric options available. One benefit over traditional fabric wall panels is that the fabric can be replaced.</p>
                                <p>You no longer need to hire an exclusive local contractor to install their specified proprietary stretch fabric wall system. Wallmate® can be approved as an equal to all specified systems and has many advantages over the competition. The Wallmate stretch fabric wall system’s unique, user friendly, hinged track system allows almost anyone to install a professional looking, high tension, stretchable fabric acoustical wall system with nearly unlimited panel sizes.</p>
                                <h2>Creating Sound environments Beautiful Walls</h2>
                                <p>In this digitized, amplified, surround sound world, traditional building methods fail to provide for acoustical control of sound.</p>
                                <p>The twin problems of reflection and reverberation may require that surfaces be acoustically treated and covered with fabric as a decorative finish.</p>
                                <p>Acoustically treating walls has become as much a necessity for helping one listen to sound as quality speakers and systems are to transmitting sound.</p>
                                <p>Acoustical Surfaces Inc, presents the Wallmate stretch fabric wall system, a high tension fabric mounting system that revolutionizes the way fabric can be applied to walls as both a decorative and acoustical wall finish.</p>
                                <p>Wallmate® fabric wall panels depart from the traditional method of wall upholstery which still uses wood lath, staples, gimp and welting … a method which has become expensive and specialized. Wallmate® fabric wall panels remove the barriers of expense and expertise and exchanges them from simplicity at an affordable cost.</p>
                            </div>
                        </div>
                        <div className="row">
                            <h3 style={{margin: "1em 0 0 1em"}}>Wallmate Fabric Wall Panel Profiles: All Models Available in 102″ (8.5 ft.) Lengths</h3>
                            <div className="col-md-12 col-sm-12 fleximages">
                                <div className="imagebox">
                                    <img src={Wallmate5} className="img-fluid" alt="" />
                                    <span>1-1/16″ Bevel</span>
                                </div>
                                <div className="imagebox">
                                    <img src={Wallmate6} className="img-fluid" alt="" />
                                    <span>1-1/16″ Radius</span>
                                </div>
                                <div className="imagebox">
                                    <img src={Wallmate7} className="img-fluid" alt="" />
                                    <span>1-1/16″ Square</span>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 fleximages">
                                <div className="imagebox">
                                    <img src={Wallmate8} className="img-fluid" alt="" />
                                    <span>9/16″ Bevel</span>
                                </div>
                                <div className="imagebox">
                                    <img src={Wallmate9} className="img-fluid" alt="" />
                                    <span>9/16″ Radius</span>
                                </div>
                                <div className="imagebox">
                                    <img src={Wallmate10} className="img-fluid" alt="" />
                                    <span>9/16″ Square</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p></p>
                            <p><Link className="btn btn-asi" to="/reverb-demo">Reverberation Demonstration</Link></p>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <tbody>
                                        <tr className="colheader" style={{fontweight: "bold"}}>
                                            <td>Barriers</td>
                                            <td>125</td>
                                            <td>250</td>
                                            <td>500</td>
                                            <td>1000</td>
                                            <td>2000</td>
                                            <td>4000</td>
                                            <td>STC</td>
                                        </tr>
                                        <tr>
                                            <td>2 lb. PSF</td>
                                            <td>16</td>
                                            <td>22</td>
                                            <td>26</td>
                                            <td>32</td>
                                            <td>35</td>
                                            <td>40</td>
                                            <td style={{color: "#cc0000"}}>31</td>
                                        </tr>
                                        <tr>
                                            <td>1.25 lb. PSF</td>
                                            <td>14</td>
                                            <td>18</td>
                                            <td>24</td>
                                            <td>27</td>
                                            <td>33</td>
                                            <td>38</td>
                                            <td style={{color: "#cc0000"}}>28</td>
                                        </tr>
                                        <tr>
                                            <td>1 lb. PSF</td>
                                            <td>13</td>
                                            <td>17</td>
                                            <td>22</td>
                                            <td>26</td>
                                            <td>32</td>
                                            <td>37</td>
                                            <td style={{color: "#cc0000"}}>27</td>
                                        </tr>
                                        <tr>
                                            <td>1/2 lb. PSF</td>
                                            <td>8</td>
                                            <td>13</td>
                                            <td>17</td>
                                            <td>22</td>
                                            <td>27</td>
                                            <td>31</td>
                                            <td style={{color: "#cc0000"}}>20</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="9">
                                                <h4>Echo Eliminator Panel– Sound Absorption Coefficients</h4>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td></td>
                                            <td>Mount</td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>NRC</td>
                                        </tr>
                                        <tr>
                                            <td>1″ 3lb/cf</td>
                                            <td>A</td>
                                            <td>.08</td>
                                            <td>.31</td>
                                            <td>.79</td>
                                            <td>1.01</td>
                                            <td>1.00</td>
                                            <td>.99</td>
                                            <td className="emphasis">.80</td>
                                        </tr>
                                        <tr>
                                            <td>2″ 3lb/cf</td>
                                            <td>A</td>
                                            <td>.35</td>
                                            <td>.94</td>
                                            <td>1.32</td>
                                            <td>1.22</td>
                                            <td>1.06</td>
                                            <td>1.03</td>
                                            <td className="emphasis">1.15</td>
                                        </tr>
                                        <tr>
                                            <td>1″ 6lb/cf</td>
                                            <td>A</td>
                                            <td>.07</td>
                                            <td>.30</td>
                                            <td>.86</td>
                                            <td>1.10</td>
                                            <td>1.05</td>
                                            <td>1.03</td>
                                            <td className="emphasis">.85</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <h3>Product Specs</h3>
                                    <tbody>
                                        <tr>
                                            <th>Acoustical Backing Material</th>
                                            <td><Link to="/echo-eliminator">Echo Eliminator</Link></td>
                                        </tr>
                                        <tr>
                                            <th>Pattern</th>
                                            <td>Standard Guilford <a target="_blank" rel="noopener noreferrer" href="https://www.acousticalsurfaces.com/fabric_panel/fabricsel.htm">FR 701 Fabric Facings</a> or any decorative fabric of choice</td>
                                        </tr>
                                        <tr>
                                            <th>Features</th>
                                            <td>These Fabric Wall Panels are Economical, Multi-Purpose, High Performance, Durable, Easy to install</td>
                                        </tr>
                                        <tr>
                                            <th>Applications</th>
                                            <td>Offices, Residences, Meeting Rooms, Apartments, Condominiums, Board Rooms, Auditoriums</td>
                                        </tr>
                                        <tr>
                                            <th>Thickness</th>
                                            <td>1″, 2″</td>
                                        </tr>
                                        <tr>
                                            <th>Sizes</th>
                                            <td>Custom to fit your needs</td>
                                        </tr>
                                        <tr>
                                            <th>Colors</th>
                                            <td>See Fabric Color Selection</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br />
                            <div className="row">
                                <h4 style={{marginleft: "2em"}}>Testimonials:</h4>
                                <ol style={{marginleft: "3.5em", color: "#1261a4"}}>
                                    <li>
                                        <Link to="https://acousticalsurfaces.in/pdfs/Pharmacy_Noise_Problem.pdf" target="_blank">Wallmate® and Echo Eliminator™ Panels Solved Pharmacy’s Reverberation and Noise Level Problem</Link>
                                    </li>
                                    <li>
                                        <Link to="https://acousticalsurfaces.in/pdfs/NewLifeChurch.pdf" target="_blank">New Life Church SOLVES Acoustical Problems with Echo Eliminator™ Acoustical Panels Wallmate®</Link>
                                    </li>
                                    <li>
                                        <Link to="https://acousticalsurfaces.in/pdfs/Bethel_Wallmate.pdf" target="_blank">Bethel Lutheran Solves Bands Acoustical Problem with Wallmate® and Echo Eliminator™ Installation</Link>
                                    </li>
                                    <li>
                                        <Link to="https://acousticalsurfaces.in/pdfs/Fedora_Studio.pdf" target="_blank">Fedora Studios Uses Echo Eliminator™ and Wallmate®</Link>
                                    </li>
                                    <li>
                                        <Link to="https://acousticalsurfaces.in/pdfs/Fellowship_Hall_Acoustics.pdf" target="_blank">Fellowship Hall Acoustics</Link>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <hr className="d-none d-md-block d-lg-block d-xl-block" />
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default wallmate;