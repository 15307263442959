import React from "react";
import './carousel-fabriwall.css';
import FabriwallAS1 from '../../images/fabwall/as1.webp';
import FabriwallAS2 from '../../images/fabwall/as2.webp';
import FabriwallAS3 from '../../images/fabwall/as3.webp';
import FabriwallAS4 from '../../images/fabwall/as4.webp';
import FabriwallAS5 from '../../images/fabwall/as5.webp';
import FabriwallAS6 from '../../images/fabwall/as6.webp';
function carousel() {
    return (
    <>      
        <div id="carousel-fabriwall">
            <div className="row">
                <div className="col-xl-12">
                    <div className="items">
                        <div className="item">
                            <div className="img-holder">
                                <img src={FabriwallAS1} alt="FabriwallAS1" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={FabriwallAS2} alt="FabriwallAS2" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={FabriwallAS3} alt="FabriwallAS3" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={FabriwallAS4} alt="FabriwallAS4" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={FabriwallAS5} alt="FabriwallAS5" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={FabriwallAS6} alt="FabriwallAS6" />
                            </div>
                        </div>
                    </div>
                </div>    
            </div>                       
        </div>
    </>
    );
  }
  
  export default carousel;