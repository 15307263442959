import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Ebook from '../../images/additional-resources/Catalogue.png';
import './catalogue-download.css';
import ScrollToTopButton from '../ScrollButton';
function catdown() {
    return (
      
    <>
    <Helmet>
    <title>Download Catalogue of Soundproofing Products - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="Download Catalogue of Soundproofing Products" />
    <meta property="og:description" content="Acoustical Surfaces provide you with various solutions and products to help you control the noise issues. " />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/additional-resources/Catalogue.png" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/catalogue-download" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="catalogue">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h3>Download Catalogue of Soundproofing Products</h3>
                            <p>Acoustical Surfaces provide you with various solutions and  products to help you control the noise issues. We understand that noise problems are unfortunate and unavoidable occurrences, they can be reduced to a great extend.</p>
                            <div className="row">
                                <div className="col-lg-7 col-md-7 col-sm-12">
                                    <p><strong><br />Inside this Catalogue you will find:</strong></p>
                                    <p style={{marginLeft: "2em"}}>Products Details <br /><br />Applications<br /><br />Fabric Colour Shades</p>
                                </div>
                                <div className="col-lg-5 col-md-5 col-sm-12">
                                    <img src={Ebook} alt="eBook" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form">
                            <h4>Fill out the form to download the Catalogue</h4>
                            <div className="enqform">          
                                <form action="dl-script-catalogue.php" method="post" className="enquiry-form">
                                    <div className="">
                                        <input className="form-control" type="text" name="inputName" placeholder="Name" required="" />
                                    </div>
                                    <div className="">
                                        <input className="form-control" type="text" name="inputCompany" placeholder="Company" required="" />
                                    </div>
                                    <div className="">
                                        <input className="form-control" type="tel" name="inputTelephone" placeholder="Telephone" required="" />
                                    </div>
                                    <div className="">
                                        <input className="form-control" type="email" name="inputEmail" placeholder="Email" required="" />
                                    </div>
                                    <div className="">
                                        <input type="hidden" name="inputMessage" value="Catalogue of Soundproofing Products Download" />
                                    </div>
                                    <Link to="mailto:info@acousticalsurfaces.in" className="btn-send">Submit</Link>
                                </form>
                            </div>     
                        </div>
                        <div className="line"></div>
                    </div>
                    <div className="row cont-address">
                        <div className="col-lg-4 col-sm-6">
                            <h5>Acoustical Surfaces (Pvt.) Ltd.</h5>
                            <span>#1157, 4th Floor, 5th Main<br /> 
                                20th Cross, 7th Sector<br /> 
                                HSR Layout, Bangalore - 560102<br />
                                Karnataka, India<br />
                                Phone: +91-80-257 22022<br />
                                Mob: +91-94497 77212<br />
                                Email: info@acousticalsurfaces.in </span>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <h5 style={{visibility: "hidden"}}>.</h5>
                            <h6>(Mangalore Region)</h6><span>
                                #404, Rohan Micasa<br /> 
                                Bejai, Mangalore - 575003<br />
                                Karnataka, India<br />
                                Contact Person - Srinath<br />
                                Tel : 97423 13856<br />
                                Email: srinath@acousticalsurfaces.in</span>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <h5>Acoustical Surfaces, Inc.</h5>
                            <span>
                                123 Columbia Court North<br />
                                Suite 201 Chaska<br />
                                MN 55318<br />
                                Phone: (952)-448-5300<br />
                                Fax: (952)-448-2613<br />
                                Toll Free:1-(800)-854-2948<br />
                                Email: sales@acousticalsurfaces.in
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default catdown;