import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Ceilingtiles1 from '../../images/inner/sound-silencer-ceiling-pool-200x200.webp';
import Ceilingtiles2 from '../../images/inner/paitnub1-200x200.webp';
import Ceilingtiles3 from '../../images/inner/barricade_ceiling3-200x199.webp';
import Ceilingtiles4 from '../../images/inner/dBA-panel1-200x191.webp';
import Ceilingtiles5 from '../../images/inner/silk-metal-tiles-200x200.webp';
import Ceilingtiles6 from '../../images/inner/squareline_large1-200x200.webp';
import './soundproofing-ceiling-tiles.css';
import ScrollToTopButton from '../ScrollButton';
function soundproofceiling() {
    return (
      
    <>
    <Helmet>
    <title>Ceiling Tiles - Soundproofing Ceiling Tile Products</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Soundproofing Ceiling Tile Products" />
    <meta property="og:description" content="Ceiling Tiles have a long history in improving and aiding room acoustics." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/sound-silencer-ceiling-pool-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/soundproofing-ceiling-tiles" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/ceiling-tiles" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/soundproofing-ceiling-tiles" />
    </Helmet>
    <Header />
    <section className="content" id="ceilingtiles">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Soundproofing Ceiling Tile Products</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>Ceiling Tiles have a long history in improving and aiding room acoustics. When attached directly to a ceiling or when used as a drop ceiling, acoustic ceiling panels and sound absorbing drop ceiling tiles provide effective noise control. Offered in a wide variety of materials, sizes, and designs, acoustic ceiling tiles are an easy solution for improving the acoustical qualities of any space.</p>
                                    <p>These soundproofing tiles manage noise control in large or small areas to keep acoustics in balance for occupant comfort. Ceiling tiles are engineered from a variety of materials and features that will meet the varied requirements of each application. If you need high performance acoustic ceiling panels that provide blocking, absorption, and diffusion of sound, then dBA Panels are a perfect option, bringing all these qualities together in one unit. For high-end decorative applications that require the best in acoustical control with visual beauty, then Silk Metal ceiling tiles should be considered. No matter what your requirements are, we will match you with the ideal solution.</p>
                                    <p>Below, you’ll find some examples of our sound absorbing drop ceiling tile line. <Link to="/contact-us">Contact us</Link> today to learn more.</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>We offer an extensive selection of patented ceiling tiles that are guaranteed to meet the needs of the most challenging situations.</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Ceilingtiles1} alt="Sound Silencer Ceiling Tiles" />
                                </Link>
                                <h5>Sound Silencer™ Ceiling tiles</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Ceilingtiles2} alt="Painted Nubby Fiberglass Titles" />
                                </Link>
                                <h5>Painted Nubby Fiberglass tiles</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Ceilingtiles3} alt="Barrier ACT Ceiling Tiles" />
                                </Link>
                                <h5>Barrier ACT ceiling tiles</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Ceilingtiles4} alt="dBa Ceiling Tiles" />
                                </Link>
                                <h5>dBa Ceiling ceiling tiles</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Ceilingtiles5} alt="Silk Metal Ceiling Tiles" />
                                </Link>
                                <h5>Silk Metal ceiling tiles</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Ceilingtiles6} alt="Squareline Ceiling Tiles" />
                                </Link>
                                <h5>Squareline ceiling tiles</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default soundproofceiling; 