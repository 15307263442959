import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Barrierdecoupler1 from '../../images/inner/decoupler.webp';
import Barrierdecoupler2 from '../../images/inner/graphic.webp';
import './barrier-decoupler.css';
import ScrollToTopButton from '../ScrollButton';
function barrierdecoup() {
    return (
      
    <>
    <Helmet>
    <title>Barrier Decoupler - Vinyl Barrier with Foam Decoupler</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Barrier Decoupler" />
    <meta property="og:description" content="Vinyl Barrier with Foam Decoupler" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/decoupler.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/barrier-decoupler" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/decoupler" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/barrier-decoupler" />
    </Helmet>
    <Header />
    <section className="content" id="barrierdecoupler">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                    </div>
                    <h1 className='barrierdecoupler-content'>Barrier Decoupler</h1>
                    <div className="row pad barrierdecoupler-content">
                        <div className="col-xl-4 col-sm-12">
                            <Link className="example-image-link" to="../../images/inner/decoupler.webp" data-lightbox="example-1">
                            <img className="example-image img-fluid" src={Barrierdecoupler1} alt="Barrier Decoupler1" />
                            </Link>
                        </div>
                        <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                            <h2>Vinyl Barrier with Foam Decoupler</h2>
                            <ul style={{paddingLeft:"1em"}}>
                                <li>Reduces Sound Transmission</li>
                                <li>Contains Intrusive Noise</li>
                                <li>Improves Health and Safety</li>
                                <li>Provides Thermal Insulation</li>
                            </ul>
                        </div>
                        <div className="line"></div>
                    </div>
                    <div className="row barrierdecoupler-content">
                        <div className="col-sm-12">
                            <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                            <tbody>
                                <tr>
                                    <th>Material</th>
                                    <td>Mass Loaded Vinyl</td>
                                </tr>
                                <tr>
                                    <th>Features</th>
                                    <td>High density limp material with acoustic foam decoupler to reduce noise transmission.</td>
                                </tr>
                                <tr>
                                    <th>Applications</th>
                                    <td>Reduces noise transmission through ceilings, walls, floors, machinery and equipment, enclosures, PVC waste pipe,HVAC ducts. Apply on any solid surface or on floor under carpet for effective noise containment.</td>
                                </tr>
                                <tr>
                                    <th>Thickness</th>
                                    <td>3/8″</td>
                                </tr>
                                <tr>
                                    <th>Sizes</th>
                                    <td>54″ Wide × 20′, 30′ Rolls</td>
                                </tr>
                                <tr>
                                    <th>Decoupler</th>
                                    <td>Polyurethane Foam</td>
                                </tr>
                                <tr>
                                    <th>Colors</th>
                                    <td>Black, Grey</td>
                                </tr>
                                <tr>
                                    <th>Flammability</th>
                                    <td>UL 94-HFTI className C</td>
                                </tr>
                                <tr>
                                    <th>Pipe Surface Maximum Temperature</th>
                                    <td>200°F</td>
                                </tr>
                                <tr>
                                    <th>Installation</th>
                                    <td>Materials can be adhered to surface.</td>
                                </tr>
                                <tr>
                                    <th>Acoustical Properties</th>
                                    <td>Airborne sound transmission className = STC 27 per ASTM C423</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                    <br />
                    <div className="row barrierdecoupler-content">
                        <div className="col-sm-12">
                            <table className="table table-bordered-2">
                            <thead>
                                <tr>
                                    <th colSpan="10">Sound Transmission Loss (STC)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>125</td>
                                    <td>250</td>
                                    <td>400</td>
                                    <td>500</td>
                                    <td>1K</td>
                                    <td>2K</td>
                                    <td>4K</td>
                                    <td>5K</td>
                                    <td>STC</td>
                                </tr>
                                <tr>
                                    <td>Vinyl Barrier&nbsp;– Decoupler</td>
                                    <td>13</td>
                                    <td>19</td>
                                    <td>19</td>
                                    <td>21</td>
                                    <td>28</td>
                                    <td>33</td>
                                    <td>40</td>
                                    <td>42</td>
                                    <td className="emphasis">27</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-sm-12">
                            <Link className="example-image-link" to="../../images/inner/graphic.webp" data-lightbox="example-1">
                            <img className="example-image img-fluid" src={Barrierdecoupler2} alt="Barrier Decoupler2" />
                            </Link>
                        </div>
                        <div className="col-xl-8 col-sm-12 text">
                            <br />
                            <p>Acoustical Pipe lagging dramatically reduces the amount of noise that transmits through the walls of ductwork, PVC and cast iron pipes. When a decoupler layer is added sound transmission is improved.</p>
                            <p><strong>Caution:</strong>Polyurethane foam is flammable and may emit toxic fumes when burned. Do not use near open flame. Check local codes for allowed use. Manufacturer assumes no liability.</p>
                        </div>
                    </div>
                    <div className="line"></div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default barrierdecoup;