import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Tile1 from '../../images/plain/tile1.webp';
import Tile2 from '../../images/plain/tile2.webp';
import Tile3 from '../../images/plain/tile3.webp';
import Tile4 from '../../images/plain/tile4.webp';
import Tile5 from '../../images/plain/tile5.webp';
import Tile6 from '../../images/plain/tile6.webp';
import Tile7 from '../../images/plain/tile7.webp';
import Tile8 from '../../images/plain/tile8.webp';
import Tile9 from '../../images/plain/tile9.webp';
import Tile10 from '../../images/plain/tile10.webp';
import Tile11 from '../../images/plain/tile11.webp';
import Tile12 from '../../images/plain/tile12.webp';
import Tile13 from '../../images/plain/tile13.webp';
import Mobsidebar from '../mobile-sidebar';
import Carousel from './carousel-decopanel';
import Sliderdecopanel from './slider-decopanel';
import Sliderdecopanelplain from './slider-decopanelplain';
import Sliderdecopanelgrooved from './slider-decopanelgrooved';
import Sliderdecopanelprint from './slider-decopanelprint';
import Sliderdecopaneltiles from './slider-decopaneltiles';
import Sliderdecopanelrolls from './slider-decopanelrolls';
import Sliderdecopanelbuffles from './slider-decopanelbaffles';
import './style-decopanel.css';
import ScrollToTopButton from '../ScrollButton';
function dba() {
    return (
      
    <>
    <Helmet>
    <title>Decopanel - Highly Absorptive Acoustic Panels</title>
    <meta name="description" content="Deco panels are highly absorptive PET acoustic panels with non-woven polyester fiber as its core. Our acoustic panels are designed to control reverberated noise." />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="D'ecopanel" />
    <meta property="og:description" content="Decopanels are highly absorptive acoustic panels with non-woven polyester fiber as its core." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/flet-sm-1.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/decopanel" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/decopanel" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/decopanel" />
    </Helmet>
    <Header />
    <section className="content" id="decopanel">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
	                <div className="col-sm-12 heading-fsorb">				
	                    <h1>D'ecopanel</h1>
	                    <p>Decopanels are highly absorptive acoustic panels with non-woven polyester fiber as its core. They form an effective remedy for reverberation related issues in interior
                            spaces and are customizable to any design depending upon the customer demands. These <Link to="/pet-panel">pet panels</Link> are available in 21 different color options.</p>
	                    <div className="demo-cont" style={{height: "560px"}}>
	                        <Sliderdecopanel />
                            <div className="demo-cont__credits">
    	                        <div className="colorful-switch">
    	                            <label className="colorful-switch__label" htmlFor="colorful-switch-cb">
			                            <span className="colorful-switch__bg"></span>
        		                        <span className="colorful-switch__dot"></span>
				                        <span className="colorful-switch__on">
          		                            <span className="colorful-switch__on__inner"></span>
        		                        </span>
				                        <span className="colorful-switch__off"></span>
			                        </label>
    	                        </div>
                            </div>
                        </div>
	                </div>
                    <div className="col-sm-12">
	                    <h1>Applications</h1>
	                    <div className="line"></div>
	                    <p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                        <div className="row pad ">	
		                    <div className="col-md-4 col-sm-12 d-none d-md-block">
	                            <ul>
		                            <li>Home</li>
		                            <li>Theatres</li>
		                            <li>Conference Rooms</li>
		                            <li>Hospitals</li>
                                </ul>
		                    </div>
		                    <div className="col-md-4 col-sm-12 d-none d-md-block">
	                            <ul>
		                            <li>Convention Centres</li>
		                            <li>Banquet Halls</li>
		                            <li>Cafeterias</li>
		                            <li>Multiplexes</li>
                                </ul>
		                    </div>
		                    <div className="col-md-4 col-sm-12 d-none d-md-block">
	                            <ul>
		                            <li>Colleges</li>
		                            <li>Factories</li>
		                            <li>Open Work Spaces</li>
		                            <li>Auditoriums</li>
                                </ul>
		                    </div>
	                    </div>
                    </div>
                    <div className="col-sm-12 more">
                        <h1> More Products </h1>
	                    <div className="line"></div>
	                </div>
	                <div className="accordion-started panels row">
		                <div className="col-sm-12 col-md-12 col-lg-12 ">
	                        <label className="ac-label" htmlFor="ac-1"><b>D'ecopanel</b><br />PLAIN COLORS<i></i></label>
	    	                <div className="article ac-content">
	                        	<Sliderdecopanelplain />
	                        	<h1>D'ecopanel - PLAIN COLORS</h1>
		                        <div className="col-md-6 col-sm-12 d-none d-md-block panelfeatures">
	                                <ul>
		                                <li>Aesthetically Pleasing </li>
		                                <li>Available in many vibrant colours</li>
		                                <li>Eco Friendly & Easily installable</li>
		                                <li>Light weight and flexible</li>
		                                <li>Can be used as Pin boards</li>
		                            </ul>
		                        </div>
                                <h2>Product Details</h2>
                                <br />
	                            <div className="col-sm-12">
	                                <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-toggle="tab" href="#home">SPECIFICATION</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#menu1">REVERBERATION</a>
                                        </li>
	                                    <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#menu3">DATASHEET</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#menu2">BROCHURE</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div id="home" className="container tab-pane active"><br />
                                            <table className="table">
                                                <tbody>
	                                                <tr>
                                                        <th scope="row">Core</th>
                                                        <td>100% PET</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Thickness (mm)</th>
                                                        <td>10mm</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Size</th>
                                                        <td>1220mm x 2440mm</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Acoustic Properties</th>
                                                        <td>Up to 0.9 NRC</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Colours</th>
                                                        <td>Available in different colours</td>
                                                    </tr>
	                                                <tr>
                                                        <th scope="row">Flamability</th>
                                                        <td>B-s1, d0 as per EN-13501</td>
                                                    </tr>
	                                                <tr>
                                                        <th scope="row">Mounting type</th>
                                                        <td>Type A mounting , E-100 & E-50</td>
                                                    </tr>
	                                                <tr>
                                                        <th scope="row">Installation</th>
                                                        <td>Adhesive</td>
                                                    </tr>
                                                </tbody>
                                            </table>
	                                    </div>
                                        <div id="menu1" className="container tab-pane fade"><br />
                                            <h3>Sound Absorption Coefficients</h3>
     		                                <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Thickness</th>
                                                        <th scope="col">125Hz</th>
                                                        <th scope="col">250Hz</th>
                                                        <th scope="col">500Hz</th>
                                                        <th scope="col">1KHz</th>
                                                        <th scope="col">2KHz</th>
                                                        <th scope="col">4KHz</th>
                                                        <th scope="col">NRC</th>
                                                    </tr>   
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">10mm</th>
                                                        <td>0.23</td>
                                                        <td>0.71</td>
                                                        <td>0.94</td>
                                                        <td>0.98</td>
                                                        <td>0.87</td>
                                                        <td>0.87</td>
                                                        <td>0.90</td>
                                                    </tr>
                                                </tbody>
                                            </table>
	                                    </div>
                                        <div id="menu2" className="container tab-pane fade"><br />
                                        <br />
                                            <h3>Brochure</h3>
		                                    <br />
		                                    <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/decopanel-pdf/brochure-decosmall.pdf" className="brochure">Download</a>
	                                    </div>
	                                    <div id="menu3" className="container tab-pane fade"><br />
	                                    <br />
                                            <h3>ASI Wall Panels : Datasheet</h3>
	                                        <br />
	                                        <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/decopanel-pdf/plain.pdf" className="brochure">Download</a>
	                                    </div>
                                    </div>
	                            </div>			
	                            <div className="main-container">
                                    <div className="coverflow-container">
                                        <Carousel />
                                    </div>
                                </div>	
	                            <div className="col-sm-12 d-none d-sm-block">	
	                                <div className="row colorcard ">			
                                        <div className="col-sm-6">	
            	                            <table>
                                                <tr>
                                                    <th>PRODUCT:</th>
                                                    <td>D’ecopanel – PLAIN COLOURS</td>
                                                </tr>
                                                <tr>
                                                    <th>TYPE: </th>
                                                    <td>Acoustic wall panels</td>
                                                </tr>
                                                <tr>
                                                    <th>COMPOSITION:</th>
                                                    <td>100% PET</td>
                                                </tr>
                                                <tr>
                                                    <th>SIZE:</th>
                                                    <td>1220mm x 2440mm</td>
                                                </tr>
                                                <tr>
                                                    <th>THICKNESS: </th>
                                                    <td>10mm</td>
                                                </tr>
                                                <tr>
                                                    <th>WEIGHT:</th>
                                                    <td>5400gms / panel</td>
                                                </tr>
	                                        </table>
                                        </div>
	                                    <div className="col-sm-6">	
	                                        <table>
                                                <tr>
                                                    <th>EDGE:</th>
                                                    <td>Straight cut</td>
                                                </tr>
                                                <tr>
                                                    <th>COLOR:</th>
                                                    <td>White / Grey / Marble Grey / Charcoal Grey
                                                        Black / Yellow / Light Beige / Dark Beige / Natural / Brown / Pink / Purple, Pacific Blue / Sky Blue / Blue / Dark Blue / Orange / Banana Green / Green, Dark Green / Red / Maroon / Dark Maroon</td>
                                                </tr>
 	                                        </table>
                                        </div>	
	                                </div>
	                            </div>			
	                        </div>
                        </div>
                        <div className='line'></div>
	                    <div className="col-sm-12 col-md-12 col-lg-12">
	                        <label className="ac-label" htmlFor="ac-2"><b>D'ecopanel </b><br /> GROOVED DESIGNS<i></i></label>
		                    <div className="article ac-content">
	                            <Sliderdecopanelgrooved />
	                            <h1>D'ecopanel - GROOVED DESIGN</h1>
	                            <div className="col-md-6 col-sm-12 d-none d-md-block panelfeatures">
		                            <ul>
    		                            <li>Aesthetically Pleasing </li>
		                                <li>Available in many vibrant colours</li>
		                                <li>Eco Friendly & Easily installable</li>
		                                <li>Light weight and flexible</li>
		                                <li>Can be used as Pin boards</li>
		                            </ul>
	                            </div>
                                <h2>Product Details</h2>
                                <br />
	                            <div className="col-sm-12">
                                    <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-toggle="tab" href="#cp-home">SPECIFICATION</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#cp-menu1">REVERBERATION</a>
                                        </li>
	                                    <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#cp-menu3">DATASHEET</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#cp-menu2">BROCHURE</a>
                                        </li>
	                                </ul>
                                    <div className="tab-content">
                                        <div id="cp-home" className="container tab-pane active"><br />
                                            <table className="table">
                                                <tbody>
	                                                <tr>
                                                        <th scope="row">Core</th>
                                                        <td>100% PET</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Thickness (mm)</th>
                                                        <td>10mm</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Size</th>
                                                        <td>1220mm x 2400mm</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Acoustic Properties</th>
                                                        <td>Up to 0.9 NRC</td>
                                                    </tr>
                	                                <tr>
                                                        <th scope="row">Colours</th>
                                                        <td>Available in different colours</td>
                                                    </tr>
	                                                <tr>
                                                        <th scope="row">Flamability</th>
                                                        <td>B-s1, d0 as per EN-13501</td>
                                                    </tr>
	                                                <tr>
                                                        <th scope="row">Mounting type</th>
                                                        <td>Type A mounting , E-100 & E-50</td>
                                                    </tr>
	                                                <tr>
                                                        <th scope="row">Installation</th>
                                                        <td>Adhesive or frame work depending on mounting</td>
                                                    </tr>
                                                </tbody>
                                            </table>
	                                    </div>
                                        <div id="cp-menu1" className="container tab-pane fade"><br />
                                            <h3>Sound Absorption Coefficients</h3>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Thickness</th>
                                                        <th scope="col">125Hz</th>
                                                        <th scope="col">250Hz</th>
                                                        <th scope="col">500Hz</th>
                                                        <th scope="col">1KHz</th>
                                                        <th scope="col">2KHz</th>
                                                        <th scope="col">4KHz</th>
                                                        <th scope="col">NRC</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">10mm</th>
                                                        <td>0.23</td>
                                                        <td>0.71</td>
                                                        <td>0.94</td>
                                                        <td>0.98</td>
                                                        <td>0.87</td>
                                                        <td>0.87</td>
                                                        <td>0.90</td>
                                                    </tr>
                                                </tbody>
                                            </table>
	                                    </div>
                                        <div id="cp-menu2" className="container tab-pane fade"><br />
                                        <br />
                                            <h3>Brochure</h3>
	                                        <br />
	                                        <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/decopanel-pdf/brochure-decosmall.pdf" className="brochure">Download</a>
                                        </div>
	                                    <div id="cp-menu3" className="container tab-pane fade"><br />
                                        <br />
                                            <h3>Decopanel Grooved : Datasheet</h3>
	                                        <br />
	                                        <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/decopanel-pdf/GROOVED.pdf" className="brochure">Download</a>
                                        </div>
                                    </div>
	                            </div>			
                                <div className="row types">
	                                <div className="col-md-6 col-sm-12 colorcard2">
    		                            <img src={Tile1} alt="Tile1" /> 
	                                </div>
	                                <div className="col-md-6 col-sm-12 colorcard2">
                                        <dl>
                                            <dt>PRODUCT:</dt>
                                            <dd>D’ecopanel – GROOVE DESIGNS – Type1</dd>
                                            <dt>TYPE:</dt>
                                            <dd>Acoustic wall panels</dd>
                                            <dt>COMPOSITION:</dt>
                                            <dd>100% PET</dd>
                                            <dt>SIZE:</dt>
                                            <dd>1220mm x 2400mm</dd>
                                            <dt>THICKNESS:</dt>
                                            <dd>10mm</dd>
                                            <dt>WEIGHT:</dt>
                                            <dd>5400gms / panel</dd>
                                            <dt>EDGE:</dt>
                                            <dd>Half Beveled </dd>
                                            <dt>COLOR:</dt>
                                            <dd>All available colors</dd>
                                        </dl>
                                    </div>
                                </div>
                                <div className="row types">
		                            <div className="col-md-6 col-sm-12 colorcard2">
		                                <dl>
                                            <dt>PRODUCT:</dt>
                                            <dd>D’ecopanel – GROOVE DESIGNS – Type2</dd>
                                            <dt>TYPE:</dt>
                                            <dd>Acoustic wall panels</dd>
                                            <dt>COMPOSITION:</dt>
                                            <dd>100% PET</dd>
                                            <dt>SIZE:</dt>
                                            <dd>1220mm x 2400mm</dd>
                                            <dt>THICKNESS:</dt>
                                            <dd>10 mm</dd>
                                            <dt>WEIGHT:</dt>
                                            <dd>5400gms / panel</dd>
                                            <dt>EDGE:</dt>
                                            <dd>Half Beveled </dd>
                                            <dt>COLOR:</dt>
                                            <dd>All available colors</dd>
                                        </dl>
                                    </div>
	                                <div className="col-md-6 col-sm-12 colorcard2">
		                                <img src={Tile2} alt="Tile2" />
    	                            </div>
                                </div>
	                            <div className="row types">
	                                <div className="col-md-6 col-sm-12 colorcard2">
		                                <img src={Tile3} alt="Tile3" />
	                                </div>
	                                <div className="col-md-6 col-sm-12 colorcard2">
                                        <dl>
                                            <dt>PRODUCT:</dt>
                                            <dd>D’ecopanel – GROOVE DESIGNS – Type3</dd>
                                            <dt>TYPE:</dt>
                                            <dd>Acoustic wall panels</dd>
                                            <dt>COMPOSITION:</dt>
                                            <dd>100% PET</dd>
                                            <dt>SIZE:</dt>
                                            <dd>1220mm x 2400mm</dd>
                                            <dt>THICKNESS:</dt>
                                            <dd>10 mm</dd>
                                            <dt>WEIGHT:</dt>
                                            <dd>5400gms / panel</dd>
                                            <dt>EDGE:</dt>
                                            <dd>Half Beveled </dd>
                                            <dt>COLOR:</dt>
                                            <dd>All available colors</dd>
                                        </dl>
                                    </div>
                                </div>
	                            <div className="col-sm-12">
                                    <p>* Also more custom designs are available as per the request.</p>
                                </div>		
	                        </div>
	                    </div>
                        <div className='line'></div>
	                    <div className="col-sm-12 col-md-12 col-lg-12">
	                        <label className="ac-label" htmlFor="ac-3"><b>D'ecopanel</b><br />PRINT DESIGN<i></i></label>
				            <div className="article ac-content">
                                <Sliderdecopanelprint />
	                            <h1>D'ecopanel - PRINT DESIGNS</h1>
                                <div className="col-md-6 col-sm-12 d-none d-md-block panelfeatures">
	                                <ul>
		                                <li>Aesthetically Pleasing </li>
		                                <li>Available in many vibrant colours</li>
		                                <li>Eco Friendly & Easily installable</li>
		                                <li>Light weight and flexible</li>
		                                <li>Can be used as Pin boards</li>
		                            </ul>
		                        </div>
                                <h2>Product Details</h2>
                                <br />
	                            <div className="col-sm-12">
                                    <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-toggle="tab" href="#cb-home">SPECIFICATION</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#cb-menu1">REVERBERATION</a>
                                        </li>
	                                    <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#cb-menu3">DATASHEET</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#cb-menu2">BROCHURE</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div id="cb-home" className="container tab-pane active"><br />
                                            <table className="table">
                                                <tbody>
	                                                <tr>
                                                        <th scope="row">Core</th>
                                                        <td>100% PET</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Thickness (mm)</th>
                                                        <td>10mm</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Sizes</th>
                                                        <td>1220mm x 2440mm</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Acoustic Properties</th>
                                                        <td>Up to 0.9NRC</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Colours</th>
                                                        <td>Available in different colours</td>
                                                    </tr>
	                                                <tr>
                                                        <th scope="row">Flammability</th>
                                                        <td>B-s1, d0 as per EN-13501</td>
                                                    </tr>
	                                                <tr>
                                                        <th scope="row">Mounting type</th>
                                                        <td>Type A mounting, E-100 & E-50</td>
                                                    </tr>
	                                                <tr>
                                                        <th scope="row">Installation</th>
                                                        <td>Adhesive or frame work depending on mounting</td>
                                                    </tr>
                                                </tbody>
                                            </table>
	                                    </div>
                                        <div id="cb-menu1" className="container tab-pane fade"><br />
                                            <h3>Sound Absorption Coefficients</h3>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Thickness</th>
                                                        <th scope="col">125Hz</th>
                                                        <th scope="col">250Hz</th>
                                                        <th scope="col">500Hz</th>
                                                        <th scope="col">1KHz</th>
                                                        <th scope="col">2KHz</th>
                                                        <th scope="col">4KHz</th>
                                                        <th scope="col">NRC</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">10mm</th>
                                                        <td>0.23</td>
                                                        <td>0.71</td>
                                                        <td>0.94</td>
                                                        <td>0.98</td>
                                                        <td>0.87</td>
                                                        <td>0.87</td>
                                                        <td>0.90</td>
                                                    </tr>
                                                </tbody>
                                            </table>
	                                    </div>
                                        <div id="cb-menu2" className="container tab-pane fade"><br />
                                        <br />
                                            <h3>Brochure</h3>
	                                        <br />
	                                        <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/decopanel-pdf/brochure-decosmall.pdf" className="brochure">Download</a>
                                        </div>
	                                    <div id="cb-menu3" className="container tab-pane fade"><br />
                                        <br />
                                            <h3>Decopanel Printed : Datasheet</h3>
		                                    <br />
		                                    <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/decopanel-pdf/print.pdf" className="brochure">Download</a>
                                        </div>
                                    </div>
                                </div>			
                                <div className="row types">
	                                <div className="col-md-6 col-sm-12 colorcard2">
		                                <img src={Tile4} alt="Tile4" />
	                                </div>
	                                <div className="col-md-6 col-sm-12 colorcard2">
                                        <dl>
                                            <dt>PRODUCT:</dt>
                                            <dd>D’ecopanel – PRINT DESIGNS – Type1</dd>
                                            <dt>TYPE:</dt>
                                            <dd>Acoustic wall panels</dd>
                                            <dt>COMPOSITION:</dt>
                                            <dd>100% PET </dd>
                                            <dt>SIZE:</dt>
                                            <dd>1220mm x 2440mm</dd>
                                            <dt>THICKNESS:</dt>
                                            <dd>10 mm</dd>
                                            <dt>WEIGHT:</dt>
                                            <dd>5400gms / panel</dd>
                                            <dt>EDGE:</dt>
                                            <dd>Straight Cut </dd>
                                            <dt>COLOR:</dt>
                                            <dd>All available colors</dd>
                                        </dl>
                                    </div>
                                </div>
	                            <div className="row types">
	                                <div className="col-md-6 col-sm-12 colorcard2">
                                        <dl>
                                            <dt>PRODUCT:</dt>
                                            <dd>D’ecopanel – PRINT DESIGNS – Type2</dd>
                                            <dt>TYPE:</dt>
                                            <dd>Acoustic wall panels</dd>
                                            <dt>COMPOSITION:</dt>
                                            <dd>100% PET</dd>
                                            <dt>SIZE:</dt>
                                            <dd>1220mm x 2440mm</dd>
                                            <dt>THICKNESS:</dt>
                                            <dd>10 mm</dd>
                                            <dt>WEIGHT:</dt>
                                            <dd>5400gms / panel</dd>
                                            <dt>EDGE:</dt>
                                            <dd>Straight Cut </dd>
                                            <dt>COLOR:</dt>
                                            <dd>All available colors</dd>
                                        </dl>
                                    </div>
	                                <div className="col-md-6 col-sm-12 colorcard2">
		                                <img src={Tile5} alt="Tile5" />
	                                </div>
                                </div>
                                <div className="row types">
	                                <div className="col-md-6 col-sm-12 colorcard2">
		                                <img src={Tile6} alt="Tile6" />
	                                </div>
	                                <div className="col-md-6 col-sm-12 colorcard2">
                                        <dl>
                                            <dt>PRODUCT:</dt>
                                            <dd>D’ecopanel – PRINT DESIGNS – Type3</dd>
                                            <dt>TYPE:</dt>
                                            <dd>Acoustic wall panels</dd>
                                            <dt>COMPOSITION:</dt>                                                                        <dd>100% PET </dd>
                                            <dt>SIZE:</dt>
                                            <dd>1220mm x 2440mm</dd>
                                            <dt>THICKNESS:</dt>
                                            <dd>10 mm</dd>
                                            <dt>WEIGHT:</dt>
                                            <dd>5400gms / panel</dd>
                                            <dt>EDGE:</dt>
                                            <dd>Straight Cut </dd>
                                            <dt>COLOR:</dt>
                                            <dd>All available colors</dd>
                                        </dl>
                                    </div>
                                </div>		
	                            <div className="col-sm-12">
                                    <p>* Also more custom designs are available as per the request.</p>		
                                </div>			
	                        </div>
	                    </div>
                        <div className='line'></div>
	                    <div className="col-sm-12 col-md-12 col-lg-12">
	                        <label className="ac-label" htmlFor="ac-4"><b>D'ecopanel</b><br />TILES<i></i></label>
				            <div className="article ac-content">
	                            <Sliderdecopaneltiles />
	                            <h1>D'ecopanel - TILES</h1>
		                        <div className="col-md-6 col-sm-12 d-none d-md-block panelfeatures">
	                                <ul>
		                                <li>Aesthetically Pleasing </li>
		                                <li>Available in many vibrant colours</li>
		                                <li>Eco Friendly & Easily installable</li>
	                                    <li>Light weight and flexible</li>
	                                    <li>Can be used as Pin boards</li>
		                            </ul>
		                        </div>
                                <h2>Product Details</h2>
                                <br />
	                            <div className="col-sm-12">
                                    <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-toggle="tab" href="#dp-home">SPECIFICATION</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#dp-menu1">REVERBERATION</a>
                                        </li>
	                                    <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#dp-menu3">DATASHEET</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#dp-menu2">BROCHURE</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div id="dp-home" className="container tab-pane active"><br />
                                            <table className="table">
                                                <tbody>
	                                                <tr>
                                                        <th scope="row">Core</th>
                                                        <td>100% PET</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Thickness (mm)</th>
                                                        <td>10mm</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Sizes</th>
                                                        <td>Varies based on the design. Custom dimensions are possible.</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Acoustic Properties</th>
                                                        <td>Up to 0.9NRC</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Colours</th>
                                                        <td>Available in different colours</td>
                                                    </tr>
	                                                <tr>
                                                        <th scope="row">Flammability</th>
                                                        <td>B-s1, d0 as per EN-13501</td>
                                                    </tr>
	                                                <tr>
                                                        <th scope="row">Mounting type</th>
                                                        <td>Type A mounting, E-100 & E-50</td>
                                                    </tr>
	                                                <tr>
                                                        <th scope="row">Installation</th>
                                                        <td>Adhesive or frame work depending on mounting</td>
                                                    </tr>
                                                </tbody>
                                            </table>
	                                    </div>
                                        <div id="dp-menu1" className="container tab-pane fade"><br />
                                            <h3>Sound Absorption Coefficients</h3>
                                            <table className="table">
                                                <thead>   
                                                    <tr>
                                                        <th scope="col">Thickness</th>
                                                        <th scope="col">125Hz</th>
                                                        <th scope="col">250Hz</th>
                                                        <th scope="col">500Hz</th>
                                                        <th scope="col">1KHz</th>
                                                        <th scope="col">2KHz</th>
                                                        <th scope="col">4KHz</th>
                                                        <th scope="col">NRC</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">10mm</th>
                                                        <td>0.23</td>
                                                        <td>0.71</td>
                                                        <td>0.94</td>
                                                        <td>0.98</td>
                                                        <td>0.87</td>
                                                        <td>0.87</td>
                                                        <td>0.90</td>
                                                    </tr>
                                                </tbody>
                                            </table>
		                                </div>
                                        <div id="dp-menu2" className="container tab-pane fade"><br />
                                        <br />
                                            <h3>Brochure</h3>
		                                    <br />
		                                    <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/decopanel-pdf/brochure-decosmall.pdf" className="brochure">Download</a>
                                        </div>
	                                    <div id="dp-menu3" className="container tab-pane fade"><br />
                                        <br />
                                            <h3>Decopanel Tiles : Datasheet</h3>
		                                    <br />
		                                    <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/decopanel-pdf/tiles.pdf" className="brochure">Download</a>
                                        </div>
                                    </div>
	                            </div>			
                                <div className="row types">
	                                <div className="col-md-6 col-sm-12 colorcard2">
                                	    <img src={Tile7} alt="Tile7" />
    	                            </div>
	                                <div className="col-md-6 col-sm-12 colorcard2">
                                        <dl>
                                            <dt>PRODUCT:</dt>
                                            <dd>D’ecopanel – TILES DESIGNS – Type3</dd>
                                            <dt>TYPE:</dt>
                                            <dd>Acoustic wall panels</dd>
                                            <dt>COMPOSITION:</dt>
                                            <dd>100% PET</dd>
                                            <dt>SIZE:</dt>
                                            <dd>Varies based on the design. Custom dimensions are possible</dd>
                                            <dt>THICKNESS:</dt>
                                            <dd>10mm</dd>
                                            <dt>WEIGHT:</dt>
                                            <dd>Varies</dd>
                                            <dt>EDGE:</dt>
                                            <dd>Straight Cut </dd>
                                            <dt>COLOR:</dt>
                                            <dd>All available colors</dd>
                                        </dl>
                                    </div>
                                </div>			
                                <div className="row types">
	                                <div className="col-md-6 col-sm-12 colorcard2">
                                        <dl>
                                            <dt>PRODUCT:</dt>
                                            <dd>D’ecopanel – TILES DESIGNS – Type2</dd>
                                            <dt>TYPE:</dt>
                                            <dd>Acoustic wall panels</dd>
                                            <dt>COMPOSITION:</dt>
                                            <dd>100% PET</dd>
                                            <dt>SIZE:</dt>
                                            <dd>Varies based on design. Custom dimensions are possible</dd>
                                            <dt>THICKNESS:</dt>
                                            <dd>10mm</dd>
                                            <dt>WEIGHT:</dt>
                                            <dd>Varies</dd>
                                            <dt>EDGE:</dt>
                                            <dd>Straight Cut </dd>
                                            <dt>COLOR:</dt>
                                            <dd>All available colors</dd>
                                        </dl>
                                    </div>
	                                <div className="col-md-6 col-sm-12 colorcard2">
    		                            <img src={Tile8} alt="Tile8" />
	                                </div>
	                            </div>
                                <div className="row types">
	                                <div className="col-md-6 col-sm-12 colorcard2">
		                                <img src={Tile9} alt="Tile9" />
	                                </div>
	                                <div className="col-md-6 col-sm-12 colorcard2">
                                        <dl>
                                            <dt>PRODUCT:</dt>
                                            <dd>D’ecopanel – TILES DESIGNS – Type2</dd>
                                            <dt>TYPE:</dt>
                                            <dd>Acoustic wall panels</dd>
                                            <dt>COMPOSITION:</dt>
                                            <dd>100% PET</dd>
                                            <dt>SIZE:</dt>
                                            <dd>Varies based on design. Custom dimensions are possible</dd>
                                            <dt>THICKNESS:</dt>
                                            <dd>10mm</dd>
                                            <dt>WEIGHT:</dt>
                                            <dd>Varies</dd>
                                            <dt>EDGE:</dt>
                                            <dd>Straight Cut </dd>
                                            <dt>COLOR:</dt>
                                            <dd>All available colors</dd>
                                        </dl>
                                    </div>
                                </div>			
	                            <div className="col-sm-12">
                                    <p>* Also more custom designs are available as per the request.</p>		
                                </div>			
	                        </div>
	                    </div>
                        <div className='line'></div>
	                    <div className="col-sm-12 col-md-12 col-lg-12">
	                        <label className="ac-label" htmlFor="ac-4"><b>D'ecopanel</b><br />PLAIN/PRINT ROLLS<i></i></label>
				            <div className="article ac-content">
                                <Sliderdecopanelrolls />
                                <h1>D’ecopanel – PLAIN/PRINT ROLLS</h1>
	                            <div className="col-md-6 col-sm-12 d-none d-md-block panelfeatures">
	                                <ul>
		                                <li>Aesthetically Pleasing </li>
		                                <li>Available in many vibrant colours</li>
		                                <li>Eco Friendly & Easily installable</li>
		                                <li>Light weight and flexible</li>
		                                <li>Can be used as Pin boards</li>
		                            </ul>
		                        </div>
                                <h2>Product Details</h2>
                                <br />
	                            <div className="col-sm-12">
                                    <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-toggle="tab" href="#dpr-home">SPECIFICATION</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#dpr-menu1">REVERBERATION</a>
                                        </li>
	                                    <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#dpr-menu3">DATASHEET</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#dpr-menu2">BROCHURE</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div id="dpr-home" className="container tab-pane active"><br />
                                            <table className="table">
                                                <tbody>
	                                                <tr>
                                                        <th scope="row">Core</th>
                                                        <td>100% PET</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Thickness (mm)</th>
                                                        <td>2mm</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Sizes</th>
                                                        <td>1220mm x 6000mm</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Acoustic Properties</th>
                                                        <td>Up to 0.1NRC</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Colours</th>
                                                        <td>Available in different colours</td>
                                                    </tr>
	                                                <tr>
                                                        <th scope="row">Flammability</th>
                                                        <td>B-s1, d0 as per EN-13501</td>
                                                    </tr>
	                                                <tr>
                                                        <th scope="row">Mounting type</th>
                                                        <td>Type A mounting, E-100 & E-50</td>
                                                    </tr>
	                                                <tr>
                                                        <th scope="row">Installation</th>
                                                        <td>Adhesive</td>
                                                    </tr>
                                                </tbody>
                                            </table>
	                                    </div>
                                        <div id="dpr-menu1" className="container tab-pane fade"><br />
                                            <h3>Sound Absorption Coefficients</h3>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Thickness</th>
                                                        <th scope="col">125Hz</th>
                                                        <th scope="col">250Hz</th>
                                                        <th scope="col">500Hz</th>
                                                        <th scope="col">1KHz</th>
                                                        <th scope="col">2KHz</th>
                                                        <th scope="col">4KHz</th>
                                                        <th scope="col">NRC</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">1.9mm</th>
                                                        <td>0.00</td>
                                                        <td>0.02</td>
                                                        <td>0.04</td>
                                                        <td>0.07</td>
                                                        <td>0.18</td>
                                                        <td>0.28</td>
                                                        <td>0.1</td>
                                                    </tr>
                                                </tbody>
                                            </table>
	                                    </div>
                                        <div id="dpr-menu2" className="container tab-pane fade"><br />
                                        <br />
                                            <h3>Brochure</h3>
		                                    <br />
		                                    <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/decopanel-pdf/brochure-decosmall.pdf" className="brochure">Download</a>
                                        </div>
	                                    <div id="dpr-menu3" className="container tab-pane fade"><br />
                                        <br />
                                            <h3>Decopanel Rolls : Datasheet</h3>
		                                    <br />
		                                    <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/decopanel-pdf/rolls.pdf" className="brochure">Download</a>
                                        </div>
                                    </div>
                                </div>		
	                            <div className="row types">
	                                <div className="col-md-6 col-sm-12 colorcard2">
                                        <img src={Tile10} alt="Tile10" />
                                    </div>
	                                <div className="col-md-6 col-sm-12 colorcard2">
                                        <dl>
                                            <dt>PRODUCT:</dt>
                                            <dd>D’ecopanel – PLAIN SHEETS</dd>
                                            <dt>TYPE:</dt>
                                            <dd>Acoustic wall finish</dd>
                                            <dt>COMPOSITION:</dt>
                                            <dd>100% PET</dd>
                                            <dt>SIZE:</dt>
                                            <dd>1220mm x 6000mm</dd>
                                            <dt>THICKNESS:</dt>
                                            <dd>2mm</dd>
                                            <dt>WEIGHT:</dt>
                                            <dd>6.50 Kg per roll</dd>
                                            <dt>EDGE:</dt>
                                            <dd>Straight Cut </dd>
                                            <dt>COLOR:</dt>
                                            <dd>All available colors</dd>
                                        </dl>
                                    </div>
                                </div>			
                                <div className="row types">
	                                <div className="col-md-6 col-sm-12 colorcard2">
                                        <dl>
                                            <dt>PRODUCT:</dt>
                                            <dd>D’ecopanel – PLAIN SHEETS - Type2</dd>
                                            <dt>TYPE:</dt>
                                            <dd>Acoustic wall finish</dd>
                                            <dt>COMPOSITION:</dt>
                                            <dd>100% PET</dd>
                                            <dt>SIZE:</dt>
                                            <dd>1220mm x 6000mm</dd>
                                            <dt>THICKNESS:</dt>
                                            <dd>2mm</dd>
                                            <dt>WEIGHT:</dt>
                                            <dd>6.50 Kg per roll</dd>
                                            <dt>EDGE:</dt>
                                            <dd>Straight Cut </dd>
                                            <dt>COLOR:</dt>
                                            <dd>All available colors</dd>
                                        </dl>
                                    </div>
                                    <div className="col-md-6 col-sm-12 colorcard2">
		                                <img src={Tile11} alt="Tile11" />
	                                </div>
                                </div>
                                <div className="col-sm-12">
                                    <p>* Also more custom designs are available as per the request.</p>		
                                </div>		
                            </div>
		                </div>
                        <div className='line'></div>
			            <div className="col-sm-12 col-md-12 col-lg-12">
			                <label className="ac-label" htmlFor="ac-4"><b>D'ecopanel</b><br />BAFFLES/CLOUDS<i></i></label>
				            <div className="article ac-content">
		                        <Sliderdecopanelbuffles />
                                <h1>D’ecopanel – BAFFLES/CLOUDS</h1>
		                        <div className="col-md-6 col-sm-12 d-none d-md-block panelfeatures">
	                                <ul>
		                                <li>Aesthetically Pleasing </li>
		                                <li>Available in many vibrant colours</li>
		                                <li>Eco Friendly & Easily installable</li>
		                                <li>Light weight and flexible</li>
		                                <li>Can be used as Pin boards</li>
		                            </ul>
		                        </div>
                                <h2>Product Details</h2>
                                <br />
	                            <div className="col-sm-12">
                                    <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-toggle="tab" href="#bfl-home">SPECIFICATION</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#bfl-menu1">REVERBERATION</a>
                                        </li>
	                                    <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#bfl-menu3">DATASHEET</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#bfl-menu2">BROCHURE</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div id="bfl-home" className="container tab-pane active"><br />
                                            <table className="table">
                                                <tbody>
	                                                <tr>
                                                        <th scope="row">Core</th>
                                                        <td>100% PET</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Thickness</th>
                                                        <td>10mm / 20mm</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Sizes</th>
                                                        <td>1220mm x 2440mm</td>
                                                    </tr>
	                                                <tr>
                                                        <th scope="row">Height</th>
                                                        <td>5cm -25 cm</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Acoustic Properties</th>
                                                        <td>Up to 0.9NRC</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Colours</th>
                                                        <td>All available colours</td>
                                                    </tr>
	                                                <tr>
                                                        <th scope="row">Flamability</th>
                                                        <td>B-s1, d0 as per EN-13501</td>
                                                    </tr>
	                                                <tr>
                                                        <th scope="row">Mounting type</th>
                                                        <td>Type A mounting, E-100 & E-50</td>
                                                    </tr>
	                                                <tr>
                                                        <th scope="row">Installation</th>
                                                        <td>Adhesive or frame work depending on mounting</td>
                                                    </tr>
                                                </tbody>
                                            </table>
	                                    </div>
                                        <div id="bfl-menu1" className="container tab-pane fade"><br />
                                            <h3>Sound Absorption Coefficients</h3>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Thickness</th>
                                                        <th scope="col">125Hz</th>
                                                        <th scope="col">250Hz</th>
                                                        <th scope="col">500Hz</th>
                                                        <th scope="col">1KHz</th>
                                                        <th scope="col">2KHz</th>
                                                        <th scope="col">4KHz</th>
                                                        <th scope="col">NRC</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">10mm</th>
                                                        <td>0.23</td>
                                                        <td>0.71</td>
                                                        <td>0.94</td>
                                                        <td>0.98</td>
                                                        <td>0.87</td>
                                                        <td>0.87</td>
                                                        <td>0.90</td>
                                                    </tr>
                                                </tbody>
                                            </table>
	                                    </div>
                                        <div id="bfl-menu2" className="container tab-pane fade"><br />
                                        <br />
                                            <h3>Brochure</h3>
		                                    <br />
		                                    <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/decopanel-pdf/brochure-decosmall.pdf" className="brochure">Download</a>
                                        </div>
	                                    <div id="bfl-menu3" className="container tab-pane fade"><br />
                                        <br />
                                            <h3>Deopanel Baffles & Cloud : Datasheet</h3>
		                                    <br />
		                                    <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/decopanel-pdf/CLOUD.pdf" className="brochure">Cloud</a>
                                            <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/decopanel-pdf/baffles.pdf" className="brochure">Baffles</a>
                                        </div>
                                    </div>
                                </div>		
			                    <div className="row types">
	                                <div className="col-md-6 col-sm-12 colorcard2">
		                                <img src={Tile12} alt="Tile12" />
	                                </div>
	                                <div className="col-md-6 col-sm-12 colorcard2">
                                        <dl>
                                            <dt>PRODUCT:</dt>
                                            <dd>D’ecopanel – BAFFLES/CLOUDS – Type1</dd>
                                            <dt>TYPE:</dt>
                                            <dd>Acoustic wall Panels</dd>
                                            <dt>COMPOSITION:</dt>
                                            <dd>100% PET</dd>
                                            <dt>SIZE:</dt>
                                            <dd>1220mm x 2440mm </dd>
                                            <dt>THICKNESS:</dt>
                                            <dd>10mm / 20mm</dd>
                                            <dt>WEIGHT:</dt>
                                            <dd>Varies</dd>
                                            <dt>EDGE:</dt>
                                            <dd>Straight Cut </dd>
                                            <dt>COLOR:</dt>
                                            <dd>All available colors</dd>
                                        </dl>
                                    </div>
                                </div>			
                                <div className="row types">
	                                <div className="col-md-6 col-sm-12 colorcard2">
                                        <dl>
                                            <dt>PRODUCT:</dt>
                                            <dd>D’ecopanel – BAFFLES/CLOUDS – Type2</dd>
                                            <dt>TYPE:</dt>
                                            <dd>Acoustic wall Panels</dd>
                                            <dt>COMPOSITION:</dt>
                                            <dd>100% PET</dd>
                                            <dt>SIZE:</dt>
                                            <dd>1220mm x 2440mm</dd>
                                            <dt>THICKNESS:</dt>
                                            <dd>10mm / 20mm</dd>
                                            <dt>WEIGHT:</dt>
                                            <dd>Varies</dd>
                                            <dt>EDGE:</dt>
                                            <dd>Straight Cut </dd>
                                            <dt>COLOR:</dt>
                                            <dd>All available colors</dd>
                                        </dl>
                                    </div>
                                    <div className="col-md-6 col-sm-13 colorcard2">
		                                <img src={Tile13} alt="Tile13" />
	                                </div>
                                </div>
                                <div className="col-sm-12">
                                    <p>* Also more custom designs are available as per the request.</p>		
                                </div>		
			                </div>
		                </div>
                        <div className='line'></div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <ScrollToTopButton />      
    </section>
<Footer />
</>
);
}
export default dba;