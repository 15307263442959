import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import ScrollToTopButton from '../ScrollButton';
function polyester() {
    return (
      
    <>
    <Helmet>
    <title>Polymax - Polyester Acoustical Panels </title>
    <meta name="description" content="" />
    <meta property="og:title" content="Polyester Acoustical Panels" />
    <meta property="og:description" content="" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/polymax-beige-white-panels.jpg" />
    <meta property="og:locale" content="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/polyester-acoustipanels" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/polyester-acoustipanels" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/polyester-acoustipanels" hreflang="en-ae" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="IN" />
    <meta name="geo.region" content="india" />
    </Helmet>
    <Header />
    <section className="content">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Poly Max™ Polyester Acoustical Panels</h1>
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <Link className="example-image-link" to="images/inner/polymax-beige-white-panels.jpg" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src="images/inner/polymax-beige-white-panels.jpg" alt="acoustics" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 text">
                                <p>
                                    Formaldehyde Free<br />
                                    No Binding Agents<br />
                                    No Chemical Irritants<br />
                                    Architecturally Decorative<br />
                                    Tackable Surface<br />
                                    className A Fire Rated<br />
                                    60% Polyethylene Terephthalate – Recycled Content
                                </p>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <h3>Product Specifications</h3>
                                    <tbody>
                                        <tr>
                                            <th>Benefits Compared to Fiberglass:</th>
                                            <td>No chemical irritants, non-allergenic, non-toxic, formaldehyde free, increased impact resistance, no binding agents cost competitive</td>
                                        </tr>
                                        <tr>
                                            <th>Material</th>
                                            <td>7.5# per cubic foot density panels made from 100% polyester (60% PET-recycled fiber, 40% PET-virgin fiber)</td>
                                        </tr>
                                        <tr>
                                            <th>Applications</th>
                                            <td>Theaters, Home Theaters, Gymnasiums, Multipurpose Room, Commercial, Office Buildings, Studios, Production Houses, Restaurants  Night Clubs, Government,  Municipal Buildings, Schools,  Universities, Industrial,  Manufacturing Facilities</td>
                                        </tr>
                                        <tr>
                                            <th>Sizes</th>
                                            <td>White: 23¾″ × 47¾″  48″ × 96″<br />
                                                Dark Beige: 24″ × 48″<br />
                                                Custom sizes available, please call for details.<br />
                                                Available in 23¾″ × 47¾″ for Ceiling Tiles
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Thickness</th>
                                            <td>1″</td>
                                        </tr>
                                        <tr>
                                            <th>Colors</th>
                                            <td>Dark Beige  White</td>
                                        </tr>
                                        <tr>
                                            <th>Weight</th>
                                            <td>7.5# PCF</td>
                                        </tr>
                                        <tr>
                                            <th>Flammability</th>
                                            <td>className A Fire Rated<br />
                                                Flame Spread Index = 15<br />
                                                Smoke Developed Index = 250<br />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <h4>Poly Max Acoustical Performance</h4>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader"  style={{fontweight: "bold"}}>
                                            <td>Thickness</td>
                                            <td>Mount  Test</td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>NRC</td>
                                        </tr>
                                        <tr>
                                            <td>1″</td>
                                            <td>Type A</td>
                                            <td>.08</td>
                                            <td>.20</td>
                                            <td>.59</td>
                                            <td>.94</td>
                                            <td>1.04</td>
                                            <td>1.02</td>
                                            <td className="emphasis">.70</td>
                                        </tr>
                                        <tr>
                                            <td>1″</td>
                                            <td>Type E400</td>
                                            <td>.44</td>
                                            <td>.80</td>
                                            <td>.73</td>
                                            <td>.99</td>
                                            <td>1.00</td>
                                            <td>.97</td>
                                            <td className="emphasis">.90</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default polyester; 