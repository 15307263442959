import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Hvac1 from '../../images/inner/quiet_acoustical_liner1-200x200.webp';
import Hvac2 from '../../images/inner/duct_wrap1.webp';
import Hvac3 from '../../images/inner/vmax-peeled-back1-200x200.webp';
import Hvac4 from '../../images/inner/vblockvb2-new1-200x175.webp';
import Hvac5 from '../../images/inner/pipe-lag-top1-200x200.webp';
import Hvac6 from '../../images/inner/sil_mod1-200x200.webp';
import './hvac.css';
import ScrollToTopButton from '../ScrollButton';
function hvac() {
    return (
      
    <>
    <Helmet>
    <title>HVAC equipment - HVAC Sound Control Products</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="HVAC Sound Control Products" />
    <meta property="og:description" content="Soundproofing products must meet acoustical as well as thermal performance requirements to be effective." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/vmax-peeled-back1-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/hvac" hreflang="en-ae" />    
    <link rel="alternate" href="https://www.acousticalsurfaces.in/hvac" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/hvac" />
    </Helmet>
    <Header />
    <section className="content" id="hvac">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>HVAC Sound Control Products</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>Keeping HVAC equipment quiet is often a challenge. Soundproofing products must meet acoustical as well as thermal performance requirements to be effective. With HVAC duct soundproofing products designed for that purpose, it isn’t as hard as you might think.</p>
                                    <p>Using one of our many HVAC duct soundproofing options, unwanted noise is absorbed before it causes an issue. Liners, wraps, and barriers are engineered to absorb sound on pipes and ducts throughout commercial, industrial, and residential applications. All acoustical HVAC duct insulation wrap and other products meet fire rating standards. Is fan noise causing a disturbance? If it is, our duct silencers reduce annoying fan noise in occupied spaces and are custom designed to accommodate duct shape and airflow requirements while effectively reducing noise.</p>
                                    <p><Link to="/contact-us">Contact us</Link> today to learn more!</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>View Our Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Hvac1} alt="Quiet Liner" />
                                </Link>
                                <h5>Quiet Liner</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Hvac2} alt="Quiet Wrap" />
                                </Link>
                                <h5>Quiet Wrap</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Hvac3} alt="Duct V-Max" />
                                </Link>
                                <h5>Duct V-Max</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Hvac4} alt="VBLOK VB-2" />
                                </Link>
                                <h5>VBLOK VB-2</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Hvac5} alt="Pipe and Duct Lag" />
                                </Link>
                                <h5>Pipe and Duct Lag</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Hvac6} alt="Silent Mod Duct Silencers" />
                                </Link>
                                <h5>Silent Mod Duct Silencers</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default hvac; 