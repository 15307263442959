import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Soundblocking1 from '../../images/inner/rsic-1-clip-furring-channel-200x177.webp';
import Soundblocking2 from '../../images/inner/mlvb1-200x200.webp';
import Soundblocking3 from '../../images/inner/greenGlueTubes-200x200.webp';
import Soundblocking4 from '../../images/inner/acoustik1-200x200.webp';
import Soundblocking5 from '../../images/inner/studio-3d-doors-200x200.webp';
import Soundblocking6 from '../../images/inner/barricade_ceiling1-200x200.webp';
import Mobsidebar from '../mobile-sidebar';
import ScrollToTopButton from '../ScrollButton';
function soundblocking() {
    return (
      
    <>
    <Helmet>
    <title>Industrial Exterior Sound Blocking Soundproof Products</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Blocking Sound with Soundproofing Products" />
    <meta property="og:description" content="Our vast sound blocking product line includes materials that can be used in HVAC situations, industrial applications, exterior applications and many more. " />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/rsic-1-clip-furring-channel-200x177.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/sound-blocking-product" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/blockingsound" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/sound-blocking-product" />
    </Helmet>
    <Header />
    <section className="content">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Blocking Sound with Soundproofing Products</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>Our vast sound blocking product line includes materials that can be used in HVAC situations, industrial applications, exterior applications and many more. Many of these sound blocking products can be custom designed to work in almost all situations, rooms or workspaces.</p>
                                    <p>Our soundproofing and acoustics experts understand how sound travels from one space to another and have access to specially designed sound blocking materials and products that prevent or reduce sound transmission. We have soundproofing products that meet the industry standard, and can build custom enclosures that block sound coming from machinery or equipment. We recognize and appreciate that each situation is different and should be approached individually to find the best solution possible.</p>
                                    <p>Working from years of experience, Acoustical Surfaces is a leader in sound blocking materials. Our skilled acoustical advisors have a solution for any noise problem. Please see the products above for examples of our sound blocking systems and <Link to="/contact-us">contact us</Link> to learn more.</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>Sound Blocking Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Soundblocking1} alt="Clip Furring Channel" />
                                </Link>
                                <h5>RSIC-1 Clips</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Soundblocking2} alt="MLVB" />
                                </Link>
                                <h5>Mass Loaded Vinyl</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Soundblocking3} alt="Greenglue Tubes" />
                                </Link>
                                <h5>Green Glue</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Soundblocking4} alt="Acoustik Underlament" />
                                </Link>
                                <h5>Acoustik Underlament</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Soundblocking5} alt="Studio 3D Doors" />
                                </Link>
                                <h5>Studio 3D Doors</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Soundblocking6} alt="Barrier Ceiling Tiles" />
                                </Link>
                                <h5>Barrier Ceiling Tiles</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default soundblocking; 