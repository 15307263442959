import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Cellulose1 from '../../images/inner/cellulose-panels.webp';
import Mobsidebar from '../mobile-sidebar';
import './cellulose-panels.css';
import ScrollToTopButton from '../ScrollButton';
function cellulose() {
    return (
      
    <>
    <Helmet>
    <title>Cellulose insulation panels | Cellulose Acoustic Panels</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Cellulose-Based Acoustical & Thermal Panels" />
    <meta property="og:description" content="CFAB cellulose panels are an industry first cellulose-based material. " />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/cellulose-panels.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/cellulose-panels" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/cellulose-panels" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/cellulose-panels" />
    </Helmet>
    <Header />
    <section className="content" id="cellulose">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
                    <h1 className='cellulose-content'>CFAB™ Cellulose Panels</h1> 
                    <div className="row pad cellulose-content">
                        <div className="col-xl-4 col-sm-12">
                            <Link className="example-image-link" to="../../images/inner/cellulose-panels.webp" data-lightbox="example-1">
                                <img className="example-image img-fluid" src={Cellulose1} alt="Cellulose panels1" />
                            </Link>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 text">
                            <h2>Cellulose-Based Acoustical & Thermal Panels</h2>
                            <ul style={{marginLeft: "1em"}}>
                                <li>Controls & Deadens Sound</li>
                                <li>Reduces Airborne Sound Transmission</li>
                                <li>Blocks Outdoor Noise</li>
                                <li>Easy to Handle & Install – No Special Equipment Required</li>
                                <li>className A Fire Rating</li>
                                <li>Resists Mold Growth</li>
                                <li>Made From Renewable & Recycled Fibers</li>
                            </ul>
                            <p>CFAB cellulose panels are an industry first cellulose-based  material. Consisting of 65-75% recycled content, CFAB cellulose products represent a very green solution for acoustical materials. They are an innovative, yet sustainable, alternative to traditional fiberglass and synthetic acoustical and thermal panels,and are true performers in a variety of different applications</p>
                        </div>
                        <div className="line"></div>
                    </div>
                    <div className="row cellulose-content">
                        <div className="col-sm-12">
                            <h3>Product Specs</h3>  
                            <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                <tbody>
                                    <tr>
                                        <th>Applications</th>
                                        <td>CFAB cellulose panels can be used in any area that calls for acoustical or 
                                            thermal treatment. They can be covered with fabric or other decorative material. Typical applications include but are not limited to insulation components, office panels, acoustic panels/inserts, and any molded, flat panel or lofted parts. 
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Sizes</th>
                                        <td>
                                            CFAB cellulose panels are available in 2′×4′, 4′×4′, and 4′×8′ panels. 
                                            Panels are available in ½″, 1″, or 2″ thickness.
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Densities</th>
                                        <td>                                    
                                            3lb pcf – 1″ Thickness & 2″ Thickness <br />
                                            6lb pcf – 1″ Thickness <br />
                                            8lb pcf – ½″ Thickness & 1″ Thickness  <br />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-sm-12 text">
                            <h3>Green Qualities</h3>
                            <p>Made from a combination of recycled and renewable fibers, CFAB cellulose products are 
                                environmentally responsible and sustainable. Plus, most CFAB cellulose products are 
                                completely recyclable, and no scrap is produced during manufacturing or installation 
                                of the material. They are also manufactured with a greener process by reducing energy 
                                use and air pollution. Through an innovative manufacturing process, ecocell is 
                                produced as a cellulose based product made primarily from post-consumer and 
                                post-industrial paper, with recycled newspaper being the main raw material.
                            </p>
                            <h3>Acoustical Performance</h3>
                            <p>The open design and density of CFAB cellulose acoustical panels increases sound 
                                absorption to control and deaden sound. CFAB cellulose acoustical panels achieve 
                                high Noise Reduction Coefficient (NRC) ratings based on the density specified. 
                                The chart below represents NRC ratings using a standard weight material. Sound 
                                Transmission classNameification (STC) ratings have been done for specific applications. 
                                STC values are determined by all of the construction materials in an assembly. 
                                The STC ratings exceed the values attained with commonly used acoustical materials.
                            </p>
                            <h3>Product Safety</h3>
                            <p>Homeowners are concerned with safety, especially when it comes to one of their 
                                biggest investments, their homes. CFAB cellulose panels have a className A fire rating. 
                                They do not contain any harmful airborne particles and do not itch or irritate skin 
                                like fiberglass insulation. Easy to handle and soft to the touch, CFAB cellulose 
                                panels can be left exposed, painted or covered with drywall. To protect against mold 
                                growth, CFAB cellulose panels contain an EPA registered fungicide.
                            </p>     
                        </div>
                    </div>
                    <div className="row cellulose-content">
                        <div className="col-sm-12">
                            <table className="table table-bordered-2">
                                <thead>
                                    <tr>
                                        <th colSpan="9">CFAB Cellulose Panels&nbsp;&ndash; Acoustical Testing Data</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="colheader">
                                        <td rowspan="2">Thickness</td>
                                        <td colSpan="6">Absorption Coefficients @ Octave Band Frequencies (Hz)</td>
                                        <td rowspan="2">NRC</td>
                                    </tr>
                                    <tr className="colheader">
                                        <td>125</td>
                                        <td>250</td>
                                        <td>500</td>
                                        <td>1,000</td>
                                        <td>2,000</td>
                                        <td>4,000</td>
                                    </tr>
                                    <tr>
                                        <td>½″</td>
                                        <td>0.05</td>
                                        <td>0.09</td>
                                        <td>0.33</td>
                                        <td>0.64</td>
                                        <td>0.87</td>
                                        <td>1.01</td>
                                        <td style={{color: "#cc0000"}} className="emphasis">0.50</td>
                                    </tr>
                                    <tr>
                                        <td>1″</td>
                                        <td>0.09</td>
                                        <td>0.26</td>
                                        <td>0.84</td>
                                        <td>1.05</td>
                                        <td>1.05</td>
                                        <td>1.05</td>
                                        <td style={{color: "#cc0000"}} className="emphasis">0.80</td>
                                    </tr>
                                    <tr>
                                        <td>1½″</td>
                                        <td>0.14</td>
                                        <td>0.40</td>
                                        <td>0.93</td>
                                        <td>1.09</td>
                                        <td>1.03</td>
                                        <td>1.03</td>
                                        <td style={{color: "#cc0000"}} className="emphasis">0.85</td>
                                    </tr>
                                    <tr>
                                        <td>2″</td>
                                        <td>0.39</td>
                                        <td>0.63</td>
                                        <td>1.18</td>
                                        <td>1.11</td>
                                        <td>1.06</td>
                                        <td>1.09</td>
                                        <td style={{color: "#cc0000"}} className="emphasis">1.00</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table table-bordered-2">
                                <thead>
                                    <tr>
                                        <th colSpan="3">CFAB Cellulose Panels&nbsp;&ndash; Physical Property Data</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="colheader">
                                        <td>Property</td>
                                        <td>Test Method</td>
                                        <td>Value</td>
                                    </tr>
                                    <tr>
                                        <td>Surface Burning Characteristics</td>
                                        <td>ASTM E-84, UL 723</td>
                                        <td>Flame Spread: 15<br />
                                            Smoke Developed: &lt;450 (className A)</td>
                                    </tr>
                                    <tr>
                                        <td>Critical Radiant Flux</td>
                                        <td>ASTM E-970</td>
                                        <td>&gt;0.12 w/cm²</td>
                                    </tr>
                                    <tr>
                                        <td>Corrosiveness</td>
                                        <td>ASTM C-739</td>
                                        <td>Acceptable</td>
                                    </tr>
                                    <tr>
                                        <td>Fungal Growth</td>
                                        <td>ASTM C-1338</td>
                                        <td>Acceptable</td>
                                    </tr>
                                    <tr>
                                        <td>Thermal Resistance</td>
                                        <td>ASTM C-518</td>
                                        <td>3.6&nbsp;&ndash; 3.7 R per Inch</td>
                                    </tr>
                                    <tr>
                                        <td>Moisture Absorption</td>
                                        <td>ASTM C-739</td>
                                        <td>Acceptable</td>
                                    </tr>
                                    <tr>
                                        <td>Odor Emission</td>
                                        <td>ASTM C-1304</td>
                                        <td>Acceptable</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default cellulose;