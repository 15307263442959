import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import './acoustyfabric-usa.css';
import ScrollToTopButton from '../ScrollButton';
function acousticfabric() {
    return (
      
    <>
    <Helmet>
    <title>Acousty FABRIC USA Collection - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="AcoustyFABRIC USA Collection" />
    <meta property="og:description" content="We manufacture all kind of interior panel and upholstery fabrics using finest quality of Polyster and are available in over 500 different varieties." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/fabwall/fabric5.jpg" />
    <meta property="og:locale" content="en-in" />
    </Helmet>
    <Header />
    <section className="content" id="fabricusa">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                    </div>
	                <div className="col-sm-12 heading-fsorb">				
                		<h1>Acousty<span className="fabriwall">FABRIC</span> USA Collection </h1>
                		<p>Although most of the fabric goes well with the FabriWALL System, it is important to choose the right fabric. We manufacture all kind of interior panel and upholstery fabrics using finest quality of Polyster and are available in over 500 different varieties. 
                        These fabrics are tested and have gained wide appreciation, owing to their exquisite designs, finish, tear strength and color fastness </p>
	                </div>
		            <br /><br />
		            <div className="col-sm-12 cloths">
		                <div className="row d-flex justify-content-center mb-4">
                        	<div className="col-2 col-sm-2"><Link to="/acfabric-Anchorage"><button>Anchorage</button></Link></div>
                            <div className="col-2 col-sm-2"><Link to="/acfabric-beehave"><button>BeeHave</button></Link></div>
                            <div className="col-2 col-sm-2"><Link to="/acfabric-broadcast"><button>Broadcast</button></Link></div>
                            <div className="col-2 col-sm-2"><Link to="/acfabric-cape"><button>Cape Cod</button></Link></div>
                        </div>
                        <div className="row d-flex justify-content-center mb-4">
                        	<div className="col-2 col-sm-2"><Link to="/acfabric-crosstown"><button>Crosstown</button></Link></div>
                            <div className="col-2 col-sm-2"><Link to="/acfabric-drift"><button>Drift</button></Link></div>
                            <div className="col-2 col-sm-2"><Link to="/acfabric-fr701"><button>FR 701</button></Link></div>
                            <div className="col-2 col-sm-2"><Link to="/acfabric-fr702"><button>FR 702</button></Link></div>
                            <div className="col-2 col-sm-2"><Link to="/acfabric-fr703"><button>FR 703</button></Link></div>
                        </div>	
                        <div className="row d-flex justify-content-center mb-4">
                            <div className="col-2 col-sm-2"><Link to="/acfabric-framework"><button>Framework</button></Link></div>
                            <div className="col-2 col-sm-2"><Link to="/acfabric-highbeams"><button>Highbeams</button></Link></div>
                            <div className="col-2 col-sm-2"><Link to="/acfabric-intermix"><button>Intermix</button></Link></div>
                            <div className="col-2 col-sm-2"><Link to="/acfabric-intution"><button>Intuition</button></Link></div>
                            <div className="col-2 col-sm-2"><Link to="/acfabric-jane"><button>Jane</button></Link></div>
                        </div>
                        <div className="row d-flex justify-content-center mb-4">
                        	<div className="col-2 col-sm-2"><Link to="/acfabric-lido"><button>Lido</button></Link></div>
                            <div className="col-2 col-sm-2"><Link to="/acfabric-network"><button>Network</button></Link></div>
                            <div className="col-2 col-sm-2"><Link to="/acfabric-quadrille"><button>Quadrille</button></Link></div>
                            <div className="col-2 col-sm-2"><Link to="/acfabric-spinel"><button>Spinel</button></Link></div>
                            <div className="col-2 col-sm-2"><Link to="/acfabric-steadfast"><button>Steadfast</button></Link></div>
                        </div>	
                        <div className="row d-flex justify-content-center mb-4">
                        	<div className="col-2 col-sm-2"><Link to="/acfabric-streetwise"><button>Streetwise</button></Link></div>
                            <div className="col-2 col-sm-2"><Link to="/acfabric-studio"><button>Studio</button></Link></div>
                            <div className="col-2 col-sm-6"></div>
                        </div>	
            		</div>
		            <br />
		            <hr />
                    <div className="col-12 d-flex justify-content-center backbtn">
                    	<Link to="acoustyfabric"><button>Back to Acousty<span style={{color:"red"}}>FABRIC</span></button></Link>
                    </div>
                    <hr />
                    <div className="col-sm-12 application">
			            <h1>APPLICATION</h1>
	                    <div className="hover14 text-center justify-content-center">
		                <br />
  		                    <div className="col-sm-12">
			                    <figure className="m-0"><div className="tinted-image">CINEMA HALL</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image2">OFFICE ROOMS & HALLS</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image3">AUDITORIUM & EDUCATION</div></figure>
 		                    </div>
                        <br />
                        </div>
                    </div>
		            <br />
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acousticfabric;