import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundroom from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import Cadlibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import CMC from '../../images/projects-slider/cmc-image-1.webp';
import ScrollToTopButton from '../ScrollButton';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
function Acousticcmc() {
    const sliderRef = useRef(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    const goToPrev = () => {
        sliderRef.current.slickPrev();
    };

    const goToNext = () => {
        sliderRef.current.slickNext();
    };

    return (

        <>
            <Helmet>
                <title>About Our Christian Medical College Acoustics Project</title>
                <meta name="description" content="" />
                <meta property="og:title" content="Christian Medical College" />
                <meta property="og:description" content="To treat the Wall Partition Acoustic Isolation to achieve 39dB addition to that absorption wall paneling of NRC 0.9 and suspended ceilings with Acoustic isolation and renovating the existing rooms by retrofitting with current technology." />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/projects-slider/cmc-image-1.webp" />
                <meta property="og:locale" content="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/acoustic-projects/project-christian-medical-college" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/acoustic-projects/project-christian-medical-college" hreflang="en-in" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="IN" />
                <meta name="geo.region" content="india" />
            </Helmet>
            <Header />
            <section className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 sidemenu">
                            <Sidebar />
                        </div>
                        <div className="col-sm-9 product-page nopaddding">
                            <div className="container">
                                <div className="megamenu">
                                    <Megamenu />
                                </div>
                            </div>
                            <div className="image-slider pro-content">
                                <div id="caption-slide" className="slider-container">
                                    <div className="slider">
                                        <Slider {...settings} ref={sliderRef}>
                                            <div className="slide">
                                                <img src={CMC} className="img-fluid" alt="cmc" />
                                            </div>
                                            <div className="slide">
                                                <img src={CMC} className="img-fluid" alt="cmc" />
                                            </div>
                                            <div className="slide">
                                                <img src={CMC} className="img-fluid" alt="cmc" />
                                            </div>
                                        </Slider>
                                    </div>
                                    <div className="switch" id="prev" onClick={goToPrev} style={{ backgroundColor: "transparent" }}><span>&#10094;</span></div>
                                    <div className="switch" id="next" onClick={goToNext} style={{ backgroundColor: "transparent" }}><span>&#10095;</span></div>
                                </div>
                            </div>
                            <div className="row pro-content">
                                <div className="col-sm-12 writeup">
                                    <h3>Christian Medical College</h3>
                                    <p className="note">Location: Vellore, Tamil Nadu</p>
                                    <p><strong>About Christian Medical College.</strong>
                                        <br /> The Christian Medical College and Hospital (CMC) is a private, minority-run educational and research institute that includes a network of primary, secondary and tertiary care hospitals in and around Vellore, Tamil Nadu, India. Founded in 1900 by an American missionary, Dr Ida S. Scudder, CMC has brought many significant achievements to India. CMC was ranked third among medical colleges in India in 2017 by India Today and Outlook India and second in India by The Week. Construction area : 2361 sq.ft
                                        <br />
                                        <br />
                                        <strong> Our Scope</strong>
                                        <br />To treat the Wall Partition Acoustic Isolation to achieve 39dB addition to that absorption wall paneling of NRC 0.9 and suspended ceilings with Acoustic isolation and renovating the existing rooms by retrofitting with current technology.
                                    </p>
                                    <br />
                                    <hr />
                                </div>
                            </div>
                            <div className="row resources">
                                <div className="col-xl-12 col-lg-12 col-sm-12">
                                    <h2>Additional Resources</h2>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-12">
                                    <h6 style={{ textAlign: "center" }}>Creating Better-Sounding Rooms</h6>
                                    <img className="hvr-grow" src={Soundroom} alt="Create Better Sounding Rooms" target="_blank" rel="noopener noreferrer" />
                                    <div className="butn"><Link to="/bs-rooms-guide">Download eBook</Link></div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-12">
                                    <h6 style={{ textAlign: "center" }}>Solutions to Common Noise Problems</h6>
                                    <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems" target="_blank" rel="noopener noreferrer" />
                                    <div className="butn"><Link to="/noise-problems-guide">Download eBook</Link></div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-12">
                                    <h6 style={{ textAlign: "center" }}><br />CAD, CSI, & Revit Library </h6>
                                    <img className="hvr-grow" src={Cadlibrary} alt="CAD Library" target="_blank" rel="noopener noreferrer" />
                                    <div className="butn"><Link to="/cad-library-guide">Access Product Cad Files</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mobsidebar'>
                        <div className="col-sm-12">
                            <div className="container">
                                <div className="row">
                                    <Mobsidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default Acousticcmc;