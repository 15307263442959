import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Sonexone1 from '../../images/inner/prod_sonexonepanels.webp';
import Sonexone2 from '../../images/inner/SNXone_panelsprintshop.webp';
import Sonexone3 from '../../images/inner/SNXone_range_fullview.webp';
import Sonexone4 from '../../images/inner/SNXone_panels_multi.webp';
import Sonexone5 from '../../images/inner/sonex-white2-200x155.webp';
import Sonexone6 from '../../images/inner/sonex-grey-200x155.webp';
import Sonexone7 from '../../images/inner/sonex-charcoal-200x155.webp';
import Sonexone8 from '../../images/inner/sonex-hpc-black2-200x155.webp';
import Sonexone9 from '../../images/inner/sonex-hpc-light-grey2-200x155.webp';
import Sonexone10 from '../../images/inner/sonex-hpc-white2-200x155.webp';
import Sonexone11 from '../../images/inner/sonex-hpc-almond2-200x155.webp';
import Sonexone12 from '../../images/inner/sonex-hpc-medium-grey2-200x155.webp';
import Sonexone13 from '../../images/inner/sonex-hpc-light-blue2-200x155.webp';
import Sonexone14 from '../../images/inner/sonex-hpc-ivory2-200x155.webp';
import './sonex-one.css';
import ScrollToTopButton from '../ScrollButton';
function sonex() {
    return (
      
    <>
    <Helmet>
    <title>Sonex One - Melamine Foam Acoustical Panels </title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Sonex One" />
    <meta property="og:description" content="Melamine Foam Acoustical Panels" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/prod_sonexonepanels.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/sonex-one" hreflang="en-ae" />    
    <link rel="alternate" href="https://www.acousticalsurfaces.in/sonex-one" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/sonex-one" />
    </Helmet>
    <Header />
    <section className="content" id="sonex">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Sonex One™</h1> 
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/prod_sonexonepanels.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Sonexone1} alt="Sonex one1" />
                                </Link>
                                <div className="row col-md-12 col-lg-12 minigallery">
                                    <div className='col-lg-4 col-md-2'>
                                        <Link className="example-image-link" to="../../images/inner/SNXone_panelsprintshop.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" style={{margin: "5px", maxwidth: "4em"}} src={Sonexone2} alt="Sonex one2" />
                                        </Link>
                                    </div>
                                    <div className='col-lg-4 col-md-2'>
                                        <Link className="example-image-link" to="../../images/inner/SNXone_range_fullview.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" style={{margin: "5px", maxwidth: "4em"}} src={Sonexone3} alt="Sonex one3" />
                                        </Link>
                                    </div>
                                    <div className='col-lg-4 col-md-2'>
                                        <Link className="example-image-link" to="../../images/inner/SNXone_panels_multi.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" style={{margin: "5px", maxwidth: "4em"}} src={Sonexone4} alt="Sonex one4" />
                                        </Link> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Melamine Foam Acoustical Panels</h2>
                                <h3>Features</h3>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>Fiber Free</li>
                                    <li>Lightweight</li>
                                    <li>Economical</li>
                                    <li>Class A Fire Retardant</li>
                                </ul> 
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/sonex-one-acoustical-panels2.pdf" target="_blank">Product Specs</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Product Specs</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>Appearance</th>
                                            <td>Melamine Acoustical Foam </td>
                                        </tr>
                                        <tr>
                                            <th>Pattern</th>
                                            <td>Convoluted Surface – Sculpted surface deflects and disperses sound waves</td>
                                        </tr>
                                        <tr>
                                            <th>Features</th>
                                            <td>Porous</td>
                                        </tr>
                                        <tr>
                                            <th>Applications </th>
                                            <td>Suitable for a wide variety of applications and environments for attractive noise control solutions. Offices, manufacturing plants, schools, broadcasting and recording studios. </td>
                                        </tr>
                                        <tr>
                                            <th>Thickness </th>
                                            <td>2″, 3″ </td>
                                        </tr>
                                        <tr>
                                            <th>Size</th>
                                            <td>2′×4′ </td>
                                        </tr>
                                        <tr>
                                            <th>Density</th>
                                            <td>0.7lbs/cub. ft. </td>
                                        </tr>
                                        <tr>
                                            <th>Standard Colors</th>
                                            <td>White, Grey </td>
                                        </tr>
                                        <tr>
                                            <th>Optional Hpc-Coated Colors </th>
                                            <td>Black, Light Grey, White, Almond, Medium Grey, Light Blue, Ivory </td>
                                        </tr>
                                        <tr>
                                            <th>Custom Colors </th>
                                            <td>Please contact us to discuss custom color options. </td>
                                        </tr>
                                        <tr>
                                            <th>Flame Spread per ASTM E84 </th>
                                            <td>Class A.<br />
                                                Natural: 5;<br />
                                                Painted: 10;<br />
                                                HPC-Coated: 15;<br />
                                                Colortec: 5
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Smoke Density per ASTM E84 </th>
                                            <td>Class A.<br />
                                                Natural: 50;<br />
                                                Painted: 10;<br />
                                                HPC-Coated: 150;<br />
                                                Colortec: 90
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Installation </th>
                                            <td>ASI S.T.O.P. Noise Acoustical Adhesive </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 text">
                                <h2>Standard Colors</h2>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/sonex-white2.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Sonexone5} alt="Sonex one5" />
                                </Link>
                                <p>White</p>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/sonex-grey.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Sonexone6} alt="Sonex one6" />
                                </Link>
                                <p>Grey</p>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/sonex-charcoal.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Sonexone7} alt="Sonex one7" />
                                </Link>
                                <p>Charcoal</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 text">
                                <h2>Optional HPC-coated Colors</h2>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/sonex-hpc-black2.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Sonexone8} alt="Sonex one8" />
                                </Link>
                                <p>Black</p>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/sonex-hpc-light-grey2.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Sonexone9} alt="Sonex one9" />
                                </Link>
                                <p>Light Grey</p>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/sonex-hpc-white2.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Sonexone10} alt="Sonex one10" />
                                </Link>
                                <p>White</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/sonex-hpc-almond2.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Sonexone11} alt="Sonex one11" />
                                </Link>
                                <p>Almond</p>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/sonex-hpc-medium-grey2.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Sonexone12} alt="Sonex one12" />
                                </Link>
                                <p>Medium Grey</p>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/sonex-hpc-light-blue2.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Sonexone13} alt="Sonex one13" />
                                </Link>
                                <p>Light Blue</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/sonex-hpc-ivory2.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Sonexone14} alt="Sonex one14" />
                                </Link>
                                <p>Ivory</p>
                            </div>
                        </div>
                        <p><Link className="btn btn-asi" to="reverb-demo">Reverberation Demonstration</Link></p>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="10">SONEX One Sabins Per Baffle Type A B Mountings</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td>Thickness</td>
                                            <td>Finish</td>
                                            <td>Mounting</td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>Avg</td>
                                        </tr>
                                        <tr>
                                            <td>2″</td>
                                            <td>Natural</td>
                                            <td>B</td>
                                            <td>0.11</td>
                                            <td>0.33</td>
                                            <td>0.85</td>
                                            <td>1.05</td>
                                            <td>1.09</td>
                                            <td>1.06</td>
                                            <td className="emphasis">0.85</td>
                                        </tr>
                                        <tr>
                                            <td>2″</td>
                                            <td>HPC-Coated</td>
                                            <td>B</td>
                                            <td>0.13</td>
                                            <td>0.41</td>
                                            <td>1.02</td>
                                            <td>1.18</td>
                                            <td>1.18</td>
                                            <td>1.13</td>
                                            <td className="emphasis">0.95</td>
                                        </tr>
                                        <tr>
                                            <td>2″</td>
                                            <td>Painted</td>
                                            <td>B</td>
                                            <td>0.07</td>
                                            <td>0.37</td>
                                            <td>0.89</td>
                                            <td>1.05</td>
                                            <td>1.04</td>
                                            <td>1.03</td>
                                            <td className="emphasis">0.85</td>
                                        </tr>
                                        <tr>
                                            <td>2″</td>
                                            <td>Colortec</td>
                                            <td>B</td>
                                            <td>0.07</td>
                                            <td>0.26</td>
                                            <td>0.77</td>
                                            <td>1.01</td>
                                            <td>0.99</td>
                                            <td>1.00</td>
                                            <td className="emphasis">0.75</td>
                                        </tr>
                                        <tr>
                                            <td>3″</td>
                                            <td>Natural</td>
                                            <td>A</td>
                                            <td>0.09</td>
                                            <td>0.68</td>
                                            <td>1.20</td>
                                            <td>1.18</td>
                                            <td>1.12</td>
                                            <td>1.05</td>
                                            <td className="emphasis">1.05</td>
                                        </tr>
                                        <tr>
                                            <td>3″</td>
                                            <td>HPC-Coated</td>
                                            <td>A</td>
                                            <td>0.13</td>
                                            <td>0.85</td>
                                            <td>1.25</td>
                                            <td>1.22</td>
                                            <td>1.13</td>
                                            <td>1.14</td>
                                            <td className="emphasis">1.10</td>
                                        </tr>
                                        <tr>
                                            <td>3″</td>
                                            <td>Painted</td>
                                            <td>A</td>
                                            <td>0.15</td>
                                            <td>0.72</td>
                                            <td>1.21</td>
                                            <td>1.20</td>
                                            <td>1.15</td>
                                            <td>1.13</td>
                                            <td className="emphasis">1.05</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default sonex; 