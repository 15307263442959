import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Proaudio1 from '../../images/inner/curve_acoustical_medium_diffusor_vanilla2-200x200.webp';
import Proaudio2 from '../../images/inner/fabric-wrapped-panel-edge-details-lg2-200x200.webp';
import Proaudio3 from '../../images/inner/wallmate-sports-arena1-200x200.webp';
import Proaudio4 from '../../images/inner/dBA-panel2-200x191.webp';
import Proaudio5 from '../../images/inner/soundproof-door-piano-room-st-olaf2-200x200.webp';
import Proaudio6 from '../../images/inner/recording-studio-windows1-200x200.webp';
import Proaudio7 from '../../images/inner/green-glue-group5-200x200.webp';
import Proaudio8 from '../../images/inner/rsic-1-clip-furring-channel3-200x177.webp';
import Proaudio9 from '../../images/inner/studio_consultant.webp';
import ScrollToTopButton from '../ScrollButton';
function proaudio() {
    return (
      
    <>
    <Helmet>
    <title>Pro studio acoustical products create natural sounding and professionally accurate acoustic spaces </title>
    <meta name="description" content=" best in sound blocking, absorbing, and diffusion for perfect sound transmission and recording. " />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Professional Audio Noise Control Products" />
    <meta property="og:description" content="The advancements in digital technology have made it possible for almost anyone to get into the recording business." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/fabric-wrapped-panel-edge-details-lg2-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/proaudio" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/proaudio" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/proaudio" />
    </Helmet>
    <Header />
    <section className="content">
        <div className="container">
            <div className="row">
            <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Professional Audio Noise Control Products</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>The advancements in digital technology have made it possible for almost anyone to get into the recording business. Even with state-of-the-art equipment, the best results are obtained in a professional-quality studio.</p>
                                    <p>Pro studio acoustical products are engineered to create natural sounding and professionally accurate acoustic spaces. As experts in understanding how sound works, our diverse line of acoustical products provide the best in sound blocking, absorbing, and diffusion for perfect sound transmission and recording. Creating pro studio acoustics is easy with flat or curved wall panels, ceiling tiles, diffusers, doors, and windows designed specifically to deliver privacy, soundproofing, and harmonic accuracy. A wide variety of custom colors, graphics, shapes, and sizes make every installation unique, distinctive, high-performing, and cost effective.</p>
                                    <p><Link to="/contact-us">Contact us</Link> today to learn more about our professional acoustic foam and other products!</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>View Our Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Proaudio1} alt="Curve Diffusers" />
                                </Link>
                                <h5>Curve Diffusers</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Proaudio2} alt="Fabric Wrapped Fiberglass Panels" />
                                </Link>
                                <h5>Fabric Wrapped Fiberglass Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Proaudio3} alt="Wallmate Stretch-Wall System" />
                                </Link>
                                <h5>WallMate™ Stretch-Wall system</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Proaudio4} alt="dBa Panels" />
                                </Link>
                                <h5>dBa Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Proaudio5} alt="Studio 3D Doors" />
                                </Link>
                                <h5>Studio 3D™ doors</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Proaudio6} alt="Studio Windows" />
                                </Link>
                                <h5>Studio Windows</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Proaudio7} alt="Green Glue" />
                                </Link>
                                <h5>Green Glue</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Proaudio8} alt="RSIC-1 Clips" />
                                </Link>
                                <h5>RSIC-1 Clips</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Proaudio9} alt="Studio Construction" />
                                </Link>
                                <h5>Studio Construction</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default proaudio; 