import React from 'react';
import ASI from '../acoustical-surfaces.svg';
import './header.css';
function header() {
    return (

        <>
            <div className="container">
                <div className="row">
                    <div className="col-xl-2 col-lg-2 col-sm-2 col-2 logo">
                        <a href="/">
                            <img src={ASI} alt="ASI" />
                        </a>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-sm-8 brand_name">
                        <div className="row">
                            <div className="help">
                                <span className="help-line hl1">Noise Control</span><span className="help-line hl2">Help Line:</span>
                                <span className="phone">
                                    <a href="tel:+91-8277331000">+91-82773 31000</a>
                                    <a href="tel:+91-8025722022">+91-80257 22022</a>
                                    <a href="tel:+91-9449777212">+91-94497 77212</a>
                                </span>
                                <span className="sep">|</span>
                                <span className="india"> INDIA & GCC Region</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="asi">
                                <span className="acoustical">Acoustical</span>
                                <span className="separator d-none">|</span>
                                <span className="surfaces">Surfaces</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="celebrate">
                                <p className="cel">Celebrating Over 40 Years</p>
                                <span className="sep">|</span>
                                <a href="/soundproofing"><p className="acoustics">Soundproofing</p></a>
                                <span className="sep">|</span>
                                <a href="/acoustic-panel"><p className="acoustics">Acoustics</p></a>
                                <span className="sep">|</span>
                                <a href="/noise-problems-guide"><p className="nvc">Noise & Vibration Control</p></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-sm-2 social">
                        <div className="sm d-flex justify-content-center">
                            <a href="https://www.facebook.com/acousticalsurfacesindia" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-facebook" aria-hidden="true"></i>
                            </a>
                            <a href="https://twitter.com/acousticsurface" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-twitter" aria-hidden="true"></i>
                            </a>
                            <a href="https://www.linkedin.com/company/architectural-&-acoustical-solutions?trk=hb_tab_compy_id_2725536" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-linkedin" aria-hidden="true"></i>
                            </a>
                            <a href="https://www.instagram.com/acoustical.surfaces/" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-instagram" aria-hidden="true"></i>
                            </a>
                            <a href="https://wa.me/919449777212" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-whatsapp" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div className="noise-problem d-flex justify-content-center">
                            <a href="/problem">What's Your Noise Problem?</a>
                        </div>
                    </div>
                </div>
                <div className="row asm">
                    <div className="asi-mobile d-block d-sm-none">
                        <span className="acoustical">Acoustical</span>
                        <span className="separator">|</span>
                        <span className="surfaces">Surfaces</span>
                    </div>
                </div>
                <div className="row d-flex content-menu">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav">
                                <li className="nav-item active">
                                    <a href='/'>Home</a>
                                </li>
                                <li className="nav-item active">
                                    <a href='/products'>Products</a>
                                </li>
                                <li className="nav-item">
                                    <a href='/projects'>Projects</a>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Sales & Distributors
                                    </a>
                                    <div className="dropdown-menu p-0" aria-labelledby="navbarDropdownMenuLink">
                                        <a className="dropdown-item" href="/international">International Sales</a>
                                        <a className="dropdown-item" href="/national">National Sales</a>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <a href='/who-we-are'>Who We Are</a>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Resources
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <a className="dropdown-item" href="/soundproofingtips">Soundproofing Tips</a>
                                        <a className="dropdown-item" href="/cad-library-guide">CAD, CSI, Revit Library</a>
                                        <a className="dropdown-item" href="/literature">Literature</a>
                                        <a className="dropdown-item" href="/links">Links</a>
                                        <a className="dropdown-item" href="https://www.acousticalsurfaces.in/pdfs/ASI-PQD-final%20-com.pdf" target="_blank" rel="noopener noreferrer">PQD</a>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <a href="/downloads">Downloads</a>
                                </li>
                                <li className="nav-item">
                                    <a href='/contact-us'>Contact</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    );
}
export default header;