import React from 'react';
import { Link } from 'react-router-dom';
import './slider.css';
import ASIProducts from '../../images/banner-image4.webp';
import ASIProjects from '../../images/banner-image5.webp';
import ASIControl from '../../images/index-b1.webp';
function slider() {
	return (

		<>
			<div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
				<ol className="carousel-indicators">
					<li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
					<li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
					<li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
				</ol>
				<div className="carousel-inner">
					<div className="carousel-item active">
						<img className="d-block w-100" src={ASIProducts} alt="Acoustical Products Manufacturer" />
						<Link to="/" target="_parent" className='cs_title'>
							<div className="carousel-caption d-none d-md-block">
								<h2>Acoustical Products Manufacturer</h2>
								<h6>we manufacture quality acoustical products in technology partnership with acoustical surfaces inc, usa</h6>
							</div>
						</Link>
					</div>
					<div className="carousel-item">
						<img className="d-block w-100" src={ASIProjects} alt="Turnkey Acoustic Projects Contractor" />
						<Link to="/projects" target="_parent" className='cs_title'>
							<div className="carousel-caption d-none d-md-block">
								<h2>Turnkey Acoustic Projects Contractor</h2>
								<h6>we are specialized and experienced in turnkey acoustical projects contracting</h6>
								<h6>( studios, multiplex cinemas, auditoriums , theme parks, corporate offices etc. )</h6>
							</div>
						</Link>
					</div>
					<div className="carousel-item">
						<img className="d-block w-100" src={ASIControl} alt="Acoustical , Soundproofing & Vibration Control" />
						<Link to="/soundproofing" target="_parent" className='cs_title'>
							<div className="carousel-caption d-none d-md-block">
								<h2>Acoustical , Soundproofing & Vibration Control</h2>
								<h6>we are supplier of various types of acoustical, soundproofing and vibration control products</h6>
							</div>
						</Link>
					</div>
				</div>
				<a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
					<span className="carousel-control-prev-icon" aria-hidden="true"></span>
					<span className="sr-only">Previous</span>
				</a>
				<a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
					<span className="carousel-control-next-icon" aria-hidden="true"></span>
					<span className="sr-only">Next</span>
				</a>
			</div>

			<script>
				{`
                document.addEventListener("DOMContentLoaded", function() {
                    const carouselInterval = 1000; // Adjust the interval as needed (in milliseconds)
                    const carousel = document.querySelector('#carouselExampleIndicators');
                    const carouselInstance = new bootstrap.Carousel(carousel, { interval: carouselInterval });
                });
                `}
			</script>

		</>
	);
}
export default slider;

