import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Cc1 from '../../images/acfabric/cc1.jpg';
import Cc2 from '../../images/acfabric/cc2.jpg';
import Cc3 from '../../images/acfabric/cc3.jpg';
import Cc4 from '../../images/acfabric/cc4.jpg';
import Cc5 from '../../images/acfabric/cc5.jpg';
import Cc6 from '../../images/acfabric/cc6.jpg';
import Cc7 from '../../images/acfabric/cc7.jpg';
import Cc8 from '../../images/acfabric/cc8.jpg';
import Cc9 from '../../images/acfabric/cc9.jpg';
import './acfabric-cape.css';
import ScrollToTopButton from '../ScrollButton';
function acfabriccape() {
    return (
      
    <>
    <Helmet>
    <title>Acoustic of Maine Cape Cod Fabric - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="AcoustyFABRIC Cape Cod" />
    <meta property="og:description" content="Guilford of Maine Cape Cod 3073 Fabric Facings" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/acfabric/cc1.jpg" />
    <meta property="og:locale" content="en-in" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/acfabric-cape" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/acfabric-cape" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acfabric-cape" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="acfabriccape">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
            	<div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
	                <div className="col-sm-12 heading-fsorb">				
		                <h1>Acousty<span className="fabriwall">FABRIC</span> Cape Cod </h1>
                		<h4 className="mb-0"> <b>Guilford of Maine Cape Cod 3073 Fabric Facings</b></h4>
		                <p><span className="color:red;">(Colors Shown are Approximate Match to Material)</span></p>
	                    <br />
		                <div className="col-sm-3 cloths p-0"><button>Product Specifications</button></div>
	                </div>
		            <br /><br />
		            <div className="col-sm-12 cloths">
		                <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Cc1} alt="Apricot 7010" />
                                <p className="text-center">Apricot 7010</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Cc2} alt="Buff 7011" />
                                <p className="text-center">Buff 7011</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Cc3} alt="Cloud 7030" />
                                <p className="text-center">Cloud 7030</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Cc4} alt="Linen 7031" />
                                <p className="text-center">Linen 7031</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Cc5} alt="Dune 7040" />
                                <p className="text-center">Dune 7040</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Cc6} alt="Shale 7042" />
                                <p className="text-center">Shale 7042</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Cc7} alt="Key Lime 7050" />
                                <p className="text-center">Key Lime 7050</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Cc8} alt="Surf 7062" />
                                <p className="text-center">Surf 7062</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Cc9} alt="Seagull 7072" />
                                <p className="text-center">Seagull 7072</p>
                            </div>
                            <div className=" col-sm-2 p-0 mx-2"></div>
                        </div>	
            		</div>
		            <br />
		            <hr />
                    <div className="col-12 d-flex justify-content-center backbtn">
                    	<Link to="acoustyfabric"><button>Back to Acousty<span style={{color: "red"}}>FABRIC</span></button></Link>
                    </div>
                    <hr />
            		<div className="col-sm-12 application">
            			<h1>APPLICATION</h1>
	                    <div className="hover14 text-center justify-content-center">
		                <br />
  		                    <div className="col-sm-12">
			                    <figure className="m-0"><div className="tinted-image">CINEMA HALL</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image2">OFFICE ROOMS & HALLS</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image3">AUDITORIUM & EDUCATION</div></figure>
 		                    </div>
                        <br />
	                    </div>
                    </div>
		            <br />
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acfabriccape;