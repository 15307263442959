import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Rsicext1 from '../../images/inner/rsic1_ext04.webp';
import Rsicext2 from '../../images/inner/detail-1-200x200.webp';
import Rsicext3 from '../../images/inner/detail-2-200x200.webp';
import Rsicext4 from '../../images/inner/detail-3-200x200.webp';
import './rsic-1ext04.css';
import ScrollToTopButton from '../ScrollButton';
function rsic1ext() {
    return (

        <>
            <Helmet>
                <title>Resilient Sound Isolation Clips-2 Retaining The Acoustics </title>
                <meta name="description" content="" />
                <meta name="robots" content="index, follow" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="in" />
                <meta name="geo.region" content="india" />
                <meta property="og:title" content="Resilient Sound Isolation Clips" />
                <meta property="og:description" content="The SC102 is designed for use where an additional drop for HVAC, Plumbing, or Electrical Chases is needed. " />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/rsic1_ext04.webp" />
                <meta property="og:locale" content="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.ae/rsic-1ext04" hreflang="en-ae" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/rsic-1ext04" hreflang="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/rsic-1ext04" />
            </Helmet>
            <Header />
            <section className="content" id="rsicext">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 sidemenu">
                            <Sidebar />
                        </div>
                        <div className="col-sm-9 product-page nopaddding">
                            <div className="container thanks">
                                <div className="megamenu">
                                    <Megamenu />
                                </div>
                                <h1>SC102</h1>
                                <h3>Resilient Sound Isolation Clips</h3>
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-sm-12">
                                        <Link className="example-image-link" href="../../images/inner/rsic1_ext04.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Rsicext1} alt="Rsic ext" />
                                        </Link>
                                    </div>
                                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 text">
                                        <img src="images/inner/Soundproofing.gif" alt="" />
                                        <h2>SC102 Resilient Sound Isolation Clip</h2>
                                        <p>The SC102 is designed for use where an additional drop for HVAC, Plumbing, or Electrical Chases is needed. This clip gives you the flexibility to drop a ceiling as much as 4″ from its original height. The SC102 i s also used to level out uneven floor joists. This clip has proven to be very popular with the Home Theater industry. It creates a dropped ceiling, allowing an open chase for the new wires, all while retaining the superior acoustical performance of the SC102 clips.</p>
                                    </div>
                                    <div className="line"></div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <Link className="example-image-link" to="../../images/inner/detail-1-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Rsicext2} alt="Rsic ext2" />
                                        </Link>
                                    </div>
                                    <div className="col-sm">
                                        <Link className="example-image-link" to="../../images/inner/detail-2-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Rsicext3} alt="Rsic ext3" />
                                        </Link>
                                    </div>
                                    <div className="col-sm">
                                        <Link className="example-image-link" to="../../images/inneder/detail-3-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Rsicext4} alt="Rsic ext4" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 text">
                                        <h3>Dimensions:</h3>
                                        <ul style={{ liststyle: "none" }}>
                                            <li>
                                                <Link target="_blank" to="../../images/inner/demensions.webp" style={{ color: "#0059a5" }} >View Clip Dimensions</Link>
                                            </li>
                                        </ul>
                                        <h3>Framing:</h3>
                                        <ol style={{ marginleft: "1em", fontsize: ".9em" }}>
                                            <li>
                                                <Link target="_blank" style={{ color: "#0059a5" }} to="../../images/inner/frame-1.webp">SC102 Ceiling System Framing at 24″ O.C. SC102 Clips at 24″ × 48″ O.C. 1 or 2 Layers of 5/8″ Gypsum Board</Link>
                                            </li>
                                            <li>
                                                <Link target="_blank" style={{ color: "#0059a5" }} to="../../images/inner/frame-2.webp">SC102 Ceiling System Framing at 16″ O.C. SC102 Clips at 24″ × 48″ O.C. 1 or 2 Layers of 5/8″ Gypsum Board</Link>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mobsidebar'>
                        <div className="col-sm-12">
                            <div className="container">
                                <div className="row">
                                    <Mobsidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default rsic1ext; 