import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import './fabric-wrapped-fiberglass-clouds.css';
import Mobsidebar from '../mobile-sidebar';
import Clouds1 from '../../images/inner/cloud_1.jpg';
import Clouds2 from '../../images/inner/cloud_3.jpg';
import Clouds3 from '../../images/inner/cloud_4.jpg';
import ScrollToTopButton from '../ScrollButton';
function fiberglasscloud() {
    return (
      
    <>
    <Helmet>
    <meta name="description" content="" />
    <title>Fabrisorb - Decorative Fabric Wrapped Acoustical Ceiling Clouds</title>
    <meta property="og:title" content="Decorative Fabric Wrapped Acoustical Ceiling Clouds" />
    <meta property="og:description" content="CUSTOM Ceiling Clouds Reduce reflective Sounds in large open areas. Suspended horizontally from the ceiling. " />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/cloud_1.jpg" />
    <meta property="og:locale" content="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/fabric-wrapped-fiberglass-clouds" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/fabric-wrapped-fiberglass-clouds" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/fabric-wrapped-fiberglass-clouds" hreflang="en-ae" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="IN" />
    <meta name="geo.region" content="india" />
    </Helmet>
    <Header />
    <section className="content" id="Fiberglassclouds">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Introducing the First Tri-Functional Acoustical Panel</h1>
                        <div className="row">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/cloud_1.jpg" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Clouds1} alt="Fiberglass Clouds 1" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Decorative Fabric Wrapped Acoustical Ceiling Clouds</h2>
                                <ul style={{marginleft: "1em"}}>                       
                                    <li>High Performance</li>
                                    <li>Architecturally Decorative</li>
                                    <li>Custom Engineered & Manufactured</li>                                        
                                </ul>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/ceiling_clouds.pdf" target="_blank">Product Specs</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                            <div className="col-sm-12">
                                <p><strong>CUSTOM Ceiling Clouds</strong> Reduce reflective Sounds in large open areas. Suspended horizontally from the ceiling. S.T.O.P. Ceiling Clouds effectively reduce reflected sound in large areas such as shopping malls, convention centers, theaters, and arenas. Custom sizes and shapes are available. Large sized clouds reinforced with tee bar stiffener/hangers.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <tbody>
                                        <tr className="colheader">
                                            <td colSpan="2"><strong>CONSTRUCTION</strong></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Link to="../../images/inner/cloud_3.jpg" data-lightbox="example-1">
                                                    <img src={Clouds2} alt="Fiberglass Clouds 2" />
                                                </Link>
                                            </td>
                                            <td>Built for maximum sound reduction, glass fiber cores with faces and edges wrapped in fabric or perforated vinyl are available in<br />
                                                <strong>Standard:</strong> 1″ 2″ thickness<br />
                                                <strong>Optional:</strong> ½″, ¾″, 1 ½″ (minimum quantities may apply)
                                            </td>
                                        </tr>
                                        <tr className="colheader">
                                            <td colSpan="2"><strong>MOUNTING OPTIONS</strong></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Link to="../../images/inner/cloud_4.jpg" data-lightbox="example-1">
                                                    <img src={Clouds3} alt="Fiberglass Clouds 3" />
                                                </Link>
                                            </td>
                                            <td>Easy to install, the Ceiling Cloud is suspended horizontally with a clip/eyelet combination.</td>
                                        </tr>
                                        <tr>
                                            <td style={{textalign: "left"}} colSpan="2"><strong>Ceiling Clouds have the same NRC rating as Ceiling Panels.</strong></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default fiberglasscloud; 