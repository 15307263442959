import React from 'react';
import Home from './pages/home/home';
import Acousticpanel from './pages/acoustic-panel/acoustic-panel';
import Noiseproblemsguide from './pages/noise-problems-guide/noise-problems-guide';
import Projects from './pages/projects/projects';
import International from './pages/international/international';
import National from './pages/national/national';
import Whoweare from './pages/who-we-are/who-we-are';
import Cadlibraryguide from './pages/cad-library-guide/cad-library-guide';
import Contactus from './pages/contact-us/contact-us';
import Fabrisorb from './pages/fabrisorb/fabrisorb';
import Fabriwall from './pages/fabriwall/fabriwall';
import Decopanel from './pages/decopanel/decopanel';
import Woodtec from './pages/woodtec/woodtec';
import Woodwool from './pages/woodwool/woodwool';
import Foamstoppu from './pages/foamstop-pu/foamstop-pu';
import Foamstopmm from './pages/foamstop-mm/foamstop-mm';
import Polymaxpolyester from './pages/polymax-polyester/polymax-polyester';
import Polysorb from './pages/polysorb/polysorb';
import Acousticalstretchfabric from './pages/acoustical-stretch-fabric/acoustical-stretch-fabric';
import Soundproofinteriordoors from './pages/soundproof-interior-doors/soundproof-interior-doors';
import Ductsound from './pages/ductsound/ductsound';
import Acoustimetal from './pages/acoustimetal/acoustimetal';
import Squarelineceilingtiles from './pages/squareline-ceiling-tiles/squareline-ceiling-tiles';
import Echoeliminator from './pages/echo-eliminator/echo-eliminator';
import Silkmetalceilingtiles from './pages/silk-metal-ceiling-tiles/silk-metal-ceiling-tiles';
import Soundsilencer from './pages/sound-silencer/sound-silencer';
import Massloadedvinyl from './pages/mass-loaded-vinyl/mass-loaded-vinyl';
import Soundbreakxp from './pages/soundbreak-xp/soundbreak-xp';
import Absorptivequiltedcurtains from './pages/absorptive-quilted-curtains/absorptive-quilted-curtains';
import Acousticfoam from './pages/acoustic-foam/acoustic-foam';
import Acousticsunderlayment from './pages/acoustics-underlayment/acoustics-underlayment';
import Cinemaport from './pages/cinema-port/cinema-port';
import Adjustabledoorsealkits from './pages/adjustable-door-seal-kits/adjustable-door-seal-kits';
import Barrierdecoupler from './pages/barrier-decoupler/barrier-decoupler';
import Barrieractceilingtiles from './pages/barrier-act-ceiling-tiles/barrier-act-ceiling-tiles';
import Cellulosepanels from './pages/cellulose-panels/cellulose-panels';
import Climatesealwindowinserts from './pages/climate-seal-window-inserts/climate-seal-window-inserts';
import Cottonsoundwaveeggcratepanels from './pages/cotton-soundwave-eggcrate-panels/cotton-soundwave-eggcrate-panels';
import Curvepanels from './pages/curve-panels/curve-panels';
import Dbapanels from './pages/dba-panels/dba-panels';
import Duracoustic from './pages/duracoustic/duracoustic';
import Hangingacousticbaffles from './pages/hanging-acoustic-baffles/hanging-acoustic-baffles';
import Soundblankets from './pages/sound-blankets/sound-blankets';
import Glasswoolceiling from './pages/glasswoolceiling/glasswoolceiling';
import Greenglue from './pages/green-glue/green-glue';
import Melaminecompositepanels from './pages/melamine-composite-panels/melamine-composite-panels';
import Paintednubbyfiberglasstiles from './pages/painted-nubby-fiberglass-tiles/painted-nubby-fiberglass-tiles';
import Pvcsailclothbaffles from './pages/pvc-sailcloth-baffles/pvc-sailcloth-baffles';
import Pyramiddiffuser from './pages/pyramiddiffuser/pyramiddiffuser';
import Quietfloornp from './pages/quiet-floor-np/quiet-floor-np';
import Quietfloorwalk from './pages/quiet-floor-walk/quiet-floor-walk';
import Quiltedexteriorabsorbers from './pages/quilted-exterior-absorbers/quilted-exterior-absorbers';
import Rsic1clips from './pages/rsic-1-clips/rsic-1-clips';
import Rsic1ext04 from './pages/rsic-1ext04/rsic-1ext04';
import Rubberisolationhangers from './pages/rubber-isolation-hangers/rubber-isolation-hangers';
import Sonexone from './pages/sonex-one/sonex-one';
import Soundsilencerbaffles from './pages/sound-silencer-baffles/sound-silencer-baffles';
import Soundsilencerceilingtiles from './pages/sound-silencer-ceiling-tiles/sound-silencer-ceiling-tiles';
import Springisolationhangers from './pages/spring-isolation-hangers/spring-isolation-hangers';
import Springisolationmounts from './pages/spring-isolation-mounts/spring-isolation-mounts';
import Studisolators from './pages/stud-isolators/stud-isolators';
import Studiowindows from './pages/studio-windows/studio-windows';
import Superwpads from './pages/super-w-pads/super-w-pads';
import Ultratouchinsulation from './pages/ultra-touch-insulation/ultra-touch-insulation';
import Whisperwaveclouds from './pages/whisper-wave-clouds/whisper-wave-clouds';
import Absorbingecho from './pages/absorbing-echo/absorbing-echo';
import Soundblockingproduct from './pages/sound-blocking-product/sound-blocking-product';
import Soundproofingcorrectionalfacilities from './pages/soundproofing-correctional-facilities/soundproofing-correctional-facilities';
import Soundproofingclassrooms from './pages/soundproofing-classrooms/soundproofing-classrooms';
import Entertainmentsoundproofingproducts from './pages/entertainment-soundproofing-products/entertainment-soundproofing-products';
import Genbldg from './pages/gen-bldg/gen-bldg';
import Greenbldg from './pages/green-bldg/green-bldg';
import Healthcare from './pages/healthcare/healthcare';
import Hotelconvention from './pages/hotel-convention/hotel-convention';
import Houseofworship from './pages/houseofworship/houseofworship';
import Mfgindustrial from './pages/mfg-industrial/mfg-industrial';
import Multiunitresi from './pages/multiunit-resi/multiunit-resi';
import Officeenviro from './pages/office-enviro/office-enviro';
import Spstoresretail from './pages/sp-stores-retail/sp-stores-retail';
import Sportsvenues from './pages/sports-venues/sports-venues';
import Studioconstruction from './pages/studio-construction/studio-construction';
import Transportation from './pages/transportation/transportation';
import Soundproofingceilingtiles from './pages/soundproofing-ceiling-tiles/soundproofing-ceiling-tiles';
import Soundproofingcloudsandbaffles from './pages/soundproofing-clouds-and-baffles/soundproofing-clouds-and-baffles';
import Soundproofingconstructionproducts from './pages/soundproofing-construction-products/soundproofing-construction-products';
import Soundproofingcurtains from './pages/soundproofing-curtains/soundproofing-curtains';
import Soundproofingdecorative from './pages/soundproofing-decorative/soundproofing-decorative';
import Soundproofdoors from './pages/soundproof-doors/soundproof-doors';
import Soundcontrolelectronics from './pages/sound-control-electronics/sound-control-electronics';
import Exteriornoisecontrol from './pages/exterior-noise-control/exterior-noise-control';
import Floorsoundproofing from './pages/floor-soundproofing/floor-soundproofing';
import Hardware from './pages/hardware/hardware';
import Hvac from './pages/hvac/hvac';
import Insulation from './pages/insulation/insulation';
import Metalpanels from './pages/metalpanels/metalpanels';
import Proaudio from './pages/proaudio/proaudio';
import Sealantsadhesive from './pages/sealants-adhesive/sealants-adhesive';
import Vibrationisolation from './pages/vibration-isolation/vibration-isolation';
import Windows from './pages/windows/windows';
import Teleperformance from './pages/acoustic-projects/project-teleperformance';
import Bosch from './pages/acoustic-projects/project-bosch';
import Twitter from './pages/acoustic-projects/project-twitter';
import Zoho from './pages/acoustic-projects/project-zoho';
import Generalmotors from './pages/acoustic-projects/project-general-motors';
import Wipro from './pages/acoustic-projects/project-wipro';
import Goldmansachs from './pages/acoustic-projects/project-goldman-sachs';
import DRCcinemas from './pages/acoustic-projects/project-drc-cinemas';
import Navelbaseacademy from './pages/acoustic-projects/project-navel-base-academy';
import Kodaikanalinternationalschool from './pages/acoustic-projects/project-kodaikanal-international-school';
import Christianmedicalcollege from './pages/acoustic-projects/project-christian-medical-college';
import Hospirahealthcare from './pages/acoustic-projects/project-hospira-healthcare';
import Hometheaterproject from './pages/acoustic-projects/home-theater-project';
import Flocert from './pages/acoustic-projects/project-flocert';
import Amaryllishealthcare from './pages/acoustic-projects/project-amaryllis-healthcare';
import Ferrariworld from './pages/acoustic-projects/project-ferrari-world';
import Museumoffuturedubai from './pages/acoustic-projects/project-the-museum-of-future-dubai';
import Presidentialpalace from './pages/acoustic-projects/project-presidential-palace';
import Louvre from './pages/acoustic-projects/project-louvre';
import EIBFS from './pages/acoustic-projects/project-EIBFS';
import Kapsarc from './pages/acoustic-projects/project-kapsarc';
import Delhipublicschool from './pages/acoustic-projects/project-delhi-public-school';
import Privpolicy from './pages/priv-policy/priv-policy';
import Search from './pages/search/search';
import Projects2 from './pages/projects-2/projects-2';
import Projects3 from './pages/projects-3/projects-3';
import Projects4 from './pages/projects-4/projects-4';
import Projects5 from './pages/projects-5/projects-5';
import Bsroomsguide from './pages/bs-rooms-guide/bs-rooms-guide';
import Wallmate from './pages/wallmate/wallmate';
import Acousticalsealant from './pages/acoustical-sealant/acoustical-sealant';
import Acousticalsealantgreen from './pages/acoustical-sealant-green/acoustical-sealant-green';
import Acoustics101 from './pages/acoustics101/acoustics101';
import Fabdownloads from './pages/fab-downloads/fab-downloads';
import FabriwallTrack from './pages/fabriwallTrack/fabriwallTrack';
import Fabriwallfabric from './pages/fabriwallfabric/fabriwallfabric';
import Fabriwallpanel from './pages/fabriwallpanel/fabriwallpanel';
import Petpanel from './pages/pet-panel/pet-panel';
import Woodwoolwall from './pages/woodwool-wall/woodwool-wall';
import Woodwoolceiling from './pages/woodwool-ceiling/woodwool-ceiling';
import Woodwoolcloud from './pages/woodwool-cloud/woodwool-cloud';
import Acoustyfabricusa from './pages/acoustyfabric-usa/acoustyfabric-usa';
import Reverbdemo from './pages/reverb-demo/reverb-demo';
import Soundproofingfixes1 from './pages/soundproofing-fixes1/soundproofing-fixes1';
import Soundproofingfixes2 from './pages/soundproofing-fixes2/soundproofing-fixes2';
import Woodwoolboard from './pages/woodwool-board/woodwool-board';
import Foamstop from './pages/foamstop/foamstop';
import Polywadding from './pages/polywadding/polywadding';
import Polyesteracoustipanels from './pages/polyester-acoustipanels/polyester-acoustipanels';
import Polyurethanefoam from './pages/polyurethanefoam/polyurethanefoam';
import Polysorbwallpanel from './pages/polysorb-wallpanel/polysorb-wallpanel';
import Fabricwrappedfiberglass from './pages/fabric-wrapped-fiber-glass/fabric-wrapped-fiber-glass';
import Fabricwrappedfiberglassbaffles from './pages/fabric-wrapped-fiberglass-baffles/fabric-wrapped-fiberglass-baffles';
import Fabricwrappedfiberglassclouds from './pages/fabric-wrapped-fiberglass-clouds/fabric-wrapped-fiberglass-clouds';
import Melamineflatfacedfoam from './pages/melamine-flat-faced-foam/melamine-flat-faced-foam';
import Melaminelinearwedgepanels from './pages/melamine-linear-wedge-panels/melamine-linear-wedge-panels';
import Melaminepyramidpanels from './pages/melamine-pyramid-panels/melamine-pyramid-panels';
import Melaminesoundwavepanels from './pages/melamine-sound-wave-panels/melamine-sound-wave-panels';
import Polymax from './pages/polymax/polymax';
import Acousticalfoameggcrate from './pages/acousticalfoam-eggcrate/acousticalfoam-eggcrate';
import Cataloguedownload from './pages/catalogue-download/catalogue-download';
import Glossaryterms from './pages/glossary-terms/glossary-terms';
import Soundproofinstallation from './pages/soundproof-installation/soundproof-installation';
import Reverberation from './pages/reverberation/reverberation';
import Acfabricanchorage from './pages/acfabric-anchorage/acfabric-anchorage';
import Acfabricbeehave from './pages/acfabric-beehave/acfabric-beehave';
import Acfabricbroadcast from './pages/acfabric-broadcast/acfabric-broadcast';
import Acfabriccape from './pages/acfabric-cape/acfabric-cape';
import Acfabriccrosstown from './pages/acfabric-crosstown/acfabric-crosstown';
import Acfabricdrift from './pages/acfabric-drift/acfabric-drift';
import Acfabricfr701 from './pages/acfabric-fr701/acfabric-fr701';
import Acfabricfr702 from './pages/acfabric-fr702/acfabric-fr702';
import Acfabricfr703 from './pages/acfabric-fr703/acfabric-fr703';
import Acfabricframework from './pages/acfabric-framework/acfabric-framework';
import Acfabrichighbeams from './pages/acfabric-highbeams/acfabric-highbeams';
import Acfabricintermix from './pages/acfabric-intermix/acfabric-intermix';
import Acfabricintution from './pages/acfabric-intution/acfabric-intution';
import Acfabricjane from './pages/acfabric-jane/acfabric-jane';
import Acfabriclido from './pages/acfabric-lido/acfabric-lido';
import Acfabricnetwork from './pages/acfabric-network/acfabric-network';
import Acfabricquadrille from './pages/acfabric-quadrille/acfabric-quadrille';
import Acfabricspinel from './pages/acfabric-spinel/acfabric-spinel';
import Acfabricsteadfast from './pages/acfabric-steadfast/acfabric-steadfast';
import Acfabricstreetwise from './pages/acfabric-streetwise/acfabric-streetwise';
import Acfabricstudio from './pages/acfabric-studio/acfabric-studio';
import Soundproofingfabrisorb from './pages/soundproofing-fabrisorb/soundproofing-fabrisorb';
import Acoustics from './pages/acoustics/acoustics';
import Soundproofingtips from './pages/soundproofingtips/soundproofingtips';
import Soundproofinginsulation from './pages/soundproofing-insulation/soundproofing-insulation';
import Literature from './pages/literature/literature';
import Links from './pages/links/links';
import Terms from './pages/terms/terms';
import Problem from './pages/problem/problem';
import Downloads from './pages/downloads/downloads';
import Soundproofing from './pages/soundproofing/soundproofing';
import Saudi from './pages/saudi-arabia/saudi-arabia';
import Bahrain from './pages/bahrain/bahrain';
import Kuwait from './pages/kuwait/kuwait';
import Oman from './pages/oman/oman';
import Qatar from './pages/qatar/qatar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/scroll-to-top/scroll-to-top';
import India from './pages/india/India';
import Products from './pages/products/Products';
import ProjectgcitBhutan from './pages/acoustic-projects/project-gcit-bhutan';
import ProjectEpam from './pages/acoustic-projects/project-epam';
import ProjectDlf from './pages/acoustic-projects/project-dlf';
import ProjectKalaignarLibrary from './pages/acoustic-projects/project-kalaignar-library';
import ProjectIpop from './pages/acoustic-projects/project-ipop';
import ProjectMyntra from './pages/acoustic-projects/project-myntra';
import ProjectBiocon from './pages/acoustic-projects/project-biocon';
import ProjectRio from './pages/acoustic-projects/project-rio';
import ProjectHdfc from './pages/acoustic-projects/project-hdfc';
import Foamwood from './pages/foamwood/Foamwood';
import Decowood from './pages/decowood/Decowood';
import Polywood from './pages/polywood/Polywood';
import PolysorbDeco from './pages/polysorb-deco/PolysorbDeco';
import FabrisorbDeco from './pages/fabrisorb-deco/FabrisorbDeco';
import PolymaxCeilingTiles from './pages/polymax-ceiling-tiles/PolymaxCeilingTiles';
import Deffuso from './pages/deffuso/Deffuso';
function App() {
  return (
    <div className='App'>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/downloads' element={<Downloads />} />
            <Route exact path='/acoustic-panel' element={<Acousticpanel />} />
            <Route exact path='/noise-problems-guide' element={<Noiseproblemsguide />} />
            <Route exact path='/projects' element={<Projects />} />
            <Route exact path='/international' element={<International />} />
            <Route exact path='/national' element={<National />} />
            <Route exact path='/who-we-are' element={<Whoweare />} />
            <Route exact path='/cad-library-guide' element={<Cadlibraryguide />} />
            <Route exact path='/contact-us' element={<Contactus />} />
            <Route exact path='/fabrisorb' element={<Fabrisorb />} />
            <Route exact path='/fabriwall' element={<Fabriwall />} />
            <Route exact path='/decopanel' element={<Decopanel />} />
            <Route exact path='/woodtec' element={<Woodtec />} />
            <Route exact path='/Woodwool' element={<Woodwool />} />
            <Route exact path='/foamstop-pu' element={<Foamstoppu />} />
            <Route exact path='/foamstop-mm' element={<Foamstopmm />} />
            <Route exact path='/polymax-polyester' element={<Polymaxpolyester />} />
            <Route exact path='/polysorb' element={<Polysorb />} />
            <Route exact path='/acoustical-stretch-fabric' element={<Acousticalstretchfabric />} />
            <Route exact path='/soundproof-interior-doors' element={<Soundproofinteriordoors />} />
            <Route exact path='/ductsound' element={<Ductsound />} />
            <Route exact path='/acoustimetal' element={<Acoustimetal />} />
            <Route exact path='/squareline-ceiling-tiles' element={<Squarelineceilingtiles />} />
            <Route exact path='/echo-eliminator' element={<Echoeliminator />} />
            <Route exact path='/silk-metal-ceiling-tiles' element={<Silkmetalceilingtiles />} />
            <Route exact path='/sound-silencer' element={<Soundsilencer />} />
            <Route exact path='/mass-loaded-vinyl' element={<Massloadedvinyl />} />
            <Route exact path='/soundbreak-xp' element={<Soundbreakxp />} />
            <Route exact path='/absorptive-quilted-curtains' element={<Absorptivequiltedcurtains />} />
            <Route exact path='/acoustic-foam' element={<Acousticfoam />} />
            <Route exact path='/acoustics-underlayment' element={<Acousticsunderlayment />} />
            <Route exact path='/cinema-port' element={<Cinemaport />} />
            <Route exact path='/adjustable-door-seal-kits' element={<Adjustabledoorsealkits />} />
            <Route exact path='/barrier-decoupler' element={<Barrierdecoupler />} />
            <Route exact path='/barrier-act-ceiling-tiles' element={<Barrieractceilingtiles />} />
            <Route exact path='/cellulose-panels' element={<Cellulosepanels />} />
            <Route exact path='/climate-seal-window-inserts' element={<Climatesealwindowinserts />} />
            <Route exact path='/cotton-soundwave-eggcrate-panels' element={<Cottonsoundwaveeggcratepanels />} />
            <Route exact path='/curve-panels' element={<Curvepanels />} />
            <Route exact path='/dba-panels' element={<Dbapanels />} />
            <Route exact path='/duracoustic' element={<Duracoustic />} />
            <Route exact path='/hanging-acoustic-baffles' element={<Hangingacousticbaffles />} />
            <Route exact path='/sound-blankets' element={<Soundblankets />} />
            <Route exact path='/glasswoolceiling' element={<Glasswoolceiling />} />
            <Route exact path='/green-glue' element={<Greenglue />} />
            <Route exact path='/melamine-composite-panels' element={<Melaminecompositepanels />} />
            <Route exact path='/painted-nubby-fiberglass-tiles' element={<Paintednubbyfiberglasstiles />} />
            <Route exact path='/pvc-sailcloth-baffles' element={<Pvcsailclothbaffles />} />
            <Route exact path='/pyramiddiffuser' element={<Pyramiddiffuser />} />
            <Route exact path='/quiet-floor-np' element={<Quietfloornp />} />
            <Route exact path='/quiet-floor-walk' element={<Quietfloorwalk />} />
            <Route exact path='/quilted-exterior-absorbers' element={<Quiltedexteriorabsorbers />} />
            <Route exact path='/rsic-1-clips' element={<Rsic1clips />} />
            <Route exact path='/rsic-1ext04' element={<Rsic1ext04 />} />
            <Route exact path='/rubber-isolation-hangers' element={<Rubberisolationhangers />} />
            <Route exact path='/sonex-one' element={<Sonexone />} />
            <Route exact path='/sound-silencer-baffles' element={<Soundsilencerbaffles />} />
            <Route exact path='/sound-silencer-ceiling-tiles' element={<Soundsilencerceilingtiles />} />
            <Route exact path='/spring-isolation-hangers' element={<Springisolationhangers />} />
            <Route exact path='/spring-isolation-mounts' element={<Springisolationmounts />} />
            <Route exact path='/stud-isolators' element={<Studisolators />} />
            <Route exact path='/studio-windows' element={<Studiowindows />} />
            <Route exact path='/super-w-pads' element={<Superwpads />} />
            <Route exact path='/ultra-touch-insulation' element={<Ultratouchinsulation />} />
            <Route exact path='/whisper-wave-clouds' element={<Whisperwaveclouds />} />
            <Route exact path='/absorbing-echo' element={<Absorbingecho />} />
            <Route exact path='/sound-blocking-product' element={<Soundblockingproduct />} />
            <Route exact path='/soundproofing-correctional-facilities' element={<Soundproofingcorrectionalfacilities />} />
            <Route exact path='/soundproofing-classrooms' element={<Soundproofingclassrooms />} />
            <Route exact path='/entertainment-soundproofing-products' element={<Entertainmentsoundproofingproducts />} />
            <Route exact path='/gen-bldg' element={<Genbldg />} />
            <Route exact path='/green-bldg' element={<Greenbldg />} />
            <Route exact path='/healthcare' element={<Healthcare />} />
            <Route exact path='/hotel-convention' element={<Hotelconvention />} />
            <Route exact path='/houseofworship' element={<Houseofworship />} />
            <Route exact path='/mfg-industrial' element={<Mfgindustrial />} />
            <Route exact path='/multiunit-resi' element={<Multiunitresi />} />
            <Route exact path='/office-enviro' element={<Officeenviro />} />
            <Route exact path='/sp-stores-retail' element={<Spstoresretail />} />
            <Route exact path='/sports-venues' element={<Sportsvenues />} />
            <Route exact path='/studio-construction' element={<Studioconstruction />} />
            <Route exact path='/transportation' element={<Transportation />} />
            <Route exact path='/soundproofing-ceiling-tiles' element={<Soundproofingceilingtiles />} />
            <Route exact path='/soundproofing-clouds-and-baffles' element={<Soundproofingcloudsandbaffles />} />
            <Route exact path='/soundproofing-construction-products' element={<Soundproofingconstructionproducts />} />
            <Route exact path='/soundproofing-curtains' element={<Soundproofingcurtains />} />
            <Route exact path='/soundproofing-decorative' element={<Soundproofingdecorative />} />
            <Route exact path='/soundproof-doors' element={<Soundproofdoors />} />
            <Route exact path='/sound-control-electronics' element={<Soundcontrolelectronics />} />
            <Route exact path='/exterior-noise-control' element={<Exteriornoisecontrol />} />
            <Route exact path='/floor-soundproofing' element={<Floorsoundproofing />} />
            <Route exact path='/hardware' element={<Hardware />} />
            <Route exact path='/hvac' element={<Hvac />} />
            <Route exact path='/insulation' element={<Insulation />} />
            <Route exact path='/metalpanels' element={<Metalpanels />} />
            <Route exact path='/proaudio' element={<Proaudio />} />
            <Route exact path='/sealants-adhesive' element={<Sealantsadhesive />} />
            <Route exact path='/vibration-isolation' element={<Vibrationisolation />} />
            <Route exact path='/windows' element={<Windows />} />
            <Route exact path='/project-teleperformance' element={<Teleperformance />} />
            <Route exact path='/project-bosch' element={<Bosch />} />
            <Route exact path='/project-twitter' element={<Twitter />} />
            <Route exact path='/project-zoho' element={<Zoho />} />
            <Route exact path='/project-general-motors' element={<Generalmotors />} />
            <Route exact path='/project-wipro' element={<Wipro />} />
            <Route exact path='/project-goldman-sachs' element={<Goldmansachs />} />
            <Route exact path='/project-drc-cinemas' element={<DRCcinemas />} />
            <Route exact path='/project-navel-base-academy' element={<Navelbaseacademy />} />
            <Route exact path='/project-kodaikanal-international-school' element={<Kodaikanalinternationalschool />} />
            <Route exact path='/project-christian-medical-college' element={<Christianmedicalcollege />} />
            <Route exact path='/project-hospira-healthcare' element={<Hospirahealthcare />} />
            <Route exact path='/home-theater-project' element={<Hometheaterproject />} />
            <Route exact path='/project-flocert' element={<Flocert />} />
            <Route exact path='/literature' element={<Literature />} />
            <Route exact path='/links' element={<Links />} />
            <Route exact path='/project-amaryllis-healthcare' element={<Amaryllishealthcare />} />
            <Route exact path='/project-ferrari-world' element={<Ferrariworld />} />
            <Route exact path='/project-the-museum-of-future-dubai' element={<Museumoffuturedubai />} />
            <Route exact path='/project-presidential-palace' element={<Presidentialpalace />} />
            <Route exact path='/project-louvre' element={<Louvre />} />
            <Route exact path='/project-EIBFS' element={<EIBFS />} />
            <Route exact path='/project-kapsarc' element={<Kapsarc />} />
            <Route exact path='/project-delhi-public-school' element={<Delhipublicschool />} />
            <Route exact path='/priv-policy' element={<Privpolicy />} />
            <Route exact path='/search' element={<Search />} />
            <Route exact path='/projects-2' element={<Projects2 />} />
            <Route exact path='/projects-3' element={<Projects3 />} />
            <Route exact path='/projects-4' element={<Projects4 />} />
            <Route exact path='/projects-5' element={<Projects5 />} />
            <Route exact path='/bs-rooms-guide' element={<Bsroomsguide />} />
            <Route exact path='/wallmate' element={<Wallmate />} />
            <Route exact path='/acoustical-sealant' element={<Acousticalsealant />} />
            <Route exact path='/acoustical-sealant-green' element={<Acousticalsealantgreen />} />
            <Route exact path='/acoustics101' element={<Acoustics101 />} />
            <Route exact path='/fab-downloads' element={<Fabdownloads />} />
            <Route exact path='/fabriwallTrack' element={<FabriwallTrack />} />
            <Route exact path='/fabriwallfabric' element={<Fabriwallfabric />} />
            <Route exact path='/fabriwallpanel' element={<Fabriwallpanel />} />
            <Route exact path='/pet-panel' element={<Petpanel />} />
            <Route exact path='/woodwool-wall' element={<Woodwoolwall />} />
            <Route exact path='/woodwool-ceiling' element={<Woodwoolceiling />} />
            <Route exact path='/woodwool-cloud' element={<Woodwoolcloud />} />
            <Route exact path='/acoustyfabric-usa' element={<Acoustyfabricusa />} />
            <Route exact path='/reverb-demo' element={<Reverbdemo />} />
            <Route exact path='/soundproofing-fixes1' element={<Soundproofingfixes1 />} />
            <Route exact path='/soundproofing-fixes2' element={<Soundproofingfixes2 />} />
            <Route exact path='/woodwool-board' element={<Woodwoolboard />} />
            <Route exact path='/foamstop' element={<Foamstop />} />
            <Route exact path='/polywadding' element={<Polywadding />} />
            <Route exact path='/polyester-acoustipanels' element={<Polyesteracoustipanels />} />
            <Route exact path='/polyurethanefoam' element={<Polyurethanefoam />} />
            <Route exact path='/polysorb-wallpanel' element={<Polysorbwallpanel />} />
            <Route exact path='/fabric-wrapped-fiber-glass' element={<Fabricwrappedfiberglass />} />
            <Route exact path='/fabric-wrapped-fiberglass-baffles' element={<Fabricwrappedfiberglassbaffles />} />
            <Route exact path='/fabric-wrapped-fiberglass-clouds' element={<Fabricwrappedfiberglassclouds />} />
            <Route exact path='/melamine-flat-faced-foam' element={<Melamineflatfacedfoam />} />
            <Route exact path='/melamine-linear-wedge-panels' element={<Melaminelinearwedgepanels />} />
            <Route exact path='/melamine-pyramid-panels' element={<Melaminepyramidpanels />} />
            <Route exact path='/melamine-sound-wave-panels' element={<Melaminesoundwavepanels />} />
            <Route exact path='/polymax' element={<Polymax />} />
            <Route exact path='/acousticalfoam-eggcrate' element={<Acousticalfoameggcrate />} />
            <Route exact path='/catalogue-download' element={<Cataloguedownload />} />
            <Route exact path='/glossary-terms' element={<Glossaryterms />} />
            <Route exact path='/soundproof-installation' element={<Soundproofinstallation />} />
            <Route exact path='/reverberation' element={<Reverberation />} />
            <Route exact path='/acfabric-anchorage' element={<Acfabricanchorage />} />
            <Route exact path='/acfabric-beehave' element={<Acfabricbeehave />} />
            <Route exact path='/acfabric-broadcast' element={<Acfabricbroadcast />} />
            <Route exact path='/acfabric-cape' element={<Acfabriccape />} />
            <Route exact path='/acfabric-crosstown' element={<Acfabriccrosstown />} />
            <Route exact path='/acfabric-drift' element={<Acfabricdrift />} />
            <Route exact path='/acfabric-fr701' element={<Acfabricfr701 />} />
            <Route exact path='/acfabric-fr702' element={<Acfabricfr702 />} />
            <Route exact path='/acfabric-fr703' element={<Acfabricfr703 />} />
            <Route exact path='/acfabric-framework' element={<Acfabricframework />} />
            <Route exact path='/acfabric-highbeams' element={<Acfabrichighbeams />} />
            <Route exact path='/acfabric-intermix' element={<Acfabricintermix />} />
            <Route exact path='/acfabric-intution' element={<Acfabricintution />} />
            <Route exact path='/acfabric-jane' element={<Acfabricjane />} />
            <Route exact path='/acfabric-lido' element={<Acfabriclido />} />
            <Route exact path='/acfabric-network' element={<Acfabricnetwork />} />
            <Route exact path='/acfabric-quadrille' element={<Acfabricquadrille />} />
            <Route exact path='/acfabric-spinel' element={<Acfabricspinel />} />
            <Route exact path='/acfabric-steadfast' element={<Acfabricsteadfast />} />
            <Route exact path='/acfabric-streetwise' element={<Acfabricstreetwise />} />
            <Route exact path='/acfabric-studio' element={<Acfabricstudio />} />
            <Route exact path='/soundproofing-fabrisorb' element={<Soundproofingfabrisorb />} />
            <Route exact path='/acoustics' element={<Acoustics />} />
            <Route exact path='/soundproofing-insulation' element={<Soundproofinginsulation />} />
            <Route exact path='/soundproofingtips' element={<Soundproofingtips />} />
            <Route exact path='/terms' element={<Terms />} />
            <Route exact path='/problem' element={<Problem />} />
            <Route exact path='/soundproofing' element={<Soundproofing />} />
            <Route exact path='/saudi-arabia' element={<Saudi />} />
            <Route exact path='/bahrain' element={<Bahrain />} />
            <Route exact path='/india' element={<India />} />
            <Route exact path='/kuwait' element={<Kuwait />} />
            <Route exact path='/oman' element={<Oman />} />
            <Route exact path='/qatar' element={<Qatar />} />
            <Route exact path='/products' element={<Products />} />
            <Route exact path='/project-bhutan' element={<ProjectgcitBhutan />} />
            <Route exact path='/project-biocon' element={<ProjectBiocon />} />
            <Route exact path='/project-epam' element={<ProjectEpam />} />
            <Route exact path='/project-dlf' element={<ProjectDlf />} />
            <Route exact path='/project-kalaignar-library' element={<ProjectKalaignarLibrary />} />
            <Route exact path='/project-ipop' element={<ProjectIpop />} />
            <Route exact path='/project-myntra' element={<ProjectMyntra />} />
            <Route exact path='/project-rio' element={<ProjectRio />} />
            <Route exact path='/project-hdfc' element={<ProjectHdfc />} />
            <Route exact path='/foamwood' element={<Foamwood />} />
            <Route exact path='/decowood' element={<Decowood />} />
            <Route exact path='/polywood' element={<Polywood />} />
            <Route exact path='/Polysorb-deco' element={<PolysorbDeco />} />
            <Route exact path='/Fabrosorb-deco' element={<FabrisorbDeco />} />
            <Route exact path='/polymax-ceiling-tiles' element={<PolymaxCeilingTiles />} />
            <Route exact path='/deffuso' element={<Deffuso />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
