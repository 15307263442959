import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Entertainment1 from '../../images/inner/sound-silencer-panels-200x200.webp';
import Entertainment2 from '../../images/inner/fabric-wrapped-panel-edge-details-lg-200x200.webp';
import Entertainment3 from '../../images/inner/cotton_stack-200x200.webp';
import Entertainment4 from '../../images/inner/iso_hangers032-200x200.webp';
import Entertainment5 from '../../images/inner/rsic-1-clip-furring-channel-200x177.webp';
import Entertainment6 from '../../images/inner/greenGlueTubes-200x200.webp';
import Entertainment7 from '../../images/inner/curve-low-profile-diffusor1-200x200.webp';
import Entertainment8 from '../../images/inner/recording-studio-windows2-200x200.webp';
import Entertainment9 from '../../images/inner/soundproof-door-piano-room-st-olaf4-200x200.webp';
import Entertainment10 from '../../images/inner/echoeliminator-stack.webp';
import './entertainment-soundproofing-products.css';
import ScrollToTopButton from '../ScrollButton';
function entertainment() {
    return (
      
    <>
    <Helmet>
    <title>Soundproof Recording Booth & Theatre | Stadiums</title>
    <meta name="description" content="We are a soundproofer of Recording Studios, Theaters, Concert Halls, Home Theaters, Arenas, and Stadiums" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Soundproofing Recording Studios, Theaters, Concert Halls, Home Theaters, Arenas, and Stadiums" />
    <meta property="og:description" content="Acoustical Surfaces specializes in designing and manufacturing noise control solutions for the entertainment industry." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/recording-studio-windows2-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/entertainment" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/entertainment-soundproofing-products" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/entertainment-soundproofing-products" />
    </Helmet>
    <Header />
    <section className="content" id="entertainment">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Soundproofing Recording Studios, Theaters, Concert Halls, Home Theaters, Arenas, and Stadiums</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>Acoustical Surfaces specializes in designing and manufacturing noise control solutions for the entertainment industry. A space or venue with poorly designed acoustics quickly detracts from a musical performance and diminishes the enjoyment of audiences and spectators. During our many years of experience, we have designed acoustical systems for soundproofing recording studios, theaters, concert halls, home theaters, arenas, and stadiums.</p>
                                    <p>We offer top-quality sound blocking, sound diffusion, and sound absorption products in many different options. Our product line includes acoustical panels, clouds and baffles and more. We can supply sound barriers and hardware for interior or exterior applications. Our products are className A rated to meet fire codes and also meet LEED certification standards.</p>
                                    <p>For complete acoustical systems that provide the best sound quality for entertainment applications, trust the experts at Acoustical Surfaces. The list above is just a sample of what we use to design and engineer a complete acoustical solution. <Link to="/contact">Contact us</Link> for more details.</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>Entertainment Soundproofing Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Entertainment1} alt="Sound Silencer Panels" />
                                </Link>
                                <h5>Sound Silencer Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Entertainment2} alt="Fabric Wrapped Fiberglass Panels" />
                                </Link>
                                <h5>Fabric Wrapped Fiberglass Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Entertainment3} alt="Echo Eliminator" />
                                </Link>
                                <h5>Echo Eliminator</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Entertainment4} alt="Spring Isolation Hangers" />
                                </Link>
                                <h5>Spring Isolation Hangers</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Entertainment5} alt="RSIC-1 Clips" />
                                </Link>
                                <h5>RSIC-1 Clips</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Entertainment6} alt="Green Glue" />
                                </Link>
                                <h5>Green Glue</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Entertainment7} alt="Curve Panels" />
                                </Link>
                                <h5>Curve Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Entertainment8} alt="Windows" />
                                </Link>
                                <h5>Windows</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Entertainment9} alt="Doors" />
                                </Link>
                                <h5>Doors</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Entertainment10} alt="Pro Audio" />
                                </Link>
                                <h5>Pro Audio</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default entertainment; 