import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import './fabric-wrapped-fiber-glass.css';
import Mobsidebar from '../mobile-sidebar';
import Fiberglass1 from '../../images/inner/fabric-wrapped-panel-edge-details.jpg';
import Fiberglass2 from '../../images/inner/Fabric-Wrapped-Panels-1-200x200.jpg';
import Fiberglass3 from '../../images/inner/Fabric-Wrapped-Panels-3-200x200.jpg';
import Fiberglass4 from '../../images/inner/Fabric-Wrapped-Panels-4-200x200.jpg';
import Fiberglass5 from '../../images/inner/Fabric-Wrapped-Panels-5-200x200.jpg';
import Fiberglass6 from '../../images/inner/fpswallp042.jpg';
import Fiberglass7 from '../../images/inner/fpswallp05.jpg';
import Fiberglass8 from '../../images/inner/fpswallp06.jpg';
import Fiberglass9 from '../../images/inner/fpswallp07.jpg';
import Fiberglass10 from '../../images/inner/fpswallp08.jpg';
import Fiberglass11 from '../../images/inner/fpswallp09.jpg';
import Fiberglass12 from '../../images/inner/fpswallp10.jpg';
import Fiberglass13 from '../../images/inner/fpswallp11.jpg';
import Fiberglass14 from '../../images/inner/fpswallp13.jpg';
import Fiberglass15 from '../../images/inner/fabric-panel-square-edge-detail.jpg';
import Fiberglass16 from '../../images/inner/fabric-panel-radius-edge-detail.jpg';
import Fiberglass17 from '../../images/inner/fabric-panel-bevel-edge-detail.jpg';
import Fiberglass18 from '../../images/inner/fabric-panel-half-bevel-edge-detail.jpg';
import ScrollToTopButton from '../ScrollButton';
function fiberglass() {
    return (
      
    <>
    <Helmet>
    <title>Decorative Fabric Wrapped Custom Acoustical Wall Panels - Fabrisorb</title>
    <meta name="description" content="" />
    <meta property="og:title" content="Decorative Fabric Wrapped Custom Acoustical Wall Panels" />
    <meta property="og:description" content="If using E.C. clips to mount these fabric wrapped acoustical panels to the ceiling, the installer must use construction adhesive on each clip so the clips do not slide apart. " />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/Fabric-Wrapped-Panels-1-200x200.jpg" />
    <meta property="og:locale" content="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/fabric-wrapped-fiber-glass" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/fabric-wrapped-fiber-glass" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/fabric-wrapped-fiber-glass" hreflang="en-ae" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="IN" />
    <meta name="geo.region" content="india" />
    </Helmet>
    <Header />
    <section className="content" id="fabricwrapped">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Noise S.T.O.P. FABRISORB™ </h1>
                        <div className="row">
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/fabric-wrapped-panel-edge-details.jpg" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Fiberglass1} alt="Fiberglass1" />
                                </Link>
                            </div>
                            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 text">
                                <h2>Decorative Fabric Wrapped Custom Acoustical Wall Panels</h2>
                                <p>High Performance
                                <br /> Architecturally Decorative
                                <br /> Custom Engineered & Manufactured
                                <br /> Reduced Noise & Reverberation
                                <br /> 100% Custom Colors and Sizes
                                </p>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h2>Fabric Wrapped Panel Installation Tutorial</h2>
                                <p className="ytvid">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Yk-kumdTdMg" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen title='Fabric-Fiber-Glass'></iframe>
                                </p>
                                <p><strong>Note:</strong> If using E.C. clips to mount these fabric wrapped acoustical panels to the ceiling, the installer must use construction adhesive on each clip so the clips do not slide apart. It is also advised to toe-nail the acoustic wall tile into place, or use trim around the perimeter to make sure that the clips adhere together correctly.</p>
                            </div>
                        </div>
                        <div className="row">
                            <h3 style={{marginleft: "1em"}}>Product Photos</h3>
                            <div className="col-md-12 col-sm-12 fleximages">
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/Fabric-Wrapped-Panels-1.jpg" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Fiberglass2} alt="Fiberglass2" />
                                    </Link>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/Fabric-Wrapped-Panels-2.jpg" data-lightbox="example-1">
                                    </Link>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/Fabric-Wrapped-Panels-3.jpg" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Fiberglass3} alt="Fiberglass3" />
                                    </Link>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/Fabric-Wrapped-Panels-4.jpg" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Fiberglass4} alt="Fiberglass4" />
                                    </Link>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/Fabric-Wrapped-Panels-5.jpg" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Fiberglass5} alt="Fiberglass5" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <h3>Product Specs</h3>
                                    <tbody>
                                        <tr>
                                            <th>Material</th>
                                            <td>6 lb. density glass fiber with woven fabric and micro-perforated vinyl facings.</td>
                                        </tr>
                                        <tr>
                                            <th>Pattern</th>
                                            <td>Flat-faced with square or radiused corners, beveled, radiused or mitered edges.</td>
                                        </tr>
                                        <tr>
                                            <th>Features</th>
                                            <td>Custom designed and manufactured to meet every need. Soft or hard edges.</td>
                                        </tr>
                                        <tr>
                                            <th>Applications</th>
                                            <td>These decorative sound absorbing panels are great for offices, schools, meeting rooms, music rooms, hotels, auditoriums, recording studios, broadcasting studios. Any space where good speech privacy or speech intelligibility is important.</td>
                                        </tr>
                                        <tr>
                                            <th>Thickness</th>
                                            <td>1″, 1-1/8″, 2″, 2-1/8″.</td>
                                        </tr>
                                        <tr>
                                            <th>Sizes</th>
                                            <td>Custom Up to 4′ × 10′.</td>
                                        </tr>
                                        <tr>
                                            <th>Colors</th>
                                            <td> A wide range of standard colors and custom colors are available for woven fabrics and perforated vinyls.</td>
                                        </tr>
                                        <tr>
                                            <th>Flammability</th>
                                            <td>ASTM E84, className A. Flame Spread: 15; Smoke Developed: 40.</td>
                                        </tr>
                                        <tr>
                                            <th>Installation</th>
                                            <td>Adhesive, Hook Loop Fasteners, Mechanical Clips, Impaling Clips, magnetic clips.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 fleximages fwfg">
                                <div className="imagebox">
                                    <img src={Fiberglass6} className="img-fluid" alt="High Performance Absorption" />
                                    <span>High performance absorption</span>
                                </div>
                                <div className="imagebox">
                                    <img src={Fiberglass7} className="img-fluid" alt="Absorptive in High Traffic Areas" />
                                    <span>Absorptive in high traffic areas</span>
                                </div>
                                <div className="imagebox">
                                    <img src={Fiberglass8} className="img-fluid" alt="Absorption and Reflection" />
                                    <span>For combined use in Studios for absorption and reflection</span>
                                </div>
                                <div className="imagebox">
                                    <img src={Fiberglass9} className="img-fluid" alt="Absorption and Sound Blocking" />
                                    <span>Multi-purpose panel for absorption and sound blocking</span>
                                </div>
                                <div className="imagebox">
                                    <img src={Fiberglass10} className="img-fluid" alt="Absorption and Abuse Resistance" />
                                    <span>For gymnasiums, absorption and abuse resistance</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 fleximages fwfg">
                                <div className="imagebox">
                                    <img src={Fiberglass11} className="img-fluid" alt="Effective Tackability" />
                                    <span>For long lasting and effective tackability</span>
                                </div>
                                <div className="imagebox">
                                    <img src={Fiberglass12} className="img-fluid" alt="High Absorption and Tackability" />
                                    <span>High absorption and tackability</span>
                                </div>
                                <div className="imagebox">
                                    <img src={Fiberglass13} className="img-fluid" alt="Sound Absorption" />
                                    <span>Tackability where sound absorption is not necessary</span>
                                </div>
                                <div className="imagebox">
                                    <img src={Fiberglass14} className="img-fluid" alt="Barricade Vinyl Barrier" />
                                    <span>Barricade vinyl barrier, ideal for areas requiring high STC</span>
                                </div>
                            </div>
                        </div>
                        <p><Link className="btn btn-asi" to="reverb-demo">Reverberation Demonstration</Link></p>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="8"><h4>Fabrisorb™ Wall panel&nbsp;– Sound Absorption Coefficients</h4></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td className="colheader">6-7 PCF Glass Fiber</td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>NRC</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">1″</td>
                                            <td>.14</td>
                                            <td>.27</td>
                                            <td>.80</td>
                                            <td>1.11</td>
                                            <td>1.14</td>
                                            <td>1.14</td>
                                            <td className="emphasis">.85</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">2″</td>
                                            <td>.22</td>
                                            <td>.81</td>
                                            <td>1.24</td>
                                            <td>1.30</td>
                                            <td>1.21</td>
                                            <td>1.16</td>
                                            <td className="emphasis">1.15</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 fleximages" style={{padding: "1em 0"}}>
                                <h3 style={{marginleft: "1em"}}>Edge Details</h3>
                                <div className="imagebox">
                                    <img src={Fiberglass15} className="img-fluid" alt="product" />
                                </div>
                                <div className="imagebox">
                                    <img src={Fiberglass16} className="img-fluid" alt="product" />
                                </div>
                                <div className="imagebox">
                                    <img src={Fiberglass17} className="img-fluid" alt="product" />
                                </div>
                                <div className="imagebox">
                                    <img src={Fiberglass18} className="img-fluid" alt="product" />
                                </div>
                            </div>
                        </div>
                        <div className="row d-none d-md-block d-lg-block d-xl-block">
                            <div className="col-sm">
                                <table className="table-simple">
                                    <tbody>
                                        <tr>
                                            <th colSpan="3">Impaling Clips and Adhesive</th>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">One impaling clip is needed for each mounting locationImpaling clips are installed onto the wall to support the weight of the panels until a construction adhesive dries.</td>
                                        </tr>
                                        <tr>
                                            <td rowspan="5">
                                                <img className="aligncenter size-thumbnail wp-image-5489" src="https://ye7zs22zd242wmzxo41cj7b5-wpengine.netdna-ssl.com/assets/fwp-hardware-impaling-clip-adhesive-200x200.jpg" alt="Impaling Clip" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>Install Location</strong></td>
                                            <td>Wall</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Parts Included</strong></td>
                                            <td>(1) × Impaling Clip</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Recommended Adhesive</strong>
                                            <br /> (sold separately)</td>
                                            <td>Titebond Green Choice Adhesive (ASTSA28)</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Part Number</strong></td>
                                            <td>HWIMP33</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>&nbsp;</p>
                                <table className="table-simple">
                                    <tbody>
                                        <tr>
                                            <th colSpan="3">Fabric Wrapped Panel Hardware Pack – Impaling Clip</th>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">One hardware pack is needed for each mounting locationDrywall anchors are installed into the drywall and the screws hold the impaling clip to the anchor. Impaling clips are installed onto the wall to support the weight of the panels until a construction adhesive dries.</td>
                                        </tr>
                                        <tr>
                                            <td rowspan="5">
                                                <img className="aligncenter size-thumbnail wp-image-5491" src="https://ye7zs22zd242wmzxo41cj7b5-wpengine.netdna-ssl.com/assets/fwp-hardware-impaling-clip1-200x200.jpg" alt="Fabric Wrapped Panel Impaling Clip Hardware Pack" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>Install Location</strong></td>
                                            <td>Wall</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Parts Included</strong></td>
                                            <td>(1) × Impaling Clip
                                            <br /> (2) × Drywall Anchors
                                            <br /> (2) × ¾″ Screws</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Recommended Adhesive</strong>
                                            <br /> (sold separately)</td>
                                            <td>Titebond Green Choice Adhesive (ASTSA28)</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Part Number</strong></td>
                                            <td>HWFWPIMP</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>&nbsp;</p>
                                <table className="table-simple">
                                    <tbody>
                                        <tr>
                                            <th colSpan="3">Fabric Wrapped Panel Hardware Pack 1</th>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">One hardware pack is needed for each mounting locationThe drywall anchors are installed onto the drywall and the screws hold one of the plastic clips to the wall. The remaining ¾″ screws are used to mount the remaining plastic clip to the mounting plate on the back of the panel. Adhesive can be used to hold the clips together to prevent the clips from detaching from one another.</td>
                                        </tr>
                                        <tr>
                                            <td rowspan="5">
                                                <img className="aligncenter size-thumbnail wp-image-5492" src="https://ye7zs22zd242wmzxo41cj7b5-wpengine.netdna-ssl.com/assets/fwp-hardware-pack-11-200x200.jpg" alt="Fabric Wrapped Panel Hardware Pack 1" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>Install Location</strong></td>
                                            <td>Wall or Ceiling</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Parts Included</strong></td>
                                            <td>(1) × Mounting Plate (factory-installed on the back of the panel)
                                            <br /> (2) × 3″ Plastic Z-bars
                                            <br /> (2) × Drywall Anchors
                                            <br /> (4) × ¾″ Screws</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Part Number</strong></td>
                                            <td>HWFWPHP1</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Note</strong></td>
                                            <td>Plastic clips are also available in 21″, 47″, and 96″ lengths.</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>&nbsp;</p>
                                <table className="table-simple">
                                    <tbody>
                                        <tr>
                                            <th colSpan="3">Fabric Wrapped Panel Rotofast Standard Anchor Kit</th>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">One hardware pack is needed for each mounting location. The Rotofast Standard Anchor is screwed to the wall. Mark anchor locations on panel. Push metal insert into anchor body. Position panel at pre-marked locations and push hex tool through panel face to engage anchor. Turn clockwise approximately two revolutions. Pull the driver out.For panels with tightly-woven fabric, leather, vinyl, or other specialty covering, the Rotofast Snap-On Anchor is recommended.</td>
                                        </tr>
                                        <tr>
                                            <td rowspan="4">
                                                <img className="aligncenter size-thumbnail wp-image-5493" src="https://ye7zs22zd242wmzxo41cj7b5-wpengine.netdna-ssl.com/assets/rotofast-standard-anchor-single-kit1-200x200.jpg" alt="Fabric Wrapped Panel Rotofast Snap-On Anchor Kit" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>Install Location</strong></td>
                                            <td>Wall</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Parts Included</strong></td>
                                            <td>(1) × Rotofast standard anchor
                                            <br /> (1) × Wall anchor
                                            <br /> (1) × 2″ #8 Screw
                                            <br /> (1) Rotofast standard hex tool
                                            <br /> (1) Rotofast standard driver tool</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Note</strong></td>
                                            <td>Multiple kits ordered together will only include (1) Rotofast standard hex tool and (1) Rotofast standard driver tool.</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>&nbsp;</p>
                                <table className="table-simple">
                                    <tbody>
                                        <tr>
                                            <th colSpan="3">Fabric Wrapped Panel Rotofast Snap-On Anchor Kit</th>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">One hardware pack is needed for each mounting location. The Rotofast Snap-On Anchor is screwed into the back of the panel. The marking plug is inserted into the anchor, then panel is pushed against wall to mark anchor locations. The ratchet is screwed into the wall at marked locations. Firmly push panels onto ratchets to complete installation.</td>
                                        </tr>
                                        <tr>
                                            <td rowspan="5">
                                                <img className="aligncenter size-thumbnail wp-image-5494" src="https://ye7zs22zd242wmzxo41cj7b5-wpengine.netdna-ssl.com/assets/rotofast-snap-on-anchor-single-kit-200x200.jpg" alt="Fabric Wrapped Panel Rotofast Snap-On Anchor Kit" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>Install Location</strong></td>
                                            <td>Wall or Ceiling</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Parts Included</strong></td>
                                            <td>(1) × Rotofast snap-on anchor with ratchet
                                            <br /> (1) × Marking plug
                                            <br /> (1) × Wall anchor
                                            <br /> (1) × 2″ #8 Screw
                                            <br /> (1) Rotofast Snap-On Hex Tool</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Note</strong></td>
                                            <td>Multiple kits ordered together will only include (1) Rotofast Snap-On Hex Tool and up to (6) Marking plugs.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <hr className="d-none d-lg-block d-xl-block" />
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default fiberglass; 