import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import Cadlibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Mobsidebar from '../mobile-sidebar';
import ScrollToTopButton from '../ScrollButton';
function acoustics101() {
    return (
      
    <>
    <Helmet>
    <title>Acoustics 101 and Sound Acoustics - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="Acoustics 101" />
    <meta property="og:description" content="Understanding the Nature of Sound, Acoustics and Noise Control" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.png" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acoustics101" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="acoustics101">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container">
                        <div className="megamenu">
                        <Megamenu />
                        </div>
                        <section className="site-content" role="main">
                            <div className="inner-wrap">
                                <article className="site-content-primary clearfix">
                                    <h1>Acoustics 101</h1>
                                    <h2 className="topbold">Understanding the Nature of Sound, Acoustics and Noise Control</h2>
                                    <p><span>The nature of Acoustics and Noise Control can be confusing. This section is designed to help educate and guide those with little to no knowledge of Acoustical terminology. If there is something you would like to learn more about, please click on the links below or call our NOISE CONTROL HELP LINE:</span><span> +91-80-257 22022,  +91-94497 77212.</span></p>
                                    <section className="additional-content">
                                        <article className="clearfix">
                                            <div className="col-3of9">
                                                <p><Link to="glossary-terms">Glossary of Acoustical terms</Link>
                                                <br />
                                                <Link to="sound_instalation">Reverberation Time Demonstration</Link>
                                                <br />
                                                <Link to="reverberation">Reflected Sound, Reverberation</Link></p>
                                            </div>
                                            <div className="col-3of9"></div>
                                            <div className="col-3of9 col-last"></div>
                                        </article>
                                        <div className="click-expand">
                                            <h4 className="ce-header">Propagation of Sound</h4>
                                            <div className="ce-body">
                                                <p><span><img className="img-fluid wp-image-1835 size-full alignright" src="https://ye7zs22zd242wmzxo41cj7b5-wpengine.netdna-ssl.com/assets/101x1_1.gif" alt="Sound is propagated in air" width="250" height="304" />Sound is propagated in air, much like blowing up a large balloon, which expands equally in all directions. (Fig 1)</span>
                                                <br />
                                                <br /><span>For sound to be generated and heard it must have a source, a medium through which to pass and a receiver.</span>
                                                <br />
                                                <br /><span>For purposes of this discussion, we will assume that we are talking about normal speech communications. The source is the speaker’s voice, the medium through which it is transmitted is air and the receiver is the listener’s ear.</span>
                                                <br />
                                                <br /><span>As sound is generated by the speaker’s speech, the speaker’s voice acts like a diaphragm which causes the molecules in the air to pulsate back and forth while moving in all directions, at a speed of 1130 ft per second (770 mph).</span>
                                                <br />
                                                <br /><span>A single segment of a sound wave may be characterized as pressure compressions and rarefactions.</span></p>
                                                <p><img className="img-fluid alignnone wp-image-1834 size-full" src="https://ye7zs22zd242wmzxo41cj7b5-wpengine.netdna-ssl.com/assets/101x1_2.gif" alt="Wavelength and Loudness" width="560" height="289" /></p>
                                            </div>
                                        </div>
                                        <div className="click-expand">
                                            <h4 className="ce-header">Wavelength and Loudness</h4>
                                            <div className="ce-body">
                                                <p>The distance between the sound compressions and rarefactions is known as wavelength. Low-frequency sounds have a long wavelength and are perceived as low-pitched sounds such as the rumble of a truck. High-pitched sounds have very short wavelengths such as sound emitted from a whistle. In the field of music, a piano can generate sounds ranging from 20 cycles per second (Hz) all the way up to 4600 cycles per second.</p>
                                                <p>(FIGURE 4) shows several wavelengths in feet and inches as a function of frequency.</p>
                                                <p></p>
                                                <p><img className="img-fluid wp-image-1842 size-full alignnone" src="https://ye7zs22zd242wmzxo41cj7b5-wpengine.netdna-ssl.com/assets/101x2_1.gif" alt="Low frequency Sounds" /></p>
                                                <p></p>
                                                <p>Loudness:(Amplitude or Intensity) is measured by the strength of the sound, depicted as a sine wave above and below the normal atmospheric pressure.
                                                </p>
                                                <p>(Figure 5) shows the combination of sine waves the sounds at different loudness levels or amplitudes. For the sake of simplicity, the sound intensity or pressure levels are measured in <a href="https://www.acousticalsurfaces.com/blog/acoustics-education/top-10-noisiest-jobs/" target="_blank" rel="noopener noreferrer"><span style={{textdecoration: "underline"}}>decibels</span></a>.</p>
                                                <p><img className="img-fluid alignnone wp-image-1843 size-full" src="https://ye7zs22zd242wmzxo41cj7b5-wpengine.netdna-ssl.com/assets/101x2_2.gif" alt="Sound Intensity" /></p>
                                            </div>
                                        </div>
                                        <div className="click-expand">
                                            <h4 className="ce-header">Equal Loudness Contours</h4>
                                            <div className="ce-body">
                                                <p><img className="img-fluid alignright size-full wp-image-1851" src="https://ye7zs22zd242wmzxo41cj7b5-wpengine.netdna-ssl.com/assets/101x3_11.gif" alt="Perception of sound" />Our ears do not perceive all sounds equally at the various frequencies or sound intensities. (FIGURE 6) shows an equal loudness contour chart. The sound levels for a particular sound as defined by the level at 1000 Hz will find the same for any given frequency along the curve. For example, a 20-decibel sound at 1000 Hz would be perceived as the same sound level of 50 decibels at 100 Hz. This indicates that our ears are less sensitive to low-frequency sounds than mid to high frequencies. As will be seen later, this sensitivity difference will feature repeatedly in the control of noise.</p>
                                            </div>
                                        </div>
                                        <div className="click-expand">
                                            <h4 className="ce-header">Perception of Sound (Loudness)</h4>
                                            <div className="ce-body">
                                                <p><span>The deviation of sound above and below the atmospheric pressure levels is called Sound Pressure. The energy expanded in the process of sound propagation is labeled intensity (loudness) and is measured in energy units. At this point the science of sound can be a little more complex and intimidating since placing a numerical value on sound is very difficult due to the extraordinary sensitivity of the human ear. Our ears can detect deviations in atmospheric pressure in the order of 1,000,000 to 1 and sound intensities of over a trillion to one.</span>
                                                <br />
                                                <br /><span>In order to make the measurement, calculation, and perception of sound more manageable, a compact scale has been devised incorporating the decibel (dB). A decibel is a logarithmic unit measure of sound pressure.</span>
                                                <br />
                                                <br /><span>(FIGURE 7) Shows sound levels of recognizable sound in decibels with a subjective evaluation from “very faint” to “deafening”. It shows the logarithmic values of intensity of energy units and the relative loudness as perceived by the human ear. Obviously, it is much easier to comprehend the decibel levels.</span>
                                                <br />
                                                <br />
                                                </p>
                                                <table className="table-simple left-align">
                                                    <thead>
                                                    <tr>
                                                        <th colSpan="5">TYPICAL A-WEIGHTED SOUND LEVELS</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr className="colheader" style={{fontweight: "bold"}}>
                                                        <td colSpan="2" rowspan="2">THRESHOLD OF FEELINGS</td>
                                                        <td colSpan="3">THRESHOLD OF AUDIBILITY</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DECIBELS
                                                            <br /> RE 20 uPA</td>
                                                        <td>INTENSITY ENERGY UNITS</td>
                                                        <td>RELATIVE LOUDNESS</td>
                                                    </tr>
                                                    <tr>
                                                        <td rowspan="3">DEAFENING</td>
                                                        <td>Jet Takeoff (200′)</td>
                                                        <td rowspan="2">120</td>
                                                        <td rowspan="2">1,000,000,000,000</td>
                                                        <td rowspan="2">4096</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Artillery</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Elevated Train</td>
                                                        <td rowspan="2">110</td>
                                                        <td rowspan="2">100,000,000,000</td>
                                                        <td rowspan="2">2048</td>
                                                    </tr>
                                                    <tr>
                                                        <td rowspan="3">VERY LOUD</td>
                                                        <td>Subway (20′)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Printing Press</td>
                                                        <td>100</td>
                                                        <td>10,000,000,000</td>
                                                        <td>1024</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Police Whistle</td>
                                                        <td>90</td>
                                                        <td>1,000,000,000</td>
                                                        <td>512</td>
                                                    </tr>
                                                    <tr>
                                                        <td rowspan="3">LOUD</td>
                                                        <td>VacuumCleaner(10′)</td>
                                                        <td>80</td>
                                                        <td>100,000,000</td>
                                                        <td>256</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Street Noise</td>
                                                        <td>70</td>
                                                        <td>10,000,000</td>
                                                        <td>128</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Noisy Office</td>
                                                        <td rowspan="2">60</td>
                                                        <td rowspan="2">1,000,000</td>
                                                        <td rowspan="2">64</td>
                                                    </tr>
                                                    <tr>
                                                        <td rowspan="3">MODERATE</td>
                                                        <td>Large Store</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Conversation</td>
                                                        <td>50</td>
                                                        <td>100,000</td>
                                                        <td>32</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Average Office</td>
                                                        <td rowspan="2">40</td>
                                                        <td rowspan="2">10,000</td>
                                                        <td rowspan="2">16</td>
                                                    </tr>
                                                    <tr>
                                                        <td rowspan="3">FAINT</td>
                                                        <td>Private office</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Quiet Conversation</td>
                                                        <td rowspan="2">30</td>
                                                        <td rowspan="2">1,000</td>
                                                        <td rowspan="2">8</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Studio (Speech)</td>
                                                    </tr>
                                                    <tr>
                                                        <td rowspan="3">VERY FAINT</td>
                                                        <td>Rustle of Leaves</td>
                                                        <td>20</td>
                                                        <td>100</td>
                                                        <td>4</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Whisper</td>
                                                        <td>10</td>
                                                        <td>10</td>
                                                        <td>2</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Soundproof Room</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <p><span>(FIGURE 7)</span>
                                                <br />
                                                <br /><span>The Relative Loudness levels are important insofar as they demonstrate that a 10-decibel increase will be perceived as twice as loud as the previous level or conversely, a decrease of 50% from the previous higher level. It is less important to understand the physics of this relative difference as much as to accept it as an acoustical phenomenon.</span>
                                                <br />
                                                <br /><span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>Note:</span><span>(FIGURE 7) expresses the sound pressure levels as single number levels in the A weighted scale. The A weighted scale uses the equal loudness contours to provide a single number value in the same manner as our ears perceived sound. The A weighting discounts the low-frequency sound level perception (This will be discussed further under <a href="https://www.acousticalsurfaces.com/soundlevel_meter/phonic_paa3.htm" target="_blank" rel="noopener noreferrer"><span style={{textdecoration: "underline"}}>Sound Level Meters</span></a>).</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="click-expand">
                                            <h4 className="ce-header">Inverse Square Law</h4>
                                            <div className="ce-body">
                                                <p>Another very important but little known acoustical phenomena is the Inverse Square Law. As a sound wave propagates spherically, the sound energy is distributed over the ever-increasing surface diameter of the wave front surface. The Inverse Square Law teaches us that for every doubling of the distance from the sound source in a free field situation, the sound intensity will diminish by 6 decibels.</p>
                                                <p>Under ideal conditions, a free field could be represented by a sound signal being generated from a mountain peak. In real life situations, however, rooms bounded by walls, floors, and ceilings will interrupt the inverse square law at a distance in an average 30′ square room at approximately 10-12 feet from the sound source. Nevertheless, it is important to accept the notion that sound will diminish in intensity with distance. For example, in a typical classNameroom with a teachers voice signal of 65 decibels at a three-foot distance from the teacher; at 6 feet away the sound intensity will be 59 decibels and at twelve feet it will diminish down to 53 decibels. (This is important to remember as we discuss the Signal to Noise Ratio S/NR later on)</p>
                                                <p>(FIGURE 8) shows a segment of the sound wave front surface area increasing with distance.</p>
                                                <p><img className="img-fluid alignleft wp-image-1857 size-full" style={{margin: "10px 20px 10px 0px"}} src="https://ye7zs22zd242wmzxo41cj7b5-wpengine.netdna-ssl.com/assets/1001x4_11.gif" alt="surface area increasing" />
                                                <br /> (FIGURE 8)</p>
                                                <p>In the angle shown in Figure 8, the same sound energy is distributed over the spherical surfaces of increasing areas as d is increased. The intensity of the sound is inversely proportional to the square of the distance of the wavefront from the signal source.</p>
                                                <p><strong>Example:</strong>
                                                <br /> 1d = 1
                                                <br /> 2d = 4
                                                <br /> 3d = 9
                                                <br /> 4d = 16</p>
                                            </div>
                                        </div>
                                        <div className="click-expand">
                                            <h4 className="ce-header">Sound Absorption</h4>
                                            <div className="ce-body">
                                                <p><img className="img-fluid alignright size-full wp-image-1863" src="https://ye7zs22zd242wmzxo41cj7b5-wpengine.netdna-ssl.com/assets/101x6_1.gif" alt="Sound Absorption" width="300" height="285" />All building materials have some acoustical properties in that they will all absorb, reflect or transmit sound striking them. Conventionally speaking, <a href="https://www.acousticalsurfaces.com/soundproofing_tips/html/PEPP_Ceiling_Info.htm"  target="_blank" rel="noopener noreferrer"><span style={{textdecoration: "underline"}}>acoustical materials</span></a> are those materials designed and used for the purpose of absorbing sound that might otherwise be reflected.</p>
                                                <p>Sound absorption is defined, as the incident sound that strikes a material that is not reflected back. An open window is an excellent absorber since the sounds passing through the open window are not reflected back but makes a poor sound barrier. Painted concrete block is a good sound barrier but will reflect about 97% of the incident sound striking it.</p>
                                                <p>When a sound wave strikes an acoustical material the sound wave causes the fibers or particle makeup of the absorbing material to vibrate. This vibration causes tiny amounts of heat due to the friction and thus sound absorption is accomplished by way of energy to heat conversion. The more fibrous a material is the better the absorption; conversely denser materials are less absorptive. The sound absorbing characteristics of acoustical materials vary significantly with frequency. In general,low-frequency sounds are very difficult to absorb because of their long wavelength. On the other hand, we are less susceptible to low-frequency sounds, which can be to our benefit in many cases.
                                                </p>
                                                <p>For the vast majority of conventional acoustical materials, the material thickness has the greatest impact on the material’s sound absorbing qualities. While the inherent composition of the acoustical material determines the material’s acoustical performance, other factors can be brought to bear to improve or influence the acoustical performance. Incorporating an air space behind an acoustical ceiling or wall panel often serves to improve low-frequency performance.</p>
                                            </div>
                                        </div>
                                        <div className="click-expand">
                                            <h4 className="ce-header">Speech Intelligibility</h4>
                                            <div className="ce-body">
                                                <p><a href="https://www.acousticalsurfaces.com/soundproofing_tips/html/speech-intelligibility-in-classNamerooms.html"  target="_blank" rel="noopener noreferrer"><span style={{textdecoration: "underline"}}>Speech Intelligibility</span></a> is defined as the percentage of speech heard correctly by the listener. The emphasis is on “correctly” rather than simply “heard.” Speech intelligibility is influenced by reverberation time, thedistance of the listener from the speaker, and background noise level.</p>
                                                <p>Of these elements, reverberation time, and background noise are influenced by the architecture of the space which, suggests that the acoustical environment should be the focus of greater design attention.</p>
                                            </div>
                                        </div>
                                        <div className="click-expand">
                                            <h4 className="ce-header">Signal To Noise Ratio (S/NR)</h4>
                                            <div className="ce-body">
                                                <p><img className="img-fluid size-medium wp-image-1877 alignright" src="https://ye7zs22zd242wmzxo41cj7b5-wpengine.netdna-ssl.com/assets/trans_21-350x228.gif" alt="Signal to noise ratio" /></p>
                                                <p>Figure 12 depicts a classNameroom setting in which the instructor’s voice signal is determined to be approximately 65 dBA at a distance of 3 feet from the teacher. The background noise level is 45 dBA and assumed to be fairly constant through out the room. The Inverse Square Law teaches us that the sound level of the instructor’s voice signal will be 59 dBA at a 6-foot distance, 53 dBA at a 12-foot distance and 47 dBA at a 24-foot distance.</p>
                                                <p>A good Signal to Noise Ratio should be not less than +15 dB, that is to say, the signal strength should be at least 15 decibels above the background noise level in order to achieve good Speech Intelligibility by the listener. From (Figure 12) it can be seen that beyond a 12-foot distance from the instructor the S/NR is far below what it should be.</p>
                                                <p>In a typical classNameroom, the ambient or background noise level in the unoccupied classNameroom should not exceed 35 dBA with the HVAC system running.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="click-expand">
                                            <h4 className="ce-header">Ambient Sound Level or Background Noise</h4>
                                            <div className="ce-body">
                                                <p><img className="img-fluid wp-image-1742 size-full alignright" src="https://ye7zs22zd242wmzxo41cj7b5-wpengine.netdna-ssl.com/assets/trans_3.gif" alt="Ambient Sound Level" /></p>
                                                <p>In an unoccupied space, sounds can be heard from a variety of sources. Careful scrutiny of the room can lead to identifying the intrusive sources. The diagram illustrates a few of the most common sources of noise.</p>
                                            </div>
                                        </div>
                                        <div className="click-expand">
                                            <h4 className="ce-header">Effects of Sound Leak on Partition Sound Insulation</h4>
                                            <div className="ce-body">
                                                <p><a href="https://ye7zs22zd242wmzxo41cj7b5-wpengine.netdna-ssl.com/assets/trans_4.gif"  target="_blank" rel="noopener noreferrer">
                                                <img className="img-fluid alignnone wp-image-1901 size-full" src="https://ye7zs22zd242wmzxo41cj7b5-wpengine.netdna-ssl.com/assets/trans_4.gif" alt="Effects of sound leak" /></a></p>
                                                <p><span>From thegapwe can see that even a small crack can significantly compromise the sound isolation performance of a wall, floor /ceiling assembly to be as airtight as possible. <a href="https://www.acousticalsurfaces.com/sealants_adhesives/acoustical-sound-sealant.html"  target="_blank" rel="noopener noreferrer"><span style={{textdecoration: "underline"}}>Caulking compounds</span></a> or acoustical sealants are indispensable for effective noise control.</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="click-expand">
                                            <h4 className="ce-header">Adding Decibels</h4>
                                            <div className="ce-body">
                                                <table className="table-simple">
                                                <tbody>
                                                    <tr className="colheader" style={{fontweight: "bold"}}>
                                                        <td style={{color: "#323232", textalign: "center"}}>When Two Decibel Levels Differ By</td>
                                                        <td style={{color: "#323232", textalign: "center"}}>Add the Following Number to the Higher Value</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{color: "#323232", textalign: "center"}}>0-1 dB</td>
                                                        <td style={{color: "#323232", textalign: "center"}}>3 dB</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{color: "#323232", textalign: "center"}}>2-3 dB</td>
                                                        <td style={{color: "#323232", textalign: "center"}}>2 dB</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{color: "#323232", textalign: "center"}}>4-9 dB</td>
                                                        <td style={{color: "#323232", textalign: "center"}}>1 dB</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{color: "#323232", textalign: "center"}}>10 dB or More</td>
                                                        <td style={{color: "#323232", textalign: "center"}}>0 dB</td>
                                                    </tr>
                                                    <tr className="colheader" style={{fontweight: "bold"}}>
                                                        <td style={{color: "#323232", textalign: "center"}} colSpan="2">Example</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{color: "#323232", textalign: "center"}} colSpan="2">88 dB + 90 dB = 92 dB</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{color: "#323232", textalign: "center"}} colSpan="2">75 dB = 81 dB = 82 dB</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{color: "#323232", textalign: "center"}} colSpan="2">70 dB = 80 dB = 80 dB</td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                                <p>When the direct sound diminishes in intensity in accordance with the inverse law the multitude of reflective sound intensities are combining to produce as an increase in the reflected sound levels to a point where the reflected sound can be higher than the direct sound. A typical example of this phenomena would be a voluminous, hard surfaced <a href="https://www.acousticalsurfaces.com/blog/gymnasium/gymnasium-acoustics-noise-treatments/"  target="_blank" rel="noopener noreferrer"><span style={{textdecoration: "underline"}}>gymnasium</span></a> that can experience a significant build up of reflective sound intensity.</p>
                                                <p>Where the direct sound and reflected sound are about equal is called the critical distance. In a typical classNameroom, critical distance is about 12″ from the source. Beyond the critical distance, the sound reduction will be less than 6 dB.</p>
                                            </div>
                                        </div>
                                        <div className="click-expand">
                                            <h4 className="ce-header">Sound Absorption Coefficients</h4>
                                            <div className="ce-body">
                                                <table className="table-simple left-align">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th colSpan="6">Coefficients</th>
                                                    </tr>
                                                    <tr className="colheader" style={{fontweight: "bold"}}>
                                                        <td>Materials</td>
                                                        <td>125Hz</td>
                                                        <td>250Hz</td>
                                                        <td>500Hz</td>
                                                        <td>1000Hz</td>
                                                        <td>2000Hz</td>
                                                        <td>4000Hz</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Brick– Unglazed</td>
                                                        <td>.03</td>
                                                        <td>.03</td>
                                                        <td>.03</td>
                                                        <td>.04</td>
                                                        <td>.05</td>
                                                        <td>.07</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Brick– Unglazed, Painted</td>
                                                        <td>.01</td>
                                                        <td>.01</td>
                                                        <td>.02</td>
                                                        <td>.02</td>
                                                        <td>.02</td>
                                                        <td>.03</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Carpet– Heavy, on Concrete</td>
                                                        <td>.02</td>
                                                        <td>.06</td>
                                                        <td>.14</td>
                                                        <td>.37</td>
                                                        <td>.60</td>
                                                        <td>.65</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Carpet– Heavy, on 40oz Hairfelt or Foam Rubber on Concrete</td>
                                                        <td>.08</td>
                                                        <td>.24</td>
                                                        <td>.57</td>
                                                        <td>.69</td>
                                                        <td>.71</td>
                                                        <td>.73</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Carpet– Heavy, with Impermeable Latex Backing on 40oz Hairfelt or Foam Rubber on Concrete</td>
                                                        <td>.08</td>
                                                        <td>.27</td>
                                                        <td>.39</td>
                                                        <td>.34</td>
                                                        <td>.48</td>
                                                        <td>.63</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Concrete Block– Light, Porous</td>
                                                        <td>.36</td>
                                                        <td>.44</td>
                                                        <td>.31</td>
                                                        <td>.29</td>
                                                        <td>.39</td>
                                                        <td>.25</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Concrete Block– Dense, Painted</td>
                                                        <td>.10</td>
                                                        <td>.05</td>
                                                        <td>.06</td>
                                                        <td>.07</td>
                                                        <td>.09</td>
                                                        <td>.08</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Gypsum Board– 1/2″, Nailed to 2×4, 16″ O.C.</td>
                                                        <td>.29</td>
                                                        <td>.10</td>
                                                        <td>.05</td>
                                                        <td>.04</td>
                                                        <td>.07</td>
                                                        <td>.09</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Marble or Glazed Tile</td>
                                                        <td>.01</td>
                                                        <td>.01</td>
                                                        <td>.01</td>
                                                        <td>.01</td>
                                                        <td>.02</td>
                                                        <td>.02</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Plaster– Gypsum, or Lime, Smooth Finish on Tile or Brick</td>
                                                        <td>.013</td>
                                                        <td>.015</td>
                                                        <td>.02</td>
                                                        <td>.03</td>
                                                        <td>.04</td>
                                                        <td>.05</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Plaster– Gypsum, or Lime, Rough Finish on Lath</td>
                                                        <td>.14</td>
                                                        <td>.10</td>
                                                        <td>.06</td>
                                                        <td>.05</td>
                                                        <td>.04</td>
                                                        <td>.03</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Plaster– Gypsum, or Lime, Smooth Finish on Lath</td>
                                                        <td>.14</td>
                                                        <td>.10</td>
                                                        <td>.06</td>
                                                        <td>.04</td>
                                                        <td>.04</td>
                                                        <td>.03</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Plywood Paneling– 3/8″ Thick</td>
                                                        <td>.28</td>
                                                        <td>.22</td>
                                                        <td>.17</td>
                                                        <td>.09</td>
                                                        <td>.10</td>
                                                        <td>.11</td>
                                                    </tr>
                                                    <tr className="colheader" style={{fontweight: "bold"}}>
                                                        <td>Fabrics</td>
                                                        <td>125Hz</td>
                                                        <td>250Hz</td>
                                                        <td>500Hz</td>
                                                        <td>1000Hz</td>
                                                        <td>2000Hz</td>
                                                        <td>4000Hz</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Light Velour– 10oz/sq yd, Hung Straight, in Contact with Wall</td>
                                                        <td>.03</td>
                                                        <td>.04</td>
                                                        <td>.11</td>
                                                        <td>.17</td>
                                                        <td>.24</td>
                                                        <td>.35</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Medium Velour– 14oz/sq yd, draped to half area</td>
                                                        <td>.07</td>
                                                        <td>.31</td>
                                                        <td>.49</td>
                                                        <td>.75</td>
                                                        <td>.70</td>
                                                        <td>.60</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Heavy Velour– 18-oz/sq yd, Draped to Half Area</td>
                                                        <td>.14</td>
                                                        <td>.35</td>
                                                        <td>.55</td>
                                                        <td>.72</td>
                                                        <td>.70</td>
                                                        <td>.65</td>
                                                    </tr>
                                                    <tr className="colheader" style={{fontweight: "bold"}}>
                                                        <td>Floors</td>
                                                        <td>125Hz</td>
                                                        <td>250Hz</td>
                                                        <td>500Hz</td>
                                                        <td>1000Hz</td>
                                                        <td>2000Hz</td>
                                                        <td>4000Hz</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Concrete or Terrazzo</td>
                                                        <td>.01</td>
                                                        <td>.01</td>
                                                        <td>.015</td>
                                                        <td>.02</td>
                                                        <td>.02</td>
                                                        <td>.02</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Linoleum– Asphalt, Rubber, or Cork Tile on Concrete</td>
                                                        <td>.02</td>
                                                        <td>.03</td>
                                                        <td>.03</td>
                                                        <td>.03</td>
                                                        <td>.03</td>
                                                        <td>.02</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Wood</td>
                                                        <td>.15</td>
                                                        <td>.11</td>
                                                        <td>.10</td>
                                                        <td>.07</td>
                                                        <td>.06</td>
                                                        <td>.07</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Wood Parquet in Asphalt on Concrete</td>
                                                        <td>.04</td>
                                                        <td>.04</td>
                                                        <td>.07</td>
                                                        <td>.06</td>
                                                        <td>.06</td>
                                                        <td>.07</td>
                                                    </tr>
                                                    <tr className="colheader" style={{fontweight: "bold"}}>
                                                        <td>Glass</td>
                                                        <td>125Hz</td>
                                                        <td>250Hz</td>
                                                        <td>500Hz</td>
                                                        <td>1000Hz</td>
                                                        <td>2000Hz</td>
                                                        <td>4000Hz</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Large Panes of Heavy Plate Glass</td>
                                                        <td>.18</td>
                                                        <td>.06</td>
                                                        <td>.04</td>
                                                        <td>.03</td>
                                                        <td>.02</td>
                                                        <td>.02</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ordinary Window Glass</td>
                                                        <td>.35</td>
                                                        <td>.25</td>
                                                        <td>.18</td>
                                                        <td>.12</td>
                                                        <td>.07</td>
                                                        <td>.04</td>
                                                    </tr>
                                                    <tr className="colheader" style={{fontweight: "bold"}}>
                                                        <td>Other</td>
                                                        <td>125Hz</td>
                                                        <td>250Hz</td>
                                                        <td>500Hz</td>
                                                        <td>1000Hz</td>
                                                        <td>2000Hz</td>
                                                        <td>4000Hz</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Water Surface, e.g. Swimming Pool</td>
                                                        <td>.008</td>
                                                        <td>.008</td>
                                                        <td>.013</td>
                                                        <td>0.15</td>
                                                        <td>.020</td>
                                                        <td>0.25</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Air, Sabins per 1000 Cubic Feet</td>
                                                        <td>.09</td>
                                                        <td>.20</td>
                                                        <td>.49</td>
                                                        <td>1.20</td>
                                                        <td>2.90</td>
                                                        <td>7.40</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Open Doors and Windows</td>
                                                        <td>1.00</td>
                                                        <td>1.00</td>
                                                        <td>1.00</td>
                                                        <td>1.00</td>
                                                        <td>1.00</td>
                                                        <td>1.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="7">Values Below are Given in Sabins/sq ft of Seating Area or Per Unit</td>
                                                    </tr>
                                                    <tr className="colheader" style={{fontweight: "bold"}}>
                                                        <td rowspan="2">Absorption of Seats and Audience</td>
                                                        <td>125Hz</td>
                                                        <td>250Hz</td>
                                                        <td>500Hz</td>
                                                        <td>1000Hz</td>
                                                        <td>2000Hz</td>
                                                        <td>4000Hz</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="6"><span className="tablenote">Values Below are in Sabins/sq ft of Seating Area or Per Unit</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Chairs– Metal or Wood Seats, Each, Unoccupied</td>
                                                        <td>.15</td>
                                                        <td>.19</td>
                                                        <td>.22</td>
                                                        <td>.39</td>
                                                        <td>.38</td>
                                                        <td>.30</td>
                                                    </tr>
                                                    <tr>
                                                        <td>People in a Room– Per Person <span className="tablenote">(Do not use for Auditorium Calculations)</span></td>
                                                        <td>2</td>
                                                        <td>3</td>
                                                        <td>4</td>
                                                        <td>5</td>
                                                        <td>5</td>
                                                        <td>4</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Audience– Seated in Upholstered Seats, Per Square Foot of Floor Area <span className="tablenote">(Use for Auditorium Calculations)</span></td>
                                                        <td>.60</td>
                                                        <td>.74</td>
                                                        <td>.88</td>
                                                        <td>.96</td>
                                                        <td>.93</td>
                                                        <td>.85</td>
                                                    </tr>
                                                </thead>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="click-expand">
                                            <h4 className="ce-header">Speed of Sound</h4>
                                            <div className="ce-body">
                                                <table className="table-simple left-align">
                                                <thead>
                                                    <tr>
                                                        <th rowspan="2">Material</th>
                                                        <th style={{textalign: "center"}} colSpan="2">Speed</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={{textalign: "center"}}>feet/second</th>
                                                        <th style={{textalign: "center"}}>meters/second</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{textalign: "left"}}>Rubber</td>
                                                        <td style={{textalign: "center"}}>196</td>
                                                        <td style={{textalign: "center"}}>60</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Air</td>
                                                        <td style={{textalign: "center"}}>1,130</td>
                                                        <td style={{textalign: "center"}}>344</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sea Water</td>
                                                        <td style={{textalign: "center"}}>4,900</td>
                                                        <td style={{textalign: "center"}}>1,494</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{textalign: "left"}}>Wood</td>
                                                        <td style={{textalign: "center"}}>12,500</td>
                                                        <td style={{textalign: "center"}}>3,810</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Steel</td>
                                                        <td style={{textalign: "center"}}>16,600</td>
                                                        <td style={{textalign: "center"}}>4,877</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{textalign: "left"}}>Gypsum Board</td>
                                                        <td>22,300</td>
                                                        <td style={{textalign: "center"}}>6,797</td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="click-expand">
                                            <h4 className="ce-header">What is Noise?</h4>
                                            <div className="ce-body">
                                                <p>Simply stated, noise is unwanted sound. An intrusive sound that interferes with speech intelligibility or speech privacy is characterized as noise. Noise is a relative term and can range from low levels of intrusive sound in a quiet environment to very loud sounds in an already noisy environment.</p>
                                            </div>
                                        </div>
                                        <div className="click-expand">
                                            <h4 className="ce-header">Noise Reduction</h4>
                                            <div className="ce-body">
                                                <p><span><a href="https://ye7zs22zd242wmzxo41cj7b5-wpengine.netdna-ssl.com/assets/noise_gr.gif"><img className="img-fluid size-medium wp-image-1866 alignright" src="https://ye7zs22zd242wmzxo41cj7b5-wpengine.netdna-ssl.com/assets/noise_gr-350x287.gif" alt="noise" width="350" height="287" /></a>Reflected sounds that are not absorbed will cause an increase in overall sound levels in a space. Whenthe spaceis acoustically treated, the sound build up is eliminated or reduced. The sound level difference between the untreated space and the acous</span>tically treated space is described as the <a href="https://www.acousticalsurfaces.com/soundproofing_tips/noise_reduction.htm" target="_blank" rel="noopener noreferrer"><span style={{textdecoration: "underline"}}>Noise Reduction</span></a> (NR).</p>
                                                <p><span>Sound measured on one side of a wall and then on the opposite side will reflect the sound transmission blocking characteristics of the wall. The difference between the two measurements is described as the Noise Reduction characteristics of the wall.</span>
                                                <br />
                                                <br /><span>Treating a noisy reverberant space with acoustical materials can reduce the reflected sound build up that occurs due to the reflective hard surfaces.</span>
                                                <br />
                                                <br /><span>In a “before treatment” and “after treatment” comparison the decibel level can be calculated. This diagram can be used to compare how the sound reduction will be perceived.</span>
                                                <br />
                                                <br /><span>Example:An existing room with 500sabinesof absorption prior to acoustical treatment that adds an additional 1500 sabins will have an absorption ratio of treated/untreated = 4</span></p>
                                                <p><span>The treated room will be perceived to be about 37% quieter than the untreated room.</span></p>
                                            </div>
                                        </div>
                                        <div className="click-expand">
                                            <h4 className="ce-header">Noise Interference with Speech Communications</h4>
                                            <div className="ce-body">
                                                <p>When background or ambient noise levels are sufficiently high enough, the background noise can mask the sound levels of speech that wish to be heard. Restaurants can often be classNameic examples of excessive noise interference due to lack of sufficient quality or quantity of sound absorbing materials that prevent excessive noise buildup. Diners have to speak louder and louder to be heard and in doing so compete with one another, thereby increasing the sound levels to even greater levels. Appropriate acoustical treatment will prevent the reflected noise build up and significantly reduce the necessity for diners to speak louder to enjoy conversations with one another.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="click-expand">
                                            <h4 className="ce-header">Speech Directivity – Speaker and Listener Orientation</h4>
                                            <div className="ce-body">
                                                <p><span><img className="img-fluid size-full wp-image-1880 alignright" src="https://ye7zs22zd242wmzxo41cj7b5-wpengine.netdna-ssl.com/assets/directivity.gif" alt="directivity" width="241" height="240" />Speaker orientation can be an important factor in classNamerooms and meeting rooms in particular. As indicated in the diagram, there is a difference of about 10 decibels (dBA) in speech levels between the front and back of the speaker. This amounts to about 1-1/2 decibels per 30 degrees of rotation from the head ondirectionof the speech signal.</span>
                                                <br />
                                                <br /><span>The average sound level of a male speaker is about 65-64 decibels at a distance of 3 feet from the speaker. The average speech level of a female speaker is about 2-4 decibels lower at a 3-foot distance from the speaker.</span>
                                                <br />
                                                <br /><span>The orientation of the speaker to the listener is an important consideration. This is particularly so when considered in the context of the Inverse Square Law, which teaches us, that for every doubling of the distance the signal will diminish in intensity by approximately 6 decibels.</span></p>
                                            </div>
                                        </div>
                                        <div className="click-expand">
                                            <h4 className="ce-header">Speech Privacy</h4>
                                            <div className="ce-body">
                                                <p>Speech privacy in an “open office” for example is the ability to maintain the confidentiality of conversational speech from the source to adjacent areas. In the open plan office,speech privacy is accomplished by introducing masking sound by way of white noise, or background music to prevent other employees nearby from understanding what the speaker is saying.</p>
                                            </div>
                                        </div>
                                        <div className="click-expand">
                                            <h4 className="ce-header">Masking Sound Systems</h4>
                                            <div className="ce-body">
                                                <p>Most commonly used and necessary in the open plan office layout, sound masking systems involve the generation of the generation of random noise fed through speakers uniformly distributed though out the office to produce a subtle and uniform masking sound level. Sound masking systems provide the means to maintain speech confidentiality as well as a masking protection against other intrusive and distracting noises in the <a href="https://www.acousticalsurfaces.com/office-environments" target="_blank" rel="noopener noreferrer"><span style={{textdecoration: "underline"}}>office environment</span></a>.</p>
                                            </div>
                                        </div>
                                        <div className="click-expand">
                                            <h4 className="ce-header">Transmission of Sound</h4>
                                            <div className="ce-body">
                                                <p>Contrary to the popular notion that sound passes through a structure, such is not the case. The sound generated on one side of a wall will energize the wall structure and set it in motion, much like a diaphragm. The wall itself becomes the transmitter of the sound energy which can be heard on the opposite side of the wall by the listener</p>
                                            </div>
                                        </div>
                                        <div className="click-expand">
                                            <h4 className="ce-header">Resonant Frequencies</h4>
                                            <div className="ce-body">
                                                <p><span>When sound waves strike a wallassemblyfor example, the sound is comprised of a wide spectrum of frequencies. The wall may react to the various frequency energy in different degrees, based on the characteristics and component make up of the wall.</span>
                                                <br />
                                                <br /><span>The accompanying diagram depicts 2 walls, one an 8″ concrete block wall and the other a steel stud and gypsum board wall. Eachhasthe same single STC value of 47 but they each have different sound isolation characteristics.</span>
                                                <br />
                                                <br /><span>The drywall stud wall does not perform as well at the low frequencies compared to the block wall. While the block wall does not provide as high isolation at the mid range frequencies it maintains a fairly uniform increase in performance across the entire frequency spectrum. Note the drop in performance of the drywall partition between 2000 Hz and 4000 Hz. This drop is known as the “coincidence dip” which reflects the resonant frequency of the wall assembly.</span>
                                                <br />
                                                <br /><span><img className="img-fluid size-full wp-image-1894 alignright" src="https://ye7zs22zd242wmzxo41cj7b5-wpengine.netdna-ssl.com/assets/resonant.gif" alt="resonant" width="306" height="301" />Rather than rely on a single number STC rating, it can often be useful and more effective to look at the total performance data and select a wall, floor or ceiling assembly that will provide the most effective performance based on the anticipated sound generated through the structure. Different structures or construction assemblies have different resonant frequencies.</span></p>
                                                <p>Check out our Acoustics 101 section for more information or even our<Link to="/soundproofingtips"><span style={{textdecoration: "underline"}}>Soundproofing Tips</span></Link>if you’re encountering any soundproofing problems.</p>
                                            </div>
                                        </div>
                                        <div className="click-expand">
                                            <h4 className="ce-header">Sound Transmission className (STC)</h4>
                                            <div className="ce-body">
                                                <p>The <a href="https://www.acousticalsurfaces.com/soundproofing_tips/html/Noise_Transmission.htm" target="_blank" rel="noopener noreferrer"><span style={{textdecoration: "underline"}}>Sound Transmission className</span></a> is a single number rating of the effectiveness of a material or construction assembly to retard the transmission of airborne sound. The sound transmission loss between the source and receiving rooms are plotted on a graph by frequency and sound level in decibels. The STC curve is a sliding contour that is fitted to the performance data plotted in a manner that will allow no more than 32 deficiencies below the appropriate contour. The maximum deficiency at any given frequency shall not exceed 8 decibels.</p>
                                                <p><img className="img-fluid size-full wp-image-1754 aligncenter" src="https://ye7zs22zd242wmzxo41cj7b5-wpengine.netdna-ssl.com/assets/23_pic.gif" alt="Sound Transmission className Graph" /></p>
                                                <p>Once the appropriate contour has been selected the STC is determined by the decibel value of the vertical scale at 500 Hz. The STC is expressed as a single STC number (Example STC 32)</p>
                                                <p>Note that the STC contour is similar to the inverse of the equal loudness contour, insofar as it discounts the lower frequency sounds to reflect how our ears perceive the lower frequency sounds.</p>
                                            </div>
                                        </div>
                                        <div className="click-expand">
                                            <h4 className="ce-header">Sound Isolation</h4>
                                            <div className="ce-body">
                                                <p>Airborne Sound can be isolated by containing the sound from intruding beyond the source area by blocking it’s potential transmission. Sound isolation can be accomplished by creating appropriate barriers and reducing the reflected sounds in the source area by acoustical absorption within the source area.</p>
                                            </div>
                                        </div>
                                    </section>
                                </article>
                                <hr />
                            </div>
                        </section>
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textalign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms" target="_blank" rel="noopener noreferrer" />
                                <div className="butn"><Link to="/bs-rooms-guide">Download eBook</Link></div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textalign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems" target="_blank" rel="noopener noreferrer" />
                                <div className="butn"><Link to="/noise-problems-guide">Download eBook</Link></div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textalign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={Cadlibrary} alt="CAD Library" target="_blank" rel="noopener noreferrer" />
                                <div className="butn"><Link to="/cad-library-guide">Access Product Cad Files</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acoustics101;