import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Healthcare1 from '../../images/inner/soundscreen-white-noise-machine-200x200.webp';
import Healthcare2 from '../../images/inner/polymax-beige-white-panels-200x173.webp';
import Healthcare3 from '../../images/inner/doorseal11-200x200.webp';
import Healthcare4 from '../../images/inner/fabric-wrapped-panel-edge-details-lg-200x200.webp';
import Healthcare5 from '../../images/inner/sound-silencer-panels-200x200.webp';
import Healthcare6 from '../../images/inner/ultra-san1-200x155.webp';
import Healthcare7 from '../../images/inner/silk-metal-tiles-sm1-200x200.webp';
import Mobsidebar from '../mobile-sidebar';
import './healthcare.css';
import ScrollToTopButton from '../ScrollButton';
function healthcare() {
    return (
      
    <>
    <Helmet>
    <title>Soundproofing for Health Care & Hospital Environments</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Hospital Noise Reduction System" />
    <meta property="og:description" content="We offer various products like hospital ceiling tiles to reduce sound pressure in health care environments" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/polymax-beige-white-panels-200x173.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/healthcare" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/healthcare" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/healthcare" hreflang="en-in" />
    </Helmet>
    <Header />
    <section className="content" id="healthcare">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Soundproofing for Health Care Environments</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>Health care settings are often full of activity and bustling with people at all hours of the day and night. A good hospital noise reduction system brings relief and calm to patients during their visits or convalescence. We offer various products like hospital ceiling tiles to reduce sound pressure in health care environments and make patient stays more comfortable.</p>
                                    <p>Acoustical Surfaces are the experts at noise reduction in hospitals, clinics, and other health care environments. Our soundproofing and noise control products are ideal for reducing reverberation in patient rooms and common areas, as well as insulating noise between patient rooms, exam rooms, and consultation rooms. Installing hospital ceiling tiles and acoustical wall panels, sound barriers, and soundproof doors bring form and function together to reduce noise without sacrificing any design elements. All of our products meet className A fire ratings. We also offer white noise machines that help to mask the intelligibility of confidential conversations.</p>
                                    <p>We have been providing hospitals, clinics, and doctors offices with high quality noise control products for years. For a sample of our products, see the list above. <Link to="/contact-us">Contact us</Link> today for help solving your noise problem!</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>Healthcare Soundproofing Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Healthcare1} alt="White Noise Machine" />
                                </Link>
                                <h5>White Noise Machine</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Healthcare2} alt="Polymax Panels" />
                                </Link>
                                <h5>PolyMax Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Healthcare3} alt="Door Seal Kits" />
                                </Link>
                                <h5>Door Seal Kits</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Healthcare4} alt="Fabric Wrapped Fiberglass Panels" />
                                </Link>
                                <h5>Fabric Wrapped Fiberglass Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Healthcare5} alt="Sound Silencer Panels" />
                                </Link>
                                <h5>Sound Silencer Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Healthcare6} alt="Ultra-San Panels and Ceiling Tiles" />
                                </Link>
                                <h5>Ultra-San Panels and Ceiling Tiles</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Healthcare7} alt="Silk Metal" />
                                </Link>
                                <h5>Silk Metal</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default healthcare; 