import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Sports1 from '../../images/inner/soundproofing_baffle_lg2-200x200.webp';
import Sports2 from '../../images/inner/pool-200x200.webp';
import Sports3 from '../../images/inner/sound-silencer-panels-200x200.webp';
import Sports4 from '../../images/inner/acousti-banner-restaurant-200x200.webp';
import Sports5 from '../../images/inner/wallmate-sports-arena-200x200.webp';
import './sports-venues.css';
import ScrollToTopButton from '../ScrollButton';
function sports() {
    return (
      
    <>
    <Helmet>
    <title>Sports Hall Acoustics - clouds and baffles To reduce noise and provide acoustical control</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Soundproofing Sports and Entertainment Venues" />
    <meta property="og:description" content="Our expertly trained acoustic technicians have worldwide experience in designing systems to reduce reverb times and prevent excessive background noise levels." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/pool-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/sports-venues" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/sports-venues" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/sports-venues" />
    </Helmet>
    <Header />
    <section className="content" id="venues">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Soundproofing Sports and Entertainment Venues</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>These large spaces often produce echoes and reverberations that diminish sound quality and impair the enjoyment of fans. With over 35 years of experience in solving venue acoustical problems, we are the top choice for designing and installing high-quality acoustical sound solutions.</p>
                                    <p>Our expertly trained acoustic technicians have worldwide experience in designing systems to reduce reverb times and prevent excessive background noise levels. For interior arenas and sports complexes, we offer a wide range of wall panels that can be manufactured to accommodate any size and shape area. To reduce noise and reverberation and provide acoustical control, clouds and baffles are available in many different materials and configurations to meet the needs of any space. We can also control exterior noise with portable or permanent barriers that block sound and absorb reverberation, which prevents noise from intruding on other areas.</p>
                                    <p>In sports and entertainment applications, the only company to completely trust for excellent acoustic solutions is Acoustical Surfaces. Our outstanding products will improve the sound quality in concert halls, sports stadiums, multi-use arenas, auditoriums, and theaters. The list below includes just some of our outstanding acoustical products that improve entertainment and sports venue acoustics. Please <Link to="/contact-us">contact us</Link> for complete details.</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>Sports Venues Soundproofing Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Sports1} alt="Echo Eliminator Hanging Baffles" />
                                </Link>
                                <h5>Echo Eliminator Hanging Baffles</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Sports2} alt="Sailcloth Baffles" />
                                </Link>
                                <h5>Sailcloth Baffles</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Sports3} alt="SOund Silencer" />
                                </Link>
                                <h5>Sound Silencer</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Sports4} alt="Acoustic Banners" />
                                </Link>
                                <h5>Acousti-Banners</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Sports5} alt="WallMate" />
                                </Link>
                                <h5>WallMate</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default sports; 