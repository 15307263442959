import React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Card1 from '../../images/FS-mm/fs11.webp';
import Card2 from '../../images/FS-mm/fs12.webp';
import Sliderfoamstopmm from './slider-foamstop-mm';
import Sliderfoamstopmmflat from './slider-foamstop-mm-flat';
import Sliderfoamstopmmpyramid from './slider-foamstop-mm-pyramiddesign';
import Sliderfoamstopmmegg from './slider-foamstop-mm-eggcrate';
import Sliderfoamstopmmcylinder from './slider-foamstop-mm-cylinder';
import Sliderfoamstopmmbass from './slider-foamstop-mm-bass';
import './style-foamstop-mm.css';
import ScrollToTopButton from '../ScrollButton';
function foamstopmm() {
    return (
      
    <>
    <Helmet>
    <title>Melamine foam sheet for better acoustic and soundproofing solutions</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Soundproof Melamine foam" />
    <meta property="og:description" content="foam-like material consisting of a formaldehyde-melamine-sodium bisulfite copolymer."/>
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/FS-mm/fs6.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/foamstop-mm" hreflang="en-ae" />    
    <link rel="alternate" href="https://www.acousticalsurfaces.in/foamstop-mm" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/foamstop-mm" />
    </Helmet>
    <Header />
    <section className="content" id="foamstopmm">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
	                    <div className="col-sm-12 heading-fsorb">				
	                        <h1><span>FOAM STOP<sup>TM</sup></span> MELAMINE FOAMS</h1>
	                        <p>	FOAM STOP<sup>TM</sup> Melamine foam is a foam-like material consisting of a formaldehyde-melamine-sodium bisulfite copolymer. Meaning it is the fusion different components at high temperatures and pressure. 
                            <br /><br />
                            The process of its manufacturing makes it perfect for using as an ideal acoustic foam.The foam has the ability to absorb higher amount of sound presence along with better thermal insulation with negligible weight.
                            </p>
	                        <div className="demo-cont">
	                            <Sliderfoamstopmm />
                            </div>
	                    </div>
	                    <div className="col-sm-12">
                            <div className="features">
	                            <h1>Uses of <span style={{color: "#f13849"}}>FOAM STOP<sup>TM</sup></span> Soundwave</h1>
	                            <hr />
	                            <div className="row pad ">	
		                            <div className="col-md-6 col-sm-12">
		                                <ul>
	    	                                <li>For better acoustic and soundproofing solutions</li>
    		                                <li>Enhancement of looks and style</li>
		                                    <li>Thermal and fire resistant insulation</li>
		                                    <li>Sound absorption</li>
                                            <li>Ventilation as well as HVAC systems</li>
    	                                </ul>
	                                </div>
	                                <div className="col-md-6 col-sm-12 d-none d-md-block">
		                                <ul>
	    	                                <li>Manufacturing of vehicles including aerospace</li>
    		                                <li>Busses, utility vehicles, rails, ships, aircrafts, etc.</li>
		                                    <li>Fire resistance solution</li>
		                                    <li>Thermal insulation</li>
                                            <li>Soundproofing</li>
		                                </ul>
	                                </div>
	                            </div>
                            </div>
	                    </div>
                        <br /><br />
	                    <div className="col-sm-12 more">
                            <h1> More Products </h1>
	                        <div className="line"></div>
	                    </div>
	                    <div className="accordion-started accordion-bral panels row">
		                    <div className="col-sm-12 col-md-12 col-lg-12 ">
		                        <label className="ac-label" htmlFor="ac-1">
                                    <b>FOAM STOP <sup>TM</sup></b><br />PLAIN (FLAT) DESIGN<i></i>
                                </label>
	    	                    <div className="article ac-content">
			                        <Sliderfoamstopmmflat />
				                    <div className="col-sm-12">		
				                        <div className="row"> 
                	                        <h1><span>FOAM STOP™</span> Melamine Plain (Flat) features</h1>
                                        </div>
					                    <div className="row">
		                                    <div className="col-md-6 col-sm-12 panelfeatures">
                                                <ul>
		                                            <li>Sound Absorption</li>
		                                            <li>Thermal Insulation</li>
		                                            <li>Fire Resistance</li>
		                                            <li>Chemical Resistance</li>
		                                        </ul>
				                            </div>
		                                    <div className="col-md-6 col-sm-12 d-none d-md-block panelfeatures">
                                                <ul>
		                                            <li>Eco Friendly</li>
		                                            <li>Light Weight</li>
		                                            <li>Temperature endurance</li>
	                                            </ul>
		    		                        </div>
	                                    </div>				
		                            </div>		
				                    <br /><br />		
	                                <div className="col-sm-12">
				                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#menu3">DATASHEET</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
	                                                    <tr>
                                                            <th scope="row">Core</th>
                                                            <td>Open Cell Melamine Acoustical Foam</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Thickness (mm)</th>
                                                            <td>25mm, 50 mm & custom thicknesses are also available</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Sizes (mm)</th>
                                                            <td>600mm x 600mm, 1200mm x 1200mm, 1000mm x 2000mm & also custom sizes </td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Acoustic Properties</th>
                                                            <td>NRC 0.66 – 0.96 ( varies with thickness and mounting )</td>
                                                        </tr>
                            	                        <tr>
                                                            <th scope="row">Density</th>
                                                            <td>1lbs/cub.ft and 2 lbs/cub.ft</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Colours</th>
                                                            <td>Charcoal Grey</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Flamability</th>
                                                            <td>Meets UL94 HF -1 Standard</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Mounting type</th>
                                                            <td>Type A</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Installation</th>
                                                            <td>ASI  S.T.O.P Noise Acoustical Adhesive or any construction adhesive</td>
                                                        </tr>
	                                                </tbody>
	                                            </table>
		                                    </div>
                                            <div id="menu1" className="container tab-pane fade"><br />
                		                        <h3>Sound Absorption Coefficients</h3>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Thickness</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">25mm</th>
                                                            <td>0.06</td>
                                                            <td>0.19</td>
                                                            <td>0.47</td>
                                                            <td>1.05</td>
                                                            <td>0.94</td>
                                                            <td>0.88</td>
                                                            <td>0.66</td>
                                                        </tr>
                                	                    <tr>
                                                            <th scope="row">50mm</th>
                                                            <td>0.08</td>
                                                            <td>0.63</td>
                                                            <td>1.11</td>
                                                            <td>1.07</td>
                                                            <td>1.04</td>
                                                            <td>0.97</td>
                                                            <td>0.96</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
		                                    </div>
                    	                    <div id="menu3" className="container tab-pane fade"><br />
	                                            <br />
                                                <h3>FOAM STOP : Datasheet</h3>
		                                        <br />
    		                                    <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/melamine/mm-plain.pdf" className="brochure">Download</a>
	                                        </div>
                                        </div>
                                    </div>			
			                    </div>
		                    </div>
                            <div className="line"></div>
		                    <div className="col-sm-12 col-md-12 col-lg-12">
		                        <label className="ac-label" htmlFor="ac-2">
                                    <b>FOAM STOP <sup>TM</sup></b><br /> PYRAMID DESIGN<i></i>
                                </label>
				                <div className="article ac-content">
				                    <Sliderfoamstopmmpyramid />
			                        <p>Pyramids are yet another one of our many acoustic foam panels. They are 2’x2′ squares of open cell className A fire rated melamine foam. These pyramid acoustic foam panels combine 
                                    to absorb echoes within a space, collapsing perimeter background noise to deliver greater clarity to original sound.</p>
    		                        <div className="col-sm-12">		
				                        <div className="row"> 
                	                        <h1><span>FOAM STOP™</span> Melamine Pyramid features</h1>
                                        </div>
	                                    <div className="row">
		                                    <div className="col-md-6 col-sm-12 panelfeatures">
            		                            <ul>
		                                            <li>Sound Absorption</li>
		                                            <li>Thermal Insulation</li>
		                                            <li>Fire Resistance</li>
		                                            <li>Chemical Resistance</li>
		                                        </ul>
				                            </div>
		                                    <div className="col-md-6 col-sm-12 d-none d-md-block panelfeatures">
            		                            <ul>
		                                            <li>Eco Friendly</li>
		                                            <li>Light Weight</li>
		                                            <li>Temperature endurance</li>
            		                            </ul>
				                            </div>
	                                    </div>				
		                            </div>	
			                        <br /> <br />
			                        <div className="col-sm-12">
			                            <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#cp-home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cp-menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cp-menu3">DATASHEET</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="cp-home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
	                                                    <tr>
                                                            <th scope="row">Core</th>
                                                            <td>Open Cell Melamine Acoustical Foam</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Thickness (mm)</th>
                                                            <td>15mm, 20mm, 25mm, 35mm, 50 mm; custom thicknesses are also available</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Sizes (mm)</th>
                                                            <td>600mm x 600mm, 1200mm x 1200mm, 1000mm x 2000mm & also custom sizes</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Acoustic Properties</th>
                                                            <td>NRC 0.42 – 0.96 ( varies with thickness and mounting )</td>
                                                        </tr>
    	                                                <tr>
                                                            <th scope="row">Density</th>
                                                            <td>1lbs/cub.ft, 2lbs/cub.ft</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Colours</th>
                                                            <td>Charcoal Grey</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Flamability</th>
                                                            <td>Meets UL94 HF -1 Standard</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Mounting type</th>
                                                            <td>Type A</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Installation</th>
                                                            <td>ASI  S.T.O.P Noise Acoustical Adhesive or any construction adhesive</td>
                                                        </tr>
	                                                </tbody>
                                                </table>
		                                    </div>
                                            <div id="cp-menu1" className="container tab-pane fade"><br />
                                                <h3>Sound Absorption Coefficients</h3>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Thickness</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">15mm</th>
                                                            <td>0.02</td>
                                                            <td>0.10</td>
                                                            <td>0.20</td>
                                                            <td>0.51</td>
                                                            <td>0.86</td>
                                                            <td>0.86</td>
                                                            <td>0.42</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">20mm</th>
                                                            <td>0.03</td>
                                                            <td>0.16</td>
                                                            <td>0.34</td>
                                                            <td>0.77</td>
                                                            <td>0.92</td>
                                                            <td>0.87</td>
                                                            <td>0.55</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">25mm</th>
                                                            <td>0.06</td>
                                                            <td>0.19</td> 
                                                            <td>0.47</td>
                                                            <td>1.05</td>
                                                            <td>0.94</td>
                                                            <td>0.88</td>
                                                            <td>0.66</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">35mm</th>
                                                            <td>0.03</td>
                                                            <td>0.32</td>
                                                            <td>0.88</td>
                                                            <td>1.09</td>
                                                            <td>0.98</td>
                                                            <td>0.93</td>
                                                            <td>0.82</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">50mm</th>
                                                            <td>0.08</td>
                                                            <td>0.63</td>
                                                            <td>1.11</td>
                                                            <td>1.07</td>
                                                            <td>1.04</td>
                                                            <td>0.97</td>
                                                            <td>0.96</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
		                                    </div>
    	                                    <div id="cp-menu3" className="container tab-pane fade"><br />
                                            <br />
                                                <h3>FOAM STOP: Datasheet</h3>
		                                        <br />
		                                        <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/melamine/mm-pyramid.pdf" className="brochure">Download</a>
                                            </div>
                                        </div>
	                                </div>			
	                            </div>
	                        </div>
                            <div className="line"></div>
	                        <div className="col-sm-12 col-md-12 col-lg-12">
		                        <label className="ac-label" htmlFor="ac-3">
                                    <b>FOAM STOP <sup>TM</sup></b><br /> EGGCRATE DESIGN<i></i>
                                </label>
    	                        <div className="article ac-content">
	                                <Sliderfoamstopmmegg />
			                        <p>FOAM STOP<sup>TM</sup> eggcrate panel are yet another one of our many acoustic foam panels. They are 2’x2′ squares of open cell className A fire rated melamine foam. These acoustic foam panels combine to absorb echoes
                                    within a space, collapsing perimeter background noise to deliver greater clarity to original sound.</p>	
			                        <div className="col-sm-12">		
				                        <div className="row"> 
                	                        <h1><span>FOAM STOP™</span> Melamine Eggcrate features</h1>
                                        </div>
				                        <div className="row">
		                                    <div className="col-md-6 col-sm-12 panelfeatures">
                		                        <ul>
		                                            <li>Sound Absorption</li>
		                                            <li>Thermal Insulation</li>
		                                            <li>Fire Resistance</li>
		                                            <li>Chemical Resistance</li>
		                                        </ul>
				                            </div>
		                                    <div className="col-md-6 col-sm-12 d-none d-md-block panelfeatures">
    		                                    <ul>
		                                            <li>Eco Friendly</li>
		                                            <li>Light Weight</li>
		                                            <li>Temperature endurance</li>
	        		                            </ul>
				                            </div>
	                                    </div>				
		                            </div>	
                                    <br /><br />
			                        <div className="col-sm-12">
			                            <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#cb-home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cb-menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cb-menu3">DATASHEET</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="cb-home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
	                                                    <tr>
                                                            <th scope="row">Core</th>
                                                            <td>Open Cell Melamine Acoustical Foam</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Dimensions (mm)</th>
                                                            <td>D200mm, custom sizes are also available</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Length (mm)</th>
                                                            <td>950mm, custom sizes are also also available</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Acoustic Properties</th>
                                                            <td>NRC 0.55-0.90 ( varies with thickness and mounting )</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Density </th>
                                                            <td>1lbs/cub.ft  and 2 lbs/cub.ft</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Colours</th>
                                                            <td>Charcoal Grey</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Flamability</th>
                                                            <td>Meets UL94 HF -1 Standard</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Mounting type</th>
                                                            <td>Type A</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Installation</th>
                                                            <td>ASI  S.T.O.P Noise Acoustical Adhesive or any construction adhesive</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
	                                        </div>
                                            <div id="cb-menu1" className="container tab-pane fade"><br />
                                                <h3>Sound Absorption Coefficients</h3>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Thickness</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">0.8 cyl/m2</th>
                                                            <td>0.10</td>
                                                            <td>0.31</td>
                                                            <td>0.60</td>
                                                            <td>0.68</td>
                                                            <td>0.58</td>
                                                            <td>0.15</td>
                                                            <td>0.55</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">1.1 cyl/m2</th>
                                                            <td>0.15</td>
                                                            <td>0.40</td>
                                                            <td>0.80</td>
                                                            <td>1.00</td>
                                                            <td>1.00</td>
                                                            <td>1.00</td>
                                                            <td>0.80</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
		                                    </div>
    	                                    <div id="cb-menu3" className="container tab-pane fade"><br />
                                            <br />
                                                <h3>FOAM STOP : Datasheet</h3>
		                                        <br />
			                                    <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/melamine/mm-eggcrate.pdf" className="brochure">Download</a>
                                            </div>
                                        </div>
	                                </div>			
	                            </div>
	                        </div>
                            <div className="line"></div>
                            <div className="col-sm-12 col-md-12 col-lg-12">
		                        <label className="ac-label" htmlFor="ac-4">
                                    <b>FOAM STOP <sup>TM</sup></b><br />CYLINDRICAL DESIGN<i></i>
                                </label>
			                    <div className="article ac-content">
			                        <Sliderfoamstopmmcylinder />
				                    <p>FOAM STOP<sup>TM</sup>  cylinder is a sound absorber with a wide range of absorption (wide band), especially appreciated by architects and decorators, it is a highly versatile acoustic panel with an extraordinary acoustic performance. They can be installed in vertical or horizontal, glued or suspended, 
                                    it is possible to combine different lengths and diameters creating an unique space with great aesthetic harmony.</p>
				                    <div className="col-sm-12">		
				                        <div className="row"> 
                	                        <h1><span>FOAM STOP™</span> Melamine Cylindrical features</h1>
                                        </div>
					                    <div className="row">
		                                    <div className="col-md-6 col-sm-12 panelfeatures">
                        	                    <ul>
    		                                        <li>Sound Absorption</li>
		                                            <li>Thermal Insulation</li>
		                                            <li>Fire Resistance</li>
		                                            <li>Chemical Resistance</li>
		                                        </ul>
	            	                        </div>
		                                    <div className="col-md-6 col-sm-12 d-none d-md-block panelfeatures">
                    		                    <ul>
		                                            <li>Eco Friendly</li>
		                                            <li>Light Weight</li>
		                                            <li>Temperature endurance</li>
	                                            </ul>
		                                    </div>
	                                    </div>				
		                            </div>	
				                    <h2>Product Details</h2>
                                    <br />
	                                <div className="col-sm-12">
				                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#dp-home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#dp-menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#dp-menu3">DATASHEET</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="dp-home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Core</th>
                                                            <td>Open Cell Melamine Acoustical Foam</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Thickness (mm)</th>
                                                            <td>Custom thickness</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Sizes (mm)</th>
                                                            <td>300mm x 300mm x 600mm, 350mm x 300mm x 600mm & also custom sizes</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Acoustic Properties</th>
                                                            <td>NRC 0.75 ( varies with thicknesses and mounting )</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Density</th>
                                                            <td>1lbs/cub.ft and 2 lbs/cub.ft</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Colour</th>
                                                            <td>Charcoal Grey</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Flamability</th>
                                                            <td>Meets UL94 HF -1 Standard</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Mounting type</th>
                                                            <td>Type A</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Installation</th>
                                                            <td>ASI  S.T.O.P Noise Acoustical Adhesive or any construction adhesive</td>
                                                        </tr>
	                                                </tbody>
	                                            </table>
		                                    </div>
                                            <div id="dp-menu1" className="container tab-pane fade"><br />
                		                        <h3>Sound Absorption Coefficients</h3>
                    		                    <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Thickness</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">BS-1</th>
                                                            <td>0.46</td>
                                                            <td>1.00</td>
                                                            <td>0.78</td>
                                                            <td>0.64</td>
                                                            <td>0.63</td>
                                                            <td>0.70</td>
                                                            <td>0.75</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">BS-2</th>
                                                            <td>0.90</td>
                                                            <td>0.84</td>
                                                            <td>0.80</td>
                                                            <td>0.64</td>
                                                            <td>0.62</td>
                                                            <td>0.70</td>
                                                            <td>0.75</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
		                                    </div>
                    	                    <div id="dp-menu3" className="container tab-pane fade"><br />
                                                <br />
                                                <h3>FOAM STOP : Datasheet</h3>
		                                        <br />
		                                        <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/melamine/mm-cylinder.pdf" className="brochure">Download</a>
                                            </div>    
                                        </div>
				                    </div>			
			                    </div>
		                    </div>
                            <div className="line"></div>
		                    <div className="col-sm-12 col-md-12 col-lg-12">
		                        <label className="ac-label" htmlFor="ac-4">
                                    <b>FOAM STOP <sup>TM</sup></b><br />BASSTRAP DESIGN<i></i>
                                </label>
				                <div className="article ac-content">
				                    <Sliderfoamstopmmbass />
	    			                <p> The unique trihedral geometry of our FOAM STOP<sup>TM</sup>  Corner Bass Traps provides exceptional acoustical performance,
                                        maximizes installation and design versatility and sparks an immediate visual interest.</p>	
					                <div className="col-sm-12">		
				                        <div className="row"> 
                	                        <h1><span>FOAM STOP™</span> Melamine Basstrap features</h1>
                                        </div>
						                <div className="row">
		                                    <div className="col-md-6 col-sm-12 panelfeatures">
                        		                <ul>
		                                            <li>Sound Absorption</li>
		                                            <li>Thermal Insulation</li>
		                                            <li>Fire Resistance</li>
		                                            <li>Chemical Resistance</li>
		                                        </ul>
		        		                    </div>
		                                    <div className="col-md-6 col-sm-12 d-none d-md-block panelfeatures">
                            	                <ul>
		                                            <li>Eco Friendly</li>
		                                            <li>Light Weight</li>
		                                            <li>Temperature endurance</li>
	                                            </ul>
				                            </div>
	                                    </div>				
		                            </div>	
					                <br />
                                    <div className="col-sm-12 alu-cards">
                                        <div className="row"> 
                                            <div className="col-12 col-sm-6 card" style={{width: "18rem"}}>
                                                <img className="card-img-top" src={Card1} alt="Card cap1" />
                                                <div className="card-body">
                                                    <p className="card-text text-center"><span style={{color: "black"}}>BASSTRAP</span> - STRIPED</p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 card" style={{width: "18rem"}}>
                                                <img className="card-img-top" src={Card2} alt="Card cap2" />
                                                <div className="card-body">
                                                    <p className="card-text text-center"><span style={{color: "black"}}>BASSTRAP</span> - PLAIN</p>
                                                </div>
                                            </div>	
                                        </div>
                                    </div>
                                    <br /><br />
				                    <div className="col-sm-12">
				                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#egg-home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#egg-menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#egg-menu3">DATASHEET</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="egg-home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
	                                                    <tr>
                                                            <th scope="row">Core</th>
                                                            <td>Open Cell Melamine Acoustical Foam</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Thickness (mm)</th>
                                                            <td>35mm, 50mm, custom thicknesses are also available</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Sizes (mm)</th>
                                                            <td>600mm x 600mm, 1200mm x 1200mm, 1000mm x 2000mm & also custom sizes</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Acoustic Properties</th>
                                                            <td>NRC 0.82 – 0.96 ( varies with thickness and mounting )</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Density</th>
                                                            <td>1lbs/cub.ft, 2 lbs/cub.ft</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Colours</th>
                                                            <td>Charcoal Grey</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Flamability</th>
                                                            <td>Meets UL94 HF -1 Standard</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Mounting type</th>
                                                            <td>Type A</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Installation</th>
                                                            <td>ASI  S.T.O.P Noise Acoustical Adhesive or any construction adhesive</td>
                                                        </tr>
	                                                </tbody>
	                                            </table>
		                                    </div>
                                            <div id="egg-menu1" className="container tab-pane fade"><br />
                		                        <h3>Sound Absorption Coefficients</h3>
                		                        <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Thickness</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">35mm</th>
                                                            <td>0.03</td>
                                                            <td>0.32</td>
                                                            <td>0.88</td>
                                                            <td>1.09</td>
                                                            <td>0.98</td>
                                                            <td>0.93</td>
                                                            <td>0.82</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">50mm</th>
                                                            <td>0.08</td>
                                                            <td>0.63</td>
                                                            <td>1.11</td>
                                                            <td>1.07</td>
                                                            <td>1.04</td>
                                                            <td>0.97</td>
                                                            <td>0.96</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
		                                    </div>
                	                        <div id="egg-menu3" className="container tab-pane fade"><br />
                                                <br />
                                                <h3>FOAM STOP : Datasheet</h3>
		                                        <br />
		                                        <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/melamine/mm-basstrap.pdf" className="brochure">Download</a>
                                            </div>
                                        </div>
                                    </div>			
		                        </div>
	                        </div>
	                    </div>
	                    <div className="line"></div>			
	                </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default foamstopmm; 