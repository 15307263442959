import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import './literature.css';
import Commonnoiseprob from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import Soundingrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Soundfixespart1 from '../../images/additional-resources/soundproofing-fixes-1-3d-cover@01x.png';
import Soundfixespart2 from '../../images/additional-resources/Easy-soundproofing-fixes-part-2.png';
import CADCSI from '../../images/additional-resources/ebook-cad-revit.webp';
import ScrollToTopButton from '../ScrollButton';
function literature() {
    return (
      
    <>
    <Helmet>
    <title>Resources - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta name="robots" content="noindex, follow" />
    <meta property="og:title" content="Soundproofing Tips for Soundproofing Problems" />
    <meta property="og:description" content="Understanding the Nature of Sound, Acoustics and Noise Control" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/additional-resources/Common-Noise-Problems-3D01x.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/literature" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/literature" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/literature" />
    </Helmet>
    <Header />
    <section className="content">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Resources</h3>
                        <div className="row resources literatures">
                            <div className="col-lg-4 col-sm-6">
                                <h5>Solutions to Common Noise Problems</h5>
                                <img className="hvr-grow" src={Commonnoiseprob} alt="Common Noise Problems" target="_blank" />
                                <div className="butn">
                                    <Link to="/noise-problems-guide">Download eBook</Link>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <h5>Creating Better-Sounding Rooms</h5>
                                <img className="hvr-grow" src={Soundingrooms} alt="Create Better Sounding Rooms" target="_blank" />
                                <div className="butn">
                                    <Link to="/bs-rooms-guide">Download eBook</Link>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <h5>Easy Soundproofing Fixes - Part 1</h5>
                                <img className="hvr-grow" src={Soundfixespart1} alt="Soundproofing fixes Part 1" target="_blank" />
                                <div className="butn">
                                    <Link to="/soundproofing-fixes_1">Download eBook</Link>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <h5>Easy Soundproofing Fixes - Part 2</h5>
                                <img className="hvr-grow" src={Soundfixespart2} alt="Soundproofing fixes Part 2" target="_blank" />
                                <div className="butn">
                                    <Link to="/soundproofing-fixes_2">Download eBook</Link>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <h5><br />CAD, CSI, & Revit Library</h5>
                                <img className="hvr-grow" src={CADCSI} alt="CAD CSI Revit Library" target="_blank" />
                                <div className="butn">
                                    <Link to="/cad-library-guide">Access Product Cad Files</Link>
                                </div>
                            </div>
                            <div className="line"></div>
                            <div className="col-sm-12">
                                <h5>Videos</h5>
                            </div>                        
                            <div className="col-lg-6 col-sm-12">
                                <iframe src="https://www.youtube.com/embed/GeIwcCK5UKk" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen title='acoustic'></iframe>
                                <div className="video-tag">
                                    <p>What You Need to Know About Acoustics</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <iframe src="https://www.youtube.com/embed/n05y1lAY8j0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen title='sound absorbing'></iframe>
                                <div className="video-tag">
                                    <p>An Acoustic Primer</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <iframe src="https://www.youtube.com/embed/__WvZ_vbkEA" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen title='soundproofing'></iframe>
                                <div className="video-tag">
                                    <p>What is Soundproofing</p>
                                </div>
                            </div>
                            <hr />
                            <div className="col-lg-6 col-sm-12">
                                <iframe src="https://www.youtube.com/embed/Tap5WBfJZBM" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen title='sound treatment'></iframe>
                                <div className="video-tag">
                                    <p>How Sound Works In Rooms</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <iframe src="https://www.youtube.com/embed/PP9fg-cvxgU" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen title='sound isolation'></iframe>
                                <div className="video-tag">
                                    <p>Acoustic Panels – What & Where</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <iframe src="https://www.youtube.com/embed/NQSjgOl5fVU" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen title='acoustic solutions'></iframe>
                                <div className="video-tag">
                                    <p>Your Brain on Sound</p>
                                </div>
                            </div>
                            <div className="line"></div>
                            <div className="col-lg-6 col-sm-12">
                                <h2>Additional Resources:</h2>
                                <ul className="ar-list">
                                    <li><Link to="/acoustics101"><strong>Acoustics 101</strong> – Understanding the Nature of Sound, Acoustics and Noise Control</Link></li>
                                    <li><Link to="/soundproofingtips">Soundproofing Tips for Soundproofing Problems</Link></li>
                                    <li><Link to="/links">Related Links</Link></li>
                                    <li><a href="www.acousticalsurfaces.com/news/" target="_blank" rel="noopener noreferrer">News</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-6 col-sm-12 d-none">
                                <h2>Other Printed Resources:</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default literature; 
