import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Pvc1 from '../../images/inner/pool.webp';
import Pvc2 from '../../images/inner/general04.webp';
import Pvc3 from '../../images/inner/general05.webp';
import Pvc4 from '../../images/inner/acoustical-baffles-on-wall.webp';
import Pvc5 from '../../images/inner/baffle-hardware.webp';
import './pvc-sailcloth-baffles.css';
import ScrollToTopButton from '../ScrollButton';
function pvc() {
    return (
      
    <>
    <Helmet>
    <title>Soundproofing PVC Sailcloth Baffles - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Noise S.T.O.P.™ Fiberglass Baffle" />
    <meta property="og:description" content="Sailcloth & Vinyl Encapsulated Acoustical Baffles" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/pool.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/pvc-sailcloth-baffles" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/pvc-sailcloth-baffles" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/pvc-sailcloth-baffles" />
    </Helmet>
    <Header />
    <section className="content" id="pvc">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Noise S.T.O.P.™ Fiberglass Baffle</h1>
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/pool.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Pvc1} alt="PVC Sailcloth Acoustics1" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Sailcloth & Vinyl Encapsulated Acoustical Baffles</h2>
                                <ul style={{marginleft: "1em"}}>
                                    <li>Cost Effective</li>
                                    <li>High Performance</li>
                                    <li>Architecturally Decorative</li>
                                    <li>Custom Engineered Manufactured</li>
                                </ul>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/fiberglass_bf.pdf">Product Specs</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Product Specs</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>Material</th>
                                            <td>Fiberglass core encapsulated in Polyvinyl Chloride Film or Sailcloth. </td>
                                        </tr>
                                        <tr>
                                            <th>Pattern</th>
                                            <td>Plain – Type A or Type B mounting.</td>
                                        </tr>
                                        <tr>
                                            <th>Features</th>
                                            <td>Cost effective, lightweight, high acoustical performance, moisture and chemical resistant.</td>
                                        </tr>
                                        <tr>
                                            <th>Applications</th>
                                            <td>Any large area where noise and reverberation needs to be reduced. Gymnasiums, community centers, sports arenas, lunch rooms, swimming pools, factories, animal hospitals, food processing plants, large open plan areas. </td>
                                        </tr>
                                        <tr>
                                            <th>Thickness</th>
                                            <td>1″, 2″</td>
                                        </tr>
                                        <tr>
                                            <th>Sizes</th>
                                            <td>Nominal 2′ × 4′ </td>
                                        </tr>
                                        <tr>
                                            <th>Core Density</th>
                                            <td>1#, 1.65#, 2.25# & 3#/cub. ft.</td>
                                        </tr>
                                        <tr>
                                            <th>Color</th>
                                            <td>PVC Film – 9 Colors; Sailcloth – 9 colors.</td>
                                        </tr>
                                        <tr>
                                            <th>Flammability</th>
                                            <td>className A Sailcloth ASTM E84, F/S 15 S/D 30. PVC, F/S 25 S/D 30. </td>
                                        </tr>
                                        <tr>
                                            <th>Installation</th>
                                            <td>Grommets, Direct Hang, Spring Clips, Tie Wraps </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <img className="example-image img-fluid" src={Pvc2} alt="PVC Sailcloth Acoustics2" />
                                <h4>Mounting Type “A”</h4>
                                <p>Slight puckering on Fabric Face</p>
                            </div>
                            <div className="col-sm">
                                <img className="example-image img-fluid" src={Pvc3} alt="PVC Sailcloth Acoustics3" />
                                <h4>Mounting Type “B”</h4>
                                <p>Clean, Smooth Facing</p>
                            </div>
                        </div>
                        <div className="line"></div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/acoustical-baffles-on-wall.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Pvc4} alt="PVC Sailcloth Acoustics4" />
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="10">Sailcloth Vinyl Encapsulated Acoustical Baffles Sabins per Baffle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="10">ASTM C423-90a &amp; E795-92</td>
                                        </tr>
                                        <tr className="colheader">
                                            <td></td>
                                            <td>125HZ</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4Khz</td>
                                            <td>AVG.</td>
                                        </tr>
                                        <tr>
                                            <td>1″ 3# PVC</td>
                                            <td>2.50</td>
                                            <td>3.94</td>
                                            <td>6.72</td>
                                            <td>11.52</td>
                                            <td>12.11</td>
                                            <td>8.69</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>2″ 3# Sailcloth</td>
                                            <td>1.73</td>
                                            <td>5.14</td>
                                            <td>10.79</td>
                                            <td>13.12</td>
                                            <td>8.69</td>
                                            <td>4.60</td>
                                            <td className="emphasis">9.43</td>
                                        </tr>
                                        <tr>
                                            <td>2″ .75# PVC</td>
                                            <td>6.88</td>
                                            <td>7.12</td>
                                            <td>7.12</td>
                                            <td>8.08</td>
                                            <td>8.16</td>
                                            <td>8.88</td>
                                            <td className="emphasis">7.75</td>
                                        </tr>
                                        <tr>
                                            <td>2″ 1.65# PVC</td>
                                            <td>5.20</td>
                                            <td>7.73</td>
                                            <td>8.93</td>
                                            <td>8.10</td>
                                            <td>8.00</td>
                                            <td>8.06</td>
                                            <td className="emphasis">8.19</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Link className="example-image-link" to="../../images/inner/baffle-hardware.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Pvc5} alt="PVC Sailcloth Acoustics5" />
                                </Link>
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="2">Mounting Hardware</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td style={{textalign: "center"}}><strong>Item</strong></td>
                                            <td style={{textalign: "center"}}><strong>Description</strong></td>
                                        </tr>
                                        <tr>
                                            <td style={{textalign: "center"}}>CS 1001</td>
                                            <td style={{textalign: "center"}}>Cable Crimp Sleeve</td>
                                        </tr>
                                        <tr>
                                            <td style={{textalign: "center"}}>TB 1002</td>
                                            <td style={{textalign: "center"}}>Turnbuckle</td>
                                        </tr>
                                        <tr>
                                            <td style={{textalign: "center"}}>CT 1000</td>
                                            <td style={{textalign: "center"}}>Crimp Tool</td>
                                        </tr>
                                        <tr>
                                            <td style={{textalign: "center"}}>2H4</td>
                                            <td style={{textalign: "center"}}>Beam Clamp 3/32″ 9/64″</td>
                                        </tr>
                                        <tr>
                                            <td style={{textalign: "center"}}>H24</td>
                                            <td style={{textalign: "center"}}>Beam Clamp 1/8″ 1/4″</td>
                                        </tr>
                                        <tr>
                                            <td style={{textalign: "center"}}>WMX3</td>
                                            <td style={{textalign: "center"}}>Hanger Wire</td>
                                        </tr>
                                        <tr>
                                            <td style={{textalign: "center"}}>AC 1000</td>
                                            <td style={{textalign: "center"}}>7×7 Aircraft Cable</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">Stainless steel hardware is available for damp and moist areas such as swimming pools or food processing plants.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default pvc; 