import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Metalpanels1 from '../../images/inner/silk-metal-tiles2-200x200.webp';
import Metalpanels2 from '../../images/inner/ceiling_metal2-200x200.webp';
import Metalpanels3 from '../../images/inner/squareline_large2-200x200.webp';
import './metalpanels.css';
import ScrollToTopButton from '../ScrollButton';
function metalpanel() {
    return (
      
    <>
    <Helmet>
    <title>Sound Absorber - Metal Panels for Noise Control </title>
    <meta name="description" content=""/>
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Metal Panels for Noise Control" />
    <meta property="og:description" content="Metal isn’t usually the first material that comes to mind when talking about sound absorption products but when metal is combined with state-of-the-art acoustical materials, the results are outstanding in both performance and appearance." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/silk-metal-tiles2-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/metalpanels" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/metalpanels" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/metalpanels" />
    </Helmet>
    <Header />
    <section className="content" id="metalpanels">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Metal Panels for Noise Control</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>Metal isn’t usually the first material that comes to mind when talking about sound absorption products but when metal is combined with state-of-the-art acoustical materials, the results are outstanding in both performance and appearance.</p>
                                    <p>We carry a line of Acoustical Metal Panels with exceptional sound absorption qualities for use in any type of environment and application. The perforated metal acoustical panels are strong enough to withstand demanding industrial environments while being visually appealing to fit in with high-end décor. These affordable and easy to install options bring maximum sound control to restaurants, airports, concert halls, offices, retail, manufacturing facilities, and many more. For an even more unique noise control solution, we offer a graphics option to customize panels to improve aesthetic pleasure even further.</p>
                                    <p><Link to="/contact-us">Contact us</Link> today to learn more!</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>View Our Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Metalpanels1} alt="Silk Metal Panels" />
                                </Link>
                                <h5>Silk Metal™ Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Metalpanels2} alt="Acousti-Metal Panels" />
                                </Link>
                                <h5>Acousti-Metal™ Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Metalpanels3} alt="Squareline Expanded Metal Ceiling Tiles" />
                                </Link>
                                <h5>Squareline expanded metal ceiling tiles</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default metalpanel; 