import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Acousticpanel1 from '../../images/inner/cotton_stack_lg-200x200.webp';
import Acousticpanel2 from '../../images/inner/Soundsilencer-group1-200x200.webp';
import Acousticpanel3 from '../../images/inner/fabric-wrapped-panel-edge-details-lg1-200x200.webp';
import Acousticpanel4 from '../../images/inner/polymax-beige-white-panels1-200x173.webp';
import Acousticpanel5 from '../../images/inner/prod_sonexonepanels1-200x200.webp';
import Acousticpanel6 from '../../images/inner/cellulose-panels2-200x200.webp';
import Acousticpanel7 from '../../images/inner/curve-low-profile-diffusor1-200x200.webp';
import './acoustic-panel.css';
import ScrollToTopButton from '../ScrollButton';
function acousticpanel() {
    return (
      
    <>
    <Helmet>
    <title>Decorative Acoustic Panels and Sound Absorbing Panels</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Acoustical Panel Soundproofing Products" />
    <meta property="og:description" content="Acoustical Panels generally refer to any specialized construction panel placed on a wall to absorb sound. " />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/cotton_stack_lg-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/acoustic-panel" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/acoustic-panel" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acoustic-panel" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="acousticpanel">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
            	<div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                        <div className="col-sm-12 heading-fsorb">
                            <h1>Acoustic Panels and Sound Absorbing Panels</h1>
                            <div className="row">
                                <div className="col-sm-12">
                                    <article className="clearfix">
                                        <p>Acoustical Panels generally refer to any specialized construction panel placed on a wall to absorb sound. Sound absorption is achieved through panel construction which includes an acoustical core and a fabric cover. Most are quite simple to install and can be cost effectively mounted using clip systems for permanent or semi-permanent installations. The highest level of sound absorption is achieved through fabric wrapped fiberglass panels that offer excellent noise control properties for a wide variety of applications.</p>
                                        <p>We carry a variety of branded products to meet your needs. This allows us to design a system to match thickness, color, style, or pricing requirements. Our wide variety of high performance and custom manufactured options enable us to supply the right panel for effective noise control and sound absorption. Acoustical cores are made from fiberglass, polypropylene, cellulose, or foam to best meet the requirements of the application. Interested in custom graphics? The Poly Max™ line of panels can be manufactured to add beauty as well as functionality in spaces such as group housing, office environments, medical offices, and more. And if you&#8217;re in need of an acoustical panel for outdoor purposes, the Sound Silencer™ line should serve you well.</p>
                                        <p><Link to="/contact-us" rel="noopener">Contact us</Link> today to learn more!</p>
                                    </article>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <h4>Noise Control Panels</h4>
                                </div>
                                <div className="col-sm-3 dbox">
                                    <Link to="/#"><img className="img" src={Acousticpanel1} alt="cotton stack" /></Link>
                                    <h5>Echo Eliminator™</h5>
                                </div>
                                <div className="col-sm-3 dbox">
                                    <Link to="/#"><img className="img" src={Acousticpanel2} alt="sound silencer" /></Link>
                                    <h5>Sound Silencer™</h5>
                                </div>
                                <div className="col-sm-3 dbox">
                                    <Link to="/#"><img className="img" src={Acousticpanel3} alt="fabric wrapped panel" /></Link>
                                    <h5>Fabric Wrapped Fiberglass</h5>
                                </div>
                                <div className="col-sm-3 dbox">
                                    <Link to="/#"><img className="img" src={Acousticpanel4} alt="Polymax" /></Link>
                                    <h5>Poly Max™</h5>
                                </div>
                                <div className="col-sm-3 dbox">
                                    <Link to="/#"><img className="img" src={Acousticpanel5} alt="Prod-sonex one panel" /></Link>
                                    <h5>Acoustical Foam Panels</h5>
                                </div>
                                <div className="col-sm-3 dbox">
                                    <Link to="/#"><img className="img" src={Acousticpanel6} alt="cellulose panel" /></Link>
                                    <h5>CFAB Cellulose Panels</h5>
                                </div>
                                <div className="col-sm-3 dbox">
                                    <Link to="/#"><img className="img" src={Acousticpanel7} alt="curve low profile diffusor" /></Link>
                                    <h5>Curve Panels</h5>
                                </div>
                            </div>
                            <hr />
                            <div className="row resources">
                                <div className="col-xl-12 col-lg-12 col-sm-12">
                                    <h2>Additional Resources</h2>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-12">
                                    <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                    <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                    <div className="butn">
                                        <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-12">
                                    <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                    <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                    <div className="butn">
                                        <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-12">
                                    <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                    <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                    <div className="butn">
                                        <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acousticpanel;