import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import ScrollToTopButton from '../ScrollButton';
function polywad() {
    return (
      
    <>
    <Helmet>
    <title>Poly Wadding Acoustic Panels created from natural fibers</title>
    <meta name="description" content="we are manufacturing and supplying Wadding/ Polyster Wadding." />
    <meta property="og:title" content="Polyester Wadding" />
    <meta property="og:description" content="In our wide range of products, we offer Polyster Wadding. The products are created from natural fibers." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/polyester-wadding.jpg" />
    <meta property="og:locale" content="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/polywadding" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/polywadding" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/polywadding" hreflang="en-ae" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="IN" />
    <meta name="geo.region" content="india" />
    </Helmet>
    <Header />
    <section className="content">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Polyester Wadding</h1>
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                <Link className="example-image-link" to="images/inner/polyester-wadding.jpg" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src="images/inner/polyester-wadding.jpg" alt="acoustics" />
                                </Link>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Polyester Wadding</h2>
                                <p>In our wide range of products, we offer Polyster Wadding. The products are created from natural fibers. These are known for their durability and reliableperformance. The fabric is used in various application areas.We offer 40-400 gsm range. In our wide range of products, we are manufacturing and supplying Wadding / Polyster Wadding.</p>
                                <p>The products are created from natural fibers. These are known for their durability and reliable performance. Moreover, our products include cotton, wool, silk and all other animal and plant fibers. Further, the fabric is used ¡n various application areas such as acoustics, medicine, aeronautics, home furnishing and modern apparels. We offer qualitative polyester wadding s, which are of soft and stiff finishes and can be provided as per the specific requirement of our customers. Polyester Wadding are of chemical bonded & thermal bonded waddings.</p>
                                <p>Further, our chemical bonded waddings weigh from 30 gsm up to 300 gsm and the thermal bonded waddings are from 100 gsm upto 1000 gsm.</p>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <h3>Product Specifications</h3>
                                    <tbody>
                                        <tr>
                                            <td>Product Name:</td>
                                            <td>Sound-absorbing cotton</td>
                                        </tr>
                                        <tr>
                                            <td>Material</td>
                                            <td>100% polyester</td>
                                        </tr>
                                        <tr>
                                            <td>Grade</td>
                                            <td>First grade</td>
                                        </tr>
                                        <tr>
                                            <td>Fiber style</td>
                                            <td>Stable</td>
                                        </tr>
                                        <tr>
                                            <td>Style</td>
                                            <td>Hollow</td>
                                        </tr>
                                        <tr>
                                            <td>Features</td>
                                            <td>Sound-absorbing, flame/heat-resistant, soft with light weight</td>
                                        </tr>
                                        <tr>
                                            <td>Packing</td>
                                            <td>In rolls or folded with plastic bag meet the customers requires</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default polywad; 