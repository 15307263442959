import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import './acfabric-studio.css';
import Mobsidebar from '../mobile-sidebar';
import St1 from '../../images/acfabric/st1.jpg';
import St2 from '../../images/acfabric/st2.jpg';
import St3 from '../../images/acfabric/st3.jpg';
import St4 from '../../images/acfabric/st4.jpg';
import St5 from '../../images/acfabric/st5.jpg';
import St6 from '../../images/acfabric/st6.jpg';
import St7 from '../../images/acfabric/st7.jpg';
import St8 from '../../images/acfabric/st8.jpg';
import St9 from '../../images/acfabric/st9.jpg';
import St10 from '../../images/acfabric/st10.jpg';
import St11 from '../../images/acfabric/st11.jpg';
import St12 from '../../images/acfabric/st12.jpg';
import St13 from '../../images/acfabric/st13.jpg';
import St14 from '../../images/acfabric/st14.jpg';
import St15 from '../../images/acfabric/st15.jpg';
import ScrollToTopButton from '../ScrollButton';
function acfabricstudio() {
    return (
      
    <>
    <Helmet>
    <title>Acoustic FABRIC Studio - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="AcoustyFABRIC Studio" />
    <meta property="og:description" content="Guilford of Maine Studio 542966 Fabric Facings" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/acfabric/st1.jpg" />
    <meta property="og:locale" content="en-in" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/acfabric-studio" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/acfabric-studio" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acfabric-studio" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="acfabricstudio">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
            	<div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
	                <div className="col-sm-12 heading-fsorb">				
		                <h1>Acousty<span className="fabriwall">FABRIC</span> Studio</h1>
                        <h4 className="mb-0"> <b>Guilford of Maine Studio 542966 Fabric Facings</b></h4>
		                <p><span className="color:red;">(Colors Shown are Approximate Match to Material)</span></p>
	                    <br />
		                <div className="col-sm-3 cloths p-0"><button>Product Specifications</button></div>
	                </div>
		            <br /><br />
		            <div className="col-sm-12 cloths">
		                <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={St1} alt="White Heat" />
                                <p className="text-center">White Heat 7050</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={St2} alt="Black Light" />
                                <p className="text-center">Black Light 7060</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={St3} alt="Celebrity" />
                                <p className="text-center">Celebrity 7045</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={St4} alt="Disco" />
                                <p className="text-center">Disco 7030</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={St5} alt="Flashy" />
                                <p className="text-center">Flashy 7043</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={St6} alt="Glam" />
                                <p className="text-center">Glam 7040</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={St7} alt="Gloria" />
                                <p className="text-center">Gloria 7020</p>
                            </div>
           	                <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={St8} alt="Hustle" />
                                <p className="text-center">Hustle 7032</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={St9} alt="Inferno" />
                                <p className="text-center">Inferno 7012</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={St10} alt="Ross" />
                                <p className="text-center">Ross 7044</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={St11} alt="Shimmer" />
                                <p className="text-center">Shimmer 7041</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={St12} alt="Stardust" />
                                <p className="text-center">Stardust 7051</p>
                            </div>
           	                <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={St13} alt="Stayin Alive" />
                                <p className="text-center">Stayin' Alive 7010</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={St14} alt="Summer" />
                                <p className="text-center">Summer 7021</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={St15} alt="Warhol" />
                                <p className="text-center">Warhol 7042</p>
                            </div>
                        </div>
                    </div>
		            <br />
		            <hr />
                    <div className="col-12 d-flex justify-content-center backbtn">
                    	<Link to="/acoustyfabric"><button>Back to Acousty<span style={{color: "red"}}>FABRIC</span></button></Link>
                    </div>
                    <hr />
                    <div className="col-sm-12 application">
			            <h1>APPLICATION</h1>
	                    <div className="hover14 text-center justify-content-center">
		                <br />
  		                    <div className="col-sm-12">
			                    <figure className="m-0"><div className="tinted-image">CINEMA HALL</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image2">OFFICE ROOMS & HALLS</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image3">AUDITORIUM & EDUCATION</div></figure>
 		                    </div>
                        <br />
		                </div>
                    </div>
		            <br />
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
    <Footer />
</>
);
}
export default acfabricstudio;