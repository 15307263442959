import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Hotel1 from '../../images/inner/doorseal11-200x200.webp';
import Hotel2 from '../../images/inner/greenGlueTubes-200x200.webp';
import Hotel3 from '../../images/inner/rsic-1-clip-furring-channel-200x177.webp';
import Hotel4 from '../../images/inner/acoustik1-200x200.webp';
import Hotel5 from '../../images/inner/fabric-wrapped-panel-edge-details-lg-200x200.webp';
import Hotel6 from '../../images/inner/studio-3d-doors-200x200.webp';
import Hotel7 from '../../images/inner/silk-metal-tiles-sm1-200x200.webp';
import Hotel8 from '../../images/inner/recording-studio-windows2-200x200.webp';
import Mobsidebar from '../mobile-sidebar';
import './hotel-convention.css';
import ScrollToTopButton from '../ScrollButton';
function hotel() {
    return (
      
    <>
    <Helmet>
    <title>Soundproofing Hotels and Convention Centers Acoustic Solutions</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" /> 
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Soundproofing Hotels and Convention Centers" />
    <meta property="og:description" content="We are the perfect provider of hotel acoustic solutions to handle venues such as large event spaces, gyms, restaurants, guest rooms, pools, meeting halls, and arenas." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/studio-3d-doors-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/hotel-convention" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/hotel-convention" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/hotel-convention" />
    </Helmet>
    <Header />
    <section className="content" id="hotel">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Soundproofing Hotels and Convention Centers</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>Hotels and convention centers can potentially have a wide range of noise and sound problems. With over 35 years of expertise and industry experience, we are the perfect provider of hotel acoustic solutions to handle venues such as large event spaces, gyms, restaurants, guest rooms, pools, meeting halls, and arenas.</p>
                                    <p>To control noise in large spaces, we offer a wide range of products designed to improve convention center and hotel acoustic qualities. No matter the size or shape of a given area, we offer custom designed acoustic ceiling and wall panels, clouds and baffles that reduce echoes and absorb sound. We also have quite a few different products that are used to block sound transmission from room to room. These products include beautiful doors, retro-fit door seal kits, high performing flooring underlayments, sound-rated window assemblies and wall assembly products that greatly increase STC while also maintaining required fire ratings. Our trained acoustic technicians can ensure an ideal solution and design for your particular issue. Functional and fashionable, our acoustical products can be made to suit the décor in new as well as renovated spaces.</p>
                                    <p>Keeping the noise at proper levels is a very important concern for successful hotels and convention centers. To get an idea of our top-quality noise control and hotel soundproofing products, see the list above and <Link to="/contact-us">contact us</Link> for additional details.</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>Hotels & Convention Centers Soundproofing Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Hotel1} alt="Door Seal Kits" />
                                </Link>
                                <h5>Door Seal Kits</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Hotel2} alt="Green Glue" />
                                </Link>
                                <h5>Green Glue</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Hotel3} alt="RSIC-1 Clips" />
                                </Link>
                                <h5>RSIC-1 Clips</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Hotel4} alt="Acoustik Underlament" />
                                </Link>
                                <h5>Acoustik Underlament</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Hotel5} alt="Fabric Wrapped Fiberglass Panels" />
                                </Link>
                                <h5>Fabric Wrapped Fiberglass Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Hotel6} alt="Studio 3D Doors" />
                                </Link>
                                <h5>Studio 3D doors</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Hotel7} alt="Silk Metal" />
                                </Link>
                                <h5>Silk Metal</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Hotel8} alt="Windows" />
                                </Link>
                                <h5>Windows</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default hotel; 