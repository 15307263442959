import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundsilencer1 from '../../images/inner/Soundsilencer-group.webp';
import Soundsilencer2 from '../../images/inner/Soundsilencer-sm.webp';
import Soundsilencer3 from '../../images/inner/Charcoal_up_lg1-200x200.webp';
import Soundsilencer4 from '../../images/inner/white_up_lg3-200x200.webp';
import Soundsilencer5 from '../../images/inner/Sound-Silencer-7-web-200x200.webp';
import Soundsilencer6 from '../../images/inner/Sound-Silencer-13-web-200x200.webp';
import Soundsilencer7 from '../../images/inner/peppwct1.webp';
import Soundsilencer8 from '../../images/inner/peppwct.webp';
import './sound-silencer.css';
import ScrollToTopButton from '../ScrollButton';
function soundsilencer() {
    return (
      
    <>
    <Helmet>
    <title>Sound Silencer Panels can be applied directly on wall and ceiling surfaces </title>
    <meta name="description" content="Sound Silencer panels are great for gymnasiums, ice rinks, swimming pools, clean rooms, laboratories, food prep areas, firing ranges and anywhere else where noise control is important." />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Rigid Porous ARPRO Acoustical Planks for Walls and Ceilings" />
    <meta property="og:description" content="Sound Silencer panels are great for gymnasiums, ice rinks, swimming pools, clean rooms, laboratories, food prep areas, firing ranges and anywhere else where noise control is important." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/Soundsilencer-group.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/sound-silencer" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/sound-silencer" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/sound-silencer" />
    </Helmet>
    <Header />
    <section className="content" id="soundsilencer">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Sound Silencer™</h1>   
                        <div className="row">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/Soundsilencer-group.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Soundsilencer1} alt="Sound Silencer 1" />
                                </Link>
                                <Link className="example-image-link" to="../../images/inner/Soundsilencer-sm.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Soundsilencer2} alt="Sound Silencer 2" />
                                </Link>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Rigid Porous ARPRO<sup>®</sup>  Acoustical (P.E.P.P.) Planks for Walls and Ceilings</h2>
                                <p>Sound Silencer acoustical sound panels are unique made from Rigid Porous ARPRO Acoustical (P.E.P.P.) Planks, these panels are Class A fire rated with STC and NRC values. Sound Silenc­er panels are great for gymnasiums, ice rinks, swimming pools, clean rooms, laboratories, food prep areas, firing ranges and anywhere else where noise control is important. The acoustical sound panels can be applied directly on wall and ceiling surfaces for attractive and effective sound control.</p>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li> High performance  both STC  NRC ratings</li>
                                    <li>Class A fire rated per ASTM E84</li>
                                    <li>No fiberglass – non-fibrous</li>
                                    <li>Water-resistant indoor</li>
                                    <li>Non-abrasive surface</li>
                                    <li>Indoor/outdoor usage</li>
                                    <li>Tackable surface</li>
                                    <li>Moisture-, impact-, bacteria-  fungi-resistant</li>
                                    <li>Lightweight  cleanable</li>
                                </ul>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 text">
                                <h2>Sound Silencer Installation Tutorial</h2>
                                <p className="ytvid-2">
                                    <iframe src="https://www.youtube.com/embed/GHF_2iA-5tY" width="560" height="315" frameborder="0" allowfullscreen="allowfullscreen" title="sound silencer"></iframe>
                                </p>
                            </div>
                            <div className="col-sm-12 text">
                                <h2>Color Options & Product Photos</h2>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/Charcoal_up_lg1.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Soundsilencer3} alt="Charcoal Color Product" />
                                </Link>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/white_up_lg3.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Soundsilencer4} alt="White Color Product" />
                                </Link>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/Sound-Silencer-7-web.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Soundsilencer5} alt="Sound Silencer Installation" />
                                </Link>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/Sound-Silencer-13-web.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Soundsilencer6} alt="acoustics Installation" />
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Product Specs</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>Material</th>
                                            <td>Rigid Porous ARPRO® Acoustical (P.E.P.P.) Planks </td>
                                        </tr>
                                        <tr>
                                            <th>Pattern</th>
                                            <td>Non-Abrasive, Slightly Textured, Porous </td>
                                        </tr>
                                        <tr>
                                            <th>Features</th>
                                            <td>Lightweight, Impact Resistant, Moisture, Bacteria & Fungi Resistant, Tackable Surface</td>
                                        </tr>
                                        <tr>
                                            <th>Applications</th>
                                            <td>Gymnasiums, Auditoriums, Classrooms, Swimming Pools, Ice Arenas, Clean Rooms, Food Processing Plants, Food Prep Areas, Cafeterias & Restaurants, Manufacturing Plants, Car Washes, Rooftop and Machine Enclosures, Gun Ranges, Dog Kennels, Locker Rooms. </td>
                                        </tr>
                                        <tr>
                                            <th>Thickness</th>
                                            <td>1″ & 2″ </td>
                                        </tr>
                                        <tr>
                                            <th>Sizes</th>
                                            <td>Exact Cut: 24″ × 24″, 24″ × 48″<br />
                                                Nominal: 23¾″ × 23¾″, 23¾″ × 47¾″<br />
                                                Custom Sizes Available – Maximum Panel Size: 25″ × 49″
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Colors</th>
                                            <td>White, Charcoal </td>
                                        </tr>
                                        <tr>
                                            <th>Flammability</th>
                                            <td>ASTM E84, Class A<br />
                                                1″ – Flame Spread: 3, Smoke Developed: 84<br />
                                                2″ – Flame Spread: 5, Smoke Developed: 113
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Installation</th>
                                            <td>ASI S.T.O.P. Noise Acoustical Adhesive, Mechanical Fasteners</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p><strong>*Note to all Installers</strong><br />
                                    Sound Silencer™ PEPP is a thermal molded product and although tough to see one side of 
                                    the panel will have injection and mold release marks these are circular marks that range 
                                    in size and indent depth. These marks denote the back side of the panel so panels should 
                                    be installed with these marks facing the wall or ceiling for best possible aesthetical 
                                    outcome.
                                </p>
                                <p><Link className="btn btn-asi" to="reverb-demo">Reverberation Demonstration</Link></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="9">Sound Silencer & Sound Absorption/Noise Reduction</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td>Panel Thickness</td>
                                            <td>Mount</td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>NRC</td>
                                        </tr>
                                        <tr>
                                            <td>1″</td>
                                            <td>Wall A Mount</td>
                                            <td>0.05</td>
                                            <td>0.06</td>
                                            <td>0.21</td>
                                            <td>0.80</td>
                                            <td>0.65</td>
                                            <td>0.75</td>
                                            <td className="emphasis">0.45</td>
                                        </tr>
                                        <tr>
                                            <td>1″</td>
                                            <td>Wall 3/4″ Airspace</td>
                                            <td>0.06</td>
                                            <td>0.13</td>
                                            <td>0.51</td>
                                            <td>0.79</td>
                                            <td>0.62</td>
                                            <td>0.79</td>
                                            <td className="emphasis">0.50</td>
                                        </tr>
                                        <tr>
                                            <td>1″</td>
                                            <td>Wall 1″ <Link to="/echo-eliminator" style={{color: "#0059a5", textdecoration: "underline"}} title="Echo Eliminator; Bonded Acoustical Cotton Panels">B.A.C.</Link></td>
                                            <td>0.11</td>
                                            <td>0.58</td>
                                            <td>1.07</td>
                                            <td>0.71</td>
                                            <td>0.74</td>
                                            <td>0.72</td>
                                            <td className="emphasis">0.80</td>
                                        </tr>
                                        <tr>
                                            <td>2″</td>
                                            <td>Wall A Mount</td>
                                            <td>0.07</td>
                                            <td>0.21</td>
                                            <td>0.81</td>
                                            <td>0.85</td>
                                            <td>0.93</td>
                                            <td>0.88</td>
                                            <td className="emphasis">0.70</td>
                                        </tr>
                                        <tr>
                                            <td>2″</td>
                                            <td>Wall 3/4″ Airspace</td>
                                            <td>0.10</td>
                                            <td>0.29</td>
                                            <td>0.99</td>
                                            <td>0.74</td>
                                            <td>0.90</td>
                                            <td>0.93</td>
                                            <td className="emphasis">0.75</td>
                                        </tr>
                                        <tr>
                                            <td>2″</td>
                                            <td>Wall 1″ <Link to="/echo-eliminator" style={{color: "#0059a5", textdecoration: "underline"}} title="Echo Eliminator; Bonded Acoustical Cotton Panels">B.A.C.</Link></td>
                                            <td>0.17</td>
                                            <td>0.81</td>
                                            <td>0.97</td>
                                            <td>0.85</td>
                                            <td>0.89</td>
                                            <td>0.92</td>
                                            <td className="emphasis">0.90</td>
                                        </tr>
                                        <tr>
                                            <td>1″</td>
                                            <td>Ceiling E400</td>
                                            <td>0.46</td>
                                            <td>0.59</td>
                                            <td>0.42</td>
                                            <td>0.49</td>
                                            <td>0.76</td>
                                            <td>0.86</td>
                                            <td className="emphasis">0.55</td>
                                        </tr>
                                        <tr>
                                            <td>2″</td>
                                            <td>Ceiling E400</td>
                                            <td>0.51</td>
                                            <td>0.52</td>
                                            <td>0.52</td>
                                            <td>0.77</td>
                                            <td>0.89</td>
                                            <td>0.98</td>
                                            <td className="emphasis">0.70</td>
                                        </tr>
                                        <tr>
                                            <td>1″</td>
                                            <td>Wall C423 12″ spacing</td>
                                            <td>0.04</td>
                                            <td>0.07</td>
                                            <td>0.20</td>
                                            <td>0.83</td>
                                            <td>0.81</td>
                                            <td>1.00</td>
                                            <td className="emphasis">0.50</td>
                                        </tr>
                                        <tr>
                                            <td>2″</td>
                                            <td>Wall C423 12″ spacing</td>
                                            <td>0.09</td>
                                            <td>0.21</td>
                                            <td>0.82</td>
                                            <td>1.11</td>
                                            <td>1.11</td>
                                            <td>1.12</td>
                                            <td className="emphasis">0.80</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/peppwct1.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Soundsilencer7} alt="Sound Silencer Peppwct1" />
                                </Link>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/peppwct.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Soundsilencer8} alt="Sound Silencer Peppwct2" />
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="8">Sound Silencer; Sound Transmission Loss (STC)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td></td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2.5KHz</td>
                                            <td>5KHz</td>
                                            <td>STC</td>
                                        </tr>
                                        <tr>
                                            <td>1″</td>
                                            <td>6</td>
                                            <td>5</td>
                                            <td>7</td>
                                            <td>8</td>
                                            <td>10</td>
                                            <td>15</td>
                                            <td className="emphasis">9</td>
                                        </tr>
                                        <tr>
                                            <td>2″</td>
                                            <td>9</td>
                                            <td>8</td>
                                            <td>10</td>
                                            <td>10</td>
                                            <td>17</td>
                                            <td>22</td>
                                            <td className="emphasis">13</td>
                                        </tr>
                                        <tr>
                                            <td>1″ w/ 5/8″ Gypsum<br />
                                                Both Sides
                                            </td>
                                            <td>27</td>
                                            <td>27</td>
                                            <td>29</td>
                                            <td>31</td>
                                            <td>32</td>
                                            <td>45</td>
                                            <td className="emphasis">32</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default soundsilencer; 