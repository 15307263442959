import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Construction1 from '../../images/inner/acoustik2-200x200.webp';
import Construction2 from '../../images/inner/rsic-1-clip-furring-channel1-200x177.webp';
import Construction3 from '../../images/inner/green-glue-group1-200x200.webp';
import Construction4 from '../../images/inner/ultra_touch_denim_logo1-200x76.webp';
import Construction5 from '../../images/inner/mlvb2-200x200.webp';
import Construction6 from '../../images/inner/soundbreak_xp_cutaway1-200x200.webp';
import './soundproofing-construction-products.css';
import ScrollToTopButton from '../ScrollButton';
function soundproofcons() {
    return (
      
    <>
    <Helmet>
    <title>Construction Acoustics Rated for Sound Blocking Improves Noise Levels</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Soundproofing Construction Products" />
    <meta property="og:description" content="Sound isolation is extremely important for comfortable living whether it is in a dwelling or in an office environment." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/acoustik2-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/soundproofing-construction-products" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/construction-products" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/soundproofing-construction-products" />
    </Helmet>
    <Header />
    <section className="content" id="construction">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Soundproofing Construction Products</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>Acoustical Construction Products are designed to add soundproofing qualities during initial construction or when renovating/remodeling existing structures. Sound isolation is extremely important for comfortable living whether it is in a dwelling or in an office environment. Since sound will always find the weakest link, using construction products rated for sound blocking improves noise levels considerably.</p>
                                    <p>We offer a wide range of innovative products that block sound transmission though walls, floors, and ceilings. Many of our products act as a barrier to stop noise. Specialized gypsum board, insulation, and subflooring products greatly improve noise control by making areas soundproof. In addition to preventing noise from one space infiltrating another, we also offer contractors options for managing noise from mechanical systems. Mechanical systems such as heating or air conditioning units, ductwork, or piping may transmit noise through vibrations or open spaces. Using our specialized coatings, barriers, pipe lagging, and sound isolation clips will help keep the noise down and occupants happy!</p>
                                    <p>Below is just a sampling of our Construction Soundproofing Products offerings. <Link to="/contact-us">Contact us</Link> today to learn more.</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>View Our Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Construction1} alt="Acoustic Underlament" />
                                </Link>
                                <h5>Acoustik Underlament</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Construction2} alt="RSIC-1 Clips" />
                                </Link>
                                <h5>RSIC-1 Clips</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Construction3} alt="Green Glue" />
                                </Link>
                                <h5>Green Glue</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Construction4} alt="Ultra Touch Insulation" />
                                </Link>
                                <h5>Ultra Touch Insulation</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Construction5} alt="Mass Loaded Vinyl" />
                                </Link>
                                <h5>Mass Loaded Vinyl</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Construction6} alt="SoundBreak XP" />
                                </Link>
                                <h5>SoundBreak XP</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default soundproofcons; 
    