import React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import ScrollToTopButton from '../ScrollButton';
function reverb() {
    return (
      
    <>
    <Helmet>
    <title>Sound Absorbing Material Reverberation Time Demonstration - Acoustical Surfaces </title>
    <meta name="description" content="" />
    <meta property="og:title" content="Reverberation Demonstration" />
    <meta property="og:description" content="Sound reflections are created when noise reverberates and echoes around architectural spaces." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.png" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/reverb-demo" hreflang="en-ae" /> 
    <link rel="alternate" href="https://www.acousticalsurfaces.in/reverb-demo" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/reverb-demo" />
    </Helmet>
    <Header />
    <section className="content">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Reverberation Demonstration</h1>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text">
                                <h2>Why Reverberation Time (RT) Is Important</h2>
                                <h3>What is Reverberation Time?</h3>
                                <p>Sound reflections are created when noise reverberates and echoes around architectural spaces. Reverberation Time (RT) is the acoustical concept which measures how long, in seconds, it takes for these noises to become inaudible.</p>
                                <h3>The Importance of Sound Quality</h3>
                                <p>Measuring Reverberation Time is important to determine the sound quality of speech and music in acoustical spaces. Instructional spaces, such as classNamerooms, are best with short RTs – less than 1 second to ensure clarity and high speech intelligibility. ANSI has set forth criteria and standards with recommendations for the maximum acceptable RT in these areas. Auditoriums, theaters, and other musical spaces will benefit from longer RTs, typically greater than 1.5 seconds.</p>
                                <h3>The Relationship Between Architectural Design and Reverberation Time</h3>
                                <h3>RT = Constant (Volume/Absorption)</h3>
                                <p>RT is determined by looking at both the volume and absorption rate in an acoustical space. The volume of a space is proportional to the RT of that space; the greater the volume, the longer the RT. Inversely, the amount of sound-absorbing material in any space will have a negative effect on the RT. As an example, a large space with tiled floors and a drywall ceiling will have a long RT. Oppositely, a small room with a low suspended ceiling and high-pile carpet will have a much shorter RT.</p>
                                <p>Create the best room for your needs – determine the RT of your space and let us help you choose the correct acoustical materials for your space.</p>
                            </div>
                            <div className="line"></div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text">
                                <h2>Hear The Difference</h2>
                                <h3>Reverberation Time Demonstration</h3>
                                <p>Play the music or voice to hear the difference.</p>
                            </div>
                            <div className="audio">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text">
                                    <p></p>
                                    <h3>1 Second Reverberation Time</h3>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 audio-left">
                                    <p>Music</p>
                                    <audio src="audio/1-sec-rt-music.mp3" controls preload="metadata">
                                        <p>Your browser doesn't support html5 audio.</p>
                                    </audio>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 audio-right">
                                    <p>Voice</p>
                                    <audio src="audio/1-sec-rt-voice.mp3" controls preload="metadata">
                                        <p>Your browser doesn't support html5 audio.</p>
                                    </audio>
                                </div>
                            </div>
                            <div className="audio">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text">
                                    <p></p>
                                    <h3>2.5 Second Reverberation Time</h3>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 audio-left">
                                    <p>Music</p>
                                    <audio src="audio/2-5-sec-rt-music.mp3" controls preload="metadata">
                                        <p>Your browser doesn't support html5 audio.</p>
                                    </audio>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 audio-right">
                                    <p>Voice</p>
                                    <audio src="audio/2-5-sec-rt-voice.mp3" controls preload="metadata">
                                        <p>Your browser doesn't support html5 audio.</p>
                                    </audio>
                                </div>
                            </div>
                            <div className="audio">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text">
                                    <p></p>
                                    <h3>4 Second Reverberation Time</h3>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 audio-left">
                                    <p>Music</p>
                                    <audio src="audio/4-sec-rt-music.mp3" controls preload="metadata">
                                        <p>Your browser doesn't support html5 audio.</p>
                                    </audio>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 audio-right">
                                    <p>Voice</p>
                                    <audio src="audio/4-sec-rt-voice.mp3" controls preload="metadata">
                                        <p>Your browser doesn't support html5 audio.</p>
                                    </audio>
                                </div>
                            </div>
                        </div>
                        <hr className="d-none d-md-block d-lg-block d-xl-block" />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textalign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src="images/additional-resources/ebook-acoustics-demyst.webp" alt="Create Better Sounding Rooms" target="_blank" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi" target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textalign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src="images/additional-resources/Common-Noise-Problems-3D01x.webp" alt="Common Noise Problems" target="_blank" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi" target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textalign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src="images/additional-resources/ebook-cad-revit.webp" alt="CAD Library" target="_blank" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi" target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default reverb; 