import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import './melamine-sound-wave-panels.css';
import Soundwave1 from '../../images/inner/melamine_soundwave_foam.jpg';
import Soundwave2 from '../../images/inner/mel_sw04.gif';
import ScrollToTopButton from '../ScrollButton';
function melaminesound() {
    return (
      
    <>
    <Helmet>
    <title>Melamine Foam Sound Absorber Soundwave Foam - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="Foam S.T.O.P. Soundwave" />
    <meta property="og:description" content="Melamine Foam Sound Absorber " />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/melamine_soundwave_foam.jpg" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/melamine-sound-wave-panels" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/melamine-sound-wave-panels" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/mmelamine-sound-wave-panels" hreflang="en-in" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    </Helmet>
    <Header />
    <section className="content" id="soundwave">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Foam S.T.O.P. Soundwave™</h1>  
                        <div className="row">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/melamine_soundwave_foam.jpg" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Soundwave1} alt="Melamine Soundwave Panels1" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Melamine Foam Sound Absorber</h2>
                                <ul style={{marginleft: "1em"}}>                                                                
                                    <li>className A Fire Retardant</li>
                                    <li>High Performance Sound Absorber</li>
                                    <li>Sound Wave Pattern</li>
                                    <li>Fiber Free</li> 
                                    <li style={{color: "#cc0000"}}>Available with H.P.C. Washable Finish</li>
                                    <li style={{color: "#cc0000"}}>Available as Hanging Acoustical Baffles</li>                         
                                </ul> 
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/Foam_STOP_Soundwave.pdf" target="_blank">Product Specs</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <h3>Product Specs</h3>
                                    <tr>
                                        <th>Material</th>
                                        <td>Open Cell Melamine Acoustical Foam </td>
                                    </tr>
                                    <tr>
                                        <th>Pattern</th>
                                        <td>Convoluted Double Hump Anechoic Wedges. Sonex Patterns Also Available </td>
                                    </tr>
                                    <tr>
                                        <th>Features</th>
                                        <td>Designed For Sound Absorption in a Wide Range of Frequencies. Increased Surface Absorption Area </td>
                                    </tr>
                                    <tr>
                                        <th>Applications</th>
                                        <td>Industrial, Commercial, Audio, OEM Residential Markets, Ceilings, Walls, Partitions, Sound/Recording Studios, Radio Stations, Board Rooms, Swimming Pools, Churches, Schools, Gun Ranges, Enclosures </td>
                                    </tr>
                                    <tr>
                                        <th>Thickness</th>
                                        <td>1″, 2″, 3″, 4″</td>
                                    </tr>
                                    <tr>
                                        <th>Sizes</th>
                                        <td>2′ × 4′</td>
                                    </tr>
                                    <tr>
                                        <th>Density</th>
                                        <td>0.7lbs/cub. ft. </td>
                                    </tr>
                                    <tr>
                                        <th>Colors</th>
                                        <td>
                                            Standard: Natural White or Light Grey <br />
                                            Latex or HPC Paint: Black, Light Grey, Almond <br /> 
                                            Painted: Black, Brown, Beige, Light Gray (Custom Colors Also Available)
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Flammability</th>
                                        <td>ASTM E84, className A. Flame Spread: 6; Smoke Developed: 15 </td>
                                    </tr>
                                    <tr>
                                        <th>Installation</th>
                                        <td>ASI S.T.O.P Noise Acoustical Adhesive </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <p>
                            <Link className="btn btn-asi" to="reverb-demo.php">Reverberation Demonstration</Link>
                        </p>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="8">FOAM S.T.O.P. Soundwave Sound Absorption/Noise Reduction</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td></td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>NRC</td>
                                        </tr>
                                        <tr>
                                            <td>1″</td>
                                            <td>0.10</td>
                                            <td>0.10</td>
                                            <td>0.30</td>
                                            <td>0.70</td>
                                            <td>0.80</td>
                                            <td>0.90</td>
                                            <td className="emphasis">0.60</td>
                                        </tr>
                                        <tr>
                                            <td>2″</td>
                                            <td>0.10</td>
                                            <td>0.35</td>
                                            <td>0.90</td>
                                            <td>1.25</td>
                                            <td>1.15</td>
                                            <td>1.10</td>
                                            <td className="emphasis">1.00</td>
                                        </tr>
                                        <tr>
                                            <td>3″</td>
                                            <td>0.10</td>
                                            <td>0.60</td>
                                            <td>1.20</td>
                                            <td>1.35</td>
                                            <td>1.10</td>
                                            <td>1.05</td>
                                            <td className="emphasis">1.15</td>
                                        </tr>
                                        <tr>
                                            <td>4″</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" href="../../images/inner/mel_sw04.gif" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Soundwave2} alt="Melamine Soundwave Panels2" />
                                </Link>
                            </div>
                        </div>
                        <hr className="d-none d-md-block d-lg-block d-xl-block" />
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default melaminesound; 