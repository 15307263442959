import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundproofing1 from '../../images/inner/soundproofing.webp';
import Soundproofing2 from '../../images/inner/soundproof.webp';
import './soundproofing.css';
import ScrollToTopButton from '../ScrollButton';
function soundproofing() {
    return (
      
    <>
    <Helmet>
    <title>Soundproofing | Soundproofing Panels Describing the Suppression of Noise</title>
    <meta name="description" content="Soundproofing a floor, a wall, roof or noisy equipment, all require different soundproofing techniques." />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Soundproofing Material Professionals" />
    <meta property="og:description" content="oundproofing, is often used when describing the suppression of noise or the containment or isolation of noise sources." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/soundproofing.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/soundproofing" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/soundproofing" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/soundproofing" />
    </Helmet>
    <Header />
    <section className="content" id="soundproofing">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Soundproofing Material Professionals</h1>
                        <div className="row">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/soundproofing.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Soundproofing1} alt="acoustics" />
                                </Link>
                                <Link className="example-image-link" to="../../images/inner/soundproof.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Soundproofing2} alt="acoustics" style={{maxwidth: "250px"}} />
                                </Link>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 text">
                                <p><br />The term, <em>Soundproofing</em>, is often used when describing the suppression of noise or the containment or isolation of noise sources.</p>
                                <p>The general public along with some professionals in the industry use the term, Sound Proof or soundproofing interchangeably when describing both Sound absorption or reduction, and what can be considered truly soundproofing.</p>
                                <p>When a sound source ceases in a space, the sound waves will continue to reflect off the hard wall, floor and ceiling surfaces until it loses enough energy and dies out. The prolongation of the reflected sound is known as reverberation.</p>
                                <p>Reverberation is dependent only on the volume of a space and the acoustically absorptive quality of the sound deadening material used. Hard surfaced rooms will have a longer reverberation time than rooms built using sound insulation, acoustic foam, or other acoustical wall finishes.</p>
                                <p><Link style={{color: "#0059a5"}} to="/fabric-wrapped-fiber-glass">Acoustical absorbers</Link> will absorb unwanted noise and prevent its build up providing noise control. On the other hand, noise isolation and containment (soundproofing) is a totally different problem requiring a wide range material applications and solutions. Soundproofing a floor, a wall, roof or noisy equipment, all require different soundproofing techniques and sound deadening materials.</p>
                                <p><Link style={{color: "#0059a5"}} to="/acoustic-panel">Acoustic Panels</Link>, 
                                    <Link style={{color: "#0059a5"}} to="/barrier-act-ceiling-tiles">Acoustic Ceiling tiles</Link> and other acoustic room and wall finishes can provide effective noise control. The specific soundproofing material required is dependant on the space, any architectural acoustics designed during the rooms construction and the type and amount of noise control desired.
                                </p>
                                <p>If you are challenged with the task of creating a soundproof environment, or establishing noise control or noise reduction in your home or work, we are here to help!</p>
                                <p>Acoustical Surfaces has been addressing soundproofing and noise reduction issues for more than 40 years. Our web site is full of helpful information about <Link style={{color: "#0059a5"}} to="/soundproofing">soundproofing materials</Link> and our staff of experienced professionals are available to answer any soundproofing or noise reduction questions you may have.</p>
                            </div>
                            <div className="line"></div>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default soundproofing;