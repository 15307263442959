import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Sliderfabrisorb from '../fabrisorb/slider-fabrisorb';
import Sliderfabrisorbbaffles from '../fabrisorb/slider-fabrisorbbaffles';
import Sliderfabrisorbceiling from '../fabrisorb/slider-fabrisorbceiling';
import Sliderfabrisorbdesigner from '../fabrisorb/slider-fabrisorbdesigner';
import Sliderfabrisorbwall from '../fabrisorb/slider-fabrisorbwall';
import Fabprint from '../../images/fabprint.webp';
import Fabrisorbbrochure from '../../images/btn-download-brochure.png';
import Fabrisorbshade from '../../images/btn-download-color.png';
import Mobsidebar from '../mobile-sidebar';
import '../fabrisorb/fabrisorb-style.css';
import ScrollToTopButton from '../ScrollButton';
function FabrisorbDeco() {
    return (

        <>
            <Helmet>
                <title>Fabrisorb Deco Panels</title>
                <meta name="description" content="" />
                <meta name="robots" content="index, follow" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="in" />
                <meta name="geo.region" content="india" />
                <meta property="og:title" content="Foamwood Panels" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/tiles.webp" />
                <meta property="og:locale" content="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.ae/fabrisorb" hreflang="en-ae" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/fabrisorb" hreflang="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/fabrisorb" />
            </Helmet>
            <Header />
            <section className="content" id="fabrisorb">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 sidemenu">
                            <Sidebar />
                        </div>
                        <div className="col-sm-9 product-page nopaddding">
                            <div className="container thanks">
                                <div className="megamenu">
                                    <Megamenu />
                                </div>
                                <div className="col-sm-12 heading-fsorb">
                                    <h1>Noise S.T.O.P. Fabrisorb DECO</h1>
                                    <p>Acoustic panel is an essential part of creating a high-quality listening environment whether you are creating a space like recording studio, home theatre, a conference room, office room, classNameroom or even a living room where all what you want is to improve the sound quality. If you are serious about how these spaces sound, then you need to start thinking about what to use in such spaces to create a more balanced and accurate sound which can enhance your listening or recording experience.
                                        For perfect balance of optimal sound quality and the aesthetics opt for Fabrisorb-Deco, where acoustics meets the eyes – Colours . As the name sounds Fabrisorb-Deco is the decorative version of Fabrisorb acoustic panels, where the surface is covered with finest quality of acoustic felt to compliment the architects colour concept. Fabrisorb-Deco comes with wide selection of acoustic felt colours to choose from.</p>
                                    <Sliderfabrisorb />

                                    <div className="flex-container">
                                        <Link to="https://www.acousticalsurfaces.in/catalogue/fabrisorb/fabrisorb-brochure.pdf" className="btn btn-danger justify-content-center">Downloads</Link>
                                    </div>
                                </div>
                                <div className="col-sm-12 features">
                                    <h1>Features</h1>
                                    <div className="line"></div>
                                    <div className="row pad">
                                        <div className="col-md-8 col-sm-12">
                                            <ul>
                                                <li>Aesthetically pleasing</li>
                                                <li>High sound absorption performance</li>
                                                <li>Available with various edges and colours</li>
                                                <li>Fire resistant</li>
                                                <li>Environmentally friendly</li>
                                                <li>Humidity resistance</li>
                                                <li>Easily cleanable and can be vacuumed</li>
                                            </ul>
                                        </div>
                                        <div className="col-md-4 col-sm-12 tiles bgimage">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 features">
                                    <h1>Applications</h1>
                                    <div className="line"></div>
                                    <p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                                    <div className="row pad">
                                        <div className="col-md-6 col-sm-12 d-none d-md-block">
                                            <ul>
                                                <li>Offices</li>
                                                <li>Schools</li>
                                                <li>Meeting Rooms</li>
                                                <li>Hotels</li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6 col-sm-12 d-none d-md-block">
                                            <ul>
                                                <li>Auditorium</li>
                                                <li>Recording Studios</li>
                                                <li>Conference halls</li>
                                                <li>Broadcasting Studios</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 printing">
                                    <h1>Custom Graphic Options for All Decorative Sound Absorbing Panels</h1>
                                    <div className="line"></div>
                                    <div className="row mb-4">
                                        <div className="col-12">
                                            <img className="w-100" src={Fabprint} alt="Fabprint" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 more">
                                        <h1>More Products</h1>
                                        <div className="line"></div>
                                    </div>
                                    <div className="accordion-started accordion-bral panels row">
                                        <div className="col-sm-12 col-md-12 col-lg-12 ">
                                            <label className="ac-label" htmlFor="ac-1">
                                                <b>FABRISORB <sup>TM</sup></b><br /> ACOUSTICAL WALL PANELS<i></i>
                                            </label>
                                            <div className="article ac-content">
                                                <Sliderfabrisorbwall />
                                                <p>
                                                    Acoustically enhanced decorative wall panels are designed for all types of walls that improves the acoustic quality of all types of spaces. <br /><br />
                                                    Edge Hardened Fabric Acoustic Panels have no internal frame, making them lightweight and suitable for multiple mounting techniques. The construction and integrity of frameless panels starts with a rigid fiberglass core which is extremely effective at absorbing sound. Fabric is tightly and evenly stretched around the core to prevent any visual imperfections such as wrinkles. Borders are treated to form hard, impact-resistant edges.
                                                </p>
                                                <h2>Product Details</h2>
                                                <br />
                                                <div className="col-sm-12">
                                                    <ul className="nav nav-tabs" role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link active show" data-toggle="tab" href="#home">SPECIFICATION</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#menu1">REVERBERATION</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#menu3">DATASHEET</a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content">
                                                        <div id="home" className="tab-pane active show"><br />
                                                            <table className="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">Core</th>
                                                                        <td>96kg/m3 Fiberglass Insulation with tissue facing</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Thickness (mm)</th>
                                                                        <td>20 – 25 – 40 – 50 mm, custom thickness are also available</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Sizes (mm)</th>
                                                                        <td>600 x 600 - 600 x 1200 - 1200 x 1200 - 1200 x 2400 & many more custom sizes </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Acoustic Properties</th>
                                                                        <td>NRC 0.75 – 1.15 ( varies with thickness and mounting )</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Edges</th>
                                                                        <td>Concealed and Hardened</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Edge Finishes</th>
                                                                        <td>Square / Radius / Bevel / Half Bevel</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Facing</th>
                                                                        <td>Fabric</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Colours</th>
                                                                        <td>Wide range of standard colours. Also available in custom colours</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Flamability</th>
                                                                        <td>Tested as per ASTM E84 and classNameified as className A</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Mounting type</th>
                                                                        <td>Type A, E200 & E400</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Installation</th>
                                                                        <td>Adhesive, Velcro, Impaling clips, Z clips etc</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div id="menu1" className="tab-pane fade"><br />
                                                            <h3>Sound Absorption Coefficients</h3>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Thickness</th>
                                                                        <th scope="col">125Hz</th>
                                                                        <th scope="col">250Hz</th>
                                                                        <th scope="col">500Hz</th>
                                                                        <th scope="col">1KHz</th>
                                                                        <th scope="col">2KHz</th>
                                                                        <th scope="col">4KHz</th>
                                                                        <th scope="col">NRC</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">20mm</th>
                                                                        <td>0.07</td>
                                                                        <td>0.32</td>
                                                                        <td>0.75</td>
                                                                        <td>0.99</td>
                                                                        <td>0.93</td>
                                                                        <td>0.85</td>
                                                                        <td>0.75</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">25mm</th>
                                                                        <td>0.09</td>
                                                                        <td>0.40</td>
                                                                        <td>0.91</td>
                                                                        <td>0.97</td>
                                                                        <td>0.92</td>
                                                                        <td>0.86</td>
                                                                        <td>0.80</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">40mm</th>
                                                                        <td>0.17</td>
                                                                        <td>0.82</td>
                                                                        <td>0.94</td>
                                                                        <td>0.98</td>
                                                                        <td>0.88</td>
                                                                        <td>0.83</td>
                                                                        <td>0.90</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">50mm</th>
                                                                        <td>0.22</td>
                                                                        <td>0.81</td>
                                                                        <td>1.24</td>
                                                                        <td>1.30</td>
                                                                        <td>1.21</td>
                                                                        <td>1.16</td>
                                                                        <td>1.15</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div id="menu3" className="tab-pane fade"><br />
                                                            <br />
                                                            <h3>ASI Wall Panels : Datasheet</h3>
                                                            <br />
                                                            <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/FABRISORB/Fabrisorb-Acoustic-Wall-Panels.pdf" className="brochure">Download</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line"></div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <label className="ac-label" htmlFor="ac-2">
                                                <b>FABRISORB <sup>TM</sup></b><br /> ACOUSTICAL CEILING PANELS<i></i>
                                            </label>
                                            <div className="article ac-content">
                                                <Sliderfabrisorbceiling />
                                                <p>
                                                    Acoustically enhanced decorative wall panels are designed for all types of walls that improves the acoustic quality of all types of spaces. <br /><br />
                                                    Edge Hardened Fabric Acoustic Panels have no internal frame, making them lightweight and suitable for multiple mounting techniques. The construction and integrity of frameless panels starts with a rigid fiberglass core which is extremely effective at absorbing sound. Fabric is tightly and evenly stretched around the core to prevent any visual imperfections such as wrinkles. Borders are treated to form hard, impact-resistant edges.
                                                </p>
                                                <h2>Product Details</h2>
                                                <br />
                                                <div className="col-sm-12">
                                                    <ul className="nav nav-tabs" role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link active" data-toggle="tab" href="#cp-home">SPECIFICATION</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#cp-menu1">REVERBERATION</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#cp-menu3">DATASHEET</a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content">
                                                        <div id="cp-home" className="container tab-pane active"><br />
                                                            <table className="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">Core</th>
                                                                        <td>96kg/m3 Fiberglass Insulation with tissue facing</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Thickness (mm)</th>
                                                                        <td>20 – 25 – 40 – 50 mm, custom thickness are also available</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Sizes (mm)</th>
                                                                        <td>600 x 600 - 600 x 1200 - 1200 x 1200 - 1200 x 2400 & many more custom sizes </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Acoustic Properties</th>
                                                                        <td>NRC 0.75 – 1.15 ( varies with thickness and mounting )</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Edges</th>
                                                                        <td>Concealed and Hardened</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Edge Finishes</th>
                                                                        <td>Square / Radius / Bevel / Half Bevel</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Facing</th>
                                                                        <td>Fabric</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Colours</th>
                                                                        <td>Wide range of standard colours. Also available in custom colours</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Flamability</th>
                                                                        <td>Tested as per ASTM E84 and classNameified as className A</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Mounting type</th>
                                                                        <td>Type A, E200 & E400</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Installation</th>
                                                                        <td>Adhesive, Velcro, Impaling clips, Z clips etc</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div id="cp-menu1" className="container tab-pane fade"><br />
                                                            <h3>Sound Absorption Coefficients</h3>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Thickness</th>
                                                                        <th scope="col">125Hz</th>
                                                                        <th scope="col">250Hz</th>
                                                                        <th scope="col">500Hz</th>
                                                                        <th scope="col">1KHz</th>
                                                                        <th scope="col">2KHz</th>
                                                                        <th scope="col">4KHz</th>
                                                                        <th scope="col">NRC</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">20mm</th>
                                                                        <td>0.07</td>
                                                                        <td>0.32</td>
                                                                        <td>0.75</td>
                                                                        <td>0.99</td>
                                                                        <td>0.93</td>
                                                                        <td>0.85</td>
                                                                        <td>0.75</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">25mm</th>
                                                                        <td>0.09</td>
                                                                        <td>0.40</td>
                                                                        <td>0.91</td>
                                                                        <td>0.97</td>
                                                                        <td>0.92</td>
                                                                        <td>0.86</td>
                                                                        <td>0.80</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">40mm</th>
                                                                        <td>0.17</td>
                                                                        <td>0.82</td>
                                                                        <td>0.94</td>
                                                                        <td>0.98</td>
                                                                        <td>0.88</td>
                                                                        <td>0.83</td>
                                                                        <td>0.90</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">50mm</th>
                                                                        <td>0.22</td>
                                                                        <td>0.81</td>
                                                                        <td>1.24</td>
                                                                        <td>1.30</td>
                                                                        <td>1.21</td>
                                                                        <td>1.16</td>
                                                                        <td>1.15</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div id="cp-menu2" className="container tab-pane fade"><br />
                                                            <br />
                                                            <h3>Acoustical Clouds : Brochure</h3>
                                                            <br />
                                                        </div>
                                                        <div id="cp-menu3" className="container tab-pane fade"><br />
                                                            <br />
                                                            <h3>Acoustical Clouds : Datasheet</h3>
                                                            <br />
                                                            <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/FABRISORB/Fabrisorb-Acoustical-Clouds.pdf" className="brochure">Download</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line"></div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <label className="ac-label" htmlFor="ac-3">
                                                <b>FABRISORB <sup>TM</sup></b><br /> ACOUSTICAL BAFFLES<i></i>
                                            </label>
                                            <div className="article ac-content">
                                                <Sliderfabrisorbbaffles />
                                                <p>
                                                    These decorative sound absorbing baffles are great for offices, schools, meeting rooms, music rooms, hotels, auditoriums, recording studios, braodcasting studios. These acoustic baffles are perfect for any space where good speech privacy or speech intelligibility is important.
                                                </p>
                                                <h2>Product Details</h2>
                                                <br />
                                                <div className="col-sm-12">
                                                    <ul className="nav nav-tabs" role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link active" data-toggle="tab" href="#cb-home">SPECIFICATION</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#cb-menu1">REVERBERATION</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#cb-menu3">DATASHEET</a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content">
                                                        <div id="cb-home" className="container tab-pane active"><br />
                                                            <table className="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">Core</th>
                                                                        <td>96kg/m3 Fiberglass Insulation with tissue facing</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Thickness (mm)</th>
                                                                        <td>40 – 50 mm, custom thickness are also available</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Sizes (mm)</th>
                                                                        <td>600 x 600 - 600 x 1200 - 1200 x 1200 - 1200 x 2400 & many more custom sizes </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Acoustic Properties</th>
                                                                        <td>NRC 0.90 – 1.15 ( varies with thickness and mounting )</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Edges</th>
                                                                        <td>Concealed and Hardened</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Edge Finishes</th>
                                                                        <td>Square / Radius </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Facing</th>
                                                                        <td>Fabric</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Colours</th>
                                                                        <td>Wide range of standard colours. Also available in custom colours</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Flamability</th>
                                                                        <td>Tested as per ASTM E84 and classNameified as className A</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Installation</th>
                                                                        <td>Twist Springs, Wire Clips etc</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div id="cb-menu1" className="container tab-pane fade"><br />
                                                            <h3>Sound Absorption Coefficients</h3>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Thickness</th>
                                                                        <th scope="col">125Hz</th>
                                                                        <th scope="col">250Hz</th>
                                                                        <th scope="col">500Hz</th>
                                                                        <th scope="col">1KHz</th>
                                                                        <th scope="col">2KHz</th>
                                                                        <th scope="col">4KHz</th>
                                                                        <th scope="col">NRC</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">40mm</th>
                                                                        <td>0.17</td>
                                                                        <td>0.82</td>
                                                                        <td>0.94</td>
                                                                        <td>0.98</td>
                                                                        <td>0.88</td>
                                                                        <td>0.83</td>
                                                                        <td>0.90</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">50mm</th>
                                                                        <td>0.22</td>
                                                                        <td>0.81</td>
                                                                        <td>1.24</td>
                                                                        <td>1.30</td>
                                                                        <td>1.21</td>
                                                                        <td>1.16</td>
                                                                        <td>1.15</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div id="cb-menu3" className="container tab-pane fade"><br />
                                                            <br />
                                                            <h3>Acoustical Baffles : Datasheet</h3>
                                                            <br />
                                                            <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/FABRISORB/Fabrisorb-Acoustical-Baffles.pdf" className="brochure">Download</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line"></div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <label className="ac-label" htmlFor="ac-4">
                                                <b>FABRISORB <sup>TM</sup></b><br /> ACOUSTICAL DESIGNER PANELS<i></i>
                                            </label>
                                            <div className="article ac-content">
                                                <Sliderfabrisorbdesigner />
                                                <p>
                                                    These acoustically decorative designer panels are designed for all spaces where aesthetics along with reflected noise from walls are a concern.They also add a 3D effect to the whole spcae which improves the overall looks of the space. They are highly recommended for spaces such as cinemas, schools, halls, museums.
                                                </p>
                                                <h2>Product Details</h2>
                                                <br />
                                                <div className="col-sm-12">
                                                    <ul className="nav nav-tabs" role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link active" data-toggle="tab" href="#dp-home">SPECIFICATION</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#dp-menu1">REVERBERATION</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#dp-menu3">DATASHEET</a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content">
                                                        <div id="dp-home" className="container tab-pane active"><br />
                                                            <table className="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">Core</th>
                                                                        <td>96kg/m3 Fiberglass Insulation with tissue facing</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Thickness (mm)</th>
                                                                        <td>20 – 25 – 40 – 50 mm, custom thickness are also available</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Sizes (mm)</th>
                                                                        <td>600 x 600 - 600 x 1200 - 1200 x 1200 - 1200 x 2400 & many more custom sizes </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Acoustic Properties</th>
                                                                        <td>NRC 0.75 – 1.15 ( varies with thickness and mounting )</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Edges</th>
                                                                        <td>Concealed and Hardened</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Edge Finishes</th>
                                                                        <td>Square / Radius / Bevel / Half Bevel</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Facing</th>
                                                                        <td>Fabric</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Colours</th>
                                                                        <td>Wide range of standard colours. Also available in custom colours</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Flamability</th>
                                                                        <td>Tested as per ASTM E84 and classNameified as className A</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Mounting type</th>
                                                                        <td>Type A, E200 & E400</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Installation</th>
                                                                        <td>Adhesive, Velcro, Impaling clips, Z clips etc</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div id="dp-menu1" className="container tab-pane fade"><br />
                                                            <h3>Sound Absorption Coefficients</h3>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Thickness</th>
                                                                        <th scope="col">125Hz</th>
                                                                        <th scope="col">250Hz</th>
                                                                        <th scope="col">500Hz</th>
                                                                        <th scope="col">1KHz</th>
                                                                        <th scope="col">2KHz</th>
                                                                        <th scope="col">4KHz</th>
                                                                        <th scope="col">NRC</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">20mm</th>
                                                                        <td>0.07</td>
                                                                        <td>0.32</td>
                                                                        <td>0.75</td>
                                                                        <td>0.99</td>
                                                                        <td>0.93</td>
                                                                        <td>0.85</td>
                                                                        <td>0.75</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">25mm</th>
                                                                        <td>0.09</td>
                                                                        <td>0.40</td>
                                                                        <td>0.91</td>
                                                                        <td>0.97</td>
                                                                        <td>0.92</td>
                                                                        <td>0.86</td>
                                                                        <td>0.80</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">40mm</th>
                                                                        <td>0.17</td>
                                                                        <td>0.82</td>
                                                                        <td>0.94</td>
                                                                        <td>0.98</td>
                                                                        <td>0.88</td>
                                                                        <td>0.83</td>
                                                                        <td>0.90</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">50mm</th>
                                                                        <td>0.22</td>
                                                                        <td>0.81</td>
                                                                        <td>1.24</td>
                                                                        <td>1.30</td>
                                                                        <td>1.21</td>
                                                                        <td>1.16</td>
                                                                        <td>1.15</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div id="dp-menu3" className="container tab-pane fade"><br />
                                                            <br />
                                                            <h3>Acoustical Designer Panels : Datasheet</h3>
                                                            <br />
                                                            <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/FABRISORB/Fabrisorb-Acoustical-Designer-Panels.pdf" className="brochure">Download</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line"></div>
                                    <div className="col-sm-12 download-button">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/Acoustical Surfaces-FABRISORB-Brochure.pdf">
                                                    <img className="mx-auto d-block" src={Fabrisorbbrochure} alt="fabrisorb brochure" />
                                                </Link>
                                            </div>
                                            <div className="col-sm-6">
                                                <Link to="https://acousticalsurfaces.in/pdfs/FABRISORB/ASI-FABRIC-SHADECARD-BLESS-STREAM.pdf">
                                                    <img className="mx-auto d-block" src={Fabrisorbshade} alt="Fabrisorb Shadecard" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mobsidebar'>
                        <div className="col-sm-12">
                            <div className="container">
                                <div className="row">
                                    <Mobsidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default FabrisorbDeco; 