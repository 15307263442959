import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Acousticsuderlayment1 from '../../images/inner/acoustik.webp';
import Acousticsuderlayment2 from '../../images/inner/ul-pic12.webp';
import Acousticsuderlayment3 from '../../images/inner/ul-pic2.webp';
import './acoustics-underlayment.css';
import ScrollToTopButton from '../ScrollButton';
function acousticunder() {
    return (
      
    <>
    <Helmet>
    <title>Acoustic Underlayment - Sound Absorbing Subflooring Vinyl Barriers</title>
    <meta name="description" content="" />
    <meta property="og:title" content="Noise Absorbing Vinyl Barrier" />
    <meta property="og:description" content="Acoustical – Sound Absorbing Subflooring" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/acoustik.webp" />
    <meta property="og:locale" content="en-in" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acoustik-underlayment" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/acoustics-underlayment" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/acoustics-underlayment" />
    </Helmet>
    <Header />
    <section className="content" id="underlayment">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                    </div>
                    <h1 className='underlayment-content'>Noise S.T.O.P.™ Vinyl Barrier</h1>
                    <div className="row pad underlayment-content">
                        <div className="col-xl-4 col-sm-12">
                            <Link className="example-image-link" to="../../images/inner/acoustik.webp" data-lightbox="example-1">
                            <img className="example-image img-fluid" src={Acousticsuderlayment1} alt="acoustics underlayment1" />
                            </Link>
                        </div>
                        <div className="col-xl-5 col-lg-8 col-md-8 col-sm-12 text">
                            <h2>Acoustical – Sound Absorbing Subflooring</h2>
                            <ul style={{marginleft: "1em"}}>                                                              
                                <li>High Performance Noise Control</li>
                                <li>Cost Effective</li>
                                <li>Recycled Rubber Materials</li>
                                <li>Easy to Install</li>
                            </ul>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <div className="testing-info">
                                <h5>Product Testing & Information</h5>
                                <ul className="">
                                    <li><Link to="https://acousticalsurfaces.in/pdfs/061629_Acoustical_Underlayment.doc" target="_blank">Specifications</Link></li>
                                    <li><Link to="https://acousticalsurfaces.in/pdfs/Construction_Details.pdf" target="_blank">Construction Details</Link></li>
                                    <li><Link to="https://acousticalsurfaces.in/pdfs/Acoustical_Test.pdf" target="_blank">Acoustical Tests</Link></li>
                                    <li><Link to="https://acousticalsurfaces.in/pdfs/Acoustik_FIIC_Test.pdf" target="_blank">FIIC Test at Carbonell</Link></li>
                                    <li><Link to="https://acousticalsurfaces.in/pdfs/FL_field_test.pdf" target="_blank">FIIC & FSTC Field Test</Link></li>
                                    <li><Link to="https://acousticalsurfaces.in/pdfs/Acoustik_Test_FIIC.pdf" target="_blank">FIIC Test 3/8″</Link></li>
                                    <li><Link to="https://acousticalsurfaces.in/pdfs/Acoustik_flame.pdf" target="_blank">Flammability Test</Link></li>
                                    <li><Link to="https://acousticalsurfaces.in/pdfs/RadiantTest1.pdf" target="_blank">Critical Radiant Flux Determination</Link></li>
                                    <li><Link to="https://acousticalsurfaces.in/pdfs/Acoustik-Recycled-Content-Certificate.pdf" target="_blank">Recycled Content Certificate</Link></li>
                                    <li><Link to="https://acousticalsurfaces.in/pdfs/acoustik.pdf" target="_blank">Product Specs</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="line"></div>
                    </div>
                    <div className="row underlayment-content">
                        <div className="col-sm-12">
                            <h3>Product Specs</h3>
                            <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                <tbody>
                                    <tr>
                                        <th>Material</th>
                                        <td>Recycled Rubber </td>
                                    </tr>
                                    <tr>
                                        <th>Pattern</th>
                                        <td>Flat </td>
                                    </tr>
                                    <tr>
                                        <th>Features</th>
                                        <td>Effective Sound Control </td>
                                    </tr>
                                    <tr>
                                        <th>Applications</th>
                                        <td>Sound Control Floor Underlayment. Homes, Offices, Condos, Home Theaters and Sound Rooms </td>
                                    </tr>
                                    <tr>
                                        <th>Thikness</th>
                                        <td>3/8″</td>
                                    </tr>
                                    <tr>
                                        <th>Roll Size</th>
                                        <td>4′×25′ – Sold in Full Rolls </td>
                                    </tr>
                                    <tr>
                                        <th>Color</th>
                                        <td>Black</td>
                                    </tr>
                                    <tr>
                                        <th>Flammability</th>
                                        <td>ASTM E-84: Flame Spread – 83; Smoke Developed – 315 </td>
                                    </tr>
                                    <tr>
                                        <th>Installation</th>
                                        <td>Adhesive Poly 300 or DA5</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br />
                    <h3 className='underlayment-content'>Testimonials</h3>
                    <div className="row underlayment-content"> 
                        <ul style={{marginLeft: "2em"}}>
                            <li><Link style={{color:"#0059a5"}} to="https://acousticalsurfaces.in/pdfs/Acoustik_Underlayment.pdf" target="_blank">Acoustik Underlayment Sub-flooring Used in Customer’s Bonus Room</Link></li>
                            <li><Link style={{color:"#0059a5"}} to="../../images/inner/Acoustik-floor-cutaway.webp" target="_blank">Acoustik Underlay Client Photo</Link></li>
                        </ul>
                    </div>
                    <div className="row underlayment-content">
                        <div className="col-sm-6">
                            <Link className="example-image-link" to="../../images/inner/ul-pic12.webp" data-lightbox="example-1">
                            <img className="example-image img-fluid" src={Acousticsuderlayment2} alt="acoustics underlayment2" /></Link>
                            <p><strong>COMPOSITION:</strong></p>
                            <ol style={{marginleft: "1em"}}>
                                <li>Wood Floor</li>
                                <li>Adhesive</li>
                                <li>8-mm (5/16″) Acoustik Membrane</li>
                                <li>Adhesive</li>
                                <li>Concrete slab</li>
                            </ol>
                            <p><strong>FIIC 62</strong></p>
                        </div>
                        <div className="col-sm-6">
                            <Link className="example-image-link" to="../../images/inner/ul-pic2.webp" data-lightbox="example-1">
                            <img className="example-image img-fluid" src={Acousticsuderlayment3} alt="acoustics underlayment3" /></Link>
                            <p><strong>COMPOSITION:</strong></p>
                            <ol style={{marginleft: "1em"}}>
                                <li>Wood Floor</li>
                                <li>Adhesive</li>
                                <li>8-mm (5/16″) Acoustik Membrane</li>
                                <li>Adhesive</li>
                                <li>38-mm (1 1/2″) Concrete Slab</li>
                                <li>11.1-mm (7/16″) Particleboard</li>
                                <li>38×235-mm (2″×10″) Floor Joist</li>
                                <li>RSI 1.470-mm (2 3/4″) Sound Insulation</li>
                            </ol>
                            <p><strong>FIIC 61</strong></p>
                        </div>
                        <div className="col-sm-12">
                            <h3>25 Year Limited Warranty:</h3>
                            <p> The 25 year limited warranty is offered by Tuile Duro Inc. Acoustik is warranted 
                            against degradation of the physical properties of the products rubber material when 
                            installed in accordance with the manufactures installation instructions. The limited 
                            warranty covers replacement of the Acoustik product only, labor, materials and other 
                            cost to repair and replace floor components are not warranted no other warranty is 
                            either expressed or implied. All liability associated with the use of Acoustik is 
                            assumed by Tuile Duro Inc.
                            </p>
                        </div>
                        <div className="col-sm-12"><h3>Adhesives</h3></div>
                        <ul style={{marginLeft: "1em"}}><strong>DA5</strong>
                        <ul>
                            <li style={{listStyle: "none"}}><Link style={{color:"#0059a5"}} to="https://acousticalsurfaces.in/pdfs/DA5_info.pdf" target="_blank">DA5 Spec Sheet</Link></li>
                            <li style={{listStyle: "none"}}><Link style={{color:"#0059a5"}} to="https://acousticalsurfaces.in/pdfs/DA5_MSDS.pdf" target="_blank">DA5 MSDS</Link></li>
                        </ul>
                        <ul><strong>Poly 300</strong>
                            <li style={{listStyle: "none"}}><Link style={{color:"#0059a5"}} to="https://acousticalsurfaces.in/pdfs/Poly_300_Info.pdf" target="_blank">Poly 300 Spec Sheet</Link></li>
                            <li style={{listStyle: "none"}}><Link style={{color:"#0059a5"}} to="https://acousticalsurfaces.in/pdfs/Poly_300_MSDS.pdf" target="_blank">Poly 300 MSDS</Link></li>
                        </ul>
                        </ul>
                    </div>
                    <hr className="d-none d-md-block d-lg-block d-xl-block" />
                </div>    
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acousticunder;