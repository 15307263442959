import React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import './bs-rooms-guide.css';
import ScrollToTopButton from '../ScrollButton';
function bsrooms() {
    return (
      
    <>
    <Helmet>
    <title>Easily Expand Your Acoustical Knowledge - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="Easily Expand Your Acoustical Knowledge" />
    <meta property="og:description" content="Learn the differences between sound blocking, absorption, and diffusion by taking advantage of this eBook offer." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/additional-resources/ebook-acoustics-demyst.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/bs-rooms-guide" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="bsrooms">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h3>Easily Expand Your Acoustical Knowledge</h3>
                            <p>Learn the differences between sound blocking, absorption, and diffusion by taking advantage of this eBook offer. </p>
                            <div className="row">
                                <div className="col-lg-7 col-md-7 col-sm-12">
                                    <p><br />Inside this eBook you will find:</p>
                                    <p style={{marginLeft: "2em"}}>How and why sound is blocked, absorbed or diffused<br /><br /> Properties of each and how they are incorporated into structures<br /><br /> Differences in these sound definitions <br /><br />Corresponding products that lead to each process.</p>
                                </div>
                                <div className="col-lg-5 col-md-5 col-sm-12">
                                    <img src="images/additional-resources/ebook-acoustics-demyst.webp" alt="eBook" className="img-fluid" />
                                </div>
                            </div>
                        <p> To download this educational eBook, <strong>Acoustics Demystified: Creating Better-Sounding Rooms Is Easier Than You Think</strong>, fill out the form on the right.</p>
                        </div>
                    </div>
                    <div className="line"></div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default bsrooms;