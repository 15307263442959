import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Duracoustic1 from '../../images/inner/duracousticnew.webp';
import Duracoustic2 from '../../images/inner/duracoustic-diagram-stc64-iic55.webp';
import Duracoustic3 from '../../images/inner/duracoustic-diagram-stc70-iic57.webp';
import Duracoustic4 from '../../images/inner/duracoustic-diagram-stc55-iic52.webp';
import './duracoustic.css';
import ScrollToTopButton from '../ScrollButton';
function duracoustic() {
    return (
      
    <>
    <Helmet>
    <title>Duracoustic - Best sound absorption material</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Duracoustic Floor Noise Reduction" />
    <meta property="og:description" content="Duracoustic sound control underlayment offers a simple but very effective solution for creating floor systems." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/duracousticnew.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/duracoustic" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/duracoustic" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/duracoustic" />
    </Helmet>
    <Header />
    <section className="content" id="duracoustic">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>DURACOUSTIC S.T.O.P.™ </h1>  
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/duracousticnew.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Duracoustic1} alt="Duracoustics1" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Floor Impact Noise Reduction Underlayment</h2>   
                                <ul style={{marginleft: "1em"}}>                                                       
                                    <li>Cost Effective</li>
                                    <li>Reduces Impact Airborne Floor Noise</li>
                                    <li>For Use with Variety of Floor Finishes</li>
                                    <li>Recycled Materials</li>
                                </ul>    
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/Duracoustic.pdf" target="_blank">Product Specs</Link>
                                        </li>
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/Duracoustic_MSDS.pdf" target="_blank">MSDS</Link>
                                        </li>
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/Duracoustic_Installation.pdf">Installation Instructions</Link>
                                        </li>                                        
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row pad duracoustic-content">
                            <div className="col-sm-12 text">
                                <h3>Features & Benefits</h3>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>Greatly reduces impact sound; rubber is the best sound absorption material</li>
                                    <li>Increases IIC and STC ratings</li>
                                    <li>Does not compress and therefore holds its acoustical value over time</li>
                                    <li>Easy installation&nbsp;&ndash; will not slip or break down during installation</li>
                                    <li>Conceals minor subfloor irregularities</li>
                                    <li>Works extremely well over radiant heated floors</li>
                                    <li>Resists mold, mildew and fungus growth</li>
                                </ul>
                            </div>
                            <div className="col-sm-12 text">
                                <h3>Environmentally Friendly</h3>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>Made with 85% recycled tire rubber and is 100% recyclable</li>
                                    <li>Low VOCs</li>
                                    <li>Can contribute to LEED credits</li>
                                </ul>
                                <p></p>
                                <p><b>Duracoustic</b> sound control underlayment offers a simple but very effective solution for creating floor systems which meet stringent sound ratings in multiple-story dwellings, condominiums, hotels, apartments, private homes and business applications including old building conversions into condominiums.</p>
                                <p>Duracoustic is made from 85% recycled rubber. It is a reinforced rubber mat with an open cell structure which enhances its sound deadening properties making possible the installation of cost effective sound rated floors.</p>
                                <p>The Duracoustic underlayment system acts as a decoupler by breaking the sound transmission path through the floor-ceiling assembly and prevents noise flanking room to room. Testing has demonstrated outstanding sound attenuation with a variety of construction assemblies and floor coverings including ceramic tile, hardwood, laminate and engineered wood floors.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Product Specs</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>MATERIAL</th>
                                            <td>Open cellular rubber bonded to fiberglass/cellulose backing scrim.</td>
                                        </tr>
                                        <tr>
                                            <th>PATTERN</th>
                                            <td>Flat Textured </td>
                                        </tr>
                                        <tr>
                                            <th>FEATURES</th>
                                            <td>Conceals floor irregularities. Resists mold, mildew and fungus growth. Designed for high traffic, long lasting floor impact noise control.</td>
                                        </tr>
                                        <tr>
                                            <th>APPLICATIONS</th>
                                            <td>Floor/ceiling applications requiring sound privacy. Apartments, condominiums, schools, offices, hospitals.</td>
                                        </tr>
                                        <tr>
                                            <th>THICKNESS</th>
                                            <td>5/16″</td>
                                        </tr>
                                        <tr>
                                            <th>SIZES</th>
                                            <td>54″ × 24′ (108 sq. ft. per roll)</td>
                                        </tr>
                                        <tr>
                                            <th>WEIGHT</th>
                                            <td>65lb roll (.6 lbs./sq.ft.)</td>
                                        </tr>
                                        <tr>
                                            <th>COLOR</th>
                                            <td>Black</td>
                                        </tr>
                                        <tr>
                                            <th>FLAMMABILITY</th>
                                            <td>ASTM E-648 Radiant Panel className 1 (FF1-70 PASS) </td>
                                        </tr>
                                        <tr>
                                            <th>INSTALLATION</th>
                                            <td>DAA5 or Poly 300 Adhesive </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="6">Test Results</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="6">Click Any Image for Larger View</td>
                                        </tr>
                                        <tr className="colheader">
                                            <td colSpan="2">Hardwood Floor<br />over Wood Assembly</td>
                                            <td colSpan="2">Ceramic Floor<br />over Wood Assembly</td>
                                            <td colSpan="2">Ceramic Tile<br />over a 6″ Concrete Slab</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">Download <Link target="_blank" style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/Duracoustic-Test-under-Wood-STC-64-IIC-55-Test.pdf" title="Click to Download PDF">STC IIC Test Data</Link></td>
                                            <td colSpan="2">Download <Link target="_blank" style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/Duracoustic-Test-under-Ceramic-STC-70-IIC-57.pdf" title="Click to Download PDF">STC IIC Test Data</Link></td>
                                            <td colSpan="2">Download <Link target="_blank" style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/Duracoustic-Test-6-Inch-Concrete-STC-55-IIC-52.pdf" title="Click to Download PDF">STC IIC Test Data</Link></td>
                                        </tr>
                                        <tr className="colheader">
                                            <td>STC</td>
                                            <td>IIC</td>
                                            <td>STC</td>
                                            <td>IIC</td>
                                            <td>STC</td>
                                            <td>IIC</td>
                                        </tr>
                                        <tr>
                                            <td className="emphasis">64</td>
                                            <td className="emphasis">55</td>
                                            <td className="emphasis">70</td>
                                            <td className="emphasis">57</td>
                                            <td className="emphasis">55</td>
                                            <td className="emphasis">52</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <Link className="example-image-link" to="../../images/inner/duracoustic-diagram-stc64-iic55.webp" data-lightbox="example-1">
                                                    <img className="example-image img-fluid" src={Duracoustic2} alt="Duracoustics2" />
                                                </Link>
                                            </td>
                                            <td colSpan="2">
                                                <Link className="example-image-link" to="../../images/inner/duracoustic-diagram-stc70-iic57.webp" data-lightbox="example-1">
                                                    <img className="example-image img-fluid" src={Duracoustic3} alt="Duracoustics3" />
                                                </Link>
                                            </td>
                                            <td colSpan="2">
                                                <Link className="example-image-link" to="../../images/inner/duracoustic-diagram-stc55-iic52.webp" data-lightbox="example-1">
                                                    <img className="example-image img-fluid" src={Duracoustic4} alt="Duracoustics4" />
                                                </Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <table className="table table-bordered-2" style={{background: "#f5f5f5"}}>
                                    <thead>
                                        <tr>
                                            <th colSpan="8">Airborne Sound Transmission className (STC)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td>Assembly</td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>STC</td>
                                        </tr>
                                        <tr>
                                            <td>Hardwood Floor<br /> over Wood Assembly</td>
                                            <td>42</td>
                                            <td>53</td>
                                            <td>70</td>
                                            <td>83</td>
                                            <td>92</td>
                                            <td>100</td>
                                            <td className="emphasis">64</td>
                                        </tr>
                                        <tr>
                                            <td>Ceramic Floor<br /> over Wood Assembly</td>
                                            <td>49</td>
                                            <td>58</td>
                                            <td>67</td>
                                            <td>81</td>
                                            <td>91</td>
                                            <td>99</td>
                                            <td className="emphasis">70</td>
                                        </tr>
                                        <tr>
                                            <td>Ceramic Tile<br /> over a 6″ Concrete Slab</td>
                                            <td>37</td>
                                            <td>43</td>
                                            <td>64</td>
                                            <td>77</td>
                                            <td>86</td>
                                            <td>92</td>
                                            <td className="emphasis">55</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered-2" style={{background: "#f5f5f5"}}>
                                    <thead>
                                        <tr>
                                            <th colSpan="8">Impact Insulation className (IIC)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td>Assembly</td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>IIC</td>
                                        </tr>
                                        <tr>
                                            <td>Hardwood Floor<br /> over Wood Assembly</td>
                                            <td>63</td>
                                            <td>55</td>
                                            <td>47</td>
                                            <td>30</td>
                                            <td>19</td>
                                            <td>13</td>
                                            <td className="emphasis">55</td>
                                        </tr>
                                        <tr>
                                            <td>Ceramic Floor<br /> over Wood Assembly</td>
                                            <td>57</td>
                                            <td>58</td>
                                            <td>54</td>
                                            <td>48</td>
                                            <td>46</td>
                                            <td>35</td>
                                            <td className="emphasis">57</td>
                                        </tr>
                                        <tr>
                                            <td>Ceramic Tile<br /> over a 6″ Concrete Slab</td>
                                            <td>66</td>
                                            <td>64</td>
                                            <td>55</td>
                                            <td>48</td>
                                            <td>38</td>
                                            <td>34</td>
                                            <td className="emphasis">52</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default duracoustic;