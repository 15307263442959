import React from 'react';
import {Helmet} from 'react-helmet';
function soundprooffabrisorb() {
    return (
      
    <>
    <Helmet>
    <title>FABRIC S.T.O.P. FABRISORB - Decorative Fabric Wrapped Acoustical Baffles </title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/soundproofing-fabrisorb" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/soundproofing-fabrisorb" />
    </Helmet>
        <embed src="https://www.acousticalsurfaces.in/pdfs/Noise-S.T.O.P.-Fabrisorb.pdf#toolbar=0&amp;navpanes=0&amp;scrollbar=0" type="application/pdf" width="100%" height="1100px" title="soundproof fabrisorb" />     
    </>
);
}
export default soundprooffabrisorb; 