import React from 'react';
import { Link } from 'react-router-dom';
import './slider-woodtec.css';
import Banner1 from '../../images/woodtec/1.webp';
import Banner2 from '../../images/woodtec/2.webp';
import Banner3 from '../../images/woodtec/3.webp';
import Banner4 from '../../images/woodtec/4.webp';
function slider() {
	return (
	  
	<>
	<div id="slider-woodtec">
		<div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
  			<ol className="carousel-indicators">
    			<li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
    			<li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    			<li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    			<li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
  			</ol>
  			<div className="carousel-inner">
    			<div className="carousel-item active">
      				<img className="d-block w-100" src={Banner1} alt="Linear Panels" />
	  				<div className="carousel-caption d-none d-md-block">
					  	<h1>Linear Panels</h1>
						<i className="fa fa-download" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/catalogue/woodtec/woodtec-brochure.pdf" target="_blank" className="demo-cont__credits-link">Linear Panels Brochure</Link>
                    </div>
				</div>
    			<div className="carousel-item">
      				<img className="d-block w-100" src={Banner2} alt="Perforated Panels" />
	  				<div className="carousel-caption d-none d-md-block">
					  	<h1>Perforated Panels</h1>
					  	<i className="fa fa-download" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/catalogue/woodtec/woodtec-brochure.pdf" target="_blank" className="demo-cont__credits-link">Perforated Panels Brochure</Link>
                    </div>
				</div>
				<div className="carousel-item">
      				<img className="d-block w-100" src={Banner3} alt="Slotted Panels" />
	  				<div className="carousel-caption d-none d-md-block">
					  	<h1>Slotted Panels</h1>
						<i className="fa fa-download" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/catalogue/woodtec/woodtec-brochure.pdf" target="_blank" className="demo-cont__credits-link">Slotted Panels Brochure</Link>
                    </div>
				</div>
				<div className="carousel-item">
      				<img className="d-block w-100" src={Banner4} alt="Designer Panels" />
	  				<div className="carousel-caption d-none d-md-block">
					  	<h1>Designer Panels</h1>
						<i className="fa fa-download" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/catalogue/woodtec/woodtec-brochure.pdf" target="_blank" className="demo-cont__credits-link">Designer Panels Brochure</Link>
                    </div>
				</div>
			</div>
  			<a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    			<span className="carousel-control-prev-icon" aria-hidden="true"></span>
    			<span className="sr-only">Previous</span>
  			</a>
  			<a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    			<span className="carousel-control-next-icon" aria-hidden="true"></span>
    			<span className="sr-only">Next</span>
  			</a>
		</div>
	</div>
	</>
);
}
export default slider;