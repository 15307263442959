import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Multiunitresi1 from '../../images/inner/rsic-1-clip-furring-channel-200x177.webp';
import Multiunitresi2 from '../../images/inner/green-glue-group-200x200.webp';
import Multiunitresi3 from '../../images/inner/acoustik1-200x200.webp';
import Multiunitresi4 from '../../images/inner/recycled_logo_jeans-200x200.webp';
import Multiunitresi5 from '../../images/inner/doorseal11-200x200.webp';
import Multiunitresi6 from '../../images/inner/acoustical_window-21-200x200.webp';
import './multiunit-resi.css';
import ScrollToTopButton from '../ScrollButton';
function multiunit() {
    return (
      
    <>
    <Helmet>
    <title>Residential Buildings soundproofing apartment walls and floors</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Residential Soundproofing Products" />
    <meta property="og:description" content="Our recycled rubber underlayment feature STC and IIC ratings that are proven to be the best in the industry." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/acoustik1-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/multiunit-resi" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/multiunit-resi" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/multiunit-resi" />
    </Helmet>
    <Header />
    <section className="content">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Soundproofing Multi-Unit Residential Buildings</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>Properly soundproofing apartment walls and floors will make for happy tenants in situations where people live together in multi-unit residential properties. Whether the noise is outside or inside the building, we have the right solutions to prevent noise pollution from becoming an issue.</p>
                                    <p>Acoustical Surfaces engineers and manufactures a wide range of construction products for noise control applications. We have products for soundproofing aparatment walls, ceilings, flooring assemblies, doors and windows. Our unique sound isolation clips prevent noise from penetrating through ceiling, floor, and wall assemblies. Our recycled rubber underlayment feature STC and IIC ratings that are proven to be the best in the industry. The Climate Seal window inserts are custom made, retro fit window solutions that install quickly and easily and offer significant reduction to noise coming through windows.</p>
                                    <p>We have products that suit any application and are designed to meet building standards and certifications. For some examples of our outstanding products, please see the list above or <Link to="/contact">contact us</Link> today.</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>Residential Soundproofing Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Multiunitresi1} alt="RSIC-1 Clips" />
                                </Link>
                                <h5>RSIC-1 Clips</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Multiunitresi2} alt="Green Glue" />
                                </Link>
                                <h5>Green Glue</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Multiunitresi3} alt="Acoustik Underlament" />
                                </Link>
                                <h5>Acoustik Underlament</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Multiunitresi4} alt="Ultra Touch Insulation" />
                                </Link>
                                <h5>Ultra Touch Insulation</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Multiunitresi5} alt="Adjustable Door Seal Kits" />
                                </Link>
                                <h5>Adjustable Door Seal kits</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Multiunitresi6} alt="Climate Seal Window" />
                                </Link>
                                <h5>Climate Seal Windows</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default multiunit; 