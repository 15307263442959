import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Fabriwalltrack1 from '../../images/fabwall/track1.webp';
import Fabriwalltrack2 from '../../images/fabwall/track2.webp';
import Fabriwalltrack3 from '../../images/fabwall/track3.webp';
import Fabriwalltrack4 from '../../images/fabwall/track4.webp';
import Fabriwalltrack5 from '../../images/fabwall/track5.webp';
import Fabriwalltrack6 from '../../images/fabwall/track6.webp';
import Fabriwalltrack7 from '../../images/fabwall/track7.webp';
import Fabriwalltrack8 from '../../images/fabwall/track8.webp';
import Fabriwalltrack9 from '../../images/fabwall/track9.webp';
import Fabriwalltrack10 from '../../images/fabwall/track10.webp';
import Fabriwalltrack11 from '../../images/fabwall/track11.webp';
import Fabriwalltrack12 from '../../images/fabwall/track12.webp';
import Fabriwalltrack13 from '../../images/fabwall/track13.webp';
import Fabriwalltrack14 from '../../images/fabwall/track14.webp';
import Fabriwalltrack15 from '../../images/fabwall/track15.webp';
import Fabriwalltrack16 from '../../images/fabwall/track16.webp';
import Card1 from '../../images/fabwall/card1.webp';
import Card2 from '../../images/fabwall/card2.webp';
import Card3 from '../../images/fabwall/card3.webp';
import Mobsidebar from '../mobile-sidebar';
import './fabriwallTrack.css';
import ScrollToTopButton from '../ScrollButton';
function fabriwallTrack() {
    return (
      
    <>
    <Helmet>
    <title>Fabric wall Track - fabric stretched absorption panel to control sound</title>
    <meta name="description" content="" />
    <meta property="og:title" content="Tracks are on-site fabricated acoustical systems" />
    <meta property="og:description" content="Fabri-Wall is the industry-leading acoustical stretched fabric mounting system for walls, ceilings and other interior surfaces." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/fabwall/card1.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/fabriwallTrack" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/fabriwallTrack" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/fabriwallTrack" hreflang="en-ae" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="IN" />
    <meta name="geo.region" content="india" />
    </Helmet>
    <Header />
    <section className="content" id="Fabriwalltrack">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
	                    <div className="col-sm-12 heading-fsorb">				
		                    <img src={Fabriwalltrack1} alt="Fabriwall Track1" />
		                    <h1>Fabri<span className="fabriwall">WALL</span> TRACK</h1>
		                    <br />
		                    <p>Tracks are on-site fabricated acoustical systems that enables fabric to be stretched for interior architectural works. The fabric is stretched over the semi rigid absorption panel to control sound. They are aesthetically pleasing and provides flexibility with onsite fabricated installation.
                            They fit perfectly to any design concept, courtesy to their versatility and the availability of wide range of fabric selections. They are available in over 500 different designs.</p>
	                    </div>
                        <div className="col-sm-12 features">
	                        <h4>ON-SITE ASSEMBLY AND INSTALLATION</h4>
	                        <p>FabriWALL Tracks can be built and installed on site, which in turn ensures proper fitting. In addition, this allows making last minute alterations before installation if required to suit the updated measurements.
                            FabriWALL Tracks are easier to mount and are normally installed with traditional track profiles or magnets in order to be easily dismounted.</p>
		                    <h5>The system is available in three depths :</h5>
		                    <header>13 mm</header>
		                    <header>25 mm</header>
		                    <header>50 mm</header>
		                    <br /><br />
		                    <h4>PRODUCT FEATURES</h4>
		                    <br />
	                        <div className="col-sm-12">
	                            <ul>
	    		                    <li>Suitable for wall use</li>
        		                    <li>Customisable to any shape</li>
        		                    <li>Flexible and easily adjustable during installation</li>
        		                    <li>Made with at least 25 % recycled fiberglass</li>
            	                    <li>Excellent noise reduction</li>
		                            <li>Resists microbial growth</li>
		                            <li>Structurally rigid</li>
			                    </ul>
	                        </div>
	                    </div>
	                    <div className="col-sm-12 featured-products">
	                        <br /><br />
	                        <h1><strong>TRACK / FRAME SYSTEMS</strong> Components</h1>
	                        <div className="row pb-2">
    	                        <div className="col-md-4 col-sm-12 featured-card">
				                    <div className="card">
		  			                    <img className="card-img-top" src={Fabriwalltrack2} alt="Frame Systems" />
  		                                <div className="card-block">
    					                    <div className="card-text">
			               	                    <ul type="square">
  					                                <li>2" Profile</li>
  					                                <li>Center Track</li>
  					                                <li>54 : 51 mm</li>
				                                </ul>
				                            </div>
    				                    </div>
            	                    </div>
		                        </div>
			                    <div className="col-md-4 col-sm-12 featured-card">
			                        <div className="card">
				                        <img className="card-img-top" src={Fabriwalltrack3} alt="Fabwall Featured Card" />
  			                            <div className="card-block">
        				                    <div className="card-text">
				                                <ul type="square">
      					                            <li>2" Profile</li>
  					                                <li>Side Track</li>
  					                                <li>44 : 51 mm</li>
				                                </ul>
				                            </div>
    			                        </div>
			                        </div>
		                        </div>
			                    <div className="col-md-4 col-sm-12 featured-card">
			                        <div className="card">
          			                    <img className="card-img-top" src={Fabriwalltrack4} alt="Fabwall Track" />
  			                            <div className="card-block">
    				                        <div className="card-text">
				                                <ul type="square">
  					                                <li>2" Profile</li>
  					                                <li>Square Track</li>
  					                                <li>44.45 : 50.8 mm</li>
				                                </ul>
				                            </div>
    			                        </div>
				                    </div>
			                    </div>
		                    </div>
		                    <div className="row pb-2">
			                    <div className="col-md-4 col-sm-12 featured-card">
		    	                    <div className="card">
			    	                    <img className="card-img-top" src={Fabriwalltrack5} alt="Featured Fabwall" />
  			                            <div className="card-block">
    		    		                    <div className="card-text">
				                                <ul type="square">
  					                                <li>1" Profile</li>
  					                                <li>Center Track</li>
  					                                <li>50.8 : 25.4 mm</li>
				                                </ul>
				                            </div>
          			                    </div>
		                            </div>
		                        </div>
			                    <div className="col-md-4 col-sm-12 featured-card">
		                            <div className="card">
			                            <img className="card-img-top" src={Fabriwalltrack6} alt="Square Fabwall" />
  			                            <div className="card-block">
    		                                <div className="card-text">
				                                <ul type="square">
  					                                <li>1" Profile</li>
  					                                <li>Side Track</li>
  					                                <li>29 : 25.4 mm</li>
				                                </ul>
				                            </div>
    				                    </div>
		                            </div>
		                        </div>
			                    <div className="col-md-4 col-sm-12 featured-card">
				                    <div className="card">
					                    <img className="card-img-top" src={Fabriwalltrack7} alt="Fabwall Square Track" />
  			                            <div className="card-block">
    					                    <div className="card-text">
				                                <ul type="square">
  					                                <li>1" Profile</li>
  					                                <li>Square Track</li>
  					                                <li>38 : 25.4 mm</li>
				                                </ul>
				                            </div>
      			                        </div>
				                    </div>
			                    </div>
		                    </div>
		                    <div className="row pb-2">
			                    <div className="col-md-4 col-sm-12 featured-card">
				                    <div className="card">
					                    <img className="card-img-top" src={Fabriwalltrack8} alt="Square Card Track" />
  			                            <div className="card-block">
    					                    <div className="card-text">
				                                <ul type="square">
  					                                <li>1/2" Profile</li>
  					                                <li>Center Track</li>
  					                                <li>29 : 13 mm</li>
				                                </ul>
				                            </div>
    			  	                    </div>
				                    </div>
			                    </div>
			                    <div className="col-md-4 col-sm-12 featured-card">
				                    <div className="card">
			  		                    <img className="card-img-top" src={Fabriwalltrack9} alt="Card Square" />
  			                            <div className="card-block">
    					                    <div className="card-text">
		        		                        <ul type="square">
  					                                <li>1/2" Profile</li>
  					                                <li>Side Track</li>
  					                                <li>27 : 13 mm</li>
				                                </ul>
				                            </div>
    				                    </div>
				                    </div>
			                    </div>
			                    <div className="col-md-4 col-sm-12 featured-card">
		    	                    <div className="card">
					                    <img className="card-img-top" src={Fabriwalltrack10} alt="Square Fabwall Profile" />
  			                            <div className="card-block">
        				                    <div className="card-text">
				                                <ul type="square">
  					                                <li>1/2" Profile</li>
  					                                <li>Square Track</li>
  					                                <li>37 : 13 mm</li>
				                                </ul>
				                            </div>
            		                    </div>
		                            </div>
		                        </div>
		                    </div>
		                    <h2>BEVELLED TRACKS</h2>
		                    <div className="row pb-2">
		                        <div className="col-md-4 col-sm-12 featured-card">
		                            <div className="card">
			 		                    <img className="card-img-top" src={Fabriwalltrack11} alt="Featured Fabwall Square" />
  			                            <div className="card-block">
    					                    <div className="card-text">
				                                <ul type="square">
  					                                <li>1" Profile</li>
  					                                <li>Side Track</li>
  					                                <li>31.75 : 25.4 mm</li>
				                                </ul>
				                            </div>
    				                    </div>
				                    </div>
			                    </div>
			                    <div className="col-md-4 col-sm-12 featured-card">
		    	                    <div className="card">
					                    <img className="card-img-top" src={Fabriwalltrack12} alt="Square Profile Fabwall" />
  			                            <div className="card-block">
    					                    <div className="card-text">
				                                <ul type="square">
  					                                <li>1" Profile</li>
  					                                <li>Corner Track</li>
  					                                <li>35 : 25 mm</li>
				                                </ul>
				                            </div>
          			                    </div>
				                    </div>
			                    </div>
			                    <div className="col-md-4 col-sm-12 featured-card">
				                    <div className="card">
					                    <img className="card-img-top" src={Fabriwalltrack13} alt="Card Fabwall" />
  			                            <div className="card-block">
    					                    <div className="card-text">
				                                <ul type="square">
  					                                <li>1" Profile</li>
  					                                <li>Center Track</li>
  					                                <li>48.26 : 25.4 mm</li>
				                                </ul>
				                            </div>
    		                            </div>
        		                    </div>
		                        </div>
		                    </div>
		                    <h2>OTHER TRACKS</h2>
	                        <div className="row pb-5">
			                    <div className="col-md-4 col-sm-12 featured-card">
				                    <div className="card">
					                    <img className="card-img-top" src={Fabriwalltrack14} alt="Featured Fabwall" />
  			                            <div className="card-block">
    		                                <div className="card-text">
				                                <ul type="square">
  					                                <li>1" Profile</li>
  					                                <li>Mid-Stream Track</li>
  					                                <li>31.75 : 25.4 mm</li>
				                                </ul>
				                            </div>
    				                    </div>
		                            </div>
		                        </div>
		                        <div className="col-md-4 col-sm-12 featured-card">
		                            <div className="card">
			 		                    <img className="card-img-top" src={Fabriwalltrack15} alt="Square Profile Fabwall Track" />
  			                            <div className="card-block">
    					                    <div className="card-text">
				                                <ul type="square">
  					                                <li>1/2" Profile</li>
  					                                <li>SQ Edge Track</li>
  					                                <li>31.75 : 25.4 mm</li>
				                                </ul>
				                            </div>
    		                            </div>
				                    </div>
			                    </div>
			                    <div className="col-md-4 col-sm-12 featured-card">
				                    <div className="card">
			 		                    <img className="card-img-top" src={Fabriwalltrack16} alt="Mounting System" />
  			                            <div className="card-block">
    					                    <div className="card-text">
				                                <ul type="square">
  					                                <li>1/2" Profile</li>
  					                                <li>Outside Corner Track</li>
  					                                <li>34.2 : 27.29 mm</li>
				                                </ul>
				                            </div>
    				                    </div>
				                    </div>
			                    </div>
		                    </div>
	                    </div>
	                    <div className="col-sm-12 featured-products">
		                    <p>Fabri-Wall is the industry-leading acoustical stretched fabric mounting system for
			                walls, ceilings and other interior surfaces.</p>
		                    <br /><br />
		                    <h4>FEATURED PRODUCTS</h4>
		                    <br /><br />
		                    <div className="row pb-5">
	    	                    <div className="col-md-4 col-sm-12 featured-card">
		                            <div className="card text-center">
			                            <img className="card-img-top" src={Card1} alt="Fabric Wall Track" />
  			                            <div className="card-block">
    			                            <h4 className="card-title">Fabri<span>Wall</span> TRACK</h4>
    			                            <p className="card-text">Tracks are on-site fabricated acoustical systems that enables fabric be stretched for interior works.</p>
    			                            <Link to="/fabriwallTrack"className="btn btn-danger">View Track</Link>
  			                            </div>
				                    </div>
			                    </div>
			                    <div className="col-md-4 col-sm-12 featured-card">
		    	                    <div className="card text-center">
			 	                        <img className="card-img-top" src={Card2} alt="Fabric Wall Fabwall" />
  			                            <div className="card-block">
    			                            <h4 className="card-title">Fabri<span>Wall</span> FABRIC</h4>
    			                            <p className="card-text">We manufacture all kind of interior panel and upholstery fabrics using finest quality of Polyster</p>
    			                            <Link to="/fabriwallfabric" className="btn btn-danger">View Fabric</Link>
  			                            </div>
				                    </div>
			                    </div>
			                    <div className="col-md-4 col-sm-12 featured-card">
				                    <div className="card text-center">
			    	                    <img className="card-img-top" src={Card3} alt="Fabri Wall Panel" />
  			                            <div className="card-block">
    			                            <h4 className="card-title">Fabri<span>Wall</span> PANEL</h4>
    			                            <p className="card-text">Fabriwall Panel is an interior acoustic solution that absorbs noise and mitigates sound reverberation,</p>
    			                            <Link to="/fabriwallpanel" className="btn btn-danger">View Panel</Link>
  			                            </div>
				                    </div>
			                    </div>
		                    </div>
	                    </div>
                    </div>
                </div>
            </div>
			<div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
		<ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default fabriwallTrack; 