import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Glasswool1 from '../../images/inner/glasswool-ceiling-tiles.webp';
import './glasswoolceiling.css';
import ScrollToTopButton from '../ScrollButton';
function glass() {
    return (
      
    <>
    <Helmet>
    <title>Acoustic ceiling Tile - Glasswool Ceiling Tiles offer superior acoustics</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Acoustyceil - Glasswool Ceiling Tiles" />
    <meta property="og:description" content="Glasswool Ceiling Tiles offer superior acoustics while meeting demanding room requirements."/>
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/glasswool-ceiling-tiles.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/glasswoolceiling" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/glasswoolceiling" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/glasswoolceiling" />
    </Helmet>
    <Header />
    <section className="content" id="glasswoolceiling">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Acoustyceil - Glasswool Ceiling Tiles</h1>
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../..images/inner/glasswool-ceiling-tiles.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Glasswool1} alt="Glasswool acoustics" />
                                </Link>
                            </div>
                            <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 text">
                                <h2>Acoustyceil - Glasswool Ceiling Tiles</h2>
                                <p>Glasswool Ceiling Tiles offer superior acoustics while meeting demanding room requirements. These are available in either faced only model or completely encapsulated model. The mil reinforced white mylar is USDA & FDA approved for room applications.</p>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Product Specifications</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>Material</th>
                                            <td>6-7# PCF rigid fiberglass core.</td>
                                        </tr>
                                        <tr>
                                            <th>Finishes</th>
                                            <td>1 mil reinforced white mylar.</td>
                                        </tr>
                                        <tr>
                                            <th>Applications</th>
                                            <td>Typical uses include food processing plants, veterinary clinics, cafeterias and kitchen areas, restaurants.</td>
                                        </tr>
                                        <tr>
                                            <th>Thickness</th>
                                            <td>1" or 2"</td>
                                        </tr>
                                        <tr>
                                            <th>Sizes</th>
                                            <td>Standard sizes of 2' x 2' or 2' x 4'</td>
                                        </tr>
                                        <tr>
                                            <th>Facing Options</th>
                                            <td>Faced 1 side only. Faced + wrapped edges completely encapsulated.</td>
                                        </tr>
                                        <tr>
                                            <th>Flammability</th>
                                            <td>ASTM E-84 className 1-A Nonflammable components. Flame Spread: 25, Smoke Developed: 50</td>
                                        </tr>
                                        <tr>
                                            <th>Installation</th>
                                            <td>Easily installed with a 15/16" grid – wall mounted</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default glass; 