import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Quietnp1 from '../../images/inner/nutra-phone.webp';
import Quietnp2 from '../../images/inner/Quiet-Floor-NP-1-200x200.webp';
import Quietnp3 from '../../images/inner/Quiet-Floor-NP-2-200x200.webp';
import Quietnp4 from '../../images/inner/Quiet-Floor-NP-3-200x200.webp';
import Quietnp5 from '../../images/inner/quiet-floor-np-diagram-1.webp';
import Quietnp6 from '../../images/inner/quiet-floor-np-diagram-2.webp';
import Quietnp7 from '../../images/inner/quiet-floor-np-diagram-3.webp';
import Quietnp8 from '../../images/inner/quiet-floor-np-diagram-4.webp';
import './quiet-floor-np.css';
import ScrollToTopButton from '../ScrollButton';
function quietfloornp() {
    return (
      
    <>
    <Helmet>
    <title>Quiet-Floor NP Sound Control Floor Underlayment</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Quiet-Floor NP Sound Control Floor Underlayment" />
    <meta property="og:description" content="Install QUIET FLOOR NP sound control floor underlayment for excellent results in floor isolation and superior sound control."/>
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/nutra-phone.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/quiet-floor-np" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/quiet-floor-np" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/quiet-floor-np" />
    </Helmet>
    <Header />
    <section className="content" id="quietnp">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Quiet-Floor NP™ Sound Control Floor Underlayment</h1>
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/nutra-phone.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid"  src={Quietnp1} alt="Quiet Floor NP1" />
                                </Link>
                                <div className="row col-md-12 col-lg-12 minigallery">
                                    <div className='col-lg-4 col-md-2'>
                                        <Link className="example-image-link" to="../../images/inner/Quiet-Floor-NP-1-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid d-none d-sm-block" style={{maxwidth: "5em", float: "left", padding: "0 2px"}} src={Quietnp2} alt="Quiet Floor NP2" />
                                        </Link>
                                    </div>
                                    <div className='col-lg-4 col-md-2'>
                                        <Link className="example-image-link" to="../../images/inner/Quiet-Floor-NP-2-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid d-none d-sm-block" style={{maxwidth: "5em", float: "left", padding: "0 2px"}} src={Quietnp3} alt="Quiet Floor NP3" />
                                        </Link>
                                    </div>
                                    <div className='col-lg-4 col-md-2'>
                                        <Link className="example-image-link" to="../../images/inner/Quiet-Floor-NP-3-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid d-none d-sm-block" style={{maxwidth: "5em", float: "left", padding: "0 2px"}} src={Quietnp4} alt="Quiet Floor NP4" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Sound Control Floor Underlayment</h2>
                                <ul style={{marginleft: "1em"}}>
                                    <li>Cost Effective</li>
                                    <li>Recycled Rubber Material</li>
                                    <li>High Performance Noise Control</li>
                                </ul>                                   
                                <p></p>
                                <p>
                                    How do you keep your floors soundproof? No secret. Install QUIET FLOOR NP 
                                    sound control floor underlayment for excellent results in floor isolation and 
                                    superior sound control.
                                </p>  
                                <h2>Quiet Floor NP™ Downloads</h2>                                  
                                <ul style={{marginleft: "1em", color: "#0059a5", fontsize: ".9em"}}>
                                    <li>
                                        <a  target="_blank" rel="noopener noreferrer" style={{color: "#0059a5"}} href="https://acousticalsurfaces.in/pdfs/Hardwood.pdf">Installation Instructions Hardwood Strip <br /> Flooring</a>
                                    </li>
                                    <li>
                                        <a  target="_blank" rel="noopener noreferrer" style={{color: "#0059a5"}} href="https://acousticalsurfaces.in/pdfs/Ceramic_etc.pdf">Installation Instructions Ceramic, Marble, <br /> Granite, Inlaid Work and Hardwood</a>
                                    </li>
                                    <li>
                                        <a  target="_blank" rel="noopener noreferrer" style={{color: "#0059a5"}} href="https://acousticalsurfaces.in/pdfs/Tile_Linoleum.pdf">Installation Instructions Vinyl Tiles and <br /> Linoleum</a>
                                    </li>
                                    <li>
                                        <a  target="_blank" rel="noopener noreferrer" style={{color: "#0059a5"}} href="https://acousticalsurfaces.in/pdfs/QuietFloor_NP_MSDS.pdf">Quiet Floor NP MSDS Sheet</a>
                                    </li>
                                    <li>
                                        <a  target="_blank" rel="noopener noreferrer" style={{color: "#0059a5"}} href="https://acousticalsurfaces.in/pdfs/Ultra_Bond_W-55.pdf">W55 Adhesive MSDS Sheet</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/Quiet_Floor_NP.pdf" target="_blank">Product Specs</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="line"></div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Product Specs</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>MATERIAL</th>
                                            <td>Recycled rubber </td>
                                        </tr>
                                        <tr>
                                            <th>PATTERN</th>
                                            <td>Flat sanded face, performance engineered, diagonal grooves </td>
                                        </tr>
                                        <tr>
                                            <th>FEATURES</th>
                                            <td>Grooved underside minimizes underlay contact between finished materials and sub-floor. Reduces ceramic tile breakage caused by cracking and fissuring of concrete slabs. Excellent floor to floor soundproofing.</td>
                                        </tr>
                                        <tr>
                                            <th>APPLICATIONS</th>
                                            <td>For use as a subfloor in new and renovation construction projects. Reduces impact noise and vibrations while reducing airborne sound transmission. Great under hardwood, ceramic or carpet. Can be used as finished floor.</td>
                                        </tr>
                                        <tr>
                                            <th>THICKNESS</th>
                                            <td>1/2″</td>
                                        </tr>
                                        <tr>
                                            <th>SIZES</th>
                                            <td>Nominal 4′×6′</td>
                                        </tr>
                                        <tr>
                                            <th>WEIGHT</th>
                                            <td>65 lb.</td>
                                        </tr>
                                        <tr>
                                            <th>COLOR</th>
                                            <td>Black</td>
                                        </tr>
                                        <tr>
                                            <th>THERMAL RESISTANCE </th>
                                            <td>R 2/inch 100% vapor barrier </td>
                                        </tr>
                                        <tr>
                                            <th>INSTALLATION</th>
                                            <td>Use Mapei W55 adhesive to Adhere, the “groove side” of Quiet Floor to your sub floor. Allow 24-48 hours for adhesive to cure. It is recommended that another layer of marine grade or exterior grade plywood be adhered (with Mapei W55) to the “flat” or top side of the underlayment before installing the finish floor. In some installations, a finish floor can be adhered to the top of the Quiet Floor NP. Please consult a sales representative with specific installation questions.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/quiet-floor-np-diagram-1.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Quietnp5} alt="Quiet Floor NP5" />
                                </Link>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" href="../../images/inner/quiet-floor-np-diagram-2.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Quietnp6} alt="Quiet Floor NP6" />
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" href="../../images/inner/quiet-floor-np-diagram-3.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Quietnp7} alt="Quiet Floor NP7" />
                                </Link>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" href="../../images/inner/quiet-floor-np-diagram-4.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Quietnp8} alt="Quiet Floor NP8" />
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="2">Diagram Key</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="colheader">1</td>
                                            <td>3/4″ Wood Flooring</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">2</td>
                                            <td>Floor Furring Strip 5/8″×3-1/2″ every 10″</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">3</td>
                                            <td>Quiet Floor NP 1/2″ Sanded Top</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">4</td>
                                            <td>Light Concrete Floor Slab 1-1/2″</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">5</td>
                                            <td>Insulation Board</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">6</td>
                                            <td>3/4″ or 5/8″ Plywood</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">7</td>
                                            <td>Joist or Beam</td>  
                                        </tr>
                                        <tr>
                                            <td className="colheader">8</td>
                                            <td>Acoustic Wool 6″</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">9</td>
                                            <td>Flexible Bar</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">10</td>
                                            <td>2 Layers Gypsum Board</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">11</td>
                                            <td>1 Layer Gypsum Board</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">12</td>
                                            <td>Ceramic</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">13</td>
                                            <td>Recommended Cement</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">14</td>
                                            <td>Recommended Glue</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">15</td>
                                            <td>Concrete Floor Slab 11″</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-sm-6">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="3">Acoustical Performance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td></td>
                                            <td>IIC</td>
                                            <td>STC</td>
                                        </tr>
                                        <tr>
                                            <td>Diagram 1</td>
                                            <td className="emphasis">63</td>
                                            <td className="emphasis">70</td>
                                        </tr>
                                        <tr>
                                            <td>Diagram 2</td>
                                            <td className="emphasis">55</td>
                                            <td className="emphasis">61</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3"></td>
                                        </tr>
                                        <tr className="colheader">
                                            <td></td>
                                            <td>FIIC</td>
                                            <td>FSTC</td>
                                        </tr>
                                        <tr>
                                            <td>Diagram 3</td>
                                            <td className="emphasis">64</td>
                                            <td className="emphasis">55</td>
                                        </tr>
                                        <tr>
                                            <td>Diagram 4</td>
                                            <td className="emphasis">57*</td>
                                            <td className="emphasis"></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3" className="bodytext">*Note: Original FIIC for concrete slab only FIIC 37</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default quietfloornp; 