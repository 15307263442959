import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Soundingrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoiseprob from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CAD from '../../images/additional-resources/ebook-cad-revit.webp';
import './who-we-are.css';
import ScrollToTopButton from '../ScrollButton';
function who() {
    return (
      
    <>
    <Helmet>
    <title>Soundproofing | Acoustic Consultant | Acoustic Solutions </title>
    <meta name="description" content="We are a manufacturer of full-line acoustic products. ASI has been a consultant for Soundproofing & supplier of Acoustic Wall Panels and Ceiling Tiles." />
    <meta name="robots" content="follow, index" />
    <meta property="og:title" content="Acoustical Surfaces LLP" />
    <meta property="og:description" content="Acoustical Surfaces is a noise control solutions provider which is the industry’s foremost expert on soundproofing, acoustics, noise control and vibration control."/>
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.png" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/who-we-are" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.ae/who-we-are" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/who-we-are" hreflang="en-in" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    </Helmet>
    <Header />
    <section className="content" id="whoweare">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Who We Are – Acoustical Surfaces</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <p>Acoustical Surfaces is a noise control solutions provider which is the industry’s foremost expert on soundproofing, acoustics, noise control and vibration control. 
                                    <br /><br />
                                We provide all solutions required for Noise Control, Soundproofing and Acoustical Products for Commercial, Industrial, Educational, House of Worship, Pro Audio, OEM, Home Theater and other Residential applications. All of Acoustical Surfaces products have independent sound and flammability tests and most products offered are ASTM E84 tested Class A-1 non-flammable. Our technical team are experts in soundproofing design and offer onsite consultation during normal business hours 9am - 6pm IST. You will be addressed by experienced sound consultant who can help you recommend solutions for your acoustical problem.</p>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className="ytvid">
                                    <iframe src="https://www.youtube.com/embed/fDNdouGIMIg" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen title="who we are"></iframe>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <p>Acoustical Surfaces provides the most user friendly, high tension fabric acoustical wall system in the industry.<Link to="/wallmate">Wallmate®</Link>, one of our product can cover any size wall with out a wall preparation, no staples and no welting/gimp. Wallmate is a great choice to stay within a budget which has endless choice of fabrics and superior acoustical performance.<br /><br /> We help you solve your noise problems. You can always buy with confidence and get the lowest price on all our noise control products.</p>
                            </div>
                            <div className="col-sm-12">
                                <p>Our company’s division Star Home Cinema will be happy to help with your music studio, rehearsal space, home theater or entertainment venue to make your listening experience to the best level. We also provide sound masking, sound reinforcement, hearing protection and assistive listening products for a truly complete pro audio line of products especially for home theaters and other applications.
                                <br /><br />Our product <Link to="/acoustimetal">Acoustimetal™</Link> system features acoustical, indoor and outdoor perforated metal ceiling and wall panels which requires no long lead times. We can have your customized panels transported quickly and ready for installation.
                                <br /><br />To assure uninhabited acoustical performance and efficient installation we always recommend the usage of Acoustical Surfaces brand acoustical <a href="acoustical_sealant">adhesives.</a> Our adhesives feature low VOC, low odor, easy water clean up and are non-flammable.
                                <br /><br />We also recommend Acoustical Surfaces NOISE S.T.O.P.™ Acoustical Sealant STC enhancer for every project. Acoustical sealant is essential to achieve and maintain specific sound transmission class (STC) values. By using <a href="acoustical_sealant-green">NOISE S.T.O.P.™ Acoustical Sealant</a> to fill perimeter openings of 1/8″ in a wall, ceiling or floor assembly can reduce the loss of expected STC value by as much as 33%.
                                <br /><br />We provide millwork quality acoustical, wood ceilings and wall systems. Get the elegance and beauty of solid wood and wood veneer available in a wide variety of either domestic or exotic wood species at a best price. Our acoustically engineered wood ceiling systems feature easy clip attachment to standard T-bar ceiling grids, full or partial access to plenum and class A, fire retardant finishing.</p>
                            </div>
                            <div className="line"></div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textalign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundingrooms} alt="Create Better Sounding Rooms" target="_blank" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi" target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textalign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoiseprob} alt="Common Noise Problems" target="_blank" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi" target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <hr />
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textalign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CAD} alt="CAD Library" target="_blank" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi" target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default who;