import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Rubberisolation1 from '../../images/inner/whr-350x336.webp';
import Rubberisolation2 from '../../images/inner/whr-wire.webp';
import './rubber-isolation-hangers.css';
import ScrollToTopButton from '../ScrollButton';
function rubber() {
    return (
      
    <>
    <Helmet>
    <title>Rubber Isolation Hangers - Reducing Noise and Minor Vibration</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="WHR Neoprene Hangers Light Duty" />
    <meta property="og:description" content="WHR Neoprene Hangers are an inexpensive but effective method of reducing noise and minor vibration transmission." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/whr-350x336.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/rubber-isolation-hangers" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/rubber-isolation-hangers" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/rubber-isolation-hangers" />
    </Helmet>
    <Header />
    <section className="content" id="rubber">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>WHR Neoprene Hangers Light Duty</h1>  
                        <div className="row">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/whr.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Rubberisolation1} alt="Rubber Isolation Hangers1" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <p></p>
                                <p>WHR Neoprene Hangers are an inexpensive but effective method of reducing noise and minor vibration transmission.</p>
                                <p>WHR hangers are noise breaks for use with wire, chain or horizontal screws for hung ceiling applications.</p>
                                <Link className="example-image-link" to="../../images/inner/whr-wire.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Rubberisolation2} alt="Rubber Isolation Hangers2" />
                                </Link>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/9._WHR_Hangers.pdf" target="_blank">Product Specs</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="4">WHR Hangers</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{fontweight: "bold"}} className="colheader">
                                            <td>Type Size</td>
                                            <td>Rated Capacity Range</td>
                                            <td>Rated Deflection</td>
                                            <td>Color Mark</td>
                                        </tr>
                                        <tr>
                                            <td>WHR-40</td>
                                            <td>up to 60 lbs</td>
                                            <td>0.20″</td>
                                            <td>Green</td>
                                        </tr>
                                        <tr>
                                            <td>WHR-50</td>
                                            <td>55 95 lbs</td>
                                            <td>0.20″</td>
                                            <td>Red</td>
                                        </tr>
                                        <tr>
                                            <td>WHR-60</td>
                                            <td>90 155 lbs</td>
                                            <td>0.20″</td>
                                            <td>White</td>
                                        </tr>
                                        <tr>
                                            <td>WHR-70</td>
                                            <td>150 220 lbs</td>
                                            <td>0.20″</td>
                                            <td>Yellow</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default rubber; 