import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Pyramid1 from '../../images/inner/foamst_5.jpg';
import Pyramid2 from '../../images/inner/mel_pyr04.gif';
import ScrollToTopButton from '../ScrollButton';
function melaminepyramid() {
    return (
      
    <>
    <Helmet>
    <title>Melamine Pyramid Panels - Acoustical Surfaces </title>
    <meta name="description" content="" />
    <meta property="og:title" content="Foam S.T.O.P. Pyramid Melamine Foam Sound Absorber" />
    <meta property="og:description" content="FOAM S.T.O.P PYRAMID panels are made from melamine acoustical foam; these acoustical panels are className A fire retardant. " />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/foamst_5.jpg" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/melamine-pyramid-panels" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/melamine-pyramid-panels" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/melamine-pyramid-panels" hreflang="en-in" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    </Helmet>
    <Header />
    <section className="content" id="pyramid">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Foam S.T.O.P. Pyramid™ | Melamine Pyramid Panels</h1>
                        <div className="row">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/foamst_5.jpg" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Pyramid1} alt="Melamine Pyramid1" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Melamine Foam Sound Absorber</h2>
                                <ul style={{marginleft: "1em"}}>                                                                
                                    <li>High Performance Absorber</li>
                                    <li>Increased Absorptive Surface Area</li>
                                    <li>Fiber Free</li>
                                    <li>className A Fire Retardant</li>                              
                                </ul> 
                                <p></p>
                                <p>FOAM S.T.O.P PYRAMID panels are made from melamine acoustical foam; these acoustical panels are className A fire retardant. FOAM S.T.O.P PYRAMID panels are an excellent option for sound control applications. These panels can be used in recording studios, home theaters, offices, schools and industrial applications.</p>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/Foam_Pyramid.pdf" target="_blank">Product Specs</Link>
                                        </li>                  
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                <h3>Product Specs</h3>
                                    <tbody>
                                        <tr>
                                            <th>Material</th>
                                            <td>Open Cell Melamine Acoustical Foam</td>
                                        </tr>
                                        <tr>
                                            <th>Pattern</th>
                                            <td>Pyramid for Monolithic Appearance</td>
                                        </tr>
                                        <tr>
                                            <th>Features</th>
                                            <td>Increased Surface Area, High Performance, Light Weight, Dramatic Visual Effect </td>
                                        </tr>
                                        <tr>
                                            <th>Applications</th>
                                            <td>Ceilings, Walls, Industrial, Commercial & Home Audio Markets, Broadcasting and Recording Studios</td>
                                        </tr>
                                        <tr>
                                            <th>Thickness</th>
                                            <td>2″, 3″, 4″</td>
                                        </tr>
                                        <tr>
                                            <th>Sizes</th>
                                            <td>2′ × 2′</td>
                                        </tr>
                                        <tr>
                                            <th>Density</th>
                                            <td>0.7lbs/cub. ft.</td>
                                        </tr>
                                        <tr>
                                            <th>Colors</th>
                                            <td>Standard: Natural White or Light Grey<br />
                                                Latex or HPC Paint: Black, Light Grey, Almond<br />
                                                Painted: Black, Brown, Beige, Light Gray (Custom Colors Also Available)
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Flammability</th>
                                            <td>ASTM E84, className 1. Flame Spread: 5; Smoke Developed: 50 </td>
                                        </tr>
                                        <tr>
                                            <th>Installation</th>
                                            <td>ASI S.T.O.P Noise Acoustical Adhesive – Mechanical fasteners / Screws </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <p>
                            <Link className="btn btn-asi" to="/reverb-demo">Reverberation Demonstration</Link>
                        </p>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="8">FOAM S.T.O.P. Pyramid&nbsp;&ndash; Sound Absorption/Noise Reduction</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="8">per ASTM C423-90a</td>
                                        </tr>
                                        <tr className="colheader">
                                            <td></td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>NRC</td>
                                        </tr>
                                        <tr>
                                            <td>2″</td>
                                            <td>0.07</td>
                                            <td>0.25</td>
                                            <td>0.60</td>
                                            <td>0.94</td>
                                            <td>0.97</td>
                                            <td>1.08</td>
                                            <td className="emphasis">0.70</td>
                                        </tr>
                                        <tr>
                                            <td>3″</td>
                                            <td>0.18</td>
                                            <td>0.44</td>
                                            <td>0.96</td>
                                            <td>1.14</td>
                                            <td>1.18</td>
                                            <td>1.19</td>
                                            <td className="emphasis">0.95</td>
                                        </tr>
                                        <tr>
                                            <td>4″</td>
                                            <td>0.16</td>
                                            <td>0.62</td>
                                            <td>1.10</td>
                                            <td>1.20</td>
                                            <td>1.21</td>
                                            <td>1.22</td>
                                            <td className="emphasis">1.05</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/mel_pyr04.gif" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Pyramid2} alt="Melamine Pyramid2" />
                                </Link>
                            </div>
                        </div>
                        <hr className="d-none d-md-block d-lg-block d-xl-block" />
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default melaminepyramid; 