import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Mail from '../../images/mail.png';
import './contact-us.css';
import ScrollToTopButton from '../ScrollButton';
function Climateseal() {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        let errorMessage = '';

        switch (id) {
            case 'name':
                if (!/^[A-Za-z\s]*$/.test(value)) {
                    errorMessage = 'Enter characters only in Name field';
                } else if (value.trim().length < 3) {
                    errorMessage = 'Enter at least 3 characters in Name field';
                }
                break;
            case 'email':
                if (value.trim() !== '' && !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)) {
                    errorMessage = 'Email address is not valid';
                }
                break;
            case 'phone':
                if (!/^(\+\d{1,3}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value)) {
                    errorMessage = 'Enter a valid 10-digit phone number';
                }
                break;
            default:
                break;
        }

        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [id]: errorMessage,
        }));
    };




    const handleSubmit = (e) => {
        e.preventDefault();

        const formIsValid = Object.values(errors).every((error) => error === '');

        const requiredFields = ['name', 'email', 'phone', 'message'];
        const emptyFieldErrors = {};
        requiredFields.forEach((field) => {
            if (!formData[field].trim()) {
                emptyFieldErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
            }
        });

        if (formIsValid && Object.keys(emptyFieldErrors).length === 0) {
            const { name, email, phone, message } = formData;
            const subject = encodeURIComponent('Contact Form Submission');
            const body = encodeURIComponent(`Name: ${name} \nEmail: ${email} \nPhone: ${phone} \nMessage: ${message} `);
            const mailtoLink = `mailto:info@acousticalsurfaces.in?subject=${subject}&body=${body}`;

            window.location.href = mailtoLink;
            setFormData({
                name: '',
                email: '',
                phone: '',
                message: '',
            });

            setErrors({
                name: '',
                email: '',
                phone: '',
                message: '',
            });

        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                ...emptyFieldErrors,
            }));
        }
    };

    return (

        <>
            <Helmet>
                <title>Contact us - Acoustical Surfaces Industries</title>
                <meta name="description" content="" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Contact Acoustical Surfaces for Soundproofing Solutions" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.png" />
                <meta property="og:locale" content="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/contact-us" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/contact-us" hreflang="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.ae/contact" hreflang="en-ae" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="IN" />
                <meta name="geo.region" content="india" />
            </Helmet>
            <Header />
            <section className="content contact" id="contact">
                <div className="container">
                    <hr />
                    <h2>Contact us</h2>
                    <div className="row cont-address">
                        <div className="col-lg-4 col-sm-4">
                            <h5>U.S.A</h5>
                            <p>PRINCIPAL COMPANY</p>
                            <span>
                                Acoustical Surfaces Inc.<br />
                                123 Columbia Court North<br />
                                Suite 201 Chaska<br />
                                MN 55318, USA<br />
                                <b>Tel:</b> (952)-448-5300<br />
                                <b>Toll Free:</b> 1-(800)-854-2948<br />
                                <b>Web:</b> www.acousticalsurfaces.com
                            </span>
                        </div>
                        <div className="col-lg-4 col-sm-4">
                            <h5>INDIA</h5>
                            <p>BANGALORE OFFICE</p>
                            <span>
                                Acoustical Surfaces Pvt Ltd.<br />
                                No.490/1, 1st Floor,<br />
                                Sri Krishna Rajendra Road,   <br />
                                Tyaga Raja Nagar(P.O) - 560028<br />
                                Bangalore, Karnataka, India<br />
                                <b>Tel:</b> <Link to="tel:+918025722022">+91-80257 22022</Link>  |  <Link to="tel:+919449777212">+91-9449777212</Link><br />
                                <b>Web:</b> www.acousticalsurfaces.in
                            </span>
                        </div>
                        <div className="col-lg-4 col-sm-4">
                            <h5>INDIA</h5>
                            <p>FACTORY</p>
                            <span>
                                Acoustical Surfaces Industries LLP.<br />
                                130/2B, Morappampalayam,<br />
                                Uppilipalayam (P.O), Karuvalur<br />
                                Tirupur - 641670<br />
                                Tamil Nadu, India  <br />
                                <b>Tel:</b> <Link to="tel:+918277331000">+91-82773 31000</Link> | <Link to="tel:+914296272700">+91-4296-272700</Link><br />
                                <b>Web:</b> www.acousticalsurfaces.in
                            </span>
                        </div>
                        <div className="col-lg-4 col-sm-6" style={{ visibility: "hidden" }}>

                        </div>
                    </div>
                    <div className="row mailine">
                        <div className="col-md-4"><div className="line"></div></div>
                        <div className="col-md-4">
                            <h1>
                                <span>
                                    <img src={Mail} alt="mail" />
                                </span>
                                <Link to="mailto:info@acousticalsurfaces.in">info@acousticalsurfaces.in</Link>
                            </h1>
                        </div>
                        <div className="col-md-4">
                            <div className="line"></div>
                        </div>
                    </div>
                    <div className="row contacts">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div id="map">
                                <iframe style={{ border: "none" }} src="https://www.google.com/maps/d/embed?mid=1G8WC78MiswxEMnYu1EMceQLZ5E98Wcuk&hl=en" width="640" height="480" Title="Contact"></iframe>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="enqform">
                                <h1>SAY SOMETHING</h1>
                                <form onSubmit={handleSubmit} noValidate className="enquiry-form">
                                    <div className="form-group">
                                        <input
                                            placeholder="Your Name"
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                        <span className="error-message" style={{ color: "red", fontSize: "15px" }}>{errors.name}</span>
                                    </div>

                                    <div className="form-group">
                                        <input
                                            placeholder="Your Email"
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                        <span className="error-message" style={{ color: "red", fontSize: "15px" }}>{errors.email}</span>
                                    </div>

                                    <div className="form-group">
                                        <input
                                            type="phone"
                                            className="form-control"
                                            id="phone"
                                            placeholder="Contact Number"
                                            value={formData.phone}
                                            onChange={handleChange}
                                        />
                                        <span className="error-message" style={{ color: "red", fontSize: "15px" }}>{errors.phone}</span>
                                    </div>

                                    <div className="form-group">
                                        <textarea
                                            className="form-control"
                                            id="message"
                                            rows="3"
                                            placeholder="Message"
                                            value={formData.message}
                                            onChange={handleChange}
                                        ></textarea>
                                        <span className="error-message" style={{ color: "red", fontSize: "15px" }}>{errors.message}</span>
                                    </div>

                                    <button type="submit" className="form-btn mt-3">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default Climateseal;