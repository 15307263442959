import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import MLVB1 from '../../images/inner/mlvb.webp';
import MLVB2 from '../../images/inner/mlv_acoustical_data.webp';
import MLVB3 from '../../images/inner/mlv_stud_assembly.webp';
import MLVB4 from '../../images/inner/pro_audio.webp';
import MLVB5 from '../../images/inner/masload.webp';
import MLVB6 from '../../images/inner/Noise-S.T.O.P.-Vinyl-Barrier-200x200.webp';
import './mass-loaded-vinyl.css';
import ScrollToTopButton from '../ScrollButton';
function mlvb() {
    return (
      
    <>
    <Helmet>
    <title>Mass Loaded Vinyl Barrier</title>
    <meta name="description" content="ASI is a Manufacturer & Supplier of Mass Loaded Vinyl Barrier, Mass Loaded Vinyl Noise Barrier is the solution to build noise barriers and soundproof walls. " />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Noise Soundproofing Vinyl Barrier" />
    <meta property="og:description" content="When noise and sound transmission needs to be effectively controlled, Mass Loaded Vinyl Noise Barrier is the solution to build noise barriers and soundproof walls." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/mlvb.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/mass-loaded-vinyl" hreflang="en-ae" />    
    <link rel="alternate" href="https://www.acousticalsurfaces.in/mass-loaded-vinyl" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/mass-loaded-vinyl" />
    </Helmet>
    <Header />
    <section className="content" id="mlvb">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>MLVB - Mass Loaded Vinyl Barrier</h1>
                        <div className="row">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/mlvb.webp" data-lightbox="example-1">
                                    <img className="example-image" src={MLVB1} alt="MLVB1" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Noise S.T.O.P.™ Vinyl Barrier</h2>
                                <p>When noise and sound transmission needs to be effectively controlled, Mass Loaded Vinyl Noise Barrier is the solution to build noise barriers and soundproof walls.</p>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link target="_blank" to="https://acousticalsurfaces.in/pdfs/vinyl-barrier-product-spec.pdf" className="ico-misc">Product Specs</Link>
                                        </li>
                                        <li>
                                            <Link target="_blank" to="https://www.acousticalsurfaces.com/noise_barrier/vinylbar_acou_test.htm" className="ico-misc">Acoustical Tests</Link>
                                        </li>
                                        <li>
                                            <Link target="_blank" to="https://www.acousticalsurfaces.com/noise_barrier/vinylbar_msds.htm" className="ico-misc">MSDS</Link>
                                        </li>
                                        <li>
                                            <Link target="_blank" to="https://acousticalsurfaces.in/pdfs/className-A-Vinyl-Barrier-Fire-Test.pdf" className="ico-misc">Flammability</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>Product Specs</h4>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>Material</th>
                                            <td>Mass Loaded Vinyl Sound Barrier</td>
                                        </tr>
                                        <tr>
                                            <th>Pattern</th>
                                            <td>Smooth Finish</td>
                                        </tr>
                                        <tr>
                                            <th>Features</th>
                                            <td>This acoustical barrier is made from high density limp material to reduce noise transmission.</td>
                                        </tr>
                                        <tr>
                                            <th>Applications</th>
                                            <td>Noise S.T.O.P. Vinyl Barrier reduces noise transmission through ceilings, walls, floors, machinery enclosures, ductwork. Apply over suspended ceilings on studs or joints.</td>
                                        </tr>
                                        <tr>
                                            <th>Thickness</th>
                                            <td>1/16″ – 1/2 lb/sf; 1/8″ – 1 lb/sf; 1/4″ – 2 lb/sf</td>
                                        </tr>
                                        <tr>
                                            <th>Sizes</th>
                                            <td>54″ Wide × 20′, 30′, or 60′ Rolls – 2′×2′, 2′×4′ Sheets, Die Cutting Available</td>
                                        </tr>
                                        <tr>
                                            <th>Colors</th>
                                            <td>Black, Grey (Reinforced), Tan and Clear (Transparent or Translucent) - Aluminized Mylar / Foil can be added to one side</td>
                                        </tr>
                                        <tr>
                                            <th>Flammability</th>
                                            <td>UL 94VO </td>
                                        </tr>
                                        <tr>
                                            <th>Installation</th>
                                            <td>Acoustical barrier material can be nailed, screwed, stapled or can be reinforced and grommeted and hung like a curtain.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="8">Noise Transmission Loss (dB) Per Octave Band (HZ)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader" style={{fontweight: "bold"}}>
                                            <td>Barriers</td>
                                            <td>125</td>
                                            <td>250</td>
                                            <td>500</td>
                                            <td>1000</td>
                                            <td>2000</td>
                                            <td>4000</td>
                                            <td>STC</td>
                                        </tr>
                                        <tr>
                                            <td>2 lb. PSF</td>
                                            <td>16</td>
                                            <td>22</td>
                                            <td>26</td>
                                            <td>32</td>
                                            <td>35</td>
                                            <td>40</td>
                                            <td className="emphasis">31</td>
                                        </tr>
                                        <tr>
                                            <td>1.25 lb. PSF</td>
                                            <td>14</td>
                                            <td>18</td>
                                            <td>24</td>
                                            <td>27</td>
                                            <td>33</td>
                                            <td>38</td>
                                            <td className="emphasis">28</td>
                                        </tr>
                                        <tr>
                                            <td>1 lb. PSF</td>
                                            <td>13</td>
                                            <td>17</td>
                                            <td>22</td>
                                            <td>26</td>
                                            <td>32</td>
                                            <td>37</td>
                                            <td className="emphasis">27</td>
                                        </tr>
                                        <tr>
                                            <td>1/2 lb. PSF</td>
                                            <td>8</td>
                                            <td>13</td>
                                            <td>17</td>
                                            <td>22</td>
                                            <td>27</td>
                                            <td>31</td>
                                            <td className="emphasis">20</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/mlv_acoustical_data.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={MLVB2} alt="MLVB2" />
                                </Link>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/mlv_stud_assembly.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={MLVB3} alt="MLVB3" />
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/pro_audio.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={MLVB4} alt="MLVB4" />
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/masload.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={MLVB5} alt="MLVB5" />
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/Noise-S.T.O.P.-Vinyl-Barrier-200x200.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={MLVB6} alt="MLVB6" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default mlvb; 