import React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import ScrollToTopButton from '../ScrollButton';
function acoustics() {
    return (
      
    <>
    <Helmet>
    <meta name="description" content="Acoustik is warranted against degradation of the physical properties of the products rubber material when installed in accordance with the manufacturers installation instructions."/>
    <title>Acoustics - ACOUSTIK Acoustic Subflooring </title>
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/acoustics" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/acoustics" />
    </Helmet>
    <Header />
        <embed src="https://www.acousticalsurfaces.in/pdfs/acoustic.pdf#toolbar=0&amp;navpanes=0&amp;scrollbar=0" type="application/pdf" width="100%" height="1100px" />     
        <ScrollToTopButton />
    <Footer />
</>
);
}
export default acoustics;