import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Barrieract1 from '../../images/inner/barricade_ceiling.webp';
import Barrieract2 from '../../images/inner/barricade_ceiling2.webp';
import Barrieract3 from '../../images/inner/barricade-ceiling-square-edge.webp';
import Barrieract4 from '../../images/inner/barricade-ceiling-standard-reveal-edge.webp';
import Barrieract5 from '../../images/inner/whr-wire-thumb.webp';
import Barrieract6 from '../../images/inner/iso_hangers03-thumb.webp';
import './barrier-act-ceiling-tiles.css'
import ScrollToTopButton from '../ScrollButton';
function barrieract() {
    return (
      
    <>
    <Helmet>
    <title>Sound Absorbing Acoustical Ceiling Tiles Barriers</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Sound Absorbing Barrier Acoustical Ceilings Tiles" />
    <meta property="og:description" content="Super Sound Absorbing Ceilngs Tiles Many Surfaces Options" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/barricade_ceiling.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/barrier-act-ceiling-tiles" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/barrier-act-ceiling-tiles" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/barrier-act-ceiling-tiles" />
    </Helmet>
    <Header />
    <section className="content" id="barrieract">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                    </div>
                    <h1 className='barrieract-content'>Noise S.T.O.P.™ Sound Barrier Acoustical Ceilings Tiles</h1>
                    <div className="row pad barrieract-content">
                        <div className="col-xl-4 col-sm-12">
                            <Link className="example-image-link" to="../../images/inner/barricade_ceiling.webp" data-lightbox="example-1">
                            <img className="example-image img-fluid" src={Barrieract1} alt="Barrier Act1" />
                            </Link>
                            <Link className="example-image-link" to="../../images/inner/barricade_ceiling2.webp" data-lightbox="example-1">
                            <img className="example-image img-fluid" src={Barrieract2} alt="Barrier Act2" />
                            </Link>
                        </div>
                        <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                            <h2>Super Sound Absorbing Ceilngs Tiles Many Surfaces Options</h2>
                            <ul style={{marginleft: "1em"}}>                         
                                <li>High Acoustical Performance</li>
                                <li>Good Light Reflectance</li>
                                <li>Cost Effective – to Reduce Reverberation</li>
                            </ul>  
                            <p>Noise S.T.O.P.™ Sound Barrier Ceiling Tiles offer excellent acoustics with 
                            customized options to enhance the visual aesthetics, while satisfying acoustical requirements. Available in standard 2′×2′, 2′×4′ or custom sizes. Installs in T-Bar Grid.
                            </p>    
                            <p>Noise S.T.O.P.™ Sound Barrier Ceiling Tile features a 1 lb./sq. ft. foil-backed 
                            mass-loaded vinyl barrier laminated to 1″ and 2-1/8″ glass fiber core with white 
                            cleanable face. This tile offers high STC and NRC values and is available in a variety of finishes. Product Testing & Information Product Specs
                            </p>       
                        </div>
                        <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                            <div className="testing-info">
                                <h5>Product Testing & Information</h5>
                                <ul>
                                    <li><Link to="https://acousticalsurfaces.in/pdfs/Sound_Barrier_Acoustical_Ceiling_Tiles.pdf" target="_blank">Product Specs</Link></li>
                                    <li><Link to="https://acousticalsurfaces.in/pdfs/Barrier_Acoustical_Ceiling_Tiles_Safety_Data_Sheet.pdf" target="_blank">Safety Data Sheet</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="line"></div>
                    </div>
                    <div className="row barrieract-content">
                        <div className="col-sm-12">
                            <h3>Product Specs</h3>
                            <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                <tbody>
                                    <tr>
                                        <th>Material</th>
                                        <td>Fiberglass core with F/G fabric facing. </td>
                                    </tr>
                                    <tr>
                                        <th>Applications</th>
                                        <td>Any place where high performance acoustics is required. </td>
                                    </tr>
                                    <tr>
                                        <th>Size</th>
                                        <td>
                                            Nominal 2′×2′, 2′×4′ or custom sizes. Installs in T-Bar grid.
                                            <br/>
                                            <strong>Part #</strong>
                                            <br/> BARCT-122 – 1-1/8″ Thickness – 2′×2′
                                            <br/> BARCT-222 – 2-1/8″ Thickness – 2′×2′
                                            <br/> BARCT-124 – 1-1/8″ Thickness – 2′×4′
                                            <br/> BARCT-224 – 2-1/8″ Thickness – 2′×4′
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Installation</th>
                                        <td>Lay-in grid</td>
                                    </tr>
                                    <tr>
                                        <th>Flammability</th>
                                        <td>ASTM E-84 className 1-A, Nonflammable. </td>
                                    </tr>
                                    <tr>
                                        <th>Performance</th>
                                        <td>
                                            2″ Thickness – NRC = 1.05, STC = 27; 1″ Thickness – NRC = .95, STC = 27
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <p><Link className="btn btn-asi" to="reverb-demo">Reverberation Demonstration</Link></p>
                    <div className="row" style={{padding:"0px 30px"}}>
                        <h3 style={{marginleft: "1em"}}>Edge Options:</h3>
                        <div className="col-md-12 col-sm-12 fleximages">
                            <div className="imagebox">
                                <img className="example-image img-fluid" src={Barrieract3} alt="Barrier Act3" />
                                <span>Square Edge (Standard)</span>
                            </div>
                            <div className="imagebox">
                                <img className="example-image img-fluid" src={Barrieract4} alt="Barrier Act4" />
                                <span>Standard Reveal Edge (Optional)</span>
                            </div>
                        </div>
                    </div>
                    <p>&nbsp;</p>
                    <div className="row" style={{padding:"0px 30px"}}>
                        <h3 style={{marginleft: "1em"}}>Vibration Isolation Hanger Options:</h3>
                        <div className="col-md-12 col-sm-12 fleximages">
                            <div className="imagebox">
                                <img className="example-image img-fluid" style={{display: "flex", marginLeft: "25%"}} src={Barrieract5} alt="Barrieract5" />
                                <span>WHR Neoprene Hangers Light Duty</span>
                            </div>
                            <div className="imagebox">
                                <img className="example-image img-fluid" src={Barrieract6} alt="Barrier Act6" />
                                <span>Isolation Hangers – Fiberglass</span>
                            </div>
                        </div>
                    </div>
                    <hr className="d-none d-md-block d-lg-block d-xl-block" />
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default barrieract;