import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Doorseal1 from '../../images/inner/doorseal1.webp';
import Doorseal2 from '../../images/inner/doorseal2.webp';
import Doorseal3 from '../../images/inner/doorseal3.webp';
import Doorseal4 from '../../images/inner/doorseal4.webp';
import Doorseal5 from '../../images/inner/599c.webp';
import Doorseal6 from '../../images/inner/doorseal5.webp';
import Doorseal7 from '../../images/inner/doorseal6.webp';
import Doorseal8 from '../../images/inner/door-seal-clear.webp';
import Doorseal9 from '../../images/inner/door-seal-darkbronze.webp';
import Doorseal10 from '../../images/inner/door-seal-gold.webp';
import Doorseal11 from '../../images/inner/Adjustable-Door-Seals.webp';
import Doorseal12 from '../../images/inner/195c.webp';
import Doorseal13 from '../../images/inner/370a.webp';
import Doorseal14 from '../../images/inner/door_kit.webp';
import './adjustable-door-seal-kits.css';
import ScrollToTopButton from '../ScrollButton';
function acousticdoorseal() {
    return (

        <>
            <Helmet>
                <title>Adjustable Acoustic Door Seals | Echo Eliminator</title>
                <meta name="description" content="" />
                <meta name="robots" content="index, follow" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="in" />
                <meta name="geo.region" content="india" />
                <meta property="og:title" content="Adjustable Door Seals" />
                <meta property="og:description" content="Surface Mounted Automatic Door Seals" />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/doorseal1.webp" />
                <meta property="og:locale" content="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/adjustable-door-seal-kits" hreflang="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.ae/adjustable-door-seal-kits" hreflang="en-ae" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/adjustable-door-seal-kits" />
            </Helmet>
            <Header />
            <section className="content" id="doorseal">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 sidemenu">
                            <Sidebar />
                        </div>
                        <div className="col-sm-9 product-page nopaddding">
                            <div className="container">
                                <div className="megamenu">
                                    <Megamenu />
                                </div>
                            </div>
                            <h1 className='doorseal-content'>Adjustable Door Seals</h1>
                            <div className="row pad doorseal-content">
                                <div className="col-xl-4 col-sm-12">
                                    <Link className="example-image-link" to="../../images/inner/doorseal1.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Doorseal1} alt="Door seal1" />
                                    </Link>
                                </div>
                                <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                    <h2>Surface Mounted Automatic Door Seals</h2>
                                    <ul style={{ marginLeft: "1em" }}>
                                        <li>Reduces Sound Through Door Seals</li>
                                        <li>Economical</li>
                                        <li>Easy to Install</li>
                                        <li>Durable</li>
                                    </ul>
                                    <Link style={{ color: "#0059a5" }} to="/acoustydoor">Echo Eliminator&trade; VS. Other Acoustical Materials</Link>
                                    <p><strong>Note:</strong> Kit pieces are sold separately and custom sizes are available upon request.</p>
                                </div>
                                <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                    <div className="testing-info">
                                        <h5>Product Testing & Information</h5>
                                        <ul className="">
                                            <li><Link to="https://acousticalsurfaces.in/pdfs/Adjustable-Door-Seal.pdf" target="_blank">Product Specs</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="line"></div>
                            </div>
                            <div className="row doorseal-content">
                                <div className="col-sm-12 ytvid2">
                                    <h3>How to Install a Door Kit</h3>
                                    <iframe width="100%" height="400" src="https://www.youtube.com/embed/MP9GYGyggK8" frameborder="0" allowfullscreen Title="door-seal"></iframe>
                                </div>
                            </div>
                            <div className="row doorseal-content">
                                <div className="col-sm">
                                    <h3>Kit 1 – Standard</h3>
                                    <strong>Automatic Door Bottom 320C</strong>
                                    <Link className="example-image-link" to="../../images/inner/doorseal2.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Doorseal2} alt="Door seal2" />
                                    </Link>
                                </div>
                                <div className="col-sm">
                                    <h3>Kit 2 – Heavy Duty</h3>
                                    <strong>Automatic Door Bottom 330C</strong>
                                    <Link className="example-image-link" to="../../images/inner/doorseal3.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Doorseal3} alt="Door seal3" />
                                    </Link>
                                </div>
                                <div className="col-sm">
                                    <strong>Adjustable Jamb Seal 33C</strong>
                                    <p>(3 piece Set – 2-7′ Long & 1-3′ Long)</p>
                                    <Link className="example-image-link" to="../../images/inner/doorseal4.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Doorseal4} alt="Door seal4" />
                                    </Link>
                                </div>
                            </div>
                            <div className="row doorseal-content">
                                <div className="col-sm">
                                    <strong>Adjustable Jamb Seal 599C</strong>
                                    <p>(3 piece Set – 2-7′ Long & 1-3′ Long)</p>
                                    <Link className="example-image-link" to="../../images/inner/599c.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Doorseal5} alt="Door seal5" />
                                    </Link>
                                </div>
                                <div className="col-sm">
                                    <strong>Door Frame Sound Gaskets</strong>
                                    <Link className="example-image-link" to="../../images/inner/doorseal5.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Doorseal6} alt="Door seal6" />
                                    </Link>
                                </div>
                                <div className="col-sm">
                                    <strong>Door Frame Sound Gaskets</strong>
                                    <Link className="example-image-link" to="../../images/inner/doorseal6.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Doorseal7} alt="Door seal7" />
                                    </Link>
                                </div>
                            </div>
                            <div className="row doorseal-content">
                                <h2 style={{marginLeft:"20px"}}>Automatic Door Bottom – Anodized Aluminum Color Choices</h2>
                                <div className="col-md-12 col-sm-12 fleximages">
                                    <div className="imagebox">
                                        <Link className="example-image-link" to="../../images/inner/door-seal-clear.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Doorseal8} alt="Door seal8" />
                                        </Link>
                                        <span>Clear</span>
                                    </div>
                                    <div className="imagebox">
                                        <Link className="example-image-link" to="../../images/inner/door-seal-darkbronze.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Doorseal9} alt="Door seal9" />
                                        </Link>
                                        <span>Dark Bronze</span>
                                    </div>
                                    <div className="imagebox">
                                        <Link className="example-image-link" to="../../images/inner/door-seal-gold.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Doorseal10} alt="Door seal10" />
                                        </Link>
                                        <span>Gold</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row doorseal-content">
                                <div className="col-sm-12">
                                    <h3>Automatic Door Bottom – Acoustical Performance STC in Accordance with ASTM E90-75</h3>
                                    <table className="table table-bordered" style={{ background: "#f5f5f5" }}>
                                        <tbody>
                                            <tr>
                                                <td>Model</td>
                                                <td>
                                                    320C – No Threshold <br />
                                                    330C – No Threshold <br />
                                                    330C – With Threshold
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Door Caulked Shut STC <strong></strong></td>
                                                <td>
                                                    32 <br />
                                                    44 <br />
                                                    51
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>With Door Bottom STC </td>
                                                <td>
                                                    28 <br />
                                                    37<br />
                                                    43
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p><strong>NOTE:</strong> Standard 1-3/4″ Solid Core Door, 5 lbs/sf STC – 22 (No Seals), 33 (With Door Seals), 7 lbs/sf STC – 46</p>
                                </div>
                            </div>
                            <div className="row doorseal-content">
                                <div className="col-md-12 col-sm-12 fleximages">
                                    <div className="imagebox">
                                        <Link className="example-image-link" to="../../images/inner/Adjustable-Door-Seals.webp" data-lightbox="example-1">
                                            <img style={{ height: "12em", width: "18em", background: "#dbcbcb" }} className="example-image img-fluid" src={Doorseal11} alt="Door seal11" />
                                        </Link>
                                        <span>Clear</span>
                                    </div>
                                    <div className="imagebox">
                                        <Link className="example-image-link" to="../../images/inner/195c.webp" data-lightbox="example-1">
                                            <img style={{ height: "12em", width: "18em", background: "#dbcbcb" }} className="example-image img-fluid" src={Doorseal12} alt="Door seal12" />
                                        </Link>
                                        <span>Dark Bronze</span>
                                    </div>
                                    <div className="imagebox">
                                        <Link className="example-image-link" to="../../images/inner/370a.webp" data-lightbox="example-1">
                                            <img style={{ height: "12em", width: "18em", background: "#dbcbcb" }} className="example-image img-fluid" src={Doorseal13} alt="Door seal13" />
                                        </Link>
                                        <span>Gold</span>
                                    </div>
                                    <div className="imagebox">
                                        <Link className="example-image-link" to="../../images/inner/door_kit.webp" data-lightbox="example-1">
                                            <img style={{ height: "12em", width: "18em", background: "#dbcbcb" }} className="example-image img-fluid" src={Doorseal14} alt="Door seal14" />
                                        </Link>
                                        <span>Gold</span>
                                    </div>
                                </div>
                            </div>
                            <hr className="d-none d-md-block d-lg-block d-xl-block" />
                        </div>
                    </div>
                    <div className='mobsidebar'>
                        <div className="col-sm-12">
                            <div className="container">
                                <div className="row">
                                    <Mobsidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default acousticdoorseal;