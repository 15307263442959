import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import './acfabric-intution.css';
import Mobsidebar from '../mobile-sidebar';
import In1 from '../../images/acfabric/in1.jpg';
import In2 from '../../images/acfabric/in2.jpg';
import In3 from '../../images/acfabric/in3.jpg';
import In4 from '../../images/acfabric/in4.jpg';
import In5 from '../../images/acfabric/in5.jpg';
import In6 from '../../images/acfabric/in6.jpg';
import In7 from '../../images/acfabric/in7.jpg';
import In8 from '../../images/acfabric/in8.jpg';
import In9 from '../../images/acfabric/in9.jpg';
import In10 from '../../images/acfabric/in10.jpg';
import In11 from '../../images/acfabric/in11.jpg';
import In12 from '../../images/acfabric/in12.jpg';
import In13 from '../../images/acfabric/in13.jpg';
import In14 from '../../images/acfabric/in14.jpg';
import ScrollToTopButton from '../ScrollButton';
function acfabricinter() {
    return (
      
    <>
    <Helmet>
    <title>Acoustic of Maine Intuition Acoustic Sound Fabric - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="AcoustyFABRIC Intuition" />
    <meta property="og:description" content="Guilford of Maine Intuition 4856 Fabric Facings" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/acfabric/in1.jpg" />
    <meta property="og:locale" content="en-in" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/acfabric-intution" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/acfabric-intution" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acfabric-intution" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="acfabricintution">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
            	<div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
	                <div className="col-sm-12 heading-fsorb">				
		                <h1>Acousty<span className="fabriwall">FABRIC</span> Intuition</h1>
                		<h4 className="mb-0"> <b>Guilford of Maine Intuition 4856 Fabric Facings</b></h4>
		                <p><span className="color:red;">(Colors Shown are Approximate Match to Material)</span></p>
	                    <br />
		                <div className="col-sm-3 cloths p-0"><button>Product Specifications</button></div>
	                </div>
                    <br /><br />
		            <div className="col-sm-12 cloths">
		                <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={In1} alt="Admiral" />
                                <p className="text-center">Admiral 1724</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={In2} alt="Bisque" />
                                <p className="text-center">Bisque 1711</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={In3} alt="Charcoal" />
                                <p className="text-center">Charcoal 1712</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={In4} alt="Coral" />
                                <p className="text-center">Coral 1714</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={In5} alt="Hickory" />
                                <p className="text-center">Hickory 1715</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={In6} alt="Honey" />
                                <p className="text-center">Honey 1716</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={In7} alt="Ochre" />
                                <p className="text-center">Ochre 1718</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={In8} alt="Peacock" />
                                <p className="text-center">Peacock 1722</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={In9} alt="Platinum" />
                                <p className="text-center">Platinum 1717</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={In10} alt="Sage" />
                                <p className="text-center">Sage 1721</p>
                            </div>
                        </div>	
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={In11} alt="Sand" />
                                <p className="text-center">Sand 1713</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={In12} alt="Scarlet" />
                                <p className="text-center">Scarlet 1720</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={In13} alt="Slate" />
                                <p className="text-center">Slate 1719</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={In14} alt="Viridian" />
                                <p className="text-center">Viridian 1723</p>
                            </div>
                            <div className="col-sm-2 p-0 mx-2"></div>
                        </div>	
                    </div>
		            <br />
		            <hr />
                    <div className="col-12 d-flex justify-content-center backbtn">
                    	<Link to="/acoustyfabric"><button>Back to Acousty<span style={{color: "red"}}>FABRIC</span></button></Link>
                    </div>
                    <hr />
                    <div className="col-sm-12 application">
			            <h1>APPLICATION</h1>
                        <div className="hover14 text-center justify-content-center">
		                <br />
  		                    <div className="col-sm-12">
			                    <figure className="m-0"><div className="tinted-image">CINEMA HALL</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image2">OFFICE ROOMS & HALLS</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image3">AUDITORIUM & EDUCATION</div></figure>
 		                    </div>
                        <br />
		                </div>
                    </div>
		            <br />
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acfabricinter;