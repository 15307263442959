import React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Studio1 from '../../images/inner/studio_consultant.webp';
import Studio2 from '../../images/inner/Home-Plan_GIF_experiment.webp';
import Studio3 from '../../images/inner/studio-builder.webp';
import './studio-construction.css';
import ScrollToTopButton from '../ScrollButton';
function studioconstruction() {
    return (
      
    <>
    <Helmet>
    <title>Studio Acoustic Construction Solutions - Studio Soundproofing</title>
    <meta name="description" content="Specializing in recording studios has given Mark experience on all levels of a build, from home studios to large recording complexes, performance rooms, voice-over booths and radio stations to home theaters. " />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Studio Construction Services" />
    <meta property="og:description" content="As a consultant in the recording industry Mark deals daily with the full range of acoustical projects" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/studio-builder.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/studio-constr" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/studio-construction" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/studio-construction" />
    </Helmet>
    <Header />
    <section className="content" id="studio">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Studio Construction Services</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <section>
                                    <h2>Consultant</h2>
                                    <div className="col-4of9">
                                        <figure>
                                            <img className="size-full alignleft" src={Studio1} alt="Studio Construction Consultation" />
                                        </figure>
                                    </div>
                                    <div>
                                        <p>As a consultant in the recording industry Mark deals daily with the full range of acoustical projects, and is capable of taking on your project from the smallest to the largest. The intelligence behind the information you use to build with is critical, and our detailed service and depth of experience will save you time and money. Ultimate satisfaction comes in knowing that you did it right the first time, using valid information reviewed from the beginning, eliminating costly mistakes. We will discuss your needs, and then tailor a custom system just for you which will surpass any test put upon it. From a simple mix, to mastering a record, we can meet your needs. The costs for our services are based on hourly rates on the front end, but design packaging is available. Have us quote on your project today.</p>
                                    </div>
                                    <h2>Designer</h2>
                                    <div>
                                        <figure>
                                            <img className="alignleft size-full" src={Studio2} alt="Acoustical Studio Design" />
                                        </figure>
                                    </div>
                                    <div className="col-5of9 col-last">
                                        <p>Mark has been designing music studios and building recording performance rooms for over 25 years for names like <strong>Take 6</strong>, <strong>EMI/Sparrow</strong>, <strong>Provident Music Publishing</strong>, <strong>Yanni</strong>, <strong>John Tesh</strong>, and a host of others. CAD/CAM software is employed for all project development, and the finished blueprints are delivered to the job ready to build. Just choose a qualified local builder, and/or have Studio Construction Service quote on specific areas of work or project management. From isolated floors, walls and ceiling systems, to custom doors, windows and acoustics, we will make sure your recording studio design is done right. Let’s not forget the quiet ventilation, clean power, wiring and workstation issues. It’s all one complete system, working together seamlessly.</p>
                                    </div>
                                    <h2>Builder</h2>
                                    <div>
                                        <figure>
                                            <img className="alignleft size-full" src={Studio3} alt="Building Custom Acoustic Studios" />
                                        </figure>
                                    </div>
                                    <p>Being licensed as a general contractor and specializing in recording studios has given Mark experience on all levels of a build, from home studios to large recording complexes, performance rooms, voice-over booths and radio stations to home theaters. Having the experience of a specialized builder is crucial when it comes to building something as complicated as an acoustic environment. His expertise in developing quiet HVAC systems, dedicated electrical supplies, and the water tight dynamics of a sonic environment will put your studio in a league of it’s own. Review Studio Construction Services’ list of satisfied clients, and read through the <a href="www.acousticalsurfaces.com/studio_construction/testimonials.htm"  target="_blank" rel="noopener noreferrer">testimonials</a>; “Reinforce your position in the market place.”</p>
                                    <p><strong>There are 3 primary questions you will need to answer before we begin designing or building a music studio:</strong></p>
                                    <ol style={{marginleft: "1em", fontsize: ".9em"}}>
                                        <li>What is the goal of your acoustic space, and who is your end user?</li>
                                        <li>What are the specific physical characteristics of your area:
                                            <ul style={{marginleft: "1em"}}>
                                                <li>Dimensions of the space, and do you have scaled drawings and photos of your space?</li>
                                                <li>What is the location of your space, relative to other rooms, buildings, etc?</li>
                                                <li>What are the existing HVAC and electrical conditions?</li>
                                            </ul>
                                        </li>
                                        <li>What kind of budget do you have?</li>
                                    </ol>
                                </section>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default studioconstruction; 