import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Sliderpolysorb from './slider-polysorb';
import Sliderpolysorbwall from './slider-polysorbwall';
import Sliderpolysorbceiling from './slider-polysorbceiling'
import Sliderpolysorbbaffles from './slider-polysorbbaffles'
import Sliderpolysorbdesigner from './slider-polysorbdesigner';
import Polysorbbrochure1 from '../../images/btn-download-brochure.png';
import Polysorbbrochure2 from '../../images/btn-download-color.png';
import Mobsidebar from '../mobile-sidebar';
import './style-polysorb.css';
import ScrollToTopButton from '../ScrollButton';
function polysorb() {
    return (
      
    <>
    <Helmet>
    <title>Polyester Fiber Decorative Acoustic Panels</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Fabric Wrapped Acoustical Panels" />
    <meta property="og:description" content="Polysorb Wall Panels provide a better sound solution and are engineered for unparalleled sound absorption performance." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/polysorb/poly1.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/polysorb" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/polysorb" />
    </Helmet>
    <Header />
    <section className="content" id="polysorb">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
	                    <div className="col-sm-12 heading-fsorb">				
		                    <h1>Polysorb<sup>TM</sup> Acoustic Panel</h1>
		                    <h4>Fabric Wrapped <b>Acoustical Panels</b></h4>
		                    <p>These are acoustically absorbent cost effective decorative ceiling and wall panels with polyester fiber as their core. They are chemical free making them non-allergic and nontoxic. They have increased impact resistance and are free of binding agents.
                                They are great for spaces such as home theatres, gymnasiums, offices, studios, restaurants, hospitals, house of worship and schools</p>
		                    <div className="demo-cont">
                                <Sliderpolysorb />
                            </div>
	                    </div>
                        <div className="col-sm-12 features">
	                        <h1>Features</h1>
	                        <div className="line"></div>
		                    <div className="row pad">	
		                        <div className="col-md-8 col-sm-12">
            		                <ul>
		                                <li>Lightweight</li>
		                                <li>Impact Resistant</li>
		                                <li>Moisture, bacteria & fungi resistant</li>
		                                <li>Tackable surface</li>
                		                <li>Cleanable</li>
		                                <li>Available with various edges and colours</li>
		    		                </ul>
				                </div>
		    	                <div className="col-md-4 col-sm-12 tiles bgimage">
	    		                </div>
	                        </div>
                        </div>
                        <div className="col-sm-12 features">
	                        <h1>Applications</h1>
	                        <div className="line"></div>
    	                    <p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                            <div className="row pad ">	
		                        <div className="col-md-6 col-sm-12 d-none d-md-block">
	    		                    <ul>
            		                    <li>Offices</li>
		                                <li>Schools</li>
		                                <li>Meeting Rooms</li>
		                                <li>Hotels</li>
        		                    </ul>
			                    </div>
			                    <div className="col-md-6 col-sm-12 d-none d-md-block">
	    		                    <ul>
            		                    <li>Auditorium</li>
		                                <li>Recording Studios</li>
		                                <li>Conference halls</li>
		                                <li>Broadcasting Studios</li>
        		                    </ul>
			                    </div>
		                    </div>
                        </div>
	                    <div className="col-sm-12 more">
                            <h1> More Products </h1>
	                        <div className="line"></div>
	                    </div>
	                    <div className="accordion-started accordion-bral panels row">
		                    <div className="col-sm-12 col-md-12 col-lg-12 ">
		                        <label className="ac-label" htmlFor="ac-1">
                                    <b>POLYSORB<sup>TM</sup></b><br /> ACOUSTICAL WALL PANELS<i></i>
                                </label>
	    	                    <div className="article ac-content">
				                    <Sliderpolysorbwall />
				                    <p>
						                Polysorb Wall Panels provide a better sound solution and are engineered for unparalleled sound absorption performance. They are lightweight and are free from allergies and irritations; courtesy of their chemical free polyester fiber core. They provide exceptional acoustical performance in terms of sound absorption as well as dampening. They are durable and can withstand shocks.
                                        They are available in different sizes and does not affect the aesthetics of the space while providing sound absorption.
                                    </p>
					                <h2>Product Details</h2>
                                    <br />
                	                <div className="col-sm-12">
                                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#menu3">DATASHEET</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
	                                                    <tr>
                                                            <th scope="row">Material</th>
                                                            <td>Tackable walls made from 100% polyester (60% PET - Recycled Fiber, 40% PET - virgin fiber)</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Sizes</th>
                                                            <td>Standard Size: 48″ × 96″<br />
			                                                    Cut to Size/Custom Sizes: Up to : 48″ × 96″<br />
			                                                    For custom sizes, please call us for details.<br />
			                                                    Ceiling Tiles: 23¾″ × 47¾″
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Thickness</th>
                                                            <td>1/2", 1″ and 2"</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Color</th>
                                                            <td>All colors available</td>
                                                        </tr>
                                	                    <tr>
                                                            <th scope="row">Weight</th>
                                                            <td>1/2" thick 12.5# per cubic feet,<br />
			                                                    1" thick 7.5# per cubic feet,<br />
			                                                    2" thick 5# per cubic feet
                                                            </td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Flammability </th>
                                                            <td>className A Fire Rated per ASTM E84<br />
			                                                    Flame Spread Index = 15<br />
			                                                    Smoke Developed Index = 250
                                                            </td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Installation</th>
                                                            <td>Wall Panels: Adhesive, Direct Attach, Z-Clip, Stick Pin options available<br />
				                                                Ceiling Tiles: Lay-In, Adhesive
                                                            </td>
                                                        </tr>
	                                                </tbody>
                                                </table>		
		                                    </div>
                                            <div id="menu1" className="container tab-pane fade"><br />
     		                                    <h3>Sound Absorption Coefficients</h3>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Thickness</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">20mm</th>
                                                            <td>0.05</td>
                                                            <td>0.22</td>
                                                            <td>0.64</td>
                                                            <td>0.92</td>
                                                            <td>1.04</td>
                                                            <td>1.02</td>
                                                            <td>0.70</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">25mm</th>
                                                            <td>0.14</td>
                                                            <td>0.27</td>
                                                            <td>0.80</td>
                                                            <td>1.11</td>
                                                            <td>1.14</td>
                                                            <td>1.14</td>
                                                            <td>0.85</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">40mm</th>
                                                            <td>0.21</td>
                                                            <td>0.65</td>
                                                            <td>0.95</td>
                                                            <td>0.99</td>
                                                            <td>1.01</td>
                                                            <td>1.02</td>
                                                            <td>0.90</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">50mm</th>
                                                            <td>0.22</td>
                                                            <td>0.81</td>
                                                            <td>1.24</td>
                                                            <td>1.30</td>
                                                            <td>1.21</td>
                                                            <td>1.16</td>
                                                            <td>1.15</td>
                                                        </tr>
                                                    </tbody>    
                                                </table>
		                                    </div>
                    	                    <div id="menu3" className="container tab-pane fade"><br />
	                                        <br />
                                                <h3>ASI Wall Panels : Datasheet</h3>
		                                        <br />
                    		                    <a target="_blank" rel="noopener noreferrer" href="/#" className="brochure">Download</a>
                                            </div>
                                        </div>
                                    </div>			
			                    </div>
			                </div>
                            <div className="line"></div>
			                <div className="col-sm-12 col-md-12 col-lg-12">
			                    <label className="ac-label" htmlFor="ac-2">
                                    <b>POLYSORB<sup>TM</sup></b><br /> ACOUSTICAL CEILING PANELS<i></i>
                                </label>
					            <div className="article ac-content">
					                <Sliderpolysorbceiling />
				                    <p>
					                    Polysorb Ceiling Panels are undoubtedly the best choice when it comes to acoustic panels for ceilings. They suit almost all types of ceilings and improves the acoustic performance without comprising the aesthetics of the spaces. The core of the Panels are environment friendly Polyester fibers which are exceptional in absorbing sound.
                                        They are available in a variety of colours and are a perfect ceiling solution with complete versatility.
                                    </p>
                                    <h2>Product Details</h2>
                                    <br />
        	                        <div className="col-sm-12">
				                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#cp-home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cp-menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cp-menu3">DATASHEET</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="cp-home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
	                                                    <tr>
                                                            <th scope="row">Material</th>
                                                            <td>Tackable walls made from 100% polyester (60% PET - Recycled Fiber, 40% PET - virgin fiber)</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Sizes</th>
                                                            <td>Standard Size: 48″ × 96″<br />
			                                                    Cut to Size/Custom Sizes: Up to : 48″ × 96″<br />
			                                                    For custom sizes, please call us for details.<br />
			                                                    Ceiling Tiles: 23¾″ × 47¾″
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Thickness</th>
                                                            <td>1/2", 1″ and 2"</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Color</th>
                                                            <td>All colors available</td>
                                                        </tr>
   	                                                    <tr>
                                                            <th scope="row">Weight</th>
                                                            <td>1/2" thick 12.5# per cubic feet,<br />
			                                                    1" thick 7.5# per cubic feet,<br />
			                                                    2" thick 5# per cubic feet
                                                            </td>
                                                        </tr>
            	                                        <tr>
                                                            <th scope="row">Flammability </th>
                                                            <td>className A Fire Rated per ASTM E84<br />
			                                                    Flame Spread Index = 15<br />
			                                                    Smoke Developed Index = 250
                                                            </td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Installation</th>
                                                            <td>Wall Panels: Adhesive, Direct Attach, Z-Clip, Stick Pin options available<br />
				                                                Ceiling Tiles: Lay-In, Adhesive
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
		                                    </div>
                                            <div id="cp-menu1" className="container tab-pane fade"><br />
                                                <h3>Sound Absorption Coefficients</h3>
     		                                    <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Thickness</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">20mm</th>
                                                            <td>0.13</td>
                                                            <td>0.45</td>
                                                            <td>0.93</td>
                                                            <td>1.01</td>
                                                            <td>1.00</td>
                                                            <td>1.03</td>
                                                            <td>0.85</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">25mm</th>
                                                            <td>0.14</td>
                                                            <td>0.27</td>
                                                            <td>0.80</td>
                                                            <td>1.11</td>
                                                            <td>1.14</td>
                                                            <td>1.14</td>
                                                            <td>0.85</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">40mm</th>
                                                            <td>0.32</td>
                                                            <td>0.75</td>
                                                            <td>0.94</td>
                                                            <td>0.98</td>
                                                            <td>1.01</td>
                                                            <td>1.04</td>
                                                            <td>0.95</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">50mm</th>
                                                            <td>0.22</td>
                                                            <td>0.81</td>
                                                            <td>1.24</td>
                                                            <td>1.30</td>
                                                            <td>1.21</td>
                                                            <td>1.16</td>
                                                            <td>1.15</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
		                                    </div>
                                            <div id="cp-menu2" className="container tab-pane fade"><br />
                                            <br />
                                                <h3>Acoustical Ceiling Panels : Brochure</h3>
		                                        <br />
		                                    </div>
	                                        <div id="cp-menu3" className="container tab-pane fade"><br />
                                            <br />
                                                <h3>Acoustical Ceiling Panels : Datasheet</h3>
		                                        <br />
		                                        <a target="_blank" rel="noopener noreferrer" href="/#" className="brochure">Download</a>
                                            </div>
                                        </div>
		                            </div>			
	                            </div>
	                        </div>
                            <div className="line"></div>
	                        <div className="col-sm-12 col-md-12 col-lg-12">
	                            <label className="ac-label" htmlFor="ac-3">
                                    <b>POLYSORB <sup>TM</sup></b><br /> ACOUSTICAL CEILING BAFFLES<i></i>
                                </label>
			                    <div className="article ac-content">
			                    	<Sliderpolysorbbaffles />
				                    <p>
					                    Polysorb Baffles are a novel ceiling acoustic solution that boosts the design element of the space without affecting the amount of noise curtailment. They are architecturally decorative and aids in materializing our creative imaginations for interiors. They are manufactured to higher standards and are easily installable.
                                        They are available in a variety of customizations and are ideal for scenarios where wall treatment is a concern.
				                    </p>
                                    <h2>Product Details</h2>
                                    <br />
				                    <div className="col-sm-12">
				                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#cb-home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cb-menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cb-menu3">DATASHEET</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="cb-home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
	                                                    <tr>
                                                            <th scope="row">Material</th>
                                                            <td>Tackable walls made from 100% polyester (60% PET - Recycled Fiber, 40% PET - virgin fiber)</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Sizes</th>
                                                            <td>Standard Size: 48″ × 96″<br />
			                                                    Cut to Size/Custom Sizes: Up to : 48″ × 96″<br />
			                                                    For custom sizes, please call us for details.<br />
			                                                    Ceiling Tiles: 23¾″ × 47¾″
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Thickness</th>
                                                            <td>1/2", 1″ and 2"</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Color</th>
                                                            <td>All colors available</td>
                                                        </tr>
   	                                                    <tr>
                                                            <th scope="row">Weight</th>
                                                            <td>1/2" thick 12.5# per cubic feet,<br />
			                                                    1" thick 7.5# per cubic feet,<br />
			                                                    2" thick 5# per cubic feet
                                                            </td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Flammability </th>
                                                            <td>className A Fire Rated per ASTM E84<br />
			                                                    Flame Spread Index = 15<br />
			                                                    Smoke Developed Index = 250
                                                            </td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Installation</th>
                                                            <td>Wall Panels: Adhesive, Direct Attach, Z-Clip, Stick Pin options available<br />
				                                                Ceiling Tiles: Lay-In, Adhesive
                                                            </td>
                                                        </tr>
                                                    </tbody>
	                                            </table>
		                                    </div>
                                            <div id="cb-menu1" className="container tab-pane fade"><br />
     		                                    <h3>Sound Absorption Coefficients</h3>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Thickness</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">40mm</th>
                                                            <td>0.32</td>
                                                            <td>0.75</td>
                                                            <td>0.94</td>
                                                            <td>0.98</td>
                                                            <td>1.01</td>
                                                            <td>1.04</td>
                                                            <td>0.95</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">50mm</th>
                                                            <td>0.22</td>
                                                            <td>0.81</td>
                                                            <td>1.24</td>
                                                            <td>1.30</td>
                                                            <td>1.21</td>
                                                            <td>1.16</td>
                                                            <td>1.15</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
	                                        </div>
  	                                        <div id="cb-menu3" className="container tab-pane fade"><br />
                                            <br />
                                                <h3>Acoustical Ceiling Baffles : Datasheet</h3>
		                                        <br />
		                                        <a target="_blank" rel="noopener noreferrer" href="/#" className="brochure">Download</a>
                                            </div>
                                        </div>
                                    </div>			
			                    </div>
		                    </div>
                            <div className="line"></div>
		                    <div className="col-sm-12 col-md-12 col-lg-12">
		                        <label className="ac-label" htmlFor="ac-4">
                                    <b>POLYSORB <sup>TM</sup></b><br /> ACOUSTICAL DESIGNER PANELS<i></i>
                                </label>
			                    <div className="article ac-content">
			                        <Sliderpolysorbdesigner />
				                    <p>
					                    Polysorb Designer Panels add a design factor to the space and can be customized to any design as per client's requirement.
                                        These panel forms a distinctive merge of functionality and aesthetics of the space. They are durable, are free from deterioration, and colour change over time. They provide a three dimensional feel to the whole space and are best suited for spaces where reflected noise from walls are a concern. 
				                    </p>
				                    <h2>Product Details</h2>
                                    <br />
                                    <div className="col-sm-12">
				                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#dp-home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#dp-menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#dp-menu3">DATASHEET</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="dp-home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
	                                                    <tr>
                                                            <th scope="row">Material</th>
                                                            <td>Tackable walls made from 100% polyester (60% PET - Recycled Fiber, 40% PET - virgin fiber)</td>
                                                        </tr>
                                                        <tr>      
                                                            <th scope="row">Sizes</th>
                                                            <td>Standard Size: 48″ × 96″<br />
			                                                    Cut to Size/Custom Sizes: Up to : 48″ × 96″<br />
			                                                    For custom sizes, please call us for details.<br />
			                                                    Ceiling Tiles: 23¾″ × 47¾″
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Thickness</th>
                                                            <td>1/2", 1″ and 2"</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Color</th>
                                                            <td>All colors available</td>
                                                        </tr>
   	                                                    <tr>
                                                            <th scope="row">Weight</th>
                                                            <td>1/2" thick 12.5# per cubic feet,<br />
		    	                                                1" thick 7.5# per cubic feet,<br />
			                                                    2" thick 5# per cubic feet
                                                            </td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Flammability </th>
                                                            <td>className A Fire Rated per ASTM E84<br />
			                                                    Flame Spread Index = 15<br />
			                                                    Smoke Developed Index = 250
                                                            </td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Installation</th>
                                                            <td>Wall Panels: Adhesive, Direct Attach, Z-Clip, Stick Pin options available<br />
				                                                Ceiling Tiles: Lay-In, Adhesive
                                                            </td>
                                                        </tr>
	                                                </tbody>
                                                </table>
		                                    </div>
                                            <div id="dp-menu1" className="container tab-pane fade"><br />
                                                <h3>Sound Absorption Coefficients</h3>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Thickness</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">20mm</th>
                                                            <td>0.05</td>
                                                            <td>0.22</td>
                                                            <td>0.64</td>
                                                            <td>0.92</td>
                                                            <td>1.04</td>
                                                            <td>1.02</td>
                                                            <td>0.70</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">25mm</th>
                                                            <td>0.14</td>
                                                            <td>0.27</td>
                                                            <td>0.80</td>
                                                            <td>1.11</td>
                                                            <td>1.14</td>
                                                            <td>1.14</td>
                                                            <td>0.85</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">40mm</th>
                                                            <td>0.21</td>
                                                            <td>0.65</td>
                                                            <td>0.95</td>
                                                            <td>0.99</td>
                                                            <td>1.01</td>
                                                            <td>1.02</td>
                                                            <td>0.90</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">50mm</th>
                                                            <td>0.22</td>
                                                            <td>0.81</td>
                                                            <td>1.24</td>
                                                            <td>1.30</td>
                                                            <td>1.21</td>
                                                            <td>1.16</td>
                                                            <td>1.15</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
		                                    </div>
                    	                    <div id="dp-menu3" className="container tab-pane fade"><br />
                                            <br />
                                                <h3>Acoustical Designer Panels : Datasheet</h3>
		                                        <br />
		                                        <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/FABRISORB/asi-DESIGNER panels.pdf" className="brochure">Download</a>
                                            </div>
                                        </div>
                                    </div>			
			                    </div>
		                    </div>
	                    </div>
	                    <div className="line"></div>			
	                    <div className="col-sm-12 download-button">
		                    <div className="row">
			                    <div className="col-sm-6">
                                    <Link to="https://acousticalsurfaces.in/pdfs/polysorb/POLYSORB-Brochure.pdf" target='_blank'>
                                        <img className="mx-auto d-block" src={Polysorbbrochure1} alt="Polysorb Brochure1" />
                                    </Link>
                                </div>
			                    <div className="col-sm-6">
                                    <Link to="https://acousticalsurfaces.in/pdfs/polysorb/POLYSORB-Brochure.pdf" target='_blank'>
                                        <img className="mx-auto d-block" src={Polysorbbrochure2} alt="Polysorb Brochure2" />
                                    </Link>
                                </div>
		                    </div>	
	                    </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default polysorb; 