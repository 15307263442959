import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import './acfabric-quadrille.css';
import Mobsidebar from '../mobile-sidebar';
import Q1 from '../../images/acfabric/q1.jpg';
import Q2 from '../../images/acfabric/q2.jpg';
import Q3 from '../../images/acfabric/q3.jpg';
import Q4 from '../../images/acfabric/q4.jpg';
import Q5 from '../../images/acfabric/q5.jpg';
import Q6 from '../../images/acfabric/q6.jpg';
import Q7 from '../../images/acfabric/q7.jpg';
import Q8 from '../../images/acfabric/q8.jpg';
import Q9 from '../../images/acfabric/q9.jpg';
import Q10 from '../../images/acfabric/q10.jpg';
import Q11 from '../../images/acfabric/q11.jpg';
import Q12 from '../../images/acfabric/q12.jpg';
import Q13 from '../../images/acfabric/q13.jpg';
import Q14 from '../../images/acfabric/q14.jpg';
import Q15 from '../../images/acfabric/q15.jpg';
import Q16 from '../../images/acfabric/q16.jpg';
import Q17 from '../../images/acfabric/q17.jpg';
import ScrollToTopButton from '../ScrollButton';
function acfabricquad() {
    return (
      
    <>
    <Helmet>
    <title>Acoustic of Maine Quadrille Acoustic Fabric - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="AcoustyFABRIC Quadrille" />
    <meta property="og:description" content="Guilford of Maine Quadrille 4701 Fabric Facings" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/acfabric/q1.jpg" />
    <meta property="og:locale" content="en-in" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/acfabric-quadrille" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/acfabric-quadrille" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acfabric-quadrille" hreflang="en-ae" />
    </Helmet>
    <Header /> 
    <section className="content" id="acfabricquadrille">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
            	<div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
	                <div className="col-sm-12 heading-fsorb">				
		                <h1>Acousty<span className="fabriwall">FABRIC</span> Quadrille</h1>
                        <h4 className="mb-0"> <b>Guilford of Maine Quadrille 4701 Fabric Facings</b></h4>
		                <p><span className="color:red;">(Colors Shown are Approximate Match to Material)</span></p>
	                    <br />
		                <div className="col-sm-3 cloths p-0"><button>Product Specifications</button></div>
	                </div>
		            <br /><br />
		            <div className="col-sm-12 cloths">
		                <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Q1} alt="Cabaret" />
                                <p className="text-center">Cabaret 1002</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Q2} alt="Carnival" />
                                <p className="text-center">Carnival 1009</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Q3} alt="Escapade" />
                                <p className="text-center">Escapade 1016</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Q4} alt="Fair" />
                                <p className="text-center">Fair 1001</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Q5} alt="Fiesta" />
                                <p className="text-center">Fiesta 1008</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Q6} alt="Gambol" />
                                <p className="text-center">Gambol 1003</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Q7} alt="Jubilee" />
                                <p className="text-center">Jubilee 1011</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Q8} alt="Leisure" />
                                <p className="text-center">Leisure 1004</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Q9} alt="Masquerade" />
                                <p className="text-center">Masquerade 1013</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Q10} alt="Opus" />
                                <p className="text-center">Opus 1012</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Q11} alt="Regalia" />
                                <p className="text-center">Regalia 1005</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Q12} alt="Revelry" />
                                <p className="text-center">Revelry 1015</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Q13} alt="Shenanigan" />
                                <p className="text-center">Shenanigan 1017</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Q14} alt="Soiree" />
                                <p className="text-center">Soiree 1014</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Q15} alt="Spree" />
                                <p className="text-center">Spree 1010</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Q16} alt="Spring" />
                                <p className="text-center">Spring 1006</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Q17} alt="Zest" />
                                <p className="text-center">Zest 1007</p>
                            </div>
                            <div className="col-sm-2 p-0 mx-2"></div>
                            <div className="col-sm-2 p-0 mx-2"></div>
                            <div className="col-sm-2 p-0 mx-2"></div>
                        </div>	
                    </div>
		            <br />
		            <hr />
                    <div className="col-12 d-flex justify-content-center backbtn">
                    	<Link to="/acoustyfabric"><button>Back to Acousty<span style={{color: "red"}}>FABRIC</span></button></Link>
                    </div>
                    <hr />
                    <div className="col-sm-12 application">
			            <h1>APPLICATION</h1>
	                    <div className="hover14 text-center justify-content-center">
		                <br />
  		                    <div className="col-sm-12">
			                    <figure className="m-0"><div className="tinted-image">CINEMA HALL</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image2">OFFICE ROOMS & HALLS</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image3">AUDITORIUM & EDUCATION</div></figure>
 		                    </div>
                        <br />
		                </div>
                    </div>
		            <br />
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acfabricquad;