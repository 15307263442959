import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import './soundproofing-fixes2.css';
import Ebook from '../../images/additional-resources/Easy-soundproofing-fixes-part-2.png';
import ScrollToTopButton from '../ScrollButton';
function soundprooffixes2() {
    return (
      
    <>
    <Helmet>
    <meta name="description" content="" />
    <title>Easy Soundproofing Fixes - Acoustical Surfaces </title>
    <meta property="og:title" content="Structural soundproofing solutions" />
    <meta property="og:description" content="We take you through the ideal solutions for structural soundproofing." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.png" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/soundproofing-fixes_2" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/soundproofing-solutions/soundproofing-fixes_2" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/soundproofing-solutions/soundproofing-fixes_2" hreflang="en-in" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="IN" />
    <meta name="geo.region" content="india" />
    </Helmet>
    <Header />
    <section className="content downloadpage" id="fixes2">
        <div className="container">
            <hr />
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3>Easy Soundproofing Fixes - Part 2</h3>
                    <p style={{color: "#f63340"}}><strong>The Big 3 for Structures: Walls, Ceilings, & Floors</strong></p>
                    <div className="row">
                        <div className="col-lg-7 col-md-7 col-sm-12">
                            <p>In part 2 of our series, we take you through the ideal solutions for structural soundproofing. Each of the solutions includes the product, benefits, applications, and next-to-best solutions accordingly.</p>
                            <p><strong>Structural soundproofing solutions for:</strong></p>
                            <ul style={{marginleft: "2em"}}>
                                <li>Walls</li>
                                <li>Ceilings</li>
                                <li>Floors</li>
                            </ul>
                            <p style={{color: "#f63340"}}>Download your copy of Easy Soundproofing Fixes - Part 2 by filling out the form on this page.</p>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <img src={Ebook} alt="eBook" className="img-fluid" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 form">
                    <h4>Fill out the form to download your eBook</h4>
                    <div className="enqform">           
                        <form action="/dl-script-easy_Soundproofing_pt2" method="post" className="enquiry-form">
                            <div className="">
                                <input className="form-control" type="text" name="inputName" placeholder="Name" required="" />
                            </div>
                            <div className="">
                                <input className="form-control" type="text" name="inputCompany" placeholder="Company" required="" />
                            </div>
                            <div className="">
                                <input className="form-control" type="tel" name="inputTelephone" placeholder="Telephone" required="" />
                            </div>
                            <div className="">
                                <input className="form-control" type="email" name="inputEmail" placeholder="Email" required="" />
                            </div>
                            <div className="">
                                <input type="hidden" name="inputMessage" value="Easy Soundproofing Fixes - Part 2 Download" />
                            </div>
                            <Link to="mailto:info@acousticalsurfaces.in" type="submit" name="submit_form" className="btn-send">Submit</Link>
                        </form>
                    </div>      
                </div>
                <div className="line"></div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default soundprooffixes2; 