import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Clouds1 from '../../images/inner/pool1-200x200.webp';
import Clouds2 from '../../images/inner/fbs_fabr11-200x200.webp';
import Clouds3 from '../../images/inner/soundproofing_baffle_lg1-200x200.webp';
import Clouds4 from '../../images/inner/ssabaffle11-200x194.webp';
import Clouds5 from '../../images/inner/fabric-panel-cloud-hanging-200x200.webp';
import Clouds6 from '../../images/inner/whisperwave-horizontal-hanging-baffles1.webp';
import './soundproofing-clouds-and-baffles.css';
import ScrollToTopButton from '../ScrollButton';
function soundproofclouds() {
    return (
      
    <>
    <Helmet>
    <title>Soundproofing Clouds and Baffles Products</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Soundproofing Cloud and Baffle Products" />
    <meta property="og:description" content=" clouds and baffles offer outstanding control across all frequencies as well as outstanding design flexibility." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/pool1-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/clouds-baffles" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/soundproofing-clouds-and-baffles" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/soundproofing-clouds-and-baffles" />
    </Helmet>
    <Header />
    <section className="content" id="cloudsbaffles">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Soundproofing Clouds and Baffles</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>For exceptional acoustical control in large, open spaces, clouds and baffles offer outstanding control across all frequencies as well as outstanding design flexibility. These products solve challenging acoustical problems in concert halls, auditoriums, gymnasiums, or meeting halls to aid in improving sound quality and occupant enjoyment.</p>
                                    <p>The proprietary products we supply come in many forms, shapes, thicknesses, materials, colors, and price points to meet your specific needs. Clouds and sound baffles are unique in that they combine noise and reverberation reduction with architectural effects to add visual beauty and interest to a space. Unlike full ceilings, clouds and baffles are installed in specific places that need acoustical control. Want to add interest and acoustical control together? Take a look at the WHISPERWAVE™ system of ceiling clouds. These custom sized curved panels are lightweight, easy to install, visually interesting, and greatly improve acoustic qualities.</p>
                                    <p>Clouds and sound baffles bring together sound reduction and aesthetic qualities for the best in function and form. See some of our exceptional line above, and feel free to <Link to="/contact-us">contact us</Link> any time.</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>Baffles And Clouds Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Clouds1} alt="PVC and Sailcloth Baffles" />
                                </Link>
                                <h5>PVC and Sailcloth Baffles</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Clouds2} alt="Fabric Wrapped Fiberglass Baffles" />
                                </Link>
                                <h5>Fabric Wrapped Fiberglass Baffles</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Clouds3} alt="Sound proofing baffle" />
                                </Link>
                                <h5>Echo Eliminator Baffles</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Clouds4} alt="SSA Baffle" />
                                </Link>
                                <h5>Sound Silencer Baffles</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Clouds5} alt="Fabric Panel" />
                                </Link>
                                <h5>Fabric Wrapped Fiberglass Clouds</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Clouds6} alt="Whisperwave" />
                                </Link>
                                <h5>Whisper Wave Clouds</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default soundproofclouds; 