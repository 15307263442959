import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import './acfabric-framework.css';
import Mobsidebar from '../mobile-sidebar';
import Fw1 from '../../images/acfabric/fw1.jpg';
import Fw2 from '../../images/acfabric/fw2.jpg';
import Fw3 from '../../images/acfabric/fw3.jpg';
import Fw4 from '../../images/acfabric/fw4.jpg';
import Fw5 from '../../images/acfabric/fw5.jpg';
import Fw6 from '../../images/acfabric/fw6.jpg';
import Fw7 from '../../images/acfabric/fw7.jpg';
import Fw8 from '../../images/acfabric/fw8.jpg';
import Fw9 from '../../images/acfabric/fw9.jpg';
import Fw10 from '../../images/acfabric/fw10.jpg';
import Fw11 from '../../images/acfabric/fw11.jpg';
import Fw12 from '../../images/acfabric/fw12.jpg';
import Fw13 from '../../images/acfabric/fw13.jpg';
import Fw14 from '../../images/acfabric/fw14.jpg';
import ScrollToTopButton from '../ScrollButton';
function acfabricframe() {
    return (
      
    <>
    <Helmet>
    <title>Acoustic of Maine Framework Acoustic Fabric - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="AcoustyFABRIC Framework" />
    <meta property="og:description" content="Guilford of Maine Framework 2762 Fabric Facings" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/acfabric/fw1.jpg" />
    <meta property="og:locale" content="en-in" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/acfabric-framework" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/acfabric-framework" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acfabric-framework" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="acfabricframework">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
            	<div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
	                <div className="col-sm-12 heading-fsorb">				
		                <h1>Acousty<span className="fabriwall">FABRIC</span> Framework</h1>
                		<h4 className="mb-0"> <b>Guilford of Maine Framework 2762 Fabric Facings</b></h4>
		                <p><span className="color:red;">(Colors Shown are Approximate Match to Material)</span></p>
	                    <br />
		                <div className="col-sm-3 cloths p-0"><button>Product Specifications</button></div>
	                </div>
		            <br /><br />
		            <div className="col-sm-12 cloths">
		                <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fw1} alt="Stone" />
                                <p className="text-center">Stone 2688</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fw2} alt="Sandy Pebble" />
                                <p className="text-center">Sandy Pebble 2554</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fw3} alt="Grotto" />
                                <p className="text-center">Grotto 2829</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fw4} alt="Gray Blue" />
                                <p className="text-center">Gray Blue 2553</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fw5} alt="Fresco" />
                                <p className="text-center">Fresco 2830</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fw6} alt="Eucalyptus" />
                                <p className="text-center">Eucalyptus 2555</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fw7} alt="Espresso" />
                                <p className="text-center">Espresso 2833</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fw8} alt="Elephant" />
                                <p className="text-center">Elephant 2690</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fw9} alt="Dove Gray" />
                                <p className="text-center">Dove Gray 2689</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fw10} alt="Chamois" />
                                <p className="text-center">Chamois 2552</p>
                            </div>
                        </div>	
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fw11} alt="Capri" />
                                <p className="text-center">Capri 2831</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fw12} alt="Cappuccino" />
                                <p className="text-center">Cappuccino 2832</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fw13} alt="Blue Smooth" />
                                <p className="text-center">Blue Smooth 2519</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fw14} alt="Beige" />
                                <p className="text-center">Beige 2687</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2"></div>
                        </div>	
                    </div>
		            <br />
		            <hr />
                    <div className="col-12 d-flex justify-content-center backbtn">
                	    <Link to="/acoustyfabric"><button>Back to Acousty<span style={{color: "red"}}>FABRIC</span></button></Link>
                    </div>
                    <hr />
                    <div className="col-sm-12 application">
			            <h1>APPLICATION</h1>
	                    <div className="hover14 text-center justify-content-center">
		                <br />
  		                    <div className="col-sm-12">
			                    <figure className="m-0"><div className="tinted-image">CINEMA HALL</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image2">OFFICE ROOMS & HALLS</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image3">AUDITORIUM & EDUCATION</div></figure>
 		                    </div>
                        <br />
                        </div>
                    </div>
		            <br />
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acfabricframe;