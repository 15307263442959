import React from 'react';
import { Link } from 'react-router-dom';
import './slider-foamstop-pu.css';
import Banner1 from '../../images/bannera.webp';
import Banner2 from '../../images/bannerb.webp';
import Banner3 from '../../images/bannerc.webp';
import Banner4 from '../../images/bannerd.webp';
import Banner5 from '../../images/bannere.webp';
function slider() {
	return (
	  
	<>
	<div id="slider-foamstop-pu">
		<div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
  			<ol className="carousel-indicators">
    			<li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
    			<li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    			<li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    			<li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
    			<li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
  			</ol>
  			<div className="carousel-inner">
    			<div className="carousel-item active">
      				<img className="d-block w-100" src={Banner1} alt="Cylindrical Foam" />
	  				<div className="carousel-caption d-none d-md-block">
					  	<h1>Cylindrical Foam</h1>
						<i className="fa fa-download" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/catalogue/foam-stop-polyurethane/foam-stop-polyurethane-catalogue.pdf" target="_blank" className="demo-cont__credits-link">Linear Panels Brochure</Link>
                    </div>
				</div>
    			<div className="carousel-item">
      				<img className="d-block w-100" src={Banner2} alt="Basstrap Foam" />
	  				<div className="carousel-caption d-none d-md-block">
					  	<h1>Basstrap Foam</h1>
						<i className="fa fa-download" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/catalogue/foam-stop-polyurethane/foam-stop-polyurethane-catalogue.pdf" target="_blank" className="demo-cont__credits-link">Perforated Panels Brochure</Link>
                    </div>
				</div>
				<div className="carousel-item">
      				<img className="d-block w-100" src={Banner3} alt="Pyramid Foam" />
	  				<div className="carousel-caption d-none d-md-block">
					  	<h1>Pyramid Foam</h1>
						<i className="fa fa-download" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/catalogue/foam-stop-polyurethane/foam-stop-polyurethane-catalogue.pdf" target="_blank" className="demo-cont__credits-link">Slotted Panels Brochure</Link>
                    </div>
				</div>
				<div className="carousel-item">
      				<img className="d-block w-100" src={Banner4} alt="Plain Foam" />
	  				<div className="carousel-caption d-none d-md-block">
					  	<h1>Plain Foam</h1>
						<i className="fa fa-download" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/catalogue/foam-stop-polyurethane/foam-stop-polyurethane-catalogue.pdf" target="_blank" className="demo-cont__credits-link">Designer Panels Brochure</Link>
                    </div>
				</div>
				<div className="carousel-item">
      				<img className="d-block w-100" src={Banner5} alt="Eggcrate Foam" />
	  				<div className="carousel-caption d-none d-md-block">
					  	<h1>Eggcrate Foam</h1>
						<i className="fa fa-download" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/catalogue/foam-stop-polyurethane/foam-stop-polyurethane-catalogue.pdf" target="_blank" className="demo-cont__credits-link">Designer Panels Brochure</Link>
                    </div>
				</div>
			</div>
  			<a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    			<span className="carousel-control-prev-icon" aria-hidden="true"></span>
    			<span className="sr-only">Previous</span>
  			</a>
  			<a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    			<span className="carousel-control-next-icon" aria-hidden="true"></span>
    			<span className="sr-only">Next</span>
  			</a>
		</div>
	</div>
	</>
);
}
export default slider;