import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Hanging1 from '../../images/inner/soundproofing_baffle_lg-350x410.webp';
import Hanging2 from '../../images/inner/Echo-Eliminator-baffles-two-200x200.webp';
import Hanging3 from '../../images/inner/Echo-Eliminator-Baffles-200x200.webp';
import Hanging4 from '../../images/inner/sound-proofing-002-200x200.webp';
import Hanging5 from '../../images/inner/white_lg-200x200.webp';
import Hanging6 from '../../images/inner/black_lg-200x200.webp';
import Hanging7 from '../../images/inner/marble_lg-200x200.webp';
import Hanging8 from '../../images/inner/lightgrey_lg-200x200.webp';
import Hanging9 from '../../images/inner/graphite_lg-200x200.webp';
import Hanging10 from '../../images/inner/grommets.webp';
import Hanging11 from '../../images/inner/ee_hang_chart.webp';
import './hanging-acoustic-baffles.css';
import ScrollToTopButton from '../ScrollButton';
function hangingacoustic() {
    return (
      
    <>
    <Helmet>
    <title>Echo Eliminator Baffles - Hanging Acoustical Baffles</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Echo Eliminator Baffles" />
    <meta property="og:description" content="" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/soundproofing_baffle_lg-350x410.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/echo-eliminator-baffles" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/hanging-acoustic-baffles" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/hanging-acoustic-baffles" />
    </Helmet>
    <Header />
    <section className="content" id="hangingbaffles">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Echo Eliminator™</h1>
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/soundproofing_baffle_lg-350x410.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Hanging1} alt="Hanging acoustic baffles1" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Hanging Acoustical Baffles</h2>
                                <ul style={{marginleft: "1em"}}>
                                    <li>No Fiberglass</li>
                                    <li>Economical</li>
                                    <li>Recycled Cotton Material</li>
                                    <li>Can Be Hung Vertically or Horizontally</li>
                                    <li>Eligible for LEED&trade; Credits (more information)</li>
                                </ul>
                                <Link to="https://acousticalsurfaces.in/pdfs/Echo_Better1.pdf" target="_blank">Echo Eliminator™ VS. Other Acoustical Materials</Link>
                                <Link to="https://acousticalsurfaces.in/pdfs/Baffle_Grommet_Placement.pdf" target="_blank">Echo Eliminator Baffle Grommet Placement Options</Link>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/Echo_Eliminator_Hanging_Acoustical_Baffles.pdf" target="_blank">Product Specs</Link>
                                        </li>
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/MSDS_BAP.pdf" target="_blank">MSDS</Link>
                                        </li>
                                        <li>
                                            <Link to="cad-library-guide">CSI, CAD, and Revit Files</Link>
                                        </li>
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/09843_Hanging_Acoustical_Cotton_Baffles.doc">MCSI Specifications</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <h2 style={{marginleft: ".3em"}}>Hanging Ceiling Panel Photos</h2>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/Echo-Eliminator-baffles-two-200x200.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Hanging2} alt="Hanging acoustic baffles2" />
                                </Link>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/Echo-Eliminator-Baffles-200x200.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Hanging3} alt="Hanging acoustic baffles3" />
                                </Link>
                            </div>  
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/sound-proofing-002-200x200.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Hanging4} alt="Hanging acoustic baffles4" />
                                </Link>
                            </div>
                        </div>
                        <h2 style={{marginleft: ".3em"}}>Color Options</h2>
                        <p>Larger images show grommet color options. Click each color swatch for a larger image.</p>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 fleximages">
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/white_lg-200x200.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Hanging5} alt="Hanging acoustic baffles5" />
                                    </Link>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/black_lg-200x200.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Hanging6} alt="Hanging acoustic baffles6" />
                                    </Link>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/marble_lg-200x200.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Hanging7} alt="Hanging acoustic baffles7" />
                                    </Link>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/lightgrey_lg-200x200.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Hanging8} alt="Hanging acoustic baffles8" />
                                    </Link>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" href="../../images/inner/graphite_lg-200x200.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Hanging9} alt="Hanging acoustic baffles9" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <h2 style={{marginleft: ".3em"}}>Grommet Color Options – Gold or Silver</h2>
                        <img className="example-image img-fluid" src={Hanging10} alt="Hanging acoustic baffles10" />
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Product Specs</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>Material</th>
                                            <td>Bonded Acoustical Cotton (B.A.C.). 100% Recyclable, Fiberglass Free.</td>
                                        </tr>
                                        <tr>
                                            <th>Pattern</th>
                                            <td>Soft Textured Surface </td>
                                        </tr>
                                        <tr>
                                            <th>Features</th>
                                            <td>Economical, Multi-Purpose, High Performance, Durable, Lightweight. </td>
                                        </tr>
                                        <tr>
                                            <th>Applications</th>
                                            <td>Gymnasiums, Auditoriums, classNamerooms, Music Rooms, Arenas & Stadiums, Churches, Offices, Computer Rooms, Restaurants/Cafeterias, Residential, Concert Halls & Theaters, Pro Audio, Gun Ranges, Manufacturing Plants, Machine Enclosures, Water Treatment Plants. </td>
                                        </tr>
                                        <tr>
                                            <th>Thickness</th>
                                            <td>1″, 2″</td>
                                        </tr>
                                        <tr>
                                            <th>Sizes</th>
                                            <td>2′ × 4′ – Custom Sizes & Die Cut Parts Available </td>
                                        </tr>
                                        <tr>
                                            <th>Colors</th>
                                            <td>White, Black, Marble Light Blue, Light Grey, Graphite, Burgundy, Pure Blue, Navy Blue, Hunter Green and Beige </td>
                                        </tr>
                                        <tr>
                                            <th>Flammability</th>
                                            <td>ASTM E84, className A. Flame Spread: 5 Smoke Developed: 35 </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="8">Echo Eliminator Baffle Sabins/Baffle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td></td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>Avg</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">1″ 2′ ×4′</td>
                                            <td>2.43</td>
                                            <td>4.93</td>
                                            <td>8.34</td>
                                            <td>12.35</td>
                                            <td>15.57</td>
                                            <td>16.73</td>
                                            <td className="color-red">10.30</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">2″ 2′ × 4′</td>
                                            <td>2.40</td>
                                            <td>6.55</td>
                                            <td>12.86</td>
                                            <td>17.46</td>
                                            <td>18.49</td>
                                            <td>16.77</td>
                                            <td className="color-red">13.85</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/ee_hang_chart.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Hanging11} alt="Hanging acoustic baffles11" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default hangingacoustic; 