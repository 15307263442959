import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Bd1 from '../../images/acfabric/bd1.jpg';
import Bd2 from '../../images/acfabric/bd2.jpg';
import Bd3 from '../../images/acfabric/bd3.jpg';
import Bd4 from '../../images/acfabric/bd4.jpg';
import Bd5 from '../../images/acfabric/bd5.jpg';
import './acfabric-broadcast.css';
import ScrollToTopButton from '../ScrollButton';
function acfabricbroad() {
    return (
      
    <>
    <Helmet>
    <title>Acoustic of Maine Broadcast Fabric - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="AcoustyFABRIC Maine Broadcast" />
    <meta property="og:description" content="Guilford of Maine Broadcast 2758 Fabric Facings" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/acfabric/bd1.jpg" />
    <meta property="og:locale" content="en-in" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/acfabric-broadcast" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/acfabric-broadcast" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acfabric-broadcast" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="acfabricbroadcast">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
            	<div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
	                <div className="col-sm-12 heading-fsorb">				
		                <h1>Acousty<span className="fabriwall">FABRIC</span> Maine Broadcast</h1>
                		<h4 className="mb-0"> <b>Guilford of Maine Broadcast 2758 Fabric Facings</b></h4>
		                <p><span className="color:red;">(Colors Shown are Approximate Match to Material)</span></p>
	                    <br />
		                <div className="col-sm-3 cloths p-0"><button>Product Specifications</button></div>
	                </div>
                    <br /><br />
		            <div className="col-sm-12 cloths">
		                <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Bd1} alt="Shade 2372" />
                                <p className="text-center">Shade 2372</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Bd2} alt="Serengeti 1196" />
                                <p className="text-center">Serengeti 1196</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Bd3} alt="Mink 2686" />
                                <p className="text-center">Mink 2686</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Bd4} alt="Graphite 2375" />
                                <p className="text-center">Graphite 2375</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Bd5} alt="Chicory 1205" />
                                <p className="text-center">Chicory 1205</p>
                            </div>
                        </div>
                    </div>
		            <br />
		            <hr />
                    <div className="col-12 d-flex justify-content-center backbtn">
                    	<Link to="/acoustyfabric"><button>Back to Acousty<span style={{color: "red"}}>FABRIC</span></button></Link>
                    </div>
                    <hr />
                    <div className="col-sm-12 application">
			            <h1>APPLICATION</h1>
                        <div className="hover14 text-center justify-content-center">
		                <br />
  		                    <div className="col-sm-12">
			                    <figure className="m-0"><div className="tinted-image">CINEMA HALL</div></figure>
 		                    </div>
                            <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image2">OFFICE ROOMS & HALLS</div></figure>
 		                    </div>
                            <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image3">AUDITORIUM & EDUCATION</div></figure>
 		                    </div>
                            <br />
		                </div>
                    </div>
		            <br />
                </div>
            </div>    
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>    
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acfabricbroad;