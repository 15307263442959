import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import './acfabric-lido.css';
import Mobsidebar from '../mobile-sidebar';
import L1 from '../../images/acfabric/l1.jpg';
import L2 from '../../images/acfabric/l2.jpg';
import L3 from '../../images/acfabric/l3.jpg';
import L4 from '../../images/acfabric/l4.jpg';
import L5 from '../../images/acfabric/l5.jpg';
import L6 from '../../images/acfabric/l6.jpg';
import L7 from '../../images/acfabric/l7.jpg';
import L8 from '../../images/acfabric/l8.jpg';
import L9 from '../../images/acfabric/l9.jpg';
import L10 from '../../images/acfabric/l10.jpg';
import L11 from '../../images/acfabric/l11.jpg';
import L12 from '../../images/acfabric/l12.jpg';
import L13 from '../../images/acfabric/l13.jpg';
import L14 from '../../images/acfabric/l14.jpg';
import L15 from '../../images/acfabric/l15.jpg';
import L16 from '../../images/acfabric/l16.jpg';
import ScrollToTopButton from '../ScrollButton';
function acfabriclido() {
    return (
      
    <>
    <Helmet>
    <title>Lido of Maine Acoustic Fabrics - Acousical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="AcoustyFABRIC Lido" />
    <meta property="og:description" content="Guilford of Maine Lido 2858 Fabric Facings" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/acfabric/l1.jpg" />
    <meta property="og:locale" content="en-in" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/acfabric-lido" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/acfabric-lido" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acfabric-lido" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="acfabriclido">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
            	<div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
	                <div className="col-sm-12 heading-fsorb">				
		                <h1>Acousty<span className="fabriwall">FABRIC</span> Lido</h1>
                		<h4 className="mb-0"> <b>Guilford of Maine Lido 2858 Fabric Facings</b></h4>
		                <p><span className="color:red;">(Colors Shown are Approximate Match to Material)</span></p>
	                    <br />
		                <div className="col-sm-3 cloths p-0"><button>Product Specifications</button></div>
	                </div>
		            <br /><br />
		            <div className="col-sm-12 cloths">
		                <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={L1} alt="Balboa" />
                                <p className="text-center">Balboa 019</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={L2} alt="Bryce" />
                                <p className="text-center">Bryce Canyon 065</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={L3} alt="Cape" />
                                <p className="text-center">Cape May 023</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={L4} alt="Green" />
                                <p className="text-center">Green Bay 013</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={L5} alt="Heartland" />
                                <p className="text-center">Heartland 064</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={L6} alt="Hermosa" />
                                <p className="text-center">Hermosa 010</p></div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={L7} alt="Huntington" />
                                <p className="text-center">Huntington 036</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={L8} alt="Moonstone" />
                                <p className="text-center">Moonstone 015</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={L9} alt="Napa" />
                                <p className="text-center">Napa Valley 062</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={L10} alt="Newport" />
                                <p className="text-center">Newport 030</p>
                            </div>
                        </div>	
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={L11} alt="Oak" />
                                <p className="text-center">Oak Bluffs 024</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={L12} alt="Padre" />
                                <p className="text-center">Padre 052</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={L13} alt="Pine" />
                                <p className="text-center">Pine Valley 054</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={L14} alt="Smith" />
                                <p className="text-center">Smith Point 031</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={L15} alt="Yellowstone" />
                                <p className="text-center">Yellowstone 067</p>
                            </div>
                        </div>	
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={L16} alt="Huron" />
                                <p className="text-center">Huron 055</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2"></div>
                            <div className="col-2 col-sm-2 p-0 mx-2"></div>
                            <div className="col-2 col-sm-2 p-0 mx-2"></div>
                            <div className="col-2 col-sm-2 p-0 mx-2"></div>
                        </div>	
                    </div>
		            <br />
		            <hr />
                    <div className="col-12 d-flex justify-content-center backbtn">
                        <Link to="/acoustyfabric"><button>Back to Acousty<span style={{color: "red"}}>FABRIC</span></button></Link>
                    </div>
                    <hr />
                    <div className="col-sm-12 application">
			            <h1>APPLICATION</h1>
                        <div className="hover14 text-center justify-content-center">
		                <br />
  		                    <div className="col-sm-12">
			                    <figure className="m-0"><div className="tinted-image">CINEMA HALL</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image2">OFFICE ROOMS & HALLS</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image3">AUDITORIUM & EDUCATION</div></figure>
 		                    </div>
                        <br />
	                    </div>
                    </div>
		            <br />
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acfabriclido;