import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import Flocert from '../../images/Teleperformance.webp';
import Bosch from '../../images/projects-slider/bosch.webp';
import Ferrari from '../../images/projects-slider/p1ferrari.webp';
import Kapsarc from '../../images/projects-slider/p1kapsarc.webp';
import Palace from '../../images/projects-slider/p1palace.webp';
import Louvre from '../../images/projects-slider/p1louvre.webp';
import biocon from '../../images/biocon.jpeg';
import bhutanmain from '../../images/bhutanmain.jpeg';
import epam from '../../images/Epam.jpeg';
import dlf from '../../images/dlf.jpeg';
import kalaignar from '../../images/kalaignar.jpeg';
import ipopaudio from '../../images/ipopaudio.jpeg';
import myntra from '../../images/myntra.jpeg';
import googlerio from '../../images/googlerio.jpeg';
import HDFC from '../../images/hdfc.jpeg';
import './projects.css';
import ScrollToTopButton from '../ScrollButton';
function projects() {
    return (

        <>
            <Helmet>
                <title>Turnkey Acoustic Projects - Acoustical Surfaces</title>
                <meta name="description" content="" />
                <meta property="og:title" content="Projects Gallery" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.png" />
                <meta property="og:locale" content="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.ae/projects" hreflang="en-ae" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/projects" hreflang="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/projects" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="IN" />
                <meta name="geo.region" content="india" />
            </Helmet>
            <Header />
            <section className="content projects">
                <div className="container">
                    <hr />
                    <h2 style={{ textalign: "center", paddingbottom: "12px" }}>Projects Gallery</h2>
                    <div style={{ width: "18%", bordertop: "1px solid #e0dede", margin: "auto", padding: "10px" }}></div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>HDFC Auditorium</h4>
                            <img className="img-fluid myntra" src={HDFC} alt="hdfc" style={{ height: "350px" }} />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-2" style={{ textalign: "justify" }}>
                            <h5>About HDFC AUDITORIUM</h5>
                            <p style={{ textAlign: "justify" }}>HDFC Bank Residential Training Centre is located as a part of a commercial sector at Palava- a new township on mumbai's outskirts.Training cum Residential Block having 8 floor levels whereas Bank branch is having ground and 1 floor level. The project aims at various firsts by aiming for a platinum green building rating as well as Health + wellbeing rating.</p>
                            <h5>Our Scope</h5>
                            <p>Supply and Installation of FABRIWALL System.</p>
                            <h5>Location</h5><p>Mumbai, Maharashtra</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>

                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>Google Rio</h4>
                            <img className="img-fluid myntra" src={googlerio} alt="googlerio" style={{ height: "350px" }} />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-2" style={{ textalign: "justify" }}>
                            <h5>About Google Rio</h5>
                            <p style={{ textAlign: "justify" }}>The 12-storey scheme has some of the largest single floor plates in India accommodating more than 7,000 employees with a variety of self-sustainable but connected communities on every floor plate, each designed to enhance interaction between employees and different teams.</p>
                            <h5>Our Scope</h5>
                            <p>Supply of FOAMSTOPPOLYURETHANE.</p>
                            <h5>Location</h5><p>Bangalore, Karnataka</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>

                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>Myntra</h4>
                            <img className="img-fluid myntra" src={myntra} alt="myntra" style={{ height: "350px" }} />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-2" style={{ textalign: "justify" }}>
                            <h5>About Myntra</h5>
                            <p style={{ textAlign: "justify" }}>Myntra is one of the largest fashion e-commerce stores in India that deals withawiderange of fashion and lifestyle products for men, women, and kids. It sells high-qualityclothes, branded footwear, bags and backpacks, beauty and personal care products, home and living accessories, and more.</p>
                            <h5>Our Scope</h5>
                            <p>Supply and installation of Fabrisorb Wall panels and Clouds.</p>
                            <h5>Location</h5><p>Bangalore, Karnataka</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>

                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>Ipop Audio-Experience Centre</h4>
                            <img className="img-fluid ipopaudio" src={ipopaudio} alt="ipopaudio" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-2" style={{ textalign: "justify" }}>
                            <h5>About Ipop Audio-Experience Centre</h5>
                            <p style={{ textAlign: "justify" }}>IPOP Audio is a premier destination for home theatre enthusiasts, offering a state-of-the-art experience centre where customers can explore the latest in audio-visual technology. With a commitment to delivering unparalleled sound quality and immersive experiences, IPOP Audio has established itself as a leader in the home theatre industry.</p>
                            <h5>Our Scope</h5>
                            <p>Assessment, Planning, Customisation and Supply of D'ecopanel to achieve a great appeal to acoustics as well as aesthetics.</p>
                            <h5>Location</h5><p>Pondicherry</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>

                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>Kalaignar Memorial Library</h4>
                            <img className="img-fluid" src={kalaignar} alt="kalaignar memorial library" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-2" style={{ textalign: "justify" }}>
                            <h5>About Kalaignar Memorial Library</h5>
                            <p style={{ textAlign: "justify" }}>The Kalaignar Centenary Library, equipped with modern facilities including a digital section, and containing over three lakh titles and an exclusive children's section, was inaugurated by Tamil Nadu Chief Minister M K Stalin.</p>
                            <p style={{ textAlign: "justify" }}>The six-storeyed structure, which resembles the Anna Centenary library in Chennai, has been constructed on 2.13 lakh square foot area at a cost of Rs 120. 75 crore by the Public Works Department.</p>
                            <h5>Our Scope</h5>
                            <p>Supply & Installation of FABRIWALL, D’ECOPANEL-Baffles & ENVIROCOUSTIC WOOD WOOL.</p>
                            <h5>Location</h5><p>Madurai, Tamil Nadu</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>

                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>DLF The Camellias</h4>
                            <img className="img-fluid" src={dlf} alt="dlf" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-2" style={{ textalign: "justify" }}>
                            <h5>About DLF The Camellias</h5>
                            <p style={{ textAlign: "justify" }}>DLF Camellias in Golf Course Road, Gurgaon is one of the finest luxury condominium project built by the prestigious firm of DLF. This high-end residential project offers premium residences in the configuration of 4BHK, 5BHK and 6BHK units.</p>
                            <h5>Our Scope</h5>
                            <p>Supply & Installation of Fabriwall Acoustic  Plaster in ceilings.</p>
                            <h5>Location</h5><p>Gurugram</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>

                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>Epam</h4>
                            <img className="img-fluid" src={epam} alt="epam" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-2" style={{ textalign: "justify" }}>
                            <h5>About Epam</h5>
                            <p style={{ textAlign: "justify" }}>EPAM India has fast grown to be a 7,100+ people organization across five locations, its a part of a global network of advisors, consultants, engineers, scientists and creatives who enable customers to be competitive and disruptive in the marketplace through innovative technology solutions while helping them to navigate successfully through multiple waves of technology change.</p>
                            <h5>Our Scope</h5>
                            <p>Supply and Installation of Acoustic Plaster.</p>
                            <h5>Location</h5><p>Bangalore, Karnataka</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>Biocon</h4>
                            <img className="img-fluid" src={biocon} alt="biocon" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-2" style={{ textalign: "justify" }}>
                            <h5>About Biocon</h5>
                            <p style={{ textAlign: "justify" }}>Biocon Limited is an Indian biopharmaceutical company based in Bangalore. It was founded by Kiran Mazumdar-Shaw in 1978. The company manufactures generic active pharmaceutical ingredients that are sold in approximately 120 countries, including the United States and Europe.</p>
                            <p style={{ textAlign: "justify" }}>Biocon is the first company worldwide to develop recombinant human insulin (rh-insulin) on a Pichia expression system.</p>
                            <h5>Our Scope</h5>
                            <p>Supply and Installation of FABRIWALL system.</p>
                            <h5>Location</h5><p>Bangalore, Karnataka</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>GCIT Bhutan</h4>
                            <img className="img-fluid bhutan" src={bhutanmain} alt="GCIT BHUTAN" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-2" style={{ textalign: "justify" }}>
                            <h5>About GCIT Bhutan</h5>
                            <p style={{ textAlign: "justify" }}>The Gyalpozhing College of Infrmation Technology (GCIT), officially inaugurated on6thOctober, 2017 is one of the integral colleges of the Royal University of Bhutan.</p>
                            <p style={{ textAlign: "justify" }}>It aims at giving hands- on experience which helps the students understand the practical implementation aspects and the concepts associated with it.Furthermore, the collegeaimstodiversify the programmes by including more practical and job-oriented course in thelong-run.</p>
                            <h5>Our Scope</h5>
                            <p>Supply & Installation of POLYSORB WALL PANELS & CLOUDS</p>
                            <h5>Location</h5><p>Bhutan</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>Teleperformance</h4>
                            <img className="img-fluid" src={Flocert} alt="Flocert Bangalore" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-2" style={{ textalign: "justify" }}>
                            <h5>About Teleperformance</h5>
                            <p style={{ textAlign: "justify" }}>Teleperformance SE, is an omnichannel company headquarters in France. The company provides customer acquisition management, customer care, technical support, debt collection, social media services, and other services. It operates in 91 countries and serves 170 markets.</p>
                            <h5>Our Scope</h5>
                            <p>Supply & Installation of D'ecopanel</p>
                            <h5>Location</h5><p>Indore , Madhya Pradesh</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>Robert Bosch Engineering & Business Solutions Pvt.Ltd.</h4>
                            <img className="img-fluid bosch" src={Bosch} alt="Bosch Acoustic" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{ textalign: "justify" }}>
                            <h5>About Bosch</h5>
                            <p style={{ textAlign: "justify" }}>In India, Bosch has set up its first sales agency in 1922 at Kolkata and started its manufacturing operations in 1951. This has grown over the years to 18 manufacturing sites and seven development and application centres. With a legacy of 100 years, Bosch India is a leading technology and services provider in the areas of Mobility Solutions, Industrial Technology, Consumer Goods, and Energy and Building Technology.  Bosch India has the largest development centre outside Germany for end to end engineering and technology solutions.</p>
                            <h5>Our Scope</h5>
                            <p>Supply & Installation of Fabriwall Stretch Fabric Acoustic Wall Panel System.</p>
                            <h5>Location</h5><p>Bangalore, India</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>Ferrari World </h4>
                            <img className="img-fluid" src={Ferrari} alt="Ferrari World" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{ textalign: "justify" }}>
                            <h5>About Ferrari World</h5>
                            <p style={{ textAlign: "justify" }}>The 86,000 m2 enclosed area of Ferrari World Abu Dhabi can fit 7 football fields in 'head to toe’. The total roof area of 200,000 m2 uses enough aluminum to cover 16,750 Ferraris, or if you laid the roof flat you could place 20,100 Ferrari's side by side and end to end.
                                If Ferrari World Abu Dhabi was turned upright, it would be the tallest man made structure in the world at over 300 floors!</p>
                            <h5>Our Scope</h5>
                            <p>We installed fabric wrapped acoustic wall panel system on the wall to increase the sound absorption there by to reduce reverberation.</p>
                            <h5>Location</h5><p>Abu Dhabi, UAE</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>KAPSARC </h4>
                            <img className="img-fluid" src={Kapsarc} alt="KAPSARC" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{ textalign: "justify" }}>
                            <p></p>
                            <h5>About KAPSARC</h5>
                            <p style={{ textAlign: "justify" }}>An independent non-profit organization dedicated to researching economics and technology across all platforms of energy. Designed by Zaha Hadid Architects, the modular structure is a living, organic scheme
                                where expansions to the building are allowed to grow and multiply like cells – without compromising the visual integrity of the project.
                                <br />Construction Area: 66,000m2; Site Area: 530,000 m2; Footprint Area: 28,500 m2</p>
                            <h5>Our Scope</h5>
                            <p>Supply & installation of acoustic wall panels, Echo Panels & Acoustic Treatment for the walls & movable partitions.</p>
                            <h5>Location</h5><p>Riyadh, Saudi Arabia</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>Presidential Palace</h4>
                            <img className="img-fluid" src={Palace} alt="Presidential Palace" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{ textalign: "justify" }}>
                            <p></p>
                            <h5>About Presidential Palace</h5>
                            <p style={{ textAlign: "justify" }}>The location of the new Presidential Palace is in Ras Al Akhdar peninsula adjacent to the Emirates Palace Hotel, Abu Dhabi. The development will encompass approximately 150 hectares, and will include the Palace and the required ancillary buildings.
                                The Palace will exceed 160000m2 of built area and will contain several VIP wings, a Ministers wing, and several Majlis and Dining Halls.</p>
                            <h5>Our Scope</h5>
                            <p>Supply & Installation of acoustical Wall panels. It was a great opportunity to be part of this iconic project.</p>
                            <h5>Location</h5><p>Abu Dhabi, UAE</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>Louvre</h4>
                            <img className="img-fluid" src={Louvre} alt="Louvre" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{ textalign: "justify" }}>
                            <p></p>
                            <h5>About Louvre</h5>
                            <p style={{ textAlign: "justify" }}>Abu Dhabi’s Tourism Development & Investment Company (TDIC) has completed the installation of the dome structure in its upcoming attraction Louvre Abu Dhabi, Designed by architect Jean Nouvel.
                                Louvre Abu Dhabi encompass 9,200 square metres of art galleries including a 6,681 sqm Permanent Gallery and a 2,364 sqm Temporary Gallery.</p>
                            <h5>Our Scope</h5>
                            <p>We have been instrumental in treating the Echo and optimizing the Reverberation Times by introducing more than 22,000SQM of acoustic wood wool.</p>
                            <h5>Location</h5><p>Abu Dhabi, UAE</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="paginate">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li class="page-item"><a class="page-link" href="/projects">1</a></li>
                                        <li class="page-item"><a class="page-link" href="/projects-2">2</a></li>
                                        <li class="page-item"><a class="page-link" href="/projects-3">3</a></li>
                                        <li class="page-item"><a class="page-link" href="/projects-4">4</a></li>
                                        <li class="page-item"><a class="page-link" href="/projects-5">5</a></li>
                                        <li class="page-item">
                                            <a class="page-link" href="/projects-2" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default projects; 