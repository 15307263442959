import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Ultratouch1 from '../../images/inner/recycled_logo_jeans.webp';
import Ultratouch2 from '../../images/inner/ultra_touch_denim_logo.webp';
import Ultratouch3 from '../../images/inner/ultra_touch_batt.webp';
import Ultratouch4 from '../../images/inner/denim_insulation.webp';
import Ultratouch5 from '../../images/inner/kids-with-ultratouch.webp';
import Ultratouch6 from '../../images/inner/nrc_pic.webp';
import Ultratouch7 from '../../images/inner/sgs.webp';
import './ultra-touch-insulation.css';
import ScrollToTopButton from '../ScrollButton';
function ultratouch() {
    return (
      
    <>
    <Helmet>
    <title>Ultra Touch Insulation provide extremely effective sound absorption </title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="UltraTouch Denim Insulation" />
    <meta property="og:description" content="Safe For You & The Environment" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/recycled_logo_jeans.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/ultra-touch-insulation" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/ultra-touch-insulation" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/ultra-touch-insulation" />
    </Helmet>
    <Header />
    <section className="content" id="ultra">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>UltraTouch™ Denim Insulation</h1>
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/recycled_logo_jeans.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Ultratouch1} alt="Ultra Touch1" />
                                </Link>
                                <div className="row col-md-12 col-lg-12 minigallery">
                                    <div className='col-lg-4 col-md-2'>
                                        <Link className="example-image-link" to="../../images/inner/ultra_touch_denim_logo.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" style={{margin: "5px", maxwidth: "4em"}} src={Ultratouch2} alt="Ultra Touch acoustics2" />
                                        </Link>
                                    </div>
                                    <div className='col-lg-4 col-md-2'>
                                        <Link className="example-image-link" to="../../images/inner/ultra_touch_batt.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" style={{margin: "5px", maxwidth: "4em"}} src={Ultratouch3} alt="Ultra Touch3" />
                                        </Link>
                                    </div>
                                    <div className='col-lg-4 col-md-2'>
                                        <Link className="example-image-link" to="../../images/inner/denim_insulation.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" style={{margin: "5px", maxwidth: "4em"}} src={Ultratouch4} alt="Ultra Touch4" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Safe For You & The Environment</h2>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>Does Not Itch and Is Easy To Handle</li>
                                    <li>No Chemical Irritants</li>
                                    <li>80% Post-Consumer Recycled Denim</li>
                                    <li>Provides Maximum R-Value Performance</li>
                                    <li>Class-A Building Material</li>
                                    <li>Offers a High NRC Value to Reduce Noise</li>
                                </ul>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <a href="https://acousticalsurfaces.in/pdfs/Ultratouch.pdf" target="_blank" rel="noopener noreferrer">Product Specs</a>
                                        </li>
                                        <li>
                                            <a href="https://acousticalsurfaces.in/pdfs/UltraTouch_Projects.pdf" target="_blank" rel="noopener noreferrer">Recent Project List</a>
                                        </li>
                                        <li>
                                            <a href="https://acousticalsurfaces.in/pdfs/UltraTouchInuslation-Imperative-11-Red-List-Letter.pdf" target="_blank" rel="noopener noreferrer">LBC Imperative 11 Red List</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row pad">
                            <div className="col-sm-12 text">
                                <p>UltraTouch™ Denim Insulation is the successful combination of 35 years of insulation experience and a revolutionary patented manufacturing process that has created a superior and safe product.</p>
                                <p>UltraTouch is made from high quality natural fibers. These fibers contain inherent qualities that provide for extremely effective sound absorption and maximum thermal performance. UltraTouch DOES NOT ITCH and is very easy to handle and work with.</p>
                                <p>UltraTouch contains no chemical irritants and requires no warning labels compared to other traditional products. There are no VOC concerns when using UltraTouch, as it is safe for you and the environment.</p>
                                <p>UltraTouch is also a Class-A Building Product and meets the highest ASTM testing standards for fire and smoke ratings, fungi resistance and corrosiveness.</p>
                                <p>UltraTouch™ Denim Insulation contains 80% post-consumer recycled natural fibers making it an ideal choice for anyone looking to use a high quality sustainable building material.</p>
                                <p>By installing UltraTouch, you are making both your building and the environment a safer place to live, work, and enjoy.</p>
                            </div>
                            <div className="col-sm-12 text"> 
                                <h3>Performance:</h3>
                                <ul style={{fontsize: ".9em", marginleft: "1.5em"}}>
                                    <li>UltraTouch does not itch and is easy to handle.</li>
                                    <li>UltraTouch is manufactured in over-sized widths to ensure a tight friction fit and fill capacity.</li>
                                    <li>UltraTouch provides maximum R-value performance.</li>
                                    <li>UltraTouch is a Class-A Building material that meets or exceeds ASTM testing for both commercial and residential batt insulation.</li>
                                    <li>The UltraTouch patented proprietary process treats each individual fiber with a boron-based fire retardant. This treatment not only acts as a superior fire retardant but also impedes the growth of fungus, mold, and resists pests.</li>
                                    <li>UltraTouch’s patented manufacturing technology creates a three dimensional infrastructure that traps, isolates and controls sound waves.</li>
                                    <li>UltraTouch offers an extremely high Noise Reduction Coefficient to effectively reduce airborne sound transmission including traffic, airplanes, radios, television, and conversation.</li>
                                </ul>       
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 text">
                                <h3>Health</h3>
                                <ul style={{fontsize: ".9em", marginleft: "1.5em"}}>
                                    <li>UltraTouch contains no chemical irritants and requires no carcinogenic warning labels compared to other traditional insulation products.</li>
                                    <li>UltraTouch contains no harmful airborne particulates eliminating health concerns regarding particulates in the surrounding environment.</li>
                                    <li>UltraTouch contains an EPA registered fungal inhibitor to actively resist the growth of mold, fungi and bacteria.</li>
                                    <li>UltraTouch recently passed the extremely stringent Environmental Specification 1350 Indoor Air Pollutant testing used for California Public Schools.</li>
                                </ul>
                            </div>
                            <div className="col-sm-4 d-none d-lg-block d-xl-block">
                                <img style={{margintop: "2em"}} className="img-fluid" src={Ultratouch5} alt="Ultra Touch5" />
                            </div>
                            <div className="col-sm-12 text"> 
                                <h3>Environment:</h3>
                                <ul style={{fontsize: ".9em", marginleft: "1.5em"}}>
                                    <li>UltraTouch consists almost entirely of natural denim and cotton fibers that are 100% recyclable, reducing landfill waste. The denim and cotton are also 80% post-consumer.</li>
                                    <li>UltraTouch requires a minimal amount of energy to manufacture aiding the environment with energy conservation and reduction in pollution compared to other types of traditional insulation.</li>
                                </ul>
                            </div>
                            <div className="col-sm-12 text">
                                <h2>Introducing NEW! Perforated UltraTouch Recycled Denim Insulation</h2>
                                <p className="ytvid-2"> 
                                    <iframe width="100%" height="400" src="https://www.youtube.com/embed/qaH1yVpNaGI" frameborder="0" allowfullscreen title="ultra touch"></iframe>
                                </p>
                                <p>
                                    <Link className="btn btn-asi" to="/reverb-demo">Reverberation Demonstration</Link>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="8">UltraTouch Insulation Ultrasonic Sound Absorption ASTM C423</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td>Thickness</td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>NRC</td>
                                        </tr>
                                        <tr>
                                            <td>3″</td>
                                            <td>0.95</td>
                                            <td>1.30</td>
                                            <td>1.19</td>
                                            <td>1.08</td>
                                            <td>1.02</td>
                                            <td>1.00</td>
                                            <td className="emphasis">1.15</td>
                                        </tr>
                                        <tr>
                                            <td>5″</td>
                                            <td>0.97</td>
                                            <td>1.37</td>
                                            <td>1.23</td>
                                            <td>1.05</td>
                                            <td>1.00</td>
                                            <td>1.01</td>
                                            <td className="emphasis">1.15</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/nrc_pic.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Ultratouch6} alt="Ultra Touch6" />
                                </Link>
                            </div>
                        </div> 
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="7">UltraTouch Insulation Thermal Performance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td>Product</td>
                                            <td>R-Value</td>
                                            <td>Length</td>
                                            <td>Width</td>
                                            <td>Pieces/Bundle</td>
                                            <td>Sq. Ft./Bundle</td>
                                            <td>Bundle Weight</td>
                                        </tr>
                                        <tr>
                                            <td>UTR1316</td>
                                            <td className="emphasis">R-13</td>
                                            <td>94″</td>
                                            <td>16.25″</td>
                                            <td>8</td>
                                            <td>84.88</td>
                                            <td>35.6 lbs.</td>
                                        </tr>
                                        <tr>
                                            <td>UTR1324</td>
                                            <td className="emphasis">R-13</td>
                                            <td>94″</td>
                                            <td>24.25″</td>
                                            <td>8</td>
                                            <td>126.63</td>
                                            <td>54 lbs.</td>
                                        </tr>
                                        <tr>
                                            <td>UTR1916</td>
                                            <td className="emphasis">R-19</td>
                                            <td>94″</td>
                                            <td>16.25″</td>
                                            <td>5</td>
                                            <td>53.04</td>
                                            <td>31 lbs.</td>
                                        </tr>
                                        <tr>
                                            <td>UTR1924</td>
                                            <td className="emphasis">R-19</td>
                                            <td>94″</td>
                                            <td>24.25″</td>
                                            <td>5</td>
                                            <td>79.15</td>
                                            <td>46.5 lbs.</td>
                                        </tr>
                                        <tr>
                                            <td>UTR2116</td>
                                            <td className="emphasis">R-21</td>
                                            <td>94″</td>
                                            <td>16.25″</td>
                                            <td>5</td>
                                            <td>53.04</td>
                                            <td>35.5 lbs.</td>
                                        </tr>
                                        <tr>
                                            <td>UTR2124</td>
                                            <td className="emphasis">R-21</td>
                                            <td>94″</td>
                                            <td>24.25″</td>
                                            <td>5</td>
                                            <td>79.15</td>
                                            <td>52.5 lbs.</td>
                                        </tr>
                                        <tr>
                                            <td>UTR3016</td>
                                            <td className="emphasis">R-30</td>
                                            <td>48″</td>
                                            <td>16.25″</td>
                                            <td>5</td>
                                            <td>27.10</td>
                                            <td>24.5 lbs.</td>
                                        </tr>
                                        <tr>
                                            <td>UTR3024</td>
                                            <td className="emphasis">R-30</td>
                                            <td>48″</td>
                                            <td>24.25″</td>
                                            <td>5</td>
                                            <td>40.40</td>
                                            <td>36.5 lbs.</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="2">UltraTouch Insulation Flammability Testing</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td colSpan="2" style={{textalign: "center"}}>Class A Material</td>
                                        </tr>
                                        <tr>
                                            <td>Flame Spread Index</td>
                                            <td className="emphasis">5</td>
                                        </tr>
                                        <tr>
                                            <td>Smoke Developed Value</td>
                                            <td className="emphasis">35</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <figure>
                                                    <img width="120" height="43" alt="Ultra Touch7" src={Ultratouch7} />
                                                </figure>
                                            </td>
                                            <td><p>SGS U.S. Testing Company Inc.</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>   
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default ultratouch;