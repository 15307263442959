import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import J1 from '../../images/acfabric/j1.jpg';
import J2 from '../../images/acfabric/j2.jpg';
import J3 from '../../images/acfabric/j3.jpg';
import J4 from '../../images/acfabric/j4.jpg';
import J5 from '../../images/acfabric/j5.jpg';
import J6 from '../../images/acfabric/j6.jpg';
import J7 from '../../images/acfabric/j7.jpg';
import J8 from '../../images/acfabric/j8.jpg';
import J9 from '../../images/acfabric/j9.jpg';
import J10 from '../../images/acfabric/j10.jpg';
import J11 from '../../images/acfabric/j11.jpg';
import J12 from '../../images/acfabric/j12.jpg';
import J13 from '../../images/acfabric/j13.jpg';
import J14 from '../../images/acfabric/j14.jpg';
import './acfabric-jane.css';
import ScrollToTopButton from '../ScrollButton';
function acfabricjane() {
    return (
      
    <>
    <Helmet>
    <title>Acoustic of Maine Jane Acoustic Fabric - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="AcoustyFABRIC Jane" />
    <meta property="og:description" content="Guilford of Maine Jane 9085 Fabric Facings" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/acfabric/j1.jpg" />
    <meta property="og:locale" content="en-in" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/acfabric-jane" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/acfabric-jane" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acfabric-jane" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="acfabricjane">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
            	<div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
	                <div className="col-sm-12 heading-fsorb">				
		                <h1>Acousty<span className="fabriwall">FABRIC</span> Jane</h1>
                        <h4 className="mb-0"> <b>Guilford of Maine Jane 9085 Fabric Facings</b></h4>
		                <p><span className="color:red;">(Colors Shown are Approximate Match to Material)</span></p>
	                    <br />
		                <div className="col-sm-3 cloths p-0"><button>Product Specifications</button></div>
	                </div>
		            <br /><br />
		            <div className="col-sm-12 cloths">
		                <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={J1} alt="Blueberry" />
                                <p className="text-center">Blueberry 4091</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={J2} alt="Chive" />
                                <p className="text-center">Chive 4070</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={J3} alt="Fuchsia" />
                                <p className="text-center">Fuchsia 4080</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={J4} alt="Gold" />
                                <p className="text-center">Gold 4020</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={J5} alt="Guacamole" />
                                <p className="text-center">Guacamole 4060</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={J6} alt="Lapis" />
                                <p className="text-center">Lapis 4041</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={J7} alt="Lichen" />
                                <p className="text-center">Lichen 4110</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={J8} alt="Natural" />
                                <p className="text-center">Natural 4061</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={J9} alt="Persimmon" />
                                <p className="text-center">Persimmon 4050</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={J10} alt="Pomegranate" />
                                <p className="text-center">Pomegranate 4081</p>
                            </div>
                        </div>	
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={J11} alt="Ruby" />
                                <p className="text-center">Ruby 4120</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={J12} alt="Steel" />
                                <p className="text-center">Steel 4100</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={J13} alt="Ultramarine" />
                                <p className="text-center">Ultramarine 4030</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={J14} alt="Jet" />
                                <p className="text-center">Jet 4040</p>
                            </div>
                            <div className="col-sm-2 p-0 mx-2"></div>
                        </div>	
                    </div>
		            <br />
		            <hr />
                    <div className="col-12 d-flex justify-content-center backbtn">
                    	<Link to="/acoustyfabric"><button>Back to Acousty<span style={{color: "red"}}>FABRIC</span></button></Link>
                    </div>
                    <hr />
                    <div className="col-sm-12 application">
			            <h1>APPLICATION</h1>
	                    <div className="hover14 text-center justify-content-center">
		                <br />
  		                    <div className="col-sm-12">
			                    <figure className="m-0"><div className="tinted-image">CINEMA HALL</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image2">OFFICE ROOMS & HALLS</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image3">AUDITORIUM & EDUCATION</div></figure>
 		                    </div>
                        <br />
		                </div>
                    </div>
		            <br />
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acfabricjane;