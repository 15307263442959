import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Bosch1 from '../../images/projects-slider/bosch-bangalore.webp';
import Bosch2 from '../../images/projects-slider/bosch.webp';
import Bosch3 from '../../images/projects-slider/bosch-meeting-hall.webp';
import Mobsidebar from '../mobile-sidebar';
import Soundroom from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import Cadlibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import ScrollToTopButton from '../ScrollButton';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
function Acousticbosch() {
    const sliderRef = useRef(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000, 
    };

    const goToPrev = () => {
        sliderRef.current.slickPrev();
    };

    const goToNext = () => {
        sliderRef.current.slickNext();
    };

    return (

        <>
            <Helmet>
                <title>About Our Bosch Acoustics Project </title>
                <meta name="description" content="" />
                <meta property="og:title" content="Zoho Corporation" />
                <meta property="og:description" content="Supply & Installation of Acoustic Panel System and Acrylic Sheet." />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/projects-slider/bosch.webp" />
                <meta property="og:locale" content="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/acoustic-projects/project-bosch" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/acoustic-projects/project-bosch" hreflang="en-in" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="IN" />
                <meta name="geo.region" content="india" />
            </Helmet>
            <Header />
            <section className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 sidemenu">
                            <Sidebar />
                        </div>
                        <div className="col-sm-9 product-page nopaddding">
                            <div className="container">
                                <div className="megamenu">
                                    <Megamenu />
                                </div>
                            </div>
                            <div className="image-slider pro-content">
                                <div id="caption-slide" className="slider-container">
                                    <div className="slider">
                                        <Slider {...settings} ref={sliderRef}>
                                            <div className="slide">
                                                <img src={Bosch1} className="img-fluid" alt="Bosch Interior" />
                                            </div>
                                            <div className="slide">
                                                <img src={Bosch2} className="img-fluid" alt="Bosch Bangalore" />
                                            </div>
                                            <div className="slide">
                                                <img src={Bosch3} className="img-fluid" alt="Bosch" />
                                            </div>
                                        </Slider>
                                    </div>
                                    <div className="switch slider-arrow" id="prev" onClick={goToPrev} style={{backgroundColor:"transparent"}}><span>&#10094;</span></div>
                                    <div className="switch slider-arrow" id="next" onClick={goToNext} style={{backgroundColor:"transparent"}}><span>&#10095;</span></div>
                                </div>
                            </div>
                            <div className="row pro-content">
                                <div className="col-sm-12 writeup">
                                    <h3>Robert Bosch Engineering & Business Solutions Pvt.Ltd.</h3>
                                    <p className="note">Location: Bangalore, Karnataka</p>
                                    <p><strong>About Bosch Acoustic Project.</strong>
                                        <br />In India, Bosch has set up its first sales agency in 1922 at Kolkata and started its manufacturing operations in 1951. This has grown over the years to 18 manufacturing sites and seven development and application centres. With a legacy of 100 years, Bosch India is a leading technology and services provider in the areas of Mobility Solutions, Industrial Technology, Consumer Goods, and Energy and Building Technology.  Bosch India has the largest development centre outside Germany for end to end engineering and technology solutions.
                                        <br />
                                        <br />
                                        <strong> Our Scope</strong>
                                        <br />Supply & Installation of Fabriwall Stretch Fabric Acoustic Wall Panel System.
                                    </p>
                                    <br />
                                    <hr />
                                </div>
                            </div>
                            <div className="row resources">
                                <div className="col-xl-12 col-lg-12 col-sm-12">
                                    <h2>Additional Resources</h2>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-12">
                                    <h6 style={{ textAlign: "center" }}>Creating Better-Sounding Rooms</h6>
                                    <img className="hvr-grow" src={Soundroom} alt="Create Better Sounding Rooms" target="_blank" rel="noopener noreferrer" />
                                    <div className="butn"><Link to="/bs-rooms-guide">Download eBook</Link></div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-12">
                                    <h6 style={{ textAlign: "center" }}>Solutions to Common Noise Problems</h6>
                                    <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems" target="_blank" rel="noopener noreferrer" />
                                    <div className="butn"><Link to="/noise-problems-guide">Download eBook</Link></div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-12">
                                    <h6 style={{ textAlign: "center" }}><br />CAD, CSI, & Revit Library </h6>
                                    <img className="hvr-grow" src={Cadlibrary} alt="CAD Library" target="_blank" rel="noopener noreferrer" />
                                    <div className="butn"><Link to="/cad-library-guide">Access Product Cad Files</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mobsidebar'>
                        <div className="col-sm-12">
                            <div className="container">
                                <div className="row">
                                    <Mobsidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default Acousticbosch;