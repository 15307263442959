import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Quietwalk1 from '../../images/inner/quflu7.webp';
import Quietwalk2 from '../../images/inner/qfloor06.webp';
import Quietwalk3 from '../../images/inner/qfloor07.webp';
import './quiet-floor-walk.css';
import ScrollToTopButton from '../ScrollButton';
function quietfloorwalk() {
    return (
      
    <>
    <Helmet>
    <title>Quiet Floor Walk Impact Reduction Pad for Floors</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Quiet Floor" />
    <meta property="og:description" content="" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/quflu7.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/quiet-floor-walk" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/quiet-floor-walk" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/quiet-floor-walk" />
    </Helmet>
    <Header />
    <section className="content" id="quietwalk">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Quiet Floor™</h1>
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/quflu7.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Quietwalk1} alt="Quiet Floor Walk1" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Impact Reduction Pad for Floors</h2>
                                <ul style={{marginleft: "1em"}}>                                                                
                                    <li>Economical</li>
                                    <li>Recycled “Green” Product</li>
                                    <li>Easy to Install</li>                         
                                </ul> 
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/qfunderlay.pdf"  target="_blank" rel="noopener noreferrer">Product Specs</Link>
                                        </li>
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/Specifications.pdf"  target="_blank" rel="noopener noreferrer">Specification Sheet</Link>
                                        </li>
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/Quiet_Floor_Flame_Test.pdf"  target="_blank" rel="noopener noreferrer">Flame Test</Link>
                                        </li>
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/Quiet_Floor_Flame_Smoke.pdf"  target="_blank" rel="noopener noreferrer">Flame and Smoke Test</Link>
                                        </li>
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/Quiet_Floor_MSDS.pdf"  target="_blank" rel="noopener noreferrer">MSDS</Link>
                                        </li>
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/TCA-146-01.pdf"  target="_blank" rel="noopener noreferrer">MISC. Data</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Product Specs</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tr>
                                        <th>Material</th>
                                        <td>100% Pre-consumer Recycled Synthetic Fiber</td>
                                    </tr>
                                    <tr>
                                        <th>Features</th>
                                        <td>Lightweight – Smooths Minor Floor Imperfections</td>
                                    </tr>
                                    <tr>
                                        <th>Applications</th>
                                        <td>Flooring underlay to reduce impact floor noise in commercial and residential construction.</td>
                                    </tr>
                                    <tr>
                                        <th>Thickness</th>
                                        <td>1/8"</td>
                                    </tr>
                                    <tr>
                                        <th>Sizes</th>
                                        <td>3′×33-1/3′ & 61″×60′ Rolls</td>
                                    </tr>
                                    <tr>
                                        <th>Weight</th>
                                        <td>2.22 oz/sf</td>
                                    </tr>
                                    <tr>
                                        <th>Density</th>
                                        <td>13.3 lbs/cub. ft.</td>
                                    </tr>
                                    <tr>
                                        <th>Flammability</th>
                                        <td>Meets Federal Flammability Standard CPSC-FFI-70 (Pill Test)</td>
                                    </tr>
                                    <tr>
                                        <th>Installation</th>
                                        <td>Noise S.T.O.P.™ Acoustical Adhesive</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="8">Quiet Floor Sound Transmission className per ASTM E-90-97 (Airborne)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td></td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>STC</td>
                                        </tr>
                                        <tr>
                                            <td>Quiet Floor</td>
                                            <td>35</td>
                                            <td>40</td>
                                            <td>54</td>
                                            <td>63</td>
                                            <td>70</td>
                                            <td>71</td>
                                            <td style={{color: "#cc0000"}} className="emphasis">52</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="8">Quiet Floor Impact Insulation className per ASTM E-492-92 (Impact)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td></td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>IIC</td>
                                        </tr>
                                        <tr>
                                            <td>Quiet Floor</td>
                                            <td>58</td>
                                            <td>56</td>
                                            <td>44</td>
                                            <td>30</td>
                                            <td>20</td>
                                            <td>18</td>
                                            <td style={{color: "#cc0000"}} className="emphasis">58</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/qfloor06.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Quietwalk2} alt="Quiet Floor Walk2" />
                                </Link>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/qfloor07.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Quietwalk3} alt="Quiet Floor Walk3" />
                                </Link>
                            </div>
                        </div>
                        <hr className="d-none d-md-block d-lg-block d-xl-block" />
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default quietfloorwalk; 