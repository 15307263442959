import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundblankets1 from '../../images/inner/bbc_ext_n.webp';
import Soundblankets2 from '../../images/inner/sound_blanket_0027.webp';
import Soundblankets3 from '../../images/inner/sound_blanket_0062.webp';
import Soundblankets4 from '../../images/inner/BBC-EXT-R-1-Front.webp';
import Soundblankets5 from '../../images/inner/BBC-EXT-R-1-Back.webp';
import './sound-blankets.css';
import ScrollToTopButton from '../ScrollButton';
function soundblankets() {
    return (
      
    <>
    <Helmet>
    <title>Sound Blankets Exterior Quilted Barrier Absorbers </title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Exterior Sound Absorber Blankets" />
    <meta property="og:description" content="BBC-EXT-R-2 offers the benefits of both a noise barrier and a sound absorber composite in one product. " />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/bbc_ext_n.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/exterior-quilted-barrier-absorbers" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/sound-blankets" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/sound-blankets" />
    </Helmet>
    <Header />
    <section className="content" id="soundblankets">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>NOISE S.T.O.P.™ Sound Blankets – Reinforced</h1> 
                        <div className="row">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/bbc_ext_n.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Soundblankets1} alt="Sound Blankets1" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>BBC-EXT-R-2 Noise Barrier/Sound Absorber Sound Blankets</h2>
                                <p></p>
                                <p>BBC-EXT-R-2 offers the benefits of both a noise barrier and a sound absorber composite in one product. This BBC product consists of an exterior grade, UV resistant heavy-duty faced quilted fiberglass absorber bonded to a one-pound per sq. ft. reinforced loaded vinyl barrier. The heavy-duty facing is a 10 oz per sq yd vinyl-coated-polyester (VCP) quilted to the sound absorber rather than the standard 4.5 oz facing. Curtain panels are constructed with grommets across the top and bottom and exterior grade Velcro seals along the vertical edges.</p>
                                <ul style={{marginleft: "1em"}}>
                                    <li><strong>STC</strong> = 33</li>
                                    <li><strong>NRC</strong> = .75</li>
                                    <li><strong>Facing Colors on Quilt:</strong>
                                        <br /> Gray, Tan, Black or White</li>
                                    <li><strong>Barrier Colors:</strong>
                                        <br /> Gray, Tan, Olive or Blue</li>
                                </ul>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/SoundBlanketRe.pdf" target="_blank">Product Specs</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                            <div className="col-sm-12">
                                <h3>Testimonials:</h3>
                                <ol style={{marginleft: "1.5em"}}>
                                    <li>
                                        <Link to="https://acousticalsurfaces.in/pdfs/Enclosure.pdf" target="_blank">Exterior Chiller Enclosure Helps Homeowner Maintain Sanity!</Link>
                                    </li>
                                    <li>
                                        <Link to="https://acousticalsurfaces.in/pdfs/Sound-Blanket-High-Pitch-Reduction-Testimonial.pdf" target="_blank">Acoustical Blanket Saves the Day by Reducing High Pitch Whiny Sound</Link>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Product Specs</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>Material</th>
                                            <td>Vinyl coated polyester facing on 2″ quilted fiberglass & 1 lb/sf reinforced mass loaded vinyl barrier</td>
                                        </tr>
                                        <tr>
                                            <th>FEATURES</th>
                                            <td>Effective and durable absorber with mass loaded vinyl barrier option. </td>
                                        </tr>
                                        <tr>
                                            <th>APPLICATIONS</th>
                                            <td>Typically used as modular curtain panels in outdoor applications where high abuse resistance or excellent durability as well as maximum longevity and noise reduction is required. Also used as sliding acoustical doors, durable acoustical jacket on fans or valves, as well as a temporary noise barrier on outdoor construction projects.</td>
                                        </tr>
                                        <tr>
                                            <th>WEIGHT</th>
                                            <td>1.45 lb/sf </td>
                                        </tr>
                                        <tr>
                                            <th>Thickness</th>
                                            <td>Nominal 2″ </td>
                                        </tr>
                                        <tr>
                                            <th>Sizes</th>
                                            <td>Standard Width: 54″; Roll Length 25′</td>
                                        </tr>
                                        <tr>
                                            <th>Colors</th>
                                            <td>Facing Colors on Quilt – Gray, Tan, Black or White<br /> 
                                                Barrier Colors – Gray, Tan, Olive, or Blue
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>TEMPERATURE RANGE </th>
                                            <td>-20°F to +180°F</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm" style={{border: "1px solid #f5f5f5"}}>
                                <Link className="example-image-link" to="../../images/inner/sound_blanket_0027.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Soundblankets2} alt="Sound Blankets2" />
                                </Link>
                                <span>Facing Color Options</span>
                            </div>
                            <div className="col-sm" style={{border: "1px solid #f5f5f5"}}>
                                <Link className="example-image-link" to="../../images/inner/sound_blanket_0062.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Soundblankets3} alt="Sound Blankets3" />
                                </Link>
                                <span>Barrier Color Options</span>
                            </div>
                            <div className="col-sm" style={{border: "1px solid #f5f5f5" , maxwidth: "350px"}}>
                                <Link className="example-image-link" to="../../images/inner/BBC-EXT-R-1-Front.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Soundblankets4} alt="Sound Blankets4" />
                                </Link>
                                <span>Front</span>
                            </div>
                            <div className="col-sm" style={{border: "1px solid #f5f5f5", maxwidth: "350px"}}>
                                <Link className="example-image-link" to="../../images/inner/BBC-EXT-R-1-Back.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Soundblankets5} alt="Sound Blankets5" />
                                </Link>
                                <span>Back</span>
                            </div>
                        </div>
                        <p></p>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="8">Sound Blanket Sound Transmission Loss ASTM E90 E 413</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td>Frequency</td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>STC</td>
                                        </tr>
                                        <tr>
                                            <td>BBC-EXT-R-2</td>
                                            <td>14</td>
                                            <td>20</td>
                                            <td>32</td>
                                            <td>41</td>
                                            <td>42</td>
                                            <td>41</td>
                                            <td className="color-red">33</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="8">Sound Blanket Sound Absorption Performance ASTM C423</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td>Frequency</td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>NRC</td>
                                        </tr>
                                        <tr>
                                            <td>BBC-EXT-R-2</td>
                                            <td>.45</td>
                                            <td>.96</td>
                                            <td>.87</td>
                                            <td>.66</td>
                                            <td>.47</td>
                                            <td>.28</td>
                                            <td className="color-red">.75</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 text">
                                <h3>Downloads:</h3>
                                <ol style={{marginleft: "1em"}}>
                                    <li>
                                        <Link to="https://acousticalsurfaces.in/pdfs/ext_steel_install.pdf" target="_blank">Steel Installation Guide</Link>
                                    </li>                                   
                                    <li>
                                        <Link to="https://acousticalsurfaces.in/pdfs/ext_fence_install.pdf" target="_blank">Fence Installation Guide</Link>
                                    </li>                                   
                                </ol> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default soundblankets; 