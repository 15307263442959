import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Echoeliminator1 from '../../images/inner/echoelim-group.webp';
import Echoeliminator2 from '../../images/inner/Echo-Eliminator-four-4-200x200.webp';
import Echoeliminator3 from '../../images/inner/Echo-Eliminator-nineteen-3-200x200.webp';
import Echoeliminator4 from '../../images/inner/Echo-Eliminator-seventeen-1-200x200.webp';
import Echoeliminator5 from '../../images/inner/Echo-eliminator-sixteen-200x200.webp';
import Echoeliminator6 from '../../images/inner/Echo-Eliminator-ten-2-200x200.webp';
import Echoeliminator7 from '../../images/inner/Echo-Eliminator-three-2-200x200.webp';
import Echoeliminator8 from '../../images/inner/Echo-Eliminator-twelve-2-200x200.webp';
import Echoeliminator9 from '../../images/inner/Echo-Eliminator-twenty-five-1-200x200.webp';
import Echoeliminator10 from '../../images/inner/black_up_lg-200x200.webp';
import Echoeliminator11 from '../../images/inner/burg_up_lg1-200x200.webp';
import Echoeliminator12 from '../../images/inner/graphite_up_lg-200x200.webp';
import Echoeliminator13 from '../../images/inner/green_up_lg1-200x200.webp';
import Echoeliminator14 from '../../images/inner/light_gray_up_lg-1-200x200.webp';
import Echoeliminator15 from '../../images/inner/marble_up_lg-200x200.webp';
import Mobsidebar from '../mobile-sidebar';
import './echo-eliminator.css';
import ScrollToTopButton from '../ScrollButton';
function echoelimi() {
    return (

        <>
            <Helmet>
                <title>Echo Eliminator can be used as an acoustic wall panel or hanging baffle</title>
                <meta name="description" content="Echo Eliminator is an eco-friendly high perfomance sound absorption product that provides excellent noise control over large open spaces" />
                <meta name="robots" content="index, follow" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="in" />
                <meta name="geo.region" content="india" />
                <meta property="og:title" content="Cost Effective Echo Eliminator Acoustical Absorbing Material" />
                <meta property="og:description" content="Echo Eliminator is a high-performance acoustical material made from recycled cotton. These cotton fabric panels are ideal for noise control applications." />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/echoelim-group.webp" />
                <meta property="og:locale" content="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.ae/echo-eliminator" hreflang="en-ae" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/echo-eliminator" hreflang="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/echo-eliminator" />
            </Helmet>
            <Header />
            <section className="content" id="echoeliminator">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 sidemenu">
                            <Sidebar />
                        </div>
                        <div className="col-sm-9 product-page nopaddding">
                            <div className="container thanks">
                                <div className="megamenu">
                                    <Megamenu />
                                </div>
                                <h1>Echo Eliminator™</h1>
                                <div className="row">
                                    <div className="col-xl-4 col-sm-12">
                                        <Link className="example-image-link" to="../../images/inner/echoelim-group.webp" data-lightbox="example-1">
                                            <img className="example-image" src={Echoeliminator1} alt="Echo Eliminator1" />
                                        </Link>
                                    </div>
                                    <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 text">
                                        <h2>Bonded Acoustical Cotton (B.A.C.)<br />The Most Cost Effective Acoustical Absorbing Material On The Market</h2>
                                        <p>Echo Eliminator is a high-performance acoustical material made from recycled cotton. These cotton fabric panels are ideal for noise control applications. Echo Eliminator can be used as an acoustic wall panel or hanging baffle. Common applications of these cotton fabric panels are school gyms, classNamerooms, lunch rooms and any application where a high-performance noise control product is needed. Echo Eliminator cotton fabric panels are eligible for LEED™ credits, className A fire rated and 100% recyclable.</p>
                                        <ul style={{ liststyle: "none" }}>
                                            <li>Recycled Cotton (Green Acoustical Material)</li>
                                            <li>className A&nbsp;– Non-Flammable (Per ASTM E-84)</li>
                                            <li>Lightweight</li>
                                            <li>Easy to Install</li>
                                            <li>Low Cost</li>
                                            <li>High Light Reflectance</li>
                                            <li>High Performance Acoustical Absorption</li>
                                            <li>Relocatable (with Grommets)</li>
                                        </ul>
                                        <p><a style={{ color: "#0059a5" }} href="https://www.acousticalsurfaces.com/echo_eliminator/misc_data/Echo_Better.pdf" target="_blank" rel="noopener noreferrer">Echo Eliminator™ VS. Other Acoustical Materials</a>
                                            <br />
                                            <a style={{ color: "#0059a5" }} href="https://www.acousticalsurfaces.com/echo_eliminator/echo-eliminator-recycled-content.html" target="_blank" rel="noopener noreferrer">Echo Eliminator LEED® Credit Contribution Information</a></p>
                                    </div>
                                    <div className="line"></div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-sm-12 ytvid">
                                        <h4>Echo Eliminator Installation Tutorial</h4>
                                        <iframe allowfullscreen="allowfullscreen" src="https://www.youtube.com/embed/pn2lDxvEkIU" width="600" height="380" title='Echo-Eliminator'></iframe>
                                    </div>
                                </div>
                                <h4>Product Photos</h4>
                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-sm-6">
                                        <Link className="example-image-link" to="../../images/inner/Echo-Eliminator-four-4-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Echoeliminator2} alt="Echo Eliminator2" />
                                        </Link>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-sm-6">
                                        <Link className="example-image-link" to="../../images/inner/Echo-Eliminator-nineteen-3-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Echoeliminator3} alt="Echo Eliminator3" />
                                        </Link>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-sm-6">
                                        <Link className="example-image-link" to="../../images/inner/Echo-Eliminator-seventeen-1-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Echoeliminator4} alt="Echo Eliminator4" />
                                        </Link>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-sm-6">
                                        <Link className="example-image-link" to="../../images/inner/Echo-eliminator-sixteen-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Echoeliminator5} alt="Echo Eliminator5" />
                                        </Link>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-sm-6">
                                        <Link className="example-image-link" to="../../images/inner/Echo-Eliminator-ten-2-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Echoeliminator6} alt="Echo Eliminator6" />
                                        </Link>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-sm-6">
                                        <Link className="example-image-link" to="../../images/inner/Echo-Eliminator-three-2-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Echoeliminator7} alt="Echo Eliminator7" />
                                        </Link>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-sm-6">
                                        <Link className="example-image-link" to="../../images/inner/Echo-Eliminator-twelve-2-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Echoeliminator8} alt="Echo Eliminator8" />
                                        </Link>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-sm-6">
                                        <Link className="example-image-link" to="../../images/inner/Echo-Eliminator-twenty-five-1-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Echoeliminator9} alt="Echo Eliminator9" />
                                        </Link>
                                    </div>
                                </div>
                                <h4>Color Options</h4>
                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-sm-6">
                                        <Link className="example-image-link" to="../../images/inner/black_up_lg-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Echoeliminator10} alt="Echo Eliminator10" />
                                        </Link>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-sm-6">
                                        <Link className="example-image-link" to="../../images/inner/burg_up_lg1-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Echoeliminator11} alt="Echo Eliminator11" />
                                        </Link>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-sm-6">
                                        <Link className="example-image-link" to="../../images/inner/graphite_up_lg-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Echoeliminator12} alt="Echo Eliminator12" />
                                        </Link>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-sm-6">
                                        <Link className="example-image-link" to="../../images/inner/green_up_lg1-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Echoeliminator13} alt="Echo Eliminator13" />
                                        </Link>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-sm-6">
                                        <Link className="example-image-link" to="../../images/inner/light_gray_up_lg-1-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Echoeliminator14} alt="Echo Eliminator14" />
                                        </Link>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-sm-6">
                                        <Link className="example-image-link" to="../../images/inner/marble_up_lg-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Echoeliminator15} alt="Echo Eliminator15" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 in-content">
                                        <h4>Product Specs</h4>
                                        <table className="table table-bordered" style={{ background: "#f5f5f5" }}>
                                            <tbody>
                                                <tr>
                                                    <th>Applications</th>
                                                    <td>Bonded Acoustical Cotton panels are ideally suited to school gymnasiums, cafeterias, classNamerooms, churches, multi-purpose rooms, community centers and more.</td>
                                                </tr>
                                                <tr>
                                                    <th>Sizes</th>
                                                    <td>Standard Size – 2′ × 4′<br /> Also available in 12″ × 12″, 2′ × 2′, 4′ × 4′ and 4′ × 8′ (Minimum quantities may apply, please call for details)</td>
                                                </tr>
                                                <tr>
                                                    <th>Densities</th>
                                                    <td>3lb per cubic ft – 1″ &amp; 2″ thickness panels<br /> 6lb per cubic ft – 1″ thickness panels</td>
                                                </tr>
                                                <tr>
                                                    <th>Colors</th>
                                                    <td>Standard Colors<br />White, Marble Light Blue, Light Grey, Beige, Graphite,  and Black.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p><strong>Please note:</strong><span>&nbsp;Although
                                            we take extensive precautions and purge the lines between colors, due to the manufacturing process color inconsistencies&nbsp;– including dye lot variations&nbsp;– flecks, specks, and other slight surface blemishes are unavoidable. Please call for details.</span>
                                            <br /><br /><em>All padded wall panel sizes are nominal and are subject to manufacturing tolerances that may vary ±1/8″. Exact fitting may require on site adjustment by the purchaser. Exact size and other unavoidable variables such as dye lot color variances, lighting conditions, pattern matching, directional materials, expansion and contraction due to environmental conditions. Project with exacted visual requirements and expectation must be identified for our special attention prior to ordering.</em></p>
                                        <p><Link className="btn btn-asi" to="reverb-demo">Reverberation Demonstration</Link></p>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-sm-12 table table-bordered-2">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th colSpan="9">Echo Eliminator™ WALL PANEL&nbsp;– Sound Absorption Coefficients</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="colheader">
                                                    <td></td>
                                                    <td>Mount</td>
                                                    <td>125Hz</td>
                                                    <td>250Hz</td>
                                                    <td>500Hz</td>
                                                    <td>1KHz</td>
                                                    <td>2KHz</td>
                                                    <td>4KHz</td>
                                                    <td >NRC</td>
                                                </tr>
                                                <tr>
                                                    <td className="colheader">1″&nbsp;– 3lb./cf.</td>
                                                    <td>A</td>
                                                    <td>.08</td>
                                                    <td>.31</td>
                                                    <td>.79</td>
                                                    <td>1.01</td>
                                                    <td>1.00</td>
                                                    <td>.99</td>
                                                    <td className="emphasis">.80</td>
                                                </tr>
                                                <tr>
                                                    <td className="colheader">2″&nbsp;– 3lb./cf.</td>
                                                    <td>A</td>
                                                    <td>.35</td>
                                                    <td>.94</td>
                                                    <td>1.32</td>
                                                    <td>1.22</td>
                                                    <td>1.06</td>
                                                    <td>1.03</td>
                                                    <td className="emphasis">1.15</td>
                                                </tr>
                                                <tr>
                                                    <td className="colheader">1″&nbsp;– 6lb./cf.</td>
                                                    <td>A</td>
                                                    <td>.07</td>
                                                    <td>.30</td>
                                                    <td>.86</td>
                                                    <td>1.10</td>
                                                    <td>1.05</td>
                                                    <td>1.03</td>
                                                    <td className="emphasis">.85</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br />
                                        <p className="in-content">
                                            <a href="https://www.acousticalsurfaces.com/echo_eliminator/shop_vac.htm?d=0" target="_blank" rel="noopener noreferrer">Click Here to View Shop Vac Enclosure</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mobsidebar'>
                        <div className="col-sm-12">
                            <div className="container">
                                <div className="row">
                                    <Mobsidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default echoelimi; 