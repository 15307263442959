import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Ww8 from '../../images/wudwool/ww8.webp';
import Woodwool1 from '../../images/wudwool/cp6.webp';
import Woodwool2 from '../../images/wudwool/ww9.webp';
import Woodwool3 from '../../images/wudwool/ww10.webp';
import Woodwool4 from '../../images/wudwool/ww11.webp';
import Woodwool5 from '../../images/wudwool/ww12.webp';
import Woodwool6 from '../../images/wudwool/cp0.webp';
import Woodwool7 from '../../images/wudwool/bs1.webp';
import Woodwool8 from '../../images/wudwool/bs2.webp';
import Woodwool9 from '../../images/wudwool/bs3.webp';
import Woodwool10 from '../../images/wudwool/bs4.webp';
import Woodwool11 from '../../images/wudwool/dt1.webp';
import Woodwool12 from '../../images/wudwool/dt2.webp';
import Woodwool13 from '../../images/wudwool/dt3.webp';
import Woodwool14 from '../../images/wudwool/dt4.webp';
import Woodwool15 from '../../images/wudwool/dt5.webp';
import Woodwool16 from '../../images/wudwool/dt6.webp';
import Woodwool17 from '../../images/wudwool/dt7.webp';
import Woodwool18 from '../../images/wudwool/dt8.webp';
import Woodwool19 from '../../images/wudwool/dt9.webp';
import Woodwool20 from '../../images/wudwool/dt10.webp';
import Woodwool21 from '../../images/wudwool/dt11.webp';
import Woodwool22 from '../../images/wudwool/E1.webp';
import Woodwool23 from '../../images/wudwool/es2.webp';
import Woodwool24 from '../../images/wudwool/es3.webp';
import Woodwool25 from '../../images/wudwool/es4.webp';
import Woodwool26 from '../../images/wudwool/es5.webp';
import Woodwool27 from '../../images/wudwool/dt12.webp';
import Woodwool28 from '../../images/wudwool/dt13.webp';
import Woodwool29 from '../../images/wudwool/dt14.webp';
import Woodwool30 from '../../images/wudwool/dt15.webp';
import Woodwool31 from '../../images/wudwool/dt16.webp';
import Woodwool32 from '../../images/wudwool/dt17.webp';
import Card1 from '../../images/wudwool/card1.webp';
import Card2 from '../../images/wudwool/card2.webp';
import Card3 from '../../images/wudwool/card3.webp';
import Mobsidebar from '../mobile-sidebar';
import './woodwool-wall.css';
import ScrollToTopButton from '../ScrollButton';
function woodwoolwall() {
  return (
      
  <>
  <Helmet>
  <title>Wood Wool Wall Panels perfect for Offices, Schools, Meeting Rooms & Hotels</title>
  <meta name="description" content="" />
  <meta name="robots" content="index, follow" />
  <meta name="geo.placename" content="India" />
  <meta http-equiv="content-language" content="in" />
  <meta name="geo.region" content="india" />
  <meta property="og:title" content="Wood Wool Wall Panels" />
  <meta property="og:description" content="Wood wool boards can transform the aesthetics and acoustics of any interior space."/>
  <meta property="og:image" content="https://www.acousticalsurfaces.in/images/wudwool/ww8.webp" />
  <meta property="og:locale" content="en-in" />
  <link rel="alternate" href="https://www.acousticalsurfaces.in/woodwool-wall" hreflang="en-in" />
  <link rel="alternate" href="https://www.acousticalsurfaces.ae/woodwool-wall" hreflang="en-ae" />
  <link rel="canonical" href="https://www.acousticalsurfaces.in/woodwool-wall" />
  </Helmet>
  <Header />
  <section className="content" id="woodwoolwall">
    <div className="container">
      <div className="row">
        <div className="col-sm-3 sidemenu">
          <Sidebar />
        </div>
        <div className="col-sm-9 product-page nopaddding">
          <div className="container thanks">
            <div className="megamenu">
              <Megamenu />
            </div>
	          <div className="col-sm-12 heading-fsorb">				
	            <div className="row"> 
                <div className="col-sm-12">
                  <img src={Ww8} style={{width: "100%"}} alt="woodwool-wall" />
                </div>
              </div>
            </div>
            <h1>Envirocoustic<sup>TM</sup> Wood Wool <span>Wall</span> Panels</h1>
		        <p>Envirocoustic™ Wood Wool is now a leader in the eco-friendly, high-performance, cost-effective acoustical-panel products category. <br /><br />
			        These eco-friendly cementitious wood-fiber panels not only liven up a room with design variety, but acoustic
 			        wood wool panels also reduce echo and reverberation through sound absorption. Wood wool boards can transform 
              the aesthetics and acoustics of any interior space.</p>
		        <br />
	          <div className="flex-container">
	            <Link to="/ww-downloads" className="btn btn-danger justify-content-center">Downloads</Link>
	          </div>	
	          <div className="col-sm-12 features p-1">
	            <h1>Applications</h1>
	            <div className="line"></div>
	            <p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
              <div className="row pad ">	
		            <div className="col-md-6 col-sm-12 d-none d-md-block">
	                <ul>
		                <li>Offices</li>
		                <li>Schools</li>
		                <li>Meeting Rooms</li>
		                <li>Hotels</li>
                  </ul>
		            </div>
		            <div className="col-md-6 col-sm-12 d-none d-md-block">
	                <ul>
		                <li>Auditorium</li>
		                <li>Recording Studios</li>
		                <li>Conference halls</li>
		                <li>Broadcasting Studios</li>
                  </ul>
		            </div>
	            </div>
            </div>	
          </div>	
		      <div className="col-sm-12 more">
            <h1> More Products </h1>
		        <div className="line"></div>
		      </div>
	        <div className="accordion-started accordion-bral panels row">
			      <div className="col-sm-12 col-md-12 col-lg-12 ">
			        <label className="ac-label" htmlFor="ac-1">
                <b>WOODWOOL </b><br />SQUARE PANELS<i></i>
              </label>
		          <div className="article ac-content">
					      <div className="row"> 
                  <div className="col-sm-12">
                    <img src={Woodwool1} alt="Woodwool Wall1" style={{width: "100%"}} />
                  </div>
                </div>
                <p>Envirocoustic™ Wood Wool is now a leader in the eco-friendly, high-performance, cost-effective acoustical-panel products category. <br /><br />
			            These eco-friendly cementitious wood-fiber panels not only liven up a room with design variety, but acoustic
 			            wood wool panels also reduce echo and reverberation through sound absorption. Wood wool boards can transform 
                  the aesthetics and acoustics of any interior space.</p>
	              <div className="col-sm-12 features p-1">
		              <h1>Applications</h1>
	                <div className="line"></div>
	                <p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                  <div className="row pad ">	
		                <div className="col-md-6 col-sm-12 d-none d-md-block">
                      <ul>
		                    <li>Offices</li>
		                    <li>Schools</li>
		                    <li>Meeting Rooms</li>
		                    <li>Hotels</li>
                      </ul>
		                </div>
		                <div className="col-md-6 col-sm-12 d-none d-md-block">
  	                  <ul>
	  	                  <li>Auditorium</li>
		                    <li>Recording Studios</li>
		                    <li>Conference halls</li>
		                    <li>Broadcasting Studios</li>
                      </ul>
		                </div>
	                </div>
                </div>	
                <div className="col-12 imgcard">
	                <div className="row">
                    <div className="col-6 col-sm-3 p-1">
                      <img src={Woodwool2} alt="Woodwool Wall2" style={{width: "100%"}} />
                    </div>
                    <div className="col-6 col-sm-3 p-1">
                      <img src={Woodwool3} alt="Woodwool Wall3" style={{width: "100%"}} />
                    </div>
                    <div className="col-6 col-sm-3 d-none d-sm-block p-1">
                      <img src={Woodwool4} alt="Woodwool Wall4" style={{width: "100%"}} />
                    </div>
                    <div className="col-6 col-sm-3 d-none d-sm-block p-1">
                      <img src={Woodwool5} alt="Woodwool Wall5" style={{width: "100%"}} />
                    </div>
                  </div>
                </div>
                <br />	
                <h2>Product Details</h2>
                <div className="line"></div>
                <br />
	              <div className="col-sm-12">
	                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active" data-toggle="tab" href="#home">SPECIFICATION</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#menu1">REVERBERATION</a>
                    </li>
	                  <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#menu2">FINISH OPTIONS</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#menu3">DATASHEET</a>
                    </li>
	                </ul>
                  <div className="tab-content">
	                  <div id="home" className="container tab-pane active"><br />
                      <table className="table">
                        <tbody>
	                        <tr>
                            <th scope="row">Core </th>
                            <td>Wood fiber, Portland cement, and water.</td>
                          </tr>
                          <tr>
                            <th scope="row">Thickness (mm)</th>
                            <td>25, 35, 50</td>
                          </tr>
                          <tr>
                            <th scope="row">Sizes (mm)</th>
                            <td>610 x 610 - 610 x 1220, custom sizes available</td>
                          </tr>
	                        <tr>
                            <th scope="row">Acoustic Properties</th>
                            <td>NRC 0.45 – 0.95 ( varies with thickness and mounting )</td>
                          </tr>
                          <tr>
                            <th scope="row">Edges</th>
                            <td>Square and Beveled</td>
                          </tr>
                          <tr>
                            <th scope="row">Edge Finishes</th>
                            <td>Primed Clear, Primed White, Painted</td>
                          </tr>
	                        <tr>
                            <th scope="row">Colours</th>
                            <td>Wide range of standard colours. Also available in custom colours</td>
                          </tr>
                          <tr>
                            <th scope="row">Mounting</th>
                            <td>A, C-25, C-50 and D-25</td>
                          </tr>
  	                      <tr>
                            <th scope="row">Installation</th>
                            <td>Direct attach</td>
                          </tr>
                        </tbody>
                      </table>
		                </div>
                    <div id="menu1" className="container tab-pane fade"><br />
                      <h3>Sound Absorption Coefficients</h3>
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Thickness</th>
                            <th scope="col">125Hz</th>
                            <th scope="col">250Hz</th>
                            <th scope="col">500Hz</th>
                            <th scope="col">1KHz</th>
                            <th scope="col">2KHz</th>
                            <th scope="col">4KHz</th>
                            <th scope="col">NRC</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">25mm</th>
                            <td>0.08</td>
                            <td>0.12</td>
                            <td>0.26</td>
                            <td>0.52</td>
                            <td>0.89</td>
                            <td>0.60</td>
                            <td>0.45</td>
                          </tr>
                          <tr>
                            <th scope="row">35mm</th>
                            <td>0.04</td>
                            <td>0.23</td>
                            <td>0.41</td>
                            <td>0.80</td>
                            <td>0.79</td>
                            <td>0.81</td>
                            <td>0.55</td>
                          </tr>
                          <tr>
                            <th scope="row">50mm</th>
                            <td>0.11</td>
                            <td>0.29</td>
                            <td>0.74</td>
                            <td>1.08</td>
                            <td>0.85</td>
                            <td>1.02</td>
                            <td>0.75</td>
                          </tr>
	                      </tbody>
                      </table>
		                </div>
  	                <div id="menu2" className="container tab-pane fade"><br /><br />
                      <h3>Available Finish Options</h3>
		                  <br />
                      <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/woodwool/color-shades.pdf" className="brochure">Download</a>
		                </div>
                    <div id="menu3" className="container tab-pane fade"><br /><br />
                      <h3>ASI Wall Panels : Datasheet</h3>
		                  <br />
                      <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/woodwool/wall-ceiling.pdf" className="brochure">Download</a>
		                </div>
                  </div>
                </div>			
		  	      </div>
			      </div>
			      <div className="col-sm-12 col-md-12 col-lg-12">
			        <label className="ac-label" htmlFor="ac-2">
                <b>WOODWOOL</b><br />BEVELLED PANELS<i></i>
              </label>
		  	      <div className="article ac-content">
			          <div className="row"> 
                  <div className="col-sm-12">
                    <img src={Woodwool6} alt="Woodwool Wall6" style={{width: "100%"}} />
                  </div>
                </div>
	  		        <p>Envirocoustic™ Wood Wool is now a leader in the eco-friendly, high-performance, cost-effective acoustical-panel products category. <br /><br />
		  	          These eco-friendly cementitious wood-fiber panels not only liven up a room with design variety, but acoustic
 			            wood wool panels also reduce echo and reverberation through sound absorption. Wood wool boards can transform 
                  the aesthetics and acoustics of any interior space.</p>
	              <div className="col-sm-12 features p-1">
                  <h1>Applications</h1>
	                <div className="line"></div>
  	              <p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                  <div className="row pad ">	
		                <div className="col-md-6 col-sm-12 d-none d-md-block">
	                    <ul>
		                    <li>Offices</li>
		                    <li>Schools</li>
		                    <li>Meeting Rooms</li>
		                    <li>Hotels</li>
                      </ul>
		                </div>
  		              <div className="col-md-6 col-sm-12 d-none d-md-block">
                      <ul>
		                    <li>Auditorium</li>
		                    <li>Recording Studios</li>
		                    <li>Conference halls</li>
		                    <li>Broadcasting Studios</li>
                      </ul>
		                </div>
	                </div>
                </div>	
                <div className="col-12 imgcard">
	                <div className="row">
                    <div className="col-6 col-sm-3 p-1">
                      <img src={Woodwool7} alt="Woodwool Wall7" style={{width: "100%"}} />
                    </div>
                    <div className="col-6 col-sm-3 p-1">
                      <img src={Woodwool8} alt="Woodwool Wall8" style={{width: "100%"}} />
                    </div>
                    <div className="col-6 col-sm-3 d-none d-sm-block p-1">
                      <img src={Woodwool9} alt="Woodwool Wall9" style={{width: "100%"}} />
                    </div>
                    <div className="col-6 col-sm-3 d-none d-sm-block p-1">
                      <img src={Woodwool10} alt="Woodwool Wall10" style={{width: "100%"}} />
                    </div>
                  </div>
                </div>
                <br />	
                <h2>Product Details</h2>
                <div className="line"></div>
                <br />
	              <div className="col-sm-12">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active" data-toggle="tab" href="#home">SPECIFICATION</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#cp-menu1">REVERBERATION</a>
                    </li>
	                  <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#cp-menu2">FINISH OPTIONS</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#cp-menu3">DATASHEET</a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div id="home" className="container tab-pane active"><br />
                      <table className="table">
                        <tbody>
	                        <tr>
                            <th scope="row">Core </th>
                            <td>Wood fiber, Portland cement, and water.</td>
                          </tr>
                          <tr>
                            <th scope="row">Thickness (mm)</th>
                            <td>25, 35, 50, Custom Thickness available</td>
                          </tr>
                          <tr>
                            <th scope="row">Sizes (mm)</th>
                            <td>610 x 610 - 610 x 1220, custom sizes available</td>
                          </tr>
	                        <tr>
                            <th scope="row">Acoustic Properties</th>
                            <td>NRC 0.45 – 0.95 ( varies with thickness and mounting )</td>
                          </tr>
                          <tr>
                            <th scope="row">Edges</th>
                            <td>Square and Beveled</td>
                          </tr>
                          <tr>
                            <th scope="row">Edge Finishes</th>
                            <td>Primed Clear, Primed White, Painted</td>
                          </tr>
	                        <tr>
                            <th scope="row">Colours</th>
                            <td>Wide range of standard colours. Also available in custom colours</td>
                          </tr>
                          <tr>
                            <th scope="row">Mounting</th>
                            <td>A, C-25, C-50 and D-25</td>
                          </tr>
	                        <tr>
                            <th scope="row">Installation</th>
                            <td>Direct attach</td>
                          </tr>
                        </tbody>
                      </table>
  		              </div>
                    <div id="cp-menu1" className="container tab-pane fade"><br />
                      <h3>Sound Absorption Coefficients</h3>
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Thickness</th>
                            <th scope="col">125Hz</th>
                            <th scope="col">250Hz</th>
                            <th scope="col">500Hz</th>
                            <th scope="col">1KHz</th>
                            <th scope="col">2KHz</th>
                            <th scope="col">4KHz</th>
                            <th scope="col">NRC</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">25mm</th>
                            <td>0.08</td>
                            <td>0.12</td>
                            <td>0.26</td>
                            <td>0.52</td>
                            <td>0.89</td>
                            <td>0.60</td>
                            <td>0.45</td>
                          </tr>
                          <tr>  
                            <th scope="row">35mm</th>
                            <td>0.04</td>
                            <td>0.23</td>
                            <td>0.41</td>
                            <td>0.80</td>
                            <td>0.79</td>
                            <td>0.81</td>
                            <td>0.55</td>
                          </tr>
                          <tr>
                            <th scope="row">50mm</th>
                            <td>0.11</td>
                            <td>0.29</td>
                            <td>0.74</td>
                            <td>1.08</td>
                            <td>0.85</td>
                            <td>1.02</td>
                            <td>0.75</td>
                          </tr>
                        </tbody>
                      </table>
		                </div>
                    <div id="cp-menu2" className="container tab-pane fade"><br />
                      <br />
                      <h3>Available Finish Options</h3>
		                  <br />
	                    <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/woodwool/color-shades.pdf" className="brochure">Download</a>
                    </div>
	                  <div id="cp-menu3" className="container tab-pane fade"><br />
                      <br />
                      <h3>Acoustical Ceiling Panels : Datasheet</h3>
		                  <br />
		                  <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/woodwool/wall-ceiling.pdf" className="brochure">Download</a>
                    </div>
                  </div>
                </div>			
	  		      </div>
		  	    </div>
			      <div className="col-sm-12 col-md-12 col-lg-12">
			        <label className="ac-label" htmlFor="ac-3">
                <b>WOODWOOL</b><br /> SHAPES PANELS<i></i>
              </label>
		  				<div className="article ac-content">
			  				<div className="row"> 
                  <div className="col-sm-12">
                    <img src={Woodwool11} alt="Woodwool Wall11" style={{width: "100%"}} />
                  </div>
                </div>
	  		        <p>Envirocoustic™ Wood Wool is now a leader in the eco-friendly, high-performance, cost-effective acoustical-panel products category. <br /><br />
		  	          These eco-friendly cementitious wood-fiber panels not only liven up a room with design variety, but acoustic
 			            wood wool panels also reduce echo and reverberation through sound absorption. Wood wool boards can transform 
                  the aesthetics and acoustics of any interior space.</p>
	              <div className="col-sm-12 features p-1">
  	              <h1>Applications</h1>
	                <div className="line"></div>
	                <p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                	<div className="row pad ">	
		                <div className="col-md-6 col-sm-12 d-none d-md-block">
	                    <ul>
		                    <li>Offices</li>
		                    <li>Schools</li>
		                    <li>Meeting Rooms</li>
		                    <li>Hotels</li>
                      </ul>
	  	              </div>
		                <div className="col-md-6 col-sm-12 d-none d-md-block">
	                    <ul>
		                    <li>Auditorium</li>
		                    <li>Recording Studios</li>
		                    <li>Conference halls</li>
		                    <li>Broadcasting Studios</li>
                      </ul>
  		              </div>
	                </div>
                </div>	
                <div className="col-12 imgcard">
	                <div className="row">
                    <div className="col-6 col-sm-3 p-1">
                      <img src={Woodwool12} alt="Woodwool Wall11" style={{width: "100%"}} />
                    </div>
                    <div className="col-6 col-sm-3 p-1">
                      <img src={Woodwool13} alt="Woodwool Wall11" style={{width: "100%"}} />
                    </div>
                    <div className="col-6 col-sm-3 d-none d-sm-block p-1">
                      <img src={Woodwool14} alt="Woodwool Wall11" style={{width: "100%"}} />
                    </div>
                    <div className="col-6 col-sm-3 d-none d-sm-block p-1">
                      <img src={Woodwool15} alt="Woodwool Wall11" style={{width: "100%"}} />
                    </div>
                  </div>
                </div>
                <br />	
                <h2>Product Details</h2>
                <div className="line"></div>
                <br />
                <div className="col-sm-12">
	                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active" data-toggle="tab" href="#cb-home">SPECIFICATION</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#cb-menu1">REVERBERATION</a>
                    </li>
	                  <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#cb-menu2">FINISH OPTIONS</a>
                    </li>
	                  <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#cb-menu3">DATASHEET</a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div id="cb-home" className="container tab-pane active"><br />
                      <table className="table">
                        <tbody>
	                        <tr>
                            <th scope="row">Core </th>
                            <td>Wood fiber, Portland cement, and water.</td>
                          </tr>
                          <tr>
                            <th scope="row">Thickness (mm)</th>
                            <td>15, 25, 35, 50, custom thickness available</td>
                          </tr>
                          <tr>
                            <th scope="row">Sizes (mm)</th>
                            <td>610 x 610 - 610 x 1220, and other custom sizes</td>
                          </tr>
	                        <tr>
                            <th scope="row">Acoustic Properties</th>
                            <td>NRC 0.40 – 0.95 ( varies with thickness and mounting )</td>
                          </tr>
                          <tr>
                            <th scope="row">Edges</th>
                            <td>Square and Tegular</td>
                          </tr>
                          <tr>
                            <th scope="row">Edge Finishes</th>
                            <td>Primed Clear, Primed White, Painted</td>
                          </tr>
	                        <tr>
                            <th scope="row">Colours</th>
                            <td>Wide range of standard colours. Also available in custom colours</td>
                          </tr>
	                        <tr>
                            <th scope="row">Mounting</th>
                            <td>E-400</td>
                          </tr>
	                        <tr>
                            <th scope="row">Installation</th>
                            <td>Lay In</td>
                          </tr>
	                      </tbody>
                      </table>
  		              </div>
                    <div id="cb-menu1" className="container tab-pane fade"><br />
                      <h3>Sound Absorption Coefficients</h3>
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Thickness</th>
                            <th scope="col">125Hz</th>
                            <th scope="col">250Hz</th>
                            <th scope="col">500Hz</th>
                            <th scope="col">1KHz</th>
                            <th scope="col">2KHz</th>
                            <th scope="col">4KHz</th>
                            <th scope="col">NRC</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">15mm</th>
                            <td>0.72</td>
                            <td>0.51</td>
                            <td>0.37</td>
                            <td>0.50</td>
                            <td>0.62</td>
                            <td>0.74</td>
                            <td>0.50</td>
                          </tr>
	                        <tr>
                            <th scope="row">25mm</th>
                            <td>0.12</td>
                            <td>0.34</td>
                            <td>0.31</td>
                            <td>0.48</td>
                            <td>0.51</td>
                            <td>0.65</td>
                            <td>0.40</td>
                          </tr>
	                        <tr>
                            <th scope="row">35mm</th>
                            <td>0.27</td>
                            <td>0.53</td>
                            <td>0.41</td>
                            <td>0.64</td>
                            <td>0.73</td>
                            <td>0.85</td>
                            <td>0.60</td>
                          </tr>
	                        <tr>
                            <th scope="row">50mm</th>
                            <td>0.35</td>
                            <td>0.64</td>
                            <td>0.60</td>
                            <td>0.86</td>
                            <td>0.94</td>
                            <td>0.88</td>
                            <td>0.75</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
	                  <div id="cb-menu2" className="container tab-pane fade"><br />
                      <br />
                      <h3>Available Finish Options</h3>
		                  <br />
	                    <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/woodwool/color-shades.pdf" className="brochure">Download</a>
                    </div>
  	                <div id="cb-menu3" className="container tab-pane fade"><br />
                      <br />
                      <h3>ASI : Datasheet</h3>
		                  <br />
		                  <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/woodwool/tiles-baffles.pdf" className="brochure">Download</a>
                    </div>
                  </div>
                </div>			
  		          <div className="col-12 text-center shape">
                  <h2>Envirocoustic™ Wood Wool <span style={{color: "#f13849"}}>Available Shapes</span></h2>
                  <br /><br />
                  <div className="row">
          	        <div className="col-4 col-sm-2">
                      <img src={Woodwool16} alt="Woodwool Wall16" style={{width: "100%"}} />
                      <caption>Square Tiles</caption>
                    </div>
                    <div className="col-4 col-sm-2">
                      <img src={Woodwool17} alt="Woodwool Wall17" style={{width: "100%"}} />
                      <caption>Circle Tiles</caption>
                    </div>
                    <div className="col-4 col-sm-2">
                      <img src={Woodwool18} alt="Woodwool Wall18" style={{width: "100%"}} />
                      <caption>Hexagon Tiles</caption>
                    </div>
                    <div className="col-4 col-sm-2">
                      <img src={Woodwool19} alt="Woodwool Wall19" style={{width: "100%"}} />
                      <caption>Triangle Tiles</caption>
                    </div>
                    <div className="col-4 col-sm-2">
                      <img src={Woodwool20} alt="Woodwool Wall20" style={{width: "100%"}} />
                      <caption>Plank Tiles</caption>
                    </div>
                    <div className="col-4 col-sm-2">
                      <img src={Woodwool21} alt="Woodwool Wall21" style={{width: "100%"}} />
                      <caption>Rectangle Tiles</caption>
                    </div>
                  </div>
                </div>			
	  		        <br /><br /><br />
              </div>
			      </div>
	          <div className="col-sm-12 col-md-12 col-lg-12">
			        <label className="ac-label" htmlFor="ac-4">
                <b>WOODWOOL</b><br />ENGRAVED<i></i>
              </label>
			  			<div className="article ac-content">
				        <div className="row"> 
                  <div className="col-sm-12">
                    <img src={Woodwool22} alt="Woodwool Wall22" style={{width: "100%"}} />
                  </div>
                </div>
	  		        <p>Envirocoustic™ Wood Wool is now a leader in the eco-friendly, high-performance, cost-effective acoustical-panel products category. <br /><br />
		  	          These eco-friendly cementitious wood-fiber panels not only liven up a room with design variety, but acoustic
 			            wood wool panels also reduce echo and reverberation through sound absorption. Wood wool boards can transform 
                  the aesthetics and acoustics of any interior space.</p>
	              <div className="col-sm-12 features p-1">
	                <h1>Applications</h1>
	                <div className="line"></div>
                  <p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                  <div className="row pad ">	
	  	              <div className="col-md-6 col-sm-12 d-none d-md-block">
                      <ul>
		                    <li>Offices</li>
		                    <li>Schools</li>
		                    <li>Meeting Rooms</li>
		                    <li>Hotels</li>
                      </ul>
		                </div>
  		              <div className="col-md-6 col-sm-12 d-none d-md-block">
	                    <ul>
		                    <li>Auditorium</li>
		                    <li>Recording Studios</li>
		                    <li>Conference halls</li>
		                    <li>Broadcasting Studios</li>
                      </ul>
		                </div>
  	              </div>
                </div>	
                <div className="col-12 imgcard">
	                <div className="row">
                    <div className="col-6 col-sm-3 p-1">
                      <img src={Woodwool23} alt="Woodwool Wall23" style={{width: "100%"}} />
                    </div>
                    <div className="col-6 col-sm-3 p-1">
                      <img src={Woodwool24} alt="Woodwool Wall24" style={{width: "100%"}} />
                    </div>
                    <div className="col-6 col-sm-3 d-none d-sm-block p-1">
                      <img src={Woodwool25} alt="Woodwool Wall25" style={{width: "100%"}} />
                    </div>
                    <div className="col-6 col-sm-3 d-none d-sm-block p-1">
                      <img src={Woodwool26} alt="Woodwool Wall26" style={{width: "100%"}} />
                    </div>
                  </div>
                </div>
                <br />	
                <h2>Product Details</h2>
                <div className="line"></div>
                <br />
	              <div className="col-sm-12">
	                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active" data-toggle="tab" href="#dp-home">SPECIFICATION</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#dp-menu1">REVERBERATION</a>
                    </li>
  	                <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#dp-menu2">FINISH OPTIONS</a>
                    </li>
	                  <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#dp-menu3">DATASHEET</a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div id="dp-home" className="container tab-pane active"><br />
                      <table className="table">
                        <tbody>
	                        <tr>
                            <th scope="row">Core</th>
                            <td>Wood fiber, Portland cement, and water.</td>
                          </tr>
                          <tr>
                            <th scope="row">Thickness (mm)</th>
                            <td>25, 35, 50, custom thickness available</td>
                          </tr>
                          <tr>
                            <th scope="row">Sizes (mm)</th>
                            <td>610 x 610 - 610 x 1220</td>
                          </tr>
	                        <tr>
                            <th scope="row">Acoustic Properties</th>
                            <td>NRC 0.40 – 0.75 ( varies with thickness and mounting )</td>
                          </tr>
                          <tr>
                            <th scope="row">Edges</th>
                            <td>Square and Beveled</td>
                          </tr>
                          <tr>
                            <th scope="row">Edge Finishes</th>
                            <td>Primed Clear, Primed White, Painted</td>
                          </tr>
	                        <tr>
                            <th scope="row">Colours</th>
                            <td>Wide range of standard colours. Also available in custom colours</td>
                          </tr>
	                        <tr>
                            <th scope="row">Mounting type</th>
                            <td>A and E-400</td>
                          </tr>
  	                      <tr>
                            <th scope="row">Installation</th>
                            <td>Suspension by others</td>
                          </tr>
	                      </tbody>
                      </table>
		                </div>
                    <div id="dp-menu1" className="container tab-pane fade"><br />
                      <h3>Sound Absorption Coefficients</h3>
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Thickness</th>
                            <th scope="col">125Hz</th>
                            <th scope="col">250Hz</th>
                            <th scope="col">500Hz</th>
                            <th scope="col">1KHz</th>
                            <th scope="col">2KHz</th>
                            <th scope="col">4KHz</th>
                            <th scope="col">NRC</th>
                          </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th scope="row">25mm</th>
                            <td>0.08</td>
                            <td>0.12</td>
                            <td>0.26</td>
                            <td>0.52</td>
                            <td>0.89</td>
                            <td>0.60</td>
                            <td>0.45</td>
                          </tr>
                          <tr>
                            <th scope="row">35mm</th>
                            <td>0.04</td>
                            <td>0.23</td>
                            <td>0.41</td>
                            <td>0.80</td>
                            <td>0.79</td>
                            <td>0.81</td>
                            <td>0.55</td>
                          </tr>
                        </tbody>
                      </table>
	  	              </div>
                    <div id="dp-menu2" className="container tab-pane fade"><br />
                      <br/>
                      <h3>Available Finish Options</h3>
		                  <br/>
	                    <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/woodwool/color-shades.pdf" className="brochure">Download</a>
                    </div>
	                  <div id="dp-menu3" className="container tab-pane fade"><br/>
                      <br/>
                      <h3>ASI : Datasheet</h3>
		                  <br/>
	                    <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/woodwool/tiles-baffles.pdf" className="brochure">Download</a>
                    </div>
                  </div>
                </div>			
			          <div className="col-12 text-center shape">
                  <h2>Envirocoustic™ Wood Wool <span style={{color: "#f13849"}}>Available Shapes</span></h2>
                  <br/><br/>
                  <div className="row">
        	          <div className="col-4 col-sm-2">
                      <img src={Woodwool27} alt="Woodwool Wall27" style={{width: "100%"}} />
                      <caption>Parallelogram Tiles</caption>
                    </div>
                    <div className="col-4 col-sm-2">
                      <img src={Woodwool28} alt="Woodwool Wall28" style={{width: "100%"}} />
                      <caption>Lines Tiles</caption>
                    </div>
                    <div className="col-4 col-sm-2">
                      <img src={Woodwool29} alt="Woodwool Wall29" style={{width: "100%"}} />
                      <caption>Stripes Tiles</caption>
                    </div>
                    <div className="col-4 col-sm-2">
                      <img src={Woodwool30} alt="Woodwool Wall30" style={{width: "100%"}} />
                      <caption>Curve Tiles</caption>
                    </div>
                    <div className="col-4 col-sm-2">
                      <img src={Woodwool31} alt="Woodwool Wall31" style={{width: "100%"}} />
                      <caption>Arch Tiles</caption>
                    </div>
                    <div className="col-4 col-sm-2">
                      <img src={Woodwool32} alt="Woodwool Wall32" style={{width: "100%"}} />
                      <caption>Quilted Tiles</caption>
                    </div>
                  </div>
                </div>			
			        </div>
	          </div>
	        </div>
		      <div className="col-sm-12 featured-products">
	          <div className="row pb-5">
  		        <div className="col-md-4 col-sm-12 featured-card">
	  	          <div className="card text-center">
                  <img className="card-img-top" src={Card1} alt="Card cap1" />
  		  	        <div className="card-block">
    		  	        <h4 className="card-title">Woodwool<br/><span>Wall</span> Panels</h4>
    			          <p className="card-text">We are the leader in eco-friendly, high-performance, cost-effective acoustical-panel products category</p>
    			          <Link to="/woodwool-wall"className="btn btn-danger">For More</Link>
  			          </div>
		            </div>
		          </div>
		          <div className="col-md-4 col-sm-12 featured-card">
		            <div className="card text-center">
			            <img className="card-img-top" src={Card2} alt="Card cap2" />
    			        <div className="card-block">
      			        <h4 className="card-title">Woodwool<br/><span>Ceiling</span> Panels</h4>
      			        <p className="card-text">Envirocoustic Wood wool ceiling panels can transform the aesthetics and acoustics of any interior space.</p>
    	  		        <Link to="/woodwool-ceiling" className="btn btn-danger">For More</Link>
  			          </div>
		            </div>
		          </div>
  		        <div className="col-md-4 col-sm-12 featured-card">
	  	          <div className="card text-center">
		  	          <img className="card-img-top" src={Card3} alt="Card cap3" />
  		  	        <div className="card-block">
    		  	        <h4 className="card-title">Woodwool<br/><span>Cloud / Baffles</span></h4>
    			          <p className="card-text">Cementitious Wood-Fiber Acoustic Ceiling Clouds / Baffles provide a high level of acoustical absorption</p>
    			          <Link to="/woodwool-cloud" className="btn btn-danger">For More</Link>
  			          </div>
		            </div>
		          </div>
		        </div>	
          </div>
		      <hr />
        </div>
      </div>
      <div className='mobsidebar'>
        <div className="col-sm-12">
          <div className="container">
            <div className="row">
              <Mobsidebar />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ScrollToTopButton />
  </section>
<Footer />
</>
);
}
export default woodwoolwall;