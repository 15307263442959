import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Hardware1 from '../../images/inner/bac-blade1.webp';
import Hardware2 from '../../images/inner/insulknife1.webp';
import Hardware3 from '../../images/inner/fwp-hardware-pack-1-200x200.webp';
import Hardware4 from '../../images/inner/rotofast-standard-anchor-single-kit-200x200.webp';
import Hardware5 from '../../images/inner/stick-pins-200x200.webp';
import Hardware6 from '../../images/inner/fwp-hardware-impaling-clip-200x200.webp';
import Hardware7 from '../../images/inner/sprinkler_blade1.webp';
import './hardware.css';
import ScrollToTopButton from '../ScrollButton';
function hardware() {
    return (
      
    <>
    <Helmet>
    <title>Hardware for Noise Control Products</title>
    <meta name="description" content="Our products have been tested for noise-controlling methods in industries with loads of our systems and to work in conjunction with one another." />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Hardware for Noise Control Products" />
    <meta property="og:description" content="The Hardware systems above are companion products for our top-quality soundproofing systems." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/fwp-hardware-pack-1-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/hardware" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/hardware" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/hardware" />
    </Helmet>
    <Header />
    <section className="content" id="hardware">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Hardware for Noise Control Products</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>Installing soundproofing systems is easy, as long as you have the right equipment. With Hardware designed especially for use with our soundproofing and noise control products, installation jobs can be completed quickly and easily.</p>
                                    <p>Don’t use inferior hardware substitutes during installations. Our products are made to work properly with soundproofing materials and will not damage panels or walls. For clean and precise cuts, using our blades and knives enables quick and safe trimming of insulation and acoustical panels. Hardware for attaching soundproofing systems to walls and ceilings is made specifically for strong, sturdy, and reliable installations. Our products have been tested to withstand the loads of our systems and to work in conjunction with one another.</p>
                                    <p>The Hardware systems above are companion products for our top-quality soundproofing systems. <a href="contact" target="_blank">Contact us</a> today to learn more!</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">   
                            <div className="col-sm-12">
                                <h4>View Our Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Hardware1} alt="Echo Eliminator BAC Blade" />
                                </Link>
                                <h5>Echo Eliminator BAC blade</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Hardware2} alt="Insul Knife" />
                                </Link>
                                <h5>Insul Knife</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Hardware3} alt="Panel Mounting Kits" />
                                </Link>
                                <h5>Panel Mounting Kits</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Hardware4} alt="RotoFast Anchors" />
                                </Link>
                                <h5>RotoFast Anchors</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Hardware5} alt="Stick Pins" />
                                </Link>
                                <h5>Stick Pins</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Hardware6} alt="Impalink Clips" />
                                </Link>
                                <h5>Impaling Clips</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Hardware7} alt="Adjustable Sprinkler Hole Cutters" />
                                </Link>
                                <h5>Adjustable Sprinkler Hole Cutters</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default hardware; 