import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Ceilingmetal1 from '../../images/inner/ceiling_metal.webp';
import Ceilingmetal2 from '../../images/inner/metal_ceiling-200x169.webp';
import Standardpattern from '../../images/inner/standard_pattern1.webp';
import Echoacoustic from '../../images/inner/echo1.webp';
import Pepp from '../../images/inner/pepp1.webp';
import Dcbe from '../../images/inner/DCBE-enclosure-200x200.webp';
import Booth from '../../images/inner/booth-200x200.webp';
import Panelfront from '../../images/inner/panel_front-200x200.webp';
import Clipinstall from '../../images/inner/clip_install-200x200.webp';
import Soundroom from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADlibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Mobsidebar from '../mobile-sidebar';
import './acoustimetal.css';
import ScrollToTopButton from '../ScrollButton';
function acousticmetal() {
    return (

        <>
            <Helmet>
                <title>Acoustimetal - latest advancement in noise control and acoustic treatment</title>
                <meta name="description" content="" />
                <meta name="robots" content="index, follow" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="in" />
                <meta name="geo.region" content="india" />
                <meta property="og:title" content="Noise Absorbing ACOUSTIMETAL" />
                <meta property="og:description" content="Introducing Acoustimetal™, the latest advancement in noise control and acoustic treatment." />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/ceiling_metal.webp" />
                <meta property="og:locale" content="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/acoustimetal" hreflang="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.ae/acoustimetal" hreflang="en-ae" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/acoustimetal" />
            </Helmet>
            <Header />
            <section className="content" id="acoustimetal">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 sidemenu">
                            <Sidebar />
                        </div>
                        <div className="col-sm-9 product-page nopaddding">
                            <div className="container">
                                <div className="megamenu">
                                    <Megamenu />
                                </div>
                            </div>
                            <div style={{padding:"15px"}}>
                                <h1>Noise S.T.O.P.™ ACOUSTIMETAL™</h1>
                                <div className="row pad">
                                    <div className="col-xl-4 col-sm-12">
                                        <Link className="example-image-link" to="../../images/inner/ceiling_metal.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Ceilingmetal1} alt="Ceiling Metal1" />
                                        </Link>
                                        <Link className="example-image-link" to="../../images/inner/metal_ceiling-200x169.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Ceilingmetal2} alt="Ceiling Metal2" style={{ maxwidth: "250px" }} />
                                        </Link>
                                    </div>
                                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 text">
                                        <ul style={{ marginleft: "1em" }}>
                                            <li>Durable</li>
                                            <li>Impact Resistant</li>
                                            <li>Walls Ceilings</li>
                                            <li>className A-1 ASTM E-84 Nonflammable</li>
                                        </ul>
                                        <p>Introducing Acoustimetal™, the latest advancement in noise control and acoustic treatment. Acoustimetal™ combines the durability of a perforated metal shell and the superior noise control, properties of our exclusive nonflammable acoustical material. Acoustimetal™ is ideal for demanding environments where noise control applications are exposed to physical impact. Indoor or outdoor use.</p>
                                        <p>The Acoustimetal™ shell is available in electrostatically finished, anodized aluminum or optional painted white. The Acoustimetal™ shell is designed to withstand the harshest industrial installations or visually appealing interior design. The unique 50% perforation pattern gives maximum sound control with the use of our className A, Fiberglass Free (LEED ELIGIBLE) <a href="echo-eliminator">Echo Eliminator</a> or <a href="sound-silencer">Sound Silencer</a> material inside. Custom panel sizes available.</p>
                                    </div>
                                    <div className="line"></div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h3>Product Specs</h3>
                                        <table className="table table-bordered" style={{ background: "#f5f5f5" }}>
                                            <tbody>
                                                <tr>
                                                    <th>Material</th>
                                                    <td>.064 Clear Anodized Perforated Aluminum</td>
                                                </tr>
                                                <tr>
                                                    <th>Metal Options</th>
                                                    <td>Galvanized steel or Stainless steel</td>
                                                </tr>
                                                <tr>
                                                    <th>Applications</th>
                                                    <td>Ceiling Tiles, Wall panels, Steps, machine enclosures.</td>
                                                </tr>
                                                <tr>
                                                    <th>Installation</th>
                                                    <td>Mechanical Clips</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <p style={{ marginLeft: "1em" }} ><Link to="https://acousticalsurfaces.in/pdfs/Worksheet_Acoustimetal.pdf" target="_blank" rel="noopener noreferrer" style={{ color: "#0059a5" }}>Download Request for Quote Worksheet</Link></p>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 fleximages">
                                        <div className="imagebox">
                                            <Link className="example-image-link" to="../../images/inner/standard_pattern1.webp" data-lightbox="example-1">
                                                <img className="example-image img-fluid" src={Standardpattern} alt="Standard Pattern" />
                                            </Link>
                                            <span>Standard Perforation</span>
                                        </div>
                                        <div className="imagebox">
                                            <Link className="example-image-link" to="../../images/inner/echo1.webp" data-lightbox="example-1">
                                                <img className="example-image img-fluid" src={Echoacoustic} alt="Echo" />
                                            </Link>
                                            <span><strong>Echo Eliminator™ – Fill</strong></span><br /><span className="d-none d-md-block d-lg-block d-xl-block">Echo Eliminator™ is a high performance acoustical material made from 85% post industrial recycled cotton. Echo Eliminator™ has an NRC up to 1.15.<br /><Link to="/echo-eliminator" style={{ color: "#0059a5" }}>More Information&nbsp;– Echo Eliminator</Link></span>
                                        </div>
                                        <div className="imagebox">
                                            <Link className="example-image-link" to="../../images/inner/pepp1.webp" data-lightbox="example-1">
                                                <img className="example-image img-fluid" src={Pepp} alt="Pepp" />
                                            </Link>
                                            <span><strong>Sound Silencer™ – Fill</strong></span><br /><span className="d-none d-md-block d-lg-block d-xl-block">Sound Silencer™ is a dual functional panel that block and absorbs sound. Sound Silencer™ has both an NRC and STC value.<br /><Link to="/sound-silencer" style={{ color: "#0059a5" }}>More Information – Sound SIlencer</Link></span>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <h4 style={{marginLeft: "1em"}}>Outdoor & Enclosures</h4>
                                    <div className="col-md-12 col-sm-12 fleximages">
                                        <div className="imagebox">
                                            <Link className="example-image-link" to="../../images/inner/DCBE-enclosure-200x200.webp" data-lightbox="example-1">
                                                <img className="example-image img-fluid" src={Dcbe} alt="DCBE Enclosure" />
                                            </Link>
                                        </div>
                                        <div className="imagebox">
                                            <Link className="example-image-link" to="../../images/inner/booth-200x200.webp" data-lightbox="example-1">
                                                <img className="example-image img-fluid" src={Booth} alt="Booth Acoustics" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <h4 style={{marginLeft:"1em"}}>Panel Front & Clip Install</h4>
                                    <div className="col-md-12 col-sm-12 fleximages">
                                        <div className="imagebox">
                                            <Link className="example-image-link" to="../../images/inner/panel_front-200x200.webp" data-lightbox="example-1">
                                                <img className="example-image img-fluid" src={Panelfront} alt="Front Panel" />
                                            </Link>
                                        </div>
                                        <div className="imagebox">
                                            <Link className="example-image-link" to="../../images/inner/clip_install-200x200.webp" data-lightbox="example-1">
                                                <img className="example-image img-fluid" src={Clipinstall} alt="Clip Install" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <hr className="d-none d-md-block d-lg-block d-xl-block" />
                                <div className="row">
                                    <div className="row resources">
                                        <div className="col-xl-12 col-lg-12 col-sm-12">
                                            <h2>Additional Resources</h2>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-sm-12">
                                            <h6 style={{ textalign: "center" }}>Creating Better-Sounding Rooms</h6>
                                            <img className="hvr-grow" src={Soundroom} alt="Create Better Sounding Rooms" target="_blank" rel="noopener noreferrer" />
                                            <div className="butn"><Link to="/bs-rooms-guide">Download eBook</Link></div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-sm-12">
                                            <h6 style={{ textalign: "center" }}>Solutions to Common Noise Problems</h6>
                                            <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems" target="_blank" rel="noopener noreferrer" />
                                            <div className="butn"><Link to="/noise-problems-guide">Download eBook</Link></div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-sm-12">
                                            <h6 style={{ textalign: "center" }}><br />CAD, CSI, & Revit Library </h6>
                                            <img className="hvr-grow" src={CADlibrary} alt="CAD Library" target="_blank" rel="noopener noreferrer" />
                                            <div className="butn"><Link to="/cad-library-guide">Access Product Cad Files</Link></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mobsidebar'>
                        <div className="col-sm-12">
                            <div className="container">
                                <div className="row">
                                    <Mobsidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default acousticmetal;