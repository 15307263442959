import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Superw1 from '../../images/inner/super-w-pad-in-sheet.webp';
import Superw2 from '../../images/inner/w_pad_only.webp';
import Superw3 from '../../images/inner/w_stack.webp';
import Superw4 from '../../images/inner/super_w_pads.webp';
import './super-w-pads.css';
import ScrollToTopButton from '../ScrollButton';
function superw() {
    return (
      
    <>
    <Helmet>
    <title>Super W Pads Reduce & High Frequency Vibration </title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Super W Pads Reduce Noise" />
    <meta property="og:description" content="The Super W Pad is One of the Thickest Most Versatile and Efficient Pads Available" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/super-w-pad-in-sheet.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/super-w-pads" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/super-w-pads" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/super-w-pads" />
    </Helmet>
    <Header />
    <section className="content" id="superwpads">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Super W Pads</h1>  
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/super-w-pad-in-sheet.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Superw1} alt="Super W Pads1" />
                                </Link>
                                <div className="row col-md-12 col-lg-12 minigallery">
                                    <Link className="example-image-link" to="../../images/inner/w_pad_only.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" style={{margin: "5px", maxwidth: "4em"}} src={Superw2} alt="Super W Pads2" />
                                    </Link>
                                    <Link className="example-image-link" to="../../images/inner/w_stack.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" style={{margin: "5px", maxwidth: "4em"}} src={Superw3} alt="Super W Pads3" />
                                    </Link>
                                    <Link className="example-image-link" to="../../images/inner/super_w_pads.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" style={{margin: "5px", maxwidth: "4em"}} src={Superw4} alt="Super W Pads4" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>The Super W Pad is One of the Thickest Most Versatile and Efficient Pads Available</h2>
                                <p><strong>Super W Pads Reduce Noise, High Frequency Vibration & Impact From Typical Equipment:</strong></p>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>                                                    
                                    <li>Single Package Air Conditioners and Heat Pumps</li>
                                    <li>Fan Coil Units</li>
                                    <li>Condensing Units</li>
                                    <li>Electronic Air Cleaners</li>
                                    <li>Beverage Coolers</li>
                                    <li>Pumps</li>
                                    <li>Air Compressors</li>                                   
                                </ul>                 
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/2._SuperW_Pads.pdf" target="_blank">Product Specs</Link>
                                        </li>  
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <p>Noise and vibration control pads should be relied upon only as a noise break and minor vibration control to be placed between equipment and a stiff support surface. Significant vibrations require “tall” rubber or spring mounts that yield higher deflections. </p>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <thead>
                                        <tr>
                                            <th  style={{verticalalign: "top"}}>Applications</th>
                                            <td>Super W Pads reduce annoying noise and minor vibration generated by air conditioners, refrigeration equipment, pumps and much more. The higher deflection waffle design with its suction cup action eliminates the need for bolting down.<br />
                                                Super W Pads should be used in 2″×2″ squares. Select the minimum number of squares required and design pads to the most convenient shape. The use of additional squares results in more conservative loading.<br />
                                                Example: Load is 700 lbs., capacity is 120 lbs/square. 700/120 = 5.83 squares. Use 6 squares configured as 2 squares by 3 squares (4″×6″ )or 1 square by 6 squares (2″×12″).<br />
                                                Pads may be stacked with 16 gauge shims between layers for increased deflection (For example, 3 layers offer 3 times the deflection.
                                            </td>
                                        </tr>
                                        <tr>
                                            <th style={{verticalalign: "top"}}>Super W Pads are Molded in 3 Elastomers </th>
                                            <td>Standard Natural Rubber for maximum resilience and vibration isolation.<br />
                                                Neoprene for longer life and moderate oil resistance. In 3/4″ thick pads only.<br />
                                                Bridge-Bearing Neoprene for maximum life, excellent oil resistance and resiliency approaching natural rubber. Available in 3/4″ thick pads only.
                                            </td>
                                        </tr>
                                        <tr>
                                            <th style={{verticalalign: "top"}}>Sizes</th>
                                            <td>Standard 18″×18″ sheets consist of 81 – 2″ squares each supporting 120 pounds. The squares are separated by a thin web that is easily cut to provide evenly dimensioned pads such as 2″×2″, 2″×4″, 4″×4″, 6″×8″, etc. </td>
                                        </tr>
                                        <tr>
                                            <th style={{verticalalign: "top"}}>Performance</th>
                                            <td>Super W Pads outperform cork and rubber “sandwich” pads by giving longer life and greater resiliency with consistent and reliable service. </td>
                                        </tr>
                                    </thead>
                                </table>
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="4">Super W Load Rating (per Square)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td>Type Size</td>
                                            <td>Standard Durometer</td>
                                            <td>Load per 2″×2″ Square (lbs)</td>
                                            <td>Rated Defl. (in)</td>
                                        </tr>
                                        <tr>
                                            <td>Super W Pad 18″×18″×3/4″</td>
                                            <td>40</td>
                                            <td>120</td>
                                            <td>.10</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default superw;