import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundroom from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import Cadlibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Sealant2 from '../../images/inner/glogo.jpg';
import Sealant1 from '../../images/inner/green_series.jpg';
import './acoustical-sealant-green.css';
import ScrollToTopButton from '../ScrollButton';
function acousticsealantgreen() {
    return (
      
    <>
    <Helmet>
    <title>GreenSeries & Adhesive High Performance Non-flammable -  Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="GreenSeries™ Adhesive – High Performance" />
    <meta property="og:description" content="Acoustical Sealant Model #AS-29" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/green_series.jpg" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acoustical_sealant-green" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="sealantgreen">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                    </div>
                    <h1>GreenSeries™ Adhesive – High Performance </h1>
                    <div className="row">
                        <div className="col-xl-4 col-sm-12">
                            <Link className="example-image-link" to="../../images/inner/green_series.jpg" data-lightbox="example-1">
                            <img className="example-image img-fluid" src={Sealant1} alt="acoustics" /></Link>
                        </div>
                        <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                            <h2>Acoustical Sealant Model #AS-29</h2>
                            <p><span>GreenSeries™ Adhesive is a high performance, non-flammable, environmentally friendly construction adhesive. This product’s unique formulation offers Green Builders a high performance, low gassing, latex based construction adhesive without sacrificing strength and performance.</span></p>
                            <p><strong>Size:</strong><span>&nbsp;28 fl. oz. (825 mL)</span>
                            <br />
                            <br /><strong>Coverage:</strong>
                            <br /><span>1/4″ bead extrudes approximately 86 ft.</span>
                            <br /><span>3/8″ bead extrudes approximately 38 ft.</span></p>
                            <Link className="example-image-link" to="../../images/inner/glogo.jpg" data-lightbox="example-1">
                            <img className="example-image img-fluid" src={Sealant2} alt="glogo" /></Link>
                        </div>
                        <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                            <div className="testing-info" style={{margintop: "2em"}}>
                                <h5>Product Testing & Information</h5>
                                <ul className="">
                                    <li><Link target="_blank" to="https://acousticalsurfaces.in/pdfs/Green_Series_Adhesive.pdf" className="ico-misc">Product Specs</Link></li>
                                    <li><Link target="_blank" to="https://acousticalsurfaces.in/pdfs/Green_MSDS.pdf" className="ico-misc">MSDS</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="line"></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                <thead>
                                    <tr>
                                        <th>Feature</th>
                                        <th>Benefits</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Ultra Low VOC content</td>
                                        <td>GREENGUARD approved and meets LEED requirements.</td>
                                    </tr>
                                    <tr>
                                        <td>Water-based Adhesive</td>
                                        <td>Non-Flammable, Environmentally Friendly.</td>
                                    </tr>
                                    <tr>
                                        <td>Bonds to Most Building Materials</td>
                                        <td>Provides a strong, durable bond to multiple surfaces; Bonds well to wet, frozen and treated lumber.</td>
                                    </tr>
                                    <tr>
                                        <td>Gap Filling</td>
                                        <td>Will bridge minor gaps when bonding irregular surfaces; Helps reduce squeaks and sound transmission.</td>
                                    </tr>
                                    <tr>
                                        <td>Easy Cleanup with Water (Uncured Adhesive)</td>
                                        <td>Eliminates the use of harsh cleaning chemicals.</td>
                                    </tr>
                                    <tr>
                                        <td>Low Odor</td>
                                        <td>Great Indoor Projects&nbsp;– No Strong Solvent Odor</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-sm-12 text">
                            <h2 style={{margintop: "0"}}>Tools Typically Required:</h2>
                            <p><span>Utility knife, caulking gun and tool to puncture inner seal of cartridge.</span>
                            <br />
                            <br />
                            </p>
                            <h2>Safety Precautions:</h2>
                            <p><span>Wear gloves.</span>
                            <br />
                            <br />
                            </p>
                            <h2>Preparation:</h2>
                            <p><span>The temperature of the adhesive, the surfaces and the working area must be above 40°F (4°C) and below 100°F (38°C). For best performance, apply adhesive at 70°F (21°C). Ensure surfaces to be bonded are clean, dry, structurally sound and free of dust, grease, oil, snow, ice, standing water or other contaminants. To increase adhesion, sand glossy surfaces to a dull finish. Pre-fit all materials prior to applying product. Cut off tip of cartridge at a 45° angle to desired bead size. Puncture inside seal of cartridge.</span>
                            <br />
                            <br />
                            </p>
                            <h2>Storage and Disposal Damaged by Freezing:</h2>
                            <p><span>Store in a cool, dry location at room temperature. Take unwanted product to an approved household hazardous waste transfer facility. Hardened material may be disposed of with trash.</span>
                            <br />
                            <br />
                            </p>
                            <h2>Discliamer:</h2>
                            <p><span>The information and recommendations contained herein are based on our research and are believed to be accurate, but no warranty, express or implied, is made or should be inferred. Purchasers should test the products to determine acceptable quality and suitability for their own intended use. Nothing contained herein shall be construed to imply the nonexistence of any relevant patents or to constitute a permission, inducement or recommendation to practice any invention covered by any patent, without authority from the owner of the patent.</span>
                            <br />
                            <br />
                            </p>
                            <h2>Precautions:</h2>
                            <p><span>CAUTION! CONTAINS ETHYLENE GLYCOL AND CRYSTALLINE SILICA. Avoid eye contact. Do not take internally. Use with adequate ventilation. KEEP OUT OF REACH OF CHILDREN.</span></p>
                        </div>
                    </div>
                    <hr className="d-none d-md-block d-lg-block d-xl-block" />
                    <div className="row resources">
                        <div className="col-xl-12 col-lg-12 col-sm-12">
                            <h2>Additional Resources</h2>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-sm-12">
                            <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                            <img className="hvr-grow" src={Soundroom} alt="Create Better Sounding Rooms" target="_blank" rel="noopener noreferrer" />
                            <div className="butn"><Link to="/bs-rooms-guide">Download eBook</Link></div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-sm-12">
                            <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                            <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems" target="_blank" rel="noopener noreferrer" />
                            <div className="butn"><Link to="/noise-problems-guide">Download eBook</Link></div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-sm-12">
                            <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                            <img className="hvr-grow" src={Cadlibrary} alt="CAD Library" target="_blank" rel="noopener noreferrer" />
                            <div className="butn"><Link to="/cad-library-guide">Access Product Cad Files</Link></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acousticsealantgreen;