import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Whisperwave1 from '../../images/inner/whisperwave-horizontal-hanging-baffles1.webp';
import Whisperwave2 from '../../images/inner/whisperwave-hanging-ceiling-cloud.webp';
import Whisperwave3 from '../../images/inner/whisperwave-wall-panels.webp';
import Whisperwave4 from '../../images/inner/bottom1.webp';
import './whisper-wave-clouds.css';
import ScrollToTopButton from '../ScrollButton';
function wallmate() {
    return (
      
    <>
    <Helmet>
    <title>WhisperWave Clouds Curved accent acoustical control products</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Soundproofing WhisperWave Ceiling Clouds" />
    <meta property="og:description" content="Whisperwave curved accent products allows you to unleash your creativity while reducing noise and reverberation." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/whisperwave-horizontal-hanging-baffles1.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/whisper-wave-clouds" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/whisper-wave-clouds" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/whisper-wave-clouds" />
    </Helmet>
    <Header />
    <section className="content" id="whisper">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>NOISE S.T.O.P. WHISPERWAVE™ Ceiling Clouds</h1> 
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/whisperwave-horizontal-hanging-baffles1.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Whisperwave1} alt="Whisper Wave Clouds1" />
                                </Link>
                                <div className="row col-md-12 col-lg-12 minigallery">
                                    <div className='col-lg-4 col-md-2'>
                                        <Link className="example-image-link" to="../../images/inner/whisperwave-hanging-ceiling-cloud.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" style={{margin: "5px", maxwidth: "4em"}} src={Whisperwave2} alt="Whisper Wave Clouds2" />
                                        </Link>
                                    </div>
                                    <div className='col-lg-4 col-md-2'>
                                        <Link className="example-image-link" to="../../images/inner/whisperwave-wall-panels.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" style={{margin: "5px", maxwidth: "4em"}} src={Whisperwave3} alt="Whisper Wave Clouds3" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <ol style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>Curved accent acoustical control products</li>
                                    <li>Exceptional acoustical control across all<br />frequencies</li>
                                    <li>Lightweight and easy to install</li>
                                </ol>
                                <p></p>
                                <p>Ride the wave with WHISPERWAVE. WHISPERWAVE curved accent products allows you to unleash your creativity while reducing noise and reverberation. Add dimension and acoustical control right where you need it the most with WHISPERWAVE ceiling clouds. Ceiling clouds are suspended on ceiling-mounted cables using corkscrew hangers.</p>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/WhisperWave.pdf" target="_blank">Product Specs</Link>
                                        </li>
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/WhisperWave-Drawing-Ribbon.pdf" target="_blank">Panel CAD Drawing</Link>
                                        </li>
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/WhisperWave-Drawing-Baffle.pdf" target="_blank">Baffle CAD Drawing</Link>
                                        </li>
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/WhisperWave-Drawing-Cloud.pdf" target="_blank">Cloud CAD Drawing</Link>
                                        </li>
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/Whisperwave-Installation-Guide.pdf" target="_blank">Installation Guide</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row pad">
                            <div className="col-sm-12 text">
                                <p>Made from Class 1 fire-rated melamine foam, WHISPERWAVE products are offered in standard and custom sizes and curve patterns. WHISPERWAVE products are lightweight and easy to install. The line also includes acoustic baffles, panels and awnings.</p>
                                <p>WHISPERWAVE products are available in natural white and light grey or can be Hypalon®-coated (black, grey, white, almond or charcoal) or painted (charcoal, beige, brown or blue). Custom colors are also available to match school colors or any décor.</p>
                                <p>WHISPERWAVE Panels, Acoustic Baffles, Ceiling Clouds and Awnings provide exceptional acoustical control and design flexibility. Lightweight and easy to install, WHISPERWAVE products are ideal for use in classrooms, cafeterias, multipurpose rooms, indoor swimming pools, offices, libraries, religious facilities and other large open areas.</p>    
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 text">
                                <h2 style={{fontweight: "bold"}}>Material</h2>
                                <p>WHISPERWAVE ceiling clouds, acoustic baffles, panels, and more are made from melamine foam, which is Class 1 fire-rated for flame spread and smoke density. Melamine natural also meets the corner burn test UL 1715. WHISPERWAVE is offered in natural white and grey and can be painted or Hypalon®-coated.</p>
                                <h4 style={{fontweight: "bold"}}>Advantages</h4>
                                <ul className='whisper-content' style={{fontsize: ".9em", marginleft: "1.5em"}}>
                                    <li>Standard and custom wave designs</li>
                                    <li>Exceptional acoustical control across all frequencies</li>
                                    <li>Offered in panel, baffle, ceiling cloud and awning options</li>
                                    <li>Easy installation</li>
                                    <li>Class 1 fire-rated</li>
                                </ul>
                            </div>
                            <div className="col-sm-4 d-none d-lg-block d-xl-block">
                                <img style={{margintop: "2em"}} className="img-fluid" src={Whisperwave4} alt="Whisper Wave Clouds4" />
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-6 col-xl-6 text">
                                <h2 style={{fontweight: "bold"}}>Sizes</h2>
                                <h5>WHISPERWAVE Wall Panels</h5>
                                <ul style={{fontsize: ".9em", marginleft: "1.5em"}}>
                                    <li>Any dimension up to 48″ × 96″</li>
                                    <li>Custom panel sizes available</li>
                                    <li>Can be joined for long ribbon appearance</li>
                                    <li>1-1/2″, 2″ and 3″ thicknesses</li>
                                </ul>
                                <h5>WHISPERWAVE Baffles</h5>
                                <ul style={{fontsize: ".9em", marginleft: "1.5em"}}>
                                    <li>Any dimension up to 48″ × 96″</li>
                                    <li>Custom baffle sizes available</li>
                                    <li>2″ and 3″ thicknesses</li>
                                </ul>
                                <h5>WHISPERWAVE Ceiling Clouds & Awnings</h5>
                                <ul style={{fontsize: ".9em", marginleft: "1.5em"}}>
                                    <li>Any dimension up to 48″ × 96″</li>
                                    <li>Custom sizes also available</li>
                                    <li>2-1/2″ and custom thicknesses</li>
                                </ul>
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-6 col-xl-6 text">
                                <h2 style={{fontweight: "bold"}}>Installation</h2>
                                <h5>WHISPERWAVE Panels</h5>
                                <ul style={{fontsize: ".9em", marginleft: "1.5em"}}>
                                    <li>Mounts to ceilings or walls using acoustical adhesive</li>
                                </ul>
                                <h5>WHISPERWAVE Baffles</h5>
                                <ul style={{fontsize: ".9em", marginleft: "1.5em"}}>
                                    <li>Corkscrew hangers are installed in the field for either wall-to-wall cable installation or ceiling-mounted cable installation</li>
                                    <li>Can be aligned in the same direction or arranged so that every other baffle is turned 90 degrees</li>
                                </ul>
                                <h5>WHISPERWAVE Ceiling Cloud</h5>
                                <ul style={{fontsize: ".9em", marginleft: "1.5em"}}>
                                    <li>Corkscrew hangers are installed in the field for ceiling mounted cable installation</li>
                                </ul>
                                <h5>WHISPERWAVE Awning</h5>
                                <ul style={{fontsize: ".9em", marginleft: "1.5em"}}>
                                    <li>Install track on the wall to support wall side of awning</li>
                                    <li>Corkscrew hangers are installed in the field for ceiling mounted cable installation</li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="2">Physical Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Material</td>
                                            <td>Open-cell Melamine-based foam</td>
                                        </tr>
                                        <tr>
                                            <td>Density</td>
                                            <td>0.5 to 0.7 lbs/cubic ft. (ASTM D3574-77)</td>
                                        </tr>
                                        <tr>
                                            <td>Long-Term Service Temp</td>
                                            <td>302°F</td>
                                        </tr>
                                        <tr>
                                            <td>Fire Resistance</td>
                                            <td>Class 1 per ASTM E 84 (all Finishes)<br />
                                                Meets UL 1715 (melamine natural)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Flame Spread per ASTM E 84</td>
                                            <td>Natural: 5<br />
                                                Painted: 10<br />
                                                Hypalon*-coated: 25
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Smoke Density per ASTM E 84</td>
                                            <td>Natural: 50<br />
                                                Painted: 10<br />
                                                Hypalon-coated: 65
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Microbial Growth</td>
                                            <td>Passes UL 181, section 11</td>
                                        </tr>
                                        <tr>
                                            <td>Toxicity</td>
                                            <td>Passes University of Pittsburg Toxicity of Smoke Emission test.</td>
                                        </tr>
                                        <tr>
                                            <td>Finishes</td>
                                            <td>Natural White or Grey, Painted or Hypalon-Coated</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="10">Sound Absorption Whisperwave Panels</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td colSpan="2"></td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1kHz</td>
                                            <td>2kHz</td>
                                            <td>4kHz</td>
                                            <td>NRC</td>
                                            <td>Mount Type</td>
                                        </tr>
                                        <tr>
                                            <td rowspan="2">Natural<br />
                                                (White or Grey)
                                            </td>
                                            <td>2″</td>
                                            <td>0.11</td>
                                            <td>0.33</td>
                                            <td>0.85</td>
                                            <td>1.05</td>
                                            <td>1.09</td>
                                            <td>1.06</td>
                                            <td className="emphasis">0.85</td>
                                            <td>B</td>
                                        </tr>
                                        <tr>
                                            <td>3″</td>
                                            <td>0.09</td>
                                            <td>0.68</td>
                                            <td>1.20</td>
                                            <td>1.18</td>
                                            <td>1.12</td>
                                            <td>1.05</td>
                                            <td className="emphasis">1.05</td>
                                            <td>A</td>
                                        </tr>
                                        <tr>
                                            <td rowspan="2">Painted<br />
                                                (Charcoal, Beige, Brown, Blue or Grey)
                                            </td>
                                            <td>2″</td>
                                            <td>0.07</td>
                                            <td>0.37</td>
                                            <td>0.89</td>
                                            <td>1.05</td>
                                            <td>1.04</td>
                                            <td>1.03</td>
                                            <td className="emphasis">0.85</td>
                                            <td>B</td>
                                        </tr>
                                        <tr>
                                            <td>3″</td>
                                            <td>0.15</td>
                                            <td>0.72</td>
                                            <td>1.21</td>
                                            <td>1.20</td>
                                            <td>1.15</td>
                                            <td>1.13</td>
                                            <td className="emphasis">1.05</td>
                                            <td>A</td>
                                        </tr>
                                        <tr>
                                            <td rowspan="2">Hypalon-coated<br />
                                                (Black, Grey, White, Almond)
                                            </td>
                                            <td>2″</td>
                                            <td>0.13</td>
                                            <td>0.41</td>
                                            <td>1.02</td>
                                            <td>1.18</td>
                                            <td>1.18</td>
                                            <td>1.13</td>
                                            <td className="emphasis">0.95</td>
                                            <td>B</td>
                                        </tr>
                                        <tr>
                                            <td>3″</td>
                                            <td>0.13</td>
                                            <td>0.85</td>
                                            <td>1.25</td>
                                            <td>1.22</td>
                                            <td>1.13</td>
                                            <td>1.14</td>
                                            <td className="emphasis">1.10</td>
                                            <td>A</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="9">Sound Absorption Whisperwave Baffles</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td colSpan="2"></td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1kHz</td>
                                            <td>2kHz</td>
                                            <td>4kHz</td>
                                            <td>Ave</td>
                                        </tr>
                                        <tr>
                                            <td>Natural<br />
                                                (White or Grey)
                                            </td>
                                            <td>2″</td>
                                            <td>1.0</td>
                                            <td>5.4</td>
                                            <td>10.8</td>
                                            <td>16.3</td>
                                            <td>18.7</td>
                                            <td>24.0</td>
                                            <td className="emphasis">12.7</td>
                                        </tr>
                                        <tr>
                                            <td>Painted<br />
                                                (Charcoal, Beige, Brown, Blue or Grey)
                                            </td>
                                            <td>2″</td>
                                            <td>2.3</td>
                                            <td>6.5</td>
                                            <td>12.7</td>
                                            <td>19.7</td>
                                            <td>21.0</td>
                                            <td>21.0</td>
                                            <td className="emphasis">15.0</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="9">Sound Absorption Whisperwave Ceiling Clouds/Awnings</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td colSpan="2"></td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1kHz</td>
                                            <td>2kHz</td>
                                            <td>4kHz</td>
                                            <td>Sabin</td>
                                        </tr>
                                        <tr>
                                            <td>Natural<br />
                                                (White or Grey)
                                            </td>
                                            <td>2.5″</td>
                                            <td>6.4</td>
                                            <td>22.1</td>
                                            <td>44.3</td>
                                            <td>55.9</td>
                                            <td>58.9</td>
                                            <td>60.6</td>
                                            <td className="emphasis">45.3</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default wallmate;
    