import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import ScrollToTopButton from '../ScrollButton';
function polyfoam() {
    return (
      
    <>
    <Helmet>
    <title>Pu Foam sound absorption wall panels</title>
    <meta name="description" content="We are a Pu Foam Manufacturer, Pu Foam is charcoal grey in color with a consistent small open-cell structure to provide maximum sound absorption." />
    <meta property="og:title" content="Polyurethane Foam Manufacturer" />
    <meta property="og:description" content="Polyester Urethane Foam is charcoal grey in color with a consistent small open-cell structure to provide maximum sound absorption." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/polyurethane-foam.jpg" />
    <meta property="og:locale" content="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.ae/polyurethanefoam" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/polyurethanefoam" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/polyurethanefoam" hreflang="en-in" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="IN" />
    <meta name="geo.region" content="india" />
    </Helmet>
    <Header />
    <section className="content">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Polyurethane Foam</h1>
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                <Link className="example-image-link" href="images/inner/polyurethane-foam.jpg" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src="images/inner/polyurethane-foam.jpg" alt="acoustics" />
                                </Link>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Polyurethane Foam</h2>
                                <p>Polyester Urethane Foam sound absorber, is a 2 lb. per cu. ft. (32 kg/m3) density polyester urethane foam developed to absorb maximum acoustical energy, using minimum weight and thickness. Sound ¡s absorbed over a broad frequency spectrum with peak efficiencies above the 500 Hz frequency level. Polyester Urethane Foam is charcoal grey in color with a consistent small open-cell structure to provide maximum sound absorption. The material is very clean to handle and is non-toxic and non-irritating. Because of high tear and shear strength properties, it can be supported with a minimum of mechanical fasteners. It can be attached by adhesive to most types of hard reflective surfaces and also furnished die-cut to any irregular shape, and can be easily cut with a utility knife or scissors. Standard thicknesses are 1/4” through 2” (6 mm through 51 mm).</p>
                                <p>Polyester Urethane Foam is available with special facings for protection against oils, dirts, and moisture, and is also available with pressure-sensitive adhesive backing for ease of installation where the material is to be applied by bonding.</p>
                            </div>
                            <div className="line"></div>
                            <div className="col-xl-12 col-lg-12col-md-12 col-sm-12 text">
                                <h3>Application</h3>
                                <p>Polyester Urethane Foam is an absorber used to reduce the energy of reflective and/or reverberant sound waves in the middle and high frequency ranges. When used as a decoupler of two masses, such as for wrapping ductwork or pipelines prior to the use of secondarybarrier materials such as mass-loaded vinyl products, it increases the Sound Transmission className (STC) of the system. It may be used in baffle form above open-top enclosures where it is necessary to reduce reverberant sound levels created by reflection from hard surfaces.</p>
                                <br />
                                <h3>Product Specifications</h3>
                                <p>
                                    Acoustical absorption material shall be polyester-type, urethane foam having an NRC Rating of 0.35 for 1/4” (6 mm)
                                    thickness, 0.54 for 1/2” (1 3 mm), 0.75 for 1 “(25 mm), or 0.90 for 2” (51 mm) thickness.
                                </p>
                                <p>
                                    Acoustical absorption material shall have a life service expectancy of ten years under normal applications, and shall be
                                    rated #94 HF-i in accordance with UL #94 Flame Retardancy Tests.
                                </p>
                                <p>
                                    When used in environments where the open cells of the foam can be filled or contaminated with oil, oil mists, dirt, dusts, and water, the exposed surface shall be faced with a thin film of either Urethane, Ted lar, or Mylar to protect against this contamination.
                                </p>
                                <p>
                                    Material shall have a density of 2 lbs./cu. ft. (32 kg/m3) and shall be manufactured by Polyester Urethane, Inc.
                                </p>
                                <p>The optional film facing provides protection in applications involving dust, oil mist, and moisture without detracting from the acoustical properties of the foam.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default polyfoam; 