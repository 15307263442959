import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import House1 from '../../images/inner/cotton_stack-200x200.webp';
import House2 from '../../images/inner/fabric-wrapped-panel-edge-details-lg-200x200.webp';
import House3 from '../../images/inner/cellulose-panels1-200x200.webp';
import House4 from '../../images/inner/wallmate-sports-arena-200x200.webp';
import House5 from '../../images/inner/curve-low-profile-diffusor1-200x200.webp';
import House6 from '../../images/inner/recording-studio-windows2-200x200.webp';
import House7 from '../../images/inner/soundproof-door-piano-room-st-olaf4-200x200.webp';
import Mobsidebar from '../mobile-sidebar';
import './houseofworship.css';
import ScrollToTopButton from '../ScrollButton';
function houseofworship() {
    return (
      
    <>
    <Helmet>
    <title>Soundproofing Churches and Houses of Worship Acoustic Solutions</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Soundproofing Churches and Houses of Worship" />
    <meta property="og:description" content="We are well-versed in solving acoustical issues found in churches, fellowship halls, and gathering spaces." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/soundproof-door-piano-room-st-olaf4-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/houseofworship" hreflang="en-ae" />    
    <link rel="alternate" href="https://www.acousticalsurfaces.in/houseofworship" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/houseofworship" />
    </Helmet>
    <Header />
    <section className="content" id="houseofworship">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Soundproofing Churches and Houses of Worship</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>A well-designed church acoustic design system is an important characteristic in any house of worship. These are often beautiful spaces made from glass, stone, marble, concrete and wood and have varying degrees of reverberation. Sound absorbing panels can help a congregation better hear the spoken word. These church acoustic treatment panels reduce echo and improve sound quality.</p>
                                    <p>We are well-versed in solving acoustical issues found in churches, fellowship halls, and gathering spaces. Because no two spaces are the same, we offer a wide variety of church acoustic treatment products. We have acoustical panels, clouds and baffles, doors, windows, sealants and adhesives to meet the needs of each individual situation. Decorative and high-end designs are available to match existing décor. We also understand that music is a large part of most services. Our experienced acoustical advisors are experts in recommending the perfect acoustical solution for your space.</p>
                                    <p>We understand the importance of sound quality in houses of worship for the satisfaction of the congregants. Our expert knowledge of acoustical requirements combined with our top-quality products form a winning combination. The products above are examples of components that we have used to improve church acoustic design. For additional information, please <Link to="/contact-us">contact us</Link>.</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>Houses Of Worship Soundproofing Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={House1} alt="Echo Eliminator" />
                                </Link>
                                <h5>Echo Eliminator</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={House2} alt="Fabric Wrapped Fiberglass Panels" />
                                </Link>
                                <h5>Fabric Wrapped Fiberglass Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={House3} alt="CFAB Cellulose Panels" />
                                </Link>
                                <h5>CFAB Cellulose Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={House4} alt="Wallmate" />
                                </Link>
                                <h5>WallMate</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={House5} alt="Curve Panels" />
                                </Link>
                                <h5>Curve Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={House6} alt="Windows" />
                                </Link>
                                <h5>Windows</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={House7} alt="Doors" />
                                </Link>
                                <h5>Doors</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default houseofworship; 