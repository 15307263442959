import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Woodwoolcloud from '../../images/wudwool/cloud2.webp';
import Woodwoolcloud2 from '../../images/wudwool/bf4.webp';
import Woodwoolcloud3 from '../../images/wudwool/bf8.webp';
import Woodwoolcloud4 from '../../images/wudwool/bf2.webp'
import Woodwoolcloud5 from '../../images/wudwool/bf3.webp';
import Woodwoolcard from '../../images/wudwool/card1.webp';
import Woodwoolcard2 from '../../images/wudwool/card2.webp';
import Woodwoolcard3 from '../../images/wudwool/card3.webp';
import Mobsidebar from '../mobile-sidebar';
import './woodwool-cloud.css';
import ScrollToTopButton from '../ScrollButton';
function woodwoolcloud() {
    return (
      
    <>
    <Helmet>
    <title>Wood Wool Cloud is eco-friendly acoustical panel</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Wood Wool Cloud / Baffle Panels" />
    <meta property="og:description" content="Wood Wool is now a leader in the eco-friendly, high-performance, cost-effective acoustical-panel products category." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/wudwool/bf2.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/woodwool-cloud" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/woodwool-cloud" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/woodwool-cloud" />
    </Helmet>
    <Header />
    <section className="content" id="woodwoolcloud">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
	                    <div className="col-sm-12 heading-fsorb">				
	                        <div className="row"> 
                                <div className="col-sm-12">
                                    <img src={Woodwoolcloud} alt="Woodwoolcloud" style={{width: "100%"}} />
                                </div>
                            </div>
                            <h1>Envirocoustic<sup>TM</sup> Wood Wool <span>Cloud / Baffles</span> Panels</h1>
		                    <p>Envirocoustic™ Wood Wool is now a leader in the eco-friendly, high-performance, cost-effective acoustical-panel products category. 
		                    <br /><br />
			                    These eco-friendly cementitious wood-fiber panels not only liven up a room with design variety, but acoustic
 			                    wood wool panels also reduce echo and reverberation through sound absorption. Wood wool boards can transform 
                                the aesthetics and acoustics of any interior space.</p>
		                    <br />
	                        <div className="flex-container">
	                            <Link to="/ww-downloads" className="btn btn-danger justify-content-center">Downloads</Link>
	                        </div>	
		                    <div className="col-sm-12 features p-1">
		                        <h1>Applications</h1>
	                            <div className="line"></div>
	    	                    <p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                                <div className="row pad ">	
		                            <div className="col-md-6 col-sm-12 d-none d-md-block">
	        		                    <ul>
		                                    <li>Offices</li>
		                                    <li>Schools</li>
		                                    <li>Meeting Rooms</li>
		                                    <li>Hotels</li>
            		                    </ul>
				                    </div>
				                    <div className="col-md-6 col-sm-12 d-none d-md-block">
	        		                    <ul>
		                                    <li>Auditorium</li>
		                                    <li>Recording Studios</li>
		                                    <li>Conference halls</li>
		                                    <li>Broadcasting Studios</li>
            		                    </ul>
				                    </div>
			                    </div>
                            </div>	
    	                    <div className="col-12 imgcard">
	                            <div className="row">
                                    <div className="col-6 col-sm-3 p-1">
                                        <img src={Woodwoolcloud2} alt="Woodwoolcloud2" style={{width: "100%"}} />
                                    </div>
                                    <div className="col-6 col-sm-3 p-1">
                                        <img src={Woodwoolcloud3} alt="Woodwoolcloud3" style={{width: "100%"}} />
                                    </div>
                                    <div className="col-6 col-sm-3 d-none d-sm-block p-1">
                                        <img src={Woodwoolcloud4} alt="Woodwoolcloud4" style={{width: "100%"}} />
                                    </div>
                                    <div className="col-6 col-sm-3 d-none d-sm-block p-1">
                                        <img src={Woodwoolcloud5} alt="Woodwoolcloud5" style={{width: "100%"}} />
                                    </div>
                                </div>
                            </div>	
	                    </div>			
                        <div className="col-sm-12 panels">
	                        <h2>Product Details</h2>
                            <div className="line"></div>
                            <br />
		                    <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" data-toggle="tab" href="#home">SPECIFICATION</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#menu1">REVERBERATION</a>
                                </li>
	                            <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#menu2">FINISH OPTIONS</a>
                                </li>
            	                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#menu3">DATASHEET</a>
                                </li>
	                        </ul>
                            <div className="tab-content">
	                            <div id="home" className="container tab-pane active"><br />
                                    <table className="table">
                                        <tbody>
	                                        <tr>
                                                <th scope="row">Core </th>
                                                <td>Wood fiber, Portland cement, and water.</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Thickness (mm)</th>
                                                <td>25, 35, 50</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Sizes (mm)</th>
                                                <td>610 x 610 - 610 x 1220, custom sizes available</td>
                                            </tr>
	                                        <tr>
                                                <th scope="row">Acoustic Properties</th>
                                                <td>NRC 0.45 – 0.95 ( varies with thickness and mounting )</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Edges</th>
                                                <td>Square and Beveled</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Edge Finishes</th>
                                                <td>Primed Clear, Primed White, Painted</td>
                                            </tr>
	                                        <tr>
                                                <th scope="row">Colours</th>
                                                <td>Wide range of standard colours. Also available in custom colours</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Mounting</th>
                                                <td>A, C-25, C-50 and D-25</td>
                                            </tr>
	                                        <tr>
                                                <th scope="row">Installation</th>
                                                <td>Direct attach</td>
                                            </tr>
                                        </tbody>
                                    </table>
		                        </div>
                                <div id="menu1" className="container tab-pane fade"><br />
                                    <h3>Sound Absorption Coefficients</h3>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Thickness</th>
                                                <th scope="col">125Hz</th>
                                                <th scope="col">250Hz</th>
                                                <th scope="col">500Hz</th>
                                                <th scope="col">1KHz</th>
                                                <th scope="col">2KHz</th>
                                                <th scope="col">4KHz</th>
                                                <th scope="col">NRC</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">25mm</th>
                                                <td>0.08</td>
                                                <td>0.12</td>
                                                <td>0.26</td>
                                                <td>0.52</td>
                                                <td>0.89</td>
                                                <td>0.60</td>
                                                <td>0.45</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">35mm</th>
                                                <td>0.04</td>
                                                <td>0.23</td>
                                                <td>0.41</td>
                                                <td>0.80</td>
                                                <td>0.79</td>
                                                <td>0.81</td> 
                                                <td>0.55</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">50mm</th>
                                                <td>0.11</td>
                                                <td>0.29</td>
                                                <td>0.74</td>
                                                <td>1.08</td>
                                                <td>0.85</td>
                                                <td>1.02</td>
                                                <td>0.75</td>
                                            </tr>
                                        </tbody>
                                    </table>
		                        </div>
	    	                    <div id="menu2" className="container tab-pane fade"><br />
     		                    <br />
                                    <h3>Available Finish Options</h3>
		                            <br />
    		                        <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/woodwool/color-shades.pdf" className="brochure">Download</a>
	                            </div>
    	                        <div id="menu3" className="container tab-pane fade"><br />
		                        <br />
                                    <h3>ASI Wall Panels : Datasheet</h3>
	    	                        <br />
		                            <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/woodwool/wall-ceiling.pdf" className="brochure">Download</a>
                                </div>
                            </div>
	                    </div>			
	                    <div className="col-sm-12 featured-products">
	                        <div className="row pb-5">
		                        <div className="col-md-4 col-sm-12 featured-card">
		                            <div className="card text-center">
				                        <img className="card-img-top" src={Woodwoolcard} alt="Card cap" />
  			                            <div className="card-block">
    			                            <h4 className="card-title">Woodwool<br /><span>Wall</span> Panels</h4>
    			                            <p className="card-text">We are the leader in eco-friendly, high-performance, cost-effective acoustical-panel products category</p>
    			                            <Link to="/woodwool-wall"className="btn btn-danger">For More</Link>
  			                            </div>
			                        </div>
		                        </div>
		                        <div className="col-md-4 col-sm-12 featured-card">
    		                        <div className="card text-center">
	    			                    <img className="card-img-top" src={Woodwoolcard2} alt="Card cap2" />
  		    	                        <div className="card-block">
    		    	                        <h4 className="card-title">Woodwool<br /><span>Ceiling</span> Panels</h4>
    			                            <p className="card-text">Envirocoustic Wood wool ceiling panels can transform the aesthetics and acoustics of any interior space.</p>
    			                            <Link to="/woodwool-ceiling" className="btn btn-danger">For More</Link>
  			                            </div>
			                        </div>
		                        </div>
		                        <div className="col-md-4 col-sm-12 featured-card">
		                            <div className="card text-center">
				                        <img className="card-img-top" src={Woodwoolcard3} alt="Card cap3" />
  			                            <div className="card-block">
    			                            <h4 className="card-title">Woodwool<br /><span>Cloud / Baffles</span></h4>
    			                            <p className="card-text">Cementitious Wood-Fiber Acoustic Ceiling Clouds / Baffles provide a high level of acoustical absorption</p>
    			                            <Link to="/woodwool-cloud" className="btn btn-danger">For More</Link>
  			                            </div>
			                        </div>
		                        </div>
	                        </div>	
	                    </div>
	                    <hr />
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default woodwoolcloud;