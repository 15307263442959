import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import './acoustical-stretch-fabric.css';
import Acousticalstretchfabric from '../../images/acfabric/banner1.webp';
import Mobsidebar from '../mobile-sidebar';
import Card1 from '../../images/fabwall/fabric3.webp';
import Card2 from '../../images/fabwall/fabric4.webp';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADlibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import ScrollToTopButton from '../ScrollButton';

function acousticfabric() {
    return (

        <>
            <Helmet>
                <title>Acoustic stretch fabric materials</title>
                <meta name="description" content="Acoustical stretch fabric systems are in high demand in office spaces, lobbies, classNamerooms, theaters and more." />
                <meta name="robots" content="index, follow" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="in" />
                <meta name="geo.region" content="india" />
                <meta property="og:title" content="AcoustyFABRIC" />
                <meta property="og:description" content="We manufacture all kind of interior panel and upholstery fabrics using finest quality of Polyster and are available in over 500 different varieties." />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/acfabric/banner1.webp" />
                <meta property="og:locale" content="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/acoustical-stretch-fabric" hreflang="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/acoustical-stretch-fabric" />
            </Helmet>
            <Header />
            <section className="content" id="stretchfabric ">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 sidemenu">
                            <Sidebar />
                        </div>
                        <div className="col-sm-9 product-page nopaddding">
                            <div className="container">
                                <div className="megamenu">
                                    <Megamenu />
                                </div>
                                <div className="col-sm-12 heading-fsorb" style={{ padding: "0px" }}>
                                    <img src={Acousticalstretchfabric} alt="Acoustical Stretch Fabric" />
                                    <div className="col-sm-12 fabric-body">
                                        <h1>Acousty<span className="fabriwall">FABRIC</span> </h1>
                                        <p>Although most of the fabric goes well with the FabriWALL System, it is important to choose the right fabric. We manufacture all kind of interior panel and upholstery fabrics using finest quality of Polyster and are available in over 500 different varieties.
                                            These fabrics are tested and have gained wide appreciation, owing to their exquisite designs, finish, tear strength and color fastness </p>
                                        <div className="row style-cards justify-content-center">
                                            <div className="col-md-5 col-sm-12 featured-card">
                                                <div className="card">
                                                    <div className="row nomargin" >
                                                        <div className="col-sm-12 col-md-12 col-12">
                                                            <img className="card-img-top cover-img1" src={Card1} alt="Card cap1" />
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <p><span type="button" onclick="window.open('https://acousticalsurfaces.in/pdfs/fabric-brochure-small.pdf')" >See more </span></p>
                                                        <hr />
                                                        <h1>INDIAN<br /> Collection</h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-5 col-sm-12 featured-card">
                                                <div className="card">
                                                    <div className="row nomargin">
                                                        <div className="col-sm-6 col-12">
                                                            <img className="card-img-top cover-img2" src={Card2} alt="Card cap2" />
                                                        </div>
                                                        <div className="col-sm-6 col-12 para">
                                                            <p>Guilford of Maine速 and Terratex速 Fabric Selection</p>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <p><Link to="/acoustyfabric-usa"><span>See more</span></Link></p>
                                                        <hr />
                                                        <h1>USA<br /> Collection</h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br /><br />
                                <div className="col-sm-12">
                                    <p>Acoustical stretch fabric systems are in high demand in office spaces, lobbies, classNamerooms, theaters and more. They provide sound control that will last for years, making the spaces truly functional</p>
                                </div>
                                <br />
                                <div className='col-sm-12 application'>
                                    <h1>APPLICATION</h1>

                                    <div className='col-sm-12'>
                                        <div className='cinemahall'>
                                            <p>CINEMA HALL</p>
                                        </div>
                                    </div>

                                    <br />
                                    <div className='col-sm-12'>
                                        <div className='officerooms'>
                                            <p>OFFICE ROOMS & HALLS</p>
                                        </div>
                                    </div>

                                    <br />
                                    <div className='col-sm-12'>
                                        <div className='auditoriam'>
                                            <p>AUDITORIUM & EDUCATION</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="row resources">
                                <div className="col-xl-12 col-lg-12 col-sm-12">
                                    <h2>ADDITIONAL RESOURCES</h2>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-12">
                                    <h6 style={{ textalign: "center" }}>Creating Better-Sounding Rooms</h6>
                                    <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms" target="_blank" rel="noopener noreferrer" />
                                    <div className="butn"><Link to="/bs-rooms-guide">Download eBook</Link></div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-12">
                                    <h6 style={{ textalign: "center" }}>Solutions to Common Noise Problems</h6>
                                    <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems" target="_blank" rel="noopener noreferrer" />
                                    <div className="butn"><Link to="/noise-problems-guide">Download eBook</Link></div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-12">
                                    <h6 style={{ textalign: "center" }}><br />CAD, CSI, & Revit Library </h6>
                                    <img className="hvr-grow" src={CADlibrary} alt="CAD Library" target="_blank" rel="noopener noreferrer" />
                                    <div className="butn"><Link to="/cad-library-guide">Access Product Cad Files</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mobsidebar'>
                        <div className="col-sm-12">
                            <div className="container">
                                <div className="row">
                                    <Mobsidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default acousticfabric;