import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Doors1 from '../../images/inner/soundproof-door-piano-room-st-olaf1-200x200.webp';
import Doors2 from '../../images/inner/doorseal31-200x200.webp';
import Doors3 from '../../images/inner/doorseal21-200x200.webp';
import Doors4 from '../../images/inner/599c1-200x200.webp';
import Doors5 from '../../images/inner/195c1-200x200.webp';
import './soundproof-doors.css';
import ScrollToTopButton from '../ScrollButton';
function soundproofdoors() {
    return (
      
    <>
    <Helmet>
    <title>Soundproof interior and exterior acoustic doors</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Soundproof Doors" />
    <meta property="og:description" content="If the door does not meet the same acoustical requirements as the rest of the room, then sound will easily pass through it to adjoining spaces." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/soundproof-door-piano-room-st-olaf1-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/doors" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/soundproof-doors" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/soundproof-doors" />
    </Helmet>
    <Header />
    <section className="content" id="doors">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Soundproof Doors</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p><strong>Watch our soundproof door installation video below. Click the video to play/pause.</strong></p>
                                    <p><iframe src="https://www.youtube.com/embed/uRStMyJL9Hw" width="640" height="360" frameborder="0" allowfullscreen="allowfullscreen" title="soundproofing doors"></iframe></p>
                                </article>
                                <article className="clearfix">
                                    <p>A soundproof room is only as good as its components. If the door does not meet the same acoustical requirements as the rest of the room, then sound will easily pass through it to adjoining spaces. When fitted with the right door and sealing systems, noise will be contained and controlled.</p>
                                    <p>Soundproof interior and exterior acoustic doors are an ideal cost effective solution for recording studios, hotels, music rooms, conference rooms, or any space that needs to be soundproof. Our pre-hung acoustic doors feature multilayer construction in a variety of wood options suitable for indoor applications or metal for exterior use. If the door itself is not a problem, it could be that sound is leaking through the space between to door and the jamb. To solve this problem, we supply a full line of soundproof door seal kits and other products to keep noise contained. These standard and adjustable door seals are economical and easy to install.</p>
                                    <p>Our most popular Door and Seal products are listed above. Please <Link to="/contact-us">contact us</Link> today to learn more.</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>View Our Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Doors1} alt="Soundproof Doors1" />
                                </Link>
                                <h5>Studio 3D doors</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Doors2} alt="Soundproof Doors2" />
                                </Link>
                                <h5>Heavy Duty Door seal Kits</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Doors3} alt="Soundproof Doors3" />
                                </Link>
                                <h5>Standard Door Seal Kits</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Doors4} alt="Soundproof Doors4" />
                                </Link>
                                <h5>Adjustable Jamb Seals</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Doors5} alt="Soundproof Doors5" />
                                </Link>
                                <h5>Magnetic Astragal</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default soundproofdoors; 