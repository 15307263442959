import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Inner1 from '../../images/inner/3U8A9377-200x200.webp';
import Inner2 from '../../images/inner/3U8A9397-200x200.webp';
import Inner3 from '../../images/inner/3U8A9541-200x200.webp';
import Inner4 from '../../images/inner/B1B1184-200x200.webp';
import Inner5 from '../../images/inner/B1B1271-200x200.webp';
import Inner6 from '../../images/inner/EC-WW-Office-Edited-2-200x200.webp';
import Inner7 from '../../images/inner/Edit-024-Hex-World-200x200.webp';
import Inner8 from '../../images/inner/3.webp';
import Inner9 from '../../images/inner/4a-clip-200x200.webp';
import Inner10 from '../../images/inner/5a-200x200.webp';
import Inner11 from '../../images/inner/015-200x200.webp';
import Mobsidebar from '../mobile-sidebar';
import Woodwool1 from '../../images/inner/B1B1259-lighter-350x194.webp';
import './woodwool-board';
import ScrollToTopButton from '../ScrollButton';
function woodwoolboard() {
    return (
      
    <>
    <Helmet>
    <title>Wood Wool Board Acoustic Ceiling and Wall Panels</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Cementitious Wood-Fiber Acoustic Ceiling and Wall Panels" />
    <meta property="og:description" content="Wood Wool is now a leader in the eco-friendly, high-performance, cost-effective acoustical-panel products category."/>
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/B1B1259-lighter-350x194.webp"/>
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/woodwool-board" hreflang="en-ae" />    
    <link rel="alternate" href="https://www.acousticalsurfaces.in/woodwool-board" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/woodwool-board" />
    </Helmet>
    <Header />
    <section className="content" id="woodwoolboard">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Envirocoustic™ Wood Wool</h1>
                        <div className="row">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/B1B1259-lighter-1024x569.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Woodwool1} alt="Woodwool Board" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Cementitious Wood-Fiber Acoustic Ceiling and Wall Panels</h2>
                                <p>Wood Wool is now a leader in the eco-friendly, high-performance, cost-effective acoustical-panel products category. Envirocoustic™ performs well by absorbing sound while thermally insulating; ecologically, acoustic wool is simple to produce; and panels are available in many size and color variations that can be designed to fit nearly any décor.</p>
                                <p>These eco-friendly cementitious wood-fiber panels not only liven up a room with design variety, but acoustic wool panels also reduce echo and reverberation through sound absorption. Wood-fiber panels can transform the aesthetics and acoustics of any interior space.</p>
                                <p>Features Benefits</p>
                                <ul style={{liststyle: "none"}}>
                                    <li>High quality wood wool:
                                    <ul style={{liststyle: "none", marginleft: "1em", fontsize: ".8em"}}>
                                        <li>Durable</li>
                                        <li>Consistent finish color</li>
                                        <li>Consistent thickness (+/-.005″) dimensions</li>
                                        <li>Clean corners</li>
                                        <li>Square panels</li>
                                        <li>Less dust than competition</li>
                                    </ul>
                                    </li>
                                    <li>Cost-effective – lower cost than other solutions</li>
                                    <li>Class A Fire Rated</li>
                                    <li>Low VOC</li>
                                    <li>Manufactured from 3 simple ingredients: wood fiber, cement, water</li>
                                    <li>Versatile – ceiling or wall mount as a surface finish</li>
                                    <li>Paintable, with wide range of SonoKote™ colors</li>
                                    <li>An effective sound absorber</li>
                                </ul>
                                <p></p>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link target="_blank" to="https://acousticalsurfaces.in/pdfs/Envirocoustic-Wood-Wool-Product-Specification-Sheet-1.pdf" className="ico-misc">Specification Sheet</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-xl-8 col-lg-8 col-sm-12 ytvid">
                                <h4>Installation Video</h4>
                                <iframe allowfullscreen="allowfullscreen"  src="https://www.youtube.com/embed/Kk7Rsxz1078" width="600" height="380" Title="Woodwool Board"></iframe>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 fleximages">
                                <h4>Product Photos</h4>
                                <div className="imagebox">
                                    <img src={Inner1} alt="Woodwool1" className="img-fluid" />
                                </div>
                                <div className="imagebox">
                                    <img src={Inner2} alt="Woodwool2" className="img-fluid" />
                                </div>
                                <div className="imagebox">
                                    <img src={Inner3} alt="Woodwool3" className="img-fluid" />
                                </div>
                                <div className="imagebox">
                                    <img src={Inner4} alt="Woodwool4" className="img-fluid" />
                                </div>
                                <div className="imagebox">
                                    <img src={Inner5} alt="Woodwool5" className="img-fluid" />
                                </div>
                                <div className="imagebox">
                                    <img src={Inner6} alt="Woodwool6" className="img-fluid" />
                                </div>
                                <div className="imagebox">
                                    <img src={Inner7} alt="Woodwool7" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 fleximages">
                                <h4>Envirocoustic Wood Wool Varieties</h4>
                                <div className="imagebox">
                                    <img src={Inner8} alt="Woodwool8" className="img-fluid" />
                                </div>
                                <div className="imagebox">
                                    <img src={Inner9} alt="Woodwool9" className="img-fluid" />
                                </div>
                                <div className="imagebox">
                                    <img src={Inner10} alt="Woodwool10" className="img-fluid" />
                                </div>
                                <div className="imagebox">
                                    <img src={Inner11} alt="Woodwool11" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <h3>Product Specifications</h3>
                                    <tbody>
                                        <tr>
                                            <th>Applications</th>
                                            <td>Schools and civic centers (gymnasiums, classrooms, auditoriums, music practice and band rooms), restaurants and bars, sports and entertainment venues, convention centers, houses of worship, shooting ranges, manufacturing and industrial facilities, parking garages, airports, and more.</td>
                                        </tr>
                                        <tr>
                                            <th>Sizes</th>
                                            <td> 
                                                <p>
                                                    Sizes:<br />
                                                    1" Thick Wall Ceiling Direct-Attach Panel Sizes – Square or<br />
                                                    Beveled Edge<br />
                                                    • 23.75" x 23.75"<br />
                                                    • 23.75" x 47.75"<br />
                                                    • 23.75" x 95.5"<br />
                                                    1" Thick Drop-In Ceiling Tile Sizes - Square or Tegular Edge<br />
                                                    • 23.75" x 23.75"<br />
                                                    • 23.75" x 47.75"<br />
                                                    1" Thick Hexagonal – Deep-Beveled Edge<br />
                                                    • 20" side-to-side (six-sided) dimension 
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Panel Installation</th>
                                            <td>
                                                <p>
                                                    • Direct attach to wall<br />
                                                    • Direct attach to ceiling<br />
                                                    • Drop in ceiling tiles<br />
                                                    • Direct attach to furring strips/hat channel/drywall T-Grid 
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Lead Times</th>
                                            <td>
                                                <p>
                                                    • 1-2 weeks for stock items<br />
                                                    • 6-8 weeks for custom projects<br />
                                                    • 10-12 weeks for large projects 
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Flammability</th>
                                            <td>Class A (Per ASTM E-84)</td>
                                        </tr>
                                        <tr>
                                            <th>Colors</th>
                                            <td>Natural, White, SonoKote™ custom colors </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <h4>Acoustical Test Results</h4>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td width="87">Panel</td>
                                            <td width="315">Test</td>
                                            <td width="87">Result</td>
                                        </tr>
                                        <tr>
                                            <td>1″</td>
                                            <td>A Mount (flat against wall / ceiling)</td>
                                            <td>0.45</td>
                                        </tr>
                                        <tr>
                                            <td>1″</td>
                                            <td>C Mount (1” CFAB behind- flat on wall / ceiling)</td>
                                            <td>0.75</td>
                                        </tr>
                                        <tr>
                                            <td>2″</td>
                                            <td>A Mount (flat against wall / ceiling)</td>
                                            <td>0.65</td>
                                        </tr>
                                        <tr>
                                            <td>2″</td>
                                            <td>C Mount (1” CFAB behind- flat on wall / ceiling)</td>
                                            <td>0.75</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>(2″ Results from double 1″ layers)</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default woodwoolboard;
    