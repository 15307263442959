import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Mobsidebar from '../mobile-sidebar';
import Megamenu from '../megamenu';
import Acousticsquilted from '../../images/inner/quilted11.webp';
import Noisebarrier from '../../images/inner/standard.webp';
import Acoustics from '../../images/inner/curtab.webp';
import './absorptive-quilted-curtains.css';
import ScrollToTopButton from '../ScrollButton';
function absorptivequilted() {
    return (
      
    <>
    <Helmet>
    <title>Absorptive Quilted Curtains - Quilted Curtains Reduce Noise</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta property="og:title" content="Quilted Curtain S.T.O.P." />
    <meta property="og:description" content="Absorptive/Noise Barrier Quilted Curtains" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/quilted11.webp" />
    <meta property="og:locale" content="en-in" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/absorptive-quilted-curtains" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/absorptive-quilted-curtains" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/absorptive-quilted-curtains" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="curtains">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Quilted Curtain S.T.O.P.</h1>
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/quilted11.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Acousticsquilted} alt="acoustics" />
                                </Link>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Absorptive/Noise Barrier Quilted Curtains</h2>
                                <ul style={{marginLeft: "1em"}}>
                                    <li>For Unusual Conditions</li>
                                    <li>Cost Effective</li>
                                    <li>Water &amp; Chemical Resistant</li>
                                    <li>Exterior Applications</li>
                                </ul>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Product Specs</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>Material</th>
                                            <td>Foam or fiberglass core, faced with quilted vinyl coated fabric.</td>
                                        </tr>
                                        <tr>
                                            <th>Pattern</th>
                                            <td>Quilted pattern </td>
                                        </tr>
                                        <tr>
                                            <th>Features</th>
                                            <td>Effective and durable absorber with mass loaded vinyl barrier option. </td>
                                        </tr>
                                        <tr>
                                            <th>Applications</th>
                                            <td>Effective solution to a wide range of unusual problems. Machinery and work area enclosures. </td>
                                        </tr>
                                        <tr>
                                            <th>Thickness</th>
                                            <td>1″, 2″ </td>
                                        </tr>
                                        <tr>
                                            <th>Sizes</th>
                                            <td>BSC-25 Curtain (Quilting on Both Sides) – Standard: 48″ Wide and Lengths up to 25′
                                            <br /> BBC-13 Curtain (Quilting on One Side – Standard: 54″ Wide and Lengths up to 25′
                                            <br /> Custom sizes also available.
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Colors</th>
                                            <td>Standard PVC Facing – Silver
                                            <br /> Optional PVC facing – Black, Beige, White
                                            <br /> Sailcloth Colors
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Flammability</th>
                                            <td>ASTM E84, className A. Flame Spread: 23; Smoke Developed: 30</td>
                                        </tr>
                                        <tr>
                                            <th>Installation</th>
                                            <td>Hook and loop fasteners, grommet hangers, curtain spanport hardware.
                                            <br /> GROMMETS: Standard Brass. (Stainless steel upgrade available upon request) ID: 9/16″ Grommet Center 1 ¼″ down from top of panel
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>GROMMETS</th>
                                            <td>Standard Brass. (Stainless steel upgrade available upon request) ID: 9/16″ Grommet Center 1 ¼″ down from top of panel </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br />
                        <h3>Testimonials</h3>
                        <div className="row pad">
                            <ul style={{marginLeft: "1.5em"}}>
                                <li style={{listStyle: "none"}}>
                                    <Link to="https://acousticalsurfaces.in/pdfs/Black_Nexxus.pdf" style={{color: "#0059a5"}} target="_blank" rel="noopener noreferrer">Acoustical Surface’s BSC-T25 Quilted Fiberglass Curtain Panels</Link>
                                </li>
                                <li style={{listStyle: "none"}}>
                                    <Link  to="https://acousticalsurfaces.in/pdfs/Quilted-Curtains-Reduce-Noise.pdf" style={{color: "#0059a5"}} target="_blank" rel="noopener noreferrer">Quilted Curtains Reduce Noise</Link>
                                </li>
                                <li style={{listStyle: "none"}}>
                                    <Link to="https://acousticalsurfaces.in/pdfs/Office.pdf" style={{color: "#0059a5"}} target="_blank" rel="noopener noreferrer">Moveable Office Wall…</Link>
                                </li>
                            </ul>
                        </div>
                        <h3>Sample Colors:</h3>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <Link to="../../images/inner/standard.webp" className="example-image-link" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Noisebarrier} alt="Noise Barrier Quilted Curtains" />
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                        <th colSpan="8">CURTAIN S.T.O.P. Sound Transmission Loss ASTM E90</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader" style={{fontweight: "bold"}}>
                                            <td>Frequency</td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>STC</td>
                                        </tr>
                                        <tr>
                                            <td>BSC-25 w/ 1lb. Barrier</td>
                                            <td>12</td>
                                            <td>10</td>
                                            <td>27</td>
                                            <td>40</td>
                                            <td>44</td>
                                            <td>43</td>
                                            <td style={{color: "#cc0000"}}>29</td>
                                        </tr>
                                        <tr>
                                            <td>BSC-25 w/ 2lb. Barrier</td>
                                            <td>19</td>
                                            <td>22</td>
                                            <td>28</td>
                                            <td>40</td>
                                            <td>56</td>
                                            <td>61</td>
                                            <td style={{color: "#cc0000"}}>33</td>
                                        </tr>
                                        <tr>
                                            <td>BBC-13 w/ 1lb. Barrier</td>
                                            <td>11</td>
                                            <td>10</td>
                                            <td>24</td>
                                            <td>30</td>
                                            <td>35</td>
                                            <td>35</td>
                                            <td style={{color: "#cc0000"}}>27</td>
                                        </tr>
                                        <tr>
                                            <td>BBC-13 w/ 2lb. Barrier</td>
                                            <td>19</td>
                                            <td>22</td>
                                            <td>28</td>
                                            <td>40</td>
                                            <td>56</td>
                                            <td>61</td>
                                            <td style={{color: "#cc0000"}}>33</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                        <th colSpan="8">CURTAIN S.T.O.P.: Sound Absorption Coefficients</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader" style={{fontweight: "bold"}}>
                                            <td>Frequency</td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>NRC</td>
                                        </tr>
                                        <tr>
                                            <td>1″ Fiberglass</td>
                                            <td>0.12</td>
                                            <td>0.47</td>
                                            <td>0.85</td>
                                            <td>0.84</td>
                                            <td>0.64</td>
                                            <td>0.62</td>
                                            <td style={{color: "#cc0000"}}>0.70</td>
                                        </tr>
                                        <tr>
                                            <td>2″ Fiberglass</td>
                                            <td>0.19</td>
                                            <td>0.99</td>
                                            <td>0.96</td>
                                            <td>0.80</td>
                                            <td>0.57</td>
                                            <td>0.33</td>
                                            <td style={{color: "#cc0000"}}>0.85</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <Link to="images/inner/curtab.webp" className="example-image-link" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Acoustics} alt="acoustics" />
                                </Link>
                            </div>
                        </div>
                        <hr className="d-none d-md-block d-lg-block d-xl-block" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default absorptivequilted;