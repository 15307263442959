import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import './acfabric-drift.css';
import Mobsidebar from '../mobile-sidebar';
import Dr1 from '../../images/acfabric/dr1.jpg';
import Dr2 from '../../images/acfabric/dr2.jpg';
import Dr3 from '../../images/acfabric/dr3.jpg';
import Dr4 from '../../images/acfabric/dr4.jpg';
import Dr5 from '../../images/acfabric/dr5.jpg';
import Dr6 from '../../images/acfabric/dr6.jpg';
import Dr7 from '../../images/acfabric/dr7.jpg';
import Dr8 from '../../images/acfabric/dr8.jpg';
import Dr9 from '../../images/acfabric/dr9.jpg';
import ScrollToTopButton from '../ScrollButton';
function acfabricdrift() {
    return (
      
    <>
    <Helmet>
    <title>Acoustic of Maine Drift Fabric - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="AcoustyFABRIC Drift" />
    <meta property="og:description" content="Guilford of Maine Crosstown 2526 Fabric Facings" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/acfabric/ct1.jpg" />
    <meta property="og:locale" content="en-in" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/acfabric-drift" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/acfabric-drift" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acfabric-drift" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="acfabricdrift">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
            	<div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
	                <div className="col-sm-12 heading-fsorb">				
                		<h1>Acousty<span className="fabriwall">FABRIC</span> Drift</h1>
                        <h4 className="mb-0"> <b>Guilford of Maine Drift 2539 Fabric Facings</b></h4>
		                <p><span className="color:red;">(Colors Shown are Approximate Match to Material)</span></p>
	                <br />
		                <div className="col-sm-3 cloths p-0"><button>Product Specifications</button></div>
	                </div>
					<br /><br />
		            <div className="col-sm-12 cloths">
		                <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Dr1} alt="Pebble 010" />
                                <p className="text-center">Pebble 010</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Dr2} alt="Dusty Miller 011" />
                                <p className="text-center">Dusty Miller 011</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Dr3} alt="Alabaster" />
                                <p className="text-center">Alabaster 020</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Dr4} alt="Sprout 030" />
                                <p className="text-center">Sprout 030</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Dr5} alt="Chamois 031" />
                                <p className="text-center">Chamois 031</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Dr6} alt="Mocha 032" />
                                <p className="text-center">Mocha 032</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Dr7} alt="Graphite 040" />
                                <p className="text-center">Graphite 040</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Dr8} alt="Storm 041" />
                                <p className="text-center">Storm 041</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Dr9} alt="Slate 050" />
                                <p className="text-center">Slate 050</p>
                            </div>
                            <div className=" col-sm-2 p-0 mx-2"></div>
                        </div>	
            		</div>
		            <br />
		            <hr />
                    <div className="col-12 d-flex justify-content-center backbtn">
                    	<Link to="/acoustyfabric"><button>Back to Acousty<span style={{color: "red"}}>FABRIC</span></button></Link>
                    </div>
                    <hr />
            		<div className="col-sm-12 application">
            			<h1>APPLICATION</h1>
		                <div className="hover14 text-center justify-content-center">
		                <br />
  		                    <div className="col-sm-12">
			                    <figure className="m-0"><div className="tinted-image">CINEMA HALL</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image2">OFFICE ROOMS & HALLS</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image3">AUDITORIUM & EDUCATION</div></figure>
 		                    </div>
                        <br />
                        </div>
                    </div>
		            <br />
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acfabricdrift;