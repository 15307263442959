import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import C1 from '../../images/acfabric/c1.jpg';
import C2 from '../../images/acfabric/c2.jpg';
import C3 from '../../images/acfabric/c3.jpg';
import C4 from '../../images/acfabric/c4.jpg';
import C5 from '../../images/acfabric/c5.jpg';
import C6 from '../../images/acfabric/c6.jpg';
import C7 from '../../images/acfabric/c7.jpg';
import C8 from '../../images/acfabric/c8.jpg';
import C9 from '../../images/acfabric/c9.jpg';
import C10 from '../../images/acfabric/c10.jpg';
import C11 from '../../images/acfabric/c11.jpg';
import C12 from '../../images/acfabric/c12.jpg';
import C13 from '../../images/acfabric/c13.jpg';
import C14 from '../../images/acfabric/c14.jpg';
import C15 from '../../images/acfabric/c15.jpg';
import C16 from '../../images/acfabric/c16.jpg';
import C17 from '../../images/acfabric/c17.jpg';
import C18 from '../../images/acfabric/c18.jpg';
import C19 from '../../images/acfabric/c19.jpg';
import C20 from '../../images/acfabric/c20.jpg';
import C21 from '../../images/acfabric/c21.jpg';
import C22 from '../../images/acfabric/c22.jpg';
import C23 from '../../images/acfabric/c23.jpg';
import C24 from '../../images/acfabric/c24.jpg';
import C25 from '../../images/acfabric/c25.jpg';
import C26 from '../../images/acfabric/c26.jpg';
import C27 from '../../images/acfabric/c27.jpg';
import C28 from '../../images/acfabric/c28.jpg';
import C29 from '../../images/acfabric/c29.jpg';
import C30 from '../../images/acfabric/c30.jpg';
import C31 from '../../images/acfabric/c31.jpg';
import C32 from '../../images/acfabric/c32.jpg';
import C33 from '../../images/acfabric/c33.jpg';
import C34 from '../../images/acfabric/c34.jpg';
import C35 from '../../images/acfabric/c35.jpg';
import C36 from '../../images/acfabric/c36.jpg';
import C37 from '../../images/acfabric/c37.jpg';
import C38 from '../../images/acfabric/c38.jpg';
import C39 from '../../images/acfabric/c39.jpg';
import C40 from '../../images/acfabric/c40.jpg';
import C41 from '../../images/acfabric/c41.jpg';
import C42 from '../../images/acfabric/c42.jpg';
import './acfabric-anchorage.css';
import ScrollToTopButton from '../ScrollButton';
function acfabric() {
    return (
      
    <>
<Helmet>
<title>Acoustic of Maine Anchorage Fabric - Acoustical Surfaces</title>
<meta name="description" content="" />
<meta property="og:title" content="AcoustyFABRIC Anchorage" />
<meta property="og:description" content="Guilford of Maine Anchorage 2335 Fabric Facings" />
<meta property="og:image" content="https://www.acousticalsurfaces.in/images/acfabric/c1.jpg" />
<meta property="og:locale" content="en-in" />
<meta name="geo.placename" content="India"/>
<meta http-equiv="content-language" content="in" />
<meta name="geo.region" content="india" />
<link rel="canonical" href="https://www.acousticalsurfaces.in/acfabric-Anchorage" />
<link rel="alternate" href="https://www.acousticalsurfaces.in/acfabric-Anchorage" hreflang="en-in" />
<link rel="alternate" href="https://www.acousticalsurfaces.ae/acfabric-Anchorage" hreflang="en-ae" />
</Helmet>
<Header />
<section className="content" id="acfabricanchorage">
    <div className="container">
        <div className="row">
            <div className="col-sm-3 sidemenu">
                <Sidebar />
            </div>
        	<div className="col-sm-9 product-page nopaddding">
                <div className="container"> 
                    <div className="megamenu">
                        <Megamenu />
				    </div>
                </div>
				<div className="col-sm-12 heading-fsorb">				
		            <h1>Acousty<span className="fabriwall">FABRIC</span> Anchorage </h1>
            		<h4 className="mb-0"> <b>Guilford of Maine Anchorage 2335 Fabric Facings</b></h4>
		            <p><span className="color:red;">(Colors Shown are Approximate Match to Material)</span></p>
	                <br />
		            <div className="col-sm-3 cloths p-0">
                        <button>Product Specifications</button>
                    </div>
	            </div>
		        <br /><br />
		        <div className="col-sm-12 cloths">
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C1} alt="Brich" />
                            <p className="text-center">Birch</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C2} alt="Vanilla" />
                            <p className="text-center">Vanilla</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C3} alt="Angora" />
                            <p className="text-center">Angora</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C4} alt="Goose" />
                            <p className="text-center">Goose</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C5} alt="Fern" />
                            <p className="text-center">Fern</p>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                    	<div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C6} alt="Fossil" />
                            <p className="text-center">Fossil</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C7} alt="Graphite" />
                            <p className="text-center">Graphite</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C8} alt="Onyx" />
                            <p className="text-center">Onyx</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C9} alt="Asteroid" />
                            <p className="text-center">Asteroid</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C10} alt="Wolf" />
                            <p className="text-center">Wolf</p>
                        </div>
                    </div>	
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C11} alt="Slate" />
                            <p className="text-center">Slate</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C12} alt="Turquoise" />
                            <p className="text-center">Turquoise</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C13} alt="Quarry Blue" />
                            <p className="text-center">Quarry Blue</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C14} alt="Cobalt" />
                            <p className="text-center">Cobalt</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C15} alt="Midnight" />
                            <p className="text-center">Midnight</p>
                        </div>
                    </div>	
                    <div className="row d-flex justify-content-center mb-2">
                    	<div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C16} alt="Deep Water" />
                            <p className="text-center">Deep Water</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C17} alt="Lapis" />
                            <p className="text-center">Lapis</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C18} alt="Lemon" />
                            <p className="text-center">Lemon</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C19} alt="Orchid" />
                            <p className="text-center">Orchid</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C20} alt="Pool" />
                            <p className="text-center">Pool</p>
                        </div>
                    </div>	
                    <div className="row d-flex justify-content-center mb-2">
                    	<div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C21} alt="Thistle" />
                            <p className="text-center">Thistle</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C22} alt="Waterfall" />
                            <p className="text-center">Waterfall</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C23} alt="Sea" />
                            <p className="text-center">Sea</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C24} alt="Sunshine" />
                            <p className="text-center">Sunshine</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C25} alt="Straw" />
                            <p className="text-center">Straw</p>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                    	<div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C26} alt="Goldenrod" />
                            <p className="text-center">Goldenrod</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C27} alt="Amber" />
                            <p className="text-center">Amber</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C28} alt="Cumin" />
                            <p className="text-center">Cumin</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C29} alt="Eucalyptus" />
                            <p className="text-center">Eucalyptus</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C30} alt="Green Olive" />
                            <p className="text-center">Green Olive</p>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                    	<div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C31} alt="Willow" />
                            <p className="text-center">Willow</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C32} alt="Green Apple" />
                            <p className="text-center">Green Apple</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C33} alt="Pine Needles" />
                            <p className="text-center">Pine Needles</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C34} alt="Coffee Bean" />
                            <p className="text-center">Coffee Bean</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C35} alt="Pumpkin" />
                            <p className="text-center">Pumpkin</p>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                    	<div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C36} alt="Geranium" />
                            <p className="text-center">Geranium</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C37} alt="Red Delicious" />
                            <p className="text-center">Red Delicious</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C38} alt="Poppy" />
                            <p className="text-center">Poppy</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C39} alt="Magenta" />
                            <p className="text-center">Magenta</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C40} alt="Mulberry" />
                            <p className="text-center">Mulberry</p>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mb-2">
                    	<div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C41} alt="Henna" />
                            <p className="text-center">Henna</p>
                        </div>
                        <div className="col-2 col-sm-2 p-0 mx-2">
                            <img className="w-100" src={C42} alt="Aubergine" />
                            <p className="text-center">Aubergine</p>
                        </div>
                        <div className="col-2 col-sm-6 p-0 mx-4"></div>
                   </div>
				</div>
		        <br />
		        <hr />
                <div className="col-12 d-flex justify-content-center backbtn">
                	<Link to="/acoustyfabric"><button>Back to Acousty<span style={{color: "red"}}>FABRIC</span></button></Link>
                </div>
                <hr />
                <div className="col-sm-12 application">
			        <h1>APPLICATION</h1>
	                <div className="hover14 text-center justify-content-center">
		            <br />
  		                <div className="col-sm-12">
			                <figure className="m-0"><div className="tinted-image">CINEMA HALL</div></figure>
 		                </div>
                    <br />
  		                <div className="col-sm-12">
    	                    <figure className="m-0"><div className="tinted-image2">OFFICE ROOMS & HALLS</div></figure>
 		                </div>
                    <br />
  		                <div className="col-sm-12">
    	                    <figure className="m-0"><div className="tinted-image3">AUDITORIUM & EDUCATION</div></figure>
 		                </div>
                    <br />
	                </div>
                </div>
		        <br />
            </div>
        </div>
        <div className='mobsidebar'>
            <div className="col-sm-12">
                <div className="container">
                    <div className="row">
                        <Mobsidebar />                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ScrollToTopButton />
</section>
<Footer />
</>
);
}
export default acfabric;