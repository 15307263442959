import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Silkmetal1 from '../../images/inner/silk-metal-tiles-sm.webp';
import Silkmetal2 from '../../images/inner/silk-metal-ceiling-tiles-lighted-200x200.webp';
import Silkmetal3 from '../../images/inner/Conf-Room-3-People-200x200.webp';
import Silkmetal4 from '../../images/inner/silk-metal-panels-hallway-200x200.webp';
import Silkmetal5 from '../../images/inner/swimming-pool-silk-metal-baffles-200x200.webp';
import Silkmetal6 from '../../images/inner/conference-room-silk-metal-baffles-200x200.webp';
import Silkmetal7 from '../../images/inner/curved-hanging-silk-metal-200x200.webp';
import Silkmetal8 from '../../images/inner/silk-metal-side-a-200x200.webp';
import Silkmetal9 from '../../images/inner/silk-metal-side-b-200x200.webp';
import Silkmetal10 from '../../images/inner/silk-metal-lay-in-ceiling-installation-drawing-200x200.webp';
import Silkmetal11 from '../../images/inner/silk-metal-flat-200x200.webp';
import Silkmetal12 from '../../images/inner/standard-grid-reveal-edge-200x200.webp';
import './silk-metal-ceiling-tiles.css';
import ScrollToTopButton from '../ScrollButton';
function silkmetal() {
    return (
      
    <>
    <Helmet>
    <title>Silk Metal Ceiling Tiles & Wall Panels Reduce Echo and Sound Reflections</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Micro-Perforated Acoustical Ceiling & Wall Panels" />
    <meta property="og:description" content="Our unique Silk Metal Ceiling Tiles are state-of-the-art micro-perforated aluminum sound absorber panels that reduce echo and sound reflections." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/silk-metal-tiles-sm.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/silk-metal-ceiling-tiles" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/silk-metal-ceiling-tiles" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/silk-metal-ceiling-tiles" />
    </Helmet>
    <Header />
    <section className="content" id="silkmetal">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Silk Metal™</h1> 
                        <div className="row">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/silk-metal-tiles-sm.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Silkmetal1} alt="Silk Metal Tiles" />
                                </Link>
                                <div className="row col-md-12 col-lg-12 minigallery">
                                    <div className='col-lg-4 col-md-2'>
                                        <Link className="example-image-link" to="../../images/inner/silk-metal-ceiling-tiles-lighted-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" style={{margin: "5px", maxwidth: "4em"}} src={Silkmetal2} alt="Silk Metal Ceiling Tiles" />
                                        </Link>
                                    </div>
                                    <div className='col-lg-4 col-md-2'>
                                        <Link className="example-image-link" to="../../images/inner/Conf-Room-3-People-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" style={{margin: "5px", maxwidth: "4em"}} src={Silkmetal3} alt="Conf Room" />
                                        </Link>
                                    </div>
                                    <div className='col-lg-4 col-md-2'>
                                        <Link className="example-image-link" to="../../images/inner/silk-metal-panels-hallway-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" style={{margin: "5px", maxwidth: "4em"}} src={Silkmetal4} alt="Silk Metal Panel" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="row col-md-12 col-lg-12 minigallery">
                                    <div className='col-lg-4 col-md-2'>
                                        <Link className="example-image-link" to="../../images/inner/swimming-pool-silk-metal-baffles-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" style={{margin: "5px", maxwidth: "4em"}} src={Silkmetal5} alt="Swimming poll silk metal baffles" />
                                        </Link>
                                    </div>
                                    <div className='col-lg-4 col-md-2'>
                                        <Link className="example-image-link" to="../../images/inner/conference-room-silk-metal-baffles-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" style={{margin: "5px", maxwidth: "4em"}} src={Silkmetal6} alt="Conference room silk metal baffles" />
                                        </Link>
                                    </div>
                                    <div className='col-lg-4 col-md-2'>
                                        <Link className="example-image-link" to="../../images/inner/curved-hanging-silk-metal-200x200.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" style={{margin: "5px", maxwidth: "4em"}} src={Silkmetal7} alt="Curved hanging silk metal" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 text features">
                                <h2>Micro-Perforated Acoustical Ceiling & Wall Panels</h2>
                                <p>Our unique Silk Metal Ceiling Tiles are state-of-the-art micro-perforated aluminum sound absorber panels that reduce echo and sound reflections. These metal ceiling panels have an elegantly-smooth appearance, with an excellent NRC Rating of 0.80. Resembling smooth silk fabric, these affordable tegular or flat tiles install easily into any standard 15/16” ceiling grid.</p>
                                <h3>Features</h3>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>                                           
                                    <li>Tegular or flat lay-in ceilings</li>
                                    <li>Anodized custom printing available</li>
                                    <li>Cost-effective</li>
                                    <li>Installs into standard 15/16” grid</li>
                                    <li>Also available as custom wall panels</li>
                                    <li>Excellent NRC Rating – 0.80 NRC (per ASTM C423) with a 4” airspace, no liner needed</li>
                                    <li>Resembles smooth fabric surface in appearance</li>
                                </ul> 
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 text"> 
                                <h2>Applications</h2>
                            </div>
                            <div className="col-sm-4 text features">
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>Restaurants</li>
                                    <li>Pro Audio/Studios</li>
                                    <li>Home Theaters</li>
                                    <li>Ceilings</li>
                                    <li>Walls</li>
                                    <li>Hallways</li>
                                    <li>Public Spaces</li>
                                    <li>Offices</li>
                                </ul>
                            </div>
                            <div className="col-sm-4 text features">
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>Airports</li>
                                    <li>Concert Halls</li>
                                    <li>Night Clubs</li>
                                    <li>Practice Rooms</li>
                                    <li>Swimming Pools</li>
                                    <li>Cafeterias</li>
                                    <li>Shopping Malls</li>
                                </ul>
                            </div>
                            <div className="col-sm-4 text features">
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>Conference Rooms</li>
                                    <li>Classrooms</li>
                                    <li>Hospitals</li>
                                    <li>Lobbies</li>
                                    <li>Department Stores</li>
                                    <li>Exhibition Spaces</li>
                                    <li>Train Stations</li>
                                </ul>
                            </div>
                            <div className="col-sm-12 text features">
                                <h2>Silk Metal Panels</h2>
                                <ul style={{marginleft: "1em"}}>
                                    <li>Achieves acoustical value without acoustical liner</li>
                                    <li>Resembles smooth fabric surface in appearance</li>
                                    <li>Reversible surfaces</li>
                                    <li>Can be fabricated into various forms & sizes</li>
                                    <li>Tested as a “sheet good” to prove its electromagnetic wave attenuation characteristics</li>
                                    <li>The only surface material that has been tested to prove its AEMIC (acoustical & electromagnetic interference control) capabilities</li>
                                </ul>
                                <span>Silk Metal uses a patented angled micro-perforation method rather than traditional direct 90° punching methods. Silk Metal features an ideal surface to absorb wave energy generated by sound and electromagnetic waves, and for heat mitigation.</span>
                                <p></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/silk-metal-side-a-200x200.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Silkmetal8} alt="silk metal side a" />
                                </Link>
                                <span style={{float: "left", width: "100%", marginleft: "1em"}}>Side A</span>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/silk-metal-side-b-200x200.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Silkmetal9} alt="silk metal side b" />
                                </Link>
                                <span style={{float: "left", width: "100%", marginleft: "1em"}}>Side B</span>
                            </div>
                        </div>
                        <div className="line"></div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h2>Lay-in System</h2>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>Appearance</th>
                                            <td>Flat or Tegular</td>
                                        </tr>
                                        <tr>
                                            <th>Stock Colors & Sizes </th>
                                            <td>23¾″ × 23¾″, White – 3/8″ – Tegular Edge<br /> 
                                                23¾″ × 23¾″, Black – 5/8″ – Tegular Edge
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Maximum Panel Size</th>
                                            <td>26¾″ × 78¾″</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Standard Lay-In for 15/16″ Grid</h3>
                            </div>
                            <div className="col-sm-4">
                                <Link className="example-image-link" to="../../images/inner/silk-metal-lay-in-ceiling-installation-drawing-200x200.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Silkmetal10} alt="silk metal lay" />
                                </Link>
                                <p>Standard Lay-In for 15/16″ Grid</p>
                            </div>
                            <div className="col-sm-4">
                                <Link className="example-image-link" to="../../images/inner/silk-metal-flat-200x200.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Silkmetal11} alt="silk metal flat" />
                                </Link>
                                <p>Flat</p>
                            </div>
                            <div className="col-sm-4">
                                <Link className="example-image-link" to="../../images/inner/standard-grid-reveal-edge-200x200.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Silkmetal12} alt="reveal edge" />
                                </Link>
                                <p>Tegular</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="8">Silk Metal Ceiling Tile Sound Absorption Coefficients</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td></td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>NRC</td>
                                        </tr>
                                        <tr>
                                            <td>Silk Metal</td>
                                            <td>0.86</td>
                                            <td>0.94</td>
                                            <td>0.65</td>
                                            <td>0.77</td>
                                            <td>0.76</td>
                                            <td>0.75</td>
                                            <td className="emphasis">0.80</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default silkmetal; 