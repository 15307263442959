import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import ScrollToTopButton from '../ScrollButton';
function problem() {
    return (
      
    <>
    <Helmet>
    <title>What's Your Noise Problem?</title>
    <meta name="description" content="" />
    <meta name="robots" content="noindex, follow" />
    <meta property="og:title" content="" />
    <meta property="og:description" content="Identifying noise problems in different types of spaces can be difficult." />
    <meta property="og:image" content="" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/problem" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/problem" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.ae/problem" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="IN" />
    <meta name="geo.region" content="india" />
    </Helmet>
    <Header />
    <section className="content contact">
        <div className="container">
            <hr />
            <h2>What's Your Noise Problem?</h2>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <p>Identifying noise problems in different types of spaces can be difficult.</p>
                    <p>Don't let acoustical engineering and design setbacks negatively impact the quality of your sound. Fill out this form to learn more about how our expert team can help you identify your noise problem, and find a solution to meet your needs.</p>
                    <p>Thank you for visiting us. Please let us know what kind of information you are looking for by filling the form. We will be in touch with you at the earliest. Or call us at <br /><Link to="tel:+918277331000">+91-82773 31000</Link> on our help line.</p>
                </div>
            </div>
            <div className="row justify-content-center my-5">
                <div className="col-lg-9 col-md-9 col-sm-12">
                    <div className="enqform">          
                        <form action="form_mail.php" method="post" className="enquiry-form">
                            <div className="">
                                <input className="form-control" type="text" name="inputName" placeholder="Name" required=" " />
                            </div>
                            <div className="">
                                <input className="form-control" type="text" name="inputCompany" placeholder="Company" required=" " />
                            </div>
                            <div className="">
                                <input className="form-control" type="tel" name="inputTelephone" placeholder="Telephone" required=" " />
                            </div>
                            <div className="">
                                <input className="form-control" type="email" name="inputEmail" placeholder="Email" required=" " />
                            </div>
                            <div className="">
                                <textarea className="form-control" rows="8" name="inputMessage" placeholder="Your Noise Problem" required></textarea>
                            </div>
                            <Link to="mailto:info@acousticalsurfaces.in">Send Us Your Message</Link>
                        </form>
                    </div>
                </div>
            </div>
            <div className="row cont-address">
                <div className="line"></div>
                <div className="col-lg-4 col-sm-6">
                    <h5>Acoustical Surfaces (Pvt.) Ltd.</h5>
                    <span>No.490/1, 1st Floor,<br /> 
                        Sri Krishna Rajendra Road,<br /> 
                        Tyaga Raja Nagar(P.O) - 560028<br />
                        Bangalore, Karnataka, India<br />
                        Phone: <a href="Tel:918277331000">+91-82773 31000</a><br />
                        Mob: <a href="Tel:919449777212">+91-94497 77212</a><br />
                        <a href="mailto:info@acousticalsurfaces.in">Email: info@acousticalsurfaces.in</a>
                    </span>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <h5>Acoustical Surfaces, Ind.</h5>
                    <span>
                        130/2B, Morappampalayam,<br />
                        Uppilipalayam (P.O), Karuvalur<br />
                        Tirupur - 641670 <br />
                        Tamil Nadu, India<br />
                        Phone: <a href="Tel:918277331000">+91-82773 31000</a><br />
                        Mob: <a href="Tel:919449777212">+91-94497 77212</a><br />
                        <a href="mailto:info@acousticalsurfaces.in">Email: info@acousticalsurfaces.in</a>
                    </span>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <h5>Acoustical Surfaces, Inc.</h5>
                    <span>
                        123 Columbia Court North<br />
                        Suite 201 Chaska<br />
                        MN 55318<br />
                        Phone: (952)-448-5300<br />
                        Fax: (952)-448-2613<br />
                        Toll Free:1-(800)-854-2948<br />
                        <a href="mailto:info@acousticalsurfaces.in">Email: info@acousticalsurfaces.in</a>
                    </span>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default problem; 