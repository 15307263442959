import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Curve1 from '../../images/inner/Curve.webp';
import './curve-panels.css';
import ScrollToTopButton from '../ScrollButton';
function curve() {
    return (
      
    <>
    <Helmet>
    <title>Curve System - Simple Accurate Affordable Acoustics</title>
    <meta name="description" content="The Curve Low-Profile Diffusor-Absorber is a new addition to our acclaimed Acoustic Geometry product line." />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Low-Profile Diffusor-Absorber" />
    <meta property="og:description" content="The Curve Low-Profile Diffusor-Absorber is a new addition to our acclaimed Acoustic Geometry product line." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/Curve.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/curve-panels" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/curve-panels" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/curve-panels" />
    </Helmet>
    <Header />
    <section className="content" id="curve">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
                    <h1 className='curve-content'>Low-Profile Diffusor-Absorber</h1>  
                    <div className="row pad">
                        <div className="col-xl-4 col-sm-12">
                            <Link className="example-image-link" to="../../images/inner/Curve.webp" data-lightbox="example-1">
                                <img className="example-image img-fluid" src={Curve1} alt="Curve Panels1" />
                            </Link>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 text">
                            <p></p>
                            <p>The Curve Low-Profile Diffusor-Absorber is a new addition to our acclaimed Acoustic Geometry product line. The Low-Profile is only 3″ deep, enabling placement in nearly any situation that would accept a 2″ deep flat panel absorber, and it may be mounted in either vertical or horizontal orientation. The walnut trim (optional wood species available) and wide selection of fabric options make this highly-effective Diffusor-Absorber the best choice in any décor.</p>
                            <p>The Low-Profile features 77% diffusion and 23% absorption from 500Hz to 20kHz. Wall mounting is simple and mounting hardware is included. The Low-Profile measures 43″ high by 15.5″ wide (in vertical orientation), and weighs 10 pounds.</p>
                            <strong>The Low-Profile offers:</strong>
                            <ul style={{marginLeft: "1em"}}>
                                <li>Diffusion and Absorption</li>
                                <li>Vertical or Horizontal Mounting</li>
                                <li>Custom Fabric and Trim Options</li>
                            </ul>
                        </div>
                        <div className="line"></div>
                    </div>
                    <div className="row curve-content">
                        <div className="col-sm-12">
                            <h3>Product Specs</h3>
                            <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                <tbody>
                                    <tr>
                                        <th>Applications</th>
                                        <td>
                                            The Low-Profile Diffusor-Absorber is perfect for situations where you want great performance without losing too much space. It can be used in almost any location that would accept a 2″ deep flat panel absorber.
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Colors</th>
                                        <td>
                                            A wide range of standard colors & custom colors are available. Shown in Sand Wheat
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Trim</th>
                                        <td>
                                            Many trim options available. Shown in in Walnut
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Size</th>
                                        <td>
                                            42″ H × 15.5″ W × 3″ D
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Performance</th>
                                        <td>
                                            77% Diffusion and 23% Absorption from 500Hz to 20kHz
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p>The CURVE System™ is a simple, accurate, and affordable way to achieve natural-sounding acoustics.</p>
                            <p>For more information on The Low-Profile Diffusor-Absorber, visit <a style={{color: "#0059a5", textDecoration: "underline"}} href="http://www.acousticgeometry.com/products/curve-deluxe/" target="_blank" rel="noopener noreferrer">Acoustic Geometry</a>.</p>
                        </div>
                    </div>
                    <hr className="d-none d-md-block d-lg-block d-xl-block" />
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default curve;