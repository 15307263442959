import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import './acfabric-streetwise.css';
import Mobsidebar from '../mobile-sidebar';
import Sw1 from '../../images/acfabric/sw1.jpg';
import Sw2 from '../../images/acfabric/sw2.jpg';
import Sw3 from '../../images/acfabric/sw3.jpg';
import Sw4 from '../../images/acfabric/sw4.jpg';
import Sw5 from '../../images/acfabric/sw5.jpg';
import Sw6 from '../../images/acfabric/sw6.jpg';
import Sw7 from '../../images/acfabric/sw7.jpg';
import Sw8 from '../../images/acfabric/sw8.jpg';
import Sw9 from '../../images/acfabric/sw9.jpg';
import Sw10 from '../../images/acfabric/sw10.jpg';
import Sw11 from '../../images/acfabric/sw11.jpg';
import Sw12 from '../../images/acfabric/sw12.jpg';
import Sw13 from '../../images/acfabric/sw13.jpg';
import Sw14 from '../../images/acfabric/sw14.jpg';
import ScrollToTopButton from '../ScrollButton';
function acfabricstreet() {
    return (
      
    <>
    <Helmet>
    <title>Acoustic of Maine Streetwise Fabric - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="AcoustyFABRIC Streetwise" />
    <meta property="og:description" content="Guilford of Maine Streetwise 2721 Fabric Facings" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/acfabric/sw1.jpg" />
    <meta property="og:locale" content="en-in" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/acfabric-streetwise" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/acfabric-streetwise" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acfabric-streetwise" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="acfabricstreet">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
            	<div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
	                <div className="col-sm-12 heading-fsorb">				
		                <h1>Acousty<span className="fabriwall">FABRIC</span> Streetwise</h1>
                        <h4 className="mb-0"> <b>Guilford of Maine Streetwise 2721 Fabric Facings</b></h4>
		                <p><span className="color:red;">(Colors Shown are Approximate Match to Material)</span></p>
	                    <br />
		                <div className="col-sm-3 cloths p-0"><button>Product Specifications</button></div>
	                </div>
		            <br /><br />
		            <div className="col-sm-12 cloths">
		                <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Sw1} alt="Crosswalk" />
                                <p className="text-center">Crosswalk 1901</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Sw2} alt="Asphalt" />
                                <p className="text-center">Asphalt 718</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Sw3} alt="Taxi" />
                                <p className="text-center">Taxi 1881</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Sw4} alt="Doughnut" />
                                <p className="text-center">Doughnut Shop 1882</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Sw5} alt="Hydrant" />
                                <p className="text-center">Hydrant 1883</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Sw6} alt="News Stand" />
                                <p className="text-center">News Stand 1884</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Sw7} alt="Light Rail" />
                                <p className="text-center">Light Rail 1885</p>
                            </div>
           	                <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Sw8} alt="Parking Meter" />
                                <p className="text-center">Parking Meter 1886</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Sw9} alt="Commuter" />
                                <p className="text-center">Commuter 1887</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Sw10} alt="Mailbox" />
                                <p className="text-center">Mailbox 1890</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Sw11} alt="Stop Sign" />
                                <p className="text-center">Stop Sign 1891</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Sw12} alt="Pedestrian" />
                                <p className="text-center">Pedestrian 1892</p>
                            </div>
           	                <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Sw13} alt="Pigeon" />
                                <p className="text-center">Pigeon 1893</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Sw14} alt="Pavement" />
                                <p className="text-center">Pavement 1894</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2"></div>
                        </div>
                    </div>
		            <br />
		            <hr />
                    <div className="col-12 d-flex justify-content-center backbtn">
                    	<Link to="/acoustyfabric"><button>Back to Acousty<span style={{color: "red"}}>FABRIC</span></button></Link>
                    </div>
                    <hr />
                    <div className="col-sm-12 application">
			            <h1>APPLICATION</h1>
	                    <div className="hover14 text-center justify-content-center">
		                <br />
  		                    <div className="col-sm-12">
			                    <figure className="m-0"><div className="tinted-image">CINEMA HALL</div></figure>
 		                    </div>
                            <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image2">OFFICE ROOMS & HALLS</div></figure>
 		                    </div>
                            <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image3">AUDITORIUM & EDUCATION</div></figure>
 		                    </div>
                            <br />
	                    </div>
                    </div>
		            <br />
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
    <Footer />
</>
);
}
export default acfabricstreet;