import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Polymaxwhite from '../../images/inner/polymax-beige-white-panels.jpg';
import Polymax5 from '../../images/inner/Poly-Max-5-web-200x200.jpg';
import Polymax3 from '../../images/inner/Poly-Max-3-web-200x200.jpg';
import ScrollToTopButton from '../ScrollButton';
function polymax() {
    return (
      
    <>
    <Helmet>
    <title>Polymax - PET Polyester Acoustic Panels Wall & Ceiling</title>
    <meta name="description" content="" />
    <meta property="og:title" content="PolyMax Polyester Acoustical Panel" />
    <meta property="og:description" content="Acoustical Wall & Ceiling Tiles" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/polymax-beige-white-panels.jpg" />
    <meta property="og:locale" content="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/poly-max" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/poly-max" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/poly-max" hreflang="en-ae" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="IN" />
    <meta name="geo.region" content="india" />
    </Helmet>
    <Header />
    <section className="content">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Poly Max™ Polyester Acoustical Panel</h1>
                        <div className="row">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" style={{float: "left"}} to="../../images/inner/polymax-beige-white-panels.jpg" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Polymaxwhite} alt="Ploymax white panel" />
                                </Link>
                                <Link className="example-image-link" style={{maxwidth: "6em", float: "left", padding: "5px"}} to="../../images/inner/Poly-Max-5-web.jpg" data-lightbox="example-1">
                                    <img className="example-image img-fluid d-none d-sm-block" src={Polymax5} alt="Polymax5" />
                                </Link>
                                <Link className="example-image-link" style={{maxwidth: "6em", float: "left", padding: "5px"}} to="../../images/inner/Poly-Max-3-web.jpg" data-lightbox="example-1">
                                    <img className="example-image img-fluid d-none d-sm-block" src={Polymax3} alt="Polymax3" />
                                </Link>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Acoustical Wall & Ceiling Tiles</h2>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>                 
                                    <li>Formaldehyde Free</li>
                                    <li>No Binding Agents</li>
                                    <li>No Chemical Irritants</li>
                                    <li>Architecturally Decorative</li>
                                    <li>Tackable Surface</li>
                                    <li>Impact Resistant</li>
                                    <li>className A Fire Rated</li>
                                    <li>60% Polyethylene Terephthalate – Recycled Content</li>                           
                                </ul> 
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h2>Poly Max Tackable Acoustical Wall Panel Installation Tutorial</h2>
                                <div className="ytvid-2">
                                    <p>
                                        <iframe src="https://www.youtube.com/embed/BF0mt1ucB9o" width="560" height="440" frameborder="0" allowfullscreen="allowfullscreen" title="polymax"></iframe>
                                    </p>
                                </div>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <h3>Product Specs</h3>
                                    <tbody>
                                        <tr>
                                            <th>Benefits Compared To Fiberglass </th>
                                            <td>No chemical irritants, non-allergenic, non-toxic, formaldehyde free, increased impact resistance, no binding agents & cost competitive </td>
                                        </tr>
                                        <tr>
                                            <th>Material</th>
                                            <td>7.5# per cubic foot density panels made from 100% polyester (60% PET-recycled fiber, 40% PET-virgin fiber) </td>
                                        </tr>
                                        <tr>
                                            <th>Applications</th>
                                            <td>Theaters & Home Theaters, Gymnasiums & Multipurpose Room, Commercial & Office Buildings, Studios & Production Houses, Restaurants & Night Clubs, Government & Municipal Buildings, Schools & Universities, Industrial & Manufacturing Facilities </td>
                                        </tr>
                                        <tr>
                                            <th>Sizes</th>
                                            <td>White: 23¾″ × 47¾″ & 48″ × 96″<br />
                                                Beige: 24″ × 48″<br />
                                                Custom sizes available, please call for details.<br />
                                                Available in 23¾″ × 47¾″ for Ceiling Tiles
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Thickness</th>
                                            <td>1″</td>
                                        </tr>
                                        <tr>
                                            <th>Color</th>
                                            <td>Beige & White </td>
                                        </tr>
                                        <tr>
                                            <th>Weight</th>
                                            <td>7.5# PCF </td>
                                        </tr>
                                        <tr>
                                            <th>Flammability</th>
                                            <td>className A Fire Rated<br />
                                                Flame Spread Index = 15<br />
                                                Smoke Developed Index = 250
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <p>
                            <Link className="btn btn-asi" to="reverb-demo">Reverberation Demonstration</Link>
                        </p>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="9">Poly Max Acoustical Performance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td>Thickness</td>
                                            <td>Mount &amp; Test</td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>NRC</td>
                                        </tr>
                                        <tr>
                                            <td>1″</td>
                                            <td>Type A<br />
                                                <Link style={{color: "#0059a5"}} target="_blank" href="https://acousticalsurfaces.in/pdfs/poly_max_type_a.pdf">Download Test</Link>
                                            </td>
                                            <td>.08</td>
                                            <td>.20</td>
                                            <td>.59</td>
                                            <td>.94</td>
                                            <td>1.04</td>
                                            <td>1.02</td>
                                            <td className="emphasis">.70</td>
                                        </tr>
                                        <tr>
                                            <td>1″</td>
                                            <td>Type E400<br />
                                                <Link style={{color: "#0059a5"}} target="_blank" to="https://acousticalsurfaces.in/pdfs/poly_max_type_e400.pdf">Download Test</Link>
                                            </td>
                                            <td>.44</td>
                                            <td>.80</td>
                                            <td>.73</td>
                                            <td>.99</td>
                                            <td>1.00</td>
                                            <td>.97</td>
                                            <td className="emphasis">.90</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p><strong>Type A</strong> – signifies panels that are mounted/installed directly onto a wall or ceiling.</p>
                                <p><strong>Type E400</strong> – signifies panels that are installed into a T-bar ceiling grid, where air space exists behind panels.</p>
                                <p><strong>Please download and read through tests for additional information</strong></p> 
                                <h2>Testimonials</h2>                            
                                <Link style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/Poly-Max-Custom-Acoustical-Panels.pdf" target="_blank">1. Custom Printed Poly Max Panels Reduce Noise and Add Beauty</Link>
                                <br />
                                <Link style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/veterinary-hospital-acoustical-improvement-polymax.pdf" target="_blank">2. Veterinary Hospital Acoustical Improvement with Poly Max Polyester Acoustical Panels</Link>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default polymax; 