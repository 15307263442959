import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Exterior1 from '../../images/inner/bbc_ext_n1-200x182.webp';
import Exterior2 from '../../images/inner/exterior-quilted-curtain-absorber1-200x200.webp';
import Exterior3 from '../../images/inner/silk-metal-tiles3-200x200.webp';
import Exterior4 from '../../images/inner/Soundsilencer-group4-200x200.webp';
import './exterior-noise-control.css';
import ScrollToTopButton from '../ScrollButton';
function exterior() {
    return (
      
    <>
    <Helmet>
    <title>Exterior Products - outdoor soundproofing Material</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Exterior Noise Control Products" />
    <meta property="og:description" content="Exterior noise control products are important in construction zones, airports, highways, industrial sites, or any applications where a noisy environment would cause a noise pollution problem. " />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/bbc_ext_n1-200x182.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/exterior-products" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/exterior-noise-control" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/exterior-noise-control" />
    </Helmet>
    <Header />
    <section className="content" id="exterior">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Outdoor Soundproofing Material</h1>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <h2>Exterior Noise Control Products</h2>
                                    <p>Exterior noise control products are important in construction zones, airports, highways, industrial sites, or any applications where a noisy environment would cause a noise pollution problem. A number of outdoor soundproofing products are available to suit any exterior applications.</p>
                                    <p>We offer a wide selection of outdoor soundproofing products that can be installed as permanent or temporary noise control. To meet noise abatement requirements, flexible barriers and absorbers come in many types of materials such as porous expanded polypropylene, polyethylene, fiberglass, or metal making it possible to create a cost efficient and easy to install system. If a jobsite requires a temporary noise control solutions, installing a reusable sound barrier/absorber to fencing improves conditions for workers and those in the surrounding areas. Our products, like our exterior acoustic wall panels, withstand harsh environmental conditions and resist mold and mildew.</p>
                                    <p>Take a look above for a sample of our Exterior Products that provide for exceptional noise control, and feel free to <Link to="/contact">contact us</Link> if you have any questions.</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>View Our Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Exterior1} alt="EXT-BBC-R2 Barrier" />
                                </Link>
                                <h5>EXT-BBC-R2 Barrier/absorbers</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Exterior2} alt="EXT-QFA Exterior" />
                                </Link>
                                <h5>EXT-QFA Exterior Absorbers</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Exterior3} alt="Silk Metal Panels" />
                                </Link>
                                <h5>Silk Metal™ Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Exterior4} alt="Sound Silencer Panels" />
                                </Link>
                                <h5>Sound Silencer™ Panels</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default exterior; 