import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import ScrollToTopButton from '../ScrollButton';
function polysorbwall() {
    return (
      
    <>
    <Helmet>
    <title>Polysorb wallpanels - Acoustywall Polysorb Wall Panels</title>
    <meta name="description" content="" />
    <meta property="og:title" content="Acoustywall - Polysorb Wall Panels" />
    <meta property="og:description" content="polyester acoustic panel and polyester panel Acoustic Wall Panel is a high performance and durable sound absorptive product." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/polysorb-wall-panels.jpg" />
    <meta property="og:locale" content="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/polysorb-wallpanel" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/polysorb-wallpanel" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/polysorb-wallpanel" hreflang="en-ae" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="IN" />
    <meta name="geo.region" content="india" />
    </Helmet>
    <Header />
    <section className="content">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Acoustywall - Polysorb Wall Panels</h1>
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                <Link className="example-image-link" href="images/inner/polysorb-wall-panels.jpg" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src="images/inner/polysorb-wall-panels.jpg" alt="acoustics" />
                                </Link>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Acoustywall - Polysorb Wall Panels</h2>
                                <p>The Polysorb, polyester acoustic panel and polyester panel Acoustic Wall Panel is a high performance and durable sound absorptive product. Installation of Polysorb Acoustic Wall Panel is very convenient and can be even completed by inexperienced labor and create visually striking interiors with superior acoustic performance.</p>
                                <h2>Composition</h2>
                                <p>Polysorb polyester acoustic panel and polyester panel, Acoustic Wall Panel is made from polyester short fiber. It is prepared by needle punching on multi folded polyester fabric sheets or polyester acoustic panel and polyester panel(arranged by carding process). The final polyester panel material consists of a three dimensional network structure with breathable open cells.</p>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <h3>Physical Properties</h3>
                                    <tbody>
                                        <tr>
                                            <th>Thickness:</th>
                                            <td> 9 mm</td>
                                        </tr>
                                        <tr>
                                            <th>Dimension:</th>
                                            <td> 1200 mm X 2400 mm</td>
                                        </tr>
                                        <tr>
                                            <th>Density:</th>
                                            <td> 237 kg/m3 (Testing method: ASTM D 1752)</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ul style={{liststyle: "none", marginleft: ".5em", lineheight: "1.8", fontsize: ".9em"}}>
                                    <li>Polysorb Acoustic Wall Panel is lightweight and flexible along with excellent tension/compression stiffness.</li>
                                    <li>The tile maintains its dimensions in high humidity and temperature.</li>
                                    <li>The color of the tile maintained even in long term exposure to sunlight (over 4th grade in 240 is at 100째C).</li>
                                    <li>The surface of the acoustic polyester panel material tile is resistant to surface wetting (rate of resistance to surface wetting: 99.6%)therefore inhabitation of various germs and molds is inhibited.</li>
                                    <li>Furthermore, the panel can be easily cut into any desired shape.</li>
                                </ul>
                                <div className="line"></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 text">
                                <table className="table table-bordered-2" style={{background: "#f5f5f5"}}>
                                    <h2>Sound Absorption</h2>
                                    <p>The Polysorb Acoustic Wall Panel can attain a maximum NRC of up to 0.60</p>
                                    <p>The absorption coefficient over important speech frequencies are as follows</p>
                                    <tr>
                                        <td><span><strong>Frequency (Hz)</strong></span></td>
                                        <td>250</td>
                                        <td>500</td>
                                        <td>1000</td>
                                        <td>2000</td>
                                    </tr>
                                    <tr>
                                        <td><span><strong>NRC</strong></span></td>
                                        <td>0.18</td>
                                        <td>0.54</td>
                                        <td>0.83</td>
                                        <td>0.81</td>
                                    </tr>
                                </table>
                                <h3>Fire Fighting</h3>
                                <p>The Polysorb Acoustic Wall Panel offers robust fire performance and does not generate any toxic gases on combustion.</p>
                                <table className="table table-bordered-2">
                                    <tr>
                                        <td width="136"><span>Test Item</span></td>
                                        <td width="172"><span>Standard</span></td>
                                        <td colSpan="3"><span>Test Result</span></td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">&nbsp;</td>
                                        <td width="100">No.1</td>
                                        <td width="136">No.2</td>
                                        <td>No.3</td>
                                    </tr>
                                    <tr>
                                        <td rowspan="4" style={{verticalalign: "middle"}}>
                                            <span><strong>Performance test of flame-proof</strong></span>
                                        </td>
                                        <td>Time of balanced<br />
                                            flame (sec) Within 10
                                        </td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td>Advance time (sec) Within 30</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td>Carbonization<br />
                                            (cm2) Within 50
                                        </td>
                                        <td>32.3</td>
                                        <td>34.5</td>
                                        <td>37.4 area</td>
                                    </tr>
                                    <tr>
                                        <td>Carbonization<br />
                                            length (cm) Within 20
                                        </td>
                                        <td>6.3</td>
                                        <td>6.4</td>
                                        <td>6.4</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default polysorbwall; 