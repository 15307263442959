import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import './foamstop.css';
import Foamstop1 from '../../images/inner/foamst_4.jpg';
import Foamstop2 from '../../images/inner/poly_pyr04.gif';
import ScrollToTopButton from '../ScrollButton';
function foamstop() {
    return (
      
    <>
    <Helmet>
    <title>Noise Absorption Pyramid Foam - Acoustical Surfaces </title>
    <meta name="description" content="" />
    <meta property="og:title" content="Polyurethane Foam Soundproof Pyramid" />
    <meta property="og:description" content="These polyurethane pyramid panels are developed to absorb maximum acoustical energy using minimum weight and thickness. " />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/pyramid2.jpg" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/foamstop" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="foamstop">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Foam S.T.O.P. Pyramid™ </h1>
                        <div className="row">
                            <div className="col-xl-3 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/foamst_4.jpg" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Foamstop1} alt="Foamstop 1" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Polyurethane Foam Sound Absorber</h2>
                                <ul style={{liststyle: "none"}}>
                                    <li>High Performance Absorber</li>
                                    <li>Increased Absorptive Surface Area</li>
                                    <li>Fiber Free</li>
                                    <li>Light Weight</li>
                                </ul>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link target="_blank" to="https://acousticalsurfaces.in/pdfs/pyramid-poly-foam-sound-absorber.pdf" className="ico-misc">Product Specs</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <h3>Product Specs</h3>
                                    <tbody>
                                        <tr>
                                            <th>Material</th>
                                            <td>Open Cell Polyurethane Acoustical Foam</td>
                                        </tr>
                                        <tr>
                                            <th>Pattern</th>
                                             <td>Pyramid for Monolithic Appearance</td>
                                        </tr>
                                        <tr>
                                            <th>Features</th>
                                             <td>Increased Surface Area, High Performance, Light Weight, Dramatic Unusual Effect</td>
                                        </tr>
                                        <tr>
                                            <th>Applications</th>
                                             <td>Industrial, Commercial, Audio, OEM, Residential, Ceilings, Walls, Partitions, Sound/Recording Studios, Radio Stations, Band Rooms, Swimming Pools, Churches, Schools, Gun Ranges, Mechanical Rooms, Enclosures, etc.</td>
                                        </tr>
                                        <tr>
                                            <th>Thickness</th>
                                             <td> 2″, 3″, and 4″ </td>
                                        </tr>
                                        <tr>
                                            <th>Sizes</th>
                                            <td>2′×2′, 2 ′×4′</td>
                                        </tr>
                                        <tr>
                                            <th>Colors</th>
                                             <td>Blue, Beige, Brown, Charcoal, Hunter Green and Burgundy – Painted: Silver and Blue</td>
                                        </tr>
                                        <tr>
                                            <th>Flammability</th>
                                             <td>ASTM E84, className 2. Flame Spread: 75; Smoke Developed: 340 (Painted F/S: 420, S/D: 300)</td>
                                        </tr>
                                        <tr>
                                            <th>Installation</th>
                                            <td>ASI S.T.O.P Noise Acoustical Adhesive</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <p><Link className="btn btn-asi" to="/reverb-demo.php">Reverberation Demonstration</Link></p>
                                    <thead>
                                        <tr>
                                            <h4>FOAM S.T.O.P. Pyramid&nbsp;– Sound Absorption/Noise Reduction</h4>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td style={{textalign: "center"}} colSpan="8">per ASTM C423-90a</td>
                                    </tr>
                                    <tr className="colheader" style={{fontweight: "bold"}}>
                                        <td></td>
                                        <td>125Hz</td>
                                        <td>250Hz</td>
                                        <td>500Hz</td>
                                        <td>1KHz</td>
                                        <td>2KHz</td>
                                        <td>4KHz</td>
                                        <td>NRC</td>
                                    </tr>
                                    <tr>
                                        <td>2″</td>
                                        <td>0.15</td>
                                        <td>0.17</td>
                                        <td>0.49</td>
                                        <td>0.73</td>
                                        <td>0.66</td>
                                        <td>0.68</td>
                                        <td className="emphasis">0.50</td>
                                    </tr>
                                    <tr>
                                        <td>3″</td>
                                        <td>0.44</td>
                                        <td>0.48</td>
                                        <td>1.19</td>
                                        <td>1.12</td>
                                        <td>1.16</td>
                                        <td>1.16</td>
                                        <td className="emphasis">1.00</td>
                                    </tr>
                                    <tr>
                                        <td>4″</td>
                                        <td>0.39</td>
                                        <td>0.60</td>
                                        <td>1.21</td>
                                        <td>1.14</td>
                                        <td>1.16</td>
                                        <td>1.13</td>
                                        <td className="emphasis">1.05</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <p><span>CAUTION:</span><span>Polyurethane foam is flammable and may emit toxic fumes when burned. Do not use near
                                open flame. Check local codes for allowed uses. Manufacturer assumes no liability.</span></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/poly_pyr04.gif" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Foamstop2} alt="Foamstop 2" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default foamstop; 