import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Woodteclinear3 from '../../images/woodtec/9.webp';
import Woodteclinear4 from '../../images/woodtec/10.webp';
import Woodteclinear5 from '../../images/woodtec/11.webp';
import Woodteclinear6 from '../../images/woodtec/12.webp';
import Woodteclinear7 from '../../images/woodtec/13.webp';
import Woodteclinear8 from '../../images/woodtec/14.webp';
import Woodtecperforated3 from '../../images/woodtec/wp1.webp';
import Woodtecperforated4 from '../../images/woodtec/wp2.webp';
import Woodtecperforated5 from '../../images/woodtec/wp3.webp';
import Woodtecperforated6 from '../../images/woodtec/wp4.webp';
import Woodtecperforated7 from '../../images/woodtec/wp8.webp';
import Woodtecperforated8 from '../../images/woodtec/wp7.webp';
import Woodtecslotted3 from '../../images/woodtec/ws1.webp';
import Woodtecslotted4 from '../../images/woodtec/ws2.webp';
import Woodtecslotted5 from '../../images/woodtec/ws3.webp';
import Woodtecslotted6 from '../../images/woodtec/ws4.webp';
import Woodtecdesigner3 from '../../images/woodtec/ds1.webp';
import Woodtecdesigner4 from '../../images/woodtec/14.webp';
import Mobsidebar from '../mobile-sidebar';
import Sliderwoodtec from './slider-woodtec';
import Sliderwoodteclinear from './slider-woodteclinear';
import Sliderwoodtecperforated from './slider-woodtecperforated';
import Sliderwoodtecslotted from './slider-woodtecslotted';
import Sliderwoodtecdesigner from './slider-woodtecdesigner';
import './style-woodtec.css';
import ScrollToTopButton from '../ScrollButton';
function woodtec() {
    return (
      
    <>
    <Helmet>
    <title>Wood Acoustic Wall Panels Reduce Sound and Reverberation</title>
    <meta name="description" content="WoodTEC acoustic wall panels are high perfoming acoustic panels suitable for both walls and ceilings." />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Acoustic Wooden Panels" />
    <meta property="og:description" content="WoodTEC panels are high perfoming acoustic panels suitable for both walls and ceilings."/>
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/woodtec/9.webp"/>
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/woodtec" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/woodtec" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/woodtec" />
    </Helmet>
    <Header />
    <section className="content" id="woodtec">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
	                    <div className="col-sm-12 heading-fsorb">				
		                    <h1>Wood<span style={{color: "red"}}>TEC</span></h1>
                            <h4 style={{fontsize: "20px"}}>Acoustic Wooden Panels</h4>
		                    <p>WoodTEC panels are high perfoming acoustic panels suitable for both walls and ceilings. With MDF as it’s core, the panels are a perfect blend of aesthetical and technical qualities. From an aesthetical point of view, they are exquisite and discreet. They are mainly available in veneer, melamine and painted finishes with a wide range of design customizations. They usually have an acoustic insulation on the backside 
                                which is normally mineral wool or fiberglass, contributing to its high absorption performance.</p>
	                        <div className="demo-cont">
	                            <Sliderwoodtec />
                                <div className="demo-cont__credits">
    	                            <div className="colorful-switch">
      		                            <label className="colorful-switch__label" htmlFor="colorful-switch-cb">
			                                <span className="colorful-switch__bg"></span>
        	                                <span className="colorful-switch__dot"></span>
				                            <span className="colorful-switch__on">
          		                                <span className="colorful-switch__on__inner"></span>
        		                            </span>
						                    <span className="colorful-switch__off"></span>
				 	                    </label>
    	                            </div>
	                            </div>
                            </div>
	                    </div>
                        <div className="col-sm-12 features">
	                        <h1>Applications</h1>
	                        <div className="line"></div>
		                    <p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                            <div className="row pad ">	
		                        <div className="col-md-6 col-sm-12">
	                                <ul>
	                                    <li>Auditoriums</li>
		                                <li>Meeting Rooms</li>
		                                <li>Theaters</li>
		                                <li>Hotels</li>
                                    </ul>
	                            </div>
		                        <div className="col-md-6 col-sm-12 d-none d-md-block">
	                                <ul>
		                                <li>Offices</li>
		                                <li>Lecture Halls</li>
		                                <li>Educational Institutions</li>
		                                <li>Airports</li>
	                                </ul>
		                        </div>
	                        </div>
                        </div>
	                    <div className="col-sm-12 more">
                            <h1> More Products </h1>
	                        <div className="line"></div>
	                    </div>
	                    <div className="accordion-started accordion-bral panels row">
		                    <div className="col-sm-12 col-md-12 col-lg-12 ">
	                            <label className="ac-label" htmlFor="ac-1">
                                    <b>Wood</b><br />LINEAR ACOUSTIC PANEL<i></i>
                                </label>
		                        <div className="article ac-content">
                                    <Sliderwoodteclinear />
			                        <h1>Wood<span style={{color: "red"}}>TEC</span> - LINEARO Series</h1>
				                    <p>Linearo Series Acoustic Panels comes with grooving on the viewed face and perforations on the backside giving it an astounding appearance and impressive acoustic properties. These panels come with a linear design which in turn gives a linear theme to walls and ceilings making it an ideal choice for spaces such as lecture halls and meeting rooms. Based on sound absorption demands of different spaces, they are made
                                        available in different customizations with different groove widths and different distances between grooves.</p>	
                                    <h3><strong>Features</strong></h3>
		                            <div className="line"></div>
			                        <div className="row pad">
		                                <div className="col-md-6 col-sm-12 panelfeatures">
                		                    <ul>
		                                        <li>Reduces reverberation and echo </li>
		                                        <li>Low formaldehyde and moisture resistant</li>
		                                        <li>Highly aesthetic</li>
		                                        <li>High impact resistance</li>
		                                    </ul>
				                        </div>
                                        <div className="col-md-6 col-sm-12 d-none d-md-block panelfeatures">
	            	                        <ul>
		                                        <li>Better speech intelligibility</li>
		                                        <li>Washable and recyclable</li>
		                                        <li>Easily installable</li>
		                                        <li>Highly sustainable</li>
		        		                    </ul>
		        	                    </div>
	                                </div>		
		                            <br />		
				                    <h3><strong>Product Details </strong></h3>
		                            <div className="line"></div>
                                    <br />
            	                    <div className="col-sm-12">
			                            <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#menu3">DATASHEET</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#menu2">BROCHURE</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#menu4">COLOR CHART</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Core</th>
                                                            <td>MDF Standard | MDF Fire rated | MDF Moisture Resistant</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Absorbent</th>
                                                            <td>Mineral wool (50mm)</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Thickness</th>
                                                            <td>16 mm | 18 mm | 20 mm</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Finishes</th>
                                                            <td>Melamine, Veneer, Painted</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Sizes</th>
                                                            <td>Standard: 2430 x 160 mm 
				                                                Optional: 600x600 , 600x1200 , 517x1381 , 517x2400 ,                                            
                                                                128 x 2430, 197x2400 , 293x2400 , 197x1381 mm (custom sizes)
                                                            </td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Density</th>
                                                            <td>700 kg/m3 </td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Flammability</th>
                                                            <td>B-s2 d0</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Installation</th>
                                                            <td>Walls: Omega metallic profiles, Clips, Wood battens<br />
                                                                Ceilings: Standard system T24, Clips 
                                                            </td>
                                                        </tr>
                                                    </tbody>
	                                            </table>
		                                    </div>
                                            <div id="menu1" className="container tab-pane fade"><br />
                		                        <h3>Linearo Series - Type 1</h3>
                		                        <br />
		                                        <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Absorbent<br />(plenum)</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>    
                                                            <th scope="row">50mm</th>
                                                            <td>0.40</td>
                                                            <td>0.70</td>
                                                            <td>0.80</td>
                                                            <td>0.75</td>
                                                            <td>0.65</td>
                                                            <td>0.65</td>
                                                            <td>0.65</td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">200mm</th>
                                                            <td>0.65</td>
                                                            <td>0.65</td>
                                                            <td>0.65</td>
                                                            <td>0.70</td>
                                                            <td>0.70</td>
                                                            <td>0.70</td>
                                                            <td>0.70</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
	                                            <br />
            	                                <h3>Linearo Series - Type 2</h3>
                                                <br />
				                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Absorbent<br />(plenum)</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">50mm</th>
                                                            <td>0.30</td>
                                                            <td>0.70</td>
                                                            <td>0.95</td>
                                                            <td>0.95</td>
                                                            <td>0.90</td>
                                                            <td>0.90</td>
                                                            <td>0.80</td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">200mm</th>
                                                            <td>0.75</td>
                                                            <td>0.85</td>
                                                            <td>0.85</td>
                                                            <td>0.90</td>
                                                            <td>0.95</td>
                                                            <td>0.95</td>
                                                            <td>0.90</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <br />
                    	                        <h3>Linearo Series - Type 3</h3>
                                                <br />
		        		                        <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Absorbent<br />(plenum)</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">50mm</th>
                                                            <td>0.05</td>
                                                            <td>0.73</td>
                                                            <td>0.98</td>
                                                            <td>0.84</td>
                                                            <td>0.75</td>
                                                            <td>0.42</td>
                                                            <td>0.65</td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">200mm</th>
                                                            <td>0.56</td>
                                                            <td>0.86</td>
                                                            <td>0.72</td>
                                                            <td>0.89</td>
                                                            <td>0.75</td>
                                                            <td>0.42</td>
                                                            <td>0.70</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div id="menu2" className="container tab-pane fade"><br />
	        		                            <br />
                                                <h3>Brochure</h3>
		                                        <br />
		                                        <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/woodtec/brochure-wd.pdf" className="brochure">Download</a>
	                                        </div>
	  	                                    <div id="menu3" className="container tab-pane fade"><br />
        		                                <br />
                                                <h3>Datasheet</h3>
		                                        <br />
		                                        <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/woodtec/linearo.pdf" className="brochure">Download</a>
		                                    </div>
	                                        <div id="menu4" className="container tab-pane fade"><br />
	    		                                <br />
                                                <h3>Color Chart</h3>
		                                        <br />
		                                        <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/woodtec/color-chart-f.pdf" className="brochure">Download</a>
		                                    </div>
                                        </div>
		                            </div>			
    	                            <div className="col-sm-12">	
                                        <div className="row">
                                            <div className="col-12 col-sm-5 mb-3">
                                                <img src={Woodteclinear3} alt="Woodteclinear3" />
                                            </div>
                                            <div className="col-12 col-sm-5">
                                                <img src={Woodteclinear4} alt="Woodteclinear4" />
                                            </div>
                                        </div>    
                                        <br /><br />  
      	                                <div className="row no-gutters colorcard">			
                                            <div className="col-sm-3">	
                                                <h1>Linearo Series</h1>
                                                <h1 style={{color: "red"}}>TYPE 1</h1>
                                                <p><Link to="/#">Datasheet</Link></p>
                                            </div>
                                            <div className="col-sm-4">	
		    	                                <table>
                                                    <tr>
                                                        <th>HOLE PITCH:</th>
                                                        <td>16 X 32mm, alternate</td>
                                                    </tr>
                                                    <tr>
                                                        <th>HOLE DIAMETER:</th>
                                                        <td>10mm</td>
                                                    </tr>
                                                    <tr>
                                                        <th>SPACING:</th>
                                                        <td>28mm</td>
                                                    </tr>
                                                </table>
                                            </div>
				                            <div className="col-sm-4">	
		        	                            <table>
                                                    <tr>
                                                        <th>PERFORATION:</th>
                                                        <td>7.6%</td>
                                                    </tr>
                                                    <tr>
                                                        <th>NRC:</th>
                                                        <td>0.65-0.70</td>
                                                    </tr>
                                                    <tr>
                                                        <th>FLEECE:</th>
                                                        <td>0.2mm</td>
                                                    </tr>
   	                                            </table>
                                            </div>
		                                </div>
	                                </div>	
                                    <div className="line"></div><br />		
		                            <div className="col-sm-12">	
                                        <div className="row">
                                            <div className="col-12 col-sm-5 mb-3">
                                                <img src={Woodteclinear5} alt="Woodteclinear5" />
                                            </div>
                                            <div className="col-12 col-sm-5">
                                                <img src={Woodteclinear6} alt="Woodteclinear6" />
                                            </div>
                                        </div>    
                                        <br /><br /> 
                                        <div className="row colorcard ">			
                                            <div className="col-sm-4">	
		                                        <h1>Linearo Series</h1>
                                                <h1 style={{color: "red"}}>TYPE 2</h1>
                                                <p><Link to="/#">Datasheet</Link></p>
                                            </div>
                                            <div className="col-sm-4">	
		    	                                <table>
                                                    <tr>
                                                        <th>HOLE PITCH:</th>
                                                        <td>16 X 16mm, alternate</td>
                                                    </tr>
                                                    <tr>
                                                        <th>HOLE DIAMETER:</th>
                                                        <td>10mm</td>
                                                    </tr>
                                                    <tr>
                                                        <th>SPACING:</th>
                                                        <td>13mm</td>
                                                    </tr>
                                                </table>
                                            </div>
			                                <div className="col-sm-4">	
		                                        <table>
                                                    <tr>
                                                        <th>PERFORATION:</th>
                                                        <td>11.5%</td>
                                                    </tr>
                                                    <tr>
                                                        <th>NRC:</th>
                                                        <td>0.80 - 0.90</td>
                                                    </tr>
                                                    <tr>
                                                        <th>FLEECE:</th>
                                                        <td>0.2mm</td>
                                                    </tr>
   	                                            </table>
                                            </div>
	                                    </div>
	                                </div>				
                                    <div className="line"></div>	<br />
	                                <div className="col-sm-12">	
                                        <div className="row">
                                            <div className="col-12 col-sm-5 mb-3">
                                                <img src={Woodteclinear7} alt="Woodteclinear7" />
                                            </div>
                                            <div className="col-12 col-sm-5">
                                                <img src={Woodteclinear8} alt="Woodteclinear8" />
                                            </div>
                                        </div>    
                                        <br /><br /> 
                                        <div className="row colorcard ">			
                                            <div className="col-sm-4">	
                                                <h1>Linearo Series</h1>
                                                <h1 style={{color: "red"}}>TYPE 3</h1>
                                                <p><Link to="/#">Datasheet</Link></p>
                                            </div>
                                            <div className="col-sm-4">	
		                                        <table>
                                                    <tr>
                                                        <th>HOLE PITCH:</th>
                                                        <td>8 X 32mm, alternate</td>
                                                    </tr>
                                                    <tr>
                                                        <th>HOLE DIAMETER:</th>
                                                        <td>10mm</td>
                                                    </tr>
                                                    <tr>
                                                        <th>SPACING:</th>
                                                        <td>6mm</td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div className="col-sm-4">	
		                                        <table>
                                                    <tr>
                                                        <th>PERFORATION:</th>
                                                        <td>5.4%</td>
                                                    </tr>
                                                    <tr>
                                                        <th>NRC:</th>
                                                        <td>0.65 - 0.70</td>
                                                    </tr>
                                                    <tr>
                                                        <th>FLEECE:</th>
                                                        <td>0.2mm</td>
                                                    </tr>
     	                                        </table>
                                            </div>
	                                    </div>
	                                </div>	
	                                <br />
	                            </div>
	                        </div>
	                        <div className="col-sm-12 col-md-12 col-lg-12">
        	                    <label className="ac-label" htmlFor="ac-2">
                                    <b>Wood</b><br />PERFORATED ACOUSTIC PANEL<i></i>
                                </label>
			                    <div className="article ac-content">
				                    <Sliderwoodtecperforated />
    			                    <h1>Wood<span style={{color: "red"}}>TEC</span> - PERFERO Series</h1>
				                    <p>Perfero acoustic panels are the go to choice when it comes to economically achieving high absorption characteristics. The panels offer a perfect combination of aesthetics of natural wood and high acoustics performance derived from precision-engineered perforations.
                                        They are available in different finish, pattern and hole diameter customizations to meet different acoustic demands of the customer.</p>	
                                    <h3><strong>Features</strong></h3>
		                            <div className="line"></div>
		    	                    <div className="row pad">
		                                <div className="col-md-6 col-sm-12 panelfeatures">
	            		                    <ul>
		                                        <li>Reduces reverberation and echo </li>
		                                        <li>Low formaldehyde and moisture resistant</li>
		                                        <li>Highly aesthetic</li>
		                                        <li>High impact resistance</li>
		                                    </ul>
		    		                    </div>
                                        <div className="col-md-6 col-sm-12 d-none d-md-block panelfeatures">
			                                <ul>
		                                        <li>Better speech intelligibility</li>
		                                        <li>Washable and recyclable</li>
		                                        <li>Easily installable</li>
		                                        <li>Highly sustainable</li>
		        		                    </ul>
		    		                    </div>
	                                </div>		
		                            <br />			
		                            <h3><strong>Product Details </strong></h3>
		                            <div className="line"></div>
				                    <div className="col-sm-12">
				                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#cp-home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cp-menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cp-menu3">DATASHEET</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cp-menu2">BROCHURE</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cp-menu4">COLOR CHART</a>
                                            </li>
	                                    </ul>
                                        <div className="tab-content">
                                            <div id="cp-home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Core</th>
                                                            <td>MDF Standard | MDF Fire rated | MDF Moisture Resistant</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Absorbent</th>
                                                            <td>Mineral wool (50mm)</td>
                                                        </tr>   
                                                        <tr>    
                                                            <th scope="row">Thickness</th>
                                                            <td>12 mm | 16 mm | 18 mm</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Finishes</th>
                                                            <td>Melamine, Veneer, Painted</td>
                                                        </tr>
                        	                            <tr>
                                                            <th scope="row">Sizes</th>
                                                            <td>Standard:  600x600<br />
				                                                Optional : 600x1200, 517x1381, 517x2400, 517x1200,
                                                                2400x1200, 1200x300, 2400x600, 1200x60 197x2400, 293x2400, 197x1381 mm
                                                            </td>
                                                        </tr>
	  	                                                <tr>
                                                            <th scope="row">Density</th>
                                                            <td>700 kg/m3 </td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Flammability</th>
                                                            <td>B-s2 d0</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Installation</th>
                                                            <td>Walls: Omega metallic profiles, Clips, Wood battens<br />
                                                                Ceilings: Standard System T24, Clips 
                                                            </td>
                                                        </tr>
	                                                </tbody>
	                                            </table>
    		                                </div>
                                            <div id="cp-menu1" className="container tab-pane fade"><br />
                                                <h3>Perfero Series - Type 1</h3>
     		                                    <br />
		                                        <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Absorbent<br />(plenum)</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">50mm</th>
                                                            <td>0.20</td>
                                                            <td>0.60</td>
                                                            <td>1.00</td>
                                                            <td>0.90</td>
                                                            <td>0.55</td>
                                                            <td>0.55</td>
                                                            <td>0.75</td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody>
                                                        <tr>    
                                                            <th scope="row">200mm</th>
                                                            <td>0.45</td>
                                                            <td>0.90</td>
                                                            <td>0.90</td>
                                                            <td>0.90</td>
                                                            <td>0.65</td>
                                                            <td>0.55</td>
                                                            <td>0.85</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
	                                            <br /> 	
                                                <h3>Perfero Series - Type 2</h3>
     		                                    <br />
		                                        <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Absorbent<br />(plenum)</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">50mm</th>
                                                            <td>0.13</td>
                                                            <td>0.75</td>
                                                            <td>0.39</td>
                                                            <td>0.29</td>
                                                            <td>0.16</td>
                                                            <td>0.07</td>
                                                            <td>0.40</td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">200mm</th>
                                                            <td>0.75</td>
                                                            <td>0.55</td>
                                                            <td>0.44</td>
                                                            <td>0.36</td>
                                                            <td>0.19</td>
                                                            <td>0.08</td>
                                                            <td>0.45</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <br />
                                                <h3>Perfero Series - Type 3</h3>
                                                <br />
		                                        <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Absorbent<br />(plenum)</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">50mm</th>
                                                            <td>0.05</td>
                                                            <td>0.74</td>
                                                            <td>0.97</td>
                                                            <td>0.83</td>
                                                            <td>0.69</td>
                                                            <td>0.34</td>
                                                            <td>0.80</td>
                                                        </tr>   
                                                    </tbody>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">200mm</th>
                                                            <td>0.56</td>
                                                            <td>0.86</td>
                                                            <td>0.72</td>
                                                            <td>0.89</td>
                                                            <td>0.76</td>
                                                            <td>0.42</td>
                                                            <td>0.80</td>
                                                        </tr>
                                                    </tbody>
                                                </table>   
                                            </div>
                                            <div id="cp-menu2" className="container tab-pane fade"><br />
                                                <br />
                                                <h3>Brochure</h3>
		                                        <br />
		                                        <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/woodtec/brochure-wd.pdf" className="brochure">Download</a>
                                            </div>
	                                        <div id="cp-menu3" className="container tab-pane fade"><br />
                                                <br />
                                                <h3>Datasheet</h3>
		                                        <br />
		                                        <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/woodtec/perfero.pdf" className="brochure">Download</a>
                                            </div>
	                                        <div id="cp-menu4" className="container tab-pane fade"><br />
		                                        <br />
                                                <h3>Color Chart</h3>
		                                        <br />
		                                        <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/woodtec/color-chart-f.pdf" className="brochure">Download</a>
    	                                    </div>
	                                    </div>
                                    </div>			
			                        <div className="col-sm-12">	
                                        <div className="row">
                                            <div className="col-12 col-sm-4 mb-3">
                                                <img src={Woodtecperforated3} alt="Woodtecperforated3" />
                                            </div>
                                            <div className="col-12 col-sm-4">
                                                <img src={Woodtecperforated4} alt="Woodtecperforated4" />
                                            </div>
                                        </div>    
                                        <br /><br /> 
                                        <div className="row colorcard ">			
                                            <div className="col-sm-4">	
		                                        <h1>Perfero Series</h1>
                                                <h1 style={{color: "red"}}>TYPE 1</h1>
                                                <p><Link to="/#">Datasheet</Link></p>
                                            </div>
                                            <div className="col-sm-4">	
		        	                            <table>
                                                    <tr>
                                                        <th>HOLE PITCH:</th>
                                                        <td>16 X 16mm, aligned</td>
                                                    </tr>
                                                    <tr>
                                                        <th>HOLE DIAMETER:</th>
                                                        <td>8mm | 4,6,10mm</td>
                                                    </tr>
                                                    <tr>
                                                        <th>DEPTH:</th>
                                                        <td>Through </td>
                                                    </tr>
                                                </table>
                                            </div>
	    			                        <div className="col-sm-4">	
		        	                            <table>
                                                    <tr>
                                                        <th>PERFORATION:</th>
                                                        <td>19.6%</td>
                                                    </tr>
                                                    <tr>
                                                        <th>NRC:</th>
                                                        <td>0.75 - 0.85</td>
                                                    </tr>
                                                    <tr>
                                                        <th>FLEECE:</th>
                                                        <td>0.2mm</td>
                                                    </tr>
                                                </table>
                                            </div>
		                                </div>
	                                </div>	
                                    <div className="line"></div><br />		
	                                <div className="col-sm-12">	
                                        <div className="row">
                                            <div className="col-12 col-sm-4 mb-3">
                                                <img src={Woodtecperforated5} alt="Woodtecperforated5" />
                                            </div>
                                            <div className="col-12 col-sm-4">
                                                <img src={Woodtecperforated6} alt="Woodtecperforated6" />
                                            </div>
                                        </div>    
                                        <br /><br /> 
                                        <div className="row colorcard ">			
                                            <div className="col-sm-4">	
		                                        <h1>Perfero Series</h1>
                                                <h1 style={{color: "red"}}>TYPE 2</h1>
                                                <p><Link to="/#">Datasheet</Link></p>
                                            </div>
                                            <div className="col-sm-4">	
		                                        <table>
                                                    <tr>
                                                        <th>HOLE PITCH:</th>
                                                        <td>32 X 32mm, aligned</td>
                                                    </tr>
                                                    <tr>
                                                        <th>HOLE DIAMETER:</th>
                                                        <td>8mm | 4,6,10mm</td>
                                                    </tr>
                                                    <tr>
                                                        <th>DEPTH:</th>
                                                        <td>Through</td>
                                                    </tr>
    	                                        </table>
                                            </div>
                                            <div className="col-sm-4">	
    		                                    <table>
                                                    <tr>
                                                        <th>PERFORATION:</th>
                                                        <td>4.9%</td>
                                                    </tr>
                                                    <tr>
                                                        <th>NRC:</th>
                                                        <td>0.40 - 0.45</td>
                                                    </tr>
                                                    <tr>
                                                        <th>FLEECE:</th>
                                                        <td>0.2mm</td>
                                                    </tr>
     	                                        </table>
                                            </div>
	                                    </div>
	                                </div>				
                                    <div className="line"></div>	<br />
	                                <div className="col-sm-12">	
                                        <div className="row">
                                            <div className="col-12 col-sm-4 mb-3">
                                                <img src={Woodtecperforated7} alt="Woodtecperforated7" />
                                            </div>
                                            <div className="col-12 col-sm-4">
                                                <img src={Woodtecperforated8} alt="Woodtecperforated8" />
                                            </div>
                                        </div>    
                                        <br /><br />    
                                        <div className="row colorcard ">			
                                            <div className="col-sm-4">	
		                                        <h1>Perfero Series</h1>
                                                <h1 style={{color: "red"}}>TYPE 3</h1>
                                                <p><Link to="/#">Datasheet</Link></p>
                                            </div>
                                            <div className="col-sm-4">	
			                                    <table>
                                                    <tr>
                                                        <th>HOLE PITCH:</th>
                                                        <td>16 X 16mm, alternate</td>
                                                    </tr>
                                                    <tr>
                                                        <th>HOLE DIAMETER:</th>
                                                        <td>8mm | 4,6,10mm</td>
                                                    </tr>
                                                    <tr>
                                                        <th>DEPTH:</th>
                                                        <td>Through</td>
                                                    </tr>
                                                </table>
                                            </div>
			                                <div className="col-sm-4">	
			                                    <table>
                                                    <tr>
                                                        <th>PERFORATION:</th>
                                                        <td>19.6%</td>
                                                    </tr>
                                                    <tr>
                                                        <th>NRC:</th>
                                                        <td>0.80</td>
                                                    </tr>
                                                    <tr>
                                                        <th>FLEECE:</th>
                                                        <td>0.2mm</td>
                                                    </tr>
   	                                            </table>
		                                    </div>
	                                    </div>
    	                            </div>	
	                                <br />
	                            </div>
	                        </div>
	                        <div className="col-sm-12 col-md-12 col-lg-12">
    	                        <label className="ac-label" htmlFor="ac-3">
                                    <b>Wood</b><br />SLOTTED ACOUSTIC PANEL<i></i>
                                </label>
		                        <div className="article ac-content">
			                        <Sliderwoodtecslotted />
			                        <h1>Wood<span style={{color: "red"}}>TEC</span> - SLOTTO Series</h1>
				                    <p>Slotto acoustic panels help to achieve high absorption and acoustic levels at a medium cost. They are offered in a wide range of standard designs and are apt to setup 
                                        an acoustically as well as aesthetically sound space. They can have different customizations based on groove widths and spacings.</p>	
                                    <h3><strong>Features</strong></h3>
		                            <div className="line"></div>
		    	                    <div className="row pad">
		                                <div className="col-md-6 col-sm-12 panelfeatures">
    	            	                    <ul>
	    	                                    <li>Reduces reverberation and echo </li>
		                                        <li>Low formaldehyde and moisture resistant</li>
		                                        <li>Highly aesthetic</li>
		                                        <li>High impact resistance</li>
		                                    </ul>
				                        </div>
                                        <div className="col-md-6 col-sm-12 d-none d-md-block panelfeatures">
			                                <ul>
		                                        <li>Better speech intelligibility</li>
		                                        <li>Washable and recyclable</li>
    		                                    <li>Easily installable</li>
	    	                                    <li>Highly sustainable</li>
		        		                    </ul>
			    	                    </div>
	                                </div>		
		                            <br />			
		                            <h3><strong>Product Details </strong></h3>
		                            <div className="line"></div>
				                    <div className="col-sm-12">
    				                    <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#cb-home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cb-menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cb-menu3">DATASHEET</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cb-menu2">BROCHURE</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cb-menu4">COLOR CHART</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="cb-home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
	                                                    <tr>    
                                                            <th scope="row">Core</th>
                                                            <td>MDF Standard | MDF Fire rated | MDF Moisture Resistant</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Absorbent</th>
                                                            <td>Mineral wool (50mm)</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Thickness</th>
                                                            <td>12 mm | 16 mm | 18 mm</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Finishes</th>
                                                            <td>Melamine, Veneer, Painted</td>
                                                        </tr>
   	                                                    <tr>
                                                            <th scope="row">Sizes</th>
                                                            <td>Standard:  600x600<br />
				                                                Optional : 2400x1200, 2400x600, 1200x60, 1200x600, 1200x300
                                                            </td>
                                                        </tr>
	                                                    <tr>    
                                                            <th scope="row">Density</th>
                                                            <td>750 kg/m3 </td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Flammability</th>
                                                            <td>B-s2 d0</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Installation</th>
                                                            <td>Walls: Omega metallic profiles, Clips, Wood battens<br />
                                                                Ceilings: Standard system T24, Clips 
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
		                                    </div>
                                            <div id="cb-menu1" className="container tab-pane fade"><br />
                        	                    <h3>Slotto Series – Type1</h3>
         		                                <br />
		                                        <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Absorbent <br />(plenum)</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">50mm</th>
                                                            <td>0.05</td>
                                                            <td>0.73</td>
                                                            <td>0.98</td>
                                                            <td>0.90</td>
                                                            <td>0.85</td>
                                                            <td>0.76</td>
                                                            <td>0.85</td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">200mm</th>
                                                            <td>0.56</td>
                                                            <td>0.87</td>
                                                            <td>0.72</td>
                                                            <td>0.90</td>
                                                            <td>0.81</td>
                                                            <td>0.51</td>
                                                            <td>0.80</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <br />
                                                <h3>Slotto Series – Type2</h3>
                    		                    <br />
		                                        <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Absorbent <br />(plenum)</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>    
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">50mm</th>
                                                            <td>0.06</td>
                                                            <td>0.70</td>
                                                            <td>0.66</td>
                                                            <td>0.55</td>
                                                            <td>0.37</td>
                                                            <td>0.18</td>
                                                            <td>0.55</td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">200mm</th>
                                                            <td>0.67</td>
                                                            <td>0.74</td>
                                                            <td>0.60</td>
                                                            <td>0.60</td>
                                                            <td>0.37</td>
                                                            <td>0.18</td>
                                                            <td>0.60</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
    		                                </div>
                                            <div id="cb-menu2" className="container tab-pane fade"><br />
                                                <br />
                                                <h3>Brochure</h3>
		                                        <br />
	                                            <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/woodtec/brochure-wd.pdf" className="brochure">Download</a>
                                            </div>
	                                        <div id="cb-menu3" className="container tab-pane fade"><br />
                                                <br />
                                                <h3>Datasheet</h3>
		                                        <br />
		    		                            <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/woodtec/slotted.pdf" className="brochure">Download</a>
                                            </div>
	                                        <div id="cb-menu4" className="container tab-pane fade"><br />
                                                <br />
                                                <h3>Color Chart</h3>
		                                        <br />
		    		                            <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/woodtec/color-chart-f.pdf" className="brochure">Download</a>
                                            </div>
                                        </div>
			                        </div>			
                                    <div className="col-sm-12">	
                                        <div className="row">
                                            <div className="col-12 col-sm-4 mb-3">
                                                <img src={Woodtecslotted3} alt="Woodtecslotted3" />
                                            </div>
                                            <div className="col-12 col-sm-4">
                                                <img src={Woodtecslotted4} alt="Woodtecslotted4" />
                                            </div>
                                        </div>    
                                        <br /><br /> 
        	                            <div className="row colorcard ">			
                                            <div className="col-sm-4">	
    		                                    <h1>Slotto Series</h1>
                                                <h1 style={{color: "red"}}>TYPE 1</h1>
                                                <p><Link to="/#">Datasheet</Link></p>
                                            </div>
                                            <div className="col-sm-4">	
        	                                    <table>
                                                    <tr>
                                                        <th>HOLE PITCH:</th>
                                                        <td>20 X 35mm, aligned</td>
                                                    </tr>
                                                    <tr>
                                                        <th>HOLE DIAMETER:</th>
                                                        <td>8 x 60 mm | 4,6,10mm</td>
                                                    </tr>
                                                    <tr>
                                                        <th>DEPTH:</th>
                                                        <td>Through </td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div className="col-sm-4">	
		                                        <table>
                                                    <tr>
                                                        <th>PERFORATION:</th>
                                                        <td>21.6%</td>
                                                    </tr>
                                                    <tr>
                                                        <th>NRC:</th>
                                                        <td>0.85 - 0.80</td>
                                                    </tr>
                                                    <tr>
                                                        <th>FLEECE:</th>
                                                        <td>0.2mm</td>
                                                    </tr>
     	                                        </table>
                                            </div>
	                                    </div>
	                                </div>	
                                    <div className="line"></div><br />		
	                                <div className="col-sm-12">	
                                        <div className="row">
                                            <div className="col-12 col-sm-4 mb-3">
                                                <img src={Woodtecslotted5} alt="Woodtecslotted5" />
                                            </div>
                                            <div className="col-12 col-sm-4">
                                                <img src={Woodtecslotted6} alt="Woodtecslotted6" />
                                            </div>
                                        </div>    
                                        <br /><br /> 
                                        <div className="row colorcard ">			
                                            <div className="col-sm-4">	
		                                        <h1>Slotto Series</h1>
                                                <h1 style={{color: "red"}}>TYPE 2</h1>
                                                <p><Link to="/#">Datasheet</Link></p>
                                            </div>
                                            <div className="col-sm-4">	
		                                        <table>
                                                    <tr>
                                                        <th>HOLE PITCH:</th>
                                                        <td>40 X 35mm, aligned</td>
                                                    </tr>
                                                    <tr>
                                                        <th>HOLE DIAMETER:</th>
                                                        <td>8 x 60mm| 4,6,10mm</td>
                                                    </tr>
                                                    <tr>
                                                        <th>DEPTH:</th>
                                                        <td>Through</td>
                                                    </tr>
                                                </table>
                                            </div>
	    		                            <div className="col-sm-4">	
	                                            <table>
                                                    <tr>
                                                        <th>PERFORATION:</th>
                                                        <td>10.9%</td>
                                                    </tr>
                                                    <tr>
                                                        <th>NRC:</th>
                                                        <td>0.55 - 0.60</td>
                                                    </tr>   
                                                    <tr>
                                                        <th>FLEECE:</th>
                                                        <td>0.2mm</td>
                                                    </tr>
             	                                </table>
                                            </div>
	                                    </div>
	                                </div>				
                                    <div className="line"></div>	<br />
	                            </div>
	                        </div>
	                        <div className="col-sm-12 col-md-12 col-lg-12">
		                        <label className="ac-label" htmlFor="ac-4">
                                    <b>Wood</b><br />DESIGNER ACOUSTIC PANEL<i></i>
                                </label>
		    	                <div className="article ac-content">
			                        <Sliderwoodtecdesigner />
	    			                <h1>Wood<span style={{color: "red"}}>TEC</span> - DESIGNO Series</h1>
		    		                <p>Designo acoustic panels comes in painted finish only and improves the visual outlook of the spaces by a great deal. The semicircular element associated with it can be used as a design element and the tongue and groove connection gives the plank a connection free, attractive appearance. The panels are available in a wide variety of design options for the customers to choose.</p>	
                                    <h3><strong>Features</strong></h3>
		                            <div className="line"></div>
		                            <div className="row pad">
		                                <div className="col-md-6 col-sm-12 panelfeatures">
	                                        <ul>
		                                        <li>Reduces reverberation and echo </li>
		                                        <li>Low formaldehyde and moisture resistant</li>
		                                        <li>Highly aesthetic</li>
		                                        <li>High impact resistance</li>
    		                                </ul>
	    	                            </div>
                                        <div className="col-md-6 col-sm-12 d-none d-md-block panelfeatures">
	                                        <ul>
		                                        <li>Better speech intelligibility</li>
		                                        <li>Washable and recyclable</li>
		                                        <li>Easily installable</li>
		                                        <li>Highly sustainable</li>
		        		                    </ul>
            		                    </div>
	                                </div>		
		                            <br />			
                                    <h3><strong>Product Details </strong></h3>
		                            <div className="line"></div>
				                    <div className="col-sm-12">
				                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#dp-home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#dp-menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#dp-menu3">DATASHEET</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#dp-menu2">BROCHURE</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#dp-menu4">COLOR CHART</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="dp-home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
	                                                    <tr>
                                                            <th scope="row">Core</th>
                                                            <td>MDF Standard | MDF Fire rated | MDF Moisture Resistant</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Absorbent</th>
                                                            <td>Mineral wool (50mm)</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Thickness</th>
                                                            <td>16 mm | 18 mm | 20 mm</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Finishes</th>
                                                            <td>Painted</td>
                                                        </tr>
        	                                            <tr>
                                                            <th scope="row">Sizes</th>
                                                            <td>Standard:  2430 x 160 mm<br />
			                                                    Optional : 2430 x 60 mm
                                                            </td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Density</th>
                                                            <td>750 kg/m3 </td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Flammability</th>
                                                            <td>B-s2 d0</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Installation</th>
                                                            <td>Walls: Omega metallic profiles, Clips, Wood battens<br />
                                                                Ceilings: Standard system T24, Clips 
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
		                                    </div>
                                            <div id="dp-menu1" className="container tab-pane fade"><br />
            		                            <h3>Designo Series - Type 1</h3>
                                                <br />
		    		                            <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Absorbent<br />(plenum)</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">50mm</th>
                                                            <td>0.38</td>
                                                            <td>0.80</td>
                                                            <td>1.04</td>
                                                            <td>0.86</td>
                                                            <td>0.72</td>
                                                            <td>0.59</td>
                                                            <td>0.85</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">200mm</th>
                                                            <td>0.30</td>
                                                            <td>0.78</td>
                                                            <td>0.92</td>
                                                            <td>0.88</td>
                                                            <td>0.78</td>
                                                            <td>0.58</td>
                                                            <td>0.82</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
		                                    </div>
                                            <div id="dp-menu2" className="container tab-pane fade"><br />
                                                <br />
                                                <h3>Brochure</h3>
		                                        <br />
		                                        <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/woodtec/brochure-wd.pdf" className="brochure">Download</a>
                                            </div>
    	                                    <div id="dp-menu3" className="container tab-pane fade"><br />
                                                <br />
                                                <h3> Datasheet</h3>
		                                        <br />
		                                        <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/woodtec/designo.pdf" className="brochure">Download</a>
                                            </div>
	                                        <div id="dp-menu4" className="container tab-pane fade"><br />
                                                <br />
                                                <h3>Color Chart</h3>
    		                                    <br />
	    	                                    <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/woodtec/color-chart-f.pdf" className="brochure">Download</a>
                                            </div>
                                        </div>
		                            </div>			
	                                <div className="col-sm-12">	
                                        <div className="row">
                                            <div className="col-12 col-sm-4 mb-3">
                                                <img src={Woodtecdesigner3} alt="Woodtecdesigner3" />
                                            </div>
                                            <div className="col-12 col-sm-4">
                                                <img src={Woodtecdesigner4} alt="Woodtecdesigner4" />
                                            </div>
                                        </div>    
                                        <br /><br />  
                                        <div className="row colorcard ">			
                                            <div className="col-sm-4">	
                                                <h1>Designo Series</h1>
                                                <h1 style={{color: "red"}}>TYPE 1</h1>
                                                <p><Link to="/#">Datasheet</Link></p>
                                            </div>
                                            <div className="col-sm-4">	
	        	                                <table>
                                                    <tr>
                                                        <th>HOLE PITCH:</th>
                                                        <td>8 X 32mm, alternate</td>
                                                    </tr>
                                                    <tr>
                                                        <th>HOLE DIAMETER:</th>
                                                        <td>10mm</td>
                                                    </tr>
                                                    <tr>
                                                        <th>SPACING:</th>
                                                        <td>6mm</td>
                                                    </tr>
                                                </table>
                                            </div>
		                                    <div className="col-sm-4">	
		                                        <table>
                                                    <tr>
                                                        <th>PERFORATION:</th>
                                                        <td>5.4%</td>
                                                    </tr>
                                                    <tr>
                                                        <th>NRC:</th>
                                                        <td>0.85</td>
                                                    </tr>
                                                    <tr>
                                                        <th>FLEECE:</th>
                                                        <td>0.2mm</td>
                                                    </tr>
     	                                        </table>
                                            </div>
	                                    </div>
    	                            </div>				
                                </div>
	                        </div>
    	                </div>
                        <hr />
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default woodtec;