import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Melamine1 from '../../images/inner/mel_comp.webp';
import Melamine2 from '../../images/inner/mel_comp06.webp';
import './melamine-composite-panels.css';
import ScrollToTopButton from '../ScrollButton';
function melamine() {
    return (
      
    <>
    <Helmet>
    <title>Sound absorber - Melamine Foam Composite Sound Containment System </title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Melamine Foam Composite Sound Containment System" />
    <meta property="og:description" content="" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/mel_comp.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/melamine-composite-panels" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/melamine-composite-panels" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/melamine-composite-panels" />
    </Helmet>
    <Header />
    <section className="content" id="melamine">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Noise S.T.O.P. Noise™ Barrier Melamine Composite</h1>
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/mel_comp.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Melamine1} alt="Melamine Acoustics1" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Melamine Foam Composite Sound Containment System</h2>
                                <ul style={{marginleft: "1em"}}>                                                                
                                    <li>Sound Absorber</li>
                                    <li>Noise Barrier</li>
                                    <li>Washable Noise Containment</li>
                                    <li>Decoupler Isolates Vibration</li>
                                    <li>className A Fire Rated</li>                               
                                </ul> 
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/melaminecom.pdf" target="_blank">Product Specs</Link>
                                        </li>            
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Product Specs</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>Material</th>
                                            <td>1″ Melamine Acoustical Foam, Bonded to 1/8″ – 1lb/sq.ft., Mass Loaded Vinyl Noise Barrier with 1/4″ Melamine Foam Vibration Decoupler </td>
                                        </tr>
                                        <tr>
                                            <th>Pattern</th>
                                            <td>Convoluted or Flat Surface Pattern – SONEX™ Pattern Also Available </td>
                                        </tr>
                                        <tr>
                                            <th>Applications</th>
                                            <td>Ideally suited to line equipment enclosures. Commonly used to line walls and ceilings of utility or mechanical rooms to absorb and contain the noise source. Use where sound absorption, noise reduction and vibration control are needed.</td>
                                        </tr>
                                        <tr>
                                            <th>Thickness</th>
                                            <td>1-3/8″</td>
                                        </tr>
                                        <tr>
                                            <th>Nom. Sizes</th>
                                            <td>2′×4′ (11.33 lbs per panel) Special Sizes Available Upon Request</td>
                                        </tr>
                                        <tr>
                                            <th>Colors</th>
                                            <td>Standard – Natural White or Light Grey<br />
                                                Latex or HPC Paint – Black, Light Grey, Almond<br />
                                                Paint – Black, Brown, Beige, Light Gray (Custom Colors Also Available)
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Flammability</th>
                                            <td>ASTM E84, className A – Hypalon Coated Foam. Flame Spread: 25. Smoke Development: 185 </td>
                                        </tr>
                                        <tr>
                                            <th>Installation</th>
                                            <td>ASI S.T.O.P Noise Acoustical Adhesive. Use Mechanical Fasteners for Overhead Applications</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <p><Link className="btn btn-asi" to="reverb-demo">Reverberation Demonstration</Link></p>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="8">Melamine Composite Barrier Sound Absorption/Noise Reduction</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="8">Type B Mounting ASTM C423-90a</td>
                                        </tr>
                                        <tr className="colheader">
                                            <td></td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>NRC</td>
                                        </tr>
                                        <tr>
                                            <td>Melamine Composite</td>
                                            <td>0.13</td>
                                            <td>0.60</td>
                                            <td>0.81</td>
                                            <td>0.97</td>
                                            <td>1.00</td>
                                            <td>0.90</td>
                                            <td className="emphasis">0.85</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="8">Melamine Composite Barrier Sound Transmission Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="8">ASTM E413-73</td>
                                        </tr>
                                        <tr className="colheader">
                                            <td></td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>STC</td>
                                        </tr>
                                        <tr>
                                            <td>Melamine Composite</td>
                                            <td>17</td>
                                            <td>22</td>
                                            <td>20</td>
                                            <td>32</td>
                                            <td>31</td>
                                            <td>43</td>
                                            <td className="emphasis">28</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/mel_comp06.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Melamine2} alt="Melamine Acoustics2" />
                                </Link>
                            </div>
                        </div>
                        <hr className="d-none d-md-block d-lg-block d-xl-block" />
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default melamine; 