import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundroom from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import Cadlibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Sealant from '../../images/inner/as-29.jpg';
import Sealant2 from '../../images/inner/as-29-2.jpg';
import './acoustical-sealant.css';
import ScrollToTopButton from '../ScrollButton';
function acousticsealant() {
    return (
      
    <>
    <Helmet>
    <title>AcoustiSeal Acoustical Sealant - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="AcoustiSeal™ Acoustical Sealant" />
    <meta property="og:description" content="Acoustical Sealant Model #AS-29" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/as-29.jpg" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acoustical_sealant" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="acousticalsealant">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>AcoustiSeal™ Acoustical Sealant </h1>
                        <div className="row">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/as-29.jpg" data-lightbox="example-1">
                                <img className="example-image img-fluid" src={Sealant} alt="acoustic sealant" /></Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Acoustical Sealant Model #AS-29</h2>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>Exceeds LEED™ Building Requirements</li>
                                    <li>Low VOC</li>
                                    <li>Low Odor</li>
                                    <li>Non Toxic</li>
                                    <li>Cost Effective</li>
                                    <li>Essential for High STC Walls/Floors</li>
                                    <li>Convenient Large Size Tubes</li>
                                    <li>Acoustically Effective</li>
                                    <li>Remains Soft and Flexible When Dry</li>
                                    <li>Bonds to Wood, Concrete, Glass, Gypsum, Metal and Plastic</li>
                                    <li>Paintable</li>
                                    <li>Dispenses Easily</li>
                                    <li>Easy Cleanup With Soap and Water</li>
                                    <li>Stops Noise From Leaking Through Joints and Edges</li>
                                    <li>Will Not Crack</li>
                                    <li>When Used Properly, AcoustiSeal™ Acoustical Sealant Drastically Increases the Overall STC of Floor, Ceiling and Wall Assemblies</li>
                                </ul>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/AS-29_Sealant.pdf" className="ico-misc" target='_blank'>Product Specs</Link>
                                        </li>
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/AcoustiSeal_MSDS.pdf" target="_blank" className="ico-misc">Material Safety Data Sheet (MSDS)</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <h3>Product Specs</h3>
                                    <tbody>
                                    <tr>
                                        <th>Material</th>
                                        <td>28 oz. tube Acoustical Sealant.</td>
                                    </tr>
                                    <tr>
                                        <th>Coverage</th>
                                        <td>1/4″ Bead = 85 Linear Feet per Tube or 3/8″ Bead = 40 Linear Feet per Tube</td>
                                    </tr>
                                    <tr>
                                        <th>Features</th>
                                        <td>This acoustical caulk soundproofing sealant is essential for achieving high STC value for sound isolation.</td>
                                    </tr>
                                    <tr>
                                        <th>Applications</th>
                                        <td>This acoustical caulk soundproofing sealant can be used on wall/floor junctions to seal cracks and voids per laboratory test data, heating and A/C ducts, cold air returns, exposed joints, electrical and utility outlet boxes.</td>
                                    </tr>
                                    <tr>
                                        <th>Color</th>
                                        <td>White</td>
                                    </tr>
                                    <tr>
                                        <th>Flammability</th>
                                        <td>ASTM E-84 – 76, className A</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-12 text">
                                <h2>Instructions</h2>
                                <p><span>When using AcoustiSeal™ acoustical caulk soundproofing sealant, the goal is to create a completely sealed room. Think of the room you are sealing as a water tank.</span></p>
                                <ol style={{marginLeft: "1em", fontSize: ".9em"}}>
                                    <li>Make sure the surfaces that are to be sealed are clean.</li>
                                    <li>Cut the nozzle to create an appropriately sized hole.</li>
                                    <li>The image below shows three possible AcoustiSeal™ applications. Image “A” shows sealant under one layer of drywall on both sides. Image “B” is the same as “A” with the addition of a bead under the plate resulting in a better seal. Image “C” shows a multiple drywall layer install with beads under each layer and a heavy bead under the plate. This would yield an excellent seal.</li>
                                </ol>
                                <p><Link className="example-image-link" to="../../images/inner/as-29-2.jpg" data-lightbox="example-1">
                                <img className="example-image img-fluid" src={Sealant2} alt="acoustics products" /></Link></p>
                                <ol style={{marginLeft: "1em", fontSize: ".9em"}}>
                                    <li>Allow to dry typically 48 hours before painting.</li>
                                    <li>Drywall mud can be applied directly over AcoustiSeal™.</li>
                                    <li>Clean up with soap and water while still wet.</li>
                                </ol>
                                <h2>First Aid</h2>
                                <p><span>Keep this acoustical caulk soundproofing sealant out of reach of children. Wear eye protection.</span>
                                <br /><strong>Skin Contact:</strong><span>Wash affected area thoroughly with soap and water.</span>
                                <br /><strong>Eye Contact:</strong><span>Flush thoroughly with water and obtain medical attention.</span>
                                <br /><strong>Inhalation:</strong><span>Remove to fresh air.</span>
                                <br /><span>Seek medical attention if swallowed.</span></p>
                            </div>
                        </div>
                        <div className="line"></div>
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundroom} alt="Create Better Sounding Rooms" target="_blank" rel="noopener noreferrer" />
                                <div className="butn"><Link to="/bs-rooms-guide">Download eBook</Link></div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems" target="_blank" rel="noopener noreferrer" />
                                <div className="butn"><Link to="/noise-problems-guide">Download eBook</Link></div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={Cadlibrary} alt="CAD Library" target="_blank" rel="noopener noreferrer" />
                                <div className="butn"><Link to="/cad-library-guide">Access Product Cad Files</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acousticsealant;