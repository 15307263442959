import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Studiowindows1 from '../../images/inner/recording-studio-windows-350x288.webp';
import Studiowindows2 from '../../images/inner/acoustical-studio-window-200x200.webp';
import Studiowindows3 from '../../images/inner/4.webp';
import Studiowindows4 from '../../images/inner/5.webp';
import Studiowindows5 from '../../images/inner/6.webp';
import Studiowindows6 from '../../images/inner/sm_studio_8.webp';
import './studio-windows.css';
import ScrollToTopButton from '../ScrollButton';
function studiowindows() {
    return (
      
    <>
    <Helmet>
    <title>Studio Series Soundproof Interior Windows - Acoustical Surfaces </title>
    <meta name="description" content="Typical Applications of these Soundproof Studio Windows: Recording Studios, Commercial Windows, Industrial Military, Victim/Interrogation Room." />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Studio Series Soundproof Interior Windows" />
    <meta property="og:description" content="Engineered To Be The Markets First Affordable High Performance Acoustical Window" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/recording-studio-windows-350x288.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/studio-windows" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/studio-windows" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/studio-windows" />
    </Helmet>
    <Header />
    <section className="content" id="studiowindows">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Studio Series Soundproof Interior Windows</h1>
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/recording-studio-windows-350x288.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Studiowindows1} alt="Studio windows1" />
                                </Link>
                                <Link className="example-image-link" to="../../images/inner/acoustical-studio-window-200x200.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Studiowindows2} alt="Studio windows2" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Engineered To Be The Markets First Affordable High Performance Acoustical Window</h2>
                                <p><strong>Typical Applications of these Soundproof Studio Windows:</strong></p>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>Recording Studios</li>
                                    <li>Commercial Windows</li>
                                    <li>Industrial</li>
                                    <li>Military</li>
                                    <li>Victim/Interrogation Room</li>
                                </ul>
                                <p></p>
                                <p><strong>Other Acoustic Window Products Offered:</strong></p>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>
                                        <Link to="climate-seal-window-inserts" style={{color: "#0059a5"}}>Climate Seal – Window Inserts</Link>
                                    </li>
                                    <li>
                                        <Link to="cinema-port" style={{color: "#0059a5"}}>Cinema Port</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/08567_Sound_Control_Windows.doc" target="_blank">Spec Sheet</Link>
                                        </li>
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/Acoustical_Window.pdf" target="_blank">Product Specs</Link>
                                        </li>
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/studio_install.pdf" target="_blank">Installation Instructions</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row pad">
                            <div className="col-sm-12 text">
                                <h3>Features:</h3>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>A Truly Affordable High Performance Noise Control Window</li>
                                    <li>Performance Tested Certified at an Affordable Price</li>
                                    <li>STC Ratings of 45 to 59</li>
                                    <li>Custom Engineered Manufactured To Any Size</li>
                                    <li>Anodized Aluminum Frames with Standard Frame Finishes Of Natural Aluminum Bronze Also Available In Other Specialty Finishes</li>
                                    <li>Quick Turnaround Delivery Upon Order!</li>
                                    <li>Fast Easy Installation No Need For A Glazing Contractor Or Special Tools ASI Includes Installation Instructions Necessary Shop Drawings</li>
                                    <li>Custom Glass Available Safety Glass, Mirror Pane, Tinted, Etc. (Note: Using Custom Glass May Change The STC Value)</li>
                                    <li>Glass Shipped Separately to Avoid Breakage</li>
                                    <li>Easy Snap-In Glass Installation On Job Site</li>
                                </ul>
                            </div>
                        </div>
                        <div className="line"></div>
                        <div className="row pad">
                            <div className="col-sm-12 text">
                                <h3>Noise S.T.O.P.™ Window Model Studio 4</h3>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 text">
                                <img className="img-fluid" src={Studiowindows3} alt="Studio windows3" />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 text">      
                                <h3>Features:</h3>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>STC Rating Up To 45</li>
                                    <li>Custom Sized To Fit Your Needs</li>
                                    <li>Extra Large Units Available With Mullions</li>
                                    <li>Frames Available In Clear Anodized Or Bronze Anodized</li>
                                    <li>Optional Tinted, One-Way Mirror Pane, Safety Glass, Etc.</li>
                                    <li>4° Angled Glass For Max Acoustical Performance Optional Flat Glass Configuration</li>
                                </ul>
                                <h3>Window Specifications:</h3>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>1″ Insulated Glass Unit With 2 Pieces of 1/4″ Laminated Glass</li>
                                    <li>4-1/2″ Aluminum Frame</li>
                                    <li>Angled Glass For Maximum Acoustical Performance</li>
                                    <li>Acoustically Treated Frames Seals</li>
                                    <li>Class A-1 ASTM E-84 Nonflammable Sound Silencer Acoustical Frame Insert</li>
                                    <li>Fits Any Wall Thickness By Insetting Or Offsetting Acoustical Window</li>
                                </ul>
                            </div>
                        </div>
                        <div className="line"></div>
                        <div className="row pad">
                            <div className="col-sm-12 text">
                                <h3>Noise S.T.O.P.™ Window Model Studio 5</h3> 
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 text">
                                <img className="img-fluid" src={Studiowindows4} alt="Studio windows4" />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 text">
                                <h3>Features:</h3>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>STC Rating Up To 49 (<Link style={{color: "#0059a5"}} target="_blank" to="https://acousticalsurfaces.in/pdfs/OL 10-0930 report.pdf">Download Test</Link>)</li>
                                    <li>Custom Sized To Fit Your Needs</li>
                                    <li>Extra Large Units Available With Mullions</li>
                                    <li>Frames Available In Clear Anodized Or Bronze Anodized</li>
                                    <li>Optional Tinted, One-Way Mirror Pane, Safety Glass, Etc.</li>
                                </ul>
                                <h3>Window Specifications:</h3>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>3/8″ Laminated Glass With Air Cavity 1/4″ Laminated Glass</li>
                                    <li>4-1/2″ Aluminum Frame</li>
                                    <li>Acoustically Treated Frames Seals</li>
                                    <li>Class A-1 ASTM E-84 Nonflammable Sound Silencer Acoustical Frame Insert</li>
                                    <li>Fits Any Wall Thickness By Insetting Or Offsetting Acoustical Window</li>
                                </ul>
                            </div>
                        </div>
                        <div className="line"></div>
                        <div className="row pad">
                            <div className="col-sm-12 text">
                                <h3>Noise S.T.O.P.™ Window Model Studio 6</h3>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 text">
                                <img className="img-fluid" src={Studiowindows5} alt="Studio windows5" />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 text">
                                <h3>Features:</h3>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>STC Rating Up To 56 (<Link style={{color: "#0059a5"}} target="_blank" to="https://acousticalsurfaces.in/pdfs/OL 10-0936 report.pdf">Download Test</Link>)</li>
                                    <li>Custom Sized To Fit Your Needs</li>
                                    <li>Extra Large Units Available With Mullions</li>
                                    <li>Frames Available In Clear Anodized Or Bronze Anodized</li>
                                    <li>Optional Tinted, One-Way Mirror Pane, Safety Glass, Etc.</li>
                                    <li>4° Angled Glass For Max Acoustical Performance Optional Flat Glass Configuration</li>
                                </ul>
                                <h3>Window Specifications:</h3>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>One Insulated Glass Unit With 2 Pieces Of 1/4″ Laminated Glass Plus An Air Cavity And An Additional Pane Of 3/8″ Laminated Glass</li>
                                    <li>6-3/8″ Aluminum Frame</li>
                                    <li>Acoustically Treated Frames Seals</li>
                                    <li>Angled Flat Glass For Maximum Acoustical Performance</li>
                                    <li>Class A-1 ASTM E-84 Nonflammable Sound Silencer Acoustical Frame Insert</li>
                                    <li>Fits Any Wall Thickness By Insetting Or Offsetting Acoustical Window</li>
                                </ul>
                            </div>
                        </div>
                        <div className="line"></div>
                        <div className="row pad">
                            <div className="col-sm-12 text">
                                <h3>Noise S.T.O.P.™ Window Model Studio 8</h3>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 text">
                                <img className="img-fluid" src={Studiowindows6} alt="Studio windows6" />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 text">      
                                <h3>Features:</h3>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>STC Rating Up To 59 (<Link style={{color: "#0059a5"}} target="_blank" to="https://acousticalsurfaces.in/pdfs/OL 10-0937 report.pdf">Download Test</Link>)</li>
                                    <li>Custom Sized To Fit Your Needs</li>
                                    <li>Extra Large Units Available With Mullions</li>
                                    <li>Frames Available In Clear Anodized Or Bronze Anodized</li>
                                    <li>Optional Tinted, One-Way Mirror Pane, Safety Glass, Etc.</li>
                                    <li>4° Angled Glass For Max Acoustical Performance Optional Flat Glass Configuration</li>
                                </ul>
                                <h3>Window Specifications:</h3>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>One Insulated Glass Unit With 2 Pieces of 1/4″ Laminated Glass Plus An Air Cavity And An Additional Pane Of 3/8″ Laminated Glass</li>
                                    <li>12-7/8″ Aluminum Frame</li>
                                    <li>Acoustically Treated Frames Seals</li>
                                    <li>Angled Flat Glass For Maximum Acoustical Performance</li>
                                    <li>Class A-1 ASTM E-84 Nonflammable Sound Silencer Acoustical Frame Insert</li>
                                    <li>Fits Any Wall Thickness By Insetting Or Offsetting Acoustical Window</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default studiowindows;