import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Fiberglass from '../../images/inner/paitnub.webp';
import './painted-nubby-fiberglass-tiles.css';
import ScrollToTopButton from '../ScrollButton';
function painted() {
    return (
      
    <>
    <Helmet>
    <title>Painted Nubby Fiber Glass - Acoustical Ceiling Tile </title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Painted Nubby Fiber Glass" />
    <meta property="og:description" content="Acoustical Ceiling Tile" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/paitnub.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/painted-nubby-fiberglass-tiles" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/painted-nubby-fiberglass-tiles" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/painted-nubby-fiberglass-tiles" />
    </Helmet>
    <Header />
    <section className="content" id="painted">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Painted Nubby Fiber Glass</h1>  
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/paitnub.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Fiberglass} alt="Fiberglass acoustics" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Acoustical Ceiling Tile</h2>
                                <ul style={{marginleft: "1em"}}>                                                                
                                    <li>High Performance Acoustics</li>
                                    <li>Light Weight</li>
                                    <li>Durable Woven Facing</li>                       
                                </ul> 
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/paitnub.pdf" target="_blank">Product Specs</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Product Specs</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tr>
                                        <th>Material</th>
                                        <td>Semi-rigid fiberglass with a painted woven textured fiberglass facing.</td>
                                    </tr>
                                    <tr>
                                        <th>Size</th>
                                        <td>Nominal 2′×2′, 2′×4′, 4′×8′ </td>
                                    </tr>
                                    <tr>
                                        <th>Thickness</th>
                                        <td>1″ or 2″</td>
                                    </tr>
                                    <tr>
                                        <th>Applications</th>
                                        <td>Ceiling tile applications where high acoustical performance is required.</td>
                                    </tr>
                                    <tr>
                                        <th>Weight</th>
                                        <td>0.5 lb/sf for 1″, 1 lb/sf for 2″</td>
                                    </tr>
                                    <tr>
                                        <th>Flammability</th>
                                        <td>Per E-84, 25 f/s, Smoked developed 50</td>
                                    </tr>
                                    <tr>
                                        <th>Light Reflectance</th>
                                        <td>LR-1 (actual .87) </td>
                                    </tr>
                                    <tr>
                                        <th>Thermal Resistance </th>
                                        <td>1″ = R-4</td>
                                    </tr>
                                    <tr>
                                        <th>Maintenance</th>
                                        <td>Can be easily cleaned with soft brush or vacuum. </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <p>
                            <Link className="btn btn-asi" to="reverb-demo">Reverberation Demonstration</Link>
                        </p>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="9">Painted Nubby Fiberglass Noise Reduction Coefficient</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td></td>
                                            <td>Mount</td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>NRC</td>
                                        </tr>
                                        <tr>
                                            <td>1″</td>
                                            <td>E</td>
                                            <td>.69</td>
                                            <td>.95</td>
                                            <td>.85</td>
                                            <td>1.06</td>
                                            <td>1.11</td>
                                            <td>1.08</td>
                                            <td className="emphasis">.95</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default painted; 