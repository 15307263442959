import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import './acfabric-network.css';
import Mobsidebar from '../mobile-sidebar';
import Nw1 from '../../images/acfabric/nw1.jpg';
import Nw2 from '../../images/acfabric/nw2.jpg';
import Nw3 from '../../images/acfabric/nw3.jpg';
import Nw4 from '../../images/acfabric/nw4.jpg';
import Nw5 from '../../images/acfabric/nw5.jpg';
import Nw6 from '../../images/acfabric/nw6.jpg';
import Nw7 from '../../images/acfabric/nw7.jpg';
import ScrollToTopButton from '../ScrollButton';
function acfabricnet() {
    return (
      
    <>
    <Helmet>
    <title>Acoustic of Maine Network Acoustic Fabric - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="AcoustyFABRIC Network" />
    <meta property="og:description" content="Guilford of Maine Network 4158 Fabric Facings" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/acfabric/nw1.jpg" />
    <meta property="og:locale" content="en-in" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/acfabric-network" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/acfabric-network" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acfabric-network" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="acfabricnetwork">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
            	<div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
					<div className="col-sm-12 heading-fsorb">				
		                <h1>Acousty<span className="fabriwall">FABRIC</span> Network</h1>
                		<h4 className="mb-0"> <b>Guilford of Maine Network 4158 Fabric Facings</b></h4>
		                <p><span className="color:red;">(Colors Shown are Approximate Match to Material)</span></p>
	                    <br />
		                <div className="col-sm-3 cloths p-0"><button>Product Specifications</button></div>
	                </div>
		            <br /><br />
		            <div className="col-sm-12 cloths">
		                <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Nw1} alt="Hickory" />
                                <p className="text-center">Hickory 011</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Nw2} alt="Frost" />
                                <p className="text-center">Frost 020</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Nw3} alt="Cappuccino" />
                                <p className="text-center">Cappuccino 030</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Nw4} alt="Putty" />
                                <p className="text-center">Putty 031</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Nw5} alt="Mocha" />
                                <p className="text-center">Mocha 052</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Nw6} alt="Zinc" />
                                <p className="text-center">Zinc 060</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Nw7} alt="Harbor" />
                                <p className="text-center">Harbor 070</p>
                            </div>
                            <div className="col-sm-2 p-0 mx-2"></div>
                            <div className="col-sm-2 p-0 mx-2"></div>
                            <div className="col-sm-2 p-0 mx-2"></div>
                        </div>	
                    </div>
		            <br />
		            <hr />
                    <div className="col-12 d-flex justify-content-center backbtn">
                    	<Link to="/acoustyfabric"><button>Back to Acousty<span style={{color: "red"}}>FABRIC</span></button></Link>
                    </div>
                    <hr />
                    <div className="col-sm-12 application">
			            <h1>APPLICATION</h1>
	                    <div className="hover14 text-center justify-content-center">
		                <br />
  		                    <div className="col-sm-12">
			                    <figure className="m-0"><div className="tinted-image">CINEMA HALL</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image2">OFFICE ROOMS & HALLS</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image3">AUDITORIUM & EDUCATION</div></figure>
 		                    </div>
                        <br />
                        </div>
                    </div>
		            <br />
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acfabricnet;