import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import './soundproof-installation.css';
import Mobsidebar from '../mobile-sidebar';
import ScrollToTopButton from '../ScrollButton';
function soundproofins() {
    return (
      
    <>
    <Helmet>
    <title>Soundproofing Installation & Sound Silencer Installation Instructions</title>
    <meta name="description" content="" />
    <meta property="og:title" content="Sound Silencer Installation Instructions" />
    <meta property="og:description" content="" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.png" />
    <meta property="og:locale" content="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/soundproof-installation" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/soundproof-installation" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/sound_instalation" hreflang="en-ae" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="IN" />
    <meta name="geo.region" content="india" />
    </Helmet>
    <Header />
    <section className="content" id="installation">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Sound Silencer™ Installation Instructions</h1>
                        <div className="row">
                            <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12 text">
                                <h5 style={{paddingtop: "2em"}}>Wall Panels:</h5>
                                <p><Link style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/PEPP_Install.pdf" target="_blank">1. Sound Silencer™ (PEPP) Installation &amp; Cleaning Instructions</Link></p>
                                <p><Link style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/PEPPI_outdoor.pdf" target="_blank">2. Sound Silencer™ (PEPP) Installation Outdoor Cleaning Instructions</Link></p>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 d-none">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default soundproofins;