import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import './melamine-flat-faced-foam.css';
import Melamine1 from '../../images/inner/melamine-flat-foam.jpg';
import Melamine2 from '../../images/inner/mel_sw04.gif';
import Mobsidebar from '../mobile-sidebar';
import ScrollToTopButton from '../ScrollButton';
function melamineflat() {
    return (
      
    <>
    <Helmet>
    <title>Flat Faced Melamine Foam - Acoustical Surfaces </title>
    <meta name="description" content="" />
    <meta property="og:title" content="Acoustical Melamine Foam" />
    <meta property="og:description" content="" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/melamine-flat-foam.jpg" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/melamine-flat-faced-foam" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/melamine-flat-faced-foam" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/melamine-flat-faced-foam" />
    </Helmet>
    <Header />
    <section className="content" id="melamine">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Flat Faced Melamine Foam</h1>  
                        <div className="row">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/melamine-flat-foam.jpg" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Melamine1} alt="Melamine Flat Faced Foam1" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Acoustical Melamine Foam</h2>
                                <ul style={{marginleft: "1em"}}>                                                                
                                    <li>className A Fire Retardant</li>
                                    <li>High Performance Sound Absorber</li>
                                    <li>Fiber Free</li> 
                                    <li style={{color: "#cc0000"}}>Available as Hanging Acoustical Baffles</li>                             
                                </ul> 
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/Flat-Faced_Melamine_Foam.pdf" target="_blank">Product Specs</Link>
                                        </li>
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/098310-Flat-Faced-Melamine-Foam.doc">CSI Specifications</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <h3>Product Specs</h3>
                                    <tr>
                                        <th>Material</th>
                                        <td>Open Cell Melamine Acoustical Foam </td>
                                    </tr>
                                    <tr>
                                        <th>Pattern</th>
                                        <td>Flat </td>
                                    </tr>
                                    <tr>
                                        <th>Features</th>
                                        <td>Designed For Sound Absorption in a Wide Range of Frequencies. Increased Surface Absorption Area</td>
                                    </tr>
                                    <tr>
                                        <th>Applications</th>
                                        <td>Industrial, Commercial, Audio, OEM Residential Markets, Ceilings, Walls, Partitions, Sound/Recording Studios, Radio Stations, Board Rooms, Swimming Pools, Churches, Schools, Gun Ranges, Enclosures</td>
                                    </tr>
                                    <tr>
                                        <th>Thickness</th>
                                        <td>1″, 2″</td>
                                    </tr>
                                    <tr>
                                        <th>Sizes</th>
                                        <td>2′ × 2′ & 2′ × 4′ </td>
                                    </tr>
                                    <tr>
                                        <th>Edge Detail</th>
                                        <td>Square (also available with half-beveled edges by request) </td>
                                    </tr>
                                    <tr>
                                        <th>Density</th>
                                        <td>0.7lbs/cub. ft. </td>
                                    </tr>
                                    <tr>
                                        <th>Colors</th>
                                        <td>Standard – Natural White or Light Grey<br />
                                            Latex or HPC Paint – Black, Light Grey, Almond<br />
                                            Painted – Black, Brown, Beige, Light Gray (Custom Colors Also Available)
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Flammability</th>
                                        <td>ASTM E84, className A. Flame Spread: 6; Smoke Developed: 15</td>
                                    </tr>
                                    <tr>
                                        <th>Installation</th>
                                        <td>Acoustical Adhesive </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <p>
                            <Link className="btn btn-asi" to="/reverb-demo">Reverberation Demonstration</Link>
                        </p>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="8">ASI Flat Faced Melamine Foam Sound Absorption/Noise Reduction</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td></td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>NRC</td>
                                        </tr>
                                        <tr className="colheader">
                                            <td>1″</td>
                                            <td>0.10</td>
                                            <td>0.10</td>
                                            <td>0.30</td>
                                            <td>0.70</td>
                                            <td>0.80</td>
                                            <td>0.90</td>
                                            <td className="emphasis">0.60</td>
                                        </tr>
                                        <tr className="colheader">
                                            <td>2″</td>
                                            <td>0.10</td>
                                            <td>0.35</td>
                                            <td>0.90</td>
                                            <td>1.25</td>
                                            <td>1.15</td>
                                            <td>1.10</td>
                                            <td className="emphasis">1.00</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/mel_sw04.gif" data-lightbox="example-1">
                                    <img className="example-image img-fluid"src={Melamine2} alt="Melamine Flat Faced Foam2" />
                                </Link>
                            </div>
                        </div>
                        <hr className="d-none d-md-block d-lg-block d-xl-block" />
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default melamineflat; 