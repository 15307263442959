import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Fabriwallfabric from '../../images/fabwall/fabric1.webp';
import Cap from '../../images/fabwall/fabric3.webp';
import Usacollection from '../../images/fabwall/fabric4.webp';
import Card1 from '../../images/fabwall/card1.webp';
import Card2 from '../../images/fabwall/card2.webp';
import Card3 from '../../images/fabwall/card3.webp';
import Mobsidebar from '../mobile-sidebar';
import './fabriwallfabric.css';
import ScrollToTopButton from '../ScrollButton';
function fabriwallfabric() {
    return (
      
    <>
    <Helmet>
    <title>Fabric Wall -  fabric mounting system for walls & ceilings</title>
    <meta name="description" content="" />
    <meta property="og:title" content="FabriWALL FABRIC" />
    <meta property="og:description" content="We manufacture all kind of interior panel and upholstery fabrics using finest quality of Polyster and are available in over 500 different varieties." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/fabwall/fabric1.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/fabriwallfabric" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/fabriwallfabric" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/fabriwallfabric" hreflang="en-ae" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="IN" />
    <meta name="geo.region" content="india" />
    </Helmet>
    <Header />
    <section className="content" id="Fabriwallfabric">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
	                    <div className="col-sm-12 heading-fsorb">				
		                    <img src={Fabriwallfabric} alt="Fabriwall Fabric" />
		                    <div className="col-sm-12 fabric-body">
		                        <h1>Fabri<span className="fabriwall">WALL</span> FABRIC</h1>
    	                        <p>Although most of the fabric goes well with the FabriWALL System, it is important to choose the right fabric. We manufacture all kind of interior panel and upholstery fabrics using finest quality of Polyster and are available in over 500 different varieties. 
                                These fabrics are tested and have gained wide appreciation, owing to their exquisite designs, finish, tear strength and color fastness </p>
	    	                    <div className="row style-cards justify-content-center">	
		                            <div className="col-md-5 col-sm-12 featured-card">
			                            <div className="card">
				                            <div className="row nomargin">
			                                    <div className="col-sm-9 col-9">
                                                    <img className="card-img-top" src={Cap} alt="Card cap" />
                                                </div>
			                                    <div className="col-sm-3 col-3"></div>
				                            </div>
  			                                <div className="card-block">
    			                                <div className=" col card-text">
					                                <p><span type="button" onclick="window.open('https://acousticalsurfaces.in/pdfs/fabric-brochure-small.pdf')" >See more </span> For Fabric Collection</p>
                				                </div>
				                                <div className="row">
				                                    <div className="col-sm-7 col-7"><hr /></div>
					                                <div className="col-sm-5 col-5"><h1>INDIAN<br /> Collection</h1></div>
				                                </div>
    		                                </div>
			                            </div>
        		                    </div>	
    		                        <div className="col-md-5 col-sm-12 featured-card">
			    	                    <div className="card">
				                            <div className="row nomargin">
			                                    <div className="col-sm-6 col-6">
                                                    <img className="card-img-top" src={Usacollection} alt="USA Collection" />
                                                </div>
			                                    <div className="col-sm-6 col-6 relative">
							                        <h1>USA<br /> Collection</h1>
				                                    <div style={{width: "100%"}}>	
                                                        <div className="vl"></div>	
                                                    </div>
				                                    <p>Guilford of Maine and Terrate Fabric Selection</p>
			                                    </div>
				                            </div>
  			                                <div className="card-block">
    			                                <div className="card-text">
					                                <br />
					                                <p className="text-center"><span>See more</span> For catalogue</p>
				                                </div>
			                                </div>
			                            </div>
		                            </div>	
		                        </div>		
		                    </div>
	                    </div>
	                    <br /><br />
	                    <div className="col-sm-12">
	                        <p>Acoustical stretch fabric systems are in high demand in office spaces, lobbies, classNamerooms, theaters and more. They provide sound control that will last for years, making the spaces truly functional</p>
	                    </div>
	                    <br /><br />
	                    <div className="col-sm-12 application">
	                        <h1>APPLICATION</h1>
	                        <div className="hover14 text-center justify-content-center">
		                        <br />
  		                        <div className="col-sm-12">
    			                    <figure className="m-0"><div className="tinted-image">CINEMA HALL</div></figure>
 		                        </div>
                                <br />
  		                        <div className="col-sm-12">
            	                    <figure className="m-0"><div className="tinted-image2">OFFICE ROOMS & HALLS</div></figure>
 		                        </div>
                                <br />
  		                        <div className="col-sm-12">
        	                        <figure className="m-0"><div className="tinted-image3">AUDITORIUM & EDUCATION</div></figure>
 		                        </div>
                                <br />
	                        </div>
	                    </div>
	                    <div className="col-sm-12 featured-products1">
		                    <p>Fabri-Wall is the industry-leading acoustical stretched fabric mounting system for
			                    walls, ceilings and other interior surfaces.</p>
		                    <br /><br />
		                    <h4>FEATURED PRODUCTS</h4>
		                    <br /><br />
		                    <div className="row pb-5">
	    	                    <div className="col-md-4 col-sm-12 featured-card">
		                            <div className="text-center">
			  		                    <img className="card-img-top" src={Card1} alt="Fabri Wall Track" />
  			                            <div className="card-block1">
    			                            <h4 className="card-title">Fabri<span>Wall</span> TRACK</h4>
    			                            <p className="card-text">Tracks are on-site fabricated acoustical systems that enables fabric be stretched for interior works.</p>
                        			        <Link to="/fabriwallTrack" className="btn btn-danger">View Track</Link>
  			                            </div>
		                            </div>
		                        </div>
			                    <div className="col-md-4 col-sm-12 featured-card">
				                    <div className="text-center">
					                    <img className="card-img-top" src={Card2} alt="Finest Quality of Polyster" />
  			                            <div className="card-block1">
    			                            <h4 className="card-title">Fabri<span>Wall</span> FABRIC</h4>
    			                            <p className="card-text">We manufacture all kind of interior panel and upholstery fabrics using finest quality of Polyster</p>
    			                            <Link to="/fabriwallfabric" className="btn btn-danger">View Fabric</Link>
  			                            </div>
			                        </div>
			                    </div>
			                    <div className="col-md-4 col-sm-12 featured-card">
				                    <div className=" text-center">
					                    <img className="card-img-top" src={Card3} alt="Mitigates Sound" />
  			                            <div className="card-block1">
    			                            <h4 className="card-title">Fabri<span>Wall</span> PANEL</h4>
    			                            <p className="card-text">Fabriwall Panel is an interior acoustic solution that absorbs noise and mitigates sound reverberation,</p>
    			                            <Link to="/fabriwallpanel" className="btn btn-danger">View Panel</Link>
      			                        </div>
		                            </div>
		                        </div>
		                    </div>	
	                    </div>		
                    </div>
                </div>
            </div>
			<div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
		<ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default fabriwallfabric; 