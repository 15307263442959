import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Sealants1 from '../../images/inner/greenchoice-panel-subfloor-adhesive-sm-200x200.webp';
import Sealants2 from '../../images/inner/greenchoice-acoustical-sound-sealant-sm-200x200.webp';
import Sealants3 from '../../images/inner/wide_web_adhesive1-200x200.webp';
import Sealants4 from '../../images/inner/green-glue-group2-200x200.webp';
import Sealants5 from '../../images/inner/as-291-200x200.webp';
import './sealants-adhesive.css';
import ScrollToTopButton from '../ScrollButton';
function sealants() {
    return (
      
    <>
    <Helmet>
    <title>Sound Control Sealants and Adhesives - Acoustical sound sealants and acoustic foam spray</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Sound Control Sealants and Adhesives" />
    <meta property="og:description" content="To ensure that sound does not find a way to pass through gaps in floors, walls, and ceilings, acoustical sealants and adhesives are excellent options. Acoustical sound sealants and acoustic foam spray adhesives increase the noise control while creating a tight bond between surfaces." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/wide_web_adhesive1-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/sealants-adhesive" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/sealants-adhesive" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/sealants-adhesive" />
    </Helmet>
    <Header />
    <section className="content" id="adhesive">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Sound Control Sealants and Adhesives</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>To ensure that sound does not find a way to pass through gaps in floors, walls, and ceilings, acoustical sealants and adhesives are excellent options. Acoustical sound sealants and acoustic foam spray adhesives increase the noise control while creating a tight bond between surfaces.</p>
                                    <p>We carry a large selection of products engineered for eliminating sound transmission and sealing air gaps. Using these compounds when installing walls and floors increases sound deadening and noise that can be transmitted from one occupied space to another. In addition to outstanding performance, our products are environmentally safe, VOC-compliant, and a safe choice for occupants and the environment.</p>
                                    <p><Link to="/contact">Contact us</Link> today to learn more!</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>View Our Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Sealants1} alt="Green Choice Panel Adhesive" />
                                    <h5>GreenChoice Panel Adhesive</h5>
                                </Link>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Sealants2} alt="Green Choice Sealant" />
                                </Link>
                                <h5>GreenChoice Sealant</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Sealants3} alt="Acoustical Spray Adhesive" />
                                </Link>
                                <h5>Acoustical Spray Adhesive</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Sealants4} alt="Green Glue Damping Compound" />
                                </Link>
                                <h5>Green Glue Damping Compound</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Sealants5} alt="Acoustis Sealant" />
                                </Link>
                                <h5>AcoustiSeal™ Sealant</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default sealants; 