import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Greenglue from '../../images/inner/green-glue-group.webp';
import Mobsidebar from '../mobile-sidebar';
import './green-glue.css';
import ScrollToTopButton from '../ScrollButton';
function greenglue() {
    return (
      
    <>
    <Helmet>
    <title>Green Glue Viscoelastic Damping Compound</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Green Glue Viscoelastic Damping Compound" />
    <meta property="og:description" content="We are aware of no product available to the construction market which can deliver damping levels even 30% as high as those offered by Green Glue."/>
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/green-glue-group.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/green-glue" hreflang="en-ae" />    
    <link rel="alternate" href="https://www.acousticalsurfaces.in/green-glue" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/green-glue" />
    </Helmet>
    <Header />
    <section className="content" id="greenglue">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Green Glue</h1> 
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/green-glue-group.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Greenglue} alt="Green glue" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text ">
                                <h2>Viscoelastic Damping Compound</h2> 
                                <ul style={{marginleft: "1em"}}>                                                              
                                    <li>Non-Toxic</li>
                                    <li>No Mixing Required</li>
                                    <li>Simple, Familiar, “Caulk Gun” Application</li>
                                    <li>Considerably Reduced Application Cost</li>
                                    <li>Light Material Squeezes from Tubes Easily Low Worker Fatigue</li>
                                </ul>
                                <p>Green Glue is a liquid, waterborne, viscoelastic damping compound which represents the highest performance product of its type. We are aware of no product available to the construction market which can deliver damping levels even 30% as high as those offered by Green Glue. It is also significantly lower in applied cost than competitive products, getting a better cost/performance ratio in the damping of normal construction materials such as drywall, wood products, etc. Finally, it is remarkably tolerant to real-world application conditions and carries almost none of the burden of precision required by many damping systems.</p>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul>
                                        <li><Link to="https://acousticalsurfaces.in/pdfs/MSDS.pdf" target="_blank">MSDS</Link></li>
                                        <li><Link to="https://acousticalsurfaces.in/pdfs/DataSheet.pdf" target="_blank">Data Sheet</Link></li>
                                        <li><Link to="https://acousticalsurfaces.in/pdfs/Impact.pdf" target="_blank">The Impact of Green Glue</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Product Specs</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>Sizes</th>
                                            <td>29 oz tube (12 tubes per case) </td>
                                        </tr>
                                        <tr>
                                            <th>Active Content </th>
                                            <td>68% +/- 5%</td>
                                        </tr>
                                        <tr>
                                            <th>Working Time</th>
                                            <td>30 minutes*</td>
                                        </tr>
                                        <tr>
                                            <th>Cure Time</th>
                                            <td>7 days </td>
                                        </tr>
                                        <tr>
                                            <th>Viscosity</th>
                                            <td>Light Paste (65,000 cps) </td>
                                        </tr>
                                        <tr>
                                            <th>Odor</th>
                                            <td>Mild (Temporary) </td>
                                        </tr>
                                        <tr>
                                            <th>VOC </th>
                                            <td>2g/liter</td>
                                        </tr>
                                        <tr>
                                            <th>Flash Point</th>
                                            <td>200°F</td>
                                        </tr>
                                        <tr>
                                            <th>Application Temperature</th>
                                            <td>40-90°F </td>
                                        </tr>
                                        <tr>
                                            <th>Damping Factor</th>
                                            <td>Typically 0.50+ with 2 layers 5/8″ fire code drywall (3X higher than any competitive product we are aware of) </td>
                                        </tr>
                                        <tr>
                                            <th>Coverage</th>
                                            <td>Approximately 16 sq ft per tube at full coverage </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row pad duracoustic-content">
                            <div className="col-lg-4 text">
                                <h3>Walls and Ceiling:</h3>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}> 
                                    <li>Improved Sound Isolation</li>
                                    <li>Constructions With Outstanding Low Frequency Isolation Are Possible</li>
                                    <li>Thoroughly Lab Tested</li>
                                    <li>Eliminates Short Circuit Risk in Resilient Channel Walls</li>
                                    <li>Allows Construction of the Highest Performance Walls Possible (In Combination With Double Stud Walls)</li>
                                    <li>Reduction in Structure-Borne Sound</li>
                                    <li>Low Cost Product Great Performance</li>
                                </ul>
                            </div>
                            <div className="col-lg-4 text">
                                <h3>Floors:</h3>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}> 
                                    <li>Reduced Impact Noise</li>
                                    <li>Improved Resistance to Airborne Sound</li>
                                    <li>Lower In Cost Than Most Floor Treatments</li>
                                    <li>Reduction In Structure-Borne Sound</li>
                                </ul>
                            </div>
                            <div className="col-lg-4 text">
                                <h3>Other Applications:</h3>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}> 
                                    <li>Tremendous Performance in Risers, Equipment Platforms, Stages, and Other Unique Structures Found In Home Theaters</li>
                                    <li>DIY and OEM Loudspeaker Construction More Inert Cabinets With Damping Factors Up To 20× Higher Than Raw MDF</li>
                                </ul>
                            </div>
                        </div>
                        <hr />
                        <div className="row duracoustic-content">
                            <div className="col-md-12 col-sm-12 text">
                                <h3>Installation Tips:</h3>
                                <ol style={{marginleft: "1em", fontsize: ".9em"}}> 
                                    <li>Standard Performance Requires (2) 29 oz Tubes of Green Glue per 4′×8′ Sheet or Drywall</li>
                                    <li>For Enhanced Performance Use (3) 29 oz tubes per 4′×8′ Sheet of Drywall</li>
                                    <li>Simply Apply Product Between 2 Layers of Drywall and Mount Drywall to Existing Wall</li>
                                    <li>Applying<Link to="/acoustical_sealant" style={{color: "#0059a5"}}>Acoustical Sealant</Link>Around The Perimeter Will Also Enhance Wall Assembly</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default greenglue; 