import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Bh1 from '../../images/acfabric/bh1.jpg';
import Bh2 from '../../images/acfabric/bh2.jpg';
import Bh3 from '../../images/acfabric/bh3.jpg';
import Bh4 from '../../images/acfabric/bh4.jpg';
import Bh5 from '../../images/acfabric/bh5.jpg';
import Bh6 from '../../images/acfabric/bh6.jpg';
import Bh7 from '../../images/acfabric/bh7.jpg';
import Bh8 from '../../images/acfabric/bh8.jpg';
import Bh9 from '../../images/acfabric/bh9.jpg';
import Bh10 from '../../images/acfabric/bh10.jpg';
import Bh11 from '../../images/acfabric/bh11.jpg';
import Bh12 from '../../images/acfabric/bh12.jpg';
import Bh13 from '../../images/acfabric/bh13.jpg';
import Bh14 from '../../images/acfabric/bh14.jpg';
import './acfabric-beehave.css';
import ScrollToTopButton from '../ScrollButton';
function acfabricbeehave() {
    return (
      
    <>
    <Helmet>
    <title>Acousty Fabric Maine Beehave | Acoustic Fabric</title>
    <meta name="description" content="" />
    <meta property="og:title" content="AcoustyFABRIC Maine BeeHave" />
    <meta property="og:description" content="Guilford of Maine BeeHave 3948 Fabric Facings" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/acfabric/bh1.jpg" />
    <meta property="og:locale" content="en-in" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/acfabric-beehave" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/acfabric-beehave" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acfabric-beehave" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="acfabricbeehave">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
            	<div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
	                <div className="col-sm-12 heading-fsorb">				
		                <h1>Acousty<span className="fabriwall">FABRIC</span> Maine BeeHave</h1>
                		<h4 className="mb-0"> <b>Guilford of Maine BeeHave 3948 Fabric Facings</b></h4>
		                <p><span className="color:red;">(Colors Shown are Approximate Match to Material)</span></p>
	                    <br />
		                <div className="col-sm-3 cloths p-0"><button>Product Specifications</button></div>
	                </div>
					<br /><br />
		            <div className="col-sm-12 cloths">
		                <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Bh1} alt="Slate 2703" />
                                <p className="text-center">Slate 2703</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Bh2} alt="Plum 2801" />
                                <p className="text-center">Plum 2801</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Bh3} alt="Pine 2806" />
                                <p className="text-center">Pine 2806</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Bh4} alt="Pear 2698" />
                                <p className="text-center">Pear 2698</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Bh5} alt="Lemon 2805" />
                                <p className="text-center">Lemon 2805</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Bh6} alt="Lapis 2807" />
                                <p className="text-center">Lapis 2807</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Bh7} alt="Java 2704" />
                                <p className="text-center">Java 2704</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Bh8} alt="Gris 2701" />
                                <p className="text-center">Gris 2701</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Bh9} alt="Currant 2802" />
                                <p className="text-center">Currant 2802</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Bh10} alt="Concord 2808" />
                                <p className="text-center">Concord 2808</p>
                            </div>
                        </div>	
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Bh11} alt="Clementine 2804" />
                                <p className="text-center">Clementine 2804</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Bh12} alt="Carnelian 2803" />
                                <p className="text-center">Carnelian 2803</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Bh13} alt="Black 2702" />
                                <p className="text-center">Black 2702</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Bh14} alt="Azure 2696" />
                                <p className="text-center">Azure 2696</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2"></div>
                        </div>	
            		</div>
		            <br />
                    <hr />
                    <div className="col-12 d-flex justify-content-center backbtn">
                    	<Link to="/acoustyfabric"><button>Back to Acousty<span style={{color: "red"}}>FABRIC</span></button></Link>
                    </div>
                    <hr />
            		<div className="col-sm-12 application">
            			<h1>APPLICATION</h1>
	                    <div className="hover14 text-center justify-content-center">
		                <br />
  		                    <div className="col-sm-12">
			                    <figure className="m-0"><div className="tinted-image">CINEMA HALL</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image2">OFFICE ROOMS & HALLS</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image3">AUDITORIUM & EDUCATION</div></figure>
 		                    </div>
                        <br />
                       </div>
                    </div>
		            <br />
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acfabricbeehave;