import React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import './glossary-terms.css';
import ScrollToTopButton from '../ScrollButton';
function gloss() {
    return (
      
    <>
    <Helmet>
    <title>Acoustical Glossary of Terms - Acoustical Surfaces </title>
    <meta name="description" content="" />
    <meta property="og:title" content="Acoustical Glossary of Terms" />
    <meta property="og:description" content="This glossary of terms has been provided with a brief description, for the most part in non technical terms in an effort to remove some of the mystery surrounding Acoustics." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.png" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/glossary-terms" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/glossary-terms" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/glossary-terms" />
    </Helmet>
    <Header />
    <section className="content" id="glossary">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <div className="row glossary">
                            <div className="col-md-12">
                                <h1>Acoustical Glossary of Terms</h1>
                                <span><strong>This glossary of terms has been provided with a brief description, for the most part in non technical terms in an effort to remove some of the mystery surrounding Acoustics. While the explanations may not be totally correct in their literal interpretations it is hoped that the plain language approach will provide a better understanding of the terminology frequently used in the field of acoustics.</strong></span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>ACOUSTICS:</span><span> The science of Sound. Its production, transmission and effects. The branch of physics that treats the phenomena and laws of sounds as it effects people.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>ACOUSTICAL:</span><span> The properties of a material to absorb or reflect Sound (adjective) Acoustically, (Adverb).</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>ACOUSTICAL ANALYSIS:</span><span> A review of a space to determine the level of reverberation or reflected sound in the space (in seconds) influenced by the building materials used to construct the space. Also the amount of acoustical absorption required to reduce reverberation and noise.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>ACOUSTICAL CONSULTANT:</span><span> A professional usually with an engineering degree who is experienced in providing advice on acoustical requirements, and noise control in a variety of situations.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>ACOUSTICAL ENVIRONMENT:</span><span> The acoustical characteristics of a space or room influenced by the amount of acoustical absorption, or lack of it in the space.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>AIRBORNE NOISE:</span><span> Noise that arrives at a point of interest by propagation through the air.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>AIRBORNE SOUND:</span><span> Sound that reaches the point of interest by propagation through air.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>ARCHITECTURAL ACOUSTICS:</span><span> The control of noise in a building space to adequately support the communications function within the space and its effect on the occupants. The qualities of the building materials used determine its character with respect to distinct hearing.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>ARCHITECTURAL ACOUSTICS:</span><span>Deals with sound in rooms and building to make them quiet &#8211; or to provide improved conditions for listening and understanding speech or music.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>ARTICULATION className:</span><span> A single number rating used for comparing acoustical ceilings and acoustical screens for speech privacy purposes. AC values increase with increasing privacy and range from approximately 100-250. This classNameification supercedes Speech Privacy Noise Isolation className (NIC) rating method.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>ARTICULATION INDEX (AI):</span><span> A measure of speech intelligibility influenced by Acoustical Environment rated from 0.01 to 1.00.The higher the number the higher the intelligibility of words and sentences understood from 0-100%.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>ABSORPTION:</span><span> The properties of a material composition to convert sound energy into heat thereby reducing the amount of energy that can be reflected.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>AREA EFFECT:</span><span> Acoustical materials spaced apart can have greater absorption than same amount of material butted together. The increase in efficiency is due to absorption by soft exposed edges and also to diffraction of sound energy around panel perimeters.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>ASSISTIVE LISTENING DEVICE:</span><span> An electronic device that provides amplification of sound to a hearing impaired person. Device include personal hearing aids, magnetic induction loops, FM radio systems and infrared systems. All have advantages and disadvantages and some may be dependent on good acoustical environment for optimal performance.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>ATTENUATION:</span><span> The reduction of sound energy as a function of distance traveled. (See also Inverse Square Law).</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>A WEIGHTING:</span><span> An electronic filtering system in a sound meter that allows meter to largely ignore lower frequency sounds in a similar fashion to the way our ears do.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>AMBIENT NOISE/SOUND:</span><span> Noise level in a space from all sources such as HVAC or extraneous sounds from outside the space. Masking sound or low-level background music can contribute to ambient level of sound or noise.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>BACKGROUND NOISE:</span><span> The sum total of all noise generated from all direct and reflected sound sources in a space that can represent an interface to good listening and speech intelligibility. (Hearing impaired persons are especially victimized by background noise).</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>BAFFLE:</span><span> A free hanging acoustical sound absorbing unit. Normally suspended vertically in a variety of patterns to introduce absorption into a space to reduce reverberation and noise levels.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>BARRIER:</span><span> Anything physical or an environment that interferes with communication or listening. A poor acoustical environment can be a barrier to good listening and especially so for persons with a hearing impairment.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>BEL:</span><span> A measurement of sound intensity named in honor of Alexander Graham Bell. First used to relate intensity to a level corresponding to hearing sensation.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>BOOMINESS:</span><span> Low frequency reflections. In small rooms acoustical panels with air space behind can better help control low frequency reflectivity.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>CLOUD:</span><span> In acoustical industry terms, an acoustical panel suspended in a horizontal position from ceiling/roof structure. Similar to a baffle but in a horizontal position.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>COCKTAIL PARTY EFFECT:</span><span> Sound in a noisy crowded room generated mostly by conversation. Levels rise and fall as people compete with one another to be heard. Perception of speech can be nearly impossible in high levels of noise.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>COCHLEA:</span><span> A snail shaped mechanism in the inner ear that contain hair cells of basilar membrane that vibrate to aid in frequency recognition.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>CYCLE:</span><span> In acoustics, the cycle is the complete oscillation of pressure above and below the atmospheric static pressure.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>CYCLES PER SECOND:</span><span> The number of oscillations that occur in the time frame of one second. (See FREQUENCY.) Low frequency sounds have fewer and longer oscillations.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>DAMPING:</span><span> The dissipation of vibratory energy in solid media and structures with time or distance. It is analogous to the absorption of sound in air.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>DECIBEL (dB):</span><span> Sound level in decibels as a logarithmic ratio. Sound intensity described in decibels. i.e.:</span> 
                                <br /><br />
                                <p>Breathing – 5 dB</p>
                                <p>Office Activity – 50 dB</p>
                                <p>Jet Aircraft During Takeoff at 300′ Distance – 130 dB</p>
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>DEFLECTION:</span><span> The distance an elastic body or spring moves when subjected to a static or dynamic force. Typical units are inches or mm.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>DEAF:</span><span> Loss of auditory sensation with or without use of assistive listening device. Loss of hearing more severe than is generally characterized as “Hearing Impaired”.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>DIFFUSION:</span><span> The scattering or random reflection of a sound wave from a surface. The directions of reflected sound is changed so that listeners may have sensation of sound coming from all directions at equal levels.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>EAR:</span><span> An incredible hearing mechanism consisting of outer, middle and inner ear segments that cause sound pressures to be picked up by the ear that are transmitted through auditory nerves where signals are interpreted by brain as sound.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>ECHO:</span><span> Reflected sound producing a distinct repetition of the original sound. Echo in mountains is distinct by reason of distance of travel after original signal has ceased.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>ECHO FLUTTER:</span><span> Short echoes in a small reverberative spaces that produce a clicking, ringing or hissing sound after the original sound signal has ceased. Flutter echoes may be present in long narrow spaces with parallel walls.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>EQUAL LOUDNESS CONTOURS:</span><span> Curves represented in graph form as a function of sound level and frequency which listeners perceive as being equally loud. High frequency sounds above 2000 Hz are more annoying. Human hearing is less sensitive to low frequency sound. (See also PHON.)</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>FLAME SPREAD:</span><span> classNameification indicating propagation of flame across a sample compared to flame propagation across concrete panels and red oak. Results are obtained through an ASTM E84 or UL723 test.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>FLANKING:</span><span> The transmission of sound around the perimeter or through holes within partitions (or barriers) that reduces the otherwise obtainable sound transmission loss of a partition. Examples of flanking paths within buildings are ceiling plena above partitions; ductwork, piping, and electrical conduit penetrations through partitions; back-to-back electrical boxes within partitions, window mullions, etc.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>FIELD SOUND TRANSMISSION className (FSTC):</span><span> A sound transmission rating obtained under “real-life” conditions. The general method to obtain this rating is almost the same as the method used in laboratory conditions. Procedures, however, have been added to take into account the differences between field conditions and laboratory conditions (e.g. flanking paths, absorption, modal distribution, etc.).</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>FREE FIELD:</span><span> Sound waves from a source outdoors where there are no obstructions.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>FREQUENCY:</span><span> The number of oscillations or cycles per unit of time. Acoustical frequency is usually expressed in units of Hertz (Hz) where one Hz is equal to one cycle per second.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>FREQUENCY ANALYSIS:</span><span> An analysis of sound to determine the character of the sound by determining the amount of sounds at various frequencies that make up the overall sound spectrum. i.e.: Higher Frequency Sound or Pitch vs. Low Frequency.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>HEARING IMPAIRMENT:</span><span> A degree of hearing loss, temporary or permanent due to many causes. Hearing loss can be caused by illness, disease, or by exposure to excessively high noise levels. Affects 25-50 million people in USA of all ages. Hearing impairment as generally used means a hearing loss of a mild, moderate, or severe degree as apposed to “Deafness” which is generally described as little or no residual hearing with or without the aid of an assistive listening device. Hearing Impaired persons are particularly victimized by long reverberation times.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>HEARING RANGE:</span>
                                <br /><br />
                                <p> 16-20000 Hz (Speech Intelligibility)</p>
                                <p> 600-4800 Hz (Speech Privacy)</p>
                                <p> 250-2500 Hz (Typical Small Table Radio)</p>
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>HERTZ (Hz):</span><span> Frequency of sound expressed by cycles per second. (See CYCLE).</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>IMPACT ISOLATION className (IIC):</span>
                                <span> The methods to measure the degree of impact noise isolation provided by a floor/ceiling assembly, in laboratory conditions, are described in the ASTM E 492 or ISO 140/6 standards. For field measurements refer to ASTM E 1007 or ISO 140/7. The impacts for these measurements are produced by the “Standard Tapping Machine”, an electrically operated mechanism consisting of five 0.5 kg hammers which fall regularly and freely onto floor surface from 40 mm height at a rate of 10 impacts/second. The sound pressure levels generated in the room directly below the floor/ceiling assembly undergoing testing are then measured, for each of the 16 third-octave-bands between 100 Hz and 3150 Hz, and they are normalized according to:</span>
                                <br /><br />
                                <p> An absorption equal to 10 metric Sabins, or</p>
                                <p> A reverberation time of 0.5 seconds (ISO 140/7)</p>
                                <span>The Normalized Impact Sound Pressure Levels (NISPL) are then plotted on a standard graph.</span>
                                <br /><br />
                                <span>The IIC rating of the tested floor/ceiling assemblers determined by sliding the classNameification curve on the graph representing the normalized sound pressure levels, until the following conditions described in the ASTM E 989 (ISO 717/2) standards, are met:</span>
                                <br /><br />
                                <p>The sum of the deviations above the normalizing curve should not exceed 32 dB.</p>
                                <p>The maximum deviation above the normalizing curve should not exceed 8 dB (see previous note on the classNameification of the isolation of airborne noise according to the ISO standard).</p>
                                <span>When the IIC contour is positioned in such a way that these two requirements are satisfied the Impact Isolation className (IIC) can be obtained by reading the normalized impact sound pressure level at the intersection of the IIC contour frequencies of 500 Hz and by subtracting this value from the number 110.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>IMPACT SOUND:</span>
                                <span> The sound produced by the collision of two solid objects. Typical sources are footsteps, dropped objects, etc., on an interior surface (wall, floor, or ceiling) of a building.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>INTENSITY:</span><span> (See LOUDNESS).</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>INVERSE SQUARE LAW:</span><span> Sound levels fall off with distance traveled. Sound level drops off 6 dB from source point for every doubling of distance.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>LIVE END/DEAD END:</span><span> An acoustical treatment plan for rooms in which one end is highly absorbent and the other end is reflective and diffusive.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>LOUDNESS:</span><span> The average deviation above and below the static value due to sound wave is called sound pressure. The energy expended during the sound wave vibration is called intensity and is measured in intensity units. Loudness is the physical resonance to sound pressure and intensity.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>MASKING:</span><span> The process by which the threshold of hearing of one sound is raised due to the presence of another. </span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>MASS:</span><span> The fundamental property of a material relevant to sound transmission loss through that material. Generally, the more massive the material, the greater the sound transmission loss.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>MOUNTING:</span><span> Standards established by ASTM to represent typical installation for purpose of testing materials. i.e.: a mounting test specimen mounted directly to test room surface. D mounting furred out to produce air space behind.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>NOISE:</span><span> Unwanted sound that is annoying or interferes with listening. Not all noise needs to be excessively loud to represent an annoyance or interference.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>NOISE CRITERIA (NC):</span><span> Noise criteria curves used to evaluate existing listening conditions at ear level by measuring sound levels at loudest locations in a room. NC criteria can be referred to equivalent dBA levels. NC curves are critical to persons with hearing loss.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>NOISE ISOLATION className (NIC):</span><span> A single number rating of the degree of speech privacy achieved through the use of an acoustical ceiling and sound absorbing screens in an open office. NIC has been replaced by the Articulation className (AC) rating method.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>NORMALIZED NOISE ISOLATION className (NNIC):</span><span> The Normalized Noise Isolation className (NNIC) rating is obtained by applying the ASTM E 413 classNameifiction standards to the Normalized Noise Reduction (NNR) values measured on a partition.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>NORMALIZED NOISE REDUCTION (NNR):</span><span> Noise Reduction normalized as a function of a 0.5 second reverberation time in the receiving room. Where:</span>
                                <br /><br />
                                <p>NNR = NR + 10 log (RT/0.5)</p>
                                <p>NNR = Normalized Noise Reduction</p>
                                <p>NR = Noise Reduction</p>
                                <p>RT = Reverberation Time in the Receiving Room</p>
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>NOISE REDUCTION (NR):</span><span> The amount of noise that is reduced through the introduction of sound absorbing materials. The level (in decibels) of sound reduced on a logarithmic basis.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>NOISE REDUCTION COEFFICIENT (NRC):</span><span> The NRC of an acoustical material is the arithmetic average to the nearest multiple of 0.05 of its absorption coefficients at 4 one third octave bands with center frequencies of 250, 500, 1000, 2000 Hertz.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>OCTAVE BANDS:</span><span> Sounds that contain energy over a wide range of frequencies are divided into sections called bands. A common standard division is in 10 octave bands identified by their center frequencies 31.5, 63, 125, 250, 500, 1000, 2000, 4000 Hz.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>OTO:</span><span> Pertaining to the ear.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>OTOLOGIST:</span><span> A doctor specializing in the structor, disorders and treratment of the ear.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>OTOLARYNGOLIST:</span><span> A doctor specializing in disorders and treatment of the ear nose and throat disorders.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>PHON:</span><span> Loudness contours. A subjective impression of equal loudness by listeners as a function of frequency and sound level (dB). An increase in low frequency sound will be perceived as being much louder than an equivalent high frequency increase.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>PITCH:</span><span> The perceived auditory sensation of sounds expressed in terms of high or low frequency stimulus of the sound.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>PRESBYCUSIS:</span><span> The loss of hearing due primarily to the aging process. High frequency loss is frequently a result of early hearing loss.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>REFLECTION:</span><span> The amount of sound wave energy (sound) that is reflected off a surface. Hard non-porous surfaces reflect more sound than soft-porous surfaces. Some sound reflection can enhance quality of signal of speech and music. (See Echo).</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>RESONANCE:</span><span> The emphasis of sound at a particular frequency.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>RESONANT FREQUENCY:</span><span> A frequency at which resonance exists.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>REVERBERATION:</span><span> The time taken for sound to decay 60 dB to 1/1,000,000 of its original sound level after the sound source has stopped. Sound after it has ended will continue to reflect off surfaces until the wave loses enough energy by absorption to eventually die out. Reverberation time is the basic acoustical property of a room which depends only on its dimensions and the absorptive properties of its surfaces and contents. Reverberation has an important impact on speech intelligibility.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>REVERBERATION TIME:</span><span> Sound after it is ended at the source will continue to reflect off surfaces until the sound wave loses energy by absorption to eventually die out.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>SABIN:</span><span> A unit of sound absorption based of one square foot of material. Baffles are frequently described as providing X number of sabins of absorption based on the size of the panel tested, through the standard range of frequencies 125-4000 Hz. The number of sabins developed by other acoustical materials are determined by the amount of material used and its absorption coefficients.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>SABINE FORMULA:</span><span> A formula developed by Wallace Clement Sabine that allows designers to plan reverberation time in a room in advance of construction and occupancy. Defined and improved empirically the Sabine Formula is T=0.049(V/A) where T = reverberation time or time required (for sound to decay 60 dB after source has stopped) in seconds. V = Volume of room in cubic feet. A = Total square footage of absorption in sabins.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>SEPTUM:</span><span> A thin layer of material between 2 layers of absorptive material. i.e.: foil, lead, steel, etc. that prevents sound wave from piercing through absorptive material.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>SIGNAL TO NOISE RATIO:</span><span> Is the sound level at the listeners ear of a speaker above the background noise level. The inverse square law impacts on the S/N ratio. Signal to Noise Ratios are important in classNamerooms and should be in range of 15 to 20 dB.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>SMOKE DEVELOPED INDEX:</span><span> classNameification that relates to a comparison of smoke development of a particular material compared to concrete panels and red oak. Results are obtained through an ASTM E84 or UL723 test. </span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>SOUND:</span><span> Sound is an oscillation in pressure, stress particle displacement, particle velocity in a medium – in room temperature. (In air speed of sound is 1125′/second or one mile in 5 seconds.) Sound produces an auditory sensation caused by the oscillation.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>SOUND ABSORPTION:</span><span> The property possessed by materials, objects and air to convert sound energy into heat. Sound waves reflected by a surface causes a loss of energy. That energy not reflected is called its absorption coefficient.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>SOUND ABSORPTION COEFFICIENT:</span><span> The fraction of energy striking a material or object that is not reflected. For instance if a material reflects 70% of the sound energy incident upon its surface, then its Sound Absorption Coefficient would be 0.30.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>SOUND BARRIER:</span><span> A material that when placed around a source of noise inhibits the transmission of that noise beyond the barrier. Also, anything physical or an environment that interferes with communication or listening. For example, a poor acoustical environment can be a barrier to good listening and especially so for persons with a hearing impairment. </span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>SOUND LEVEL:</span><span> A subjective measure of sound expressed in decibels as a comparison corresponding to familiar sounds experienced in a variety of situations.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>SOUND PRESSURE:</span><span> The total instantaneous pressure at a point in space, in the presence of a sound wave, minus the static pressure at that point.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>SOUND PRESSURE LEVEL:</span><span> The sound pressure level, in decibels, of a sound is 20 times the logarithm to the base 10 of the ratio of the sound pressure to the reference pressure. The reference pressure shall be explicitly stated and is defined by standards.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>SOUNDPROOFING:</span><span> Building materials that make structures impervious to sound or insulates against sound.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>SOUND LEVEL METER:</span><span> A device that converts sound pressure variations in air into corresponding electronic signals. The signals are filtered to exclude signals outside frequencies desired.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>SPEECH:</span><span> The act of speaking. Communication of thoughts and feelings by spoken words. </span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>SPEECH PRIVACY:</span><span> The degree to which speech is unintelligible between offices. Three ratings are used, Confidential, Normal (Non obtrusive), Minimal.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>SPL: SOUND PRESSURE LEVEL:</span><span> Quantity used to describe the loudness of a sound. The sound pressure level is expressed in decibels and is measured with a sound level meter. For example, a converstaion between two people inside an average-size room will produce an average “A” weighted sound pressure level of 50 to 55 lb.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>SOUND TRANSMISSION className (STC):</span><span> A single-number rating obtained by classNameifying the measured values of Sound Transmission Loss in accordance with ASTM Standard E 413, “classNameification for Sound Rating Insulations”. It provides a quick indication of the performance of a partition for certain common sound insulation problems.</span>
                                <br /><br />
                                <span>To determine the Sound Transmission className (STC) in conformance to the ASTM E 413 (lSO 71 7/1) one must slide the STC contour along its Y-axis of the graph on which the transmission loss curve is plotted until the following conditions are met:</span>
                                <br /><br />
                                <p>The sum of the deviation below the STC contour does not exceed 32 dB.</p>
                                <p>No deviation below the STC contour exceeds 8 dB.</p>
                                <strong>Note:</strong><span> The ISO standard excludes this last requirement. One should indicate however in the test report, the frequencies a which a difference of 8 dB or more occurs between the noise reduction curve and the STC contour.</span>
                                <span>When the STC contour is positioned in such a way that these two requirements are satisfied the sound transmission className can be obtained by reading the transmission loss value at the intersection of the STC contour at the frequency of 500 Hz. This value corresponds to the STC of the partition.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>SOUND TRANSMISSION LOSS (TL):</span><span> The difference between the sound power level incident on a partition and that transmitted through that partition.</span>
                                <br /><br />
                                <p>TL = LW incident−LW transmitted TL = NR + 10 log S/A OR</p>
                                <p>TL = Sound Transmission Loss WHERE:</p>
                                <p>LW = Sound Power Level</p>
                                <p>NR = Noise Reduction</p>
                                <p>S = Surface area of the partition</p>
                                <p> A = Acoustical absorption present in by the receiving room (in Sabins)</p>
                                <span>The standards for measuring Sound Transmission Loss are:</span>
                                <br />
                                <strong>Laboratory Measurements:</strong><span> ASTM E 90 ISO 140/1, /2, /3</span>
                                <br />
                                <strong>Field Measurements:</strong><span> ASTM E 336 ISO 140/4, /5</span>
                                <br /><br />
                                <span>The Sound Transmission Loss (TL) of a partition can be obtained in laboratory conditions by following the guidelines given below, in conformance to the ASTM E 90 standard.</span>
                                <br /><br />
                                <p>For each one-third-octave-band, measure the noise reduction (NR) provided by the partition.</p>
                                <p> Measure the quantity of acoustical absorption in the receiving room (where the sound power levels are transmitted).</p>
                                <p> Transform the values of noise reduction to sound transmission loss, using the correction 10*log (S/A).</p>
                                <br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>SOUNDPROOFING:</span><span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}><span> </span></span><span className="emphasis-header"><span>B</span></span><span className="emphasis-header"><span>uilding materials that make any structure impervious to sound or, insulates against the transmission or production of unwanted sound (noise).</span></span>
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>SPECTRUM:</span><span> The description of a sound wave’s components of frequency and amplitude.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>STRUCTURE BORNE NOISE:</span><span> Noise that arrives at a point of interest by propagation through a solid structure.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>TIME WEIGHTED AVERAGE (TWA):</span><span> The yardstick used by the Occupational Safety and Health Administration (OSHA) to measure noise levels in the workplace. It is equal to a constant sound level lasting eight hours that would cause the same hearing damage as the variable noises that a worker is actually exposed to. (This hearing loss, of course, occurs over long-term exposures.) Same as LOSHA.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>ULTRASOUNDS:</span><span> Sounds of a frequency higher than 20,000 Hz. The frequency region containing these frequencies is called the ultrasonic region.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>VIBRATION:</span><span> A force which oscillates about some specified reference point. Vibration is commonly expressed in terms of frequency such as cycles per second (cps), Hertz (Hz), cycles per Minute (cpm) or (rpm) and Strokes per Minute (spm). This is the number of oscillations which occurs in that time period. The amplitude is the magnitude or distance of travel of the force.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>VIBRATION ISOLATOR:</span><span> A resilient support that tends to isolate a mechanical system from steady state excitation.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>VOLUME:</span><span> The Cubic space of a room bounded by walls, floors, and ceilings determined by Volume = Length × Width × Height of space. Volume influences reverberation time.</span>
                                <br /><br />
                                <span className="emphasis-header" style={{fontweight: "bold", color: "#cc0000"}}>WAVELENGTH:</span><span> Sound that passes through air it produces a wavelike motion of compression and Parefaction. Wavelength is the distance between two identical positions in the cycle or wave. Similar to ripples or waves produced by dropping two stones in water. Length of sound wave varies with frequency. Low frequency equals longer wavelengths.</span><br /><br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default gloss;