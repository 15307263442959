import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Electronics1 from '../../images/inner/soundscreen-white-noise-machine-200x200.webp';
import Electronics2 from '../../images/inner/PAA31-200x200.webp';
import Electronics3 from '../../images/inner/remote-sound-testing-device1.webp';
import Electronics4 from '../../images/inner/noise_reduction_headphones1-200x200.webp';
import Electronics5 from '../../images/inner/dcs_4001-200x200.webp';
import Electronics6 from '../../images/inner/smart-sms-front-sm1-200x157.webp';
import './sound-control-electronics.css';
import ScrollToTopButton from '../ScrollButton';
function soundcontrol() {
    return (
      
    <>
    <Helmet>
    <title>Sound Control Electronic Devices for Sound Control </title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Sound Control Electronics" />
    <meta property="og:description" content="Many different types of electronic devices are used throughout the acoustical industry for sound control, analysis, or inspection." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/soundscreen-white-noise-machine-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/electronics" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/sound-control-electronics" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/sound-control-electronics" />
    </Helmet>
    <Header />
    <section className="content" id="electronics">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Sound Control Electronics</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>Many different types of electronic devices are used throughout the acoustical industry for sound control, analysis, or inspection. In order to determine or provide the most effective noise control solution, a variety of electronic devices are available.</p>
                                    <p>Testing and analysis devices are the first step in determining accurate noise levels. Once a diagnosis is made, a suitable system can be designed and installed. For temporary noise masking, we offer electronic noise reduction devices that create the sound of white noise or work to block out background noise completely. Active noise control products erase lower frequency sound waves by creating an out of phase wave to cancel the ambient noise. For example, a white noise machine ensures privacy through random sound to prevent accidental eavesdropping. Active noise control products, including noise cancelling headphones, are an easy, portable way to increase comfort and privacy.</p>
                                    <p>We offer a diverse variety of options to test, analyze, block out, or mask unwanted sound. Some of our best-selling testing devices and electronic noise reduction products can be found below. <Link to="/contact-us">Contact us</Link> today to learn more.</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>View Our Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Electronics1} alt="Soundscreen White Noise Machine" />
                                </Link>
                                <h5>SoundScreen™ White Noise Machine</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Electronics2} alt="PAA-3 Sound Level Meter" />
                                </Link>
                                <h5>PAA-3 sound level meter</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Electronics3} alt="Diagnoise System" />
                                </Link>
                                <h5>Diagnoise™ System</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Electronics4} alt="Noise Reduction Headphones" />
                                </Link>
                                <h5>Noise Reduction Headphones</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Electronics5} alt="The Seeker 400" />
                                </Link>
                                <h5>The Seeker 400</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Electronics6} alt="Sound Masking Systems" />
                                </Link>
                                <h5>Sound Masking Systems</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default soundcontrol; 