import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Ft1 from '../../images/acfabric/ft1.jpg';
import Ft2 from '../../images/acfabric/ft2.jpg';
import Ft3 from '../../images/acfabric/ft3.jpg';
import Ft4 from '../../images/acfabric/ft4.jpg';
import Ft5 from '../../images/acfabric/ft5.jpg';
import Ft6 from '../../images/acfabric/ft6.jpg';
import Ft7 from '../../images/acfabric/ft7.jpg';
import Ft8 from '../../images/acfabric/ft8.jpg';
import Ft9 from '../../images/acfabric/ft9.jpg';
import Ft10 from '../../images/acfabric/ft10.jpg';
import Ft11 from '../../images/acfabric/ft11.jpg';
import Ft12 from '../../images/acfabric/ft12.jpg';
import Ft13 from '../../images/acfabric/ft13.jpg';
import Ft14 from '../../images/acfabric/ft14.jpg';
import Ft15 from '../../images/acfabric/ft15.jpg';
import Ft16 from '../../images/acfabric/ft16.jpg';
import Ft17 from '../../images/acfabric/ft17.jpg';
import Ft18 from '../../images/acfabric/ft18.jpg';
import Ft19 from '../../images/acfabric/ft19.jpg';
import Ft20 from '../../images/acfabric/ft20.jpg';
import Ft21 from '../../images/acfabric/ft21.jpg';
import Ft22 from '../../images/acfabric/ft22.jpg';
import Ft23 from '../../images/acfabric/ft23.jpg';
import Ft24 from '../../images/acfabric/ft24.jpg';
import Ft25 from '../../images/acfabric/ft25.jpg';
import Ft26 from '../../images/acfabric/ft26.jpg';
import Ft27 from '../../images/acfabric/ft27.jpg';
import Ft28 from '../../images/acfabric/ft28.jpg';
import Ft29 from '../../images/acfabric/ft29.jpg';
import Ft30 from '../../images/acfabric/ft30.jpg';
import Ft31 from '../../images/acfabric/ft31.jpg';
import Ft32 from '../../images/acfabric/ft32.jpg';
import Ft33 from '../../images/acfabric/ft33.jpg';
import Ft34 from '../../images/acfabric/ft34.jpg';
import Ft35 from '../../images/acfabric/ft35.jpg';
import Ft36 from '../../images/acfabric/ft36.jpg';
import Ft37 from '../../images/acfabric/ft37.jpg';
import Ft38 from '../../images/acfabric/ft38.jpg';
import Ft39 from '../../images/acfabric/ft39.jpg';
import Ft40 from '../../images/acfabric/ft40.jpg';
import Ft41 from '../../images/acfabric/ft41.jpg';
import Ft42 from '../../images/acfabric/ft42.jpg';
import Ft43 from '../../images/acfabric/ft43.jpg';
import Ft44 from '../../images/acfabric/ft44.jpg';
import Ft45 from '../../images/acfabric/ft45.jpg';
import Ft46 from '../../images/acfabric/ft46.jpg';
import Ft47 from '../../images/acfabric/ft47.jpg';
import Ft48 from '../../images/acfabric/ft48.jpg';
import Ft49 from '../../images/acfabric/ft49.jpg';
import Ft50 from '../../images/acfabric/ft50.jpg';
import Ft51 from '../../images/acfabric/ft51.jpg';
import Ft52 from '../../images/acfabric/ft52.jpg';
import Ft53 from '../../images/acfabric/ft53.jpg';
import Ft54 from '../../images/acfabric/ft54.jpg';
import Ft55 from '../../images/acfabric/ft55.jpg';
import Ft56 from '../../images/acfabric/ft56.jpg';
import Ft57 from '../../images/acfabric/ft57.jpg';
import Ft58 from '../../images/acfabric/ft58.jpg';
import Ft59 from '../../images/acfabric/ft59.jpg';
import Ft60 from '../../images/acfabric/ft60.jpg';
import Ft61 from '../../images/acfabric/ft61.jpg';
import Ft62 from '../../images/acfabric/ft62.jpg';
import Ft63 from '../../images/acfabric/ft63.jpg';
import Ft64 from '../../images/acfabric/ft64.jpg';
import Ft65 from '../../images/acfabric/ft65.jpg';
import Ft66 from '../../images/acfabric/ft66.jpg';
import Ft67 from '../../images/acfabric/ft67.jpg';
import Ft68 from '../../images/acfabric/ft68.jpg';
import Ft69 from '../../images/acfabric/ft69.jpg';
import './acfabric-fr701.css';
import ScrollToTopButton from '../ScrollButton';
function acfabricfr() {
    return (
      
    <>
    <Helmet>
    <title>Acoustic of Maine Fabric FR701 - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="AcoustyFABRIC FR 701" />
    <meta property="og:description" content="Guilford of Maine FR 701 2100 Fabric Facings" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/acfabric/ft1.jpg" />
    <meta property="og:locale" content="en-in" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/acfabric-fr701" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/acfabric-fr701" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acfabric-fr701" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="acfabricfr701">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
            	<div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
	                <div className="col-sm-12 heading-fsorb">				
		                <h1>Acousty<span className="fabriwall">FABRIC</span> FR 701</h1>
                		<h4 className="mb-0"> <b>Guilford of Maine FR 701 2100 Fabric Facings</b></h4>
		                <p><span className="color:red;">(Colors Shown are Approximate Match to Material)</span></p>
	                    <br />
		                <div className="col-sm-3 cloths p-0"><button>Product Specifications</button></div>
	                </div>
		            <br /><br />
		            <div className="col-sm-12 cloths">
		                <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft1} alt="Silver Papier" />
                                <p className="text-center">Silver Papier 538</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft2} alt="Pearl" />
                                <p className="text-center">Pearl 481</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft3} alt="Eggshell" />
                                <p className="text-center">Eggshell 144</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft4} alt="Buff" />
                                <p className="text-center">Buff 460</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft5} alt="Bone" />
                                <p className="text-center">Bone 748</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft6} alt="Coin" />
                                <p className="text-center">Coin 794</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft7} alt="Cement Mix" />
                                <p className="text-center">Cement Mix 750</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft8} alt="Quartz" />
                                <p className="text-center">Quartz 380</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft9} alt="Vanilla Neutral" />
                                <p className="text-center">Vanilla Neutral 403</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft10} alt="Wheat" />
                                <p className="text-center">Wheat 130</p>
                            </div>
                        </div>	
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft11} alt="Opal" />
                                <p className="text-center">Opal 394</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft12} alt="Dsilver Neutral" />
                                <p className="text-center">DSilver Neutral 406</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft13} alt="Desert Sand" />
                                <p className="text-center">Desert Sand 758</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft14} alt="Straw" />
                                <p className="text-center">Straw 747</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft15} alt="Light Moss" />
                                <p className="text-center">Light Moss 754</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft16} alt="Grey Mix" />
                                <p className="text-center">Grey Mix 238</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft17} alt="Steel Grey" />
                                <p className="text-center">Steel Grey 471</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft18} alt="Moleskin" />
                                <p className="text-center">Moleskin 796</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft19} alt="Sunshine" />
                                <p className="text-center">Sunshine 797</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft20} alt="Earth" />
                                <p className="text-center">Earth 792</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft21} alt="Flannel" />
                                <p className="text-center">Flannel 795</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft22} alt="Medium Grey" />
                                <p className="text-center">Medium Grey 298</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft23} alt="Apricot Neutral" />
                                <p className="text-center">Apricot Neutral 404</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft24} alt="Terra" />
                                <p className="text-center">Terra 751</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft25} alt="Dune" />
                                <p className="text-center">Dune 749</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft26} alt="Black" />
                                <p className="text-center">Black 408</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft27} alt="Chocolate" />
                                <p className="text-center">Chocolate 793</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft28} alt="Pumice" />
                                <p className="text-center">Pumice 423</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft29} alt="Cinnabar" />
                                <p className="text-center">Cinnabar 422</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft30} alt="Leaf" />
                                <p className="text-center">Leaf 755</p>
                            </div>
                        </div>	
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft31} alt="Stream" />
                                <p className="text-center">Stream 757</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft32} alt="Eucalyptus" />
                                <p className="text-center">Eucalyptus 468</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft33} alt="Blue Neutral" />
                                <p className="text-center">Blue Neutral 401</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft34} alt="Blue Papier" />
                                <p className="text-center">Blue Papier 539</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft35} alt="Cherry Neutral" />
                                <p className="text-center">Cherry Neutral 400</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft36} alt="Verte Papier" />
                                <p className="text-center">Verte Papier 561</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft37} alt="Lake" />
                                <p className="text-center">Lake 756</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft38} alt="Lavender Neutral" />
                                <p className="text-center">Lavender Neutral 405</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft39} alt="Crystal Blue" />
                                <p className="text-center">Crystal Blue 175</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft40} alt="Rose Qartz" />
                                <p className="text-center">Rose Quartz 390</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft41} alt="Green Neutral" />
                                <p className="text-center">Green Neutral 402</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft42} alt="Ultramarine" />
                                <p className="text-center">Ultramarine 470</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft43} alt="Amethyst" />
                                <p className="text-center">Amethyst 424</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft44} alt="Bayberry" />
                                <p className="text-center">Bayberry 486</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft45} alt="Bronze" />
                                <p className="text-center">Bronze 545</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft46} alt="Aquamarine" />
                                <p className="text-center">Aquamarine 381</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft47} alt="Wedgewood" />
                                <p className="text-center">Wedgewood 150</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft48} alt="Lilac" />
                                <p className="text-center">Lilac 752</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft49} alt="Cobalt" />
                                <p className="text-center">Cobalt 420</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft50} alt="Sienna" />
                                <p className="text-center">Sienna 475</p>
                            </div>
                        </div>	
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft51} alt="Chrome Green" />
                                <p className="text-center">Chrome Green 549</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft52} alt="Baltic" />
                                <p className="text-center">Baltic 153</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft53} alt="Violet" />
                                <p className="text-center">Violet 753</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft54} alt="Red" />
                                <p className="text-center">Red 799</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft55} alt="Deep Burgundy" />
                                <p className="text-center">Deep Burgundy 556</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft56} alt="Blue Spruce" />
                                <p className="text-center">Blue Spruce 467</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft57} alt="Blue Plum" />
                                <p className="text-center">Blue Plum 553</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft58} alt="Iris" />
                                <p className="text-center">Iris 798</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft59} alt="Yellow" />
                                <p className="text-center">Yellow 744</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft60} alt="Chartreuse" />
                                <p className="text-center">Chartreuse 734</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft61} alt="Lime" />
                                <p className="text-center">Lime 721</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft62} alt="Fir" />
                                <p className="text-center">Fir 743</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft63} alt="Teal" />
                                <p className="text-center">Teal 742</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft64} alt="Sapphire" />
                                <p className="text-center">Sapphire 745</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft65} alt="Sky" />
                                <p className="text-center">Sky 740</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft66} alt="Cardinal" />
                                <p className="text-center">Cardinal 738</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft67} alt="Orange" />
                                <p className="text-center">Orange 746</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft68} alt="Claret Accent" />
                                <p className="text-center">Claret Accent 418</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Ft69} alt="White" />
                                <p className="text-center">White 224</p>
                            </div>
                            <div className="col-sm-2 p-0 mx-2"></div>
                        </div>	
            		</div>
		            <br />
		            <hr />
                    <div className="col-12 d-flex justify-content-center backbtn">
                    	<Link to="/acoustyfabric"><button>Back to Acousty<span style={{color: "red"}}>FABRIC</span></button></Link>
                    </div>
                    <hr />
                    <div className="col-sm-12 application">
			            <h1>APPLICATION</h1>
	                    <div className="hover14 text-center justify-content-center">
		                <br />
  		                    <div className="col-sm-12">
			                    <figure className="m-0"><div className="tinted-image">CINEMA HALL</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image2">OFFICE ROOMS & HALLS</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image3">AUDITORIUM & EDUCATION</div></figure>
 		                    </div>
                        <br />
		                </div>
                    </div>
		            <br />
                </div>     
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acfabricfr;