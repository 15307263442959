import React, { useEffect, useState } from 'react';

function ScrollToTopButton() {

    const [isVisible, setIsVisible] = useState(false);
    const [isTablet, setIsTablet] = useState(window.innerWidth <= 768);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleScroll = () => {
        const viewportHeight = window.innerHeight;
        const scrollPositionToShow = viewportHeight / 2;
        if (window.pageYOffset > scrollPositionToShow) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const handleResize = () => {
        setIsTablet(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        handleResize(); 

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {isVisible && !isTablet &&
                <button className="btn btn-primary scroll-to-top" onClick={scrollToTop}
                    style={{
                        position: 'fixed',
                        bottom: '160px',
                        right: '40px',
                        backgroundColor: 'red',
                        border: 'none',
                        zIndex: 1000,
                        padding: '10px 15px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <i className="fa fa-arrow-up"></i>
                </button>
            }
        </>
    );
}

export default ScrollToTopButton;
