import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Quietabsorber from '../../images/inner/exterior-quilted-curtain-absorber.webp';
import './quilted-exterior-absorbers.css';
import ScrollToTopButton from '../ScrollButton';
function quilted() {
    return (
      
    <>
    <Helmet>
    <title>Exterior Quilted Grade Curtain Fiberglass Absorbers</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="QFA Absorptive Exterior Grade Curtain" />
    <meta property="og:description" content="Exterior Grade Quilted Fiberglass Absorbers reduce the reflection of sound waves by covering reflective surfaces with a suitably UV protected, porous, sound absorbing product." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/exterior-quilted-curtain-absorber.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/quilted-exterior-absorbers" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/quilted-exterior-absorbers" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/quilted-exterior-absorbers" />
    </Helmet>
    <Header />
    <section className="content" id="quilted">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>QFA Absorptive Exterior Grade Curtain</h1> 
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/exterior-quilted-curtain-absorber.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Quietabsorber} alt="Quilted Exterior Absorbers" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Exterior Quilted Fiberglass Absorbers</h2>
                                <ul style={{marginleft: "1em"}}>                        
                                    <li>Superior UV and Weather Resistance</li>
                                    <li>Available In Four Facing Colors</li>
                                    <li>Heavy-Duty Vinyl-Coated-Polyester Facing on Both Sides</li>
                                    <li>2″ Thick Fiberglass Core</li>
                                    <li>NRC Rating .75</li>                        
                                </ul> 
                                <p></p>
                                <p>
                                    Exterior Grade Quilted Fiberglass Absorbers reduce the reflection of sound waves 
                                    by covering reflective surfaces with a suitably UV protected, porous, sound 
                                    absorbing product. The exterior Quilted Fiberglass Absorber consists of a 
                                    heavy-duty vinyl-coated-polyester facing on both sides of a 2″ thick quilted fiberglass. 
                                    Wall panels are constructed with grommets across the top and bottom, and are sewn 
                                    with an exterior grade thread.
                                </p>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/QFA-Absorptive-Exterior-Grade-Curtain.pdf" target="_blank">Product Specs</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Product Specs</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tr>
                                        <th>Material</th>
                                        <td>Fiberglass core, faced with heavy-duty vinyl-coated-polyester (VCP) on both sides. </td>
                                    </tr>
                                    <tr>
                                        <th>Pattern</th>
                                        <td>Quilted Diamond Pattern</td>
                                    </tr>
                                    <tr>
                                        <th>Features</th>
                                        <td>Effective, Durable, UV Resistant, Weather Resistant, Sound Absorbing </td>
                                    </tr>
                                    <tr>
                                        <th>Applications</th>
                                        <td>Exterior Grade faced quilted fiberglass absorbers are used to reduce reverberant noise on permanent outdoor applications such as enclosing HVAC equipment, dust collectors or similar machinery behind a manufacturing plant where UV resistance is required. It is commonly installed over wood fencing or on brick or masonry walls of buildings adjacent to a job site or a noisy piece of machinery.</td>
                                    </tr>
                                    <tr>
                                        <th>Thickness</th>
                                        <td>2" </td>
                                    </tr>
                                    <tr>
                                        <th>Temperature Range</th>
                                        <td>-40°F to +180°F </td>
                                    </tr>
                                    <tr>
                                        <th>Roll Size</th>
                                        <td>48″ Wide × 25′ Long</td>
                                    </tr>
                                    <tr>
                                        <th>Weight</th>
                                        <td>0.50 lb psf</td>
                                    </tr>
                                    <tr>
                                        <th>Color</th>
                                        <td>Gray, Tan, Black, Beige</td>
                                    </tr>
                                    <tr>
                                        <th>Installation</th>
                                        <td>Grommet Hangers</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="8">Absorptive Exterior Curtain Sound Absorption Performance ASTM C 423</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td></td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>NRC</td>
                                        </tr>
                                        <tr>
                                            <td>2″ Absorptive Exterior Curtain</td>
                                            <td>.46</td>
                                            <td>.94</td>
                                            <td>.85</td>
                                            <td>.64</td>
                                            <td>.47</td>
                                            <td>.33</td>
                                            <td className="emphasis">.75</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colspan="8">Absorptive Exterior Curtain Sound Transmission Loss ASTM E-90 E 413</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td></td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>STC</td>
                                        </tr>
                                        <tr>
                                            <td>2″ Absorptive Exterior Curtain</td>
                                            <td>9</td>
                                            <td>14</td>
                                            <td>19</td>
                                            <td>21</td>
                                            <td>27</td>
                                            <td>34</td>
                                            <td className="emphasis">21</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default quilted; 