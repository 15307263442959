import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Ceilingtiles1 from '../../images/inner/sound-silencer-ceiling-pool-sm.webp';
import Ceilingtiles2 from '../../images/inner/New-World-Ceiling-Panels-3-web-200x200.webp';
import Ceilingtiles3 from '../../images/inner/New-World-Ceiling-Panels-4-web-200x200.webp';
import Ceilingtiles4 from '../../images/inner/Charcoal_up_lg-200x200.webp';
import Ceilingtiles5 from '../../images/inner/white_up_lg1-200x200.webp';
import Ceilingtiles6 from '../../images/inner/ss_graph.webp';
import './sound-silencer-ceiling-tiles.css';
import ScrollToTopButton from '../ScrollButton';
function soundsilencerbaffles() {
    return (
      
    <>
    <Helmet>
    <title>Sound Silencer Ceiling Tiles - Soundproof ceilings and Acoustical wall panels </title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Sound Silencer Ceiling Tiles" />
    <meta property="og:description" content="When soundproofing is essential for noise and sound control, Sound Silencer™ soundproofing ceilings and acoustical ceiling tiles provide the control you expect. " />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/sound-silencer-ceiling-pool-sm.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/sound-silencer-ceiling-tiles" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/sound-silencer-ceiling-tiles" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/sound-silencer-ceiling-tiles" />
    </Helmet>
    <Header />
    <section className="content" id="silencertiles">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Sound Silencer™ Ceiling Tiles</h1>
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/sound-silencer-ceiling-pool-sm.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Ceilingtiles1} alt="Sound Silencer Ceiling Tiles1" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Acoustical Ceiling Tiles</h2>
                                <ul style={{paddingleft: "1em"}}>
                                    <li>Class A Fire Retardant</li>
                                    <li>No Fiberglass– Non-Fibrous</li>
                                    <li>Moisture Resistant– Indoor-Outdoor</li>
                                    <li>Impact Resistant</li>
                                    <li>Lightweight Sound Absorbing Ceiling Tiles</li>
                                </ul>
                                <p>When soundproofing is essential for noise and sound control, Sound Silencer™ soundproofing ceilings and acoustical ceiling tiles provide the control you expect. Large noisy areas, such as gymnasiums and work floors can now be effectively managed with soundproof ceilings and acoustical wall panels.</p>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li><Link target="_blank" to="https://acousticalsurfaces.in/pdfs/PEPP-Sound-Silencer-Ceiling-Panels.pdf" className="ico-misc">Product Specs</Link></li>
                                        <li><a  target="_blank" rel="noopener noreferrer" href="https://www.acousticalsurfaces.com/csi_specs/sound-silencer/Sound-Silencer-Ceiling-Tile-Specification.doc" className="ico-misc">CSI specification</a></li>
                                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.acousticalsurfaces.com/sound_silencer/sound_architect_resource.html" className="ico-misc">CSI, CAD, and Revit Files</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 fleximages">
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/New-World-Ceiling-Panels-3-web-200x200.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Ceilingtiles2} alt="Sound Silencer Ceiling Tiles2" />
                                    </Link>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/New-World-Ceiling-Panels-4-web-200x200.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Ceilingtiles3} alt="Sound Silencer Ceiling Tiles3" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <h3>Color Options</h3>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 fleximages">
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/Charcoal_up_lg-200x200.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Ceilingtiles4} alt="Sound Silencer Ceiling Tiles4" />
                                    </Link>
                                </div>
                                <div className="imagebox">
                                    <Link className="example-image-link" to="../../images/inner/white_up_lg1-200x200.webp" data-lightbox="example-1">
                                        <img className="example-image img-fluid" src={Ceilingtiles5} alt="Sound Silencer Ceiling Tiles5" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Product Specs</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>Material</th>
                                            <td>Semi Rigid Porous Expanded Polypropylene Acoustical Bead Foam (P.E.P.P.).</td>
                                        </tr>
                                        <tr>
                                            <th>Pattern</th>
                                            <td>Non-Abrasive, Slightly Textured, Porous.</td>
                                        </tr>
                                        <tr>
                                            <th>Features</th>
                                            <td>These soundproofing ceiling tiles feature a lightweight, Impact Resistant, Moisture, Bacteria Fungi Resistant, Tackable Surface.</td>
                                        </tr>
                                        <tr>
                                            <th>Applications</th>
                                            <td>Use these sound dampening ceiling tiles for soundproofing ceilings of Gymnasiums, Auditoriums, Classrooms, Swimming Pools, Ice Arenas, Clean Rooms, Food Processing Plants, Food Prep Areas, Cafeterias Restaurants, Manufacturing Plants, Car Washes, Rooftop and Machine Enclosures, Gun Ranges, Dog Kennels, Locker Rooms.</td>
                                        </tr>
                                        <tr>
                                            <th>Thickness</th>
                                            <td> 1″  2″</td>
                                        </tr>
                                        <tr>
                                            <th>Sizes</th>
                                            <td>
                                                <p>Exact Cut: 24″ × 24″, 24″ × 48″<br /><br />
                                                    Nominal: 23¾″ × 23¾″, 23¾″ × 47¾″<br /><br />
                                                    Custom Sizes Available – Maximum Acoustical Ceiling Panel Size: 25″ × 49″ 
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Colors</th>
                                            <td>White, Charcoal.</td>
                                        </tr>
                                        <tr>
                                            <th>Flammability</th>
                                            <td>ASTM E84, Class A. 1″ – Flame Spread: 3,Smoke Developed: 84. 2″ – Flame Spread: 5, Smoke Developed: 11 3.</td>
                                        </tr>
                                        <tr>
                                            <th>Installation</th>
                                            <td>Glue up direct or as a lay-in acoustical tile in a suspended t-bar grid system..</td>
                                        </tr>
                                        <tr>
                                            <th>Weight</th>
                                            <td> 1″ – .25lbs/Sq ft; 2″ – .45lbs/Sq ft</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p><Link className="btn btn-asi" to="/reverb-demo">Reverberation Demonstration</Link></p>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="8">
                                                <h4 style={{fontweight: "bold"}}>Sound Silencer™ Acoustic Ceiling Tiles– Sound Absorption/Noise Reduction</h4>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="8" style={{fontsize: ".9em", textalign: "center"}}>Tested Using Type E400 Ceiling Mounting</td>
                                        </tr>
                                        <tr className="colheader">
                                            <td>Thickness</td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>NRC</td>
                                        </tr>
                                        <tr>
                                            <td>1″</td>
                                            <td>0.46</td>
                                            <td>0.59</td>
                                            <td>0.42</td>
                                            <td>0.49</td>
                                            <td>0.76</td>
                                            <td>0.86</td>
                                            <td className="emphasis">0.55</td>
                                        </tr>
                                        <tr>
                                            <td>2″</td>
                                            <td>0.51</td>
                                            <td>0.52</td>
                                            <td>0.52</td>
                                            <td>0.77</td>
                                            <td>0.89</td>
                                            <td>0.98</td>
                                            <td className="emphasis">0.70</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr style={{visibility: "hidden"}} />
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="8">
                                                <h4 style={{fontweight: "bold"}}>Sound Silencer™ Acoustic Ceiling Tiles– Sound Transmission Loss (STC)</h4>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td>Thickness</td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2.5KHz</td>
                                            <td>5KHz</td>
                                            <td>STC</td>
                                        </tr>
                                        <tr>
                                            <td>1″</td>
                                            <td>6</td>
                                            <td>5</td>
                                            <td>7</td>
                                            <td>8</td>
                                            <td>10</td>
                                            <td>15</td>
                                            <td className="emphasis">9</td>
                                        </tr>
                                        <tr>
                                            <td>2″</td>
                                            <td>9</td>
                                            <td>8</td>
                                            <td>10</td>
                                            <td>10</td>
                                            <td>17</td>
                                            <td>22</td>
                                            <td className="emphasis">13</td>
                                        </tr>
                                        <tr>
                                            <td>1″ w/ 5/8″ Gypsum<br />
                                                Both Sides
                                            </td>
                                            <td>27</td>
                                            <td>27</td>
                                            <td>29</td>
                                            <td>31</td>
                                            <td>32</td>
                                            <td>45</td>
                                            <td className="emphasis">32</td>
                                        </tr>   
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/ss_graph.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Ceilingtiles6} alt="Sound Silencer Ceiling Tiles6" />
                                </Link>
                            </div>
                        </div>
                        <hr className="d-none d-md-block d-lg-block d-xl-block" />
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default soundsilencerbaffles;