import React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Silentmod from '../../images/ductsound/duct1.webp';
import Sliderductsound from './slider-ductsound';
import Ductsound1 from '../../images/ductsound/duct2.webp';
import Ductsound2 from '../../images/ductsound/duct3.webp';
import Ductsound3 from '../../images/ductsound/duct4.webp'
import Ductsound4 from '../../images/ductsound/duct5.webp';
import Ductsound5 from '../../images/ductsound/duct6.webp';
import Ductsound6 from '../../images/ductsound/duct7.webp';
import Mobsidebar from '../mobile-sidebar';
import './style-ductsound.css';
import ScrollToTopButton from '../ScrollButton';
function duct() {
    return (
      
    <>
    <Helmet>
    <title>Acoustical DUCT Sound - helps to reduce noise issues</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="DUCTsound" />
    <meta property="og:description" content="Duct sound is basically a sound attenuator that helps to reduce noise issues associated with modern HVAC Systems." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/ductsound/duct1.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/ductsound" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/ductsound" />
    </Helmet>
    <Header />
    <section className="content" id="ductsound">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
	                    <div className="col-sm-12 heading-fsorb">				
		                    <h1><span>DUCT</span>sound</h1>
    	                    <p>Duct sound is basically a sound attenuator that helps to reduce noise issues associated with modern HVAC Systems. The ductworks through which conditioned air gets carried to populated spaces transmits annoying fan noise that is intolerable in spaces like offices and classNamerooms. The duct silencers, available in a wide range of models and shapes when placed between the fan
                                and populated spaces effectively reduces fan noise and crosstalk transmitted from one space to another.</p>
	                        <div className="demo-cont">
                                <Sliderductsound />
                            </div>       
                            <div className="col-sm-12">
                                <h2>Applications</h2>
                                <div className="line"></div>
                                <div className="row app">
		                            <div className="col-sm-6">
		                                <ul className="a">
		                                    <li>High sound insulation feature</li>
		                                    <li>High light transmission</li>
		                                    <li>Strong impact resistance</li>
		                                    <li>Weather resistance and UV protection</li>
                                        </ul>
		                            </div>
                                    <div className="col-sm-6 d-none d-sm-block">
		                                <ul className="a">
		                                    <li>Anti-scratching and anti-abrasion properties</li>
                                            <li>Low maintenance</li>
                                            <li>Light weight</li>
		                                </ul>
		                            </div>
                                </div>
                            </div>
                        </div>
	                    <div className="col-sm-12 more">
                            <h1> More Products </h1>
	                        <div className="line"></div>
	                    </div>
	                    <div className="accordion-started accordion-bral panels row">
	   	                    <div className="col-sm-12 col-md-12 col-lg-12 ">
	                            <label className="ac-label" for="ac-1">
                                    <b>DUCTsound</b><br />Silent-Mod Duct Silencers<i></i>
                                </label>
			                    <div className="article ac-content">
				                    <p><strong>Silencing HVAC Fan Noise</strong>, The fans that move air through modern HVAC systems are noise generators. The ductwork carrying conditioned air to occupied spaces throughout the building can often act similar to the old fashion “speaking tubes” that can conduct intrusive and annoying fan noise to occupied spaces. This can often result in 
                                        annoying or intolerable noise levels in offices, conference rooms, classNamerooms and auditoriums, etc.</p>
	                                <div className="row listing">
        		                        <div className="col-12 col-sm-6 px-5">
                                            <img className="w-75" src={Silentmod} alt="Silent Mod Duct" />
                                        </div>
                                        <div className="col-12 col-sm-6">
                	                        <ol>
                                                <li>Rectangular Duct Silencers</li>
                                                <li>Circular Duct Silencers</li>
                                                <li>Industrial Fan Silencers</li>
                                                <li>Elbow Silencers</li>
                                                <li>Custom Size Modular Silencers</li>
                                            </ol>
                                        </div>
		                            </div>
                                    <p>One solution is place one or more rectangular or round air duct noise silencers between the fan and the occupied spaces. While reducing objectionable fan noise, duct sound attenuators can also reduce cross-talk transmitted from one space to another through the ducts to insure office privacy.
                                    Silent-Mod duct sound attenuators are available in a wide assortment of geometries, models, and lengths. This choice in duct mufflers is to accommodate shape, pressure drop vs. airflow and noise reduction.
                                    <br /><br />
                                    Silent-Mod HVAC are fabricated from 22-18 gauge galvanized steel for superior strength and maximum sound transmission reduction through side walls. No flimsy 26 or 24 gauge vent noise silencer material that vibrates in the airstream.
                                    Let our engineering department help you with your air duct noise reduction needs.</p>
	                                <div className="col-sm-12">
 	                                    <img className="w-75" src={Ductsound1} alt="DUCT Sound" />
                                    </div>
 		                        </div>
	                        </div>	
                            <div className="line"></div>
	                        <div className="col-sm-12 col-md-12 col-lg-12 ">
		                        <label className="ac-label" for="ac-2">
                                    <b>DUCTsound</b><br />Duct V-Max Vibration Damper<i></i>
                                </label>
	    	                    <div className="article ac-content">
		                            <div className="row listing">
        		                        <div className="col-12 col-sm-6">
                	                        <img src={Ductsound2} alt="Non Toxic" />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                        <br />
        	                                <ol className="a">
                                                <li>Lightweight, High Performance Design</li>
                                                <li>Can be installed in any area of assembly</li>
                                                <li>Reduced Installation Time – Simply Cut, Peel & Stick</li>
                                                <li>Non-toxic and odor-free</li>
                                                <li>Excellent adhesion to most substrates and process aids</li>
                                                <li>Alternative to OEM Specified Vibration Damping Materials</li>
                                            </ol>
                                        </div>
		                            </div>
			                        <div className="panelfeatures">
	    		                        <p>VMAX vibration and sound damping sheets are constructed of an advanced, non-curing, butylene rubber bonded to a thin layer of black aluminum. Unlike other types of vibration damping material, VMAX sound dampening sheets have been engineered to utilize sheer force to control vibration. The thin layer of aluminum incorporated into the VMAX product will prevent a substrate from bending beyond a given point. VMAX vibration and sound damping sheets are lightweight and require no special tools to install. The VMAX sound damping sheet may be applied to sheet metal, wood, fiberglass and plastic surfaces.<br /><br />
                                        Automotive body panels constructed of sheet metal, fiberglass or plastic vibrate at various frequencies. If they vibrate at a high rate, noise can be heard throughout the passenger compartment and contribute to driver fatigue. This type of noise can also negatively affect the performance of auto sound system. VMAX HVAC and car vibration damper will reduce vibration of the body panels by preventing a substrate from bending beyond a given point. The installation of VMAX HVAC and car vibration damper will assist in reducing interior noise levels as well as improving performance from auto sound systems by preventing the conversion of sound energy into vibration energy. VMAX HVAC and car vibration damper may 
                                        also be used to create or replace flimsy plastic or paper vapor barriers behind interior panels.</p>
		    	                    </div>
                                    <h2>Product Details</h2>
                                    <div className="line"></div>
                                    <br />
            	                    <div className="col-sm-12">
                                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#menu3">DATASHEET</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Thickness (mm)</th>
                                                            <td>.060″ with a .004″ foil and .056″ Butyl</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Dimensions</th>
                                                            <td>12" x 36"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Weight</th>
                                                            <td>.39 lbs/sf</td>
                                                        </tr>
    	                                                <tr>
                                                            <th scope="row">Color</th>
                                                            <td>Black Aluminum</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Flame Resistance</th>
                                                            <td>MVSS-302 passes with a Rating of B lessthan 1.0 and UL HF94-VO </td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Chemical Type</th>
                                                            <td>Butylene Rubber with 4mil Aluminum Layer</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Sheet</th>
                                                            <td>3 Sq Ft per sheet</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
	                                        </div>
                                            <div id="menu1" className="container tab-pane fade"><br />
                                                <h3>Sound Absorption Coefficients</h3>
                                            </div>
                                            <div id="menu3" className="container tab-pane fade"><br /><br />
                                                <h3>Datasheet</h3><br />
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <h2>Applications</h2>
                                            <div className="line"></div>
                                            <div className="row app">
	    	                                    <div className="col-sm-6">
		                                            <ul className="a">
		                                                <li>Automotive Body Panels</li>
		                                                <li>Marine – Hull Bullhead</li>
		                                                <li>Sheet metal Chutes, Hoppers and Ducting</li>
		                                                <li>Computer and Related Cases or Housings</li>
                                                    </ul>
		                                        </div>
                                            </div>
                                        </div>
                                    </div>			
	    			                <br />
		    	                </div>
		                    </div>
                            <div className="line"></div>
	                        <div className="col-sm-12 col-md-12 col-lg-12">
	                            <label className="ac-label" for="ac-3">
                                    <b>DUCTsound</b><br />Silent-Mod Acoustical Louver™<i></i>
                                </label>
    					        <div className="article ac-content">
	                                <div className="row listing">
            	        	            <div className="col-12 col-sm-5">
                            	            <img className="w-100" src={Ductsound3} alt="Acoustical Louver" />
                                        </div>
                                        <div className="col-12 col-sm-7"><br /><br />
                                            <h4>Louver silencers</h4>
        	                                <p>This HVAC vent cover are designed to provide the maximum noise reduction obtainable in short depth while still giving an effective combination of privacy and weather protection.
                                                Silentflow HVAC louvers are for use in installations such as process air intakes, fresh air intakes for ventilation systems, mechanical equipment screens, cooling tower inlets, etc.
                                                Constructed to meet architectural standards, Silentflow acoustical louvers are available in two models in 54 sizes, providing a wide range of pressure-drop and acoustical-performance requirements.
                                            </p>
                                        </div>
    		                        </div>
                                    <h2>Product Details</h2>
                                    <div className="line"></div><br />
			    	                <div className="col-sm-12">
				                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#cp-home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cp-menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cp-menu3">DATASHEET</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="cp-home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Materials</th>
                                                            <td>Out Casings – 16 Gauge Galvanized Steel<br /> 
				                                                Internals – 22 Gauge Galvanized Steel<br />
				                                                Bird Screens – ½″ Mesh Galvanized Screen (Insect Screening Available)<br />
                            				                    Fill – Water Resistant Resin Bonded Fiberglass
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Construction</th>
                                                            <td>Top surface Solid, Bottom Surface Absorptive, Water Stops on Upper Surface</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Standard Sizes</th>
                                                            <td>From 24″×12″ to 48″×120</td>
                                                        </tr>
	                                                </tbody>
                                                </table>
		                                    </div>
                                            <div id="cp-menu1" className="container tab-pane fade"><br />
                                                <h3>Sound Absorption Coefficients</h3>
   	                                        </div>
                    	                    <div id="cp-menu3" className="container tab-pane fade">
                                                <br /><br /><br />
		                                    </div>
                                        </div>
				                    </div>			
        	                        <div className="col-sm-12">
                                        <h2>Applications</h2>
                                        <div className="line"></div>
                                        <div className="row app">
            		                        <div className="col-sm-6">
                		                        <ul className="a">
		                                            <li>Automotive Body Panels</li>
		                                            <li>Marine – Hull Bullhead</li>
		                                            <li>Sheet metal Chutes, Hoppers and Ducting</li>
		                                            <li>Computer and Related Cases or Housings</li>
                		                        </ul>
		        	                        </div>
                                        </div>
                                    </div>
	                                <br />			
			                    </div>
		                    </div>
                            <div className="line"></div>
	                        <div className="col-sm-12 col-md-12 col-lg-12">
	                            <label className="ac-label" for="ac-4"><b>DUCTsound</b>
                                    <br />Noise S.T.O.P.™ LAG-Series Flexible Noise Barriers<i></i>
                                </label>
    		                    <div className="article ac-content">
	        	                    <div className="row listing">
            		                    <div className="col-12 col-sm-5">
                    	                    <img className="w-100" src={Ductsound4} alt="duct 5" />
                                        </div>
                                        <div className="col-12 col-sm-7">
                                        <br /> 
                                    	<p className="m-0">LAG-Series acoustic pipe and duct lagging flexible noise barriers are a composite material featuring a flexible, reinforced, foil-faced mass loaded vinyl noise barrier bonded 
                                        to a scrim-faced quilted decoupler/sound absorber. This acoustic insulation is used for noisy HVAC systems, pipes, ducts, valves and fan housings.</p>
                	                    <ol className="a">
                                            <li>Lightweight, High Performance Design</li>
                                            <li>Can be installed in any area of assembly</li>
                                            <li>Reduced Installation Time – Simply Cut, Peel & Stick</li>
                                            <li>Non-toxic and odor-free</li>
                                            <li>Excellent adhesion to most substrates and process aids</li>
                                            <li>Alternative to OEM Specified Vibration Damping Materials</li>
                                        </ol>
                                        </div>
        		                    </div>	
                                    <h4 className="mt-5">Acoustic Lagging Applications:</h4>		
                                    <p className="mt-1">This acoustic pipe insulation and duct lagging is used to wrap noisy pipes and ducts to block the noise that transmits through the walls of the pipe or duct as air, or other contents, move through it. The quilted fiberglass decoupler improves the performance of the noise barrier and provides sound absorption and thermal insulation around the duct or pipe. This HVAC insulation wrap is suitable for both indoor and outdoor applications.
                                        The reinforced foil exterior readily accepts a matching lag tape for easy installation.</p>
				                    <h2>Product Details</h2>
                                    <div className="line"></div>
                                    <br />
				                    <div className="col-sm-12">
				                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#cb-home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cb-menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cb-menu3">DATASHEET</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="cb-home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
	                                                    <tr>
                                                            <th scope="row">Description</th>
                                                            <td>1 lb/sf foil-faced loaded vinyl barrier bonded to a 1″ quilted fiberglass absorbert</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Nominal Thickness</th>
                                                            <td>1"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Roll Size</th>
                                                            <td>54 ″ Wide × 30′ Long</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Weight</th>
                                                            <td>1.2 lb/sf</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">"R" Factor</th>
                                                            <td>4.0</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Flammability</th>
                                                            <td>className A Fire Rated per ASTM E84</td>
                                                        </tr>
    	                                                <tr>
                                                            <th scope="row">Temperature</th>
                                                            <td>-20°F to +220°F</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div id="cb-menu1" className="container tab-pane fade"><br />
                		                        <h3>Sound Absorption Coefficients</h3>
 	                                        </div>
                                            <div id="cb-menu3" className="container tab-pane fade"><br />
                                                <br />
                                                <h3>Datasheet</h3>
		                                        <br />
				                                <button type="button" onclick="window.open('https://acousticalsurfaces.in/pdfs/foamstop/pu-cyl.pdf')" className="brochure">Download</button>
                                            </div>
                                        </div>
			                        </div>			
    		                    </div>
	                        </div>
                            <div className="line"></div>
                            <div className="col-sm-12 col-md-12 col-lg-12">
	    	                    <label className="ac-label" for="ac-5"><b>DUCTsound</b>
                                    <br />Quiet-Duct Wrap™<i></i>
                                </label>
    		                    <div className="article ac-content">
	    			                <div className="row listing">
            		                    <div className="col-12 col-sm-5">
                	                        <img className="w-100" src={Ductsound5} alt="Ductsound wrap" />
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <br /> 
       		                                <h4>Recycled Cotton Acoustical Duct Wrap Liner</h4>
        	        	                    <ol className="a">
                                                <li>Excellent Noise Absorption</li>
                                                <li>Reduces Unwanted Heat</li>
                                                <li>No Itch or Skin Irritation</li>
                                                <li>Resist Microbial Growth</li>
                                                <li>Easy to Handle & Install</li>
                                                <li>Durable & Long Lasting</li>
                                            </ol>
                                        </div>
            		                </div>	
      	                            <p className="mt-1">Quiet-Duct Wrap, the Thermal/Acoustic Insulation is a lightweight, foil faced, fire retardant blanket-type insulation with multiple uses. The Duct-Wrap insulation offers excellent thermal and acoustical performance and carries a className-A Fire rating.
                                    <br /><br />
                                        The fibers used to manufacture the Duct-Wrap insulation offer excellent sound absorption properties and every fiber used is individually treated with an EPA registered anti-microbial agent that offers excellent protection from mold, mildew, fungi, and pests as well as fire resistance.
                                    </p>
				                    <h2>Product Details</h2>
                                    <div className="line"></div>
                                    <br />
				                    <div className="col-sm-12">
				                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#cb1-home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cb1-menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cb1-menu3">DATASHEET</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="cb1-home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
	                                                    <tr>
                                                            <th scope="row">Description</th>
                                                            <td>Made from durable natural fibers, Quiet-Duct Wrap insulation contains no fiberglass 
				                                                and does not itch or cause skin irritation.<br />
				                                                This foil-faced duct insulation's pure aluminum barrier reflects unwanted radiant heat
				                                                and offers excellent protection from air currents, moisture, and vapors.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Thickness</th>
                                                            <td>3/8"</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Size</th>
                                                            <td>Standard Size – 48″ wide × 15′ long. The foil-faced duct insulation is also available in
				                                                48″ wide × 75′ size (minimum quantities may apply, please call for details)
                                                            </td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Uses</th>
                                                            <td>Use this foil-faced duct insulation in homes, offices or wherever unwanted heat or
				                                                noise control is necessary
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                		                    </div>
                                            <div id="cb1-menu1" className="container tab-pane fade"><br />
                                                <h3>Sound Absorption Coefficients</h3>
                                            </div>
                                            <div id="cb1-menu3" className="container tab-pane fade"><br />
                                            <br />
                                                <h3>Datasheet</h3>
		                                        <br />
				                                <button type="button" onclick="window.open('https://acousticalsurfaces.in/pdfs/foamstop/pu-cyl.pdf')" className="brochure">Download</button>
                                            </div>
                                        </div>
	    			                </div>			
		    		            </div>
			                </div>
                            <div className="line"></div>
		                    <div className="col-sm-12 col-md-12 col-lg-12">
			                    <label className="ac-label" for="ac-6"><b>DUCTsound</b>
                                    <br />Quiet Liner<sup>TM</sup><i></i>
                                </label>
    		                    <div className="article ac-content">
	    		                    <div className="row listing">
                	                    <div className="col-12 col-sm-5">
                    	                    <img className="w-100" src={Ductsound6} alt="duct 7" />
                                        </div>
                                        <div className="col-12 col-sm-7">
                                        <br /> 
       	                                    <h4>Recycled Cotton Acoustical Duct Line</h4>
    	       	                            <ol className="a">
                                                <li>Excellent Noise Absorption</li>
                                                <li>className A Fire Rate</li>
                                                <li>Reduces Heat Loss/Gain</li>
                                                <li>No Formaldehyde</li>
                                                <li>Resists Microbial Growth</li>
                                                <li>Low Air Resistance</li>
                                                <li>No Itch or Skin Irritation</li>
                                            </ol>
                                        </div>
            	                    </div>	
                                    <p className="mt-1">Quiet Natural Fiber Liner (Patent Pending) is a thermally bonded HVAC acoustic insulation that offers superior acoustic and thermal performance. The air stream surface of Quiet Liner is overlaid with a durable, fire-resistant black facing providing additional strength to the product. 
                                        Both the insulation and the facing are treated with an EPA registered anti-microbial agent.</p>
			                        <h2>Product Details</h2>
                                    <div className="line"></div>
                                    <br />
		                            <div className="col-sm-12">
			                            <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#cb1-home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cb1-menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cb1-menu3">DATASHEET</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="cb1-home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
    	                                                <tr>
                                                            <th scope="row">Description</th>
                                                            <td>Made from durable natural fibers, Quiet-Duct Wrap insulation contains no fiberglass and does not itch or cause skin irritation.<br />
                                                                This foil-faced duct insulation's pure aluminum barrier reflects unwanted radiant heat and offers excellent protection from air currents, 
                                                                moisture, and vapors.
                                                            </td>
                                                        </tr>
                                                        <tr>                                                            
                                                            <th scope="row">Uses</th>
                                                            <td>Quiet Natural Fiber Liner is used primarily as an acoustic duct liner designed for
				                                                HVAC sheet metal to absorb unwanted noise from multiple sources. Quiet Liner HVAC
				                                                acoustic insulation also reduces heat gain or loss. Quiet Natural Fiber Acoustic Duct 
				                                                Liner contains no harmful irritants and is safe to handle and install.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">FIRE & SAFETY</th>
                                                            <td>NFPA 90A Installation of Air Conditioning and Ventilating Systems<br />
				                                                NFPA 90B Installation of Warm Air Heating and Air Conditioning System
                                                            </td>
                                                        </tr>
                                                    </tbody>
	                                            </table>
    		                                </div>
                                            <div id="cb1-menu1" className="container tab-pane fade"><br />
     	    	                                <h3>Sound Absorption Coefficients</h3>
                                            </div>
   	                                        <div id="cb1-menu3" className="container tab-pane fade"><br />
                                            <br />
                                                <h3>Datasheet</h3>
		                                        <br />
				                                <button type="button" onclick="window.open('https://acousticalsurfaces.in/pdfs/foamstop/pu-cyl.pdf')" className="brochure">Download</button>
                                            </div>
                                        </div>
	                                </div>			
	                            </div>
	                        </div>
	                    </div>
                        <br /><br />	
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>  
            </div>  
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default duct;