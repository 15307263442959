import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Amaryllis1 from '../../images/projects-slider/amaryllis-1.webp';
import Amaryllis2 from '../../images/projects-slider/amaryllis-2.webp';
import Amaryllis3 from '../../images/projects-slider/amaryllis-3.webp';
import Mobsidebar from '../mobile-sidebar';
import Soundroom from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import Cadlibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import ScrollToTopButton from '../ScrollButton';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
function Acousticamary() {
    const sliderRef = useRef(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    const goToPrev = () => {
        sliderRef.current.slickPrev();
    };

    const goToNext = () => {
        sliderRef.current.slickNext();
    };

    return (

        <>
            <Helmet>
                <title>About Our Amaryllis Healthcare Pvt Ltd Acoustics Project </title>
                <meta name="description" content="" />
                <meta property="og:title" content="Amaryllis Healthcare Pvt Ltd, Bangalore" />
                <meta property="og:description" content="Supply and installation of Noise S.T.O.P Vinyl Barrier and stretch fabric system with Glass wool to increase sound isolation property of wall and to reduce reverberation time in the conference room which provides better speech intelligibility." />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/projects-slider/amaryllis-3.webp" />
                <meta property="og:locale" content="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/acoustic-projects/project-amaryllis-healthcare" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/acoustic-projects/project-amaryllis-healthcare" hreflang="en-in" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="IN" />
                <meta name="geo.region" content="india" />
            </Helmet>
            <Header />
            <section className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 sidemenu">
                            <Sidebar />
                        </div>
                        <div className="col-sm-9 product-page nopaddding">
                            <div className="container">
                                <div className="megamenu">
                                    <Megamenu />
                                </div>
                            </div>
                            <div className="image-slider pro-content">
                                <div id="caption-slide" className="slider-container">
                                    <div className="slider">
                                        <Slider {...settings} ref={sliderRef}>
                                            <div className="slide">
                                                <img src={Amaryllis1} className="img-fluid" alt="amaryllis acoustics" />
                                            </div>
                                            <div className="slide">
                                                <img src={Amaryllis2} className="img-fluid" alt="acoustic amaryllis" />
                                            </div>
                                            <div className="slide">
                                                <img src={Amaryllis3} className="img-fluid" alt="amaryllis" />
                                            </div>
                                        </Slider>
                                    </div>
                                    <div className="switch" id="prev" onClick={goToPrev} style={{backgroundColor:"transparent"}}><span>&#10094;</span></div>
                                    <div className="switch" id="next" onClick={goToNext} style={{backgroundColor:"transparent"}}><span>&#10095;</span></div>
                                </div>
                            </div>
                            <div className="row pro-content">
                                <div className="col-sm-12 writeup">
                                    <h3>Amaryllis Healthcare Pvt Ltd,  Bangalore </h3>
                                    <p className="note">Location: HSR Layout, Bangalore</p>
                                    <p><strong>About Amaryllis Healthcare Pvt Ltd</strong>
                                        <br />
                                        Amaryllis Healthcare Private Limited, an ISO Certified Company, has emerged as a key partner of the healthcare sector in India over the last few years. Their product range extends from Disposable Surgical Drapes & Gowns to various Hygiene & Protective products.
                                        <br />
                                        <br />
                                        <strong> Our Scope</strong>
                                        <br />Supply and installation of Noise S.T.O.P Vinyl Barrier and stretch fabric system with Glass wool to increase sound isolation property of wall and to reduce reverberation time in the conference room which provides better speech intelligibility.</p>
                                    <br />
                                    <hr />
                                </div>
                            </div>
                            <div className="row resources">
                                <div className="col-xl-12 col-lg-12 col-sm-12">
                                    <h2>Additional Resources</h2>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-12">
                                    <h6 style={{ textAlign: "center" }}>Creating Better-Sounding Rooms</h6>
                                    <img className="hvr-grow" src={Soundroom} alt="Create Better Sounding Rooms" target="_blank" rel="noopener noreferrer" />
                                    <div className="butn"><Link to="/bs-rooms-guide">Download eBook</Link></div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-12">
                                    <h6 style={{ textAlign: "center" }}>Solutions to Common Noise Problems</h6>
                                    <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems" target="_blank" rel="noopener noreferrer" />
                                    <div className="butn"><Link to="/noise-problems-guide">Download eBook</Link></div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-12">
                                    <h6 style={{ textAlign: "center" }}><br />CAD, CSI, & Revit Library </h6>
                                    <img className="hvr-grow" src={Cadlibrary} alt="CAD Library" target="_blank" rel="noopener noreferrer" />
                                    <div className="butn"><Link to="/cad-library-guide">Access Product Cad Files</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mobsidebar'>
                        <div className="col-sm-12">
                            <div className="container">
                                <div className="row">
                                    <Mobsidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default Acousticamary;