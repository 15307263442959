import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Springmounts1 from '../../images/inner/imf.webp';
import Springmounts2 from '../../images/inner/imf-simple.webp';
import './spring-isolation-mounts.css';
import ScrollToTopButton from '../ScrollButton';
function springmounts() {
    return (
      
    <>
    <Helmet>
    <title>Spring Isolation Mounts deflection to insure minimum effective vibration control</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="IMF Simple Spring Mounts – Unhoused" />
    <meta property="og:description" content="Virtually eliminate structurally transmitted noise and vibration by installing equipment on Spring Isolators." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/imf.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/spring-isolation-mounts" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/spring-isolation-mounts" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/spring-isolation-mounts" />
    </Helmet>
    <Header />
    <section className="content" id="mounts">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>IMF Simple Spring Mounts – Unhoused</h1>
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/imf.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Springmounts1} alt="IMF Spring Mounts1" />
                                </Link>
                                <Link className="example-image-link" to="../../images/inner/imf-simple.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Springmounts2} alt="IMF Spring Mounts2" />
                                </Link>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 text">
                                <p></p>
                                <p>Virtually eliminate structurally transmitted noise and vibration by installing equipment on Spring Isolators. Properly selected and installed spring isolators offer the greatest assurance that destructive vibration and noise will be eliminated or greatly reduced.</p>
                                <p>Spring isolators should be installed where maximum vibration and noise control is needed. They are most frequently required on upper building levels and on equipment located near inhabited areas with rotational speeds below 1200 RPM.</p>
                                <p>When installing equipment on very wide vibration sensitive building spans it may be necessary to select isolators with even higher deflection than shown in this guide in order to cancel the effect of the spans deflection or “sag”. The isolator selected must have a static deflection at least three times greater than the floor span deflection to insure minimum effective vibration control.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default springmounts; 