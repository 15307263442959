import React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Sliderfoamstoppu from './slider-foamstop-pu';
import Mobsidebar from '../mobile-sidebar';
import Sliderfoamstoppupyramid from './slider-foamstop-pu-pyramiddesign';
import Sliderfoamstoppuflat from './slider-foamstop-pu-flat';
import Sliderfoamstoppubass from './slider-foamstop-pu-bass';
import Sliderfoamstoppuegg from './slider-foamstop-pu-eggcrate';
import Sliderfoamstoppucylinder from './slider-foamstop-pu-cylinder';
import './style-foamstop-pu.css';
import ScrollToTopButton from '../ScrollButton';
function foamstoppu() {
    return (
      
    <>
    <Helmet>
    <title>Polyurethane Acoustic Foam - PU Foam is an open celled foam used for acoustic treatment</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Soundproofing Polyurethane Acoustic Foams" />
    <meta property="og:description" content="Acoustic foam is an open celled foam used for acoustic treatment." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/pyramid1.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/foamstop-pu" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/foamstop-pu" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/foamstop-pu" />
    </Helmet>
    <Header />
    <section className="content" id="foamstoppu">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
	                    <div className="col-sm-12 heading-fsorb">				
	                        <h1><span>NOISE STOP</span> POLYURETHANE FOAMS</h1>
		                    <p>Acoustic foam is an open celled foam used for acoustic treatment. It attenuates airborne sound waves by increasing air resistance, thus reducing the amplitude of the waves. The energy is dissipated as heat. <br />
                            Acoustic foam comes in a variety of sizes and can be attached to walls, ceilings, doors, and other features of a room to control noise levels, vibration, and echoes.</p>
		                    <div className="demo-cont">
	                            <Sliderfoamstoppu />
                                <div className="demo-cont__credits">
    	                        <div className="demo-cont__credits-close"></div>
	   		                        <div className="colorful-switch">
      		                            <label className="colorful-switch__label" htmlFor="colorful-switch-cb">
				                            <span className="colorful-switch__bg"></span>
        		                            <span className="colorful-switch__dot"></span>
				                            <span className="colorful-switch__on">
          		                                <span className="colorful-switch__on__inner"></span>
        		                            </span>
				                            <span className="colorful-switch__off"></span>
			                            </label>
    	                            </div>
	                            </div>
                            </div>
	                    </div>
                        <div className="col-sm-12 features">
	                        <h1>Applications</h1>
	                        <div className="line"></div>
		                    <p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                            <div className="row pad ">	
		                        <div className="col-md-6 col-sm-12 d-none d-md-block">
        		                    <ul>
            		                    <li>Offices</li>
		                                <li>Schools</li>
		                                <li>Meeting Rooms</li>
		                                <li>Hotels</li>
                                    </ul>
		                        </div>
    		                    <div className="col-md-6 col-sm-12 d-none d-md-block">
	                                <ul>
		                                <li>Auditorium</li>
		                                <li>Recording Studios</li>
		                                <li>Conference halls</li>
		                                <li>Broadcasting Studios</li>
		                            </ul>
		                        </div>
	                        </div>
                        </div>
	                    <div className="col-sm-12 more">
                            <h1> More Products </h1>
	                        <div className="line"></div>
	                    </div>
	                    <div className="accordion-started accordion-bral panels row">
		                    <div className="col-sm-12 col-md-12 col-lg-12 ">
		                        <label className="ac-label" htmlFor="ac-1">
                                    <b>FOAM STOP <sup>TM</sup></b><br />PYRAMID DESIGN<i></i>
                                </label>
			                    <div className="article ac-content">
			                        <Sliderfoamstoppupyramid />    
				                    <h1><span>POLYURETHANE</span> FOAM S.T.O.P. PYRAMID™</h1>
				                    <p>These polyurethane pyramid panels are developed to absorb maximum acoustical energy using minimum weight and thickness.  They work well with mid and high frequency waves.</p>
		                            <div className="col-md-6 col-sm-12 d-none d-md-block panelfeatures">
	                                    <ul>
		                                    <li>High Performance Absorber</li>
		                                    <li>Pyramid pattern</li>
		                                    <li>Fiber Free</li>
		                                    <li>Lightweight</li>
		                                </ul>
				                    </div>
                                    <h2>Product Details</h2>
                                    <br />
	                                <div className="col-sm-12">
				                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#menu3">DATASHEET</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
	                                                    <tr>
                                                            <th scope="row">Core</th>
                                                            <td>Open Cell Polyurethane Acoustical Foam</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Thickness (mm)</th>
                                                            <td>25mm, 50 mm & custom thicknesses are also available</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Sizes (mm)</th>
                                                            <td>600mm x 600mm, 1200mm x 1200mm, 1000mm x 2000mm & also custom sizes </td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Acoustic Properties</th>
                                                            <td>NRC 0.66 – 0.96 ( varies with thickness and mounting )</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Density</th>
                                                            <td>1lbs/cub.ft and 2 lbs/cub.ft</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Colours</th>
                                                            <td>Charcoal Grey</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Flamability</th>
                                                            <td>Meets UL94 HF -1 Standard</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Mounting type</th>
                                                            <td>Type A</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Installation</th>
                                                            <td>ASI  S.T.O.P Noise Acoustical Adhesive or any construction adhesive</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
		                                    </div>
                                            <div id="menu1" className="container tab-pane fade"><br />
     		                                    <h3>Sound Absorption Coefficients</h3>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Thickness</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">25mm</th>
                                                            <td>0.06</td>
                                                            <td>0.19</td>
                                                            <td>0.47</td>
                                                            <td>1.05</td>
                                                            <td>0.94</td>
                                                            <td>0.88</td>
                                                            <td>0.66</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">50mm</th>
                                                            <td>0.08</td>
                                                            <td>0.63</td>
                                                            <td>1.11</td>
                                                            <td>1.07</td>
                                                            <td>1.04</td>
                                                            <td>0.97</td>
                                                            <td>0.96</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
	                                        </div>
   	                                        <div id="menu3" className="container tab-pane fade"><br />
	                                            <br />
                                                <h3>FOAM STOP Pyramid Design : Datasheet</h3>
		                                        <br />
                                                <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/foamstop/pu-pyramid.pdf" className="brochure">Download</a>
	                                        </div>
                                        </div>
                                    </div>			
			                    </div>
		                    </div>
                            <div className='line'></div>
		                    <div className="col-sm-12 col-md-12 col-lg-12">
		                        <label className="ac-label" htmlFor="ac-2">
                                    <b>FOAM STOP <sup>TM</sup></b><br /> PLAIN (FLAT) DESIGN<i></i>
                                </label>
				                <div className="article ac-content">
				                    <Sliderfoamstoppuflat />
		    		                <h1><span>POLYURETHANE</span> FOAM S.T.O.P. PLAIN (FLAT)</h1>
					                <p>PU Plain panels come in sound wave pattern and have the advantage of high absorptive surface area.</p>	
	                                <div className="col-md-6 col-sm-12 d-none d-md-block panelfeatures">
	            		                <ul>
		                                    <li>High performance sound absorber</li>
		                                    <li>Plain/Flat pattern</li>
		                                    <li>Fiber free</li>
		                                    <li>Light Weight</li>
		                                </ul>
		    		                </div>
				                    <h2>Product Details</h2>
                                    <br />
					                <div className="col-sm-12">
                                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#cp-home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cp-menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cp-menu3">DATASHEET</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="cp-home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
	                                                    <tr>
                                                            <th scope="row">Core</th>
                                                            <td>Open Cell Polyurethane Acoustical Foam</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Thickness (mm)</th>
                                                            <td>15mm, 20mm, 25mm, 35mm, 50 mm; custom thicknesses are also available</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Sizes (mm)</th>
                                                            <td>600mm x 600mm, 1200mm x 1200mm, 1000mm x 2000mm & also custom sizes</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Acoustic Properties</th>
                                                            <td>NRC 0.42 – 0.96 ( varies with thickness and mounting )</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Density</th>
                                                            <td>1lbs/cub.ft, 2lbs/cub.ft</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Colours</th>
                                                            <td>Charcoal Grey</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Flamability</th>
                                                            <td>Meets UL94 HF -1 Standard</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Mounting type</th>
                                                            <td>Type A</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Installation</th>
                                                            <td>ASI  S.T.O.P Noise Acoustical Adhesive or any construction adhesive</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
		                                    </div>
                                            <div id="cp-menu1" className="container tab-pane fade"><br />
                                                <h3>Sound Absorption Coefficients</h3>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Thickness</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">15mm</th>
                                                            <td>0.02</td>
                                                            <td>0.10</td>
                                                            <td>0.20</td>
                                                            <td>0.51</td>
                                                            <td>0.86</td>
                                                            <td>0.86</td>
                                                            <td>0.42</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">20mm</th>
                                                            <td>0.03</td>
                                                            <td>0.16</td>
                                                            <td>0.34</td>
                                                            <td>0.77</td>
                                                            <td>0.92</td>
                                                            <td>0.87</td>
                                                            <td>0.55</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">25mm</th>
                                                            <td>0.06</td>
                                                            <td>0.19</td>
                                                            <td>0.47</td>
                                                            <td>1.05</td>
                                                            <td>0.94</td>
                                                            <td>0.88</td>
                                                            <td>0.66</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">35mm</th>
                                                            <td>0.03</td>
                                                            <td>0.32</td>
                                                            <td>0.88</td>
                                                            <td>1.09</td>
                                                            <td>0.98</td>
                                                            <td>0.93</td>
                                                            <td>0.82</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">50mm</th>
                                                            <td>0.08</td>
                                                            <td>0.63</td>
                                                            <td>1.11</td>
                                                            <td>1.07</td>
                                                            <td>1.04</td>
                                                            <td>0.97</td>
                                                            <td>0.96</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
	                                        </div>
                                            <div id="cp-menu3" className="container tab-pane fade"><br />
                                                <br />
                                                <h3>FOAM STOP Plain(Flat) Design : Datasheet</h3>
		                                        <br />
		                                        <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/foamstop/pu-plain.pdf" className="brochure">Download</a>
                                            </div>
                                        </div>
                                    </div>			
			                    </div>
		                    </div>
                            <div className='line'></div>
		                    <div className="col-sm-12 col-md-12 col-lg-12">
		                        <label className="ac-label" htmlFor="ac-3">
                                    <b>FOAM STOP <sup>TM</sup></b><br /> CYLINDRICAL DESIGN<i></i>
                                </label>
				                <div className="article ac-content">
				                    <Sliderfoamstoppucylinder />
				                    <h1><span>POLYURETHANE</span> FOAM S.T.O.P. CYLINDRICAL</h1>
		                            <p>These PU cylindrical panels are perfect for spaces where there are echoes and excessive reverberation time related issues which in turn distorts the sound of a space.</p>			
        	                        <div className="col-md-6 col-sm-12 d-none d-md-block panelfeatures">
	        		                    <ul>
		                                    <li>High performance sound absorber</li>
		                                    <li>Cylindrical shape</li>
		                                    <li>Fiber free</li>
		                                    <li>Light weight</li>
		                                </ul>
        	                        </div>
				                    <h2>Product Details</h2>
                                    <br />
				                    <div className="col-sm-12">
				                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#cb-home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cb-menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#cb-menu3">DATASHEET</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="cb-home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
	                                                    <tr>
                                                            <th scope="row">Core</th>
                                                            <td>Open Cell Polyurethane Acoustical Foam</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Dimensions (mm)</th>
                                                            <td>D200mm, custom sizes are also available</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Length (mm)</th>
                                                            <td>950mm, custom sizes are also also available</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Acoustic Properties</th>
                                                            <td>NRC 0.55-0.90 ( varies with thickness and mounting )</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Density </th>
                                                            <td>1lbs/cub.ft  and 2 lbs/cub.ft</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Colours</th>
                                                            <td>Charcoal Grey</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Flamability</th>
                                                            <td>Meets UL94 HF -1 Standard</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Mounting type</th>
                                                            <td>Type A</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Installation</th>
                                                            <td>ASI  S.T.O.P Noise Acoustical Adhesive or any construction adhesive</td>
                                                        </tr>
	                                                </tbody>
	                                            </table>
	                                        </div>
                                            <div id="cb-menu1" className="container tab-pane fade"><br />
     		                                    <h3>Sound Absorption Coefficients</h3>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Thickness</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">0.8 cyl/m2</th>
                                                            <td>0.10</td>
                                                            <td>0.31</td>
                                                            <td>0.60</td>
                                                            <td>0.68</td>
                                                            <td>0.58</td>
                                                            <td>0.15</td>
                                                            <td>0.55</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">1.1 cyl/m2</th>
                                                            <td>0.15</td>
                                                            <td>0.40</td>
                                                            <td>0.80</td>
                                                            <td>1.00</td>
                                                            <td>1.00</td>
                                                            <td>1.00</td>
                                                            <td>0.80</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
	                                        </div>
    	                                    <div id="cb-menu3" className="container tab-pane fade"><br />
                                                <br />
                                                <h3>FOAM STOP Cylindrical Design : Datasheet</h3>
		                                        <br />
		                                        <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/foamstop/pu-cyl.pdf" className="brochure">Download</a>
                                            </div>
                                        </div>
                                    </div>			
			                    </div>
		                    </div>
                            <div className='line'></div>
		                    <div className="col-sm-12 col-md-12 col-lg-12">
		                        <label className="ac-label" htmlFor="ac-4">
                                    <b>FOAM STOP <sup>TM</sup></b><br />BASS TRAP DESIGN<i></i>
                                </label>
				                <div className="article ac-content">
			                        <Sliderfoamstoppubass />
			                        <h1><span>POLYURETHANE</span> FOAM S.T.O.P. BASS TRAP</h1>
				                    <p>PU bass trap panels effectively reduce the reverberations in a uniform manner across the desired acoustic spectrum.</p>			
        		                    <div className="col-md-6 col-sm-12 d-none d-md-block panelfeatures">
                	                    <ul>
		                                    <li>High performance sound absorber</li>
		                                    <li>Triangular pattern</li>
		                                    <li>Fiber free</li>
		                                    <li>Light weight</li>
		                                </ul>
            		                </div>
			                        <h2>Product Details</h2>
                                    <br />
				                    <div className="col-sm-12">
				                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#dp-home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#dp-menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#dp-menu3">DATASHEET</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="dp-home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
	                                                    <tr>
                                                            <th scope="row">Core</th>
                                                            <td>Open Cell Polyurethane Acoustical Foam</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Thickness (mm)</th>
                                                            <td>Custom thickness</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Sizes (mm)</th>
                                                            <td>300mm x 300mm x 600mm, 350mm x 300mm x 600mm & also custom sizes</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Acoustic Properties</th>
                                                            <td>NRC 0.75 ( varies with thicknesses and mounting )</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Density</th>
                                                            <td>1lbs/cub.ft and 2 lbs/cub.ft</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Colour</th>
                                                            <td>Charcoal Grey</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Flamability</th>
                                                            <td>Meets UL94 HF -1 Standard</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Mounting type</th>
                                                            <td>Type A</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Installation</th>
                                                            <td>ASI  S.T.O.P Noise Acoustical Adhesive or any construction adhesive</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
		                                    </div>
                                            <div id="dp-menu1" className="container tab-pane fade"><br />
                        		                <h3>Sound Absorption Coefficients</h3>
                        		                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Thickness</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">BS-1</th>
                                                            <td>0.46</td>
                                                            <td>1.00</td>
                                                            <td>0.78</td>
                                                            <td>0.64</td>
                                                            <td>0.63</td>
                                                            <td>0.70</td>
                                                            <td>0.75</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">BS-2</th>
                                                            <td>0.90</td>
                                                            <td>0.84</td>
                                                            <td>0.80</td>
                                                            <td>0.64</td>
                                                            <td>0.62</td>
                                                            <td>0.70</td>
                                                            <td>0.75</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
	                                        </div>
   	                                        <div id="dp-menu3" className="container tab-pane fade"><br />
                                                <br />
                                                <h3>FOAM STOP Bass Trap Design : Datasheet</h3>
		                                        <br />
		                                        <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/foamstop/pu-BASSTRAP.pdf" className="brochure">Download</a>
                                            </div>
                                        </div>
        			                </div>			
				                </div>
			                </div>
                            <div className='line'></div>
			                <div className="col-sm-12 col-md-12 col-lg-12">
			                    <label className="ac-label" htmlFor="ac-4">
                                    <b>FOAM STOP <sup>TM</sup></b><br />EGGCRATE DESIGN<i></i>
                                </label>
						        <div className="article ac-content">
			    		            <Sliderfoamstoppuegg />
				                    <h1><span>POLYURETHANE</span> FOAM S.T.O.P. EGGCRATE</h1>
						            <p>These panels have an unique shape which helps in improving sound absorption compared to conventional flat surfaces.</p>				
	                                <div className="col-md-6 col-sm-12 d-none d-md-block panelfeatures">
	                                    <ul>
		                                    <li>High performance sound absorber</li>
		                                    <li>Sound wave pattern</li>
		                                    <li>Fiber free</li>
		                                    <li>Light weight</li>
		                                </ul>
				                    </div>
                                    <h2>Product Details</h2>
                                    <br />
	                                <div className="col-sm-12">
                                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#egg-home">SPECIFICATION</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#egg-menu1">REVERBERATION</a>
                                            </li>
	                                        <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#egg-menu3">DATASHEET</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="egg-home" className="container tab-pane active"><br />
                                                <table className="table">
                                                    <tbody>
	                                                    <tr>
                                                            <th scope="row">Core</th>
                                                            <td>Open Cell Polyurethane Acoustical Foam</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Thickness (mm)</th>
                                                            <td>35mm, 50mm ;custom thicknesses are also available</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Sizes (mm)</th>
                                                            <td>600mm x 600mm, 1200mm x 1200mm, 1000mm x 2000mm & also custom sizes</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Acoustic Properties</th>
                                                            <td>NRC 0.82 – 0.96 ( varies with thickness and mounting )</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Density</th>
                                                            <td>1lbs/cub.ft, 2 lbs/cub.ft</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Colours</th>
                                                            <td>Charcoal Grey</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Flamability</th>
                                                            <td>Meets UL94 HF -1 Standard</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Mounting type</th>
                                                            <td>Type A</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">Installation</th>
                                                            <td>ASI  S.T.O.P Noise Acoustical Adhesive or any construction adhesive</td>
                                                        </tr>
	                                                </tbody>
                                                </table>
	                                        </div>
                                            <div id="egg-menu1" className="container tab-pane fade"><br />
                        		                <h3>Sound Absorption Coefficients</h3>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Thickness</th>
                                                            <th scope="col">125Hz</th>
                                                            <th scope="col">250Hz</th>
                                                            <th scope="col">500Hz</th>
                                                            <th scope="col">1KHz</th>
                                                            <th scope="col">2KHz</th>
                                                            <th scope="col">4KHz</th>
                                                            <th scope="col">NRC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">35mm</th>
                                                            <td>0.03</td>
                                                            <td>0.32</td>
                                                            <td>0.88</td>
                                                            <td>1.09</td>
                                                            <td>0.98</td>
                                                            <td>0.93</td>
                                                            <td>0.82</td>
                                                        </tr>
	                                                    <tr>
                                                            <th scope="row">50mm</th>
                                                            <td>0.08</td>
                                                            <td>0.63</td>
                                                            <td>1.11</td>
                                                            <td>1.07</td>
                                                            <td>1.04</td>
                                                            <td>0.97</td>
                                                            <td>0.96</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
		                                    </div>
                            	            <div id="egg-menu3" className="container tab-pane fade"><br />
                                                <br />
                                                <h3>FOAM STOP Eggcrate Design : Datasheet</h3>
		                                        <br />
		                                        <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/foamstop/eggcrate-datasheet-small.pdf" className="brochure">Download</a>
                                            </div>
                                        </div>
        				            </div>			
		        	            </div>
			                </div>
			            </div>
                        <div className="line"></div>			
			        </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default foamstoppu; 