import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import './acfabric-fr703.css';
import Mobsidebar from '../mobile-sidebar';
import Frr1 from '../../images/acfabric/frr1.jpg';
import Frr2 from '../../images/acfabric/frr2.jpg';
import Frr3 from '../../images/acfabric/frr3.jpg';
import Frr4 from '../../images/acfabric/frr4.jpg';
import Frr5 from '../../images/acfabric/frr5.jpg';
import Frr6 from '../../images/acfabric/frr6.jpg';
import Frr7 from '../../images/acfabric/frr7.jpg';
import Frr8 from '../../images/acfabric/frr8.jpg';
import Frr9 from '../../images/acfabric/frr9.jpg';
import ScrollToTopButton from '../ScrollButton';
function acfabricfr703() {
    return (
      
    <>
    <Helmet>
    <meta name="description" content="" />
    <title>Acoustic of Maine FR703 Fabrics - Acoustical Surfaces</title>
    <meta property="og:title" content="AcoustyFABRIC FR 703" />
    <meta property="og:description" content="Guilford of Maine FR 703 3123 Fabric Facings" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/acfabric/frr1.jpg" />
    <meta property="og:locale" content="en-in" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/acfabric-fr703" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/acfabric-fr703" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acfabric-fr703" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="acfabricfr703">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
            	<div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
	                <div className="col-sm-12 heading-fsorb">				
                		<h1>Acousty<span className="fabriwall">FABRIC</span> FR 703</h1>
                		<h4 className="mb-0"> <b>Guilford of Maine FR 703 3123 Fabric Facings</b></h4>
		                <p><span className="color:red;">(Colors Shown are Approximate Match to Material)</span></p>
	                    <br />
		                <div className="col-sm-3 cloths p-0"><button>Product Specifications</button></div>
	                </div>
		            <br /><br />
		            <div className="col-sm-12 cloths">
		                <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Frr1} alt="Arctic" />
                                <p className="text-center">Arctic 010</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Frr2} alt="Cloud" />
                                <p className="text-center">Cloud 050</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Frr3} alt="Flax" />
                                <p className="text-center">Flax 080</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Frr4} alt="Oyster" />
                                <p className="text-center">Oyster 090</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Frr5} alt="Parchment" />
                                <p className="text-center">Parchment 060</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Frr6} alt="Pebble" />
                                <p className="text-center">Pebble 040</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Frr7} alt="Raven" />
                                <p className="text-center">Raven 020</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Frr8} alt="Sand" />
                                <p className="text-center">Sand 070</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Frr9} alt="Shadow" />
                                <p className="text-center">Shadow 030</p>
                            </div>
                            <div className="col-sm-2 p-0 mx-2"></div>
                        </div>	
                    </div>
		            <br />
		            <hr />
                    <div className="col-12 d-flex justify-content-center backbtn">
                        <Link to="/acoustyfabric"><button>Back to Acousty<span style={{color: "red"}}>FABRIC</span></button></Link>
                    </div>
                    <hr />
                    <div className="col-sm-12 application">
			            <h1>APPLICATION</h1>
                        <div className="hover14 text-center justify-content-center">
		                <br />
  		                    <div className="col-sm-12">
			                    <figure className="m-0"><div className="tinted-image">CINEMA HALL</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image2">OFFICE ROOMS & HALLS</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image3">AUDITORIUM & EDUCATION</div></figure>
 		                    </div>
                        <br />
	                    </div>
                    </div>
                    <br />
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acfabricfr703;