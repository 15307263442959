import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Genbldg1 from '../../images/inner/rsic-1-clip-furring-channel-200x177.webp';
import Genbldg2 from '../../images/inner/greenGlueTubes-200x200.webp';
import Genbldg3 from '../../images/inner/acoustik1-200x200.webp';
import Genbldg4 from '../../images/inner/recycled_logo_jeans-200x200.webp';
import Genbldg5 from '../../images/inner/quiet_acoustical_liner-200x200.webp';
import Genbldg6 from '../../images/inner/mlvb1-200x200.webp';
import Mobsidebar from '../mobile-sidebar';
import './gen-bldg.css';
import ScrollToTopButton from '../ScrollButton';
function genbldg() {
    return (
      
    <>
    <Helmet>
    <title>General Building - Indoor soundproofing and soundproof wall construction</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Soundproofing General Building Applications" />
    <meta property="og:description" content="Our extensive product lines include sound barrier wall and ceiling tiles, windows and doors, flooring, hardware, vibration isolation components, HVAC silencers, and construction sealants." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/quiet_acoustical_liner-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/gen-bldg" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/gen-bldg" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/gen-bldg" />
    </Helmet>
    <Header />
    <section className="content" id="genbldg">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Soundproofing General Building Applications</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>In general building applications, managing sound transmission should be a standard part of indoor soundproofing and soundproof wall construction. It is easy to incorporate building products that provide sound privacy from one area to the next, whether it is in an office building, an apartment building or a commercial site.</p>
                                    <p>Our extensive product lines include sound barrier wall and ceiling tiles, windows and doors, flooring, hardware, vibration isolation components, HVAC silencers, and construction sealants all of which are designed to create spaces that are free from unwanted external or internal noise.</p>
                                    <p>Working with the leaders in the noise control industry ensures that our indoor soundproofing solutions will provide the highest quality results in all general building applications. Below are just a few of our outstanding soundproof wall construction products, commonly used in the construction industry to bring a little peace to the living and working environment.</p>
                                    <p><Link to="/contact-us">Contact us</Link> today and we will help you solve your noise problem.</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>General Building Soundproofing Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Genbldg1} alt="RSIC-1 Clips" />
                                </Link> 
                                <h5>RSIC-1 Clips</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Genbldg2} alt="Green Glue" />
                                </Link>
                                <h5>Green Glue</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Genbldg3} alt="Acoustik Underlament" />
                                </Link>
                                <h5>Acoustik Underlament</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Genbldg4} alt="Ultra Touch Insulation" />
                                </Link>
                                <h5>Ultra Touch Insulatio</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Genbldg5} alt="Quiet Liner" />
                                </Link>
                                <h5>Quiet Liner</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Genbldg6} alt="Mass Loaded Vinyl" />
                                </Link>
                                <h5>Mass Loaded Vinyl</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default genbldg; 