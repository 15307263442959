import React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import ScrollToTopButton from '../ScrollButton';
function soundproofinsulation() {
    return (
      
    <>
    <Helmet>
    <title>Sounproofing Insulation - Project Case Study </title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/soundproofing-insulation" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/soundproofing-insulation" />
    </Helmet>
    <Header />
    <section className="content" id="insulation">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <embed src="https://www.acousticalsurfaces.in/pdfs/UltraTouch_Projects.pdf#toolbar=0&amp;navpanes=0&amp;scrollbar=0" type="application/pdf" width="100%" height="1100px" title="soundproofing insulation" />     
                    </div>    
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>            
<Footer />
</>
);
}
export default soundproofinsulation;     