import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import './acfabric-fr702.css';
import Mobsidebar from '../mobile-sidebar';
import Fr1 from '../../images/acfabric/fr1.jpg';
import Fr2 from '../../images/acfabric/fr2.jpg';
import Fr3 from '../../images/acfabric/fr3.jpg';
import Fr4 from '../../images/acfabric/fr4.jpg';
import Fr5 from '../../images/acfabric/fr5.jpg';
import Fr6 from '../../images/acfabric/fr6.jpg';
import Fr7 from '../../images/acfabric/fr7.jpg';
import Fr8 from '../../images/acfabric/fr8.jpg';
import Fr9 from '../../images/acfabric/fr9.jpg';
import ScrollToTopButton from '../ScrollButton';
function acfabricfr7() {
    return (
      
    <>
    <Helmet>
    <title>Acoustic of Maine FR702 Acoustic Fabric - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="AcoustyFABRIC FR 702" />
    <meta property="og:description" content="Guilford of Maine FR 702 3124 Fabric Facings" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/acfabric/fr1.jpg" />
    <meta property="og:locale" content="en-in" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/acfabric-fr702" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/acfabric-fr702" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acfabric-fr702" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="acfabricfr702">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
            	<div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
	                <div className="col-sm-12 heading-fsorb">				
		                <h1>Acousty<span className="fabriwall">FABRIC</span> FR 702</h1>
                		<h4 className="mb-0"> <b>Guilford of Maine FR 702 3124 Fabric Facings</b></h4>
		                <p><span className="color:red;">(Colors Shown are Approximate Match to Material)</span></p>
	                    <br />
		                <div className="col-sm-3 cloths p-0"><button>Product Specifications</button></div>
	                </div>
		            <br /><br />
		            <div className="col-sm-12 cloths">
		                <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fr1} alt="Albaster" />
                                <p className="text-center">Alabaster 060</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fr2} alt="Delft" />
                                <p className="text-center">Delft 010</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fr3} alt="Dune" />
                                <p className="text-center">Dune 070</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fr4} alt="Ebony" />
                                <p className="text-center">Ebony 020</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fr5} alt="Fog" />
                                <p className="text-center">Fog 040</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fr6} alt="Iron" />
                                <p className="text-center">Iron 030</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fr7} alt="Mist" />
                                <p className="text-center">Mist 050</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fr8} alt="Ramie" />
                                <p className="text-center">Ramie 080</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Fr9} alt="Tin" />
                                <p className="text-center">Tin 090</p>
                            </div>
                            <div className="col-sm-2 p-0 mx-2"></div>
                        </div>	
                    </div>
		            <br />
		            <hr />
                    <div className="col-12 d-flex justify-content-center backbtn">
                    	<Link to="acoustyfabric"><button>Back to Acousty<span style={{color: "red"}}>FABRIC</span></button></Link>
                    </div>
                    <hr />
                    <div className="col-sm-12 application">
			            <h1>APPLICATION</h1>
		                <div className="hover14 text-center justify-content-center">
		                <br />
  		                    <div className="col-sm-12">
			                    <figure className="m-0"><div className="tinted-image">CINEMA HALL</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image2">OFFICE ROOMS & HALLS</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image3">AUDITORIUM & EDUCATION</div></figure>
 		                    </div>
                        <br />
                        </div>
                    </div>
		            <br />
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acfabricfr7;