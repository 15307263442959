import React from 'react';
import { Link } from 'react-router-dom';
import './slider-decopanel.css';
import Banner1 from '../../images/flet1.webp';
import Banner2 from '../../images/flet2.webp';
import Banner3 from '../../images/flet3.webp';
import Banner4 from '../../images/flet4.webp';
import Banner5 from '../../images/flet5.webp';
import Banner6 from '../../images/flet6.webp';
function slider() {
	return (
	  
	<>
	<div id="slider-decopanel">
		<div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
  			<ol className="carousel-indicators">
    			<li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
    			<li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    			<li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    			<li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
    			<li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
    			<li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
  			</ol>
  			<div className="carousel-inner">
    			<div className="carousel-item active">
      				<img className="d-block w-100" src={Banner1} alt="Plain Panels" />
	  				<div className="carousel-caption d-none d-md-block">
					  	<h2>Plain Panels</h2>
						<i className="fa fa-download" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/catalogue/decopanel/decopanel-catalogue.pdf" target="_blank" className="demo-cont__credits-link">Plain Panels Brochure</Link>
                    </div>
				</div>
    			<div className="carousel-item">
      				<img className="d-block w-100" src={Banner2} alt="Grooved Panels" />
	  				<div className="carousel-caption d-none d-md-block">
					  	<h2>Grooved Panels</h2>
						<i className="fa fa-download" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/catalogue/decopanel/decopanel-catalogue.pdf" target="_blank" className="demo-cont__credits-link">Grooved Panels Brochure</Link>
                    </div>
				</div>
				<div className="carousel-item">
      				<img className="d-block w-100" src={Banner3} alt="Tiles Panels" />
	  				<div className="carousel-caption d-none d-md-block">
					  	<h2>Tiles Panels</h2>
						<i className="fa fa-download" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/catalogue/decopanel/decopanel-catalogue.pdf" target="_blank" className="demo-cont__credits-link">Tiles Panels Brochure</Link>
                    </div>
				</div>
				<div className="carousel-item">
      				<img className="d-block w-100" src={Banner4} alt="Print Panels" />
	  				<div className="carousel-caption d-none d-md-block">
					  	<h2>Print Panels</h2>
						<i className="fa fa-download" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/catalogue/decopanel/decopanel-catalogue.pdf" target="_blank" className="demo-cont__credits-link">Print Panels Brochure</Link>
                    </div>
				</div>
				<div className="carousel-item">
      				<img className="d-block w-100" src={Banner5} alt="Roll Panels" />
	  				<div claclassNamess="carousel-caption d-none d-md-block">
					  	<h2>Roll Panels</h2>
						<i className="fa fa-download" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/catalogue/decopanel/decopanel-catalogue.pdf" target="_blank" className="demo-cont__credits-link">Roll Panels Brochure</Link>
                    </div>
				</div>
				<div className="carousel-item">
      				<img className="d-block w-100" src={Banner6} alt="Baffles/Clouds" />
	  				<div className="carousel-caption d-none d-md-block">
					  	<h2>Baffles/Clouds</h2>
						<i className="fa fa-download" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/catalogue/decopanel/decopanel-catalogue.pdf" target="_blank" className="demo-cont__credits-link">Baffles/Clouds Brochure</Link>
                    </div>
				</div>
    		</div>
  			<a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    			<span className="carousel-control-prev-icon" aria-hidden="true"></span>
    			<span className="sr-only">Previous</span>
  			</a>
  			<a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    			<span className="carousel-control-next-icon" aria-hidden="true"></span>
    			<span className="sr-only">Next</span>
  			</a>
		</div>
	</div>
	</>
);
}
export default slider;