import React from 'react';
import { Link } from 'react-router-dom';
import './megamenu.css';
function megamenu() {
  return (
    <>
    <div className="menu-container">
        <div className="menu">
            <ul>
                <li className="text-center" id="megamenu3"><Link to="/#">Browse by <span style={{color: "#f13849"}}>Application</span><i className="fa fa-angle-down" aria-hidden="true"></i></Link>
                    <ul className="text-left nav-dropdown1">
                        <li>
                            <ul>
                                <li><Link to="/absorbing-echo">Absorbing Echo</Link></li>
                                <li><Link to="/sound-blocking-product">Blocking Sound</Link></li>
                                <li><Link to="/soundproofing-correctional-facilities">Correctional Facilities</Link></li>
                                <li><Link to="/soundproofing-classrooms">Educational Institutions</Link></li>
                                <li><Link to="/entertainment-soundproofing-products">Entertainment</Link></li>
                                <li><Link to="/gen-bldg">General Building</Link></li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li><Link to="/green-bldg">Green Building</Link></li>
                                <li><Link to="/healthcare">Health Care</Link></li>
                                <li><Link to="/hotel-convention">Hotels and Convention Centers</Link></li>
                                <li><Link to="/houseofworship">Houses of Worship</Link></li>
                                <li><Link to="/mfg-industrial">Manufacturing and Industrial</Link></li>
                                <li><Link to="/multiunit-resi">Multi-Unit Residential</Link></li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li><Link to="/office-enviro">Office Environments</Link></li>
                                <li><Link to="/sp-stores-retail">Soundproofing Stores & Retail Spaces</Link></li>
                                <li><Link to="/sports-venues">Sports Venues</Link></li>
                                <li><Link to="/studio-construction">Studio Construction</Link></li>
                                <li><Link to="/transportation">Transportation</Link></li>
                            </ul>
                        </li>
                    </ul>
                </li>
             
                <li className="text-center border-left border-white" id="megamenu4"><Link to="/#">Browse by <span style={{color: "#f13849"}}>Product Type</span><i className="fa fa-angle-down" aria-hidden="true"></i></Link>
                    <ul className="text-left nav-dropdown2">
                        <li>
                            <ul>
                                <li><Link to="/acoustic-panel">Acoustic Panels</Link></li>
                                <li><Link to="/soundproofing-ceiling-tiles">Acoustical Ceiling Tiles</Link></li>
                                <li><Link to="/soundproofing-clouds-and-baffles">Clouds and Baffles</Link></li>
                                <li><Link to="/soundproofing-construction-products">Construction Products</Link></li>
                                <li><Link to="/soundproofing-curtains">Curtains & Sound Blankets</Link></li>
                            	<li><Link to="/soundproofing-decorative">Decorative / High-end Products</Link></li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li><Link to="/soundproof-doors">Doors</Link></li>
                                <li><Link to="/sound-control-electronics">Electronics</Link></li>
                                <li><Link to="/exterior-noise-control">Exterior Products</Link></li>
                                <li><Link to="/floor-soundproofing">Floor Soundproofing Products</Link></li>
                                <li><Link to="/hardware">Hardware</Link></li>
                                <li><Link to="/hvac">HVAC</Link></li>
                            	<li><Link to="/insulation">Insulation</Link></li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li><Link to="/mfg-industrial">Manufacturing / Industrial</Link></li>
                                <li><Link to="/metalpanels">Metal Panels</Link></li>
                                <li><Link to="/proaudio">Pro Audio</Link></li>
                                <li><Link to="/sealants-adhesive">Sealants and Adhesive</Link></li>
                               	<li><Link to="/vibration-isolation">Vibration Isolation</Link></li>
                                <li><Link to="/windows">Windows</Link></li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</>
);
}
export default megamenu;
