import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import './melamine-linear-wedge-panels.css';
import Mobsidebar from '../mobile-sidebar';
import Wedge1 from '../../images/inner/mel_wedge04.gif';
import ScrollToTopButton from '../ScrollButton';
function melaminelinear() {
    return (
      
    <>
    <Helmet>
    <title>Sound Absorbing Melamine Foam - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="Melamine Foam Linear Wedges" />
    <meta property="og:description" content="The linear wedge pattern offers excellent absorption and allows you to create many different designs." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.png" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/melamine-linear-wedge-panels" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/melamine-linear-wedge-panels" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/melamine-linear-wedge-panels" />
    </Helmet>
    <Header />
    <section className="content" id="wedge">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Foam S.T.O.P.™</h1>   
                        <div className="row">
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Melamine Foam Linear Wedges</h2>
                                <ul style={{marginleft: "1em"}}>                                                                
                                    <li>className A Fire Retardant</li>
                                    <li>High Performance Absorption</li>
                                    <li>Fiber Free</li>
                                    <li>Anechoic Wedge Design</li>                             
                                </ul> 
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/linearwedges_35.pdf" target="_blank">Product Specs</Link>
                                        </li> 
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tr>
                                        <th>Material</th>
                                        <td>Open cell melamine or polyurethane. </td>
                                    </tr>
                                    <tr>
                                        <th>Pattern</th>
                                        <td>The linear wedge pattern offers excellent absorption and allows you to create many different designs. Install vertically, horizontally, diagonally, checkerboard or create your own design. </td>
                                    </tr>
                                    <tr>
                                        <th>Applications</th>
                                        <td>For use in all industrial, commercial, audio, OEM and residential markets; Ceilings, walls partitions, sound studios, radio stations, band rooms, gyms, swimming pools, gun ranges, mechanical rooms and enclosures. Thicker wedges are designed for use in anechoic chambers and test cells. </td>
                                    </tr>
                                    <tr>
                                        <th>Thickness (Standard Panel)</th>
                                        <td>2″, 3″, 4″ and custom</td>
                                    </tr>
                                    <tr>
                                        <th>Sizes (Standard Panel) </th>
                                        <td>2′ × 2′</td>
                                    </tr>
                                    <tr>
                                        <th>Thickness (Max Wedge) </th>
                                        <td>6″, 8″</td>
                                    </tr>
                                    <tr>
                                        <th>Sizes (Max Wedge)</th>
                                        <td>12″ × 12"</td>
                                    </tr>
                                    <tr>
                                        <th>Colors</th>
                                        <td>Standard – Natural White or Light Grey<br />
                                            Latex or HPC Paint – Black, Light Grey, Almond<br />
                                            Painted – Black, Brown, Beige, Light Gray (Custom Colors Also Available)
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Flammability</th>
                                        <td>ASTM E84, className 1 </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <p>
                            <Link className="btn btn-asi" to="/reverb-demo">Reverberation Demonstration</Link>
                        </p>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="8">FOAM S.T.O.P. Linear Wedges Noise Reduction Coefficients</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="8">Standard Panel</td>
                                        </tr>
                                        <tr className="colheader">
                                            <td></td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>NRC</td>
                                        </tr>
                                        <tr>
                                            <td>2″</td>
                                            <td>.09</td>
                                            <td>.28</td>
                                            <td>.79</td>
                                            <td>.94</td>
                                            <td>1.00</td>
                                            <td>1.04</td>
                                            <td className="emphasis">.75</td>
                                        </tr>
                                        <tr>
                                            <td>3″</td>
                                            <td>.10</td>
                                            <td>.48</td>
                                            <td>.91</td>
                                            <td>1.11</td>
                                            <td>1.11</td>
                                            <td>1.14</td>
                                            <td className="emphasis">.90</td>
                                        </tr>
                                        <tr>
                                            <td>4″</td>
                                            <td>.16</td>
                                            <td>.56</td>
                                            <td>1.11</td>
                                            <td>1.13</td>
                                            <td>1.13</td>
                                            <td>1.21</td>
                                            <td className="emphasis">1.00</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/mel_wedge04.gif" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Wedge1} alt="Melamine Linear Wedge Panels" />
                                </Link>
                            </div>
                        </div>
                        <hr className="d-none d-md-block d-lg-block d-xl-block" />
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default melaminelinear; 