import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import Zoho from '../../images/projects-slider/zoho-chennai.webp';
import Navel from '../../images/projects-slider/naval-vizag.webp';
import DPS from '../../images/projects-slider/acousticproject-acoustical-surfaces.webp';
import Generalmotors from '../../images/projects-slider/gm-bangalore.webp';
import KISK from '../../images/projects-slider/proj-kodai-3.webp';
import ScrollToTopButton from '../ScrollButton';
function projects3() {
    return (

        <>
            <Helmet>
                <title>Turnkey Acoustic Projects - Acoustical Surfaces</title>
                <meta name="description" content="" />
                <meta property="og:title" content="Projects Gallery" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.png" />
                <meta property="og:locale" content="en_IN" />
                <link rel="alternate" href="https://www.acousticalsurfaces.ae/projects-3" hreflang="en-ae" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/projects-3" hreflang="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/projects-3" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="IN" />
                <meta name="geo.region" content="india" />
            </Helmet>
            <Header />
            <section className="content projects">
                <div className="container">
                    <hr />
                    <h2 style={{ textalign: "center" }}>Projects Gallery</h2>
                    <div style={{ width: "18%", bordertop: "1px solid #e0dede", margin: "auto", padding: "10px" }}></div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>Zoho Corporation</h4>
                            <img className="img-fluid" src={Zoho} alt="Zoho Chennai" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{ textalign: "justify" }}>
                            <p></p>
                            <h5>About Zoho Corporation</h5>
                            <p style={{textAlign:"justify"}}>Zoho Corporation is a multi-national business software development company. Zoho Corp's offerings focus on web-based business tools and information technology solutions, including an office tools suite, internet of things management platform, and a suite of IT management software.</p>
                            <h5>Our Scope</h5>
                            <p>Supply & Installation of Acoustic Panel System and Acrylic Sheet</p>
                            <h5>Location</h5><p>Chennai</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>Navel Base Academy</h4>
                            <img className="img-fluid" src={Navel} alt="Navel Base Academy Vishakapatnam" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{ textalign: "justify" }}>
                            <p></p>
                            <h5>About Navel Base Academy</h5>
                            <p style={{textAlign:"justify"}}>Naval Maritime Academy, Visakhapatnam provides quality education to meet the needs of a growing Global Shipping Industry. The Academy has been recognised by the Director General of Shipping, India and would award B.Sc (Tech) Marine Degree from College of Engineering, Andhra University.</p>
                            <h5>Our Scope</h5>
                            <p>Supply & Installation of Acoustic Panel</p>
                            <h5>Location</h5><p>Vishakapatnam</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>Delhi Public School</h4>
                            <img className="img-fluid" src={DPS} alt="Delhi Public School" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{ textalign: "justify" }}>
                            <p></p>
                            <h5>About Delhi Public School</h5>
                            <p style={{textAlign:"justify"}}>Delhi Public School Bangalore East is a member of the prestigious DPS family comprising of over a hundred schools located in India as well as overseas.The DPS network in India spans across the geographical extent of our country and beyond. The schools abroad include the ones at Nepal, Indonesia, Singapore, United Arab Emirates, Qatar and Kuwait.</p>
                            <h5>Our Scope</h5>
                            <p>We were entrusted with installation of Polysorb Baffles along with Polysorb Wall Panels in the swimming pool area. Our design and Acoustic treatment optimized the reverberation.</p>
                            <h5>Location</h5><p>Bangalore, Karnataka</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>General Motors </h4>
                            <img className="img-fluid" src={Generalmotors} alt="General Motors Bangalore" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{ textalign: "justify" }}>
                            <p></p>
                            <h5>About General Motors</h5>
                            <p style={{textAlign:"justify"}}>General Motors India Private Limited is a partnership between General Motors and SAIC that is engaged in the automobile business in India</p>
                            <h5>Our Scope</h5>
                            <p>Acoustic Anaechoic Chamber</p>
                            <h5>Location</h5><p>Bangalore</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h4>Kodaikanal International School</h4>
                            <img className="img-fluid" src={KISK} alt="Kodaikanal International School Kodaikanal" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{ textalign: "justify" }}>
                            <p></p>
                            <h5>About Kodaikanal International School</h5>
                            <p style={{textAlign:"justify"}}>Kodaikanal International School is a co-educational independent residential school offering education for grades P-12. It is located on 43 acres in Kodaikanal, Dindigul, Tamil Nadu, India. With over a hundred years of international education in its history, KIS has high standards to match its high altitude.</p>
                            <h5>Our Scope</h5>
                            <p>Supply & Installation of acoustical wall panels for the music jamming studio. Acoustically treating the ceiling and walls of the music room to reduce the reverberation time to provide the best performance results when loud music is being played.</p>
                            <h5>Location</h5><p>Kodaikanal</p>
                        </div>
                        <div className="line" style={{ margin: "1em 0" }}></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="paginate">
                            <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a class="page-link" href="/projects-2" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item"><a class="page-link" href="/projects">1</a></li>
                                        <li class="page-item"><a class="page-link" href="/projects-2">2</a></li>
                                        <li class="page-item"><a class="page-link" href="/projects-3">3</a></li>
                                        <li class="page-item"><a class="page-link" href="/projects-4">4</a></li>
                                        <li class="page-item"><a class="page-link" href="/projects-5">5</a></li>
                                        <li class="page-item">
                                            <a class="page-link" href="/projects-4" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default projects3; 