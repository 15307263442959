import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import './acfabric-spinel.css';
import Mobsidebar from '../mobile-sidebar';
import S1 from '../../images/acfabric/s1.webp';
import S2 from '../../images/acfabric/s2.webp';
import S3 from '../../images/acfabric/s3.webp';
import S4 from '../../images/acfabric/s4.webp';
import S5 from '../../images/acfabric/s5.webp';
import S6 from '../../images/acfabric/s6.webp';
import S7 from '../../images/acfabric/s7.webp';
import S8 from '../../images/acfabric/s8.webp';
import S9 from '../../images/acfabric/s9.webp';
import S10 from '../../images/acfabric/s10.webp';
import S11 from '../../images/acfabric/s11.webp';
import S12 from '../../images/acfabric/s12.webp';
import S13 from '../../images/acfabric/s13.webp';
import S14 from '../../images/acfabric/s14.webp';
import ScrollToTopButton from '../ScrollButton';
function acfabricspinel() {
    return (
      
    <>
    <Helmet>
    <title>Acoustic Fabric Spinel - Guilford of Maine Spinel 3582 Fabric Facings</title>
    <meta name="description" content="" />
    <meta property="og:title" content="AcoustyFABRIC Spinel" />
    <meta property="og:description" content="Guilford of Maine Spinel 3582 Fabric Facings" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/acfabric/s1.webp" />
    <meta property="og:locale" content="en-in" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/acfabric-spinel" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/acfabric-spinel" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acfabric-spinel" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="acfabricspinel">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
	            </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
                    <div className="col-sm-12 heading-fsorb">				
		                <h1>Acousty<span className="fabriwall">FABRIC</span> Spinel</h1>
    	                <h4 className="mb-0"> <b>Guilford of Maine Spinel 3582 Fabric Facings</b></h4>
		                <p><span className="color:red;">(Colors Shown are Approximate Match to Material)</span></p>
	                    <br />
		                <div className="col-sm-3 cloths p-0"><button>Product Specifications</button></div>
	                </div>
	                <br /><br />
	                <div className="col-sm-12 cloths">
	                    <div className="row d-flex justify-content-center mb-2">
        	                <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={S1} alt="opal" />
                                <p className="text-center">Opal 011</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={S2} alt="Patina" />
                                <p className="text-center">Patina 013</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={S3} alt="Granite" />
                                <p className="text-center">Granite 015</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={S4} alt="Obsidian" />
                                <p className="text-center">Obsidian 021</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={S5} alt="Tiger Eye" />
                                <p className="text-center">Tiger Eye 030</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
        	                <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={S6} alt="Quartz" />
                                <p className="text-center">Quartz 031</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={S7} alt="Sandstone" />
                                <p className="text-center">Sandstone 050</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={S8} alt="Alabaster" />
                                <p className="text-center">Alabaster 058</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={S9} alt="Petoskey" />
                                <p className="text-center">Petoskey 056</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={S10} alt="Guilden" />
                                <p className="text-center">Guilden 070</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
        	                <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={S11} alt="Perlite" />
                                <p className="text-center">Perlite 080</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={S12} alt="quarry" />
                                <p className="text-center">Quarry 091</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={S13} alt="Yellowstone" />
                                <p className="text-center">Yellowstone 112</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={S14} alt="Amber" />
                                <p className="text-center">Amber 113</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2"></div>
                        </div>
                    </div>
	                <br />
	                <br />
                    <div className="col-12 d-flex justify-content-center backbtn">
                        <Link to="acoustyfabric"><button>Back to Acousty<span style={{color: "red"}}>FABRIC</span></button></Link>
                    </div>
                    <br />
	                <div className="col-sm-12 application">
	                    <h1>APPLICATION</h1>
	                    <div className="hover14 text-center justify-content-center">
	                    <br />
	                        <div className="col-sm-12">
		                        <figure className="m-0"><div className="tinted-image">CINEMA HALL</div></figure>
	                        </div>
                        <br />
	                        <div className="col-sm-12">
        	                    <figure className="m-0"><div className="tinted-image2">OFFICE ROOMS & HALLS</div></figure>
	                        </div>
                        <br />
	                        <div className="col-sm-12">
        	                    <figure className="m-0"><div className="tinted-image3">AUDITORIUM & EDUCATION</div></figure>
     	                    </div>
                        <br />
                        </div>
                    </div>
	                <br />
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acfabricspinel;