import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import ScrollToTopButton from '../ScrollButton';
function reverberation() {
    return (
      
    <>
    <Helmet>
    <title>Reflected Sound Reverberation Acoustic - Acoustical Surfaces </title>
    <meta name="description" content="" />
    <meta property="og:title" content="Reflected Sound – Reverberation" />
    <meta property="og:description" content="When a sound source ceases in a space, the sound waves will continue to reflect off the hard wall, floor and ceiling surfaces until it loses enough energy and dies out. " />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/reverberation.gif" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/reverberation" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/reverberation" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/reverberation" />
    </Helmet>
    <Header />
    <section className="content">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Reflected Sound – Reverberation</h1>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text">
                                <p>When a sound source ceases in a space, the sound waves will continue to reflect off the hard wall, floor and ceiling surfaces until it loses enough energy and dies out. The prolongation of the reflected sound is known as reverberation. Reverberation Time (RT) is the number of seconds it takes for the reverberant sound energy to die down to one millionth (or 60dB) of its original value from the instant that the sound signal ceases.</p>
                            </div>
                            <div className="col-xl-6 col-sm-12">
                                <Link className="example-image-link" to="images/inner/reverberation.gif" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src="images/inner/reverberation.gif" alt="acoustics" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-sm-12">
                                <Link className="example-image-link" to="images/inner/101x5_1.gif" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src="images/inner/101x5_1.gif" alt="acoustics" />
                                </Link>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text">
                                <p>Reverberation is dependent only on the volume of a space and the acoustically absorptive quality of the room’s finishes. Hard surfaced rooms will have a longer reverberation time than rooms finished with sound absorbing materials. <br /> <br />When a sound wave travels outward in all directions and encounters an obstacle such as a wall, floor or ceiling surface the direction of the sound will be changed or reflected. The direction of travel of the reflected sound will be at the same angle as the original sound striking the surface as shown in (FIGURE 10) <br /> <br />The immediate effect of multiple reflections is an increase in the sound intensity caused by the reflections. A listener will hear the direct sound arriving at the ear along with all of the multiple reflections. Thus the combined loudness of the direct sound and the reflected sound will be greater than the direct sound alone.</p>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textalign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src="images/additional-resources/ebook-acoustics-demyst.webp" alt="Create Better Sounding Rooms" target="_blank" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi" target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textalign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src="images/additional-resources/Common-Noise-Problems-3D01x.webp" alt="Common Noise Problems" target="_blank" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi" target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textalign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src="images/additional-resources/ebook-cad-revit.webp" alt="CAD Library" target="_blank" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi" target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default reverberation; 