import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Carousel from './carousel-fabriwall';
import Fabriwall from '../../images/fabwall/fabriwall2.webp';
import Card1 from '../../images/fabwall/card1.webp';
import Card2 from '../../images/fabwall/card2.webp';
import Card3 from '../../images/fabwall/card3.webp';
import Mobsidebar from '../mobile-sidebar';
import './fabriwall-style.css';
import ScrollToTopButton from '../ScrollButton';
function fabriwall() {
    return (
      
    <>
    <Helmet>
    <title>Fabriwall - Fabric Acoustic Panels </title>
    <meta name="description" content=" " />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Fabriwall - Fabric Acoustic Panels" />
    <meta property="og:description" content=" " />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/tiles.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/fabriwall" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/fabriwall" hreflang="en-ae" /> 
    <link rel="alternate" href="https://www.acousticalsurfaces.in/fabriwall" hreflang="en-in" />
    </Helmet>
    <Header />
    <section className="content" id="fabriwall">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
	                    <div className="col-sm-12 heading-fsorb">				
		                    <h1>Fabri<span className="fabriwall">WALL</span></h1>
                            <h4 className="p-0"><b>Stretch Fabric Acoustical Wall Track System</b></h4>
		                    <p>FabriWALL acoustical wall & ceiling panel is an ecofriendly, flexible, PVC -free track solution that enables architects and designers to fulfil desired specifications and criteria for any project. The system consists of a set of tracks which acts as a framework. The infill
                                is placed between this frame work and the fabric is stretched into the interlocking jaws of the track giving the whole system a perfect finish. </p>
		                    <img src={Fabriwall} alt="Fabriwall" />
	                    </div>
                    </div>
                    <div className="col-sm-12 features">
	                    <h1>Applications</h1>
                        <div className='line'></div>
                        <div className="row pad ">	
		                    <div className="col-md-6 col-sm-12 d-none d-md-block">
		                        <h4>FOR COMMERCIAL SPACES</h4>
		                        <br />
    		                    <ul>
                		            <li>Offices</li>
        		                    <li>Schools</li>
        		                    <li>Meeting Rooms</li>
        		                    <li>Hotels</li>
    		                    </ul>
		                    </div>
		                    <div className="col-md-6 col-sm-12 d-none d-md-block">
		                        <h4>FOR RESIDENTIAL SPACES</h4>
		                        <br />
    		                    <ul>
        		                    <li>Auditorium</li>
        		                    <li>Recording Studios</li>
        		                    <li>Conference halls</li>
        		                    <li>Broadcasting Studios</li>
    		                    </ul>
		                    </div>  
	                    </div>
                        <div className="col-sm-12 panels">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" data-toggle="tab" href="#home">SPECIFICATION</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#menu1">REVERBERATION</a>
                                </li>
    	                        <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#menu3">DATASHEET</a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div id="home" className="container tab-pane active"><br />
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Core</th>
                                                <td>Echo Eliminator | Poly Max | Foam Stop | Fiber Board</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Thickness (mm)</th>
                                                <td>Standard 25mm and 50mm, custom thickness are also possible</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Sizes (mm)</th>
                                                <td>Any dimension or design to meet aesthetic architectural applications </td>
                                            </tr>
        	                                <tr>
                                                <th scope="row">Acoustic Properties</th>
                                                <td>NRC upon 1.15 ( Varies with core, thickness and mounting)</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Density</th>
                                                <td>Standard 48Kg/m3, 64 Kg/m3 and 96 Kg/m3</td>
                                            </tr>
	                                        <tr>
                                                <th scope="row">Colours</th>
                                                <td>Any decorative acoustical fabric color of your choice</td>
                                            </tr>
	                                        <tr>
                                                <th scope="row">Flamability</th>
                                                <td>EE:- ASTM E84 className A | ASI Weave:- BSN:1021 PART 1 AND 2</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Applications</th>
                                                <td>Offices, Schools, Meeting Rooms, Auditoriums, Theatres, etc.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div id="menu1" className="container tab-pane fade"><br />
	                                <h3>FabriWALL Echo Eliminator</h3>
	                                <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Thickness</th>
                                                <th scope="col">125Hz</th>
                                                <th scope="col">250Hz</th>
                                                <th scope="col">500Hz</th>
                                                <th scope="col">1KHz</th>
                                                <th scope="col">2KHz</th>
                                                <th scope="col">4KHz</th>
                                                <th scope="col">NRC</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">25mm</th>
                                                <td>0.08</td>
                                                <td>0.31</td>
                                                <td>0.79</td>
                                                <td>1.01</td>
                                                <td>1.00</td>
                                                <td>0.99</td>
                                                <td>0.80</td>
                                            </tr>
            	                            <tr>
                                                <th scope="row">50mm</th>
                                                <td>0.35</td>
                                                <td>0.94</td>
                                                <td>1.32</td>
                                                <td>1.22</td>
                                                <td>1.06</td>
                                                <td>1.03</td>
                                                <td>1.15</td>
                                            </tr>
                                        </tbody>
                                    </table>
		                            <br />
		                            <h3>FabriWALL Poly Max</h3>
		                            <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Thickness</th>
                                                <th scope="col">125Hz</th>
                                                <th scope="col">250Hz</th>
                                                <th scope="col">500Hz</th>
                                                <th scope="col">1KHz</th>
                                                <th scope="col">2KHz</th>
                                                <th scope="col">4KHz</th>
                                                <th scope="col">NRC</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">25mm</th>
                                                <td>0.08</td>
                                                <td>0.20</td>
                                                <td>0.59</td>
                                                <td>0.94</td>
                                                <td>1.04</td>
                                                <td>1.02</td>
                                                <td>0.70</td>
                                            </tr>
	                                        <tr>
                                                <th scope="row">50mm</th>
                                                <td>0.31</td>
                                                <td>0.58</td>
                                                <td>1.08</td>
                                                <td>1.11</td>
                                                <td>1.04</td>
                                                <td>1.04</td>
                                                <td>0.95</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <h3>FabriWALL Foam Stop</h3>
	                                <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Thickness</th>
                                                <th scope="col">125Hz</th>
                                                <th scope="col">250Hz</th>
                                                <th scope="col">500Hz</th>
                                                <th scope="col">1KHz</th>
                                                <th scope="col">2KHz</th>
                                                <th scope="col">4KHz</th>
                                                <th scope="col">NRC</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">25mm</th>
                                                <td>0.06</td>
                                                <td>0.19</td>
                                                <td>0.47</td>
                                                <td>1.05</td>
                                                <td>0.94</td>
                                                <td>0.88</td>
                                                <td>0.66</td>
                                            </tr>
	                                        <tr>
                                                <th scope="row">50mm</th>
                                                <td>0.08</td>
                                                <td>0.63</td>
                                                <td>1.11</td>
                                                <td>1.07</td>
                                                <td>1.04</td>
                                                <td>0.97</td>
                                                <td>0.96</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
	                                <h3>FabriWALL Fibre Board</h3>
	                                <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Thickness</th>
                                                <th scope="col">125Hz</th>
                                                <th scope="col">250Hz</th>
                                                <th scope="col">500Hz</th>
                                                <th scope="col">1KHz</th>
                                                <th scope="col">2KHz</th>
                                                <th scope="col">4KHz</th>
                                                <th scope="col">NRC</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">25mm</th>
                                                <td>0.14</td>
                                                <td>0.27</td>
                                                <td>0.80</td>
                                                <td>1.11</td>
                                                <td>1.14</td>
                                                <td>1.14</td>
                                                <td>0.85</td>
                                            </tr>
	                                        <tr>
                                                <th scope="row">50mm</th>
                                                <td>0.22</td>
                                                <td>0.81</td>
                                                <td>1.24</td>
                                                <td>1.30</td>
                                                <td>1.21</td>
                                                <td>1.16</td>
                                                <td>1.15</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
	                                <h3>FabriWALL CFAB</h3>
	                                <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Thickness</th>
                                                <th scope="col">125Hz</th>
                                                <th scope="col">250Hz</th>
                                                <th scope="col">500Hz</th>
                                                <th scope="col">1KHz</th>
                                                <th scope="col">2KHz</th>
                                                <th scope="col">4KHz</th>
                                                <th scope="col">NRC</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">25mm</th>
                                                <td>0.09</td>
                                                <td>0.26</td>
                                                <td>0.84</td>
                                                <td>1.05</td>
                                                <td>1.05</td>
                                                <td>1.05</td>
                                                <td>0.80</td>
                                            </tr>
	                                        <tr>
                                                <th scope="row">50mm</th>
                                                <td>0.39</td>
                                                <td>0.63</td>
                                                <td>1.18</td>
                                                <td>1.11</td>
                                                <td>1.06</td>
                                                <td>1.09</td>
                                                <td>1.00</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div id="menu3" className="container tab-pane fade"><br />
                                <br />
                                    <h3>Datasheet</h3>
                                    <br />
                                    <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/fabriwall/polymax2.pdf" className="brochure mr-3 mb-4">Polymax</a>
                                    <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/fabriwall/cfab.pdf" className="brochure mr-3 mb-4">CFAB</a>
                                    <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/fabriwall/echo-eliminator.pdf" className="brochure mr-3 mb-4">Echo Eliminator</a>
                                    <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/fabriwall/fiberboard.pdf" className="brochure mr-3 mb-4">Fiber Board</a>
                                    <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/fabriwall/foam stop.pdf" className="brochure mb-4">Foam Stop</a>
                                </div>
                            </div>
                        </div>			
                        <div className="container pad2">
                            <Carousel />
                        </div>
                        <br />
                        <div className="line2"></div>		
                    </div>
                    <div className="col-sm-12 featured-products">
                        <h4>FEATURED PRODUCTS</h4>
	                    <br />
	                    <div className="row pb-5">
		                    <div className="col-md-4 col-sm-12 featured-card">
                                <div className="card text-center">
      			                    <img className="card-img-top" src={Card1} alt="Card cap1" />
      			                    <div className="card-block">
    			                        <h4 className="card-title">Fabri<span>Wall</span> TRACK</h4>
    			                        <p className="card-text">Tracks are on-site fabricated acoustical systems that enables fabric be stretched for interior works.</p>
    			                        <Link to="/fabriwallTrack" className="btn btn-danger">View Track</Link>
      			                    </div>
    		                    </div>
		                    </div>
		                    <div className="col-md-4 col-sm-12 featured-card">
    		                    <div className="card text-center">
     			                    <img className="card-img-top" src={Card2} alt="Card cap2" />
      			                    <div className="card-block">
    			                        <h4 className="card-title">Fabri<span>Wall</span> FABRIC</h4>
    			                        <p className="card-text">We manufacture all kind of interior panel and upholstery fabrics using finest quality of Polyster</p>
    			                        <Link to="/fabriwallfabric" className="btn btn-danger">View Fabric</Link>
      			                    </div>
    	                        </div>
		                    </div>
		                    <div className="col-md-4 col-sm-12 featured-card">
    		                    <div className="card text-center">
      			                    <img className="card-img-top" src={Card3} alt="Card cap3" />
      			                    <div className="card-block">
    			                        <h4 className="card-title">Fabri<span>Wall</span> PANEL</h4>
    			                        <p className="card-text">Fabriwall Panel is an interior acoustic solution that absorbs noise and mitigates sound reverberation,</p>
    			                        <Link to="/fabriwallpanel" className="btn btn-danger">View Panel</Link>
      			                    </div>
    		                    </div>
		                    </div>
	                    </div>	
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default fabriwall; 