import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import Cadlibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Echo from '../../images/inner/echo-eggcrate-graphite1-200x200.jpg';
import Melamine from '../../images/inner/melamine_soundwave_foam1-200x200.jpg';
import Foamst from '../../images/inner/foamst_51.jpg';
import Linear from '../../images/inner/linearwedges_351-200x124.jpg';
import Melamine2 from '../../images/inner/melamine-flat-foam1-200x200.jpg';
import Melcomp from '../../images/inner/mel_comp1-200x200.jpg';
import Sonex from '../../images/inner/sonex_one-200x200.jpg';
import Mobsidebar from '../mobile-sidebar';
import './acousticalfoam-eggcrate.css';
import ScrollToTopButton from '../ScrollButton';
function acousticfoam() {
    return (
      
    <>
    <Helmet>
    <title>Egg Crate Foam Soundproofing - Acoustical Surfaces</title>
    <meta name="description" content="Acoustical Surfaces Pvt. Ltd provides soundproofing solutions for all noise issues." />
    <meta property="og:title" content="Acoustical Foams & Eggcrate" />
    <meta property="og:description" content="Foam absorbs noise for excellent sound and noise control and also reduces reverberation times." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/echo-eggcrate-graphite1-200x200.jpg" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acousticalfoam-eggcrate" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="eggcrate">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                    </div>
                    <h3>Acoustical Foams & Eggcrate</h3>
                    <div className="row">
                        <div className="col-sm-12">
                            <article className="clearfix">
                                <p>Foam absorbs noise for excellent sound and noise control and also reduces reverberation times. Egg crate foam soundproofing is widely used in recording studios, home theaters, broadcasting studios, and many other industrial and commercial applications.</p>
                                <p>Offering many different configurations of foam sound absorbers, we will find the right egg crate foam soundproofing solution for any situation. Made from open cell melamine, foam panels come in a variety of patterns that can be applied to ceilings and walls and are className A fire retardant. Melamine composite systems handle situations where noise reduction, sound absorption, and vibration control are needed such as in equipment enclosures or mechanical rooms. With many products to choose from, we will be able to meet any application requirements for an ideal noise control solution.</p>
                                <p>Above you will find some of our most popular Foam noise reduction products. <Link to="/contact" target="_blank" rel="noopener">Contact us</Link> today to learn more.</p>
                            </article>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12"><h4>Foam Products</h4></div>
                        <div className="col-sm-3 dbox">
                            <Link to="/#"><img className="img" src={Echo} alt="Echo Eggcrate Graphitel" /></Link>
                            <h5>Cotton Soundwave Eggcrate Panels</h5>
                        </div>
                        <div className="col-sm-3 dbox">
                            <Link to="/#"><img className="img" src={Melamine} alt="Melamine Soundwave foam" /></Link>
                            <h5>Melamine Sound wave panels</h5>
                        </div>
                        <div className="col-sm-3 dbox">
                            <Link to="/#"><img className="img" src={Foamst} alt="foamst" /></Link>
                            <h5>Melamine Pyramid Panels</h5>
                        </div>
                        <div className="col-sm-3 dbox">
                            <Link to="/#"><img className="img" src={Linear} alt="Linearwedges" /></Link>
                            <h5>Melamine Linear Wedge Panels</h5>
                        </div>
                        <div className="col-sm-3 dbox">
                            <Link to="/#"><img className="img" src={Melamine2} alt="Melamine" /></Link>
                            <h5>Melamine Flat-faced foam</h5>
                        </div>
                        <div className="col-sm-3 dbox">
                            <Link to="/#"><img className="img" src={Melcomp} alt="mel comp" /></Link>
                            <h5>Melamine Composite panels</h5>
                        </div>
                        <div className="col-sm-3 dbox">
                            <Link to="/#"><img className="img" src={Sonex} alt="sonex" /></Link>
                            <h5>Sonex One</h5>
                        </div>
                    </div>
                    <hr />
                    <div className="row resources">
                        <div className="col-xl-12 col-lg-12 col-sm-12">
                            <h2>Additional Resources</h2>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-sm-12">
                            <h6 style={{textalign: "center"}}>Creating Better-Sounding Rooms</h6>
                            <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms" target="_blank" rel="noopener noreferrer" />
                            <div className="butn"><Link to="/bs-rooms-guide">Download eBook</Link></div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-sm-12">
                            <h6 style={{textalign: "center"}}>Solutions to Common Noise Problems</h6>
                            <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems" target="_blank" rel="noopener noreferrer" />
                            <div className="butn"><Link to="/noise-problems-guide">Download eBook</Link></div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-sm-12">
                            <h6 style={{textalign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                            <img className="hvr-grow" src={Cadlibrary} alt="CAD Library" target="_blank" rel="noopener noreferrer" />
                            <div className="butn"><Link to="/cad-library-guide">Access Product Cad Files</Link></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default acousticfoam;