import React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Soundingrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoiseprob from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CAD from '../../images/additional-resources/ebook-cad-revit.webp';
import './links.css';
import ScrollToTopButton from '../ScrollButton';
function links() {
    return (
      
    <>
    <Helmet>
    <title>Acoustics Soundproofing Solutions</title>
    <meta name="description" content="" />
    <meta name="robots" content="noindex, follow" />
    <meta property="og:title" content="Soundproofing Insulation and Acoustic Panel Manufacturers India" />
    <meta property="og:description" content="Acoustical Surfaces, has been providing acoustical solutions to soundproofing, noise control, acoustical and vibration problems for over 35 years." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.png" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/links" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/links" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/links" />
    </Helmet>
    <Header />
    <section className="content" id="links">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Related Links</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="links">
                                    <p><strong>Interesting Web Site Links</strong><br />
                                        Acoustical Society of America (ASA)<br />
                                    </p>
                                    <p>Noise Pollution Clearinghouse (A complete web site for noise issues)<br />
                                    <a href="http://www.nonoise.org" target="_blank" rel="noopener noreferrer">http://www.nonoise.org</a><br />
                                    <a href="http://www.nonoise.org/quietnet/qc" target="_blank" rel="noopener noreferrer">http://www.nonoise.org/quietnet/qc</a><br />
                                    <a href="http://www.nonoise.org/quietnet/qc/booklet.htm" target="_blank" rel="noopener noreferrer">http://www.nonoise.org/quietnet/qc/booklet.htm</a></p>
                                    <p>The US Architectural and Transportation Barriers Compliance Board (USATBCB), The Access Board<br />
                                    <a href="http://www.access-board.gov/" target="_blank" rel="noopener noreferrer">http://www.access-board.gov/</a></p>
                                    <p>Institute of Noise Control Engineers (INCE)<br />
                                    <a href="http://www.inceusa.org" target="_blank" rel="noopener noreferrer">inceusa.org</a></p>
                                    <p>Google, the best search engine around – Search “classNameroom acoustics”<br />
                                    <a href="https://www.google.com/search?ie=UTF-8&amp;q=classNameroom+acoustics" target="_blank" rel="noopener noreferrer">http://www.google.com</a></p>
                                    <p>A newsgroup worth observing.<br />
                                    <a href="http://groups.google.com/groups?hl=en&amp;lr=&amp;ie=UTF-8&amp;newwindow=1&amp;group=alt.sci.physics.acoustics" target="_blank" rel="noopener noreferrer">alt.sci.physics.acoustics</a></p>
                                    <p>Freight Management Logistics – ASI’s Logistics Heroes<br />
                                    <a title="ltl freight minnesota" href="http://www.fmlfreight.com/" target="_blank" rel="noopener noreferrer">LTL Freight Minnesota – http://www.fmlfreight.com</a></p>
                                    <hr />
                                    <h2>Other Acoustical Surfaces Sites</h2>
                                    <p><strong>Pro Audio Acoustics</strong><br />
                                    Home Theater, Commercial Sound and Recording Studios<br />
                                    <a href="http://www.proaudioacoustics.com" target="_blank" rel="noopener noreferrer">http://www.proaudioacoustics.com</a></p>
                                    <p><strong>Architectural Surfaces Inc.</strong><br />
                                    Acoustical Wood Ceiling and Wall Systems<br />
                                    <a href="http://www.architecturalsurfaces.net/" target="_blank" rel="noopener noreferrer">http://www.architecturalsurfaces.net/</a></p>
                                    <p><strong>Fire Retardants Inc.</strong><br />
                                    Fire Retardant Paint, Varnish, Coatings and Solutions for Fabric, Paper, Wood, Metal and More<br />
                                    <a href="http://www.fireretardantsinc.com/" target="_blank" rel="noopener noreferrer">http://www.fireretardantsinc.com/</a></p>
                                    <p><strong>Sound Silencer™</strong><br />
                                    An innovative product from ASI<br />
                                    <a href="http://www.soundsilencer.com/" target="_blank" rel="noopener noreferrer">http://www.soundsilencer.com/</a></p>
                                    <p><strong>Echo Eliminator™</strong><br />
                                    An innovative product from ASI<br />
                                    <a href="http://www.echoeliminator.com/" target="_blank" rel="noopener noreferrer">http://www.echoeliminator.com/</a></p>
                                    <p><strong>ASI Pro Audio</strong><br />
                                    Residential, Home Theater, House of Worship Acoustics<br />
                                    <a href="http://www.asiproaudio.com/" target="_blank" rel="noopener noreferrer">http://www.asiproaudio.com/</a></p>
                                   
                                    <p><strong>Soundproofing Blog</strong><br />
                                    Articles and Helpful Tips<br />
                                    <a href="https://www.acousticalsurfaces.com/blog/" target="_blank" rel="noopener noreferrer">www.acousticalsurfaces.com/blog/</a></p>
                                    <p><strong>Buy Online</strong><br />
                                    Purchase acoustical and soundproofing products online, secure site.<br />
                                    <a href="http://www.asistorefront.com" target="_blank" rel="noopener noreferrer">http://www.asistorefront.com/</a></p>
                                    <p><strong>RSIC-1 Sound Isolation Clips</strong><br />
                                    The ultimate for soundproofing walls, ceilings and floors.<br />
                                    <a href="http://www.rsic1clips.com/" target="_blank" rel="noopener noreferrer">http://www.rsic1clips.com/ </a></p>
                                    <p><strong>Acoustical and Thermal Window Inserts</strong><br />
                                    The finest acoustical, thermal and preservation window inserts.<br />
                                    <a href="http://www.climateseal.com/" target="_blank" rel="noopener noreferrer">http://www.climateseal.com/</a></p>
                                    <hr />
                                    <h2>Textbooks</h2>
                                    <ul>
                                        <li><em>Acoustics</em>, Charles M. Salter Associates, William Stout Publishers, San Francisco 1999; ISBN 0-9651144-6-5.</li>
                                        <li><em>Acoustics and Noise Control Handbook for Architects and Builders</em>, Leland K. Irvine and Roy L. Richards, Krieger Publishing Co., Malabar, FL.</li>
                                        <li><em>Acoustics and Sound Systems in Schools</em>, Frederick S. Berg, Singular Publishing Group, San Diego, 1993.</li>
                                        <li><em>Architectural Acoustics</em>, Madan Mehta, Jim Johnson, and Jorge Rocafort, Prentice-Hall, New York, 1998.</li>
                                        <li><em>classNameroom Acoustics</em>, T. Finitzo-Hieber, in Auditory Disorders in School Children, Thieme-Stratton, New York (1988, second edition).</li>
                                        <li><em>Concepts in Architectural Acoustics</em>, M. David Egan, McGraw-Hill, New York, 1998.</li>
                                        <li><em>Deaf Architects and Blind Acousticians?</em> A Guide to the Principles of Sound Design, R.E. Apfel, 1998; ISBN 0-9663331-0-1.</li>
                                        <li><em>Mechanical and Electrical Equipment for Buildings</em>, “Acoustics”, Stein, Reynolds, and McGuiness, New York, 1996.</li>
                                        <li><em>Acoustical Guidelines for Building Design</em>, E. Wetherill, in Encyclopedia of Acoustics, Wiley, New York, 1997.</li>
                                        <li><em>Noise Control in U.S. Building Codes</em>, Greg Tocci, in Handbook of Acoustics, Wiley &amp; Sons.</li>
                                        <li><em>Room Acoustics</em>, C. Crandell and J. Smaldino, in Assistive Devices for the Hearing-Impaired, Allyn &amp; Baon, Needham Heights, MA, 1994.</li>
                                        <li><em>Noise Control Manual for Residential Buildings</em>, David Harris, McGraw Hill</li>
                                        <li><em>Master Handbook of Acoustics</em>, Alton Everest. McGraw Hill.</li>
                                        <li><em>Acoustical Designing In Architecture</em>, Vern Knudsen, Cyril Harris, Acoustical Society Of America.</li>
                                        <li><em>classNameroom Acoustics</em>, Students of the Architectural Engineering Dept, U. of Kansas, Acoustical Society of America.</li>
                                        <li><em>Sound-Field FM Amplification</em>, Crandell, Smaldino, Flexer, Singular Press, San Diego.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textalign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundingrooms} alt="Create Better Sounding Rooms" target="_blank" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi" target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textalign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoiseprob} alt="Common Noise Problems" target="_blank" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi" target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <hr />
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textalign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CAD} alt="CAD Library" target="_blank" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi" target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default links; 