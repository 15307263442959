import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import './fabric-wrapped-fiberglass-baffles.css';
import Baffles1 from '../../images/inner/fbs_fabr1.jpg';
import Baffles2 from '../../images/inner/baffelsjpg.jpg';
import Baffles3 from '../../images/inner/fbs_fabr3.jpg';
import Baffles4 from '../../images/inner/fbs_fabr4-200x191.jpg';
import Baffles5 from '../../images/inner/fbs_fabr5-200x191.jpg';
import ScrollToTopButton from '../ScrollButton';
function fiberglassbaf() {
    return (
      
    <>
    <Helmet>
    <title>Decorative Fabric Wrapped Acoustical Baffles - Fabrisorb</title>
    <meta name="description" content="" />
    <meta property="og:title" content="Decorative Fabric Wrapped Acoustical Baffles" />
    <meta property="og:description" content=" " />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/fbs_fabr1.jpg" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/fabric-wrapped-fiberglass-baffles" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/fabric-wrapped-fiberglass-baffles" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/fabric-wrapped-fiberglass-baffles" hreflang="en-in" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="IN" />
    <meta name="geo.region" content="india" />
    </Helmet>
    <Header />
    <section className="content" id='Fiberglassbaffles'>
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>NOISE S.T.O.P. FABRISORB™</h1>
                        <div className="row">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/fbs_fabr1.jpg" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Baffles1} alt="Fiberglass baffles1" />
                                </Link>
                                <Link className="example-image-link" to="../../images/inner/baffelsjpg.jpg" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Baffles2} style={{maxwidth: "240px"}} alt="Fiberglass baffles2" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Decorative Fabric Wrapped Acoustical Baffles</h2>
                                <ul style={{marginleft: "1em"}}>                                                    
                                    <li>High Performance</li>
                                    <li>Architecturally Decorative</li>
                                    <li>Custom Engineered & Manufactured</li>
                                </ul>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li><Link to="https://acousticalsurfaces.in/pdfs/Noise-S.T.O.P.-Fabrisorb.pdf" target="_blank">Product Specs</Link></li>
                                        <li><Link to="https://acousticalsurfaces.in/pdfs/097711-Fabric-Wrapped-Acoustical-Baffles-fabrisorb.doc">CSI Specifications</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <tr>
                                        <td rowspan="3">
                                            <Link className="example-image-link" to="../../images/inner/fbs_fabr3.jpg" data-lightbox="example-1">
                                                <img className="example-image img-fluid" src={Baffles3} alt="Fiberglass baffles3" />
                                            </Link>
                                        </td>
                                        <th>Construction</th>
                                        <td>Two pieces of 7PCF glass fiber with square or radius edges with perforated vinyl or fabric.</td>
                                    </tr>
                                    <tr>
                                        <th>Mounting</th>
                                        <td>D-rings</td>
                                    </tr>
                                    <tr>
                                        <th>Thickness</th>
                                        <td>1″, 1-1/2″, 2″</td>
                                    </tr>
                                </table>
                                <p></p>
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="11">SABINS PER BAFFLE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td rowspan="2">Thickness</td>
                                            <td rowspan="2">Finish</td>
                                            <td rowspan="2">Core</td>
                                            <td colSpan="7">Absorption (Sabins per 48″ × 24″ Unit)</td>
                                        </tr>
                                        <tr className="colheader">
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1000Hz</td>
                                            <td>2000Hz</td>
                                            <td>4000Hz</td>
                                            <td>SABINS</td>
                                        </tr>
                                        <tr>
                                            <td>2″</td>
                                            <td>Fabric</td>
                                            <td>Glass Fiber</td>
                                            <td>4.48</td>
                                            <td>8.00</td>
                                            <td>13.20</td>
                                            <td>17.93</td>
                                            <td>16.98</td>
                                            <td>16.35</td>
                                            <td className="emphasis">14.02</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-lg-4">
                                <Link className="example-image-link" to="../../images/inner/fbs_fabr4-200x191.jpg" data-lightbox="example-1">
                                    <img className="example-image img-fluid d-none d-sm-block" src={Baffles4} alt="Fiberglass baffles4" />
                                </Link>
                            </div>
                            <div className="col-sm-6 col-lg-4">
                                <Link className="example-image-link" to="../../images/inner/fbs_fabr5-200x191.jpg" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Baffles5} alt="Fiberglass baffles5" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default fiberglassbaf; 