import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Decorative1 from '../../images/inner/silk-metal-tiles1-200x200.webp';
import Decorative2 from '../../images/inner/curve_acoustical_medium_diffusor_vanilla1-200x200.webp';
import Decorative3 from '../../images/inner/custom_graphic1-200x180.webp';
import Decorative4 from '../../images/inner/ceiling_metal3-200x200.webp';
import Decorative5 from '../../images/inner/fabric-wrapped-panel-edge-details-lg3-200x200.webp';
import Decorative6 from '../../images/inner/acousti-curtain-conference-room1-200x200.webp';
import Decorative7 from '../../images/inner/arch-200x200.webp';
import './soundproofing-decorative.css';
import ScrollToTopButton from '../ScrollButton';
function soundproofdecorative() {
    return (
      
    <>
    <Helmet>
    <title>Soundproofing Decorative Panels | Acoustic High-end Products</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Soundproofing Decorative / High-end Products" />
    <meta property="og:description" content="Decorative and High-End acoustical products offer effective noise controlling solutions that fit into any décor or atmosphere." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/silk-metal-tiles1-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/soundproofing-decorative" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/decorative-highend" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/soundproofing-decorative" />
    </Helmet>
    <Header />
    <section className="content" id="decorative">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Soundproofing Decorative / High-end Products</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>Improving sound quality in an area doesn’t have to be completely utilitarian, or unattractive, in appearance. Decorative and High-End acoustical products offer effective noise controlling solutions that fit into any décor or atmosphere. In fact, these products not only improve the acoustical qualities of a space, they also enhance the aesthetics!</p>
                                    <p>Our wide-ranging products include decorative soundproof wall and ceiling panels that are available in different materials and numerous color options. Fabric wrapped fiberglass panels offer high performance combined with the architecturally decorative appearance required for offices, hotels, school, meeting rooms, or areas where good speech privacy and intelligibility are important. If you have an area that needs acoustical control but must withstand harsh industrial environments then the innovative Acoustimetal™ products are for you. In addition to durability and impact resistance, these silk metal panels and tiles create a visually appealing interior design. Need to control acoustic reflection off a glass window pane? The Acousti-Curtain™ is a new product that is custom made from sound absorbing material and comes in a wide variety of face layer and lining fabric options to fit in with any décor.</p>
                                    <p>Custom designed systems will match your needs for sound absorption, cost, and appearance. Our decorative soundproof panels and ceiling tiles are available with custom graphic options for a one-of-a-kind acoustical solution.</p>
                                    <p>Below you’ll find a sample of our Decorative Soundproof Panels and other High-End products. <Link to="/contact-us">Contact us</Link> today to learn more.</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>View Our Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Decorative1} alt="Silk metal and tiles" />
                                </Link>
                                <h5>Silk Metal Panels and Tiles</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Decorative2} alt="Curve Diffusers" />
                                </Link>
                                <h5>Curve Diffusers</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Decorative3} alt="Custom Graphic Options" />
                                </Link>
                                <h5>Custom Graphic options</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Decorative4} alt="Acoustimetal" />
                                </Link>
                                <h5>Acoustimetal</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Decorative5} alt="Fabric Wrpped Fiberglass" />
                                </Link>
                                <h5>Fabric Wrapped Fiberglass</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Decorative6} alt="Acoustic Curtain" />
                                </Link>
                                <h5>Acousti Curtain</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Decorative7} alt="Elite Ceiling" />
                                </Link>
                                <h5>Elite Ceilings</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default soundproofdecorative; 