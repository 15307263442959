import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Transport1 from '../../images/inner/vmax-peeled-back-200x200.webp';
import Transport2 from '../../images/inner/vblockvb2-new-200x153.webp';
import Transport3 from '../../images/inner/decoup-200x165.webp';
import Transport4 from '../../images/inner/pic13-200x185.webp';
import Transport5 from '../../images/inner/cellulose-panels1-200x200.webp';
import Transport6 from '../../images/inner/sound-silencer-panels-200x200.webp';
import Transport7 from '../../images/inner/silk-metal-tiles-sm1-200x200.webp';
import './transportation.css';
import ScrollToTopButton from '../ScrollButton';
function transportation() {
    return (
      
    <>
    <Helmet>
    <title>Soundproofing Materials for Cars, Ships, and other Modes of Transportation </title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Soundproofing Materials for Cars, Ships, and other Modes of Transportation" />
    <meta property="og:description" content="To improve noise control and reduce sound transmission, Acoustical Surfaces offers a wide range of automotive vibration control products that will meet the needs of the toughest transportation applications." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/vblockvb2-new-200x153.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/transportation" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/transportation" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/transportation" />
    </Helmet>
    <Header />
    <section className="content" id="transportation">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Soundproofing Materials for Cars, Ships, and other Modes of Transportation</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>To improve noise control and reduce sound transmission, Acoustical Surfaces offers a wide range of automotive vibration control products that will meet the needs of the toughest transportation applications.</p>
                                    <p>To reduce noise in automobiles and ships, we offer damping sheets that easily install to wood, sheet metal, plastic, or fiberglass surfaces. These specialized automotive vibration control films prevent body panels from vibrating, thus preventing unwanted noise in passenger areas. We also carry a line of barriers that reduce sound transmission of materials moving through waste pipes and HVAC ducts, and panels that reduce airborne sound transmission.</p>
                                    <p>Acoustic Surfaces is a leader in soundproofing and noise control solutions across a very wide range of industries and applications. Above is a list of some acoustic products that are most commonly used throughout the transportation industry. For more information about our car soundproofing materials , please <Link to="/contact-us">contact us</Link> today.</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>Transportation Soundproofing Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Transport1} alt="V-Max Vibration Damper" />
                                </Link>
                                <h5>V-Max Vibration Damper</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Transport2} alt="VB-2 Vibration Dampening Sheet" />
                                </Link>
                                <h5>VB-2 Vibration Dampening Sheet</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Transport3} alt="Barrier Decoupler" />
                                </Link>
                                <h5>Barrier Decoupler</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Transport4} alt="PVC Decoupler" />
                                </Link>
                                <h5>PVC Decoupler</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Transport5} alt="CFAB Cellulose Panels" />
                                </Link>
                                <h5>CFAB Cellulose Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Transport6} alt="Sound Silencer" />
                                </Link>
                                <h5>Sound Silencer</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Transport7} alt="Silk Metal" />
                                </Link>
                                <h5>Silk Metal</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default transportation;