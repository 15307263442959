import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Polymaxpolyester from '../../images/polymax/banner.webp';
import Polymaxgallery1 from '../../images/polymax/gall1.webp';
import Polymaxgallery2 from '../../images/polymax/gall2.webp';
import Polymaxgallery3 from '../../images/polymax/gall3.webp';
import Polymaxgallery4 from '../../images/polymax/gall4.webp';
import Polymaxgallery5 from '../../images/polymax/gall5.webp';
import Mobsidebar from '../mobile-sidebar';
import './style-polymax-polyester.css';
import ScrollToTopButton from '../ScrollButton';
function polymaxpolyester() {
    return (
      
    <>
    <Helmet>
    <title>Polymax Polyester Acoustic Panels</title>
    <meta name="description" content="Our polyester acoustic panels are non-allergenic, non-toxic and are available in a variety of colors which can also be printed with custom graphics to blend into any décor. Call Now!" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Poly Max Polyester Acoustics" />
    <meta property="og:description" content="polyester acoustical panels are nonallergenic, non-toxic, fungal-resistant, and contain no chemical irritants or formaldehyde." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/polymax/banner.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/polymax-polyester" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/polymax-polyester" />
    </Helmet>
    <Header />
    <section className="content" id="polymaxpoly">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
	                    <div className="col-sm-12 heading-fsorb">				
	                        <h1><span>Poly Max<sup>TM</sup></span> Polyester Acoustics</h1>
	                        <p>Poly Max<sup>TM</sup>        
                                polyester acoustical panels are nonallergenic, non-toxic, fungal-resistant, and contain no chemical irritants or formaldehyde. Decorative and tackable, these <Link to="/soundproofing-ceiling-tiles">acoustic ceiling tiles</Link> and <Link to="/acoustic-wall-panels">wall panels</Link> are available in a variety 
                                of colors and can be printed with custom graphics to blend into any décor. Material: 100% polyester (60% PET-recycled fiber, 40% PET-virgin fiber) 
                            </p>
	                        <div className="demo-cont" style={{height: "560px"}}>
	                            <img src={Polymaxpolyester} alt="Polymax Polyester" />
                            </div>
	                    </div>
	                    <div className="col-sm-12">
                            <div className="features">
	                            <h1><span style={{color: "#f13849"}}>Poly Max<sup>TM</sup></span> Acoustical Wall & Ceiling Tiles</h1>
	                            <hr />
	                            <p>Poly Max polyester tackable wall panels offer an acoustically absorbent panel that is non-allergenic, non-toxic, and contains no 
                                    chemical irritants or formaldehyde. These decorative acoustic ceiling and wall panels can be printed with custom graphics to blend into any décor.</p>
                                <div className="row pad ">	
		                            <div className="col-md-6 col-sm-12">
        		                        <ul>
		                                    <li>Formaldehyde Free</li>
		                                    <li>No Binding Agents</li>
		                                    <li>No Chemical Irritants</li>
		                                    <li>Architecturally Decorative</li>
        		                        </ul>
			                        </div>
			                        <div className="col-md-6 col-sm-12 d-none d-md-block">
	    		                        <ul>
		                                    <li>Tackable Surface</li>
		                                    <li>Impact Resistant</li>
		                                    <li>className A Fire Rated</li>
		                                    <li>60% Polyethylene Terephthalate – Recycled Content</li>
        		                        </ul>
			                        </div>
		                        </div>
                            </div>
	                    </div>
                        <br /><br />		
	                    <div className="col-sm-12 panels">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" data-toggle="tab" href="#home">SPECIFICATION</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#menu1">REVERBERATION</a>
                                </li>
	                            <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#menu3">DATASHEET</a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div id="home" className="container tab-pane active"><br />
                                    <table className="table">
                                        <tbody>
	                                        <tr>
                                                <th scope="row">Material</th>
                                                <td>Tackable walls made from 100% polyester (60% PET - Recycled Fiber, 40% PET - virgin fiber)</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Sizes</th>
                                                <td>Standard Size: 48″ × 96″<br />
			                                        Cut to Size/Custom Sizes: Up to : 48″ × 96″<br />
			                                        For custom sizes, please call us for details.<br />
			                                        Ceiling Tiles: 23¾″ × 47¾″
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Thickness</th>
                                                <td>1/2", 1″ and 2"</td>
                                            </tr>
	                                        <tr>
                                                <th scope="row">Color</th>
                                                <td>White</td>
                                            </tr>
        	                                <tr>
                                                <th scope="row">Weight</th>
                                                <td>1/2" thick 12.5# per cubic feet,<br />
			                                        1" thick 7.5# per cubic feet,<br />
			                                        2" thick 5# per cubic feet
                                                </td>
                                            </tr>
	                                        <tr>
                                                <th scope="row">Flammability </th>
                                                <td>className A Fire Rated per ASTM E84<br />
			                                        Flame Spread Index = 15<br />
			                                        Smoke Developed Index = 250
                                                </td>
                                            </tr>
	                                        <tr>
                                                <th scope="row">Installation</th>
                                                <td>Wall Panels: Adhesive, Direct Attach, Z-Clip, Stick Pin options available<br />
				                                    Ceiling Tiles: Lay-In, Adhesive
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
	                            </div>
                                <div id="menu1" className="container tab-pane fade"><br />
                                    <h3>Sound Absorption Coefficients</h3>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Thickness</th>
                                                <th scope="col">125Hz</th>
                                                <th scope="col">250Hz</th>
                                                <th scope="col">500Hz</th>
                                                <th scope="col">1KHz</th>
                                                <th scope="col">2KHz</th>
                                                <th scope="col">4KHz</th>
                                                <th scope="col">NRC</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">25mm</th>
                                                <td>0.06</td>
                                                <td>0.19</td>
                                                <td>0.47</td>
                                                <td>1.05</td>
                                                <td>0.94</td>
                                                <td>0.88</td>
                                                <td>0.66</td>
                                            </tr>
        	                                <tr>
                                                <th scope="row">50mm</th>
                                                <td>0.08</td>
                                                <td>0.63</td>
                                                <td>1.11</td>
                                                <td>1.07</td>
                                                <td>1.04</td>
                                                <td>0.97</td>
                                                <td>0.96</td>
                                            </tr>
                                        </tbody>
                                    </table>
	                            </div>
                                <div id="menu3" className="container tab-pane fade"><br />
	                                <h3>Datasheet</h3><br />
	                                <a target="_blank" rel="noopener noreferrer" href="https://acousticalsurfaces.in/pdfs/polymax/brochure.pdf" className="brochure">Download</a>
	                            </div>
                            </div>
	                    </div>	
	                    <div className="col-sm-12 more">
                            <h1> Applications </h1>
    	                    <div className="line"></div>
                            <p>Theaters & home theaters, gymnasiums & multipurpose room, commercial & office buildings, studios & production houses, pro audio, restaurants & night clubs, government & municipal buildings, 
                                hospitals & medical clinics, schools & universities, houses of worship, industrial & manufacturing facilities and much more</p>
                        </div>
	                    <br />
 	                    <div className="col-sm-12 gall">
    	                    <div className="row">
        	                    <div className="col-md-3 col-6">
                                    <img src={Polymaxgallery1} alt="Polymax gallery1" />
                                </div>
                                <div className="col-md-3 col-6 pl-0">
                                    <img src={Polymaxgallery2} alt="Polymax gallery2" />
                                </div>
                                <div className="col-md-3 col-6 pl-0">
                                    <img src={Polymaxgallery3} alt="Polymax gallery3" />
                                </div>
                                <div className="col-md-3 col-6 pl-0">
                                    <img src={Polymaxgallery4} alt="Polymax gallery4" />
                                </div>
                            </div>
                        </div>
	                    <br />
	                    <div className="col-sm-12">
                            <img src={Polymaxgallery5} alt="Polymax gallery5" />
                        </div>		
	                    <div className="line"></div>			
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default polymaxpolyester; 