import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import './acfabric-steadfast.css';
import Mobsidebar from '../mobile-sidebar';
import Sf1 from '../../images/acfabric/sf1.jpg';
import Sf2 from '../../images/acfabric/sf2.jpg';
import Sf3 from '../../images/acfabric/sf3.jpg';
import Sf4 from '../../images/acfabric/sf4.jpg';
import Sf5 from '../../images/acfabric/sf5.jpg';
import Sf6 from '../../images/acfabric/sf6.jpg';
import Sf7 from '../../images/acfabric/sf7.jpg';
import ScrollToTopButton from '../ScrollButton';
function acfabricstead() {
    return (
      
    <>
    <Helmet>
    <title>Acoustic FABRIC Steadfast - Acoustical Surfaces</title>
    <meta name="description" content="" />
    <meta property="og:title" content="AcoustyFABRIC Steadfast" />
    <meta property="og:description" content="Guilford of Maine Steadfast 3125 Fabric Facings" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/acfabric/sf1.jpg" />
    <meta property="og:locale" content="en-in" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/acfabric-steadfast" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/acfabric-steadfast" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/acfabric-steadfast" hreflang="en-ae" />
    </Helmet>
    <Header />
    <section className="content" id="acfabricstead">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
            	<div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
	                <div className="col-sm-12 heading-fsorb">				
		                <h1>Acousty<span className="fabriwall">FABRIC</span> Steadfast</h1>
                        <h4 className="mb-0"> <b>Guilford of Maine Steadfast 3125 Fabric Facings</b></h4>
		                <p><span className="color:red;">(Colors Shown are Approximate Match to Material)</span></p>
	                    <br />
		                <div className="col-sm-3 cloths p-0"><button>Product Specifications</button></div>
	                </div>
		            <br /><br />
		            <div className="col-sm-12 cloths">
		                <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Sf1} alt="Tin" />
                                <p className="text-center">Tin 011</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Sf2} alt="Slate" />
                                <p className="text-center">Slate 030</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Sf3} alt="Sage" />
                                <p className="text-center">Sage 013</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Sf4} alt="Oyster" />
                                <p className="text-center">Oyster 010</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Sf5} alt="Muslin" />
                                <p className="text-center">Muslin 012</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-2">
                        	<div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Sf6} alt="Dolphin" />
                                <p className="text-center">Dolphin 020</p>
                            </div>
                            <div className="col-2 col-sm-2 p-0 mx-2">
                                <img className="w-100" src={Sf7} alt="Coal" />
                                <p className="text-center">Coal 040</p>
                            </div>
                            <div className="col-sm-2 p-0 mx-2"></div>
                            <div className="col-sm-2 p-0 mx-2"></div>
                            <div className="col-sm-2 p-0 mx-2"></div>
                        </div>
                    </div>
		            <br />
		            <hr />
                    <div className="col-12 d-flex justify-content-center backbtn">
                        <Link to="/acoustyfabric"><button>Back to Acousty<span style={{color: "red"}}>FABRIC</span></button></Link>
                    </div>
                    <hr />
                    <div className="col-sm-12 application">
			            <h1>APPLICATION</h1>
	                    <div className="hover14 text-center justify-content-center">
		                <br />
  		                    <div className="col-sm-12">
			                    <figure className="m-0"><div className="tinted-image">CINEMA HALL</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image2">OFFICE ROOMS & HALLS</div></figure>
 		                    </div>
                        <br />
  		                    <div className="col-sm-12">
    	                        <figure className="m-0"><div className="tinted-image3">AUDITORIUM & EDUCATION</div></figure>
 		                    </div>
                        <br />
                        </div>
                    </div>
		            <br />
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
    <Footer />
</>
);
}
export default acfabricstead;