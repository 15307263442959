import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Cinemaport1 from '../../images/inner/cp-Front.webp';
import Cinemaport2 from '../../images/inner/cp-Open.webp';
import Cinemaport3 from '../../images/inner/cp-Side.webp';
import Cinemaport4 from '../../images/inner/20x24.webp';
import Cinemaport5 from '../../images/inner/20x20.webp';
import './cinema-port.css';
import ScrollToTopButton from '../ScrollButton';
function cinemaport() {
    return (
      
    <>
    <Helmet>
    <title>Cinema Ports Sound Absorbing Acoustical Windows For Home Theatres</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Sound Absorbing Acoustical Windows For Home Theatres Cinema Ports" />
    <meta property="og:description" content="Affordable, Easy To Install Acoustical Windows For Recording Studios or Projection Ports for Movie Theaters. " />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/cp-Front.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/cinema-port" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/cinema-port" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/cinema-port" />
    </Helmet>
    <Header />
    <section className="content" id="cinema">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
                    <h1 className='cinema-content'>Noise S.T.O.P.™ Acoustical Windows For Home Theatres/Cinema Ports</h1>
                    <div className="row pad cinema-content">
                        <div className="col-xl-4 col-sm-12">
                            <Link className="example-image-link" to="../../images/inner/cp-Front.webp" data-lightbox="example-1">
                                <img className="example-image img-fluid" src={Cinemaport1} alt="Cinemaport1" />
                            </Link>
                            <Link className="example-image-link" to="../../images/inner/cp-Open.webp" data-lightbox="example-1">
                                <img className="example-image img-fluid" src={Cinemaport2} alt="Cinemaport2" />
                            </Link>
                            <Link className="example-image-link" to="../../images/inner/cp-Side.webp" data-lightbox="example-1">
                                <img className="example-image img-fluid" src={Cinemaport3} alt="Cinemaport3" />
                            </Link>
                        </div>
                        <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                            <h2>Affordable, Easy To Install Acoustical Windows For Recording Studios or Projection Ports for Movie Theaters</h2>
                                <ul style={{marginLeft: "1em", fontSize: ".9em"}}>
                                    <li>14 Gal. Welded Steel Construction</li>
                                    <li>2-piece Telescoping Frames Adapt From 4″ to 16″ Wall Thickness</li>
                                    <li>Dual Pane Angled Insulated Glass Panels Factory Installed With Dual Isolation Media To Obtain Maximum Sound Attenuation As Well As Unwanted Reflections</li>
                                    <li>Glass Options Offered In Standard Plate, Opti-White Double Optically Coated High Performance</li>
                                    <li>Hand Operated Hinged Glass Frame For Ease Of Cleaning Testing From Between Sound Booths</li>
                                    <li>Elastomeric Seals Isolate Frame From Wall To Eliminate Resonate Noise</li>
                                    <li>Matte Black, Low Glare Powder Coat Finish</li>
                                    <li>Clear Simple Installation Instructions Hardware Included</li>
                                </ul>
                            <p><strong>Stack multiple windows next to each other for a larger viewing area.</strong></p>
                            <h3>Need a Larger Window?</h3>
                            <p>
                                <Link to="/studio-windows" style={{color: "#0059a5"}}>Click Here to View NOISE S.T.O.P Windows</Link>
                            </p>
                        </div>
                        <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                            <div className="testing-info">
                                <h5>Product Testing & Information</h5>
                                <ul className="">
                                    <li><Link to="https://acousticalsurfaces.in/pdfs/quiet_view_details.pdf" target="_blank">Product Specs</Link></li>
                                    <li><Link to="https://acousticalsurfaces.in/pdfs/Recording_Studios_installation.pdf" target="_blank">Installation Instructions</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="line"></div>
                    </div>
                    <div className="row cinema-content">
                        <div className="col-md-12 col-sm-12 fleximages">
                            <div className="imagebox">
                                <Link className="example-image-link" to="../../images/inner/20x24.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" style={{border: "1px solid #000"}} src={Cinemaport4} alt="Cinemaport4" />
                                </Link>
                                <h4>20″×24″ Soundproof Window</h4>
                                <p><strong>Rough Opening Size:</strong> <br /> 20″ H × 24″ W  <br /><br />
                                <strong>Clear Glass Area: <br /> </strong> 17″ H × 22 ″ W</p>
                            </div>
                            <div className="imagebox">
                                <p></p>
                            </div>
                            <div className="imagebox">
                                <Link className="example-image-link" to="../../images/inner/20x20.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" style={{border: "1px solid #000"}} src={Cinemaport5} alt="Cinemaport5" />
                                </Link>
                                <h4>20″×20″ Soundproof Window</h4>
                                <p><strong>Rough Opening Size:</strong> <br /> 20″ H × 20″ W  <br /><br />
                                <strong>Clear Glass Area: <br /> </strong> 17″ H × 18″ W</p>
                            </div>
                        </div>
                    </div>
                    <hr className="d-none d-md-block d-lg-block d-xl-block" />
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default cinemaport;