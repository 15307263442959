import React from "react";
import './carousel-decopanel.css';
import Decoplain1 from '../../images/plain/pl1.webp';
import Decoplain2 from '../../images/plain/pl2.webp';
import Decoplain3 from '../../images/plain/pl3.webp';
import Decoplain4 from '../../images/plain/pl4.webp';
import Decoplain5 from '../../images/plain/pl5.webp';
import Decoplain6 from '../../images/plain/pl6.webp';
import Decoplain7 from '../../images/plain/pl7.webp';
import Decoplain8 from '../../images/plain/pl8.webp';
import Decoplain9 from '../../images/plain/pl9.webp';
import Decoplain10 from '../../images/plain/pl10.webp';
import Decoplain11 from '../../images/plain/pl11.webp';
import Decoplain12 from '../../images/plain/pl12.webp';
import Decoplain13 from '../../images/plain/pl13.webp';
import Decoplain14 from '../../images/plain/pl14.webp';
import Decoplain15 from '../../images/plain/pl15.webp';
function carousel() {
    return (
    <>      
        <div id="carousel-decopanel">
            <div className="row">
                <div className="col-xl-12">
                    <div className="items">
                        <div className="item">
                            <div className="img-holder">
                                <img src={Decoplain1} alt="Decoplain1" width="10px" />
                                <div className="title-box">
                                    <h3><span>Pink</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Decoplain2} alt="Decoplain2" width="10px" />
                                <div className="title-box">
                                    <h3><span>Green</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Decoplain3} alt="Decoplain3" width="10px" />
                                <div className="title-box">
                                    <h3><span>Black</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Decoplain4} alt="Decoplain4" width="10px" />
                                <div className="title-box">
                                    <h3><span>Blue</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Decoplain5} alt="Decoplain5" width="10px" />
                                <div className="title-box">
                                    <h3><span>Brown</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Decoplain6} alt="Decoplain6" width="10px" />
                                <div className="title-box">
                                    <h3><span>Charcoal</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Decoplain7} alt="Decoplain7" width="10px" />
                                <div className="title-box">
                                    <h3><span>Dark Biege</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Decoplain8} alt="Decoplain8" width="10px" />
                                <div className="title-box">
                                    <h3><span>Dark Blue</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Decoplain9} alt="Decoplain9" width="10px" />
                                <div className="title-box">
                                    <h3><span>Dark Green</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Decoplain10} alt="Decoplain10" width="10px" />
                                <div className="title-box">
                                    <h3><span>Dark Maroon</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Decoplain11} alt="Decoplain11" width="10px" />
                                <div className="title-box">
                                    <h3><span>Light Green</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Decoplain12} alt="Decoplain12" width="10px" />
                                <div className="title-box">
                                    <h3><span>Grey</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Decoplain13} alt="Decoplain13" width="10px" />
                                <div className="title-box">
                                    <h3><span>Light Biege</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Decoplain14} alt="Decoplain14" width="10px" />
                                <div className="title-box">
                                    <h3><span>Marble</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Decoplain15} alt="Decoplain15" width="10px" />
                                <div className="title-box">
                                    <h3><span>Light Maroon</span></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>                       
        </div>
    </>
    );
  }
  
  export default carousel;