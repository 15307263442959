import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Studisolators1 from '../../images/inner/stud-isolators.webp';
import Studisolators2 from '../../images/inner/graphics1-200x157.webp';
import './stud-isolators.css';
import ScrollToTopButton from '../ScrollButton';
function studisolators() {
    return (
      
    <>
    <Helmet>
    <title>Beam Isolators effective way to decouple walls and ceilings </title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Beam Isolators" />
    <meta property="og:description" content="Rubber Stud Isolators are an affordable and effective way to decouple walls and ceilings." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/stud-isolators.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/stud-isolators" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/stud-isolators" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/stud-isolators" />
    </Helmet>
    <Header />
    <section className="content" id="stud">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>Stud/Beam Isolators</h1> 
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/stud-isolators.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Studisolators1} alt="studisolators1" />
                                </Link>
                                <Link className="example-image-link" to="../../images/inner/graphics1-200x157.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Studisolators2} alt="studisolators2" />
                                </Link>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <p></p>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>Compact</li>
                                    <li>Easily Installed</li>
                                    <li>Cost Effective</li>
                                </ul>
                                <p></p>
                                <p>Rubber Stud Isolators are an affordable and effective way to decouple walls and ceilings. Simply attach to studs and continue construction and experience improved performance to any construction method.</p>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li>
                                            <Link to="https://acousticalsurfaces.in/pdfs/studbeam.pdf" target="_blank">Product Specs</Link>
                                        </li> 
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Product Specs</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>Material</th>
                                            <td>Extruded Rubber </td>
                                        </tr>
                                        <tr>
                                            <th>Features</th>
                                            <td>Extruded shape to fit wood beams & studs. </td>
                                        </tr>
                                        <tr>
                                            <th>Applications</th>
                                            <td>Floor/ceiling wood beam isolators, wood stud isolation. </td>
                                        </tr>
                                        <tr>
                                            <th>Thickness</th>
                                            <td>2-1/8″ Wide × 1-1/2″ High × 2″ Long </td>
                                        </tr>
                                        <tr>
                                            <th>Carton Size </th>
                                            <td>50 pcs/online order, 25 pcs, 100 pcs </td>
                                        </tr>
                                        <tr>
                                            <th>Color</th>
                                            <td>Black</td>
                                        </tr>
                                        <tr>
                                            <th>Installation</th>
                                            <td>Friction fit over stud/beam faces – Install 16″ o.c.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default studisolators;