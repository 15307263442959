import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Climate1 from '../../images/inner/acoustical_window-2.webp';
import Climate2 from '../../images/inner/double_hung_window.webp';
import Climate3 from '../../images/inner/triple_casement_window.webp';
import Climate4 from '../../images/inner/fixed_window.webp';
import Climate5 from '../../images/inner/window_inserts.webp';
import Climate6 from '../../images/inner/Molding-Profile.webp';
import Climate7 from '../../images/inner/office1-350x262.webp';
import Climate8 from '../../images/inner/Ipswich.webp';
import Climate9 from '../../images/inner/3_windows.webp';
import Climate10 from '../../images/inner/closeup.webp';
import Climate11 from '../../images/inner/Superior.webp';
import Climate12 from '../../images/inner/inferior.webp';
import Climate13 from '../../images/inner/in_jamb_large.webp';
import Climate14 from '../../images/inner/surface_mount.webp';
import Climate15 from '../../images/inner/surface_mount2.webp';
import './climate-seal-window-inserts.css';
import ScrollToTopButton from '../ScrollButton';
function climateseal() {
    return (
      
    <>
    <Helmet>
    <title>Climate Seal Acoustic Soundproof Windows</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India"/>
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Climate Seal Acoustic Series Window Insert" />
    <meta property="og:description" content="Exterior grade PVC extrusion with impact resistance and U.V. stabilization for durability and strength." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/acoustical_window-2.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/climate-seal-window-inserts" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/climate-seal-window-inserts" hreflang="en-ae" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/climate-seal-window-inserts" />
    </Helmet>
    <Header />
    <section className="content" id="climateseal">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container"> 
                        <div className="megamenu">
                            <Megamenu />
				        </div>
                    </div>
                    <h1 className='climateseal-content'>Climate Seal™ Acoustic Series Window Insert</h1> 
                    <div className="row pad climateseal-content">
                        <div className="col-xl-4 col-sm-12">
                            <Link className="example-image-link" to="../../images/inner/acoustical_window-2.webp" data-lightbox="example-1">
                                <img className="example-image img-fluid" src={Climate1} alt="Seal Window1" />
                            </Link>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 text">
                            <p><strong>Features:</strong></p>                                    
                            <ul style={{marginLeft: "1em", fontSize: ".9em"}}>
                                <li>Exterior grade PVC extrusion with impact resistance and U.V. stabilization for durability and strength.</li>
                                <li>3/4″ Wide × 1″ Deep frame. Acoustic Series can receive up to 1/4″ thick glazing.</li>
                                <li>Mitered Corners (except extreme out-of-square applications) to ensure maximum performance and sound abatement.</li>
                                <li>High performance “closed cell” acoustical weather strip foam to further dampen sound vibrations between the primary window frame and the Climate Seal&trade; steel attachment angle.</li>
                                <li>Half the weight of glass competitors, which can make removal very dangerous with their system.</li>
                            </ul>
                        </div>
                        <div className="line"></div>
                    </div>
                    <h2 className='climateseal-content'>Testimonials</h2>
                    <div className="row climateseal-content">
                        <div className="col-sm-4">
                            <li style={{listStyle: "none"}}>
                                <Link style={{color: "#0059a5"}} target="_blank" to="https://acousticalsurfaces.in/pdfs/Climate-Seal-Solves-Noise-Issue.pdf">Climate Seal Solves <br /> Noise Issue</Link>
                            </li>
                        </div>
                        <div className="col-sm-4">
                            <li style={{listStyle: "none"}}>
                                <Link style={{color: "#0059a5"}} target="_blank" to="https://acousticalsurfaces.in/pdfs/Climate-Seal-Recording-Studio.pdf">Climate Seal & <br /> Recording Studio</Link>
                            </li>
                        </div>
                        <div className="col-sm-4">
                            <li style={{listStyle: "none"}}>
                                <Link style={{color: "#0059a5"}} target="_blank" to="https://acousticalsurfaces.in/pdfs/Climate-Seal-Window-Testimonial.pdf">Climate Seal Window <br /> Testimonial</Link>
                            </li>
                        </div>
                        <div className="col-sm-12 text">
                        <p></p>
                        <p>Born from our original Climate Seal™ Thermal Series, the Climate Seal™ Acoustic Series 
                            window insert specifically focuses on sound abatement across all frequency levels with 
                            exceptional performance while maintaining its discreet look and superior thermal 
                            properties. While the Climate Seal™ Thermal Series abates sound by up to 60% from 
                            pre-existing levels, the Acoustic Series boasts up to 80% sound reduction with an STC 
                            rating as high as 49, which rivals or exceeds the performance of many other competitive 
                            products and does so at a significantly reduced cost and aesthetic impact.
                        </p>
                        <p>The Climate Seal™ Acoustic Series model is an ideal choice for many residential, 
                            commercial and historical applications. Whether you live on a busy street, airplane 
                            flight path or wish to maintain the comfort level of your hotel guests, the Climate 
                            Seal™ Acoustic Series window insert will perform extremely well, regardless of the 
                            application. Other acoustical window products have you call for a secondary and permanent 
                            window layer, which we feel is excessive, bulky and unattractive without an appreciable 
                            improvement above and beyond our Climate Seal™ Acoustic Series system. The Climate Seal™ 
                            Acoustic Series window insert is easily removed in seconds for access to the primary 
                            window for cleaning or maintenance and is just as easily re-applied to protect you from 
                            irritating and excessive decibel levels.
                        </p>
                        <p>Below is a comprehensive breakdown of our performance ratings and results. We’ve also 
                            included a “before & after” scenario on (3) different primary window applications to 
                            illustrate how effective the Climate Seal™ Acoustic Series can be when applied over your 
                            primary window. And, just like all of our other Climate Seal™ family of products, the 
                            Acoustic Series is fully customizable. Custom paint colors and glazing options, wood 
                            veneers as well as custom shapes and sizes are all available to ensure performance and 
                            aesthetics are not compromised.
                        </p>
                        </div>
                    </div>
                    <p></p>
                    <div className="row climateseal-content">
                        <div className="col-sm-12">
                            <table className="table table-bordered-2" style={{background: "#fff"}}>
                                <thead>
                                    <tr>
                                        <th colSpan="4">Climate Seal Sound Rating STC</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="colheader topbold">
                                        <td colSpan="2">Window Assembly</td>
                                        <td>STC*</td>
                                        <td>Test</td>
                                    </tr>
                                    <tr>
                                        <td rowspan="2" align="middle">
                                        <figure> 
                                        <Link className="example-image-link" to="../../images/inner/double_hung_window.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Climate2} alt="Seal Window2" />
                                        </Link>
                                        </figure>
                                        </td>
                                        <td>Double Hung Window</td>
                                        <td>24</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="emphasis">Double Hung Window w/Climate Seal Insert</td>
                                        <td className="emphasis">37</td>
                                        <td>
                                            <Link target="_blank" style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/double-hung-with-climate-seal-stc-37.pdf" title="Click to Download Test PDF">ASTM E90</Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="2" align="middle">
                                        <figure> 
                                        <Link className="example-image-link" to="../../images/inner/triple_casement_window.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Climate3} alt="Seal Window3" />
                                        </Link>
                                        </figure>
                                        </td>
                                        <td>Triple Casement Window</td>
                                        <td>27</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="emphasis">Triple Casement Window w/Climate Seal Insert</td>
                                        <td className="emphasis">44</td>
                                        <td>
                                            <Link target="_blank" style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/triple-casement-window-with-climate-seal-stc-44.pdf" title="Click to Download Test PDF">ASTM E90</Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="4" align="middle">
                                        <figure> 
                                        <Link className="example-image-link" to="../../images/inner/fixed_window.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" src={Climate4} alt="Seal Window4" />
                                        </Link>
                                        </figure>
                                        </td>
                                        <td>Store Front/Hotel Fixed Window</td>
                                        <td>33</td>
                                        <td>
                                        <Link target="_blank" style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/fixed-window-stc-33.pdf" title="Click to Download Test PDF">ASTM E90</Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="emphasis">Store Front/Hotel Fixed Window<br />
                                            w/Climate Seal Insert 2.5″ AS</td>
                                        <td className="emphasis">38</td>
                                        <td>
                                            <Link target="_blank" style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/fixed-window-with-climate-seal-stc-38.pdf" title="Click to Download Test PDF">ASTM E90</Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="emphasis">Store Front/Hotel Fixed Window<br />
                                            w/Climate Seal Insert 4.5″ AS</td>
                                        <td className="emphasis">46</td>
                                        <td>
                                            <Link target="_blank" style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/fixed-window-with-climate-seal-stc-46.pdf" title="Click to Download Test PDF">ASTM E90</Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="emphasis">Store Front/Hotel Fixed Window<br />
                                            w/Climate Seal Insert 6.5″ AS</td>
                                        <td className="emphasis">51</td>
                                        <td>
                                            <Link target="_blank" style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/fixed-window-with-climate-seal-stc-51.pdf" title="Click to Download Test PDF">ASTM E90</Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4" className="tablenote">*STC (Sound Transmission className) a rating to represent how well a building partition attenuates airborn sound. The higher the STC number, the greater amount of sound that is lost. This rating targets a noise spectrum surrounding speech levels.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br />
                    <div className="row image-display climateseal-content">
                        <div className="col-sm-4">
                            <img className="img-fluid" src={Climate5} alt="Seal Window5" />
                            <h4>Climate Seal Window Insert</h4>
                        </div>
                        <div className="col-sm-4">
                            <img className="img-fluid" src={Climate6} alt="Seal Window6" />
                            <h4>Molding Profile</h4>
                        </div>
                        <div className="col-sm-4">
                            <img className="img-fluid" src={Climate7} alt="Seal Window7" />
                            <h4>Climate Seal™ Acoustic Series</h4>
                            <p>window insert applied over aluminum curtain wall construction. <br />
                            <em>Houston, TX Balance America Office Suites</em></p>
                        </div>
                    </div>
                    <div className="row image-display climateseal-content">
                        <div className="col-sm-4">
                            <img className="img-fluid" src={Climate8} alt="Seal Window8" />
                            <h4>Climate Seal&trade; Acoustic Series</h4>
                            <p>window insert applied over existing storefront glass for maximum sound protection from street traffic<br />
                            <em>Ipswich, MA</em></p>
                        </div>
                        <div className="col-sm-4">
                            <img className="img-fluid" src={Climate9} alt="Seal Window9" />
                            <h4>Climate Seal&trade; Acoustic Series</h4>
                            <p>window insert for a residential triple wide double-hung application.<br />
                            <em>Charlotte, NC</em></p>
                        </div>
                        <div className="col-sm-4">
                            <img className="img-fluid" src={Climate10} alt="Seal Window10" />
                            <h4>Climate Seal&trade; Acoustic Series</h4>
                            <p>window insert applied over historic wooden windows.<br />
                            <em>Ocean Springs, MS <br /> Mary O’Keefe Cultural Center</em></p>
                        </div>
                    </div>
                    <div className="row image-display climateseal-content">
                        <div className="col-sm-4">
                            <img className="img-fluid" src={Climate11} alt="Seal Window11" />
                            <h4>Superior Climate Seal&trade; Acoustic Series</h4>
                            <p>window insert. Easily removed for cleaning of the primary window while retaining exceptional performance.</p>
                        </div>
                        <div className="col-sm-4">
                            <img className="img-fluid" src={Climate12} alt="Seal Window12" />
                            <h4>Inferior</h4>
                            <p>X-brand permanent acoustic window. 3X thicker aluminum fixed window eliminated the ability to access the primary window when needed. Performance not equivalent to Climate Seal™ Acoustic Series window insert.</p>
                        </div>
                        <div className="col-sm-4">
                            <img className="img-fluid" src={Climate13} alt="Seal Window13" />
                            <h4>In-Jamb Mounting</h4>
                            <p>WINDOWS INSTALLED (shown above) – In-Jamb mounting style where the Climate Seal™ window sits inside of the window jamb itself, much closer to the existing window.</p>
                        </div>
                    </div>
                    <div className="row image-display climateseal-content">
                        <div className="col-sm-4">
                            <img className="img-fluid" src={Climate14} alt="Seal Window14" />
                        </div>
                        <div className="col-sm-4">
                            <img className="img-fluid" src={Climate15} alt="Seal Window15" />
                        </div>
                        <hr />
                        <div className="col-sm-12">
                            <h3>Surface Mounting</h3>
                            <p>FRAME ONLY (shown above) – Surface mount style installation where the Climate Seal™ window is mounted on the surface of the existing window frame. This mounting style is preferred because of the increased amount of airspace, which allows for maximum sound reduction between the existing window and the Climate Seal™ window insert.</p>
                        </div>
                    </div>
                    <hr className="d-none d-md-block d-lg-block d-xl-block" />
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default climateseal;