import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import './office-enviro.css';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Office1 from '../../images/inner/barricade_ceiling1-200x200.webp';
import Office2 from '../../images/inner/doorseal11-200x200.webp';
import Office3 from '../../images/inner/soundscreen-white-noise-machine-200x200.webp';
import Office4 from '../../images/inner/fabric-wrapped-panel-edge-details-lg-200x200.webp';
import Office5 from '../../images/inner/sound-silencer-panels-200x200.webp';
import Office6 from '../../images/inner/cellulose-panels1-200x200.webp';
import Office7 from '../../images/inner/silk-metal-tiles-sm1-200x200.webp';
import ScrollToTopButton from '../ScrollButton';
function office() {
    return (
      
    <>
    <Helmet>
    <title>Soundproofing an Office Environment Acoustic Walls & Ceilings</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Soundproofing an Office Space" />
    <meta property="og:description" content="Our sound-absorbing wall panels, door seals, and ceiling tiles offer the best noise reduction on the market. " />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/barricade_ceiling1-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/office-enviro" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/office-enviro" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/office-enviro" />
    </Helmet>
    <Header />
    <section className="content" id="office">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Soundproofing an Office Space</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>The challenges of working in an office environment are many, but excessive noise shouldn’t be one of them. We have numerous office noise reduction solutions for improving office environment acoustics from experts who know how to keep things quiet.</p>
                                    <p>Our products for soundproofing an office space vary widely and include everything from traditional or standard acoustical panels to high-end products that are custom-designed and engineered to meet any need and match almost any décor. Our sound-absorbing wall panels, door seals, and ceiling tiles offer the best noise reduction on the market. With many surface options, these products combine a designer look with exceptional acoustical qualities. The className A fire rating adds a high level of safety to all of our products. We also offer a white noise machine to mask distracting sounds.</p>
                                    <p>Acoustical Surfaces prides itself on its successful history and skill at creating acoustically satisfying environments. The list below highlights some of our exceptional office noise control products. We can solve any noise problem! <Link to="/contact-us">Contact us</Link> today and we'll help you get started.</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>Soundproofing Office Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Office1} alt="Barrier ACT Tiles" />
                                </Link>
                                <h5>Barrier ACT Tiles</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Office2} alt="Door Seal Kits" />
                                </Link>
                                <h5>Door Seal Kits</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Office3} alt="White Noise Machine" />
                                </Link>
                                <h5>White Noise Machine</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Office4} alt="Fabric Wrapped Fiberglass Panels" />
                                </Link>
                                <h5>Fabric Wrapped Fiberglass Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Office5} alt="Sound Silencer Panels" />
                                </Link>
                                <h5>Sound Silencer Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Office6} alt="CFAB Cellulose Panels" />
                                </Link>
                                <h5>CFAB Cellulose Panels</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Office7} alt="Silk Metal" />
                                </Link>
                                <h5>Silk Metal</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default office; 