import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundrooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADLibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Insulation1 from '../../images/inner/recycled_logo_jeans2-200x200.webp';
import Insulation2 from '../../images/inner/cellulose-panels4-200x200.webp';
import Insulation3 from '../../images/inner/handy_bag1-200x200.webp';
import Insulation4 from '../../images/inner/pipe-lag-top2-200x200.webp';
import './insulation.css';
import ScrollToTopButton from '../ScrollButton';
function insulation() {
    return (
      
    <>
    <Helmet>
    <title>Acoustic Insulation | Sound Control Products </title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Insulation Sound Control Products" />
    <meta property="og:description" content="Our innovative and revolutionary acoustical insulation products offer benefits not seen in any other insulations." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/recycled_logo_jeans2-200x200.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/insulation" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/insulation" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/insulation" />
    </Helmet>
    <Header />
    <section className="content" id="insulation">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h3>Insulation Sound Control Products</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <article className="clearfix">
                                    <p>Insulation serves a very important purpose. It is an effective way to maintain comfortable temperatures in heated or cooled spaces and helps to conserve energy. But, did you know that not all insulation offers the same level of sound absorption?</p>
                                    <p>Our innovative and revolutionary acoustical insulation products offer benefits not seen in any other insulations. Made from recycled materials, our insulations provide maximum R-Value performance and an extremely high Noise Reduction Coefficient. Since our acoustical insulations do not contain any fiberglass, there is no itching and they are easily handled. And, as they are made from natural denim or cellulose fibers, there are no chemicals used in the manufacturing process to make these a sustainable and very “green” solution.</p>
                                    <p>Combining exceptional acoustical performance with R-Value performance makes our Insulation products superior to others on the market today. <Link to="/contact-us">Contact us</Link> to learn more!</p>
                                </article>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>View Our Products</h4>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Insulation1} alt="UltraTouch Wall Insulation" />
                                </Link>
                                <h5>UltraTouch™ Wall Insulation</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Insulation2} alt="Cellulose Wall Insulation" />
                                </Link>
                                <h5>Cellulose Wall Insulation</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Insulation3} alt="UltraTouch Handy Bag" />
                                </Link>
                                <h5>UltraTouch Handy Bag</h5>
                            </div>
                            <div className="col-sm-3 dbox">
                                <Link to="/#">
                                    <img className="img" src={Insulation4} alt="Pipe and Duct Lag" />
                                </Link>
                                <h5>Pipe and Duct Lag</h5>
                            </div>
                        </div>
                        <hr />
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={Soundrooms} alt="Create Better Sounding Rooms"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"   target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADLibrary} alt="CAD Library"  target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi"  target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default insulation; 