import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundbreak1 from '../../images/inner/soundbreakjpg.webp';
import Soundbreak2 from '../../images/inner/soundbreak_xp_sideview.webp';
import Soundbreak3 from '../../images/inner/1_2_regular_single_layer_2x4_studs.webp';
import Soundbreak4 from '../../images/inner/1_2_soundbreak_single_layer_2x4_studs.webp';
import Soundbreak5 from '../../images/inner/1_2_soundbreak_unbalanced_2x4_studs.webp';
import Soundbreak6 from '../../images/inner/5_8_soundbreak_single_layer_2x4_studs.webp';
import Soundbreak7 from '../../images/inner/5_8_soundbreak_unbalanced_staggered_2x4_studs.webp';
import Soundbreak8 from '../../images/inner/5_8_soundbreak_unbalanced_double_row_2x4_studs.webp';
import Soundbreak9 from '../../images/inner/5_8_soundbreak_h_stud_area_separation_wall.webp';
import Soundbreak10 from '../../images/inner/5_8_soundbreak_steel_single_layer_358_studs.webp';
import Soundbreak11 from '../../images/inner/5_8_soundbreak_steel_unbalanced_358_studs.webp';
import Soundbreak12 from '../../images/inner/5_8_soundbreak_steel_double_layer_358_studs.webp';
import Soundbreak13 from '../../images/inner/5_8_soundbreak_steel_double_layer_6_studs.webp';
import Soundbreak14 from '../../images/inner/5_8_soundbreak_steel_unbalanced_double_row_212_studs.webp';
import './soundbreak-xp.css';
import ScrollToTopButton from '../ScrollButton';
function soundbreak() {
    return (
      
    <>
    <Helmet>
    <title>Acoustical SoundBreak XP Acoustically Enhanced Gypsum Board </title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="SoundBreak XP Acoustically Enhanced Gypsum Board" />
    <meta property="og:description" content="Soundbreak XP Gypsum Board is an acoustically enhanced gypsum board used in the construction of high STC wall assemblies." />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/soundbreakjpg.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/soundbreak-xp" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/soundbreak-xp" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/soundbreak-xp" />
    </Helmet>
    <Header />
    <section className="content" id="soundxp">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>SOUNDBREAK® XP®</h1>
                        <div className="row pad">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/soundbreakjpg.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Soundbreak1} alt="Soundbreak1" />
                                </Link>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>SoundBreak XP Acoustically Enhanced Gypsum Board</h2>
                                <ul style={{marginleft: "1em"}}>
                                    <li>Superior Sound Damping</li>
                                    <li>Acoustical Enhanced for High Rated STC Wall Assemblies</li>
                                    <li>Mold/Mildew/Moisture Resistant</li>
                                    <li>Approved For Use on Walls and Ceilings</li>
                                </ul>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row pad">
                            <div className="col-sm-12 text">
                                <h2>Testimonials</h2>
                                <Link target="_blank" style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/Schmitt-Music-Testimonial.pdf">Listening Environment Greatly Improved in Schmitt Music Store</Link>
                                <p></p>
                                <p>Soundbreak XP Gypsum Board is an acoustically enhanced gypsum board used in the construction of high STC wall assemblies. This innovative gypsum board allows for construction of high STC wall assemblies that are thinner, cost effective and more reliable than traditional methods for constructing these types of assemblies.</p>
                                <p>SoundBreak XP Gypsum Board has an acoustically enhanced, high density gypsum core encased in a heavy, abrasion and mold/mildew/moisture resistant, 100% recycled, purple paper on both sides. Used in construction of high rated STC wall assemblies, SoundBreak XP consists of a layer of viscoelastic damping polymer sandwiched between two pieces of high density mold resistant gypsum board, providing constrained layer damping.</p>
                            </div>
                            <div className="line"></div>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 text">
                                <h3>Market Trends Driving The Need for Higher Rated STC Wall Partitions</h3>
                                <p>Increasing land costs have resulted in larger amounts of high density multi-family housing in a growing number of United States housing markets. The result of high density housing is individual living units positioned closer together than traditional single-family housing.</p>
                                <p>Home theater systems are becoming more prevalent in use and sophistication, resulting in the potential for more noise being transmitted between wall partitions. Commercial buildings such as schools, hospitals and hotels also have an increasing need to control sound between areas of a building.</p>
                                <p>All of these changing market dynamics have resulted in an increased need for higher rated Sound Transmission Class (STC) wall partitions, which reduce the transmission of airborne sound between living spaces within buildings.</p>
                                <strong>What is an Acceptable STC Rating for a Wall Partition?</strong>
                                <strong>National Research Council of Canada Survey</strong>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>600 multi-family residences (300 party walls between them)</li>
                                    <li>Residents with lower STC rated walls are more likely to:
                                    <ul style={{marginleft: ".5em", fontsize: ".9em"}}>
                                        <li>Want to move</li>
                                        <li>Be awakened by noises</li>
                                        <li>Have trouble falling asleep due to noises</li>
                                        <li>Think neighbors are less considerate</li>
                                    </ul>
                                    </li>
                                </ul>
                                <strong></strong>
                                <strong>General Survey Conclusions</strong>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li><b>STC ≥55</b> A realistic goal for acceptable sound insulation</li>
                                    <li><b>STC ≥60</b> More ideal, would practically eliminate negative effects of noise from neighbors</li>
                                    <li>Music related sounds may require the highest rated walls</li>
                                </ul>
                                <p></p>
                                <p>J.S. Bradley, <em>Deriving Acceptable Values for Party Wall Sound Insulation</em> survey results</p>
                                <div className="line"></div>
                                <h3>FEATURES/BENEFITS</h3>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>Resists the growth of mold per ASTM G 21 with a score of 0, the best possible score.</li>
                                    <li>Resists the growth of mold per ASTM D 3272 with a score of 10, the best possible score.</li>
                                    <li>Use of SoundBreak XP Gypsum Board results in wall partitions with high rated STC values that are thinner than traditionally built high rated STC wall partitions providing increased usable floor space.</li>
                                    <li>Superior sound damping, cost-efficient material that is easily finished and decorated in the same manner as standard gypsum board.</li>
                                    <li>All SoundBreak XP Gypsum Board designs were tested by an independent third-party acoustical laboratory using the full-scale ASTM E90 test procedure.</li>
                                    <li>Heavy abrasion resistant paper and denser core provide greater resistance to surface abuse and indentation when tested in accordance with ASTM C 1629.</li>
                                    <li>Features a smooth, heavy face paper that is highly resistant to scuffing and provides a superior surface for decoration.</li>
                                    <li>5/8″ SoundBreak XP features a fire resistant Type X core and is UL Classified and approved for inclusion in specific UL fire-rated designs.</li>
                                    <li>SoundBreak XP Gypsum Board is GREENGUARD Children &amp; Schools<sup>SM</sup> Certified for indoor air quality.</li>
                                    <li>Approved for use on walls and ceilings.</li>
                                </ul>
                                <p></p>
                                <p></p>
                                <strong>How SoundBreak XP Gypsum Board Works</strong>
                                <img src={Soundbreak2} alt="Soundbreak2" />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 text">
                                <h3>Design Considerations in Acoustical Wall Partitions</h3>
                                <p>The goal of a high rated STC wall partition is to decrease the amount of sound transmission through the partition. The following five variables can have impact on the ability of the partition to provide this loss.</p>
                                <h3>Mass</h3>
                                <p>Increasing the mass of a wall partition increases the amount of sound transmission loss. Increasing mass in a cost and space effective way can be a challenge.</p>
                                <h3>Stiffness</h3>
                                <p>Increasing the stiffness of a wall partition will decrease the amount of sound transmission loss. For that reason metal studs outperform wood studs, and 24″ o.c. f raming spacing outperforms 16″ o.c. framing spacing.</p>
                                <h3>Damping</h3>
                                <p>Introduction of damping will increase the amount of sound transmission loss. In particular, constrained layer damping can be effective for structure type applications.</p>
                                <h3>Cavity Depth</h3>
                                <p>Increasing the depth of the cavity of the partition can increase the amount of sound transmission loss, especially when the cavity is filled with acoustical insulation.</p>
                                <h3>Cavity Absorption</h3>
                                <p>Adding sound-absorbing material such as fiberglass or mineral fiber insulation to the cavity of a partition will increase the amount of sound transmission loss. The sound-absorbing material should completely fill the cavity but not be compacted or compressed in any way.</p>
                                <div className="line"></div>
                                <h3>LIMITATIONS</h3>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>Exposure to excessive or continuous moisture and extreme temperatures should be avoided. SoundBreak XP Gypsum Board is not recommended where it will be exposed to temperatures exceeding 125°F (52°C) for extended periods of time.</li>
                                    <li>Installing SoundBreak XP Gypsum Board panels over an insulating blanket, installed continuously across the face of the framing members, is not recommended. Blankets should be recessed and flanges attached to the sides of the studs.</li>
                                    <li>SoundBreak XP Gypsum Board must be stored off the ground and under cover. Sufficient risers must be used to ensure support for the entire length of the gypsum board to prevent sagging.</li>
                                    <li>SoundBreak XP Gypsum Board must be kept dry to minimize the potential for mold growth. Adequate care should be taken while transporting, storing, applying and maintaining SoundBreak XP Gypsum Board. For additional information, refer to the Gypsum Association publication, <em>“Guidelines for the Prevention of Mold Growth on Gypsum Board”</em> (GA-238-03), which is available at under the “Download Free Gypsum Association Publications” section.</li>
                                </ul>
                                <div className="line"></div>
                                <strong>ACCESSORIES</strong>
                                <ul style={{marginleft: "1em", fontsize: ".9em"}}>
                                    <li>Fasteners: Drywall Screws or Nails</li>
                                    <li>Joint Tape</li>
                                    <li>Joint Compound</li>
                                    <li>Cornerbead</li>
                                    <li>Trims</li>
                                    <li>Casing Beads</li>
                                    <li>Acoustical Sealant</li>
                                    <li>Acoustical Putty Pads</li>
                                </ul>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="2">APPLICABLE STANDARDS AND REFERENCES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td width="50%">ASTM C 1396</td>
                                            <td>ASTM G 21</td>
                                        </tr>
                                        <tr>
                                            <td>ASTM C 1629</td>
                                            <td>Gypsum Association GA-216</td>
                                        </tr>
                                        <tr>
                                            <td>ASTM C 840</td>
                                            <td>Gypsum Association GA-214</td>
                                        </tr>
                                        <tr>
                                            <td>ASTM D 3273</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="3">PHYSICAL PROPERTIES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td className="colheader">Thickness, Nominal</td>
                                            <td>1/2″ Regular<br />
                                                (12.7 mm)
                                            </td>
                                            <td>5/8″ Type X<br />
                                                (15.9 mm)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">Width, Nominal</td>
                                            <td>4′<br />
                                                (1219 mm)
                                            </td>
                                            <td>4′<br />
                                                (1219 mm)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">Length, Standard</td>
                                            <td>8′ through 12′<br />
                                                (2438 mm 3657 mm)
                                            </td>
                                            <td>8′ through 12′<br />
                                                (2438 mm 3657 mm)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">Weight, lbs/sf, Nominal</td>
                                            <td>2.3</td>
                                            <td>2.7</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">Surface Burning Characteristics<br />
                                                (per ASTM E 84)
                                            </td>
                                            <td>Flame Spread: 15 Smoke Developed: 0</td>
                                            <td>Flame Spread: 15 Smoke Developed: 0</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">Surface Abrasion Resistance<br />
                                                (per ASTM C 1629)
                                            </td>
                                            <td>Level 3</td>
                                            <td>Level 3</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">Indentation Resistance<br />
                                                (per ASTM C 1629)
                                            </td>
                                            <td>Level 1</td>
                                            <td>Level 1</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader">Soft Body Impact Resistance<br />
                                                (per ASTM C 1629)
                                            </td>
                                            <td>Level 1</td>
                                            <td>Level 1</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="6">1/2″ Regular Gypsum Board Partitions Wood Framing</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bodytext">
                                        <tr className="colheader">
                                            <td colSpan="3"></td>
                                            <td>Description</td>
                                            <td width="18%">Test No.</td>
                                            <td width="5%">STC</td>
                                        </tr>
                                        <tr className="colheader">
                                            <td colSpan="6">SINGLE LAYER 2 × 4 STUDS</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <img width="140" height="35" alt="Single Layer 2x4 Studs" src={Soundbreak3} />
                                            </td>
                                            <td>1/2″ Regular Gypsum Board vertically applied to each side of 24 studs 16″ o.c. with 1-1/4″ type W screws 12″ o.c. Joints staggered on opposite side. 3″ glass fiber insulation in stud cavity.</td>
                                            <td>NBC-W1b</td>
                                            <td className="emphasis">34</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="6">1/2″ Soundbreak XP Gypsum Board Partitions Wood Framing</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bodytext">
                                        <tr className="colheader">
                                            <td colSpan="3"></td>
                                            <td>Description</td>
                                            <td width="18%">Test No.</td>
                                            <td width="5%">STC</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="6" className="colheader">SINGLE LAYER 2 × 4 STUDS</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <img width="140" height="35" alt="Single Layer 2x4 Studs" src={Soundbreak4} />
                                            </td>
                                            <td>1/2″ SoundBreak XP Gypsum Board vertically applied to one side of 24 studs 24″ o.c. with 1-1/4″ type W screws 12″ o.c. 1/2″ Standard Gypsum Board vertically applied to opposite side with 1-1/4″ type W screws 12″ o.c. Joints staggered on opposite side. 3″ glass fiber insulation in stud cavity.</td>
                                            <td>
                                                <Link target="_blank" style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/Single_Layer_2x4_Studs.pdf" title="Download Test Data">NGC 2009027</Link>
                                            </td>
                                            <td className="emphasis">49</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="6" className="colheader">UNBALANCED 24 STUDS</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <img width="140" height="36" alt="Single Layer 2x4 Studs" src={Soundbreak5} />
                                            </td>
                                            <td>Base layer of 1/2″ Standard Gypsum Board vertically applied to one side of 24 studs 24″ o.c. with 1-1/4″ type W screws 24″ o.c. Face layer of 1/2″ SoundBreak XP Gypsum Board vertically applied with 1-5/8″ type W screws 12″ o.c. 1/2″ Standard Gypsum Board vertically to opposite side with 1-1/4″ type W screws 12″ o.c. Joints staggered each layer and opposite sides. 3″ glass fiber insulation in stud cavity.</td>
                                            <td>
                                                <Link target="_blank" style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/Unbalanced_2x4_Studs.pdf" title="Download Test Data">NGC 2009028</Link>
                                            </td>
                                            <td className="emphasis">51</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="6">5/8″ Soundbreak XP Gypsum Board Partitions Wood Framing</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bodytext">
                                        <tr className="colheader">
                                            <td colSpan="3"><strong>Fire Rating</strong></td>
                                            <td>Description</td>
                                            <td width="18%">Test No.</td>
                                            <td width="5%">STC</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="6" className="colheader">SINGLE LAYER 24 STUDS</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <img width="140" height="30" alt="Single Layer 2x4 Studs" src={Soundbreak6} />
                                            </td>
                                            <td rowspan="3">5/8″ SoundBreak XP Gypsum Board vertically applied to each side of 24 studs spaced 24″ o.c. with 1-1/4″ type W screws 12″ o.c. 3-1/2″ glass fiber in stud cavity.</td>
                                            <td rowspan="3">
                                                <Link target="_blank" style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/Wood_SB_Each_Side_STC_53.pdf" title="Download Test Data">RAL TL-07-145</Link>
                                            </td>
                                            <td rowspan="3" className="emphasis">53</td>
                                        </tr>
                                        <tr className="colheader">
                                            <td>Fire Rating</td>
                                            <td>Ref.</td>
                                            <td>Design No.</td>
                                        </tr>
                                        <tr>
                                            <td>1 hr.</td>
                                            <td>UL</td>
                                            <td>U309</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="6" className="colheader">UNBALANCED STAGGERED 24 STUDS</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <img width="140" height="44" alt="Single Layer 2x4 Studs" src={Soundbreak7} />
                                            </td>
                                            <td rowspan="3">Base layer 5/8″ Fire-Resistance Rated Gypsum Board vertically applied to staggered 24 studs spaced 16″ o.c. on 26 plates with 1-1/4″ type W screws 12″ o.c. Face layer of 5/8″ SoundBreak XP vertically applied with 2″ type W screws 16″ o.c. 5/8″ Fire-Resistance Rated Gypsum Board vertically applied to opposite side with 1-1/4″ type W screws 12″ o.c. Vertical joints staggered 16″ each layer and opposite sides. 2-1/2″ glass fiber in stud cavity.</td>
                                            <td rowspan="3">
                                                <Link target="_blank" style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/Wood_Unbalanced_Staggered_STC_60.pdf" title="Download Test Data">RAL TL-07-170</Link>
                                            </td>
                                            <td rowspan="3" className="emphasis">60</td>
                                        </tr>
                                        <tr className="colheader">
                                            <td>Fire Rating</td>
                                            <td>Ref.</td>
                                            <td>Design No.</td>
                                        </tr>
                                        <tr>
                                            <td>1 hr.</td>
                                            <td>GA</td>
                                            <td>Based on WP3514</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="6" className="colheader">UNBALANCED DOUBLE ROW 24 STUDS</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <img width="140" height="54" alt="Single Layer 2x4 Studs" src={Soundbreak8} />
                                            </td>
                                            <td rowspan="3">Base layer 5/8″ Fire-Resistance Rated Gypsum Board vertically applied to double row of 24 studs spaced 16″ o.c. on separate plates with 1-1/4″ type W screws 12″ o.c. Face layer of 5/8″ SoundBreak XP vertically applied with 2″ type W screws 16″ o.c. 5/8″ Fire-Resistance Rated Gypsum Board vertically applied to opposite side with 1-1/4″ type W screws 12″ o.c. Vertical joints staggered 16″ each layer and opposite sides. 3-1/2″ glass fiber in stud cavity.</td>
                                            <td rowspan="3">
                                                <Link target="_blank" style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/Wood_Unbalanced_Double_STC_64.pdf" title="Download Test Data">RAL TL-07-147</Link>
                                            </td>
                                            <td rowspan="3" className="emphasis">64</td>
                                        </tr>
                                        <tr className="colheader">
                                            <td>Fire Rating</td>
                                            <td>Ref.</td>
                                            <td>Design No.</td>
                                        </tr>
                                        <tr>
                                            <td>1 hr.</td>
                                            <td>GA</td>
                                            <td>Based on WP3514</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="6" className="colheader">H-STUD AREA SEPARATION WALL</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <img width="140" height="68" alt="Single Layer 2x4 Studs" src={Soundbreak9} />
                                            </td>
                                            <td rowspan="3">Two layers of 1″ Fire-Shield Shaftliner inserted in 2″ H-studs spaced 24″ o.c. Minimum 3/4″ air space between shaftliner and adjacent construction.5/8″ Soundbreak XP Gypsum Board vertically applied to outside of 24 studs spaced 16″ o.c. with 1-1/4″ type W screws 12″ o.c. 3-1/2″ glass fiber in stud cavity.</td>
                                            <td rowspan="3">
                                                <Link target="_blank" style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/Metal_H-Stud_Separation_STC_67.pdf" title="Download Test Data">NRCC B-3451.1</Link>
                                            </td>
                                            <td rowspan="3" className="emphasis">67</td>
                                        </tr>
                                        <tr className="colheader">
                                            <td>Fire Rating</td>
                                            <td>Ref.</td>
                                            <td>Design No.</td>
                                        </tr>
                                        <tr>
                                            <td>2 hr.</td>
                                            <td>UL</td>
                                            <td>U347</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="6">5/8″ Soundbreak XP Gypsum Board Partitions Steel Framing</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bodytext">
                                        <tr className="colheader">
                                            <td colSpan="3">Fire Rating</td>
                                            <td>Description</td>
                                            <td width="18%">Test No.</td>
                                            <td width="5%">STC</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader" colSpan="6">SINGLE LAYER 3-5/8″ STUDS</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <img width="140" height="35" src={Soundbreak10} alt="Single Layer 2x4 Studs" />
                                            </td>
                                            <td rowspan="3">5/8″ SoundBreak XP Gypsum Board vertically applied to one side of 3-5/8″ steel studs 24″ o.c. with 1″ type S screws 8″ o.c. at perimeter and 12″ o.c. in the field. 5/8″ Fire-Resistance Rated Gypsum Board vertically applied to opposite side with 1″ type S screws 8″ o.c. at perimeter and 12″ o.c. in the field. Joints staggered on opposite side. 3-1/2″ glass fiber in stud cavity.</td>
                                            <td rowspan="3">
                                                <Link title="Download Test Data" style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/Metal_One_Side_SB_STC_54.pdf" target="_blank">RAL TL-07-389</Link>
                                            </td>
                                            <td className="emphasis" rowspan="3">54</td>
                                        </tr>
                                        <tr className="colheader">
                                            <td>Fire Rating</td>
                                            <td>Ref.</td>
                                            <td>Design No.</td>
                                        </tr>
                                        <tr>
                                            <td>1 hr.</td>
                                            <td>UL</td>
                                            <td>U465</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader" colSpan="6">UNBALANCED 3-5/8″ STUDS</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <img width="140" height="36" src={Soundbreak11} alt="Single Layer 2x4 Studs" />
                                            </td>
                                            <td rowspan="3">Base layer 5/8″ SoundBreak XP Gypsum Board vertically applied to 3-5/8″ steel studs spaced 24″ o.c. with 1″ type S screws 24″ o.c. Face layer 5/8″ Fire-Resistance Rated Gypsum Board vertically applied with 1-5/8″; type S screws 12″ o.c. 5/8″ Fire-Resistance Rated Gypsum Board vertically applied to opposite side with 1″ type S screws 12″ o.c. Vertical joints staggered 24″ each layer and opposite sides. 3-1/2″ glass fiber in stud cavity.</td>
                                            <td rowspan="3">
                                                <Link title="Download Test Data" style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/Metal_Unbalanced_STC_57.pdf" target="_blank">RAL TL-06-334</Link>
                                            </td>
                                            <td className="emphasis" rowspan="3">57</td>
                                        </tr>
                                        <tr className="colheader">
                                            <td>Fire Rating</td>
                                            <td>Ref.</td>
                                            <td>Design No.</td>
                                        </tr>
                                        <tr>
                                            <td>1 hr.</td>
                                            <td>UL</td>
                                            <td>UL465</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader" colSpan="6">DOUBLE LAYER 3-5/8″ STUDS</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <img width="140" height="39" src={Soundbreak12} alt="Single Layer 2x4 Studs" />
                                            </td>
                                            <td rowspan="3">Base layer 5/8″ Soundbreak XP Gypsum Board vertically applied to 3-5/8″ steel studs spaced 24″ o.c. Two layers 5/8″ Fire-Resistance Rated Gypsum Board vertically applied to opposite side. Base layer attached with 1″ type S screws 24″ o.c. Face layer attached with 1-5/8″ type S screws 12″ o.c. Vertical joints staggered 24″ each layer and opposite sides. 3-1/2″ glass fiber in stud cavity.</td>
                                            <td rowspan="3">
                                                <Link title="Download Test Data" style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/Metal_Balanced_Double_Layer_STC_60.pdf" target="_blank">RAL TL-07-168</Link>
                                            </td>
                                            <td className="emphasis" rowspan="3">60</td>
                                        </tr>
                                        <tr className="colheader">
                                            <td>Fire Rating</td>
                                            <td>Ref.</td>
                                            <td>Design No.</td>
                                        </tr>
                                        <tr>
                                            <td>2 hr.</td>
                                            <td>UL</td>
                                            <td>V484</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader" colSpan="6">DOUBLE LAYER 6″ STUDS</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <img width="140" height="53" src={Soundbreak13} alt="Single Layer 2x4 Studs" />
                                            </td>
                                            <td rowspan="3">Base layer 5/8″ SoundBreak XP Gypsum Board vertically applied to 6″ steel studs spaced 24″ o.c. with 1″ type S screws 24″ o.c. Face layer 5/8″ Fire-Resistance Rated Gypsum Board vertically applied with 1-5/8″ type S screws 12″ o.c. Two layers 5/8″ Fire-Resistance Rated Gypsum Board vertically applied to opposite side. Base layer attached with 1″ type S screws 24″ o.c. Face layer attached with 1-5/8″ type S screws 12″ o.c. Vertical joints staggered 24″ each layer and opposite sides. 6″ glass fiber in stud cavity.</td>
                                            <td rowspan="3">
                                                <Link title="Download Test Data" style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/Metal_6_Inch_Balanced_STC_61.pdf" target="_blank">NRCC B-3456.2</Link>
                                            </td>
                                            <td className="emphasis" rowspan="3">61</td>
                                        </tr>
                                        <tr className="colheader">
                                            <td>Fire Rating</td>
                                            <td>Ref.</td>
                                            <td>Design No.</td>
                                        </tr>
                                        <tr>
                                            <td>2 hr</td>
                                            <td>UL</td>
                                            <td>V484</td>
                                        </tr>
                                        <tr>
                                            <td className="colheader" colSpan="6">UNBALANCED DOUBLE ROW 2-1/2″ STUDS</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <img width="140" height="55" src={Soundbreak14} alt="Single Layer 2x4 Studs" />
                                            </td>
                                            <td rowspan="3">Base layer 5/8″ SoundBreak XP Gypsum Board applied vertically to double row of 2-1/2″ steel studs 24″ o.c. with 1″ type S screws 8″ o.c. at perimeter and 12″ o.c. in the field. Face layer 5/8″ Fire-Resistance Rated Gypsum Board applied vertically to opposite side with 1″ type S screws 8″ o.c. at perimeter and 12″ o.c. in the field. Joints staggered on opposite side. 3″ glass fiber or mineral wool insulation in stud cavity.</td>
                                            <td rowspan="3">
                                                <Link title="Download Test Data" style={{color: "#0059a5"}} to="https://acousticalsurfaces.in/pdfs/Metal_Double_Wall_STC_59.pdf" target="_blank">NGC 2008036</Link>
                                            </td>
                                            <td className="emphasis" rowspan="3">59</td>
                                        </tr>
                                        <tr className="colheader">
                                            <td>Fire Rating</td>
                                            <td>Ref.</td>
                                            <td>Design No.</td>
                                        </tr>
                                        <tr>
                                            <td>1 hr.</td>
                                            <td>UL</td>
                                            <td>V488</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <p>
                                    <strong>Note:</strong> In multi-layer systems, SoundBreak XP Gypsum Board can be used as either a face layer or a base layer without affecting the STC Rating.
                                </p>
                                <p></p>
                                <p>
                                    <strong>UL Listed Assemblies</strong>
                                    <br /> The 5/8″ SoundBreak XP Gypsum Board is tested in accordance with ASTM Standard E 119 and is classified as Type X for use in the following UL listings: U017, U301, U302, U305, U309, U326, U330, U332, U338, U339, U341, U342, U351, U354, U355, U356, U357, U358, U360, U364, U368, U369, U371, U379, U392, U405, U411, U418, U420, U425, U428, U429, U434, U439, U449, U450, U460, U465, U466, U475, U487, U494, U499, U505, U524, U525, U531, U646, U647, U648, U649, U651, U652, U926, V408, V415, V419, V420, V421, V425, V430, V432, V433, V434, V435, V438, V449, V450, V486, V483, V484, V488
                                </p>
                                <p></p>
                                <p>
                                    <strong>UL Core Designation</strong>
                                    <br /> 5/8″ SoundBreak XP Gypsum Board: SoundBreak XP
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default soundbreak;