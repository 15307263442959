import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Squarelinelarge from '../../images/inner/squareline_large.webp';
import Squarelineceiling from '../../images/inner/square05.webp';
import Squaretiles from '../../images/inner/square06.webp';
import Squareceiling from '../../images/inner/square07.webp';
import Metalinestandard from '../../images/inner/squareline_standard_chrome_charcoal.webp';
import Metalineultra from '../../images/inner/squareline_ultra_chrome_charcoal.webp';
import Metalinemedium from '../../images/inner/squareline_medium_chrome_charcoal.webp';
import Whitemetal from '../../images/inner/squareline_standard_white_grey.webp'; 
import Metallight from '../../images/inner/squareline_standard_chrome_grey.webp';
import Metalcharcoal from '../../images/inner/squareline_standard_white_charcoal.webp';
import Metalchrome from '../../images/inner/squareline_standard_chrome_charcoal1.webp';
import Lightgrey from '../../images/inner/squareline_medium_chrome_grey.webp';
import Chromecharcoal from '../../images/inner/squareline_medium_chrome_charcoal1.webp';
import Chromegrey from '../../images/inner/squareline_ultra_chrome_grey.webp';
import Charcoalmetal from '../../images/inner/squareline_ultra_chrome_charcoal1.webp';
import Mobsidebar from '../mobile-sidebar';
import './squareline-ceiling-tiles.css';
import ScrollToTopButton from '../ScrollButton';
function squarelineceiling() {
    return (
      
    <>
    <Helmet>
    <title>Squareline Acoustical Metal Ceiling Tiles - Excellent Sound Absorber</title>
    <meta name="description" content="" />
    <meta name="robots" content="index, follow" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="in" />
    <meta name="geo.region" content="india" />
    <meta property="og:title" content="Acoustical Metal Ceiling Tiles" />
    <meta property="og:description" content="" />
    <meta property="og:image" content="https://www.acousticalsurfaces.in/images/inner/squareline_large.webp" />
    <meta property="og:locale" content="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/squareline-ceiling-tiles" hreflang="en-ae" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/squareline-ceiling-tiles" hreflang="en-in" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/squareline-ceiling-tiles" />
    </Helmet>
    <Header />
    <section className="content" id="squareline">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <h1>METALINE™</h1> 
                        <div className="row">
                            <div className="col-xl-4 col-sm-12">
                                <Link className="example-image-link" to="../../images/inner/squareline_large.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Squarelinelarge} alt="Squareline Large" />
                                </Link>
                                <div className="row col-md-12 col-lg-12 minigallery">
                                    <div className='col-lg-4 col-md-12'>
                                        <Link className="example-image-link" to="../../images/inner/square05.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" style={{margin: "5px", maxwidth: "4em"}} src={Squarelineceiling} alt="Square line ceiling tiles" />
                                        </Link>
                                    </div>
                                    <div className='col-lg-4 col-md-12'>
                                        <Link className="example-image-link" to="../../images/inner/square06.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" style={{margin: "5px", maxwidth: "4em"}} src={Squaretiles} alt="ceiling tiles" />
                                        </Link>
                                    </div>
                                    <div className='col-lg-4 col-md-12'>
                                        <Link className="example-image-link" to="../../images/inner/square07.webp" data-lightbox="example-1">
                                            <img className="example-image img-fluid" style={{margin: "5px", maxwidth: "4em"}} src={Squareceiling} alt="square ceiling" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 text">
                                <h2>Acoustical Metal Ceiling Tiles</h2>
                                <ul style={{marginleft: "1em"}}>
                                    <li>Expanded Metal</li>
                                    <li>Decorative – High Style</li>
                                    <li>Fiber Free</li>
                                    <li>Excellent Sound Absorption Qualities</li>
                                    <li>Affordable and Easily Installed</li>
                                </ul> 
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                                <div className="testing-info">
                                    <h5>Product Testing & Information</h5>
                                    <ul className="">
                                        <li><Link to="https://acousticalsurfaces.in/pdfs/square.pdf" target="_blank">Product Specs</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/squareline_standard_chrome_charcoal.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Metalinestandard} alt="Metaline Standard" />
                                </Link>
                                <p style={{margin: "0"}}>METALINE Standard</p>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/squareline_medium_chrome_charcoal.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Metalinemedium} alt="Metaline Medium" />
                                </Link>
                                <p style={{margin: "0"}}>METALINE Medium</p>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/squareline_ultra_chrome_charcoal.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Metalineultra} alt="Metaline Ultra" />
                                </Link>
                                <p style={{margin: "0"}}>METALINE Ultra</p>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Product Specs</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>MATERIAL</th>
                                            <td>Expanded perforated metal with melamine foam backing. </td>
                                        </tr>
                                        <tr>
                                            <th>PATTERN</th>
                                            <td>Standard, Medium and Ultra</td>
                                        </tr>
                                        <tr>
                                            <th>FEATURES</th>
                                            <td>Expanded metal design.</td>
                                        </tr>
                                        <tr>
                                            <th>APPLICATIONS</th>
                                            <td>Offices, conference rooms, board rooms, airports, lobbies, convention centers, retail stores.</td>
                                        </tr>
                                        <tr>
                                            <th>THICKNESS</th>
                                            <td>.25″ or .50″</td>
                                        </tr>
                                        <tr>
                                            <th>SIZES</th>
                                            <td>Nominal 24″×24″, 24″×48″</td>
                                        </tr>
                                        <tr>
                                            <th>COLOR</th>
                                            <td>White metal, chromed metal with colored backing inserts (light gray, charcoal gray and white)</td>
                                        </tr>
                                        <tr>
                                            <th>FLAMMABILITY</th>
                                            <td>Class 1 fire-rated for smoke density and flame spread.</td>
                                        </tr>
                                        <tr>
                                            <th>INSTALLATION</th>
                                            <td>Lay-in 15/16″ T-bar grid system. Slight tegular edge.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>METALINE Standard</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>Options</th>
                                            <td>White or Chrome Metal with Light-Grey or Charcoal Insert </td>
                                        </tr>
                                        <tr>
                                            <th>Profile</th>
                                            <td>Flat or Beveled Profile</td>
                                        </tr>
                                        <tr>
                                            <th>Panel Sizes</th>
                                            <td>24″×24″</td>
                                        </tr>
                                        <tr>
                                            <th>Open Surface</th>
                                            <td>68.2%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/squareline_standard_white_grey.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Whitemetal} alt="White Metal Light Grey Insert" />
                                </Link>
                                <p>White Metal Light-Grey Insert</p>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/squareline_standard_chrome_grey.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Metallight} alt="Chrome Metal Light Grey Insert" />
                                </Link>
                                <p>Chrome Metal Light-Grey Insert</p>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/squareline_standard_white_charcoal.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Metalcharcoal} alt="White Metal Charcoal Insert" />
                                </Link>
                                <p>White Metal Charcoal Insert</p>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/squareline_standard_chrome_charcoal1.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Metalchrome} alt="Chrome Metal Charcoal Insert" />
                                </Link> 
                                <p>Chrome Metal Charcoal Insert</p>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>METALINE Medium</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>Options</th>
                                            <td>White or Chrome Metal with Light-Grey or Charcoal Insert </td>
                                        </tr>
                                        <tr>
                                            <th>Profile</th>
                                            <td>Flat profile ONLY</td>
                                        </tr>
                                        <tr>
                                            <th>Panel Sizes</th>
                                            <td>24″×24″ or 24″×48″</td>
                                        </tr>
                                        <tr>
                                            <th>Open Surface</th>
                                            <td>62.5%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/squareline_medium_chrome_grey.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Lightgrey} alt="Chrome Metal Light Grey" />
                                </Link>
                                <p>Chrome Metal Light-Grey Insert</p>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/squareline_medium_chrome_charcoal1.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Chromecharcoal} alt="Chrome Metal Charcoal" />
                                </Link>
                                <p>Chrome Metal Charcoal Insert</p>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>METALINE Ultra</h3>
                                <table className="table table-bordered" style={{background: "#f5f5f5"}}>
                                    <tbody>
                                        <tr>
                                            <th>Options</th>
                                            <td>White or Chrome Metal with Light-Grey or Charcoal Insert </td>
                                        </tr>
                                        <tr>
                                            <th>Profile</th>
                                            <td>Flat profile ONLY</td>
                                        </tr>
                                        <tr>
                                            <th>Panel Sizes</th>
                                            <td>24″×24″, 24″×48″ or 48″×48″</td>
                                        </tr>
                                        <tr>
                                            <th>Open Surface</th>
                                            <td>71.4%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/squareline_ultra_chrome_grey.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Chromegrey} alt="Chrome Metal" />
                                </Link>
                                <p>Chrome Metal Light-Grey Insert</p>
                            </div>
                            <div className="col-sm">
                                <Link className="example-image-link" to="../../images/inner/squareline_ultra_chrome_charcoal1.webp" data-lightbox="example-1">
                                    <img className="example-image img-fluid" src={Charcoalmetal} alt="Charcoal Metal" />
                                </Link>
                                <p>Chrome Metal Charcoal Insert</p>
                            </div>
                        </div>
                        <hr />
                        <p>
                            <Link className="btn btn-asi" to="reverb-demo">Reverberation Demonstration</Link>
                        </p>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered-2">
                                    <thead>
                                        <tr>
                                            <th colSpan="8">Sound Absorption/Noise Reduction</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="colheader">
                                            <td></td>
                                            <td>125Hz</td>
                                            <td>250Hz</td>
                                            <td>500Hz</td>
                                            <td>1KHz</td>
                                            <td>2KHz</td>
                                            <td>4KHz</td>
                                            <td>NRC</td>
                                        </tr>
                                        <tr>
                                            <td>0.25″ Thick</td>
                                            <td>0.37</td>
                                            <td>0.59</td>
                                            <td>0.30</td>
                                            <td>0.50</td>
                                            <td>0.75</td>
                                            <td>1.09</td>
                                            <td className="emphasis">0.55</td>
                                        </tr>
                                        <tr>
                                            <td>0.50″ Thick</td>
                                            <td>0.40</td>
                                            <td>0.63</td>
                                            <td>0.43</td>
                                            <td>0.60</td>
                                            <td>0.77</td>
                                            <td>0.89</td>
                                            <td className="emphasis">0.61</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>Lighting can be combined with both WHITELINE and METALINE Ceiling Tiles.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobsidebar'>
                <div className="col-sm-12">
                    <div className="container">
                        <div className="row">
                            <Mobsidebar />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default squarelineceiling; 